import {
  BulkLoadCulturePlatesWizardData,
  UploadLiveWellsStepData,
  UploadLiveWellsStepValidData,
  UploadPlateDataStepValidData,
} from './BulkLoadCulturePlatesWizardData.interface'

import { useEffect, useState } from 'react'
import { BulkLoadParseWellDataResponse } from '~/api/operatorActions/bulkLoadCulturePlates'
import bulkLoadCulturePlatesAPI, {} from '~/api/operatorActions/bulkLoadCulturePlates'
import Textarea from '~/components/Textarea'
import Toaster from '~/components/Toaster'
import MinimalButton from '~/components/buttons/MinimalButton'
import useDebouncedAPIEndpoint from '~/utils/hooks/useDebouncedAPIEndpoint'
import BulkLoadDataSchemasDialog from './BulkLoadDataSchemasDialog'
import UploadLiveWellsStepPreview from './UploadLiveWellsStepPreview'
import cs from './upload_live_wells_step.scss'

interface UploadLiveWellsStepProps {
  stepData: UploadLiveWellsStepData
  setStepData: (stepData: Partial<UploadLiveWellsStepData>) => void
  uploadPlateStepData: UploadPlateDataStepValidData
}

interface ParseDataRequest {
  rawData: string
}

export const getValidStepDataIfComplete = (
  wizardData: BulkLoadCulturePlatesWizardData,
): UploadLiveWellsStepValidData | null => {
  const stepData = wizardData.stepData.uploadLiveWells

  if (stepData.data === '' && stepData.parseDataResponse === null) {
    return {
      data: '',
      parseDataResponse: null,
    }
  }
  if (stepData.data !== '' && stepData.parseDataResponse !== null) {
    if (stepData.parseDataResponse.type === 'errors') {
      return null
    }
    return {
      data: stepData.data,
      parseDataResponse: stepData.parseDataResponse,
    }
  }
  return null
}

const UploadLiveWellsStep = ({
  stepData,
  setStepData,
  uploadPlateStepData,
}: UploadLiveWellsStepProps) => {
  const [dataSchemasDialogOpen, setDataSchemasDialogOpen] = useState(false)
  const {
    response: parseDataDebouncedResponse,
    debouncedAPIEndpoint: debouncedParseData,
    loading: isParsingData,
  } = useDebouncedAPIEndpoint<ParseDataRequest, BulkLoadParseWellDataResponse>(
    async (parseDataRequest: ParseDataRequest) => {
      return bulkLoadCulturePlatesAPI.parseWellData({
        data: parseDataRequest.rawData,
        culture_plates: uploadPlateStepData.parseDataResponse.culture_plates,
      })
    },
    250,
    !!stepData.data,
  )

  useEffect(() => {
    if (parseDataDebouncedResponse === null) {
      setStepData({
        parseDataResponse: null,
      })
    } else if (parseDataDebouncedResponse.type === 'response') {
      setStepData({
        parseDataResponse: parseDataDebouncedResponse.response,
      })
    } else if (parseDataDebouncedResponse.type === 'error') {
      Toaster.show({
        message: 'Error communicating with the server.',
        intent: 'danger',
      })
      setStepData({
        parseDataResponse: null,
      })
    }
  }, [parseDataDebouncedResponse])

  useEffect(() => {
    // Null responses must be passed through the debounced API call, so
    // they don't get overwritten by stale responses.
    if (stepData.data) {
      debouncedParseData({
        rawData: stepData.data,
      })
    } else {
      debouncedParseData(null)
    }
  }, [stepData.data])

  return (
    <div className={cs.uploadLiveWellsStep}>
      <div className={cs.inputColumn}>
        <div className={cs.liveWellsContainer}>
          <div className={cs.sectionTitle}>Paste Live Wells (Optional)</div>
          <Textarea
            className={cs.textarea}
            value={stepData.data}
            rows={8}
            onChange={value =>
              setStepData({
                data: value,
              })
            }
          />
          <MinimalButton
            className={cs.viewAcceptedDataSchemas}
            type='primary'
            onClick={() => setDataSchemasDialogOpen(true)}
            label='View Accepted Data Format'
            variant='normal'
          />
        </div>
      </div>
      <div className={cs.previewColumn}>
        <UploadLiveWellsStepPreview
          className={cs.preview}
          stepData={stepData}
          loading={isParsingData}
          uploadPlateStepData={uploadPlateStepData}
        />
      </div>
      <BulkLoadDataSchemasDialog
        isOpen={dataSchemasDialogOpen}
        onClose={() => setDataSchemasDialogOpen(false)}
        schema='well'
      />
    </div>
  )
}

export default UploadLiveWellsStep
