import PropTypes from 'prop-types'
import { IconProps } from './types/IconProps'
const SubtractIcon = ({ className, onClick }: IconProps) => (
  <svg
    width='24'
    height='24'
    viewBox='0 0 24 24'
    onClick={onClick}
    className={className}
  >
    <path d='M19 13H5V11H19V13Z' />
  </svg>
)

SubtractIcon.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
}

export default SubtractIcon
