import cx from 'classnames'
import { get } from 'lodash/fp'
import { InstrumentStatus } from '~/common.interface'
import cs from './instrument_status_text.scss'

interface InstrumentStatusTextProps {
  instrumentStatus: InstrumentStatus
  isExecutingControlCommand?: boolean
  additionalStatusText?: string | JSX.Element
  className?: string
}

const InstrumentStatusText = ({
  instrumentStatus,
  isExecutingControlCommand,
  additionalStatusText,
  className,
}: InstrumentStatusTextProps) => {
  const renderStatus = () => {
    const executionState = get('execution_state', instrumentStatus)
    if (!instrumentStatus) return null
    if (instrumentStatus.driver_state === 'STOPPED') {
      return <span className={cs.error}>Stopped</span>
    }
    if (instrumentStatus.driver_state === 'ERROR') {
      return <span className={cs.error}>Error</span>
    }
    if (isExecutingControlCommand || executionState === 'BUSY') {
      return <span className={cs.busy}>Busy</span>
    }
    if (!executionState) {
      return <span className={cs.busy}>Connecting</span>
    }
    if (executionState === 'FAULTED') {
      return <span className={cs.error}>Faulted</span>
    }
    if (executionState === 'DISCONNECTED') {
      return <span className={cs.error}>Connection Error</span>
    }
    return <span className={cs.ready}>Ready</span>
  }

  return (
    <div className={cx(cs.instrumentStatusText, className)}>
      Status: {renderStatus()}
      {additionalStatusText && <>&nbsp;&middot;&nbsp;{additionalStatusText}</>}
    </div>
  )
}

export default InstrumentStatusText
