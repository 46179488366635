import dayjs from 'dayjs'
import { ProcessItem } from '~/common.interface'

export interface ProcessItemLastCheckInTimeProps {
  processItem?: ProcessItem
  className?: string
}

const ProcessItemLastCheckInTime = ({
  processItem,
  className,
}: ProcessItemLastCheckInTimeProps) => {
  if (!processItem || !processItem.lastCheckInTime) return null
  return (
    <span className={className}>
      Loaded {dayjs(processItem.lastCheckInTime).fromNow()}
    </span>
  )
}

export default ProcessItemLastCheckInTime
