import { get, isString } from 'lodash/fp'
import { JsonSchema } from '~/types/JsonSchema.interface'

// JSON Schema syntax used by enums.
export const getFieldTypeObjByRef = (key: string, schema: JsonSchema) => {
  const configProperties = get('properties', schema)
  const ref =
    get([key, 'allOf', 0, '$ref'], configProperties) ||
    get([key, '$ref'], configProperties)
  if (!ref) return null

  // ref looks like "#/definitions/Sensitivity"
  return get(ref.slice(2).split('/'), schema)
}

// TODO: This return type should be an enum of field types, not a general string
export const getFieldType = (key: string, schema?: JsonSchema): string => {
  if (!schema) {
    return 'string'
  }

  const configProperties = get('properties', schema)
  // TODO: Will a non-null JSONSchema ever be missing `properties`? This check is
  // probably unnecessary, but currently biasing toward being defensive.
  if (!configProperties) {
    return 'string'
  }
  const fieldType = get([key, 'type'], configProperties)
  const fieldFormat = get([key, 'format'], configProperties)

  if (fieldFormat === 'date-time') {
    return 'string_datetime'
  }

  // Some custom fields not listed here:
  // sample_plate, assay_plate, reagent
  if (fieldType === 'array') {
    const itemType = configProperties[key]?.items?.type
    if (itemType === 'integer') {
      return 'int_array'
    } else if (itemType === 'string') {
      return 'string_array'
    } else if (itemType === 'sample_plate') {
      return 'sample_plate_array'
    }
    return 'json'
  }
  if (fieldType) return fieldType

  const typeObj = getFieldTypeObjByRef(key, schema)

  if (!typeObj) return 'json'
  if (get('enum', typeObj)) return 'enum'
  return 'json'
}

export const getFieldDescription = (key: string, schema?: JsonSchema) => {
  const configProperties = get('properties', schema)
  return get([key, 'description'], configProperties)
}

// When readOnly is true (e.g. the default is being displayed in a help popover),
// this should always be called together with getDisplayStringForField.
export const getFieldDefault = (key: string, schema?: JsonSchema, readOnly = false) => {
  const configProperties = get('properties', schema)
  const defaultValue = configProperties?.[key]?.default
  if (defaultValue === undefined || defaultValue === null) {
    return null
  }

  // Don't display the smart default when editing, e.g. in Input elements.
  if (isSmartDefault(defaultValue)) {
    if (!readOnly) {
      return ''
    }

    // Smart defaults are converted to a more human-readable form in getDisplayStringForField.
  }

  return defaultValue
}

export const getFieldConstraints = (key: string, schema?: JsonSchema) => {
  const configProperties = schema?.properties
  return configProperties?.[key]?.constraints
}

export const getFieldSchemaProperty = (
  key: string,
  property: string,
  schema?: JsonSchema,
) => {
  const configProperties = get('properties', schema)
  return get([key, property], configProperties)
}

// All default values that have template strings are smart defaults.
export const isSmartDefault = (defaultValue: unknown) => {
  return isTemplateString(defaultValue)
}

export const isTemplateString = (value: unknown) => {
  return isString(value) && value.startsWith('{') && value.endsWith('}')
}

// Display the smart default template string in a more human-readable form.
export const getSmartDefaultDisplay = defaultValue => {
  let match
  if ((match = /{get_live_wells_of_sample_plate\((.*)\)}/.exec(defaultValue))) {
    return `Live wells of ${match[1]}`
  }
  if ((match = /{get_live_columns_of_sample_plate\((.*)\)}/.exec(defaultValue))) {
    return `Live columns of ${match[1]}`
  }
  if ((match = /{get_live_wells_of_sample_plates\((.*)\)}/.exec(defaultValue))) {
    return `Live wells of all ${match[1]}`
  }
  return defaultValue
}
