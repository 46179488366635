import { filter, includes, reverse, sortBy } from 'lodash/fp'
import memoize from 'memoize-one'
import { useContext, useEffect, useState } from 'react'
import processItemsAPI from '~/api/desktop/processItems'
import { ProcessItem } from '~/common.interface'
import MultiSelect from '~/components/MultiSelect'
// TODO: Find a better way to share these styles than stealing
import WorkcellStatusContext from '~/pages/Workcell/WorkcellStatusContext'
import { getInstrumentNames } from '~/pages/Workcell/utils/workcellStatus'
import { getComparatorStringForShallowObject } from '~/utils/array'
import { processItemMatchesInstrumentNames } from '~/utils/processItems/common'
import { ProcessItemFilters } from './ProcessItemFilters'
import { ProcessItemSelectOption } from './ProcessItemSelectOption'
import { getProcessItemFiltersWithDefaults } from './getProcessItemFiltersWithDefaults'
import { processItemMatchesQuery } from './processItemMatchesQuery'

interface SharedProps {
  renderProcessItemViz: boolean

  placeholder?: string
  disabled?: boolean
}

type ProcessItemMultiSelectProps = SharedProps & {
  selectableItems: ProcessItem[] | null
  selectedItems: ProcessItem[]
  setSelectableItems: (processItems: ProcessItem[]) => void
  onItemsUpdate(processItems: ProcessItem[]): void
}

const orderProcessItems = memoize((items: ProcessItem[]): ProcessItem[] =>
  reverse(sortBy(item => item.lastCheckInTime || '', items)),
)

export function ProcessItemMultiSelect(
  props: ProcessItemMultiSelectProps,
): JSX.Element {
  return (
    <MultiSelect<ProcessItem>
      itemRenderer={item => (
        <ProcessItemSelectOption
          processItem={item}
          renderProcessItemViz={props.renderProcessItemViz}
        />
      )}
      selectedItems={props.selectedItems}
      placeholder={'Select Process Items ...'}
      // TODO Remove/reconsider this
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      selectableItems={orderProcessItems(props.selectableItems!)}
      // TODO: Probably choose a better component for this
      tagRenderer={item => item.uuid}
      disabled={props.disabled}
      itemPredicate={(query, item) =>
        processItemMatchesQuery(item, query.toLocaleLowerCase())
      }
      itemKey='uuid'
      itemLabelKey='uuid'
      onSelectedItemsUpdate={props.onItemsUpdate}
    />
  )
}

// TODO: This typedef isn't good because renderProcessItemViz has a default here
type ManagedProcessItemMultiSelectProps = SharedProps & {
  onSelectedItemUuidsUpdate: (processItemUuids: string[]) => void
  processItemFilters?: ProcessItemFilters
  selectedItemUuids: string[]
}

export function ManagedProcessItemMultiSelect(
  props: ManagedProcessItemMultiSelectProps,
): JSX.Element {
  const workcellStatus = useContext(WorkcellStatusContext)
  const instrumentNames = getInstrumentNames(workcellStatus)
  const [processItems, setProcessItems] = useState<ProcessItem[]>([])

  const fetchProcessItems = async () => {
    const _processItems = await processItemsAPI.getProcessItems(
      getProcessItemFiltersWithDefaults(props.processItemFilters || {}),
    )

    return filter(
      processItem => processItemMatchesInstrumentNames(processItem, instrumentNames),
      _processItems,
    )
  }

  useEffect(() => {
    fetchProcessItems().then(items => {
      setProcessItems(items)
      props.onSelectedItemUuidsUpdate([])
    })
  }, [getComparatorStringForShallowObject(props.processItemFilters)])

  const selectedItems = processItems.filter(item =>
    includes(item.uuid, props.selectedItemUuids),
  )

  const handleSelectedItemsUpdate = (items: ProcessItem[]) => {
    props.onSelectedItemUuidsUpdate(items.map(item => item.uuid))
  }

  return (
    <ProcessItemMultiSelect
      selectedItems={selectedItems}
      selectableItems={processItems}
      setSelectableItems={setProcessItems}
      onItemsUpdate={handleSelectedItemsUpdate}
      renderProcessItemViz
      placeholder={props.placeholder}
      disabled={props.disabled}
    />
  )
}
