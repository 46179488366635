import PropTypes from 'prop-types'
import { IconProps } from './types/IconProps'

const UnfoldMoreIcon = ({ className, onClick }: IconProps) => (
  <svg
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    className={className}
    onClick={onClick}
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M15 15.1699L18.17 11.9999L15 8.82991L16.41 7.40991L21 11.9999L16.41 16.5799L15 15.1699ZM9 8.82991L5.83 11.9999L9 15.1699L7.59 16.5899L3 11.9999L7.59 7.41991L9 8.82991Z'
    />
  </svg>
)

UnfoldMoreIcon.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
}

export default UnfoldMoreIcon
