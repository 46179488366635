import { ProcessItem } from '~/common.interface'
import ProcessItemContiguousEdit from '~/pages/Workcell/components/processItem/ProcessItemContiguousEdit'
import { ProcessItemMultiWellEdit } from '~/pages/Workcell/components/processItem/ProcessItemMultiWellEdit'

interface ProcessItemVizEditableProps {
  className?: string
  processItem?: ProcessItem
  onCurrentIndexUpdate?(hoverIndex?: number): void
  onWellArrayUpdate?(wellArray: string[]): void
}

const ProcessItemVizEditable = ({
  className,
  processItem,
  onCurrentIndexUpdate,
  onWellArrayUpdate,
}: ProcessItemVizEditableProps) => {
  if (processItem) {
    // We currently only support either Contiguous or MultiWell edits depending on
    // the plate-type. In the future, we may support both for all plate-types
    switch (processItem.type) {
      case 'reagent_plate':
      case 'tiprack':
        if (onCurrentIndexUpdate == undefined) {
          throw new Error('Unable to render component due to missing update function')
        }
        return (
          <ProcessItemContiguousEdit
            processItem={processItem}
            className={className}
            onCurrentIndexUpdate={onCurrentIndexUpdate}
          />
        )
      case 'culture_plate':
        if (onWellArrayUpdate == undefined) {
          throw new Error('Unable to render component due to missing update function')
        }
        return (
          <ProcessItemMultiWellEdit
            className={className}
            processItem={processItem}
            onWellArrayUpdate={onWellArrayUpdate}
          />
        )
      default:
        throw new Error(`Process Item Viz not implemented for ${processItem.type}`)
    }
  } else {
    return null
  }
}

export default ProcessItemVizEditable
