import { useEffect, useState } from 'react'
import { LinkLotsParseDataResponse } from '~/api/operatorActions/linkCellLineLotsToCultures'
import linkCellLineLotsToCulturesAPI from '~/api/operatorActions/linkCellLineLotsToCultures'
import Textarea from '~/components/Textarea'
import Toaster from '~/components/Toaster'
import MinimalButton from '~/components/buttons/MinimalButton'
import useDebouncedAPIEndpoint from '~/utils/hooks/useDebouncedAPIEndpoint'
import {
  ConfirmUpdatesStepData,
  LinkCellLineLotsToCulturesWizardData,
  UploadDataStepValidData,
  UploadDataStepData as UploadMetadataStepData,
} from './LinkCellLineLotsToCulturesWizardData.interface'
import LinkLotsDataSchemasDialog from './LinkLotsDataSchemasDialog'
import LookupErrorsInlineDialog from './LookupErrorsInlineDialog'
import UploadDataStepPreview from './UploadMetadataStepPreview'
import cs from './upload_metadata_step.scss'

interface UploadMetadataStepProps {
  stepData: UploadMetadataStepData
  setStepData: (stepData: Partial<UploadMetadataStepData>) => void
  confirmUpdatesStepData: ConfirmUpdatesStepData
  clearConfirmUpdatesStepData: () => void
}

export const getValidStepDataIfComplete = (
  wizardData: LinkCellLineLotsToCulturesWizardData,
): UploadDataStepValidData | null => {
  const stepData = wizardData.stepData.uploadMetadata
  if (
    stepData.data !== '' &&
    stepData.parseDataResponse !== null &&
    stepData.parseDataResponse.type !== 'errors' &&
    !(
      wizardData.stepData.confirmUpdates &&
      wizardData.stepData.confirmUpdates.lookupDataResponse &&
      wizardData.stepData.confirmUpdates.lookupDataResponse.type === 'errors'
    )
  )
    return {
      data: stepData.data,
      parseDataResponse: stepData.parseDataResponse,
    }
  return null
}

interface ParseDataRequest {
  rawData: string
}

const UploadMetadataStep = ({
  stepData,
  setStepData,
  confirmUpdatesStepData,
  clearConfirmUpdatesStepData,
}: UploadMetadataStepProps) => {
  const [dataSchemasDialogOpen, setDataSchemasDialogOpen] = useState(false)
  const {
    response: parseDataDebouncedResponse,
    debouncedAPIEndpoint: debouncedParseData,
    loading: isParsingData,
  } = useDebouncedAPIEndpoint<ParseDataRequest, LinkLotsParseDataResponse>(
    async (parseDataRequest: ParseDataRequest) => {
      return linkCellLineLotsToCulturesAPI.parseData({
        data: parseDataRequest.rawData,
      })
    },
    250,
    !!stepData.data,
  )

  useEffect(() => {
    if (parseDataDebouncedResponse === null) {
      setStepData({
        parseDataResponse: null,
      })
    } else if (parseDataDebouncedResponse.type === 'response') {
      setStepData({
        parseDataResponse: parseDataDebouncedResponse.response,
      })
    } else if (parseDataDebouncedResponse.type === 'error') {
      Toaster.show({
        message: 'Error communicating with the server.',
        intent: 'danger',
      })
      setStepData({
        parseDataResponse: null,
      })
    }
  }, [parseDataDebouncedResponse])

  useEffect(() => {
    // Null responses must be passed through the debounced API call, so
    // they don't get overwritten by stale responses.
    debouncedParseData(stepData.data ? { rawData: stepData.data } : null)
  }, [stepData.data])

  return (
    <div className={cs.uploadMetadataStep}>
      <div className={cs.inputColumn}>
        <div className={cs.sectionTitle}>Paste Culture Metadata</div>
        <Textarea
          className={cs.textarea}
          value={stepData.data}
          rows={8}
          onChange={value =>
            setStepData({
              data: value,
            })
          }
        />
        <MinimalButton
          className={cs.viewAcceptedDataSchemas}
          type='primary'
          onClick={() => setDataSchemasDialogOpen(true)}
          label='View Accepted Data Formats'
          variant='normal'
        />
      </div>
      <div className={cs.previewColumn}>
        {confirmUpdatesStepData.lookupDataResponse &&
        confirmUpdatesStepData.lookupDataResponse.type === 'errors' ? (
          <LookupErrorsInlineDialog
            lookupErrorResponse={confirmUpdatesStepData.lookupDataResponse}
            onClose={clearConfirmUpdatesStepData}
            className={cs.inlineDialog}
          />
        ) : (
          <UploadDataStepPreview
            className={cs.preview}
            loading={isParsingData}
            parseDataResponse={stepData.parseDataResponse}
          />
        )}
      </div>
      <LinkLotsDataSchemasDialog
        isOpen={dataSchemasDialogOpen}
        onClose={() => setDataSchemasDialogOpen(false)}
      />
    </div>
  )
}

export default UploadMetadataStep
