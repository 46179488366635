import { useState } from 'react'

import { ProcessItem } from '~/common.interface'
import { LoadUnloadItemExperimentPlateParams } from '~/pages/Workcell/OperatorActions/LoadUnloadItems/LoadUnloadItemsProcessItemParams.interface'
import { ExperimentPlateMapSelect } from '~/pages/Workcell/OperatorActions/LoadUnloadItems/experimentPlates/ExperimentPlateMapSelect'
import { ExperimentPlateLoadParamsRequest } from '~/pages/Workcell/OperatorActions/reloadItems/convertChangeToReloadOperation'
import { ExperimentPlateMap } from '~/pages/Workcell/ProcessItems/PlateMap'
import { ManagedProcessItemSelect } from '~/pages/Workcell/components/processItem/ProcessItemSelect/ManagedProcessItemSelect'
import { getPlateFormat } from '~/utils/processItems/common'
import { LabwareSelect } from '../LabwareSelect'
import { LoadParamsRequestOrError } from '../LoadParamsRequestOrError.interface'
import { getExperimentPlateExperimentRequest } from '../experimentPlates/getExperimentPlateExperimentRequest'
import { LoadUnloadItemsProcessItemFormComponentProps } from './LoadUnloadItemsProcessItemFormComponentProps.interface'
import cs from './load_unload_items_experiment_plate_form.scss'

const LoadUnloadItemsExperimentPlateForm = ({
  className,
  config,
  processItemParams,
  setProcessItemParams,
  disabled,
}: LoadUnloadItemsProcessItemFormComponentProps<'experiment_plate'>) => {
  const [lastSelectedLabwareName, setLastSelectedLabwareName] = useState<
    string | undefined
  >(undefined)

  return (
    <div className={className}>
      <ManagedProcessItemSelect
        label='Select Sample Plate'
        selectedProcessItem={processItemParams.selectedSamplePlate || null}
        onProcessItemSelected={(processItem: ProcessItem | null) => {
          setProcessItemParams(
            (
              _latestProcessItemParams: Partial<LoadUnloadItemExperimentPlateParams>,
            ) => ({
              ..._latestProcessItemParams,
              selectedSamplePlate: processItem || undefined,
            }),
          )
        }}
        disabled={disabled}
        processItemFilters={{
          types: ['culture_plate'],
        }}
      />
      <LabwareSelect
        processItemType='experiment_plate'
        className={cs.labwareSelect}
        defaultLabwareName={lastSelectedLabwareName}
        selectedLabware={processItemParams.selectedLabware}
        onLabwareSelect={labware => {
          setLastSelectedLabwareName(labware?.labware_name)
          setProcessItemParams(
            (
              _latestProcessItemParams: Partial<LoadUnloadItemExperimentPlateParams>,
            ) => ({
              ..._latestProcessItemParams,
              selectedLabware: labware,
            }),
          )
        }}
        triggerClassName={cs.trigger}
        popoverClassName={cs.popover}
        disabled={disabled}
      />
      <ExperimentPlateMapSelect
        fromPlateFormat={processItemParams.selectedLabware?.plate_format}
        toPlateFormat={
          processItemParams.selectedSamplePlate
            ? getPlateFormat(processItemParams.selectedSamplePlate)
            : undefined
        }
        hideSelect={!config.enableExperimentPlateMapSelect}
        className={cs.plateMapSelect}
        wide
        defaultPlateMapName={config.defaultExperimentPlateMapName}
        selectedPlateMap={processItemParams.selectedPlateMap}
        onSelectPlateMap={(plateMap: ExperimentPlateMap | undefined) => {
          setProcessItemParams(
            (
              _latestProcessItemParams: Partial<LoadUnloadItemExperimentPlateParams>,
            ) => ({
              ..._latestProcessItemParams,
              selectedPlateMap: plateMap,
            }),
          )
        }}
        disabled={disabled}
      />
    </div>
  )
}

export const getExperimentPlateLoadParamsRequestOrError = (
  processItemParams: Partial<LoadUnloadItemExperimentPlateParams>,
): LoadParamsRequestOrError => {
  if (!processItemParams.selectedSamplePlate) {
    return {
      error: 'Please select a sample plate.',
    }
  }
  if (!processItemParams.selectedLabware) {
    return {
      error: 'Please select a labware.',
    }
  }

  if (!processItemParams.selectedPlateMap) {
    return {
      error: 'Please select an experiment plate map.',
    }
  }

  return {
    params: {
      labware_name: processItemParams.selectedLabware.labware_name,
      experiments: getExperimentPlateExperimentRequest(
        processItemParams.selectedSamplePlate,
        processItemParams.selectedPlateMap,
      ),
    } as ExperimentPlateLoadParamsRequest,
  }
}

export default LoadUnloadItemsExperimentPlateForm
