import { includes } from 'lodash/fp'
import demoScheduleContinuousCulture from '~/api/operatorActions/demoScheduleContinuousCulture'
import { RoutineParametersForm } from '~/pages/Workcell/Routines/RoutineParametersForm'
import { ManagedProcessItemSelect } from '~/pages/Workcell/components/processItem/ProcessItemSelect/ManagedProcessItemSelect'
import LoadingActionMessage from '../../LoadingActionMessage'
import {
  OperatorActionConfigProps,
  PresentationalScheduleOperatorAction,
  ScheduleOperatorActionWithError,
} from '../../Schedule/PresentationalScheduleOperatorAction'
import { useEnabledAndUnusedOperatorActionNames } from '../../useEnabledAndUnusedOperatorActionNames'
import useOperatorActionConfig from '../../utils/useOperatorActionConfig'
import cs from './schedule_continuous_culture_action.scss'
import { useContinuousCultureRequestAssembler } from './useContinuousCultureRequestAssembler'

// For now, this is hardcoded to expect specific routine names
const ROUTINE_NAMES = ['Demo Media Exchange', 'Demo Image Culture Plate']

type ScheduleContinuousCultureConfig = {
  displayName: string
  displayDescription: string
}

const OPERATOR_ACTION_NAME = 'demo_schedule_continuous_culture'

export function ScheduleContinuousCultureAction(): JSX.Element | null {
  const { enabledOperatorActionNames } = useEnabledAndUnusedOperatorActionNames()
  const { config, handleConfigUpdate } =
    useOperatorActionConfig<ScheduleContinuousCultureConfig>(OPERATOR_ACTION_NAME)

  if (!includes(OPERATOR_ACTION_NAME, enabledOperatorActionNames)) {
    return null
  }

  if (!config) {
    return <LoadingActionMessage />
  }

  const operatorActionConfig = {
    operatorActionName: OPERATOR_ACTION_NAME,
    config,
    onConfigUpdate: handleConfigUpdate,
  }

  return (
    <ConfiguredContinuousCultureAction operatorActionConfig={operatorActionConfig} />
  )
}

interface ConfiguredContinuousCultureActionProps {
  operatorActionConfig: OperatorActionConfigProps<ScheduleContinuousCultureConfig>
}

function ConfiguredContinuousCultureAction({
  operatorActionConfig,
}: ConfiguredContinuousCultureActionProps) {
  const requestAssembler = useContinuousCultureRequestAssembler(ROUTINE_NAMES)

  if (requestAssembler.isLoading) {
    return <LoadingActionMessage />
  } else if (requestAssembler.errorMessage != null) {
    return (
      <ScheduleOperatorActionWithError
        operatorActionConfig={operatorActionConfig}
        title={'Schedule Continuous Culture'}
        message={requestAssembler.errorMessage}
      />
    )
  }

  return (
    <PresentationalScheduleOperatorAction
      routineTitle={'Continuous Culture'}
      submitProps={{
        taskEndpoint:
          // TODO(damon): Doing this more directly causes the types to fail - I think because
          // technically perhaps when the callback is executed, `requestAssembler` may have
          // changed? Not sure whether this is necessarily a pattern we want to encourage.
          requestAssembler.scheduleRequest != null
            ? (
                scheduleRequest => () =>
                  demoScheduleContinuousCulture.submit(scheduleRequest)
              )(requestAssembler.scheduleRequest)
            : null,
        onSuccess: () => requestAssembler.reset(),
        label: 'Schedule Routines',
      }}
      operatorActionConfig={operatorActionConfig}
    >
      <div className={cs.formContainer}>
        <ManagedProcessItemSelect
          selectedProcessItem={requestAssembler.culturePlate}
          onProcessItemSelected={requestAssembler.setCulturePlate}
          processItemFilters={{ types: ['culture_plate'] }}
          label='Culture Plate'
        />
      </div>
      {requestAssembler.routineFormPropsArray.map(formProps => (
        <div className={cs.formContainer} key={formProps.routineDefinitionWithDSL.name}>
          <div className={cs.sectionHeader}>
            {formProps.routineDefinitionWithDSL.name.replace('Demo ', '')}
          </div>
          <RoutineParametersForm {...formProps} />
        </div>
      ))}
    </PresentationalScheduleOperatorAction>
  )
}
