import { isNumber } from 'lodash'
import { get, map, range, set, size } from 'lodash/fp'
import { useContext, useEffect, useState } from 'react'

import desktop from '~/api/desktop'
import workcellAPI from '~/api/desktop/workcell'
import Dialog from '~/components/Dialog'
import Input from '~/components/Input'
import LoadingMessage from '~/components/LoadingMessage'
import StructuredDataForm from '~/components/StructuredDataForm'
import Textarea from '~/components/Textarea'
import Toaster from '~/components/Toaster'
import WorkcellStatusContext from '~/pages/Workcell/WorkcellStatusContext'
import { getRoutineIds } from '~/pages/Workcell/utils/workcellStatus'

import TinyNotification from '~/components/notifications/TinyNotification'
import { JsonSchema } from '~/types/JsonSchema.interface'
import { RoutineDefinition } from '../../types/RoutineDefinition.interface'
import cs from './schedule_routine_dialog.scss'

export interface ScheduleRoutineDialogProps {
  isOpen: boolean
  onClose: () => void
  routine: RoutineDefinition
  onScheduleSuccess: () => void
}

const ScheduleRoutineDialog = ({ isOpen, onClose, routine, onScheduleSuccess }) => {
  const [routineParameters, setRoutineParameters] = useState({})
  const [routineParametersJson, setRoutineParametersJson] = useState('')
  const [repeats, setRepeats] = useState(1)
  const [loadingRoutineJson, setLoadingRoutineJson] = useState(false)
  const [loadingRoutineError, setLoadingRoutineError] = useState('')
  const [showQC, setShowQC] = useState(false)
  const [routineJson, setRoutineJson] = useState(null)
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)
  const workcellStatus = useContext(WorkcellStatusContext)

  const isLive = get('live', workcellStatus)
  const hasRoutines = size(getRoutineIds(workcellStatus)) !== 0

  const fetchTemplate = async () => {
    setRoutineJson(null)
    setLoadingRoutineError('')
    if (!routine) return
    setLoadingRoutineJson(true)
    try {
      const response = await desktop.getRoutineDefinitionTemplateJson(routine.id)
      setRoutineJson(response)
    } catch (e) {
      setLoadingRoutineError('Could not load routine definition JSON')
    }
    setLoadingRoutineJson(false)
  }

  useEffect(() => {
    fetchTemplate()
  }, [routine])

  useEffect(() => {
    setRoutineParameters({})
    setRoutineParametersJson('')
    setRepeats(1)
    setShowQC(false)
    setError('')
  }, [isOpen])

  // TODO: Refactor this with RoutineParametersForm's getRoutineSchema.
  const getRoutineSchema = () => {
    // The inputs field is almost like an OpenAPI schema. Just enclose it in 'properties'.
    const inputs: JsonSchema['properties'] | undefined = get(
      ['templateJson', 'inputs'],
      routineJson,
    )

    if (inputs) {
      return {
        properties: inputs,
      }
    }

    return null
  }

  const handleSubmit = async () => {
    let parameters = {}

    const schema = getRoutineSchema()

    if (schema) {
      parameters = routineParameters
    } else {
      try {
        parameters = JSON.parse(routineParametersJson)
        setError('')
      } catch (e) {
        setError('Invalid JSON')
        return
      }
    }

    setLoading(true)

    const routines = map(
      () => ({
        name: routine.name,
        parameters,
      }),
      range(0, repeats || 1),
    )

    try {
      const response = await workcellAPI.bulkScheduleRoutines(routines, false)
      Toaster.show({
        message: `Scheduled ${response.succeeded.length} routine (${response.failed.length} failed)`,
        intent: response.failed.length > 0 ? 'warning' : 'success',
      })
      onClose()
      setLoading(false)
      setRoutineParametersJson('')
      setRoutineParameters({})
      onScheduleSuccess()
    } catch (e) {
      setError(String(e))
      setLoading(false)
    }
  }

  const handleRepeatsUpdate = (number: string) => {
    const ans = JSON.parse(number)
    if (!isNumber(ans)) {
      setError('repeats must be number')
      return
    }
    if (ans <= 0) {
      setError('repeats must be greater than 0')
      return
    }
    setRepeats(parseInt(number))
  }

  const handleRoutineParametersUpdate = (key: string, value: unknown) => {
    setRoutineParameters(set(key, value, routineParameters))
  }

  const renderFooter = () => {
    let errorMessage: string | null = null
    if (error) {
      errorMessage = error
    } else if (isLive) {
      errorMessage = 'Cannot schedule routines while workcell is live.'
    } else if (hasRoutines) {
      errorMessage =
        'Cannot schedule additional routines. Workcell already has routines scheduled.'
    }

    return (
      <Dialog.Footer
        error={loading ? null : errorMessage}
        loadingMessage={loading ? 'Scheduling Routine' : undefined}
      >
        <Dialog.FooterButton label='Cancel' onClick={onClose} />
        <Dialog.FooterButton label='Schedule' onClick={handleSubmit} type='primary' />
      </Dialog.Footer>
    )
  }

  const renderInputs = () => {
    if (!routineJson) {
      return (
        <div className={cs.placeholder}>
          <LoadingMessage label='Loading routine definition...' />
        </div>
      )
    }

    const schema = getRoutineSchema()

    if (schema) {
      if (size(schema.properties) === 0) {
        return <div className={cs.noParametersMessage}>No parameters required.</div>
      }
      return (
        <StructuredDataForm
          className={cs.inputFields}
          dataSchema={schema}
          data={routineParameters}
          onEdit={handleRoutineParametersUpdate}
          fieldClassName={cs.row}
          inputsClassName={cs.inputs}
        />
      )
    }

    return (
      <>
        <div className={cs.label}>Routine parameters (JSON)</div>
        <Textarea
          className={cs.textarea}
          value={routineParametersJson}
          rows={8}
          onChange={setRoutineParametersJson}
        />
      </>
    )
  }

  const renderQCInputs = () => {
    return (
      <div>
        <div className={cs.qcToggle} onClick={() => setShowQC(!showQC)}>
          {showQC ? 'Hide' : 'Show'} QC Options...
        </div>
        {showQC && (
          <Input
            label='Repeat Multiple Times'
            className={cs.repeatsInput}
            inputClassName={cs.inputs}
            value={repeats.toString()}
            onChange={handleRepeatsUpdate}
            changeOnBlur
          />
        )}
      </div>
    )
  }

  const renderContents = () => {
    if (loadingRoutineJson) {
      return (
        <div className={cs.container}>
          <LoadingMessage
            label='Loading Routine Definition...'
            className={cs.loadingMessageBox}
          />
        </div>
      )
    }
    if (loadingRoutineError) {
      return (
        <div className={cs.container}>
          <TinyNotification
            className={cs.error}
            message={loadingRoutineError}
            type='bareError'
          />
        </div>
      )
    }

    return (
      <>
        {renderInputs()}
        {renderQCInputs()}
        {renderFooter()}
      </>
    )
  }

  return (
    <Dialog isOpen={isOpen} onClose={onClose} className={cs.scheduleRoutineDialog}>
      <div className={cs.title}>Schedule Routine: {get('name', routine)}</div>
      {renderContents()}
    </Dialog>
  )
}

export default ScheduleRoutineDialog
