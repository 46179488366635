import cx from 'classnames'
import { get } from 'lodash/fp'

import HelpPopover from '~/components/HelpPopover'
import PropTypes from '~/propTypes'

import { Instrument, InstrumentStatus } from '~/common.interface'
import cs from './instrument_name_with_status.scss'

interface InstrumentNameWithStatusProps {
  className?: string
  instrument: Instrument
  instrumentStatus: InstrumentStatus
  big?: boolean
}

const InstrumentNameWithStatus = ({
  className,
  instrument,
  instrumentStatus,
  big,
}: InstrumentNameWithStatusProps) => {
  let header = ''
  let message = ''
  let statusType: 'error' | 'warning' | undefined = undefined
  if (instrumentStatus) {
    message = get(['instrument_messages', 0], instrumentStatus)
    if (get('driver_state', instrumentStatus) === 'STOPPED') {
      header = 'Instrument is stopped'
      statusType = 'error'
    } else if (get('driver_state', instrumentStatus) === 'UNREACHABLE') {
      header = 'Instrument is unreachable'
      statusType = 'error'
    } else if (get('driver_state', instrumentStatus) === 'ERROR') {
      header = 'Instrument error'
      statusType = 'error'
    } else if (get('execution_state', instrumentStatus) === 'FAULTED') {
      header = 'Instrument is faulted'
      statusType = 'error'
    } else if (get('execution_state', instrumentStatus) === 'DISCONNECTED') {
      header = 'Instrument is disconnected'
      statusType = 'error'
    } else if (get('is_simulated', instrumentStatus)) {
      header = 'Instrument is in simulation mode'
      statusType = 'warning'
    }
  }

  const popover = (
    <div className={cs.popover}>
      <div className={cx(cs.header, statusType && cs[statusType])}>{header}</div>
      {message && <div className={cs.message}>{message}</div>}
    </div>
  )

  const classNames = cx(
    className,
    cs.instrumentNameWithStatus,
    big ? cs.big : cs.normal,
    statusType && cs[statusType],
  )

  if (header) {
    return (
      <HelpPopover
        className={classNames}
        type={statusType}
        text={instrument.instrumentName}
        elementType='div'
        helpContent={popover}
        smallText={false}
        interactionKind='hover'
      />
    )
  }
  return <div className={classNames}>{instrument.instrumentName}</div>
}

InstrumentNameWithStatus.propTypes = {
  className: PropTypes.string,
  instrument: PropTypes.Instrument,
  // Instrument status is optional.
  instrumentStatus: PropTypes.InstrumentStatus,
}

export default InstrumentNameWithStatus
