import { Popover2 } from '@blueprintjs/popover2'
import cx from 'classnames'

import ProcessItemPopover from '~/pages/Workcell/components/processItem/ProcessItemPopover'
import PropTypes from '~/propTypes'
import {
  getProcessItemPercentFilled,
  getShortDisplayName,
  isCulturePlate,
  isDeadCulturePlate,
  isEditable,
  isExistingItemPlaceholder,
  isExperimentPlate,
  isPooledConsumable,
  isProcessItem,
} from '~/utils/processItems/common'

import { ProcessItem, ProcessItemLike, ProcessItemLocation } from '~/common.interface'
import cs from './process_item_viz_slot.scss'

export type ProcessItemVizSlotColorType = 'highlight' | 'invalid' | 'unselectable'

export type ProcessItemVizSlotSize =
  | 'large'
  | 'ot2DeckSlot'
  | 'mini'
  | 'micro'
  | 'normal'
  | 'tecanDeckSlot'

interface ProcessItemVizSlotProps {
  className?: string
  processItem?: ProcessItemLike
  processItemLocation?: ProcessItemLocation
  onClick?: (processItem: ProcessItemLike | undefined) => void
  onPopoverEditClick?: (processItem: ProcessItem) => void
  additionalLabelInfo?: string
  isHidden?: boolean
  shouldDisplayAddIcon?: boolean
  shouldDisplayRemoveIcon?: boolean
  size: ProcessItemVizSlotSize
  colorType?: ProcessItemVizSlotColorType
  disabled?: boolean
}

const ProcessItemVizSlot = ({
  className,
  processItem,
  processItemLocation,
  onClick,
  onPopoverEditClick,
  additionalLabelInfo,
  isHidden,
  shouldDisplayAddIcon,
  shouldDisplayRemoveIcon,
  size,
  colorType,
  disabled,
}: ProcessItemVizSlotProps) => {
  // Culture plates do not have a bar and ignore the bar styling in the css file.
  const getSlotColorClasses = () => {
    if (disabled) {
      // unselectable slots have stripes, which we want to show even if the slot is disabled.
      if (colorType === 'unselectable') {
        return [cs.disabled, cs.unselectable]
      }

      return [cs.disabled]
    }
    // Handle color types.
    if (colorType === 'invalid') return [cs.invalid]

    const colorClasses: string[] = []

    if (processItem) {
      if (isProcessItem(processItem)) {
        if (isPooledConsumable(processItem) || isExperimentPlate(processItem)) {
          if (getProcessItemPercentFilled(processItem) === 0) {
            colorClasses.push(cs.emptyConsumable)
          } else {
            colorClasses.push(cs.consumable)
          }
        } else if (isCulturePlate(processItem)) {
          if (isDeadCulturePlate(processItem)) {
            colorClasses.push(cs.emptyConsumable)
          } else {
            colorClasses.push(cs.culturePlate)
          }
        } else {
          colorClasses.push(cs.processItem)
        }
      } else if (
        isExistingItemPlaceholder(processItem) &&
        processItem.existingItemType === 'culture_plate'
      ) {
        colorClasses.push(cs.culturePlate)
      } else {
        // NewProcessItemPlaceholder | StringOnlyProcessItem
        colorClasses.push(cs.stringOnlyProcessItem)
      }
    } else {
      colorClasses.push(cs.emptySlot)
    }

    if (colorType === 'highlight') {
      colorClasses.push(cs.highlight)
    } else if (colorType === 'unselectable') {
      colorClasses.push(cs.unselectable)
    } else {
      colorClasses.push(cs.defaultColor)
    }
    return colorClasses
  }

  const renderBar = (_processItem: ProcessItemLike) => {
    if (
      isProcessItem(_processItem) &&
      (isPooledConsumable(_processItem) || isExperimentPlate(_processItem))
    ) {
      const percentFilled = getProcessItemPercentFilled(_processItem)
      return (
        <div className={cs.bar}>
          <div
            className={cs.barInner}
            style={{
              height: `${percentFilled}%`,
            }}
          />
        </div>
      )
    }
    return null
  }

  const slot = (
    <div
      className={cx(
        className,
        cs.slot,
        ...getSlotColorClasses(),
        isHidden && cs.hidden,
        onClick && cs.clickable,
        (shouldDisplayAddIcon || shouldDisplayRemoveIcon) && cs.displayIconOnHover,
        disabled && cs.disabled,
      )}
      onClick={() => onClick && !disabled && onClick(processItem)}
    >
      <div className={cs.slotInner}>
        {processItem && (
          <>
            {renderBar(processItem)}
            <div className={cs.name}>{getShortDisplayName(processItem)}</div>
          </>
        )}
      </div>
      {shouldDisplayAddIcon && <div className={cs.slotInnerAddIcon}>+</div>}
      {shouldDisplayRemoveIcon && <div className={cs.slotInnerRemoveIcon}>x</div>}
    </div>
  )

  if (isHidden || colorType === 'invalid') {
    return <div className={cx(cs.processItemVizSlot, cs[size])}>{slot}</div>
  }

  return (
    <Popover2
      content={
        <ProcessItemPopover
          item={processItem}
          processItemLocation={processItemLocation}
          onEditClick={
            onPopoverEditClick && processItem && isEditable(processItem)
              ? () => onPopoverEditClick(processItem)
              : undefined
          }
          additionalLabelInfo={additionalLabelInfo}
        />
      }
      interactionKind='hover'
      placement='right'
      className={cx(cs.processItemVizSlot, cs[size])}
      hoverOpenDelay={50}
      hoverCloseDelay={0}
      minimal
      openOnTargetFocus={false}
    >
      {slot}
    </Popover2>
  )
}

ProcessItemVizSlot.propTypes = {
  className: PropTypes.string,
  processItem: PropTypes.ProcessItem,
  onClick: PropTypes.func,
  onPopoverEditClick: PropTypes.func,
  additionalLabelInfo: PropTypes.string,
  isHidden: PropTypes.bool,
  shouldDisplayAddIcon: PropTypes.bool,
  shouldDisplayRemoveIcon: PropTypes.bool,
  processItemLocation: PropTypes.ProcessItemLocation,
  additionalStyle: PropTypes.shape({}),
  colorType: PropTypes.oneOf(['highlight', 'invalid', 'unselectable']),
  size: PropTypes.oneOf([
    'large',
    'ot2DeckSlot',
    'micro',
    'mini',
    'normal',
    'tecanDeckSlot',
  ]),
}

ProcessItemVizSlot.defaultProps = {
  size: 'normal',
}

export default ProcessItemVizSlot
