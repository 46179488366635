import { set } from 'lodash/fp'
import { useEffect, useState } from 'react'

import integrationsAPI from '~/api/integrations'
import Dialog from '~/components/Dialog'
import Input from '~/components/Input'
import Button from '~/components/buttons/Button'

import { ScispotIntegration } from '~/types/Integration.interface'
import cs from './configure_scispot_dialog.scss'

interface ConfigureScispotDialogProps {
  open: boolean
  onClose: () => void
  integration: ScispotIntegration
  onIntegrationUpdate: (integration: ScispotIntegration) => void
}

const ConfigureScispotDialog = ({
  open,
  onClose,
  integration,
  onIntegrationUpdate,
}: ConfigureScispotDialogProps) => {
  const default_scispot_api_url = 'https://api.scispot.io'
  const [apiUrl, setApiUrl] = useState(default_scispot_api_url)
  const [apiKey, setApiKey] = useState('')

  useEffect(() => {
    if (integration) {
      setApiUrl(integration.settings.apiUrl || default_scispot_api_url)
      setApiKey(integration.settings.apiKey || '')
    }
  }, [integration])

  const update = async () => {
    onClose()
    await integrationsAPI.partialUpdate(integration.id, {
      settings: {
        apiUrl: apiUrl,
        apiKey: apiKey,
      },
    })
    onIntegrationUpdate(
      set(
        'settings',
        {
          ...integration.settings,
          apiUrl: apiUrl,
          apiKey: apiKey,
        },
        integration,
      ),
    )
  }

  return (
    <Dialog isOpen={open} onClose={onClose} className={cs.configureScispotDialog}>
      <div className={cs.title}>Configure Scispot Integration</div>
      <Input
        label='Api Url'
        value={apiUrl}
        onChange={setApiUrl}
        className={cs.inputContainer}
        inputClassName={cs.input}
      />
      <Input
        label='Api Key'
        value={apiKey}
        onChange={setApiKey}
        className={cs.inputContainer}
        inputClassName={cs.input}
      />
      <div className={cs.controls}>
        <Button
          type='primary'
          label='Update'
          disabled={!apiKey || apiKey === apiUrl}
          className={cs.button}
          onClick={update}
        />
      </div>
    </Dialog>
  )
}

export default ConfigureScispotDialog
