import cx from 'classnames'
import dayjs from 'dayjs'
import { useState } from 'react'
import { useHistory } from 'react-router-dom'
import Dialog from '~/components/Dialog'
import Table from '~/components/Table'
import {
  CultureDatasetViewModel,
  CultureViewModel,
} from '../../events/ViewModels/CultureViewModel'
import {
  MediaLotViewModel,
  ReagentMediaExchange,
  getMediaLotCreatedAt,
} from '../../events/ViewModels/MediaLotViewModel'
import { ViewModels } from '../../events/ViewModels/ViewModels'
import { getRelativeTime } from '../../events/data/eventTimes'
import { CultureDatasetImagesDialog } from '../CultureDatasetImagesDialog/CultureDatasetImagesDialog'
import { CultureLink } from '../CultureLink/CultureLink'
import { IssueLabel } from '../IssueLabel/IssueLabel'
import cs from './reagent_performance.scss'

export function ReagentPerformance({
  mediaLotID,
  viewModels,
}: {
  mediaLotID: string
  viewModels: ViewModels
}) {
  // TODO: The navigation at the top of the page is a bit messy - should we include
  // a back button?

  const [metadataDialogIsOpen, setMetadataDialogIsOpen] = useState(false)
  const history = useHistory()

  const mediaLot = viewModels.mediaLots[mediaLotID]
  return (
    <>
      <MetadataDialog
        today={viewModels.today}
        mediaLot={mediaLot}
        isOpen={metadataDialogIsOpen}
        onClose={() => {
          setMetadataDialogIsOpen(false)
        }}
      />
      <div className={cs.reagentPerformance}>
        <div className={cs.header}>
          <div className={cs.navigateBack} onClick={() => history.goBack()}>
            Back to Culture Overview
          </div>
          <div className={cs.headerSubtext}>Reagent Performance Analysis</div>
          <div className={cs.headerReagentName}>{mediaLot.name}</div>
          <div
            className={cs.headerViewMetadata}
            onClick={() => {
              setMetadataDialogIsOpen(true)
            }}
          >
            View Metadata
          </div>
        </div>
        <HighlightMetrics mediaLot={mediaLot} />
        <PerformanceAnalysisTable today={viewModels.today} mediaLot={mediaLot} />
      </div>
    </>
  )
}

function HighlightMetrics({ mediaLot }: { mediaLot: MediaLotViewModel }) {
  const num = mediaLot.anomaliesDetected
  const denom = mediaLot.mediaExchanges.length
  const percentAnomaliesStr = `${num}/${denom} (${Math.round((num * 100) / denom)}%)`
  const isGood = num / denom < 0.25

  return (
    <div className={cs.highlightMetrics}>
      <div className={cs.highlightMetric}>
        <div className={cs.highlightMetricLabel}>Avg Doubling Time</div>
        <div className={cs.highlightMetricLabel}>(Next Day)</div>
        <div
          className={cx(
            isGood ? cs.metric__success : cs.metric__error,
            cs.highlightMetricValueContainer,
          )}
        >
          <span className={cs.highlightMetricValue}>{mediaLot.avgDoublingTime}</span>
          <span className={cs.highlightMetricUnit}> hr</span>
        </div>
      </div>
      <div className={cs.highlightMetric}>
        <div className={cs.highlightMetricLabel}>Anomalies</div>
        <div className={cs.highlightMetricLabel}>(Next Day)</div>
        <div
          className={cx(
            isGood ? cs.metric__success : cs.metric__error,
            cs.highlightMetricValueContainer,
          )}
        >
          <span className={cs.highlightMetricValue}>{percentAnomaliesStr}</span>
        </div>
      </div>
    </div>
  )
}

const TABLE_COLUMNS = [
  {
    name: 'Cell Culture',
    width: 300,
    render: (mediaExchange: ReagentMediaExchange) => (
      <CultureLink
        id={mediaExchange.culture.id}
        name={mediaExchange.culture.name}
        cellLine={mediaExchange.culture.cellLine}
        className={cs.cultureLink}
      />
    ),
  },
  {
    name: 'Date',
    width: 220,
    render: (mediaExchange: ReagentMediaExchange) => (
      <div>
        <div>{dayjs().add(mediaExchange.onDay, 'day').format('YYYY-MM-DD')}</div>
        <div className={cs.mediaExchangeSubtitle}>Media Exchange</div>
      </div>
    ),
  },
  {
    // TODO: <Table> expects column names to be strings, not components, so it's
    // not trivial to add a line break between "Images" and "(Next Day)"
    // Same goes for anomalies. (Doubling time is a thin enough column that it
    // wraps automatically)
    name: 'Images (Next Day)',
    width: 200,
    // TODO: Consider opening a CultureDatasetImagesDialog here on click
    render: (mediaExchange: ReagentMediaExchange) => (
      <img
        className={cs.thumbnail}
        src={mediaExchange.nextDayDataset.thumbnails[0][0]}
      />
    ),
  },
  {
    name: 'Anomalies Detected (Next Day)',
    width: 250,
    render: (mediaExchange: ReagentMediaExchange) => (
      <div>
        {mediaExchange.nextDayDataset.cellDeathDetected ? (
          <>
            <IssueLabel
              label='Cell Death'
              messageKind='error'
              flushLeft
              className={cs.issueLabel}
            />
            <IssueLabel
              label='Contamination'
              messageKind='error'
              flushLeft
              className={cs.issueLabel}
            />
          </>
        ) : (
          <span className={cs.noAnomaly}>None</span>
        )}
      </div>
    ),
  },
  {
    name: 'Doubling Time (Next Day)',
    width: 100,
    render: (mediaExchange: ReagentMediaExchange) => {
      const doublingTime = mediaExchange.nextDayDataset.doublingTime
      return (
        <div
          className={cx(
            cs.doublingTime,
            (doublingTime ?? 0) < 0 ? cs.metric__error : cs.metric__success,
          )}
        >
          {doublingTime}
        </div>
      )
    },
  },
]

interface CultureDialogState {
  culture: CultureViewModel
  dataset: CultureDatasetViewModel
}

function PerformanceAnalysisTable({
  today,
  mediaLot,
}: {
  today: number
  mediaLot: MediaLotViewModel
}) {
  const [dialogCulture, setDialogCulture] = useState<CultureDialogState | null>(null)

  const mediaExchanges = mediaLot.mediaExchanges.map(exch => ({
    ...exch,
    id: `${exch.culture.id}_${exch.onDay}`,
  }))
  return (
    <>
      <CultureDatasetImagesDialog
        today={today}
        culture={dialogCulture?.culture ?? null}
        dataset={dialogCulture?.dataset ?? null}
        isOpen={dialogCulture != null}
        onClose={() => {
          setDialogCulture(null)
        }}
      />
      <div className={cs.outerTableContainer}>
        <div className={cs.tableTitle}>
          Reagent Was Used {mediaLot.mediaExchanges.length} Times
        </div>
        <div className={cs.innerTableContainer}>
          <Table
            columns={TABLE_COLUMNS}
            data={mediaExchanges}
            rowPaddingVariant='rowPaddingLow'
            rowKey='id'
            hideRowBorders
            onRowClick={(mediaExchange: ReagentMediaExchange) => {
              setDialogCulture({
                culture: mediaExchange.culture,
                dataset: mediaExchange.nextDayDataset,
              })
            }}
            flushLeft
          />
        </div>
      </div>
    </>
  )
}

function MetadataDialog({
  today,
  mediaLot,
  isOpen,
  onClose,
}: {
  today: number
  mediaLot: MediaLotViewModel
  isOpen: boolean
  onClose: () => void
}) {
  const mediaLotCreatedAt = getRelativeTime(getMediaLotCreatedAt(today, mediaLot))

  return (
    <Dialog isOpen={isOpen} onClose={onClose}>
      <div className={cs.metadataDialogHeader}>Reagent Metadata</div>
      <div className={cx(cs.metadataDialogBody, cs.metadataFieldValue)}>
        <div className={cs.metadataDialogLeft}>
          <div className={cs.metadataFieldLabel}>Components</div>
          {mediaLot.components.map(component => (
            <div key={component.lotNumber} className={cs.metadataHighlight}>
              &middot; {component.componentName} ({component.lotNumber})
            </div>
          ))}
        </div>
        <div className={cs.metadataDialogRight}>
          <div>
            <div className={cs.metadataFieldLabel}>Prepared</div>
            <div>{mediaLotCreatedAt}</div>
          </div>
          <div>
            <div className={cs.metadataFieldLabel}>Type</div>
            <div>Media Mix</div>
          </div>
        </div>
      </div>
    </Dialog>
  )
}
