import cx from 'classnames'
import PropTypes from 'prop-types'
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom'

import Tabs from '~/components/Tabs'
import useTabsWithUrl from '~/utils/hooks/useTabsWithUrlPart'

import ArchivedItems from './ArchivedItems'
import RoutineHistory from './RoutineHistory'
import SearchByBarcode from './SearchByBarcode'
import cs from './history.scss'

const TABS = [
  {
    key: 'past-routines',
    title: 'Past Routines',
  },
  {
    key: 'search-by-barcode',
    title: 'Search by Barcode',
  },
  {
    key: 'archived-items',
    title: 'Archived Items',
  },
]

const History = ({ className }) => {
  const match = useRouteMatch()

  const [tabs, selectedTabId, handleTabChange] = useTabsWithUrl(TABS)

  return (
    <div className={cx(className, cs.history)}>
      <Tabs
        tabs={tabs}
        selectedTabId={selectedTabId}
        className={cs.tabs}
        panelClassName={cs.tabPanel}
        onlyRenderWhenViewed
        empty
        nested
        onTabChange={handleTabChange}
      />
      <Switch>
        <Route
          exact
          path={match.path}
          render={() => <Redirect to={`${match.path}/past-routines`} />}
        />
        <Route path={`${match.path}/past-routines`} render={() => <RoutineHistory />} />
        <Route
          path={`${match.path}/search-by-barcode`}
          render={() => <SearchByBarcode />}
        />
        <Route path={`${match.path}/archived-items`} render={() => <ArchivedItems />} />
      </Switch>
    </div>
  )
}

History.propTypes = {
  className: PropTypes.string,
}

export default History
