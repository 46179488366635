import { WellCultureGraphQl, WellCultureStatusGraphQl } from '~/__generated__/graphql'

import { DemoObjectConverter, LinkingError } from '../demoData'
import { plateObservationKey } from './demoTimepoint'

export type DemoSourceWell = {
  plateBarcode: string
  position: string

  parentWellPlateBarcode: string
  parentWellPosition: string

  cellLine: string
  cellLineLot: string
  passageNumber: string
}

type PartialWell = Omit<
  WellCultureGraphQl,
  'culturePlate' | 'parentCulture' | 'montages' | 'montageHistory'
> & {
  culturePlate: { barcode: string }
  parentCulture: { plateBarcode: string; position: string }
  montages: { id: string }[]
  montageHistory: { id: string }[]
}

export const wellConverter: DemoObjectConverter<
  DemoSourceWell,
  PartialWell,
  WellCultureGraphQl
> = {
  tsvColumns: [
    'plateBarcode',
    'position',
    'parentWellPlateBarcode',
    'parentWellPosition',
    'cellLine',
    'cellLineLot',
    'passageNumber',
  ],

  validate(wells) {
    if (
      wells.length !== new Set(wells.map(w => w.plateBarcode + '|' + w.position)).size
    ) {
      throw new Error('There are wells with duplicate positions for the same plate.')
    }
    if (wells.some(w => !w.plateBarcode)) {
      throw new Error('Some wells are missing a plate barcode.')
    }
    if (wells.some(w => !w.position)) {
      throw new Error('Some wells are missing a position.')
    }
    if (wells.some(w => w.position !== w.position.toUpperCase())) {
      throw new Error('Positions must be uppercase')
    }
    if (wells.some(w => w.passageNumber && Number.isNaN(parseInt(w.passageNumber)))) {
      throw new Error('Some wells have an invalid passage number')
    }
  },

  convert(well, others) {
    const timepoints =
      others.timepoints?.filter(
        t => t.plateBarcode === well.plateBarcode && t.wellPosition === well.position,
      ) ?? []

    return {
      partialItem: {
        id: encodeURIComponent(btoa(well.plateBarcode + '|' + well.position)),
        well: well.position,
        name: well.plateBarcode + '|' + well.position,
        createdAt: '2024-11-05T17:05:00.000Z',
        // Eventually, we need to thread the actual plate into here.
        culturePlate: { barcode: well.plateBarcode },
        status: WellCultureStatusGraphQl.Active,
        parentCulture: {
          plateBarcode: well.parentWellPlateBarcode,
          position: well.parentWellPosition,
        },
        cellLine: well.cellLine,
        cellLineLot: well.cellLineLot,
        passageNumber:
          well.passageNumber != '' && well.passageNumber != null
            ? parseInt(well.passageNumber)
            : null,
        confluenceHistory: timepoints.map(t => ({
          __typename: 'PastWellConfluenceGraphQL',
          source: {
            __typename: 'DatasetSourceGraphQL',
            datasetId: plateObservationKey(t),
          },
          timestamp: t.timestamp,
          value: parseFloat(t.confluence),
        })),
        montages: [],
        montageHistory: [],
        observationHistory: timepoints.map(t => ({
          __typename: 'PastObservationGraphQL',
          source: {
            __typename: 'DatasetSourceGraphQL',
            datasetId: plateObservationKey(t),
          },
          timestamp: t.timestamp,
          confluence: parseFloat(t.confluence),
        })),
      },
      lookupKey: demoWellKey(well),
    }
  },

  link(derived, partial, lookup) {
    const barcode = partial.culturePlate.barcode
    const plate = lookup('plates', barcode)
    if (!plate) {
      throw new LinkingError(
        `A well is defined with plate barcode "${barcode}" but there is no plate defined with that barcode.`,
      )
    }
    derived.culturePlate = plate

    derived.parentCulture = lookup('wells', demoWellKey(partial.parentCulture))
    if (
      !derived.parentCulture &&
      (partial.parentCulture.plateBarcode || partial.parentCulture.position)
    ) {
      throw new LinkingError(
        `A well has a parent culture (plate "${partial.parentCulture.plateBarcode}" well "${partial.parentCulture.position}") that does not exist.`,
      )
    }
  },
}

export function demoWellKey({
  plateBarcode,
  position,
}: { plateBarcode: string; position: string }): string {
  return plateBarcode + '|' + position
}
