import cx from 'classnames'
import { compact, flatten, get } from 'lodash/fp'
import { useEffect, useState } from 'react'

import commonDriverAPI from '~/api/desktop/commonDriver'
import useReloadKey from '~/utils/hooks/useReloadKey'

import { Instrument, InstrumentStatus } from '~/common.interface'
import GenericInstrumentBody from '../GenericInstrumentBody'
import GenericInstrumentPage from '../GenericInstrumentPage'
import LatestProcessVariableValuesTooltip from './LatestProcessVariableValuesTooltip'
import { LiconicConfig } from './LiconicConfig.interface'
import cs from './liconic_instrument_page.scss'

interface LiconicInstrumentPageProps {
  className?: string
  instrument: Instrument
  status: InstrumentStatus
  live?: boolean
}

const LiconicInstrumentPage = ({
  className,
  status,
  live,
  instrument,
}: LiconicInstrumentPageProps) => {
  const [instrumentState, setInstrumentState] = useState(null)
  const [reloadKey, refreshReloadKey] = useReloadKey()
  const [config, setConfig] = useState<null | LiconicConfig>(null)

  const refreshConfig = async () => {
    const _config = await commonDriverAPI.getConfigV2(instrument.instrumentName)
    setConfig(_config.config as unknown as LiconicConfig)
  }

  useEffect(() => {
    refreshConfig()
  }, [])

  const fetchLiconicInfo = async () => {
    const _instrumentState = await commonDriverAPI.getState(instrument.instrumentName)
    setInstrumentState(_instrumentState)
  }

  const handleControlCommandComplete = commandName => {
    if (
      commandName === 'load_plate' ||
      commandName === 'unload_plate' ||
      commandName === 'load_plate_manual' ||
      commandName === 'unload_plate_manual' ||
      commandName === 'refresh_item_cache_command'
    ) {
      fetchLiconicInfo()
      refreshReloadKey()
    }
  }

  useEffect(() => {
    fetchLiconicInfo()
  }, [])

  const renderBodyContents = () => {
    return (
      <GenericInstrumentBody
        instrument={instrument}
        reloadKey={reloadKey}
        views={['storage', 'transfer_stations', 'list']}
        defaultView='storage'
        className={cs.bodyContents}
      />
    )
  }

  const renderLatestProcessVariableValuesTooltip = () => {
    return (
      <LatestProcessVariableValuesTooltip
        instrumentName={instrument.instrumentName}
        config={config}
      />
    )
  }

  const items = get('storedProcessItems', instrumentState)

  const totalSlots = flatten(items).length
  const filledSlots = compact(flatten(items)).length

  const additionalStatusText = `${filledSlots} / ${totalSlots} plates`
  return (
    <div className={cx(className, cs.liconicInstrumentPage)}>
      <GenericInstrumentPage
        instrument={instrument}
        bodyContents={renderBodyContents()}
        status={status}
        live={live}
        additionalStatusText={additionalStatusText}
        onControlCommandComplete={handleControlCommandComplete}
        className={cs.genericInstrumentPage}
        sidebarContents={renderLatestProcessVariableValuesTooltip()}
        onConfigUpdated={refreshConfig}
      />
    </div>
  )
}

export default LiconicInstrumentPage
