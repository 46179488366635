import { client, unwrap } from '~/api/desktopAPIv2'
import { components } from './generated-schema'

export type WorkflowInstance = components['schemas']['WorkflowInstance']
export type WorkflowRoutine = components['schemas']['WorkflowRoutine']

export type RetrieveWorkflowInstanceResponse =
  components['schemas']['RetrieveWorkflowInstanceResponse']
export type BulkRetrieveWorkflowInstanceResponse =
  components['schemas']['BulkRetrieveWorkflowInstanceResponse']
export type CheckIfWorkflowInstanceCancellableResponse =
  components['schemas']['CheckIfWorkflowInstanceCancellableResponse']
export type BulkRetrieveWorkflowInstanceByWorkflowRoutineUuidsResponse =
  components['schemas']['BulkRetrieveWorkflowInstanceByWorkflowRoutineUuidsResponse']

export default {
  retrieve: (uuid: string): Promise<RetrieveWorkflowInstanceResponse> =>
    unwrap(
      client().GET('/api/v2/workflows/workflow-instances/retrieve/{uuid}', {
        params: {
          path: { uuid },
        },
      }),
    ),
  bulkRetrieve: (uuids: string[]): Promise<BulkRetrieveWorkflowInstanceResponse> =>
    unwrap(
      client().POST('/api/v2/workflows/workflow-instances/bulk-retrieve', {
        body: {
          uuids,
        },
      }),
    ),
  checkIfCancellable: (
    uuid: string,
  ): Promise<CheckIfWorkflowInstanceCancellableResponse> =>
    unwrap(
      client().POST(
        '/api/v2/workflows/workflow-instances/check-if-cancellable/{uuid}',
        {
          params: {
            path: { uuid },
          },
        },
      ),
    ),
  bulkRetrieveByWorkflowRoutineUuids: (
    uuids: string[],
  ): Promise<BulkRetrieveWorkflowInstanceByWorkflowRoutineUuidsResponse> =>
    unwrap(
      client().POST(
        '/api/v2/workflows/workflow-instances/bulk-retrieve-by-workflow-routine-uuids',
        {
          body: {
            uuids,
          },
        },
      ),
    ),
  bulkRetrieveWorkflowRoutinesByWorkflowInstanceUuid: (
    uuid: string,
  ): Promise<WorkflowRoutine[]> =>
    unwrap(
      client().GET(
        '/api/v2/workflows/workflow-instances/retrieve/{uuid}/workflow-routines',
        {
          params: {
            path: { uuid },
          },
        },
      ),
    ),
}
