import cx from 'classnames'
import dayjs from 'dayjs'
import { get } from 'lodash/fp'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'

import { ISODateString } from '~/types/ISODateString.interface'
import cs from './input.scss'

// This component accepts and returns a date as an ISO 8601 format string, but
// displays and parses the date in a human friendly format (M/D/YYYY h:mm A)
//
// Example:
//
//   <InputDatetime value="2022-04-07T13:58:10.104Z" ... />
//
//   Will display in the input as:
//
//   4/7/2022 6:58 AM
//
//   Any user changes to this input will then be parsed back out int ISO 8601
//   format
//
// This input only changes on blur to allow for editing without premature
// validation
//
// Note that this input can also handle time, e.g. "5:00 PM"
// For example, use the prop values:
//   parseFormats={['h:mm A']}
//   displayFormat='h:mm A'
// The return value will still be an ISO 8601 format string, from which the caller will
// need to extract the time component.

const DEFAULT_DISPLAY_FORMAT = 'M/D/YYYY h:mm A'

interface InputDatetimeProps {
  value?: ISODateString
  onChange: (newDate: ISODateString) => void
  className?: string
  label?: string
  inputClassName?: string
  placeholder?: string
  disabled?: boolean
  onSubmit?: () => void
  parseFormats?: string[]
  displayFormat?: string
  parseStrict?: boolean
}

const InputDatetime = React.forwardRef<HTMLInputElement, InputDatetimeProps>(
  (props, ref) => {
    const {
      value,
      onChange,
      className,
      label,
      inputClassName,
      placeholder,
      disabled,
      onSubmit,
      parseFormats,
      displayFormat,
      parseStrict,
    } = props

    // This is exactly the string displayed in the input
    // which can diverge from 'value' prop as the user types.
    const [inputValue, setInputValue] = useState<string | null>(null)

    const parseDateString = v => {
      return dayjs(v, parseFormats, parseStrict)
    }

    const validateDateString = v => {
      return parseDateString(v).isValid()
    }

    // Attempts to parse an isoformat string and return a date string in the Display Format.
    // If invalid, an empty string is returned
    const parseIsoformatString = v => {
      if (dayjs(v).isValid()) {
        return dayjs(v).format(
          displayFormat || get(0, parseFormats) || DEFAULT_DISPLAY_FORMAT,
        )
      }
      return ''
    }

    // Whenever the incoming value changes, update the input value
    useEffect(() => {
      setInputValue(parseIsoformatString(value))
    }, [value])

    const onInputChange = event => {
      setInputValue(event.target.value)
    }

    // Only change on blur, otherwise parsed date may not be valid while being
    // edited
    const onInputBlur = () => {
      if (validateDateString(inputValue)) {
        // If valid, the fire onChange
        const isoValue = parseDateString(inputValue).toISOString()

        setInputValue(parseIsoformatString(isoValue))
        onChange(isoValue)
      } else {
        // Otherwise reset value
        setInputValue(parseIsoformatString(value))
      }
    }

    const handleKeyDown = event => {
      if (event.key === 'Enter' && onSubmit) {
        onSubmit()
      }
    }

    return (
      <div className={className}>
        {label && <div className={cs.label}>{label}</div>}
        <input
          ref={ref}
          className={cx(inputClassName, cs.input, disabled && cs.disabled)}
          placeholder={placeholder}
          value={inputValue || ''}
          onChange={onInputChange}
          onKeyDown={handleKeyDown}
          onBlur={onInputBlur}
          type='text'
          disabled={disabled}
        />
      </div>
    )
  },
)

InputDatetime.propTypes = {
  className: PropTypes.string,
  inputClassName: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  // When the user presses "Enter" in the Input, fire onSubmit.
  onSubmit: PropTypes.func,
  // This is an array of parse formats, which can be used to parse
  // the date string input by the user.
  // e.g. ['M/D/YYYY h:mm A']
  parseFormats: PropTypes.arrayOf(PropTypes.string.isRequired),
  // Strict parsing mode from dayjs.
  // May enable more accurate parsing of dates, depending on format.
  parseStrict: PropTypes.bool,
  // This is the format to display the string in the input.
  // Whenever the user blurs the input, whatever they typed in the input
  // is converted to this format. Usually, this will be one of the parse formats.
  displayFormat: PropTypes.string,
}

InputDatetime.defaultProps = {
  parseFormats: ['M/D/YYYY h:mm A'],
  parseStrict: false,
}

export default InputDatetime
