import { any, find, flow, map, sortBy } from 'lodash/fp'
import { useEffect, useState } from 'react'

import workcellAPI from '~/api/desktop/workcell'
import Select, { SelectOption } from '~/components/Select'

import { LabwareConfigResponse } from '~/api/desktop/workcell.interface'

const getLabwareSelectOptions = (labwareConfig: LabwareConfigResponse[]) =>
  flow(
    sortBy('labware_name'),
    map((labwareConfig: LabwareConfigResponse) => ({
      key: labwareConfig.labware_name,
      label: labwareConfig.labware_name,
    })),
  )(labwareConfig)

export function LabwareSelect({
  processItemType,
  selectedLabware,
  onLabwareSelect,
  defaultLabwareName,
  overrideLabwareName,
  className,
  triggerClassName,
  popoverClassName,
  disabled,
}: {
  processItemType: string
  selectedLabware?: LabwareConfigResponse
  onLabwareSelect: (labware: LabwareConfigResponse | undefined) => void
  // When selectedLabware is set to undefined, this labware will be automatically selected.
  defaultLabwareName?: string
  // When this is changed, if a labware matching this name is found, it will be selected.
  // NOTE: This interface could be cleaned up. It currently supports
  // when an existing process item is being reloaded and we need to force a particular labware.
  overrideLabwareName?: string
  triggerClassName?: string
  popoverClassName?: string
  className?: string
  disabled?: boolean
}) {
  const [labwareOptionsByProcessItemType, setLabwareOptionsByProcessItemType] =
    useState<{ [key: string]: LabwareConfigResponse[] | undefined }>({})

  const fetchLabwareOptionsByProcessItemType = async () => {
    const _response = await workcellAPI.getLabwareOptionsByProcessItemType()
    if (_response.labwareOptions) {
      setLabwareOptionsByProcessItemType(_response.labwareOptions)
    }
  }

  const getLabwareOptions = (): LabwareConfigResponse[] => {
    return labwareOptionsByProcessItemType[processItemType] || []
  }

  // Do this once.
  useEffect(() => {
    fetchLabwareOptionsByProcessItemType()
  }, [])

  useEffect(() => {
    // If labware is not selected, automatically select a default.
    if (!selectedLabware && labwareOptionsByProcessItemType) {
      const labwareOptions = labwareOptionsByProcessItemType[processItemType]
      if (labwareOptions && defaultLabwareName) {
        const defaultLabware = find(
          ['labware_name', defaultLabwareName],
          labwareOptions,
        )
        if (defaultLabware) {
          onLabwareSelect(defaultLabware)
          return
        }
      }
      if (labwareOptions && labwareOptions[0]) {
        onLabwareSelect(labwareOptions[0])
      }
    }
    // If the selected labware is invalid based on the item type, select a valid option.
    if (selectedLabware && labwareOptionsByProcessItemType) {
      const labwareOptions = labwareOptionsByProcessItemType[processItemType]
      if (
        labwareOptions &&
        labwareOptions.length > 0 &&
        !any(['labware_name', selectedLabware.labware_name], labwareOptions)
      ) {
        onLabwareSelect(labwareOptions[0])
      }
    }
  }, [labwareOptionsByProcessItemType, processItemType, selectedLabware])

  // TODO: If override labware cannot be found, we should probably error.
  // This would indicate that we are trying to check in a process item
  // which used a labware type that was once allowed, but is no longer allowed.
  // This currently errors on the back-end, because we are attempting to change the plate format
  // of an existing process item.
  useEffect(() => {
    const labwareOptions = labwareOptionsByProcessItemType[processItemType]
    if (labwareOptions && overrideLabwareName) {
      const overrideLabware = find(
        ['labware_name', overrideLabwareName],
        labwareOptions,
      )
      if (overrideLabware) {
        onLabwareSelect(overrideLabware)
      }
    }
  }, [overrideLabwareName])

  const handleChange = (value: SelectOption) => {
    const labwareOptions = getLabwareOptions()
    onLabwareSelect(find(['labware_name', value.label], labwareOptions))
  }

  const itemMatchesQuery = (labware: SelectOption, queryLowerCase: string) =>
    labware.key.toLowerCase().includes(queryLowerCase)

  const labwareSelectOptions = getLabwareSelectOptions(getLabwareOptions())
  return (
    <div>
      <Select<SelectOption>
        className={className}
        label='Labware'
        items={labwareSelectOptions}
        itemKey='key'
        itemLabelKey='label'
        filterable
        itemMatchesQuery={itemMatchesQuery}
        activeItem={
          find(['label', selectedLabware?.labware_name], labwareSelectOptions) || null
        }
        onChange={handleChange}
        triggerClassName={triggerClassName}
        popoverClassName={popoverClassName}
        disabled={disabled}
      />
    </div>
  )
}
