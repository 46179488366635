import dayjs from 'dayjs'
import { omitBy, reverse } from 'lodash'
import { useState } from 'react'
import Table, { TableColumn } from '~/components/Table'
import Button from '~/components/buttons/Button'
import DownloadIcon from '~/components/icons/DownloadIcon'
import {
  CultureProcessLog,
  CultureProcessLogEvent,
  EVENT_KIND_TO_PRESENTATION,
  getEventUser,
} from '../../events/ViewModels/CultureProcessLogViewModel'
import ProcessLogDownloadDialog from './ProcessLogDownloadDialog'
import cs from './culture_process_log_view.scss'

const TABLE_COLUMNS: TableColumn<CultureProcessLogEvent>[] = [
  {
    name: 'Event',
    width: 350,
    // TODO: Render icons
    render: (event: CultureProcessLogEvent) => {
      const presentation = EVENT_KIND_TO_PRESENTATION(event)[event.kind]
      return (
        <div>
          <div className={cs.eventKindLabel}>{presentation.eventType}</div>
          <div className={cs.eventName}>{presentation.message}</div>
        </div>
      )
    },
  },
  {
    name: 'Time',
    width: 250,
    // TODO: Match the mocks by rendering dividers between days and only rendering
    // time in this column
    render: (event: CultureProcessLogEvent) => (
      <div className={cs.rowText}>{dayjs(event.at).format('YYYY/MM/DD hh:mma')}</div>
    ),
    smallText: true,
  },
  {
    name: 'User',
    width: 250,
    render: (event: CultureProcessLogEvent) => (
      <div className={cs.rowText}>{getEventUser(event)}</div>
    ),
    smallText: true,
  },
  {
    name: 'Notes',
    width: 350,
    // TODO: Render icons
    render: (event: CultureProcessLogEvent) => {
      const presentation = EVENT_KIND_TO_PRESENTATION(event)[event.kind]
      return (
        <div>
          {presentation.details ? (
            <div className={cs.eventDetailsLabel}>{presentation.details}</div>
          ) : (
            <></>
          )}
        </div>
      )
    },
  },
  // TODO: Add a notes column
]

export function CultureProcessLogView({
  processLog,
}: {
  processLog: CultureProcessLog
}) {
  // TODO: Download as CSV button
  const [processLogDownloadDialogOpen, setProcessLogDownloadDialogOpen] =
    useState(false)

  const processedEvents = reverse(
    processLog.events.map((event, i) => ({
      id: `${i}`,
      // Hacky way to remove objects as they can't be rendered. Only applies to one event case
      ...omitBy(event, o => typeof o == 'object'),
    })),
  ) as CultureProcessLogEvent[]
  // This type cast is hacky and not quite right. PlateCheckedIn has lost its wellNameToCulture field.

  return (
    <div className={cs.cultureProcessLogView}>
      <div className={cs.controls}>
        <div className={cs.fill} />
        <Button
          className={cs.button}
          label='Download as CSV'
          IconComponent={DownloadIcon}
          onClick={() => setProcessLogDownloadDialogOpen(true)}
        />
      </div>
      <Table
        columns={TABLE_COLUMNS}
        data={processedEvents}
        className={cs.table}
        rowPaddingVariant='rowPaddingLow'
        rowKey='id'
        hideRowBorders
        flushLeft
      />
      <ProcessLogDownloadDialog
        isOpen={processLogDownloadDialogOpen}
        onClose={() => setProcessLogDownloadDialogOpen(false)}
        data={processedEvents}
        datasetCount={processedEvents.length}
      />
    </div>
  )
}
