import cx from 'classnames'
import { debounce, findIndex, set } from 'lodash/fp'
import PropTypes from 'prop-types'
import { useCallback, useEffect, useState } from 'react'

import routineAPI from '~/api/desktop/routines'
import workcellAPI from '~/api/desktop/workcell'
import Input from '~/components/Input'
import Toaster from '~/components/Toaster'
import ScheduledRoutineList from '~/pages/Workcell/components/routine/ScheduledRoutineList'
import { displayCount } from '~/utils/string'

import cs from './search_by_barcode.scss'

const SearchByBarcode = ({ className }) => {
  const [searchQuery, setSearchQuery] = useState('')
  const [routines, setRoutines] = useState([])

  const [loading, setLoading] = useState(false)

  // Only call this once every 500 ms.
  // This function can be a bit slow, so don't call it every time the user types a key.
  const refreshRoutines = useCallback(
    debounce(500, async _searchQuery => {
      if (_searchQuery.length >= 3) {
        const _routines = await routineAPI.list({
          searchQuery: _searchQuery,
          verbose: 1,
        })
        setRoutines(_routines)
        setLoading(false)
      }
      setLoading(false)
    }),
    [],
  )

  useEffect(() => {
    setLoading(true)
    refreshRoutines(searchQuery)
  }, [searchQuery])

  const handleRoutineCancel = async routine => {
    const index = findIndex(['id', routine.id], routines)
    setRoutines(set([index, 'status'], 'canceled', routines))
    try {
      await workcellAPI.bulkCancelRoutines([routine.id])
      Toaster.show({
        message: `${routine.routineDefinition.name} successfully canceled.`,
        intent: 'success',
      })
    } catch {
      Toaster.show({
        message: `${routine.routineDefinition.name} failed to cancel.`,
        intent: 'danger',
      })
    }
  }

  const renderTable = () => {
    if (searchQuery.length < 3) {
      return (
        <div className={cs.bigMessage}>
          Search query must be 3 characters or more...
        </div>
      )
    }
    return (
      <>
        {!loading && (
          <div className={cs.bigMessage}>
            {displayCount('matching routine', routines.length)}
          </div>
        )}
        <ScheduledRoutineList
          loading={loading}
          routines={routines}
          className={cs.routineList}
          onRoutineCancel={handleRoutineCancel}
          timeDisplayType='datetime'
        />
      </>
    )
  }

  return (
    <div className={cx(className, cs.searchByBarcode)}>
      <div className={cs.headerContainer}>
        <Input
          className={cs.searchInput}
          value={searchQuery}
          onChange={setSearchQuery}
          placeholder='Search...'
        />
      </div>
      {renderTable()}
    </div>
  )
}

SearchByBarcode.propTypes = {
  className: PropTypes.string,
}

export default SearchByBarcode
