import cx from 'classnames'
import dayjs from 'dayjs'
import { filter, get, includes, orderBy } from 'lodash/fp'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'

import routineAPI from '~/api/desktop/routines'
import CheckboxOption from '~/components/CheckboxOption'
import ScheduledRoutineList from '~/pages/Workcell/components/routine/ScheduledRoutineList'

import DateHeader from './DateHeader'
import cs from './routine_history.scss'

const RoutineHistory = ({ className }) => {
  const [date, setDate] = useState(new Date())
  const [routines, setRoutines] = useState([])
  const [includeSupport, setIncludeSupport] = useState(false)
  const [includeCancelled, setIncludeCancelled] = useState(false)
  const [loading, setLoading] = useState(false)

  useEffect(async () => {
    setLoading(true)
    const _routines = await routineAPI.list({
      startTimeAfter: dayjs(date).startOf('day').toISOString(),
      startTimeBefore: dayjs(date).endOf('day').toISOString(),
      verbose: 1,
    })

    setRoutines(_routines)
    setLoading(false)
  }, [date])

  const getFilteredRoutines = () => {
    let filteredRoutines = routines

    if (!includeSupport) {
      filteredRoutines = filter(
        routine => get(['routineDefinition', 'type'], routine) === 'default',
        filteredRoutines,
      )
    }

    if (!includeCancelled) {
      filteredRoutines = filter(
        routine => includes(routine.status, ['completed']),
        filteredRoutines,
      )
    } else {
      filteredRoutines = filter(
        routine => includes(routine.status, ['completed', 'canceled']),
        filteredRoutines,
      )
    }

    filteredRoutines = orderBy(
      routine => routine.startTime || routine.estimatedStartTime,
      'asc',
      filteredRoutines,
    )

    return filteredRoutines
  }

  return (
    <div className={cx(className, cs.routineHistory)}>
      <div className={cs.headerContainer}>
        <DateHeader value={date} onChange={setDate} className={cs.dateHeader} />
        <div className={cs.controls}>
          <CheckboxOption
            label='Show Support Routines'
            checked={includeSupport}
            onClick={setIncludeSupport}
            className={cs.option}
            allCaps
          />
          <CheckboxOption
            label='Show Cancelled Routines'
            checked={includeCancelled}
            onClick={setIncludeCancelled}
            className={cs.option}
            allCaps
          />
        </div>
      </div>
      <ScheduledRoutineList
        loading={loading}
        routines={getFilteredRoutines()}
        className={cs.routineList}
      />
    </div>
  )
}

RoutineHistory.propTypes = {
  className: PropTypes.string,
}

export default RoutineHistory
