import cx from 'classnames'
import {
  LinkLotsLookupDataResponseCellLineLots,
  LinkLotsLookupDataResponseCellLines,
} from '~/api/operatorActions/linkCellLineLotsToCultures'
import { displayCount } from '~/utils/string'
import cs from './confirm_updates_step_data_table_title.scss'
import {
  getNumDistinctCellLineLots,
  getNumDistinctCellLines,
} from './lookupDataResponseExtractors'

export interface ConfirmUpdatesStepDataTableTitleProps {
  className?: string
  lookupDataResponse:
    | LinkLotsLookupDataResponseCellLineLots
    | LinkLotsLookupDataResponseCellLines
}

const ConfirmUpdatesStepDataTableTitle = ({
  className,
  lookupDataResponse,
}: ConfirmUpdatesStepDataTableTitleProps) => {
  if (lookupDataResponse.type === 'cell_line_lots') {
    return (
      <div className={cx(className, cs.confirmUpdatesStepDataTableTitle)}>
        Successfully looked up{' '}
        {displayCount('cell line lot', getNumDistinctCellLineLots(lookupDataResponse))}
      </div>
    )
  }
  if (lookupDataResponse.type === 'cell_lines') {
    return (
      <div className={cx(className, cs.confirmUpdatesStepDataTableTitle)}>
        Successfully looked up{' '}
        {displayCount('cell line', getNumDistinctCellLines(lookupDataResponse))}
      </div>
    )
  }
  return null
}

export default ConfirmUpdatesStepDataTableTitle
