import PropTypes from 'prop-types'
import { IconProps } from './types/IconProps'
const TrendingFlatIcon = ({ className, onClick }: IconProps) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    height='24'
    width='24'
    viewBox='0 0 24 24'
    className={className}
    onClick={onClick}
  >
    <g>
      <path d='M0,0H24V24H0Z' fill='none' />
      <path d='M22,12,18,8v3H3v2H18v3Z' />
    </g>
  </svg>
)

TrendingFlatIcon.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
}

export default TrendingFlatIcon
