import cx from 'classnames'
import dayjs from 'dayjs'
import { findIndex, reject, set, sortBy } from 'lodash/fp'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

import desktopAPI from '~/api/desktop'
import jobsAPI from '~/api/desktop/jobs'
import { getDesktopDomain } from '~/api/desktop/utils'
import JsonWithOverflow from '~/components/JsonWithOverflow'
import Table from '~/components/Table'
import TextCheckbox from '~/components/TextCheckbox'
import Toaster from '~/components/Toaster'
import Button from '~/components/buttons/Button'
import AddIcon from '~/components/icons/AddIcon'
import DeleteIcon from '~/components/icons/DeleteIcon'
import EditIcon from '~/components/icons/EditIcon'
import ExecuteIcon from '~/components/icons/ExecuteIcon'
import RefreshIcon from '~/components/icons/RefreshIcon'
import EditJobDialog from './EditJobDialog'
import cs from './jobs.scss'
import { parseCronString } from './utils'

// TODO(mark): Make an actual UI for managing jobs, instead of linking to the Django API.
const desktopDomain = getDesktopDomain()
const ADD_JOB_LINK = `${desktopDomain}/api/jobs`

interface JobsProps {
  className?: string
}

const Jobs = ({ className }: JobsProps) => {
  const [loading, setLoading] = useState(false)
  const [jobs, setJobs] = useState([])
  const [editJobDialogOpen, setEditJobDialogOpen] = useState(false)
  const [editJobId, setEditJobId] = useState(null)
  const history = useHistory()

  const fetchJobs = async () => {
    const _jobs = await desktopAPI.getJobs()
    setJobs(_jobs)
    setLoading(false)
  }

  const refreshJobs = () => {
    setJobs([])
    setLoading(true)
    fetchJobs()
  }

  const deleteJob = async id => {
    setJobs(reject(['id', id], jobs))
    await desktopAPI.deleteJob(id)
    Toaster.show({
      message: 'Successfully deleted job',
      intent: 'success',
    })
    refreshJobs()
  }

  const kickoffJob = async id => {
    await desktopAPI.kickoffJob(id)
    Toaster.show({
      message: 'Successfully kicked off job',
      intent: 'success',
    })
    history.push('/workcell/integrations/job-log')
  }

  const editJob = id => {
    setEditJobId(id)
    setEditJobDialogOpen(true)
  }

  const handleEditSuccess = () => {
    refreshJobs()
  }

  useEffect(() => {
    refreshJobs()
  }, [])

  const toggleIsActive = async row => {
    const jobIndex = findIndex(['id', row.id], jobs)
    setJobs(set([jobIndex, 'isActive'], !row.isActive, jobs))
    try {
      await jobsAPI.updateJob(row.id, {
        is_active: !row.isActive,
      } as unknown as Record<string, never>)
      Toaster.show({
        message: 'Successfully modified job',
        intent: 'success',
      })
    } catch {
      Toaster.show({
        message: 'Failed to modify job',
        intent: 'danger',
      })
      refreshJobs()
    }
  }

  const renderCronString = cronString => {
    const isoString = parseCronString(cronString)
    return isoString ? `${dayjs(isoString).format('h:mm A')} daily` : 'invalid'
  }

  const tableColumns = [
    {
      name: 'Name',
      width: 400,
      render: row => <div className={cs.eventType}>{row.name}</div>,
    },
    {
      name: 'Active',
      width: 100,
      render: row => (
        <TextCheckbox
          checkedText='Active'
          uncheckedText='inactive'
          checked={row.isActive}
          onClick={() => toggleIsActive(row)}
          className={cx(cs.checkbox, cs.header)}
        />
      ),
    },
    {
      name: 'Schedule',
      width: 200,
      render: row => (
        <div className={cs.schedule}>{renderCronString(row.cronString)}</div>
      ),
    },
    {
      name: 'Type',
      width: 200,
      render: row => <div className={cs.type}>{row.jobType}</div>,
    },
    {
      name: 'Metadata',
      width: 200,
      render: row => <JsonWithOverflow jsonObj={row.jobMetadata} />,
    },

    {
      name: '',
      width: 30,
      render: row => (
        <ExecuteIcon className={cs.icon} onClick={() => kickoffJob(row.id)} />
      ),
      showOnHover: true,
      omitCellPadding: true,
    },
    {
      name: '',
      width: 30,
      render: row => <EditIcon className={cs.icon} onClick={() => editJob(row.id)} />,
      showOnHover: true,
      omitCellPadding: true,
    },
    {
      name: '',
      width: 30,
      render: row =>
        row.jobType === 'system_job' ? (
          <div />
        ) : (
          <DeleteIcon className={cs.icon} onClick={() => deleteJob(row.id)} />
        ),
      showOnHover: true,
      omitCellPadding: true,
    },
  ]

  const renderTable = () => {
    if (loading) {
      return <div className={cs.bigMessage}>Loading jobs...</div>
    }
    if (!loading && (!jobs || jobs.length === 0)) {
      return <div className={cs.bigMessage}>No jobs found.</div>
    }

    const sortedJobs = sortBy('name', jobs)

    return (
      <Table
        columns={tableColumns}
        data={sortedJobs}
        className={cs.table}
        rowPaddingVariant='rowPaddingLow'
        rowKey='id'
      />
    )
  }

  return (
    <div className={cx(className, cs.jobs)}>
      <div className={cs.controls}>
        <div className={cs.fill} />
        <Button
          className={cs.button}
          label='Refresh Table'
          IconComponent={RefreshIcon}
          onClick={refreshJobs}
        />
        <Button
          type='primary'
          label='Add Job'
          IconComponent={AddIcon}
          external
          to={ADD_JOB_LINK}
          className={cs.button}
        />
      </div>
      {renderTable()}
      <EditJobDialog
        isOpen={editJobDialogOpen}
        onClose={() => setEditJobDialogOpen(false)}
        jobId={editJobId}
        onEditSuccess={handleEditSuccess}
      />
    </div>
  )
}

Jobs.propTypes = {
  className: PropTypes.string,
}

export default Jobs
