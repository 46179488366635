import cx from 'classnames'
import { find } from 'lodash/fp'
import PropTypes from 'prop-types'
import { ReactNode } from 'react'

import { ProcessItemWithTransferStationId } from '../StorageViz/ProcessItemWithTransferStationId.interface'
import cs from './ot_2_transfer_station_viz_view.scss'

const DECK_SLOTS = [
  ['DECK_SLOT_10', 'DECK_SLOT_11', 'DECK_SLOT_12'],
  ['DECK_SLOT_7', 'DECK_SLOT_8', 'DECK_SLOT_9'],
  ['DECK_SLOT_4', 'DECK_SLOT_5', 'DECK_SLOT_6'],
  ['DECK_SLOT_1', 'DECK_SLOT_2', 'DECK_SLOT_3'],
]

function deckSlotMatchesPrefix(deckSlot: string, prefix: string): boolean {
  // Example test-cases: DECK_SLOT_1, DECK_SLOT_1_CULTURE, and DECK_SLOT_11_TIPS
  return (
    deckSlot.startsWith(prefix) &&
    (deckSlot.length === prefix.length || deckSlot[prefix.length] === '_')
  )
}

interface Ot2TransferStationVizViewProps {
  className?: string
  transferStations: ProcessItemWithTransferStationId[]
  renderTransferStation: (
    transferStation: ProcessItemWithTransferStationId | undefined,
  ) => ReactNode
}

const Ot2TransferStationVizView = ({
  className,
  transferStations,
  renderTransferStation,
}: Ot2TransferStationVizViewProps) => {
  const renderCell = (deckSlotPrefix: string) => {
    const transferStation = find(
      _transferStation =>
        deckSlotMatchesPrefix(_transferStation.transferStationId, deckSlotPrefix),
      transferStations,
    )
    return (
      <div key={deckSlotPrefix} className={cs.cell}>
        {renderTransferStation(transferStation)}
      </div>
    )
  }

  return (
    <div className={cx(className, cs.ot2TransferStationViz)}>
      {DECK_SLOTS.map((row, i) => (
        <div key={i} className={cs.row}>
          {row.map(renderCell)}
        </div>
      ))}
    </div>
  )
}

Ot2TransferStationVizView.propTypes = {
  className: PropTypes.string,
  transferStations: PropTypes.arrayOf(
    PropTypes.shape({
      transferStationId: PropTypes.string,
    }),
  ),
  renderTransferStation: PropTypes.func,
}

export default Ot2TransferStationVizView
