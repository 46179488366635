import cx from 'classnames'
import {
  KO_SCORE_HETEROZYGOUS_BOUNDS,
  KO_SCORE_HOMOZYGOUS_BOUNDS,
  KO_SCORE_MAX,
  KO_SCORE_MIN,
} from '../data/hitpickingData'
import CleDemoHistogram from './CleDemoHistogram'
import { getLegendBarStyle } from './getLegendBarStyle'
import cs from './ko_score_histogram.scss'

export interface KoScoreHistogramProps {
  className?: string
  koScoreValues: number[]
}

const HETEROZYGOUS_COLOR = '#3994C1'
const HOMOZYGOUS_COLOR = '#8662C7'
const DEFAULT_COLOR = '#EAEAEA'

const KoScoreHistogram = ({ koScoreValues }: { koScoreValues: number[] }) => {
  return (
    <div>
      <KoScoreHistogramChart className={cs.histogram} koScoreValues={koScoreValues} />
      <KoScoreHistogramLegend className={cs.histogramLegend} />
    </div>
  )
}

const KoScoreHistogramChart = ({ className, koScoreValues }: KoScoreHistogramProps) => {
  return (
    <CleDemoHistogram
      xMin={KO_SCORE_MIN}
      xMax={KO_SCORE_MAX}
      values={koScoreValues}
      className={className}
      getBarColor={(x0: number, x1: number) => {
        if (
          x0 >= KO_SCORE_HETEROZYGOUS_BOUNDS[0] &&
          x1 <= KO_SCORE_HETEROZYGOUS_BOUNDS[1]
        ) {
          return HETEROZYGOUS_COLOR
        }
        if (
          x0 >= KO_SCORE_HOMOZYGOUS_BOUNDS[0] &&
          x1 <= KO_SCORE_HOMOZYGOUS_BOUNDS[1]
        ) {
          return HOMOZYGOUS_COLOR
        }
        return DEFAULT_COLOR
      }}
    />
  )
}

interface KoScoreHistogramLegendProps {
  className?: string
}

const KoScoreHistogramLegend = ({ className }: KoScoreHistogramLegendProps) => {
  return (
    <div className={cx(className, cs.koScoreHistogramLegend)}>
      <div className={cs.koScoreHistogramLegendBlock}>
        <div
          className={cs.heterozygous}
          style={getLegendBarStyle(KO_SCORE_HETEROZYGOUS_BOUNDS, [
            KO_SCORE_MIN,
            KO_SCORE_MAX,
          ])}
        />
        <div
          className={cs.homozygous}
          style={getLegendBarStyle(KO_SCORE_HOMOZYGOUS_BOUNDS, [
            KO_SCORE_MIN,
            KO_SCORE_MAX,
          ])}
        />
      </div>
      <div className={cs.labels}>
        <div className={cx(cs.label, cs.leftLabel)}>0</div>
        <div className={cx(cs.label, cs.midLabel)}>50</div>
        <div className={cx(cs.label, cs.rightLabel)}>100</div>
      </div>
    </div>
  )
}

export default KoScoreHistogram
