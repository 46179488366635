import PropTypes from 'prop-types'
import { IconProps } from './types/IconProps'
const ArtemysFoodsIcon = ({ className, onClick }: IconProps) => (
  <svg
    width='32'
    height='32'
    viewBox='0 0 32 32'
    xmlns='http://www.w3.org/2000/svg'
    className={className}
    onClick={onClick}
  >
    <g transform='translate(2,30) scale(0.035,-0.035)' strokeWidth='2'>
      <path d='M190 384 c-99 -202 -180 -370 -180 -375 0 -5 34 -9 75 -9 l76 0 115 237 116 236 116 -236 115 -237 79 0 c43 0 78 3 78 7 0 5 -82 173 -182 375 -159 322 -185 368 -204 368 -20 0 -45 -45 -204 -366z' />
    </g>
  </svg>
)

ArtemysFoodsIcon.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
}

export default ArtemysFoodsIcon
