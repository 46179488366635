import cx from 'classnames'
import Select from '~/components/Select'
import cs from './simple_selector.scss'

export interface SimpleSelectorOption {
  id: string
  value: string
}

export function SimpleSelector({
  label,
  options,
  selectedOption,
  onOptionSelected,
  className,
  optionClassName,
}: {
  label: string
  options: SimpleSelectorOption[]
  selectedOption: SimpleSelectorOption
  onOptionSelected: (option: SimpleSelectorOption) => void
  className?: string
  optionClassName?: string
}) {
  return (
    <Select
      className={cx(cs.selector, className)}
      itemKey='id'
      label={label}
      filterable={false}
      onChange={option => {
        onOptionSelected(option)
      }}
      activeItem={selectedOption}
      items={options}
      itemRenderer={option => (
        <div className={cx(cs.option, optionClassName)}>{option.value}</div>
      )}
      triggerClassName={cs.trigger}
      popoverClassName={cs.popover}
    />
  )
}
