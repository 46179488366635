import PropTypes from 'prop-types'
import { IconProps } from './types/IconProps'
const WarningIcon = ({ className, onClick }: IconProps) => (
  <svg
    width='32'
    height='32'
    viewBox='0 0 30 30'
    xmlns='http://www.w3.org/2000/svg'
    className={className}
    onClick={onClick}
    fill='none'
  >
    <path
      d='M16 28C22.6274 28 28 22.6274 28 16C28 9.37258 22.6274 4 16 4C9.37258 4 4 9.37258 4 16C4 22.6274 9.37258 28 16 28Z'
      strokeWidth='2'
      strokeMiterlimit='10'
    />
    <path d='M16 10V17' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M16 22V22' strokeWidth='3' strokeLinecap='round' strokeLinejoin='round' />
  </svg>
)

WarningIcon.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
}

export default WarningIcon
