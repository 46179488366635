import React from 'react'
import Pill, { PillProps } from '~/components/Pill'

// TODO: Convert this to a component <StatusPill>.
export const renderStatusPill = (
  status: string,
  // TODO: Add a more precise type than `object`
  config: object,
  props: Omit<PillProps, 'type' | 'label'>,
): React.ReactElement | null => {
  const snakeCaseToTitleCase = word =>
    word
      .split('_')
      .map(_word => _word[0].toUpperCase() + _word.slice(1).toLowerCase())
      .join(' ')
  if (!config[status]) return null

  return (
    <Pill
      {...props}
      type={config[status].type}
      label={config[status].label || snakeCaseToTitleCase(status)}
    />
  )
}
