import { areAllParametersSpecified } from '~/pages/Workcell/Routines/areAllParametersSpecified'
import { GenericScheduleFormState } from './GenericScheduleFormState.interface'

export const isFormReadyForSubmit = (formState: GenericScheduleFormState) => {
  if (formState.scheduleType === 'schedule_future' && !formState.scheduledFor) {
    return false
  }

  if (formState.samplePlateBarcodeParameter && !formState.isQcRoutine) {
    return (
      areAllParametersSpecified(
        formState.selectedRoutine,
        formState.routineParameters,
        [formState.samplePlateBarcodeParameter],
      ) && formState.samplePlateBarcodes.length > 0
    )
  } else {
    return areAllParametersSpecified(
      formState.selectedRoutine,
      formState.routineParameters,
    )
  }
}
