import cx from 'classnames'
import { Instrument, InstrumentStatus } from '~/common.interface'
import GenericInstrumentBody from './GenericInstrumentBody'
import GenericInstrumentPage from './GenericInstrumentPage'
import cs from './cytation_instrument_page.scss'

interface CytationInstrumentPageProps {
  className?: string
  instrument: Instrument
  status: InstrumentStatus
  live?: boolean
}

const CytationInstrumentPage = ({
  className,
  status,
  live,
  instrument,
}: CytationInstrumentPageProps) => {
  const renderBodyContents = () => {
    return (
      <GenericInstrumentBody
        instrument={instrument}
        views={['transfer_stations', 'list']}
        defaultView='transfer_stations'
        className={cs.bodyContents}
      />
    )
  }

  return (
    <div className={cx(className, cs.cytationInstrumentPage)}>
      <GenericInstrumentPage
        instrument={instrument}
        bodyContents={renderBodyContents()}
        status={status}
        live={live}
        className={cs.genericInstrumentPage}
      />
    </div>
  )
}

export default CytationInstrumentPage
