import { size } from 'lodash/fp'
import futureRoutinesAPI from '~/api/desktop/futureRoutines'
import Toaster from '~/components/Toaster'
import { displayCount } from '~/utils/string'

export interface QueueFutureRoutinesResult {
  succeeded_ids: number[]
}

export const queueFutureRoutines = (
  futureRoutineUuids: string[],
  submitTask,
  onQueueSuccess,
) => {
  const taskEndpoint = async () =>
    futureRoutinesAPI.queueFutureRoutines(futureRoutineUuids)
  submitTask?.(taskEndpoint, {
    onSuccess: (result: QueueFutureRoutinesResult) => {
      Toaster.show({
        message: `Successfully scheduled ${displayCount(
          'future routines',
          size(result?.succeeded_ids),
        )}`,
        intent: 'success',
      })

      onQueueSuccess()
    },
    onError: (error?: string) => {
      if (error && error.includes('Additional consumables required')) {
        Toaster.show({
          message: error,
          intent: 'warning',
          timeout: 0,
        })
      }
    },
  })
}

export const cancelFutureRoutines = async (
  futureRoutineUuids: string[],
  onCancelSuccess,
) => {
  try {
    await futureRoutinesAPI.clearFutureRoutinesByUuids(futureRoutineUuids)
    Toaster.show({
      message: `${displayCount(
        'routine',
        futureRoutineUuids.length,
      )} successfully cancelled.`,
      intent: 'success',
    })
  } catch {
    Toaster.show({
      message: `${displayCount(
        'routine',
        futureRoutineUuids.length,
      )} failed to cancel.`,
      intent: 'danger',
    })
  }
  onCancelSuccess()
}
