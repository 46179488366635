import cx from 'classnames'
import { get } from 'lodash/fp'
import { useEffect, useState } from 'react'

import workcellAPI from '~/api/desktop/workcell'
import Toaster from '~/components/Toaster'

import cs from './process_item.scss'

interface ProcessItemProps {
  className?: string
  processItem: {
    transferStationName: string
    processItem: string
    instrumentName: string
  }
  live?: boolean
}

/**
 * This allows unassigning items from transfer stations.
 *
 * This is distinct from "unloading" an item.
 * Unload an item - set location to null and also set is_checked_in to False.
 * Unassign item - set location to null.
 *
 * This functionality is generally only used during error recovery, when a process item
 * is accidentally left on a transfer station (such as the liconic or cytation transfer station) following an error.
 *
 * This is currently the only way to manually unload a process item from the liconic or cytation transfer stations.
 * Note that some customers are aware of this feature and use it for error recovery.
 * Down the line, it may make sense to move this functionality to Manually Move Items. See SWE-1164.
 *
 * We don't currently check the item out here, mainly because it's very hard to check a tiprack or reagent plate back in
 * once it's been checked out. We want to enable the user flow where the user just wants to move a tiprack from one location
 * to another. Down the line, we will want to revisit the user flows here.
 *
 * Some unorganized notes on is_checked_in:
 * - Only process items with is_checked_in=True show up in the process item dropdown in Manually Move Items.
 * - Only culture plates with is_checked_in=True show up in the sample plate dropdown in Generic Schedule.
 * - We use check-in and check-out events to determine whether cultures are live.
 * - As noted above, once a reagent plate or tiprack is checked out, it is impossible to check the same consumable back in via Load & Unload Items,
 *   because we generate a new uuid every time. However, a culture plate can be checked back in simply by reusing the barcode.
 *
 * Some unorganized notes on null locations:
 * - Once a process item's location is null, it must be moved back onto the workcell via Manually Move Items
 * (or by running a raw driver command via the Instrument page) before it can be used in a routine.
 * - If a culture plate's location is null, it can be loaded back into the workcell via Load & Unload items.
 */

const ProcessItem = ({ className, processItem, live }: ProcessItemProps) => {
  const [processItemName, setProcessItemName] = useState(
    get(['processItem', 'uuid'], processItem) || '',
  )

  const currentValue = get(['processItem', 'uuid'], processItem) || ''

  useEffect(() => {
    setProcessItemName(currentValue)
  }, [currentValue])

  useEffect(() => {
    setProcessItemName(currentValue)
  }, [live])

  const handleUnassignLocation = async () => {
    const oldValue = processItemName
    setProcessItemName('')

    let success = true
    try {
      const response = await workcellAPI.unloadTransferStation(
        processItem.instrumentName,
        processItem.transferStationName,
        oldValue,
      )
      success = response.success
    } catch (_error) {
      success = false
    }

    if (success) {
      Toaster.show({
        message:
          'Unassigned process item from transfer station. Process item is still checked into the workcell.',
        intent: 'success',
      })
    } else {
      Toaster.show({
        message: 'Failed to unassign process item from transfer station.',
        intent: 'danger',
      })
      setProcessItemName(oldValue)
    }
  }

  if (processItemName) {
    return (
      <div className={cx(className, cs.processItem)}>
        <div className={cs.itemName}>{processItemName}</div>
        {live === false && (
          <div className={cs.unloadAction} onClick={handleUnassignLocation}>
            Unassign Location
          </div>
        )}
      </div>
    )
  }

  return (
    <div className={cx(className, cs.processItem)}>
      <div className={cs.empty}>Empty</div>
    </div>
  )
}

export default ProcessItem
