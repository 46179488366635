import { color, interpolateHcl } from 'd3'
import { scaleThreshold } from 'd3-scale'
import { any, filter, flatten, floor, flow, map, range, size, sortBy } from 'lodash/fp'
import { useState } from 'react'
import { useHistory } from 'react-router'
import Histogram from '~/components/Histogram'
import { MonomerErrorBoundary } from '~/components/MonomerErrorBoundary'
import TinyMicroplate from '~/components/TinyMicroplate'
import { D3Legend } from '~/components/d3/D3Legend'
import { convertWellCoordsToWellName } from '~/utils/microplate'
import { displayCount } from '~/utils/string'
import { clearURLParam, getURLParams, pushURLParam } from '~/utils/url'
import {
  CultureViewModel,
  getCultureIssue,
  getLatestConfluency,
  omitArchivedCultures,
} from '../../events/ViewModels/CultureViewModel'
import { PlateViewModel, getAllCultures } from '../../events/ViewModels/PlateViewModel'
import { ViewModels } from '../../events/ViewModels/ViewModels'
import { useCommandCenterEventContext } from '../CommandCenterEventsContext'
import { CultureDatasetImagesDialog } from '../CultureDatasetImagesDialog/CultureDatasetImagesDialog'
import { PlateCulturePopover } from '../CulturePopover/CulturePopover'
import { DemoConfig, getProfileSettings } from '../SlasDemoConfigDialog/DemoConfig'
import { LiveCulturesFilters, SelectedLiveCulturesFilter } from './LiveCulturesFilters'
import { LiveCulturesHeader } from './LiveCulturesHeader'
import cs from './live_cultures.scss'

const DEAD_CULTURE_COLOR = '#CCCCCC'
const CELL_DEATH_COLOR = '#D64545'
const OVER_CONFLUENT_COLOR = '#FF8F4F'
const CONFLUENCY_COLORS = ['#BFF5FB', '#80D0D8', '#2CB1BC', '#F4CE76', '#FF8F4F']

export interface LiveCultureProps {
  viewModels: ViewModels
  useLocalAssets: boolean
}

const _getPlateHeaderName = (config: DemoConfig) => {
  const platePrefix = getProfileSettings(config).platePrefix
  return `${platePrefix} Plate`
}

export const LiveCultures = (props: LiveCultureProps) => {
  const [selectedCulture, setSelectedCulture] = useState<CultureViewModel | null>(null)
  const plates = Object.values(props.viewModels.plates)
  const { config } = useCommandCenterEventContext()

  const cellLineURLParam = getURLParams().get('cellLine')
  const initialCellLine = cellLineURLParam
    ? { kind: 'SPECIFIC_VALUE', value: cellLineURLParam as string }
    : { kind: 'ALL' }
  const [selectedCellLine, setSelectedCellLine] = useState<SelectedLiveCulturesFilter>(
    initialCellLine as SelectedLiveCulturesFilter,
  )
  const experimentLabelParam = getURLParams().get('experimentLabel')
  const initialExperimentLabel = experimentLabelParam
    ? { kind: 'SPECIFIC_VALUE', value: experimentLabelParam as string }
    : { kind: 'ALL' }
  const [selectedExperimentLabel, setSelectedExperimentLabel] =
    useState<SelectedLiveCulturesFilter>(
      initialExperimentLabel as SelectedLiveCulturesFilter,
    )

  const livePlates = flow(
    filter((plate: PlateViewModel) =>
      shouldRenderPlate(plate, selectedCellLine, selectedExperimentLabel),
    ),
    // Put plates with CELL_DEATH first.
    sortBy(plate => {
      const cultures = getAllCultures(plate)

      return any(
        issue => issue === 'CELL_DEATH',
        map(culture => getCultureIssue(props.viewModels.today, culture), cultures),
      )
        ? 0
        : 1
    }),
  )(plates)

  const renderLegend = () => {
    const allConfluencyValues = flow(
      map(getAllCultures),
      flatten,
      map(getLatestConfluency),
    )(livePlates)

    // Hard-code these y-max values for now to look nice.
    let yMax = 2
    if (size(allConfluencyValues) > 5) {
      yMax = 6
    }

    if (size(allConfluencyValues) > 25) {
      yMax = 20
    }

    if (size(allConfluencyValues) > 150) {
      yMax = 100
    }

    if (size(allConfluencyValues) > 500) {
      yMax = 400
    }

    return (
      <div className={cs.legend}>
        {size(allConfluencyValues) > 0 ? (
          <Histogram
            className={cs.histogram}
            data={allConfluencyValues}
            layoutOptions={{
              marginLeft: -1,
              marginRight: 1,
              marginBottom: 0,
              marginTop: 0,
            }}
            axisOptions={{
              showYAxis: false,
              showXAxis: false,
            }}
            options={{
              xDomain: [0, 100],
              yDomain: [0, yMax],
              bins: 50,
              hoverBins: 50,
              getBarColor: (x0: number, x1: number) => {
                const index = floor((x0 + x1) / 2 / 20)
                return CONFLUENCY_COLORS[index]
              },
            }}
          />
        ) : (
          <div className={cs.histogram} />
        )}

        {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          D3Legend(scaleThreshold([20, 40, 60, 80], CONFLUENCY_COLORS))
        }
        <div className={cs.label}>Confluence (%)</div>
      </div>
    )
  }

  return (
    <MonomerErrorBoundary>
      <div>
        <LiveCulturesHeader
          viewModels={props.viewModels}
          useLocalAssets={props.useLocalAssets}
        />
        <LiveCulturesFilters
          cultures={props.viewModels.cultures}
          selectedCellLine={selectedCellLine}
          onSelectCellLine={value => {
            if (value.kind == 'SPECIFIC_VALUE') {
              pushURLParam('cellLine', value.value)
            } else {
              clearURLParam('cellLine')
            }
            setSelectedCellLine(value)
          }}
          selectedExperimentLabel={selectedExperimentLabel}
          onSelectExperimentLabel={value => {
            if (value.kind == 'SPECIFIC_VALUE') {
              pushURLParam('experimentLabel', value.value)
            } else {
              clearURLParam('experimentLabel')
            }
            setSelectedExperimentLabel(value)
          }}
        />
        <div className={cs.liveCultures}>
          <div className={cs.innerHeader}>
            <div className={cs.title}>
              {displayCount(_getPlateHeaderName(config), livePlates.length)},{' '}
              {displayCount(
                'culture',
                size(omitArchivedCultures(props.viewModels.cultures)),
              )}
            </div>
            <div className={cs.spacer}></div>
            {renderLegend()}
          </div>
          <div className={cs.grid}>
            {livePlates.map(plate => (
              <LiveCulturesPlate
                key={plate.id}
                today={props.viewModels.today}
                plate={plate}
                onCultureOpen={(culture: CultureViewModel) => {
                  setSelectedCulture(culture)
                }}
              />
            ))}
            {range(0, 8).map(index => (
              <LiveCulturesPlate
                key={`filler_${index}`}
                today={props.viewModels.today}
              />
            ))}
          </div>
        </div>
        <CultureDatasetImagesDialog
          today={props.viewModels.today}
          culture={selectedCulture}
          dataset={
            selectedCulture &&
            selectedCulture.datasets[selectedCulture.datasets.length - 1]
          }
          isOpen={selectedCulture != null}
          onClose={() => {
            setSelectedCulture(null)
          }}
        />
      </div>
    </MonomerErrorBoundary>
  )
}

function shouldRenderPlate(
  plate: PlateViewModel,
  cellLineFilter: SelectedLiveCulturesFilter,
  experimentLabelFilter: SelectedLiveCulturesFilter,
): boolean {
  return (
    !plate.isArchived &&
    (cellLineFilter.kind === 'ALL' ||
      Object.values(plate.wellNameToCulture)
        .map(culture => culture.cellLine)
        .includes(cellLineFilter.value)) &&
    (experimentLabelFilter.kind === 'ALL' ||
      Object.values(plate.wellNameToCulture)
        .map(culture => culture.experimentLabel)
        .includes(experimentLabelFilter.value))
  )
}

function LiveCulturesPlate({
  today,
  plate,
  onCultureOpen,
}: {
  today: number
  plate?: PlateViewModel
  onCultureOpen?(...args: unknown[]): unknown
}) {
  const history = useHistory()

  if (!plate) {
    return <div className={cs.gridItem}></div>
  }

  return (
    <div key={plate.id} className={cs.gridItem}>
      <div className={cs.plateName}>{plate.name}</div>
      <TinyMicroplate
        plateFormat={plate.type}
        onClickCell={(rowIndex: number, colIndex: number) => {
          const wellName = convertWellCoordsToWellName(rowIndex, colIndex)
          const cultureID = plate.wellNameToCulture[wellName].id
          history.push(`/command-center/culture/${cultureID}`)
        }}
        highlights={[
          {
            colorRgbFn: (row, col) => {
              const wellName = convertWellCoordsToWellName(row, col)
              const culture = plate.wellNameToCulture[wellName]
              const maybeIssue = getCultureIssue(today, culture)
              const confluency = getLatestConfluency(culture)
              if (culture.status !== 'ACTIVE') {
                return DEAD_CULTURE_COLOR
              } else if (maybeIssue === 'CELL_DEATH') {
                return CELL_DEATH_COLOR
              } else if (maybeIssue === 'OVER_CONFLUENT') {
                return OVER_CONFLUENT_COLOR
              } else {
                return getConfluencyColor(confluency)
              }
            },
          },
        ]}
        showWarningIcon={(row, col) => {
          const wellName = convertWellCoordsToWellName(row, col)
          const culture = plate.wellNameToCulture[wellName]
          const maybeIssue = getCultureIssue(today, culture)
          return maybeIssue === 'CELL_DEATH' && culture.status === 'ACTIVE'
        }}
        getPopoverContent={(row, col) => {
          const wellName = convertWellCoordsToWellName(row, col)
          const culture = plate.wellNameToCulture[wellName]
          return (
            <PlateCulturePopover
              today={today}
              culture={culture}
              onImageClick={() => onCultureOpen && onCultureOpen(culture)}
            />
          )
        }}
        size='medium'
        shouldAnimate
      />
    </div>
  )
}

function interpolateColor(colorOne: string, colorTwo: string, value: number) {
  const rgbString = color(interpolateHcl(colorOne, colorTwo)(value))

  if (rgbString) {
    return rgbString.formatHex()
  }

  return colorOne
}

// Stretch certain ranges out more according to IPSC_CULTURE_CONFLUENCY_DATASETS.
export function getConfluencyColor(confluency: number, forText = false) {
  if (confluency < 36 && forText) {
    return CONFLUENCY_COLORS[1]
  }
  if (confluency < 20) {
    return CONFLUENCY_COLORS[0]
  }
  if (confluency >= 20 && confluency < 36) {
    return interpolateColor(
      CONFLUENCY_COLORS[0],
      CONFLUENCY_COLORS[1],
      (confluency - 20) / 16,
    )
  }
  // IPSC_CULTURE_CONFLUENCY_DATASETS rarely has values here, so keep it one color.
  if (confluency >= 36 && confluency < 50) {
    return CONFLUENCY_COLORS[1]
  }
  if (confluency >= 50 && confluency < 58) {
    return interpolateColor(
      CONFLUENCY_COLORS[1],
      CONFLUENCY_COLORS[2],
      (confluency - 50) / 8,
    )
  }
  if (confluency >= 58 && confluency < 70) {
    return CONFLUENCY_COLORS[2]
  }
  if (confluency >= 70 && confluency < 80) {
    return CONFLUENCY_COLORS[3]
  }

  return CONFLUENCY_COLORS[4]
}
