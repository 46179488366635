import { Classes, Popover2 } from '@blueprintjs/popover2'
import cx from 'classnames'
import { get, map, toPairs } from 'lodash/fp'
import { useContext } from 'react'

import Table from '~/components/Table'
import LoadingIcon from '~/components/icons/LoadingIcon'
import WorkcellStatusContext from '~/pages/Workcell/WorkcellStatusContext'

import { snakeCaseToTitleCase } from '~/utils/string'
import { getInstrumentRealTimeState } from '../../utils/workcellStatus'
import cs from './inheco_scila_last_environmental_data_popover.scss'

const TABLE_COLUMNS = [
  {
    name: 'Metric',
    width: 200,
    render: row => snakeCaseToTitleCase(row.metric),
  },
  {
    name: 'Value',
    width: 100,
    rightAlign: true,
    render: row => <div className={cs.value}>{row.value}</div>,
  },
]

interface InstrumentStatusPopoverProps {
  instrumentName: string
}

interface InhecoScilaRealTimeData {
  last_environmental_data_point: Record<string, unknown>
}

const InstrumentStatusPopover = ({ instrumentName }: InstrumentStatusPopoverProps) => {
  const workcellStatus = useContext(WorkcellStatusContext)
  const realTimeStatus = getInstrumentRealTimeState(
    workcellStatus,
    instrumentName,
  ) as InhecoScilaRealTimeData
  const metrics =
    realTimeStatus && realTimeStatus.last_environmental_data_point
      ? map(
          pairs => ({
            metric: pairs[0],
            value: pairs[1],
          }),
          toPairs(realTimeStatus.last_environmental_data_point),
        )
      : []

  const renderPopover = () => {
    return (
      <>
        <Table
          columns={TABLE_COLUMNS}
          data={metrics}
          rowKey='name'
          rowPaddingVariant='rowPaddingLow'
          className={cx(cs.table, Classes.POPOVER2_DISMISS)}
          heightSizing='default'
        />
      </>
    )
  }

  if (get('_tempStatus', workcellStatus) === 'LOADING') {
    return <LoadingIcon className={cs.loadingIcon} />
  }

  return (
    <Popover2 content={renderPopover()} placement='bottom'>
      <span className={cx(cs.popoverTrigger)}>View Latest Environmental Data</span>
    </Popover2>
  )
}

export default InstrumentStatusPopover
