import { useAuth0 } from '@auth0/auth0-react'

import Button from '~/components/buttons/Button'

import cs from './left_nav_debug_info.scss'

export default function LeftNavDebugInfo() {
  const { user } = useAuth0()
  if (!user?.email?.endsWith('@monomerbio.com')) {
    return null
  }

  if (!localStorage.getItem('show_left_nav_debug_info')) {
    return null
  }

  return (
    <div className={cs.container}>
      <Environment />
      <Commit />
      <Button
        label='X'
        variant='minimalTiny'
        onClick={() => {
          localStorage.removeItem('show_left_nav_debug_info')
          window.location.reload()
        }}
        className={cs.hideButton}
      />
    </div>
  )
}

function Environment() {
  let value = <span>{process.env.NODE_ENV}</span>

  if (process.env.AWS_BRANCH === 'master') {
    value = <span className={cs.prod}>Production</span>
  }

  if (process.env.AWS_PULL_REQUEST_ID) {
    const pr = process.env.AWS_PULL_REQUEST_ID
    value = (
      <span>
        PR Preview{' '}
        <a
          href={`https://github.com/monomerbio/monomer-core/pull/${pr}`}
          rel='noreferrer'
          target='_blank'
        >
          #{pr}
        </a>
      </span>
    )
  }

  return (
    <div className={cs.group}>
      <div className={cs.label}>Env</div>
      <div className={cs.value}>{value}</div>
    </div>
  )
}

function Commit() {
  const commit = process.env.AWS_COMMIT_ID
  if (!commit) {
    return null
  }

  return (
    <div className={cs.group}>
      <div className={cs.label}>Commit</div>
      <div className={cs.value}>
        <a
          href={`https://github.com/monomerbio/monomer-core/commit/${commit}`}
          rel='noreferrer'
          target='_blank'
        >
          {commit.slice(0, 7)}
        </a>
      </div>
    </div>
  )
}
