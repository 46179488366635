import cx from 'classnames'
import { get } from 'lodash/fp'
import PropTypes from 'prop-types'

import { displayDateDiff } from '~/utils/date'
import { renderStatusPill } from '~/utils/statusPill'

import cs from './process_step_tooltip.scss'

const STATUS_PILL_CONFIG = {
  initialized: { type: 'default' },
  scheduled: { type: 'warning' },
  in_progress: { type: 'info' },
  completed: { type: 'success' },
  canceled: { type: 'default' },
  failed: { type: 'error' },
}

const ProcessStepTooltip = ({ className, step }) => {
  if (!step) {
    return null
  }
  const displayKeyItem = () => {
    return (
      get(['routineParameters', 'plateBarcode'], step) ||
      get(['routineParameters', 'processItem'], step)
    )
  }
  const displayTime = () => {
    if (step.status === 'in_progress' || step.status === 'failed') {
      return displayDateDiff(new Date(step.startTime), new Date())
    }
    if (step.status === 'scheduled') {
      const dateDiff = displayDateDiff(new Date(step.startTime), new Date())
      return <span className={cs.scheduledDate}>Starting in {dateDiff}</span>
    }
    return null
  }

  const keyItem = displayKeyItem()

  return (
    <div className={cx(className, cs.processStepTooltip)}>
      <div className={cs.left}>
        <div className={cs.subheader}>
          {step.routineName}
          {keyItem && <>&nbsp;&middot; {keyItem}</>}
        </div>
        <div className={cs.commandName}>{step.stepDisplay || step.stepType}</div>
      </div>
      <div className={cs.fill} />
      <div className={cs.right}>
        {renderStatusPill(step.status, STATUS_PILL_CONFIG, {
          className: cs.status,
          small: true,
        })}
        <div className={cs.runtime}>{displayTime()}</div>
      </div>
    </div>
  )
}

ProcessStepTooltip.propTypes = {
  className: PropTypes.string,
  step: PropTypes.shape({
    stepDisplay: PropTypes.string,
    stepType: PropTypes.string,
    routineParameters: PropTypes.shape({
      plateBarcode: PropTypes.string,
    }),
    routineName: PropTypes.string,
    status: PropTypes.string,
    startTime: PropTypes.string,
  }),
}

export default ProcessStepTooltip
