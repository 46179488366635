import { all, get, includes, isNil, size } from 'lodash/fp'
import { WorkcellStatus } from '~/api/desktop/workcell.interface'
import { ProcessItemLike, StringOnlyProcessItem } from '~/common.interface'
import { isProcessItem } from '~/utils/processItems/common'

// Internal alias as we use this throughout the file
type MaybeWorkcellStatus = WorkcellStatus | undefined

// TODO: In general, we haven't typed WorkcellStatus state. Once we do that, we
//  should migrate the usage of lodash get to use the type system
// WARNING: workcellStatus is not type-checked right now. Please be careful that accessor functions
// do not throw an error, even if some fields on workcell status are missing.
export const getRoutineState = (workcellStatus: MaybeWorkcellStatus) => {
  return get('routine_state', workcellStatus?.state)
}

export const getStepState = (workcellStatus: MaybeWorkcellStatus) => {
  return get('step_state', workcellStatus?.state)
}

export const getRoutineIdsScheduled = (workcellStatus: MaybeWorkcellStatus) => {
  const routineState = getRoutineState(workcellStatus)
  return get('routine_ids_scheduled', routineState) || []
}

export const getRoutineIdsInProgress = (workcellStatus: MaybeWorkcellStatus) => {
  const routineState = getRoutineState(workcellStatus)
  return get('routine_ids_in_progress', routineState) || []
}

export const getProcessStepsIdsInProgress = (workcellStatus: MaybeWorkcellStatus) => {
  const stepState = getStepState(workcellStatus)
  return get('step_uuids_in_progress', stepState) || []
}

export const getProcessStepsIdsFailed = (workcellStatus: MaybeWorkcellStatus) => {
  const stepState = getStepState(workcellStatus)
  return get('step_uuids_failed', stepState) || []
}

export const getProcessStepsIdsScheduled = (workcellStatus: MaybeWorkcellStatus) => {
  const stepState = getStepState(workcellStatus)
  return get('step_uuids_scheduled', stepState) || []
}

export const hasRoutinesInProgress = (workcellStatus: MaybeWorkcellStatus): boolean => {
  const routineIdsInProgress = getRoutineIdsInProgress(workcellStatus)
  return size(routineIdsInProgress) > 0
}

export const getIsWorkcellBusy = (workcellStatus: MaybeWorkcellStatus): boolean => {
  return workcellStatus?.is_busy || false
}

export const getRoutineIds = (workcellStatus: MaybeWorkcellStatus) => {
  return [
    ...getRoutineIdsInProgress(workcellStatus),
    ...getRoutineIdsScheduled(workcellStatus),
  ]
}

export const getNumRoutinesQueued = (workcellStatus: MaybeWorkcellStatus) => {
  return size(getRoutineIds(workcellStatus))
}

export const areStepsScheduled = (workcellStatus: MaybeWorkcellStatus) => {
  const isStepStateFieldEmpty = field => {
    return (get(['state', 'step_state', field], workcellStatus) || []).length === 0
  }

  return !all(isStepStateFieldEmpty, [
    'step_uuids_failed',
    'step_uuids_scheduled',
    'step_uuids_in_progress',
  ])
}

export const getInstrumentNames = (workcellStatus: MaybeWorkcellStatus): string[] => {
  return workcellStatus?.config?.instruments || []
}

export const getAllInstrumentStatus = (workcellStatus: MaybeWorkcellStatus) => {
  return workcellStatus?.instrument_status || {}
}

export const getInstrumentStatus = (
  workcellStatus: MaybeWorkcellStatus,
  instrumentName: string,
) => {
  // TODO: Type instrument_status
  return get(instrumentName, workcellStatus?.instrument_status)
}

export const getIsInstrumentErrored = (
  workcellStatus: MaybeWorkcellStatus,
  instrumentName: string,
) => {
  // TODO: Migrate away from lodash get once we have typed instrument_status
  const instrumentStatus = getInstrumentStatus(workcellStatus, instrumentName)
  const executionState = get('execution_state', instrumentStatus)
  const driverState = get('driver_state', instrumentStatus)

  // undefined is also treated as an error.
  return (
    includes(driverState, ['STOPPED', 'ERROR', 'UNREACHABLE', undefined]) ||
    includes(executionState, ['FAULTED', 'DISCONNECTED', undefined])
  )
}

export const getIsInstrumentBusy = (
  workcellStatus: MaybeWorkcellStatus,
  instrumentName: string,
) => {
  // TODO: Migrate away from lodash get once we have typed instrument_status
  const instrumentStatus = getInstrumentStatus(workcellStatus, instrumentName)
  const executionState = get('execution_state', instrumentStatus)
  const driverState = get('driver_state', instrumentStatus)

  return !driverState || !executionState || includes(executionState, ['BUSY'])
}

export const getIsInstrumentReady = (
  workcellStatus: MaybeWorkcellStatus,
  instrumentName: string,
) => {
  // TODO: Migrate away from lodash get once we have typed instrument_status
  const instrumentStatus = getInstrumentStatus(workcellStatus, instrumentName)

  return get('execution_state', instrumentStatus) === 'READY'
}

export const getInstrumentRealTimeState = (
  workcellStatus: MaybeWorkcellStatus,
  instrumentName: string,
): unknown => {
  const instrumentStatus = getInstrumentStatus(workcellStatus, instrumentName)
  return get('real_time_state', instrumentStatus)
}

export const getSimulatedInstrumentNames = (workcellStatus: MaybeWorkcellStatus) => {
  const status = getAllInstrumentStatus(workcellStatus)

  return Object.keys(status).filter(instrumentName => {
    const instrumentStatus = status[instrumentName]
    return get('is_simulated', instrumentStatus)
  })
}

interface StringOnlyProcessItemOnTransferStation {
  uuid: string
  transferStationName: string
  instrumentName: string
}

export const displayStringOnlyProcessItemOnTransferStation = (
  item: StringOnlyProcessItemOnTransferStation,
) => {
  return `${item.uuid} (${item.transferStationName}, ${item.instrumentName})`
}

export const getStringOnlyProcessItemsOnTransferStations = (
  workcellStatus: MaybeWorkcellStatus,
): StringOnlyProcessItemOnTransferStation[] => {
  const status = getAllInstrumentStatus(workcellStatus)

  const items: StringOnlyProcessItemOnTransferStation[] = []

  Object.keys(status).forEach(instrumentName => {
    const instrumentStatus = status[instrumentName]
    const transferStations: Record<string, ProcessItemLike | undefined> | undefined =
      get('transfer_stations', instrumentStatus)

    if (transferStations) {
      Object.keys(transferStations).forEach(transferStationName => {
        const processItemLike: ProcessItemLike = transferStations[transferStationName]
        if (processItemLike && !isProcessItem(processItemLike)) {
          items.push({
            uuid: (processItemLike as StringOnlyProcessItem).uuid,
            transferStationName,
            instrumentName,
          })
        }
      })
    }
  })

  return items
}

export const getNumFutureRoutines = (workcellStatus: MaybeWorkcellStatus): number => {
  return workcellStatus?.num_future_routines || 0
}

export const getFutureRoutinesLastUpdateTime = (
  workcellStatus: MaybeWorkcellStatus,
): string | null => {
  return workcellStatus?.future_routines_last_update_time || null
}

export const getNumQueuedRoutines = (workcellStatus: MaybeWorkcellStatus): number => {
  return (
    (size(getRoutineIdsScheduled(workcellStatus)) || 0) +
    (size(getRoutineIdsInProgress(workcellStatus)) || 0)
  )
}

export const getNumDefaultQueuedRoutines = (
  workcellStatus: MaybeWorkcellStatus,
): number => {
  return workcellStatus?.num_default_scheduled_routines || 0
}

export const getNumSupportQueuedRoutines = (
  workcellStatus: MaybeWorkcellStatus,
): number => {
  return (
    getNumQueuedRoutines(workcellStatus) - getNumDefaultQueuedRoutines(workcellStatus)
  )
}

export const isWorkcellStatusReady = (workcellStatus: MaybeWorkcellStatus) => {
  return !isNil(workcellStatus)
}

export const getAllowFullColumnsOnly = (
  workcellStatus: MaybeWorkcellStatus,
): boolean => {
  return workcellStatus?.config?.allow_full_columns_only || false
}

export const getWarnOnSimulatedInstruments = (
  workcellStatus: MaybeWorkcellStatus,
): boolean => {
  return workcellStatus?.config?.warn_on_simulated_instruments || false
}

export const experimentalEnableWorkflows = (
  workcellStatus: MaybeWorkcellStatus,
): boolean => {
  return workcellStatus?.config?.experimental_enable_workflows || false
}

export const useNativeSlackIntegration = (
  workcellStatus: MaybeWorkcellStatus,
): boolean => {
  return workcellStatus?.config?.use_native_slack_integration || false
}

export const getExperimentalEnableMonitor = (
  workcellStatus: MaybeWorkcellStatus,
): boolean => {
  return workcellStatus?.config?.experimental_enable_monitor || false
}

export const getExperimentalEnableMonitorCultureDataUpload = (
  workcellStatus: MaybeWorkcellStatus,
): boolean => {
  return (
    workcellStatus?.config?.experimental_enable_monitor_culture_data_upload || false
  )
}

// Do not use this function directly, as this does not filter out
// operator actions that are guarded by feature flags.
// Use getEnabledAndUnusedOperatorActionNames instead.
export const getEnabledOperatorActions = (
  workcellStatus: MaybeWorkcellStatus,
): string[] => {
  return workcellStatus?.config?.enabled_operator_actions || []
}
