import { Popover2 } from '@blueprintjs/popover2'
import cx from 'classnames'
import dayjs from 'dayjs'
import PropTypes from 'prop-types'
import { useState } from 'react'

import DatePicker from '~/components/DatePicker'
import CalendarIcon from '~/components/icons/CalendarIcon'

import cs from './date_header.scss'

const DateHeader = ({ className, value, onChange }) => {
  const [open, setOpen] = useState(false)

  const handleDatePick = (_value, hasUserManuallySelectedDate) => {
    if (hasUserManuallySelectedDate) {
      setOpen(false)
      // _value can be null if the user selects the same date
      if (onChange && _value) {
        onChange(_value)
      }
    }
  }
  const renderDatePicker = () => {
    return <DatePicker value={value} onChange={handleDatePick} />
  }

  const renderTarget = ({ ...targetProps }) => {
    return (
      <div
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...targetProps}
        className={cx(cs.date, className)}
        onClick={() => setOpen(true)}
      >
        {dayjs(value).format('dddd, MMM D, YYYY')}
        <CalendarIcon className={cs.icon} onClick={() => setOpen(true)} />
      </div>
    )
  }
  return (
    <Popover2
      content={renderDatePicker()}
      interactionKind='click'
      isOpen={open}
      onInteraction={setOpen}
      placement='top'
      renderTarget={renderTarget}
    />
  )
}

DateHeader.propTypes = {
  className: PropTypes.string,
  value: PropTypes.instanceOf(Date),
  onChange: PropTypes.func,
}

export default DateHeader
