import { useEffect, useRef, useState } from 'react'

import Toaster from '~/components/Toaster'
import usePollAsync from '~/utils/hooks/usePollAsync'

// monomer-desktop's electron_app/preload.js sets monomerDesktopAPI.
declare global {
  interface Window {
    monomerDesktopAPI?: {
      send: (command: string, data?: unknown) => void
      receive: (
        callback: (event: unknown, command: string, data: unknown) => void,
      ) => void
      stopReceive: (
        callback: (event: unknown, command: string, data: unknown) => void,
      ) => void
    }
  }
}

const UpdateNotification = () => {
  const [version, setVersion] = useState<string | null>(null)
  // Use a ref so we get the updated value of version inside processMessage.
  // processMessage function is only created once.
  const versionRef = useRef<string | null>()
  // Use a ref so we get the updated value of toastId inside processMessage.
  // processMessage function is only created once.
  const toastIdRef = useRef<string | null>()

  useEffect(() => {
    versionRef.current = version
  })

  const updateToastMessage = message => {
    const _toastId = Toaster.show(
      { message, intent: 'warning', timeout: 0 },
      toastIdRef.current || undefined,
    )
    toastIdRef.current = _toastId
  }

  // Process a message from the Desktop app.
  const processMessage = (event, command, data) => {
    // Receive data from the Desktop app.
    if (command === 'UPDATE_AVAILABLE') {
      updateToastMessage(`Monomer Desktop v${data.version} is available.`)
      setVersion(data.version)
    }

    if (command === 'UPDATE_DOWNLOAD_PROGRESS') {
      updateToastMessage(
        `Downloading Monomer Desktop Version ${versionRef.current}: ${Math.floor(
          data.percent,
        )}%`,
      )
    }

    if (command === 'UPDATE_DOWNLOAD_COMPLETE') {
      updateToastMessage(
        `Update ready to install. (Monomer Desktop Version ${versionRef.current}). ` +
          'Please restart the app.',
      )
    }
  }

  const setup = () => {
    // Set up handler to Desktop app messages.
    if (window.monomerDesktopAPI) {
      window.monomerDesktopAPI.receive(processMessage)
      window.monomerDesktopAPI.send('CHECK_FOR_UPDATES')
      return function cleanup() {
        if (window.monomerDesktopAPI) {
          window.monomerDesktopAPI.stopReceive(processMessage)
        }
      }
    }
    return null
  }

  const checkForUpdates = async () => {
    if (window.monomerDesktopAPI) {
      window.monomerDesktopAPI.send('CHECK_FOR_UPDATES')
    }
  }

  useEffect(() => {
    setup()
  }, [])
  // Check for updates every hour.
  usePollAsync(checkForUpdates, 3600 * 1000)

  return null
}

export default UpdateNotification
