import PropTypes from 'prop-types'
import { IconProps } from './types/IconProps'
const VerticalMenuIcon = ({ className, onClick }: IconProps) => (
  <svg
    width='24'
    height='24'
    viewBox='0 0 24 24'
    xmlns='http://www.w3.org/2000/svg'
    className={className}
    onClick={onClick}
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M12 8C13.1 8 14 7.10001 14 6C14 4.89999 13.1 4 12 4C10.9 4 10 4.89999 10 6C10 7.10001 10.9 8 12 8ZM12 10C10.9 10 10 10.9 10 12C10 13.1 10.9 14 12 14C13.1 14 14 13.1 14 12C14 10.9 13.1 10 12 10ZM10 18C10 16.9 10.9 16 12 16C13.1 16 14 16.9 14 18C14 19.1 13.1 20 12 20C10.9 20 10 19.1 10 18Z'
    />
  </svg>
)

VerticalMenuIcon.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
}

export default VerticalMenuIcon
