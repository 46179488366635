import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'

import datasetsAPI from '~/api/desktop/datasets'

import CSVDownloadDialog from '~/components/CSVDownloadDialog'
import { CSVData } from '~/utils/csv'
import { displayCount } from '~/utils/string'
import { DatasetQueryParams } from './DatasetQueryParams.interface'

interface DatasetDownloadDialogProps {
  className?: string
  isOpen: boolean
  onClose: () => void
  validDatasetCount: number | null
  queryParams: DatasetQueryParams
}

const DatasetDownloadDialog = ({
  className,
  isOpen,
  onClose,
  validDatasetCount,
  queryParams,
}: DatasetDownloadDialogProps) => {
  const [csvData, setCsvData] = useState<CSVData | null>(null)
  const [loading, setLoading] = useState(false)

  const fetchDatasetsAsCsv = async () => {
    setCsvData(null)
    setLoading(true)
    const data = await datasetsAPI.getDatasetsAsCsv(queryParams)
    setCsvData(data)
    setLoading(false)
    // loading is set to false in refreshFilteredCsvData,
    // which is called whenever csvData changes.
  }

  useEffect(() => {
    if (isOpen) {
      fetchDatasetsAsCsv()
    }
  }, [isOpen, queryParams])

  return (
    <CSVDownloadDialog
      className={className}
      isOpen={isOpen}
      onClose={onClose}
      loading={loading}
      csvData={csvData}
      title={`Download ${displayCount('Dataset', validDatasetCount || 0)}`}
    />
  )
}

DatasetDownloadDialog.propTypes = {
  className: PropTypes.string,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  queryParams: PropTypes.shape({}),
  // This should be the number of datasets that match the provided queryParams.
  // This dialog box will not fetch those datasets again, and relies on this number to be correct.
  datasetCount: PropTypes.number,
}

export default DatasetDownloadDialog
