import PropTypes from 'prop-types'
import { IconProps } from './types/IconProps'

const IntegrationIcon = ({ className, onClick }: IconProps) => (
  <svg
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    onClick={onClick}
    className={className}
  >
    <path
      id='icon/action/swap_horiz_24px'
      fillRule='evenodd'
      clipRule='evenodd'
      d='M17.01 5L21 9L17.01 13V10H10V8H17.01V5ZM3 15L6.98999 11V14H14V16H6.98999V19L3 15Z'
    />
  </svg>
)

IntegrationIcon.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
}

export default IntegrationIcon
