import { zip } from 'lodash'
import { DemoConfig } from '~/pages/slasDemo/CommandCenter/SlasDemoConfigDialog/DemoConfig'
import { makePlateSeededEvent } from '~/pages/slasDemo/events/data/generate/generatePlateSeededEvents'
import { CulturePassaged, DemoEvent } from '../../DemoEvent'
import { CultureViewModel } from '../../ViewModels/CultureViewModel'
import { generateEventTime } from '../eventTimes'
import { generatePlateName } from '../generate/generatePlateName'

export function passageCultures(
  parentCultures: CultureViewModel[],
  onDay: number,
  numExistingPlates: number,
  config: DemoConfig,
): DemoEvent[] {
  const eventTime = generateEventTime({
    eventDay: onDay,
    eventKind: 'CulturePassaged',
  })

  // For now, passaging always goes into 6 well plates, regardless of the parent plate type.
  const plateSeededEvents = parentCultures.map((parent, newPlateIndex) =>
    makePlateSeededEvent({
      at: eventTime,
      day: onDay,
      plateName: generatePlateName(numExistingPlates + newPlateIndex + 1, config),
      cellLine: parent.cellLine,
      experimentLabel: parent.experimentLabel,
      plateFormat: 'wells_6',
    }),
  )

  const passageEvents: CulturePassaged[] = zip(parentCultures, plateSeededEvents).map(
    ([culture, newPlateEvent]) => ({
      kind: 'CulturePassaged',
      day: onDay,
      at: eventTime,
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      parentCultureID: culture!.id,
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      childCultureIDs: Object.values(newPlateEvent!.wellNameToCulture).map(
        culture => culture.id,
      ),
    }),
  )

  return [...plateSeededEvents, ...passageEvents]
}
