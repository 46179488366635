import { mapValues } from 'lodash'
import { EventState } from '../EventState'
import {
  CultureProcessLogEvent,
  CultureProcessLogMap,
} from '../ViewModels/CultureProcessLogViewModel'

export function projectCultureProcessLogs(
  eventState: EventState,
  startAt: { eventIndex: number; cultureProcessLogs: CultureProcessLogMap },
): CultureProcessLogMap {
  const eventsToProcess = eventState.eventLog.slice(startAt.eventIndex)

  const cultureProcessLogs: CultureProcessLogMap = mapValues(
    startAt.cultureProcessLogs,
    log => ({ events: [...log.events] }),
  )

  for (const { data: event } of eventsToProcess) {
    if (event.kind === 'CultureImaged') {
      pushEvent(cultureProcessLogs, event.cultureID, event)
    } else if (event.kind === 'CultureMediaExchanged') {
      pushEvent(cultureProcessLogs, event.cultureID, event)
    } else if (event.kind === 'PlateCheckedIn' || event.kind === 'PlateSeeded') {
      for (const culture of Object.values(event.wellNameToCulture)) {
        pushEvent(cultureProcessLogs, culture.id, event)
      }
    } else if (event.kind === 'CulturePassaged') {
      pushEvent(cultureProcessLogs, event.parentCultureID, {
        kind: 'CultureConsumed',
        at: event.at,
        day: event.day,
        cultureID: event.parentCultureID,
      })
    } else if (
      event.kind === 'UserScheduledPassages' ||
      event.kind === 'UserTerminatedCultures' ||
      event.kind === 'UserMarkedCulturesOkay'
    ) {
      for (const cultureID of event.cultureIDs) {
        pushEvent(cultureProcessLogs, cultureID, event)
      }
    }
  }

  return cultureProcessLogs
}

function pushEvent(
  cultureProcessLogs: CultureProcessLogMap,
  cultureID: string,
  event: CultureProcessLogEvent,
) {
  if (cultureProcessLogs[cultureID] == null) {
    cultureProcessLogs[cultureID] = { events: [] }
  }
  cultureProcessLogs[cultureID].events.push(event)
}
