import { get } from 'lodash/fp'
import { TeachToolSeqFile } from '~/api/desktop/drivers/roboticArm'

// This is brittle and relies on the static_storage_dsl instrument transport
// implementation in the back-end.
export const sequenceRequiresStorageLocationParam = (seqFile: TeachToolSeqFile) => {
  return (
    get(['derived_params', 'static_storage_nest', 'source_param'], seqFile.seq_data) ===
    'storage_loc'
  )
}
