import cx from 'classnames'
import { sortBy } from 'lodash/fp'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'

import desktopAPI from '~/api/desktop'
import Table from '~/components/Table'
import Button from '~/components/buttons/Button'
import RefreshIcon from '~/components/icons/RefreshIcon'

import CustomScriptsDialog from '../components/CustomScriptsDialog'

import cs from './custom_scripts.scss'

const CustomScripts = ({ className }) => {
  const [selectedCustomScript, setSelectedCustomScript] = useState(null)
  const [showCustomScriptDialog, setShowCustomScriptDialog] = useState(false)
  const [loading, setLoading] = useState(false)
  const [routineDefinitions, setCustomScripts] = useState([])

  const fetchCustomScripts = async () => {
    const _jobs = await desktopAPI.getCustomScripts()
    setCustomScripts(_jobs)
    setLoading(false)
  }

  const refreshCustomScripts = () => {
    setCustomScripts([])
    setLoading(true)
    fetchCustomScripts()
  }

  const handleCustomScriptDialogClose = () => {
    setSelectedCustomScript(null)
    setShowCustomScriptDialog(false)
  }

  const handleRowClicked = row => {
    setSelectedCustomScript(row)
    setShowCustomScriptDialog(true)
  }

  useEffect(() => {
    refreshCustomScripts()
  }, [])

  const tableColumns = [
    {
      name: 'Name',
      width: 'flex',
      render: row => <div className={cs.eventType}>{row}.py</div>,
    },
  ]

  const renderTable = () => {
    if (loading) {
      return <div className={cs.bigMessage}>Loading custom scripts...</div>
    }
    if (!loading && (!routineDefinitions || routineDefinitions.length === 0)) {
      return <div className={cs.bigMessage}>No custom scripts found.</div>
    }

    const sortedCustomScripts = sortBy('name', routineDefinitions)

    return (
      <Table
        columns={tableColumns}
        data={sortedCustomScripts}
        className={cs.table}
        rowPaddingVariant='rowPaddingLow'
        rowKey='id'
        onRowClick={handleRowClicked}
      />
    )
  }

  return (
    <div className={cx(className, cs.customScripts)}>
      <div className={cs.controls}>
        <div className={cs.fill} />
        <Button
          className={cs.button}
          label='Refresh Table'
          IconComponent={RefreshIcon}
          onClick={refreshCustomScripts}
        />
      </div>
      {renderTable()}
      <CustomScriptsDialog
        scriptName={selectedCustomScript}
        isOpen={showCustomScriptDialog}
        onClose={handleCustomScriptDialogClose}
      />
    </div>
  )
}

CustomScripts.propTypes = {
  className: PropTypes.string,
  workcellStatus: PropTypes.shape({
    live: PropTypes.bool,
  }),
}

export default CustomScripts
