import Dialog from '~/components/Dialog'
import PropTypes from '~/propTypes'

import { ProcessStep } from '../../types/ProcessStep.interface'
import RetryProcessStepWarning from './RetryProcessStepWarning'
import cs from './retry_process_step_dialog.scss'

const RetryProcessStepDialog = ({
  isOpen,
  onClose,
  step,
  onConfirm,
}: {
  isOpen: boolean
  onClose: () => void
  step?: ProcessStep
  onConfirm: () => void
}) => {
  return (
    <Dialog isOpen={isOpen} onClose={onClose} className={cs.cancelProcessStepDialog}>
      {step && (
        <RetryProcessStepWarning step={step} onCancel={onClose} onConfirm={onConfirm} />
      )}
    </Dialog>
  )
}

RetryProcessStepDialog.propTypes = {
  isOpen: PropTypes.bool,
  step: PropTypes.ProcessStep,
  onConfirm: PropTypes.func,
  onClose: PropTypes.func,
}

export default RetryProcessStepDialog
