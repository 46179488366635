import cx from 'classnames'
import Link from '~/components/Link'
import ReadOnlyField from '~/components/ReadOnlyField'
import { CultureIssue } from '~/pages/slasDemo/CommandCenter/CulturePage/CultureIssue'
import {
  CultureViewModel,
  NEXT_ACTION_USER_STRING,
  getCultureCreatedAt,
  getDatasetCreatedAt,
} from '../../events/ViewModels/CultureViewModel'
import { getRelativeTime } from '../../events/data/eventTimes'
import { useCommandCenterEventContext } from '../CommandCenterEventsContext'
import { CultureGrowthChart } from '../CultureGrowthChart/CultureGrowthChart'
import CultureImage from '../CulturePage/CultureImage'
import { getProfileSettings } from '../SlasDemoConfigDialog/DemoConfig'
import {
  getCellLineDisplayLabel,
  getExperimentLabelDisplayLabel,
} from '../displayLabel'
import cs from './culture_popover.scss'

export function PlateCulturePopover({
  today,
  culture,
  onImageClick,
}: {
  today: number
  culture: CultureViewModel
  onImageClick?(...args: unknown[]): unknown
}) {
  // TODO: Check for uses of isArchived, consider whether status === "ACTIVE"
  // is more appropriate

  const { config } = useCommandCenterEventContext()
  const cultureCreatedAt = getRelativeTime(getCultureCreatedAt(today, culture))
  const latestDataset = culture.datasets[culture.datasets.length - 1]
  const datasetCapturedAt = getRelativeTime(getDatasetCreatedAt(today, latestDataset))

  return (
    <div className={cs.popover}>
      <CultureIssue culture={culture} day={today} className={cs.issue} />
      <div className={cs.popoverHeader}>
        <div className={cs.popoverHeaderLeft}>
          <div className={cs.popoverHeaderLabel}>
            {culture.plateName}, Well {culture.wellName}
          </div>
          <Link
            to={`/command-center/culture/${culture.id}`}
            className={cs.popoverHeaderCulture}
            variant='primary'
          >
            {culture.name}
          </Link>
          <div className={cs.popoverHeaderMetadata}>
            {culture.creationKind === 'PlateSeeded' ? 'Seeded' : 'Checked in'}{' '}
            {cultureCreatedAt}
          </div>
        </div>
        <div className={cs.popoverHeaderRight}>
          <CultureGrowthChart culture={culture} className={cs.popoverChart} />
        </div>
      </div>
      <div className={cs.popoverData}>
        <div className={cs.popoverDataTitle}>
          <span className={cs.confluency}>{latestDataset.confluency}% Confluent</span>{' '}
          <span className={cs.capturedAt}>{datasetCapturedAt}</span>
        </div>
        <div className={cs.thumbnailContainer} onClick={onImageClick}>
          <CultureImage
            className={cs.popoverImage}
            thumbnail={latestDataset.thumbnails[0][0]}
          />
        </div>
      </div>
      <div className={cs.popoverMetadata}>
        <ReadOnlyField
          label={getCellLineDisplayLabel(config)}
          value={culture.cellLine}
          className={cx(cs.popoverMetadataField, cs.popoverMetadataHighlight)}
          condensed
        />
        {getProfileSettings(config).showCellBankMetadataField && (
          <ReadOnlyField
            label='Source Cell Bank'
            value='WCB1'
            className={cs.popoverMetadataField}
            condensed
          />
        )}
        <ReadOnlyField
          label='Passage Number'
          value={culture.passageNumber}
          className={cs.popoverMetadataField}
          condensed
        />
        <ReadOnlyField
          label={getExperimentLabelDisplayLabel(config)}
          value={culture.experimentLabel}
          className={cx(cs.popoverMetadataField, cs.popoverMetadataHighlight)}
          condensed
        />
        {getProfileSettings(config).showNextScheduledAction && (
          <ReadOnlyField
            label='Next Action'
            value={
              culture.nextScheduledAction
                ? NEXT_ACTION_USER_STRING[culture.nextScheduledAction]
                : '-'
            }
            className={cs.popoverMetadataField}
            condensed
          />
        )}
      </div>
    </div>
  )
}
