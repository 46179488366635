import { assign } from 'lodash/fp'
import { useEffect, useState } from 'react'

import operatorActionAPI from '~/api/operatorActions/shared'

export interface UseConfigResult<TConfig> {
  config: TConfig | null
  handleConfigUpdate: (newConfig: TConfig, overwrite?: boolean) => void
}

// TODO(mark): Maybe share this outside of operator actions.
function useConfig<TConfig extends Record<string, unknown>>(
  fetchConfig: () => Promise<TConfig>,
): UseConfigResult<TConfig> {
  const [config, setConfig] = useState<TConfig | null>(null)

  const fetchInitialConfig = async () => {
    const _config = await fetchConfig()
    setConfig(_config)
  }

  const handleConfigUpdate = (newValues: TConfig, overwrite = false) => {
    if (overwrite) {
      setConfig(newValues)
    } else {
      setConfig(assign(config, newValues))
    }
  }

  useEffect(() => {
    fetchInitialConfig()
  }, [])

  return { config, handleConfigUpdate }
}

function useOperatorActionConfig<TConfig>(
  operatorActionName: string,
): UseConfigResult<TConfig> {
  const { config, handleConfigUpdate } = useConfig(() =>
    operatorActionAPI.getConfig(operatorActionName),
  )

  return { config, handleConfigUpdate }
}

export default useOperatorActionConfig
