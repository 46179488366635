import { useEffect, useState } from 'react'
import workflowDefinitionsAPI, {
  NodeWithExecutionTime,
  WorkflowDefinition,
} from '~/api/desktop/workflowDefinitions'
import Dialog from '~/components/Dialog'

import dayjs from 'dayjs'
import HelpPopover from '~/components/HelpPopover'
import LoadingMessage from '~/components/LoadingMessage'
import Table from '~/components/Table'
import Toaster from '~/components/Toaster'
import TinyNotification from '~/components/notifications/TinyNotification'
import cs from './workflow_definition_viz_dialog.scss'

interface WorkflowDefinitionVizDialogProps {
  isOpen: boolean
  onClose: () => void
  selectedWorkflowDefinition?: WorkflowDefinition
}

const WorkflowDefinitionVizDialog = ({
  selectedWorkflowDefinition,
  isOpen,
  onClose,
}: WorkflowDefinitionVizDialogProps) => {
  const [nodeSchedule, setNodeSchedule] = useState<NodeWithExecutionTime[] | null>()
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState('')
  const [isValidating, setIsValidating] = useState(false)
  const fetchNodeSchedule = async () => {
    setNodeSchedule(null)
    setError('')
    if (!selectedWorkflowDefinition) return
    setLoading(true)
    try {
      const response = await workflowDefinitionsAPI.allNodesScheduled(
        selectedWorkflowDefinition.db_id,
      )
      setNodeSchedule(response.nodes)
    } catch (e) {
      setError('Could not load workflow definition')
    }
    setLoading(false)
  }

  useEffect(() => {
    fetchNodeSchedule()
  }, [selectedWorkflowDefinition])

  const handleValidate = async () => {
    if (!selectedWorkflowDefinition) return
    setIsValidating(true)
    workflowDefinitionsAPI
      .validateWorkflowDefinition(selectedWorkflowDefinition.db_id)
      .then(response => {
        setIsValidating(false)
        if (response.is_valid) {
          Toaster.show({
            message: `Workflow definition is valid for placeholder inputs`,
            intent: 'success',
          })
        } else {
          Toaster.show({
            message: `Workflow definition is invalid due to: ${response.reason}`,
            intent: 'danger',
          })
        }
      })
      .catch(e => {
        setIsValidating(false)
        console.error(e)
        Toaster.show({
          message: `Failed to validate workflow definition`,
          intent: 'danger',
        })
      })
  }

  if (!selectedWorkflowDefinition) {
    return null
  }

  const tableColumns = [
    {
      name: 'Step',
      width: 'flex',
      render: (node: NodeWithExecutionTime) => (
        <div>
          <div className={cs.stepName}>{node.node_key}</div>
          <HelpPopover
            className={cs.stepInfo}
            text={node.routine_reference.routine_name}
            popoverStyle={'minimal'}
            helpContent={JSON.stringify(
              node.routine_reference.routine_parameters,
              null,
              2,
            )}
            interactionKind={'hover'}
            placement='top'
            elementType='div'
          />
        </div>
      ),
    },
    {
      name: 'Schedule',
      width: 125,
      render: (node: NodeWithExecutionTime) => (
        <div className={cs.schedule}>
          {dayjs
            .duration(node.execution_time_minute, 'minutes')
            .format('[Day] D, H [hours]')}
        </div>
      ),
    },
  ]

  const renderContainer = () => {
    if (loading) {
      return (
        <div className={cs.container}>
          <LoadingMessage
            label='Loading Workflow Definition...'
            className={cs.loadingMessageBox}
          />
        </div>
      )
    }
    if (error) {
      return (
        <div className={cs.container}>
          <TinyNotification className={cs.error} message={error} type='bareError' />
        </div>
      )
    }
    if (nodeSchedule) {
      return (
        <Table
          className={cs.table}
          rowPaddingVariant={'rowPaddingLow'}
          heightSizing={'flexAuto'}
          columns={tableColumns}
          data={nodeSchedule}
          rowKey={'node_key'}
        />
      )
    }
    return null
  }

  return (
    <Dialog
      isOpen={isOpen}
      onClose={onClose}
      className={cs.workflowDefinitionViewDialog}
    >
      <div className={cs.title}>{selectedWorkflowDefinition.name}</div>
      {renderContainer()}
      <Dialog.Footer loadingMessage={isValidating ? 'Validating Workflow' : undefined}>
        <Dialog.FooterButton type='primary' label='Validate' onClick={handleValidate} />
      </Dialog.Footer>
    </Dialog>
  )
}

export default WorkflowDefinitionVizDialog
