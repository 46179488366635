import { Instrument } from '~/common.interface'
import { SelectedStorageLocation } from '~/pages/Workcell/components/StorageViz/StorageVizView'
import { getLocationString } from '~/utils/location'
import {
  ProcessItemUnloadParamsRequest,
  ReloadOperationRequest,
  StorageParamsRequest,
} from '../../reloadItems/convertChangeToReloadOperation'
import { LoadUnloadItemsProcessItemType } from '../LoadUnloadItemsProcessItemType.interface'

export const getUnloadStorageOperation = (
  instrument: Instrument,
  storageLocation: SelectedStorageLocation,
  processItemType: LoadUnloadItemsProcessItemType,
  useArmAssist: boolean,
  manuallyMove: boolean,
  removeFromCurrentWorkflow: boolean,
): ReloadOperationRequest => {
  const uuid = storageLocation.itemUuid

  if (!uuid) {
    throw Error(
      `Could not find valid itemUuid for selected storage Location ${getLocationString(
        storageLocation.shelfIndex,
        storageLocation.levelIndex,
      )}`,
    )
  }

  return {
    instrument_name: instrument.instrumentName,
    command: 'unload_process_item',
    command_params: {
      level_index: storageLocation.levelIndex,
      shelf_index: storageLocation.shelfIndex,
    } as StorageParamsRequest,
    type: processItemType,
    process_item_params: {
      uuid,
      remove_from_current_workflow: removeFromCurrentWorkflow,
    } as ProcessItemUnloadParamsRequest,
    use_arm_assist: useArmAssist,
    manually_move: manuallyMove,
  }
}
