import cx from 'classnames'
import { filter, findIndex, get, orderBy, set, size } from 'lodash/fp'
import PropTypes from 'prop-types'
import { useContext, useEffect, useState } from 'react'

import routineAPI from '~/api/desktop/routines'
import workcellAPI from '~/api/desktop/workcell'
import CheckboxOption from '~/components/CheckboxOption'
import Toaster from '~/components/Toaster'
import Button from '~/components/buttons/Button'
import ConfirmCancelAllRoutinesDialog from '~/pages/Workcell/WorkcellHeader/ConfirmCancelAllRoutinesDialog'
import WorkcellStatusContext from '~/pages/Workcell/WorkcellStatusContext'
import ScheduledRoutineList from '~/pages/Workcell/components/routine/ScheduledRoutineList'
import { getNumQueuedRoutines } from '~/pages/Workcell/utils/workcellStatus'
import { displayCount } from '~/utils/string'

import { ScheduledRoutine } from '../../types/ScheduledRoutine.interface'
import cs from './queued_routines.scss'

interface QueuedRoutinesProps {
  className?: string
}

const QueuedRoutines = ({ className }: QueuedRoutinesProps) => {
  const workcellStatus = useContext(WorkcellStatusContext)
  const [routines, setRoutines] = useState<ScheduledRoutine[]>([])
  const [loading, setLoading] = useState(true)
  const [includeSupport, setIncludeSupport] = useState(false)
  const [showConfirmCancelAllRoutinesDialog, setShowConfirmCancelAllRoutinesDialog] =
    useState(false)

  const refreshQueuedRoutines = async () => {
    const _routines = await routineAPI.list({
      hasScheduledStatus: '1',
      verbose: 1,
    })

    setRoutines(_routines)
    setLoading(false)
  }

  useEffect(() => {
    refreshQueuedRoutines()
  }, [getNumQueuedRoutines(workcellStatus)])

  const handleRoutineCancel = async (routine: ScheduledRoutine) => {
    const index = findIndex(['id', routine.id], routines)
    setRoutines(set([index, 'status'], 'canceled', routines))
    try {
      await workcellAPI.bulkCancelRoutines([routine.id])
      Toaster.show({
        message: `${routine.routineDefinition?.name} successfully canceled. The workcell may still run the corresponding support routines.`,
        intent: 'warning',
      })
    } catch (e) {
      Toaster.show({
        message: `${routine.routineDefinition?.name} failed to cancel: ${String(e)}`,
        intent: 'danger',
      })
    }
  }

  const handleAllRoutinesCancel = () => {
    setShowConfirmCancelAllRoutinesDialog(true)
  }

  const getFilteredRoutines = () => {
    let filteredRoutines = routines

    if (!includeSupport) {
      filteredRoutines = filter(
        routine => get(['routineDefinition', 'type'], routine) === 'default',
        filteredRoutines,
      )
    }

    filteredRoutines = orderBy(
      routine => routine.startTime || routine.estimatedStartTime,
      'asc',
      filteredRoutines,
    )

    return filteredRoutines
  }

  const getNumSupportRoutines = () => {
    return size(
      filter(
        routine => get(['routineDefinition', 'type'], routine) !== 'default',
        routines,
      ),
    )
  }

  const getNumDefaultRoutines = () => {
    return size(
      filter(
        routine => get(['routineDefinition', 'type'], routine) === 'default',
        routines,
      ),
    )
  }

  return (
    <div className={cx(className, cs.queuedRoutines)}>
      <div className={cs.header}>
        <div className={cs.title}>
          {displayCount('Queued Routine', getNumDefaultRoutines())}
        </div>
        <div className={cs.fill} />
        <Button
          type='normal'
          label='Cancel All'
          disabled={size(routines) === 0}
          onClick={handleAllRoutinesCancel}
        />
      </div>
      <div className={cs.controls}>
        <CheckboxOption
          label={`Show ${displayCount('Support Routine', getNumSupportRoutines())}`}
          checked={includeSupport}
          onClick={setIncludeSupport}
          className={cs.option}
          allCaps
        />
      </div>
      <ScheduledRoutineList
        loading={loading}
        routines={getFilteredRoutines()}
        className={cs.routineList}
        onRoutineCancel={handleRoutineCancel}
        timeDisplayType='datetime'
      />
      <ConfirmCancelAllRoutinesDialog
        isOpen={showConfirmCancelAllRoutinesDialog}
        onClose={() => setShowConfirmCancelAllRoutinesDialog(false)}
      />
    </div>
  )
}

QueuedRoutines.propTypes = {
  className: PropTypes.string,
}

export default QueuedRoutines
