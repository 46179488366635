import MicroplateIcon from '~/components/icons/MicroplateIcon'
import LoadUnloadItemsEmptyCulturePlateForm, {
  getEmptyCulturePlateLoadParamsRequestOrError,
} from '~/pages/Workcell/OperatorActions/LoadUnloadItems/LoadUnloadItemsProcessItemForm/LoadUnloadItemsEmptyCulturePlateForm'
import { ProcessItemTypeConfig } from './ProcessItemTypeConfig.interface'

export const LOAD_UNLOAD_ITEMS_EMPTY_CULTURE_PLATE_CONFIG: ProcessItemTypeConfig<'empty_culture_plate'> =
  {
    getLoadParamsRequestOrError: getEmptyCulturePlateLoadParamsRequestOrError,
    formComponent: LoadUnloadItemsEmptyCulturePlateForm,
    iconComponent: MicroplateIcon,
  }
