import MinimalButton from '~/components/buttons/MinimalButton'
import TinyNotification from '~/components/notifications/TinyNotification'

import ConfigIcon from '~/components/icons/ConfigIcon'
import cs from './technician_tools_tab.scss'

export interface TechnicianToolsTabProps {
  className?: string
  onRawStateEditClick(): void
  onGlobalConfigEditClick(): void
}

const TechnicianToolsTab = ({
  onRawStateEditClick,
  onGlobalConfigEditClick,
}: TechnicianToolsTabProps) => {
  return (
    <div>
      <TinyNotification
        type='bareWarning'
        message='For Monomer service technicians only. Improper use of these tools can crash the
        workcell and cause data loss.'
        className={cs.warningNotification}
      />
      <div className={cs.toolContainer}>
        <MinimalButton
          type='primary'
          label='Edit Raw Execution State'
          className={cs.tool}
          IconComponent={ConfigIcon}
          onClick={onRawStateEditClick}
        />
        <MinimalButton
          type='primary'
          label='Edit Global Config'
          className={cs.tool}
          IconComponent={ConfigIcon}
          onClick={onGlobalConfigEditClick}
        />
      </div>
    </div>
  )
}

export default TechnicianToolsTab
