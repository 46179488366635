// Plate types supported
export type supportedPlateFormats = 'wells_6' | 'wells_12' | 'wells_24' | 'wells_96'
export const SUPPORTED_PLATE_DIMENSIONS = [
  { rows: 2, columns: 3 },
  { rows: 3, columns: 4 },
  { rows: 4, columns: 6 },
  { rows: 8, columns: 12 },
]

// Rendering size supported
export type supportedSize = 'micro' | 'tiny' | 'medium' | 'large' | 'extraLarge'
