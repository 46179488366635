import { LoadUnloadItemCulturePlateParams } from '../LoadUnloadItemsProcessItemParams.interface'
import { GetReloadOperationsOrErrorContext } from './GetReloadOperationsOrErrorContext.interface'

export const validateReloadOperationWorkflowChanges = (
  context: GetReloadOperationsOrErrorContext,
) => {
  if (context.loadOrUnload === 'load' && context.processItemType == 'culture_plate') {
    const processItemParams =
      context.processItemParams as LoadUnloadItemCulturePlateParams

    if (processItemParams.workflowDefinition && context.isWorkcellBusy) {
      return 'Cannot add culture plate to a new workflow if workcell is busy. You can check in the culture plate while the workcell is busy and set the workflow later.'
    }
  }

  if (context.loadOrUnload === 'unload') {
    if (context.removeFromCurrentWorkflow && context.isWorkcellBusy) {
      return 'Cannot remove culture plate from workflow if workcell is busy. You can check out the culture plate now and terminate the workflow later.'
    }
  }

  return null
}
