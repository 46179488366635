import cx from 'classnames'
import PropTypes from 'prop-types'

import AddIcon from '~/components/icons/AddIcon'
import SubtractIcon from '~/components/icons/SubtractIcon'
import { getChangeName } from '~/pages/Workcell/utils/processItemChange'

import cs from './process_item_change.scss'

const ProcessItemChange = ({ className, change }) => {
  if (change.command === 'load_process_item') {
    return (
      <div className={cx(cs.processItemChange, className)}>
        <AddIcon className={cs.icon} />
        <div className={cs.text}>
          <div className={cs.main}>{getChangeName(change)}</div>
          <div className={cs.label}>
            Shelf {change.shelfIndex}, Level {change.levelIndex}
          </div>
        </div>
      </div>
    )
  }

  if (change.command === 'unload_process_item') {
    return (
      <div className={cx(cs.processItemChange, className)}>
        <SubtractIcon className={cs.icon} />
        <div className={cs.text}>
          <div className={cs.main}>{getChangeName(change)}</div>
          <div className={cs.label}>
            Shelf {change.shelfIndex}, Level {change.levelIndex}
          </div>
        </div>
      </div>
    )
  }

  if (change.command === 'load_transfer_station') {
    return (
      <div className={cx(cs.processItemChange, className)}>
        <AddIcon className={cs.icon} />
        <div className={cs.text}>
          <div className={cs.main}>{getChangeName(change)}</div>
          <div className={cs.label}>{change.transferStationId}</div>
        </div>
      </div>
    )
  }

  if (change.command === 'unload_transfer_station') {
    return (
      <div className={cx(cs.processItemChange, className)}>
        <SubtractIcon className={cs.icon} />
        <div className={cs.text}>
          <div className={cs.main}>{getChangeName(change)}</div>
          <div className={cs.label}>{change.transferStationId}</div>
        </div>
      </div>
    )
  }

  return null
}

ProcessItemChange.propTypes = {
  className: PropTypes.string,
  change: PropTypes.shape({
    transferStationId: PropTypes.string,
    command: PropTypes.string,
    uuid: PropTypes.string,
    shelfIndex: PropTypes.number,
    levelIndex: PropTypes.number,
  }),
}

export default ProcessItemChange
