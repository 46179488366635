import { find } from 'lodash/fp'
import memoize from 'memoize-one'
import { AllowedInstrumentForItemTypeResponse } from '~/api/desktop/workcell.interface'
import { Instrument } from '~/common.interface'
import { InstrumentDescriptorForItemType } from './InstrumentDescriptorForItemType.interface'

export const getAllowedInstruments = memoize(
  (
    _allInstruments: Instrument[],
    allowed_instruments_for_item_type:
      | AllowedInstrumentForItemTypeResponse[]
      | undefined,
  ): InstrumentDescriptorForItemType[] => {
    if (!allowed_instruments_for_item_type) {
      return []
    }

    const allowed_instruments: InstrumentDescriptorForItemType[] = []

    allowed_instruments_for_item_type.forEach(instrument => {
      const _instrument = find(
        ['instrumentName', instrument.instrument_name],
        _allInstruments,
      )
      if (_instrument) {
        allowed_instruments.push({
          instrument: _instrument,
          allowed_partitions: instrument.allowed_partitions || undefined,
          allowed_transfer_stations: instrument.allowed_transfer_stations || undefined,
        })
      }
    })

    return allowed_instruments
  },
)
