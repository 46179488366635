import { get, map } from 'lodash/fp'
import PropTypes from 'prop-types'

import desktopAPI from '~/api/desktop'
import commonDriverAPI from '~/api/desktop/commonDriver'
import Dialog from '~/components/Dialog'
import Tabs from '~/components/Tabs'
import {
  INSTRUMENT_DISPLAY_NAMES,
  SCRIPT_TYPES,
  getSupportedScripts,
} from '~/utils/instrument'

import ConfigDialogTab from '~/components/Dialog/ConfigDialogTab'
import { JsonSchemaResponse } from '~/types/JsonSchema.interface'
import S3UploadScriptTab from './S3UploadScriptTab'
import StructureRawDataScriptTab from './StructureRawDataScriptTab'
import cs from './driver_config_dialog.scss'

const SCRIPT_DIALOG_COMPONENT = {
  [SCRIPT_TYPES.s3Upload]: S3UploadScriptTab,
  [SCRIPT_TYPES.structureRawData]: StructureRawDataScriptTab,
}

const SCRIPT_DIALOG_NAME = {
  [SCRIPT_TYPES.s3Upload]: 'S3 Upload',
  [SCRIPT_TYPES.structureRawData]: 'Data Transform',
}

interface DriverConfigDialogProps {
  isOpen: boolean
  onClose: () => void
  instrumentName: string
  instrumentType: string
  onConfigUpdated?: () => void
}

const DriverConfigDialog = ({
  isOpen,
  onClose,
  instrumentName,
  instrumentType,
  onConfigUpdated,
}: DriverConfigDialogProps) => {
  if (!instrumentName) return null

  const supportedScripts = getSupportedScripts(instrumentType)
  const instrumentTypeDisplay = INSTRUMENT_DISPLAY_NAMES[instrumentType]

  const renderDriverSettings = () => {
    const api = {
      getSchema: async () => {
        const response = await commonDriverAPI.getConfigSchemaV2(instrumentName)

        return {
          schema: response.config_schema,
        } as unknown as JsonSchemaResponse
      },
      setConfig: async (newConfig, overwrite) =>
        commonDriverAPI.setConfigV2(instrumentName, newConfig, overwrite, true),
      getConfig: async () => {
        const response = await commonDriverAPI.getConfigV2(instrumentName)
        return response.config
      },
    }
    return (
      <ConfigDialogTab api={api} onClose={onClose} onConfigUpdated={onConfigUpdated} />
    )
  }

  const renderPcSettings = () => {
    const api = {
      getSchema: desktopAPI.getGlobalConfigSchema,
      setConfig: desktopAPI.setGlobalConfig,
      getConfig: desktopAPI.getGlobalConfig,
    }
    return <ConfigDialogTab api={api} onClose={onClose} />
  }

  const renderScriptTab = script => {
    const api = {
      getScript: async () => {
        const response = await commonDriverAPI.getScript(instrumentName, script.type)
        return response.code || ''
      },
      setScript: _script =>
        commonDriverAPI.setScript(instrumentName, script.type, _script),
    }
    const Component = SCRIPT_DIALOG_COMPONENT[script.type]
    return (
      <Component
        api={api}
        onClose={onClose}
        metadata={get('metadata', script)}
        example={get('example', script)}
      />
    )
  }

  const tabs = [
    {
      key: 'driver_settings',
      title: `${instrumentTypeDisplay} Settings`,
      panel: renderDriverSettings(),
    },
    {
      key: 'pc_settings',
      title: 'PC Settings',
      panel: renderPcSettings(),
    },
    ...map(
      script => ({
        key: script.type,
        title: SCRIPT_DIALOG_NAME[script.type],
        panel: renderScriptTab(script),
      }),
      supportedScripts,
    ),
  ]

  return (
    <Dialog isOpen={isOpen} onClose={onClose} className={cs.driverConfigDialog}>
      <div className={cs.title}>Edit {instrumentTypeDisplay} Settings</div>
      <Tabs
        tabs={tabs}
        initialTab='driver_settings'
        className={cs.tabs}
        panelClassName={cs.tabPanel}
      />
    </Dialog>
  )
}

DriverConfigDialog.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  instrumentName: PropTypes.string,
  instrumentType: PropTypes.string,
}

export default DriverConfigDialog
