import cx from 'classnames'
import React from 'react'

import LoadingMessage from '~/components/LoadingMessage'
import ZoomInIcon from '~/components/icons/ZoomInIcon'
import cs from './culture_image.scss'

export interface CultureImageProps {
  className?: string
  thumbnail: string
  placeholderHeight?: number
}

const CultureImage = ({
  className,
  thumbnail,
  placeholderHeight,
}: CultureImageProps) => {
  const [imageLoaded, setImageLoaded] = React.useState(false)

  return (
    <div className={cx(className, cs.cultureImage)}>
      {imageLoaded ? null : (
        // TODO: Refine the height to be more conditional
        <div
          className={`${cs.imagePlaceholder}`}
          style={{ height: placeholderHeight ?? 200 }}
        >
          <LoadingMessage label={'Loading image...'} />
        </div>
      )}
      <div className={imageLoaded ? cs.cultureImageInner : cs.cultureImageHidden}>
        <div className={cs.overlay}>
          <div className={cs.overlayMessage}>
            <ZoomInIcon className={cs.icon} />
            View Image
          </div>
        </div>
        <img
          className={cs.thumbnail}
          src={thumbnail}
          onLoad={() => setImageLoaded(true)}
        />
      </div>
    </div>
  )
}

export default CultureImage
