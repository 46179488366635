import cx from 'classnames'
import PropTypes from 'prop-types'

import TextWithOverflow from '~/components/TextWithOverflow'

import HelpPopover from './HelpPopover'
import cs from './pill.scss'

export type PillType =
  | 'default'
  | 'success'
  | 'error'
  | 'warning'
  | 'info'
  | 'accent'
  | 'inactive'
  | 'checkIn'
  | 'checkOut'
  | 'cleDemoBlue'
  | 'cleDemoPurple'

export interface PillProps {
  label?: string
  type?: PillType
  className?: string
  inverted?: boolean
  small?: boolean
  onClick?: () => void
  popoverContent?: string
}

const Pill = ({
  label,
  type,
  className,
  inverted,
  small,
  onClick,
  popoverContent,
}: PillProps) => {
  if (popoverContent) {
    return (
      <HelpPopover
        className={cx(
          className,
          cs.pill,
          small && cs.small,
          type && cs[type],
          inverted && cs.inverted,
        )}
        text={label}
        elementType='div'
        helpContent={popoverContent}
        smallText={false}
        interactionKind='hover'
      />
    )
  }

  return (
    <TextWithOverflow
      className={cx(
        className,
        cs.pill,
        small && cs.small,
        type && cs[type],
        inverted && cs.inverted,
      )}
      onClick={onClick}
      text={label}
      popoverContent={label}
    />
  )
}

Pill.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  type: PropTypes.oneOf([
    'default',
    'success',
    'error',
    'warning',
    'info',
    'accent',
    'inactive',
    'checkIn',
    'checkOut',
    'cleDemoBlue',
    'cleDemoPurple',
  ]),
  inverted: PropTypes.bool,
  small: PropTypes.bool,
  onClick: PropTypes.func,
  popoverContent: PropTypes.string,
}

Pill.defaultProp = {
  type: 'default',
}

export default Pill
