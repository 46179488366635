import { DesktopAPI } from '~/api/core'
import { client, unwrap } from '~/api/desktopAPIv2'
import {
  RoutineDSL,
  RoutineDefinition,
} from '~/pages/Workcell/types/RoutineDefinition.interface'
import { components } from './generated-schema'

const desktopAPI = new DesktopAPI('/api')

export type DefaultSuccessResponse = components['schemas']['DefaultSuccessResponse']

export default {
  getGlobalConfigSchema: () => desktopAPI.get('global-configs/schema/'),
  getGlobalConfig: () => desktopAPI.get('global-configs/'),
  setGlobalConfig: (newConfig, overwrite) =>
    desktopAPI.post('global-configs/', {
      config: newConfig,
      overwrite,
    }),
  getTaskStatus: taskUuid => desktopAPI.get(`tasks/by-uuid/${taskUuid}/`),
  getBulkTaskStatus: taskUuids =>
    desktopAPI.get('tasks/by-uuids/', {
      taskUuids,
    }),
  getInstrumentDrivers: params => desktopAPI.get('instrument-drivers/', params),
  getInstrumentDriverByName: name =>
    desktopAPI.get(`instrument-drivers/by-name/${name}`),
  deleteInstrumentDriver: id => desktopAPI.del(`instrument-drivers/${id}`),
  getValidInstrumentTypes: () => desktopAPI.get('instrument-drivers/list-valid-types'),
  getProcessStepsPaginate: params => desktopAPI.get('process-steps/paginate/', params),
  getEventHandlers: () => desktopAPI.get('event-handlers/'),
  deleteEventHandler: id => desktopAPI.del(`event-handlers/${id}/`),
  getJobs: () => desktopAPI.get('jobs/'),
  getJob: id => desktopAPI.get(`jobs/${id}/`),
  deleteJob: id => desktopAPI.del(`jobs/${id}/`),
  getTasks: params => desktopAPI.get('tasks/', params),
  kickoffJob: id => desktopAPI.post(`jobs/${id}/kickoff/`),
  createRoutineDefinition: (
    routineDefinitionEditableFields,
  ): Promise<RoutineDefinition> =>
    desktopAPI.post('routine-definitions/', routineDefinitionEditableFields),
  updateRoutineDefinition: (
    id,
    routineDefinitionEditableFields,
  ): Promise<RoutineDefinition> =>
    desktopAPI.patch(`routine-definitions/${id}/`, routineDefinitionEditableFields),
  getRoutineDefinitions: (params?: unknown): Promise<RoutineDefinition[]> =>
    desktopAPI.get('routine-definitions/', params),
  deleteRoutineDefinition: id => desktopAPI.del(`routine-definitions/${id}/`),
  getRoutineDefinitionTemplateJson: id =>
    desktopAPI.get(`routine-definitions/${id}/template-json/`),
  bulkRoutineDefinitionTemplateJsonsByName: (
    routineNames: string[],
  ): Promise<{ templateJsons: { [routineName: string]: RoutineDSL } }> =>
    desktopAPI.get('routine-definitions/bulk-template-json-by-name', {
      routineNames,
    }),
  getCustomScripts: () => desktopAPI.get('custom-scripts/list/'),
  getCustomScriptCode: scriptName =>
    desktopAPI.get('custom-scripts/code/', {
      scriptName,
    }),
  executeCustomScript: (scriptName, scriptParameters, scriptType) =>
    desktopAPI.post('custom-scripts/execute/', {
      scriptParameters,
      scriptType,
      scriptName,
    }),
  // Execute as POST even though it's a getter, so we can send parameters as JSON more easily.
  executeCustomScriptAsGetter: (scriptName, scriptParameters, scriptType) =>
    desktopAPI.post('custom-scripts/execute-as-getter/', {
      scriptParameters,
      scriptType,
      scriptName,
    }),
  updateEventHandler: (id, fields) => desktopAPI.patch(`event-handlers/${id}/`, fields),
  emitEvent: (eventType, eventProperties) =>
    desktopAPI.post('events/emit/', {
      eventType,
      eventProperties,
    }),
  getWorkcellProfiles: () => desktopAPI.get('workcell-profiles/'),
  getRoutineDefinitionSchema: () =>
    unwrap(client().GET('/api/v2/routine-definitions/schema', {})),
}
