import cx from 'classnames'
import { includes, map, reject, sortBy } from 'lodash/fp'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'

import instrumentDriverAPI from '~/api/desktop/instrumentDrivers'
import workcellAPI from '~/api/desktop/workcell'
import Dialog from '~/components/Dialog'
import Button from '~/components/buttons/Button'
import AddIcon from '~/components/icons/AddIcon'
import InstrumentSelect from '~/pages/Workcell/components/instrument/InstrumentSelect'

import { Instrument } from '~/common.interface'
import cs from './add_instrument_dialog.scss'

const AddInstrumentDialog = ({
  className,
  isOpen,
  onClose,
  onInstrumentAdd,
}: {
  className?: string
  isOpen: boolean
  onClose: () => void
  onInstrumentAdd: (instrument: Instrument) => void
}) => {
  const [selectedInstrument, setSelectedInstrument] = useState<Instrument | null>(null)
  const [instruments, setInstruments] = useState<Instrument[] | null>(null)

  const fetchInstruments = async () => {
    const [_workcellInstruments, _drivers] = await Promise.all([
      workcellAPI.getInstruments(),
      instrumentDriverAPI.get(),
    ])

    const workcellInstrumentNames = map('instrumentName', _workcellInstruments)
    // Exclude instruments already added to the workcell.
    let availableInstruments = reject(
      instrument => includes(instrument.instrumentName, workcellInstrumentNames),
      _drivers,
    )

    availableInstruments = sortBy('instrumentName', availableInstruments)

    setInstruments(availableInstruments)

    if (availableInstruments && availableInstruments.length > 0) {
      setSelectedInstrument(availableInstruments[0])
    }
  }

  const handleSelectInstrument = (instrument: Instrument) => {
    setSelectedInstrument(instrument)
  }

  // Do this whenever we open dialog.
  useEffect(() => {
    if (isOpen) {
      fetchInstruments()
    }
  }, [isOpen])

  return (
    <Dialog
      isOpen={isOpen}
      onClose={onClose}
      className={cx(className, cs.addInstrumentDialog)}
    >
      <div className={cs.title}>Add Instrument To Workcell</div>
      <InstrumentSelect
        instruments={instruments}
        selectedInstrument={selectedInstrument}
        onInstrumentSelect={handleSelectInstrument}
        className={cs.instrumentSelect}
      />
      <div className={cs.footer}>
        <Button
          className={cs.button}
          label='Add Instrument'
          IconComponent={AddIcon}
          type='primary'
          disabled={!selectedInstrument}
          onClick={
            selectedInstrument ? () => onInstrumentAdd(selectedInstrument) : undefined
          }
        />
      </div>
    </Dialog>
  )
}

AddInstrumentDialog.propTypes = {
  className: PropTypes.string,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onInstrumentAdd: PropTypes.func,
}

export default AddInstrumentDialog
