import { ProcessStep } from '../types/ProcessStep.interface'

export const getProcessStepDisplay = (processStep: ProcessStep) => {
  if (processStep.stepDisplay) {
    // The name of the sequence
    return processStep.stepDisplay
  }
  const { commandName } = processStep

  if (commandName === 'execute_sequence_from_json') {
    // The name of the sequence
    return processStep.commandParams[0]
  }

  return processStep.commandName
}
