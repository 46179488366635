import { isNumber } from 'lodash/fp'
import React from 'react'

import InputWithValidation from './InputWithValidation'

interface InputNumberProps {
  value?: number
  onChange: (newNumber: number | null) => void
  className?: string
  label?: string
  inputClassName?: string
  placeholder?: string
  disabled?: boolean
}

const InputNumber = React.forwardRef<HTMLInputElement, InputNumberProps>(
  (props, ref) => {
    const { value, onChange, className, label, inputClassName, placeholder, disabled } =
      props

    const validateNumberString = (v: string) => {
      try {
        const value = JSON.parse(v)
        return isNumber(value)
      } catch (error) {
        return false
      }
    }

    const parseNumberString = (v: string) => {
      try {
        const value = JSON.parse(v)
        if (isNumber(value)) return value
      } catch (error) {
        console.error(String(error)) // eslint-disable-line no-console
      }

      return null
    }

    return (
      <InputWithValidation<number>
        ref={ref}
        validateInput={validateNumberString}
        parseInput={parseNumberString}
        errorMessage='Invalid number'
        value={value}
        onChange={onChange}
        className={className}
        label={label}
        inputClassName={inputClassName}
        placeholder={placeholder}
        disabled={disabled}
      />
    )
  },
)

export default InputNumber
