import cx from 'classnames'
import { isEmpty } from 'lodash'
import React, { useEffect, useState } from 'react'
import processItemsAPI from '~/api/desktop/processItems'
import { ProcessItem } from '~/common.interface'
import { getComparatorStringForShallowObject } from '~/utils/array'
import { ProcessItemFilters } from './ProcessItemFilters'
import ProcessItemSelect from './ProcessItemSelect'
import { getProcessItemFiltersWithDefaults } from './getProcessItemFiltersWithDefaults'
import cs from './managed_process_item_select.scss'

export function ManagedProcessItemSelect({
  selectedProcessItem,
  onProcessItemSelected,
  processItemFilters,
  processItemUuidsToDisplayAsSelected,
  label,
  hideLabel,
  disabled,
  className,
  variant,
  placeholder,
  hideArrow,
  triggerClassName,
  noBorder,
  stayOpenOnSelect,
}: {
  selectedProcessItem: ProcessItem | null
  onProcessItemSelected: (plate: ProcessItem | null) => void
  label?: string
  hideLabel?: boolean
  disabled?: boolean
  processItemFilters?: ProcessItemFilters
  processItemUuidsToDisplayAsSelected?: string[]
  className?: string
  variant?: 'default' | 'narrow'
  placeholder?: React.ReactNode
  hideArrow?: boolean
  triggerClassName?: string
  noBorder?: boolean
  stayOpenOnSelect?: boolean
}) {
  const [processItems, setProcessItems] = useState<ProcessItem[]>([])

  const fetchProcessItems = async () => {
    return processItemsAPI.getProcessItems(
      getProcessItemFiltersWithDefaults(processItemFilters || {}),
    )
  }

  useEffect(() => {
    fetchProcessItems().then(items => {
      setProcessItems(items)
      onProcessItemSelected(null)
    })
  }, [getComparatorStringForShallowObject(processItemFilters)])

  return (
    <ProcessItemSelect
      // TODO: We could probably come up with a better label default using the process itemttypes
      label={label ?? 'Process Item'}
      hideLabel={hideLabel}
      processItems={processItems}
      // TODO: Hack required due to downstream component expecting initial null state
      selectedProcessItem={
        isEmpty(selectedProcessItem) ? undefined : selectedProcessItem
      }
      onProcessItemSelect={(processItem: ProcessItem) => {
        onProcessItemSelected(processItem)
      }}
      processItemUuidsToDisplayAsSelected={processItemUuidsToDisplayAsSelected}
      className={cx(cs.select, className)}
      triggerClassName={cx(triggerClassName, cs.trigger, {
        [cs.narrow]: variant === 'narrow',
      })}
      popoverClassName={cx(cs.popover, { [cs.narrow]: variant === 'narrow' })}
      renderProcessItemViz
      disabled={disabled}
      placeholder={placeholder}
      hideArrow={hideArrow}
      noBorder={noBorder}
      stayOpenOnSelect={stayOpenOnSelect}
    />
  )
}
