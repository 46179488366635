import PropTypes from 'prop-types'
import { IconProps } from './types/IconProps'
const MonitorIcon = ({ className, onClick }: IconProps) => (
  <svg
    width='32'
    height='32'
    viewBox='0 0 32 32'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    className={className}
    onClick={onClick}
  >
    <path
      d='M6 24L26 24C27.1046 24 28 23.1046 28 22L28 8C28 6.89543 27.1046 6 26 6L6 6C4.89543 6 4 6.89543 4 8L4 22C4 23.1046 4.89543 24 6 24Z'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path d='M20 28H12' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
  </svg>
)

MonitorIcon.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
}

export default MonitorIcon
