import { useContext, useEffect, useState } from 'react'

import { flatten, includes } from 'lodash/fp'
import MicroplateWellSelect from '~/components/MicroplateWellSelect'
import WorkcellStatusContext from '~/pages/Workcell/WorkcellStatusContext'
import PlateBarcodeInput from '~/pages/Workcell/components/processItem/PlateBarcodeInput'
import { experimentalEnableWorkflows } from '~/pages/Workcell/utils/workcellStatus'
import { contentsToWellNames, getWellMatrix } from '~/utils/microplate'
import {
  CulturePlateLoadParamsRequest,
  WorkflowCreateParams,
} from '../../reloadItems/convertChangeToReloadOperation'
import { LabwareSelect } from '../LabwareSelect'
import { LoadParamsRequestOrError } from '../LoadParamsRequestOrError.interface'
import { LoadUnloadItemCulturePlateParams } from '../LoadUnloadItemsProcessItemParams.interface'
import { WorkflowDefinitionSelect } from '../WorkflowDefinitionSelect'
import { LoadUnloadItemsProcessItemFormComponentProps } from './LoadUnloadItemsProcessItemFormComponentProps.interface'
import cs from './load_unload_items_culture_plate_form.scss'

import workflowInstancesAPI, { WorkflowInstance } from '~/api/desktop/workflowInstances'
import DateInput from '~/components/DateInput'
import WorkflowIcon from '~/components/icons/WorkflowsIcon'

const LoadUnloadItemsCulturePlateForm = ({
  className,
  config,
  processItemParams,
  setProcessItemParams,
  disabled,
}: LoadUnloadItemsProcessItemFormComponentProps<'culture_plate'>) => {
  const workcellStatus = useContext(WorkcellStatusContext)
  const workflowsEnabled = experimentalEnableWorkflows(workcellStatus)
  const [lastSelectedLabwareName, setLastSelectedLabwareName] = useState<
    string | undefined
  >(undefined)
  const [lastSelectedWorkflowDefinitionName, setLastSelectedWorkflowDefinitionName] =
    useState<string | undefined>(undefined)

  const [lastSelectedWorkflowStartTime, setLastSelectedWorkflowStartTime] =
    useState<Date>(new Date())

  const [workflowInstance, setWorkflowInstance] = useState<
    WorkflowInstance | undefined
  >(undefined)

  // Eventually, the process item endpoint may return the workflow instance together
  // with the culture plate.
  // But for now, we fetch it separately.
  const fetchWorkflowInstance = async () => {
    if (processItemParams.existingCulturePlate?.workflowInstanceUuid) {
      const response = await workflowInstancesAPI.retrieve(
        processItemParams.existingCulturePlate.workflowInstanceUuid,
      )
      setWorkflowInstance(response.workflow_instance)
    }
  }

  useEffect(() => {
    fetchWorkflowInstance()
  }, [processItemParams.existingCulturePlate?.workflowInstanceUuid])

  useEffect(() => {
    // Labware and workflow definition are set via override props,
    // because we need the LabwareSelect and WorkflowDefinitionSelect to provide the full object.
    setProcessItemParams(
      (_latestProcessItemParams: Partial<LoadUnloadItemCulturePlateParams>) => {
        if (!processItemParams.existingCulturePlate) {
          return _latestProcessItemParams
        }

        const contents = processItemParams.existingCulturePlate.state?.contents

        return {
          ..._latestProcessItemParams,
          specificWellsToFill: contents ? contentsToWellNames(contents) : [],
        }
      },
    )
  }, [processItemParams.existingCulturePlate])

  useEffect(() => {
    // Set specificWellsToFill to all wells when labware changes, UNLESS existingCulturePlate is set.
    if (processItemParams.selectedLabware) {
      setProcessItemParams(
        (_latestProcessItemParams: Partial<LoadUnloadItemCulturePlateParams>) => {
          if (!processItemParams.existingCulturePlate) {
            return {
              ..._latestProcessItemParams,
              ...(_latestProcessItemParams.selectedLabware?.plate_format
                ? {
                    specificWellsToFill: flatten(
                      getWellMatrix(
                        _latestProcessItemParams.selectedLabware?.plate_format,
                      ),
                    ),
                  }
                : {}),
            }
          }

          return _latestProcessItemParams
        },
      )
    }
  }, [processItemParams.selectedLabware])

  return (
    <div className={className}>
      <PlateBarcodeInput
        inputClassName={cs.uuidInput}
        value={processItemParams.uuid}
        onChange={uuid => {
          setProcessItemParams(
            (_latestProcessItemParams: Partial<LoadUnloadItemCulturePlateParams>) => ({
              ..._latestProcessItemParams,
              uuid: uuid,
            }),
          )
        }}
        existingPlateOrError={{
          error: processItemParams.uuidError,
          existingPlate: processItemParams.existingCulturePlate,
        }}
        onExistingPlateOrErrorUpdate={({ error, existingPlate }) => {
          setProcessItemParams(
            (_latestProcessItemParams: Partial<LoadUnloadItemCulturePlateParams>) => ({
              ..._latestProcessItemParams,
              uuidError: error,
              existingCulturePlate: existingPlate,
            }),
          )
        }}
        validationOptions={{
          allowExistingPlate: true,
          processItemType: 'culture_plate',
        }}
        disabled={disabled}
      />
      <LabwareSelect
        processItemType='culture_plate'
        className={cs.labwareSelect}
        defaultLabwareName={lastSelectedLabwareName}
        overrideLabwareName={processItemParams.existingCulturePlate?.labwareName}
        selectedLabware={processItemParams.selectedLabware}
        onLabwareSelect={labware => {
          setLastSelectedLabwareName(labware?.labware_name)
          setProcessItemParams(
            (_latestProcessItemParams: Partial<LoadUnloadItemCulturePlateParams>) => ({
              ..._latestProcessItemParams,
              selectedLabware: labware,
            }),
          )
        }}
        triggerClassName={cs.trigger}
        popoverClassName={cs.popover}
        disabled={disabled || !!processItemParams.existingCulturePlate}
      />
      {includes('culture_plate', config.itemTypesWithSelectableWells) &&
        processItemParams.selectedLabware && (
          <div className={cs.selectWellsContainer}>
            <MicroplateWellSelect
              className={cs.wellSelect}
              label='Select wells'
              plateFormat={processItemParams.selectedLabware.plate_format}
              size='large'
              selectedWellArray={processItemParams.specificWellsToFill || []}
              onSelectedWellArrayUpdate={specificWellsToFill => {
                setProcessItemParams(
                  (
                    _latestProcessItemParams: Partial<LoadUnloadItemCulturePlateParams>,
                  ) => ({
                    ..._latestProcessItemParams,
                    specificWellsToFill: specificWellsToFill,
                  }),
                )
              }}
              showWellNames={false}
              disabled={disabled}
            />
          </div>
        )}
      {workflowsEnabled && (
        <>
          <div className={cs.workflowsSectionTitle}>
            <WorkflowIcon className={cs.workflowsIcon} />
            Set a Workflow
          </div>
          <WorkflowDefinitionSelect
            className={cs.workflowDefinitionSelect}
            defaultWorkflowDefinitionName={lastSelectedWorkflowDefinitionName}
            selectedWorkflowDefinition={processItemParams.workflowDefinition}
            overrideWorkflowDefinitionName={
              processItemParams.existingCulturePlate?.workflowInstanceUuid &&
              workflowInstance?.workflow_definition_id
            }
            onWorkflowDefinitionSelect={_workflowDefinition => {
              setLastSelectedWorkflowDefinitionName(_workflowDefinition?.name)
              setProcessItemParams(
                (
                  _latestProcessItemParams: Partial<LoadUnloadItemCulturePlateParams>,
                ) => ({
                  ..._latestProcessItemParams,
                  workflowDefinition: _workflowDefinition,
                }),
              )
            }}
            triggerClassName={cs.trigger}
            popoverClassName={cs.popover}
            disabled={
              disabled || !!processItemParams.existingCulturePlate?.workflowInstanceUuid
            }
          />
          {processItemParams.workflowDefinition &&
            !processItemParams.existingCulturePlate?.workflowInstanceUuid && (
              <DateInput
                value={processItemParams.workflowStartAt}
                onChange={_workflowStartAt => {
                  if (_workflowStartAt) {
                    setLastSelectedWorkflowStartTime(new Date(_workflowStartAt))
                  }
                  setProcessItemParams(
                    (
                      _latestProcessItemParams: Partial<LoadUnloadItemCulturePlateParams>,
                    ) => ({
                      ..._latestProcessItemParams,
                      workflowStartAt: _workflowStartAt || undefined,
                    }),
                  )
                }}
                placeholder={'Select date'}
                defaultDate={lastSelectedWorkflowStartTime}
                className={cs.dateInput}
                showTime
                showActionsBar
                label='Start Time'
                disabled={disabled}
                todayButtonText='Now'
              />
            )}
        </>
      )}
    </div>
  )
}

export const getCulturePlateLoadParamsRequestOrError = (
  processItemParams: Partial<LoadUnloadItemCulturePlateParams>,
): LoadParamsRequestOrError => {
  if (!processItemParams.selectedLabware) {
    return {
      error: 'Please select a labware.',
    }
  }

  if (!processItemParams.uuid) {
    return {
      error: 'Please enter a barcode.',
    }
  }

  if (processItemParams.uuidError) {
    return {
      error: processItemParams.uuidError,
    }
  }
  let workflowCreateParams: WorkflowCreateParams | null = null
  if (processItemParams.workflowDefinition?.name && processItemParams.workflowStartAt) {
    workflowCreateParams = {
      workflow_definition_name: processItemParams.workflowDefinition?.name,
      start_at: processItemParams.workflowStartAt,
    }
  }

  return {
    params: {
      labware_name: processItemParams.selectedLabware.labware_name,
      uuid: processItemParams.uuid,
      specific_wells_to_fill: processItemParams.specificWellsToFill,
      workflow_create_params: workflowCreateParams,
    } as CulturePlateLoadParamsRequest,
  }
}

export default LoadUnloadItemsCulturePlateForm
