import cx from 'classnames'
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom'

import Tabs from '~/components/Tabs'
import useTabsWithUrl from '~/utils/hooks/useTabsWithUrlPart'

import CultureOverview from './CultureOverview'
import CulturePageHeader from './CulturePageHeader'

import { CultureProcessLogMap } from '../../events/ViewModels/CultureProcessLogViewModel'
import {
  CultureViewModel,
  CultureViewModelMap,
} from '../../events/ViewModels/CultureViewModel'
import { MediaLotViewModelMap } from '../../events/ViewModels/MediaLotViewModel'
import CultureImages from './CultureImages'
import { CultureProcessLogView } from './CultureProcessLogView'
import cs from './culture_page.scss'

const TABS = [
  {
    key: 'overview',
    title: 'Overview',
  },
  {
    key: 'images',
    title: 'Images',
  },
  {
    key: 'process-log',
    title: 'Process Log',
  },
]

export interface CulturePageProps {
  className?: string
  today: number
  culture: CultureViewModel
  allCultures: CultureViewModelMap
  mediaLots: MediaLotViewModelMap
  cultureProcessLogs: CultureProcessLogMap
}

const CulturePage = ({
  className,
  today,
  culture,
  allCultures,
  mediaLots,
  cultureProcessLogs,
}: CulturePageProps) => {
  const match = useRouteMatch()

  const [tabs, selectedTabId, handleTabChange] = useTabsWithUrl(TABS)

  return (
    <div className={cx(className, cs.culturePage)}>
      <CulturePageHeader className={cs.header} culture={culture} today={today} />
      <Tabs
        tabs={tabs}
        selectedTabId={selectedTabId}
        className={cs.tabs}
        panelClassName={cs.tabPanel}
        onlyRenderWhenViewed
        empty
        onTabChange={handleTabChange}
      />
      <Switch>
        <Route
          exact
          path={match.path}
          render={() => <Redirect to={`${match.url}/overview`} />}
        />
        <Route
          path={`${match.path}/overview`}
          render={() => (
            <CultureOverview
              today={today}
              culture={culture}
              allCultures={allCultures}
              mediaLots={mediaLots}
            />
          )}
        />
        <Route
          path={`${match.path}/images`}
          render={() => <CultureImages today={today} culture={culture} />}
        />
        <Route
          path={`${match.path}/process-log`}
          render={() => (
            <CultureProcessLogView processLog={cultureProcessLogs[culture.id]} />
          )}
        />
      </Switch>
    </div>
  )
}

export default CulturePage
