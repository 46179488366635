import cx from 'classnames'

import Button from '~/components/buttons/Button'
import TinyNotification from '~/components/notifications/TinyNotification'
import { displayCount } from '~/utils/string'
import { LoadUnloadItemsConfig } from './LoadUnloadItemsAction'
import {
  LoadUnloadItemsProcessItemType,
  getDisplayNameForLoadUnloadItemsProcessItemType,
} from './LoadUnloadItemsProcessItemType.interface'
import { ReloadOperationsOrError } from './getReloadOperationsOrError/ReloadOperationsOrError.interface'
import cs from './load_unload_items_footer.scss'

export interface LoadUnloadItemsFooterProps {
  className?: string
  config: LoadUnloadItemsConfig
  reloadOperationsOrError: ReloadOperationsOrError
  loadOrUnload: 'load' | 'unload'
  onSubmit: () => void
  executingTask: boolean
  taskProgressMessage?: string
  selectedProcessItemType: LoadUnloadItemsProcessItemType
}

interface Message {
  message: string
  type: 'bareSuccess' | 'bareError'
}

const getMessage = (reloadOperationsOrError: ReloadOperationsOrError): Message => {
  if (reloadOperationsOrError.error) {
    return {
      message: reloadOperationsOrError.error,
      type: 'bareError',
    }
  }

  if (
    !reloadOperationsOrError.operations ||
    reloadOperationsOrError.operations.length === 0
  ) {
    return {
      message: 'No items to load',
      type: 'bareError',
    }
  }

  return {
    message: reloadOperationsOrError.success || '',
    type: 'bareSuccess',
  }
}

const getButtonMessage = (
  reloadOperationsOrError: ReloadOperationsOrError,
  selectedProcessItemType: LoadUnloadItemsProcessItemType,
  loadOrUnload,
  itemTypeDisplayAliases: Record<string, string>,
) => {
  const numItems = reloadOperationsOrError.operations
    ? reloadOperationsOrError.operations.length
    : 0
  const displayName = getDisplayNameForLoadUnloadItemsProcessItemType(
    selectedProcessItemType,
    itemTypeDisplayAliases,
  )
  if (numItems > 0) {
    return loadOrUnload === 'load'
      ? `Load ${displayCount(displayName, numItems)}`
      : `Unload ${displayCount(displayName, numItems)}`
  }
  return loadOrUnload === 'load' ? `Load ${displayName}` : `Unload ${displayName}`
}

const getExecutingButtonMessage = (
  reloadOperationsOrError: ReloadOperationsOrError,
  selectedProcessItemType: LoadUnloadItemsProcessItemType,
  loadOrUnload,
  itemTypeDisplayAliases: Record<string, string>,
) => {
  const numItems = reloadOperationsOrError.operations
    ? reloadOperationsOrError.operations.length
    : 0
  const displayName = getDisplayNameForLoadUnloadItemsProcessItemType(
    selectedProcessItemType,
    itemTypeDisplayAliases,
  )
  if (numItems > 0) {
    return loadOrUnload === 'load'
      ? `Loading ${displayCount(displayName, numItems)}...`
      : `Unloading ${displayCount(displayName, numItems)}...`
  }
  return loadOrUnload === 'load'
    ? `Loading ${displayName}s...`
    : `Unloading ${displayName}s...`
}

const LoadUnloadItemsFooter = ({
  className,
  config,
  reloadOperationsOrError,
  loadOrUnload,
  onSubmit,
  executingTask,
  taskProgressMessage,
  selectedProcessItemType,
}: LoadUnloadItemsFooterProps) => {
  const message = getMessage(reloadOperationsOrError)
  const buttonMessage = getButtonMessage(
    reloadOperationsOrError,
    selectedProcessItemType,
    loadOrUnload,
    config.itemTypeDisplayAliases,
  )

  if (executingTask) {
    const defaultLoadMessage =
      loadOrUnload === 'load' ? 'Starting Load...' : 'Starting Unload...'

    const executingButtonMessage = getExecutingButtonMessage(
      reloadOperationsOrError,
      selectedProcessItemType,
      loadOrUnload,
      config.itemTypeDisplayAliases,
    )

    return (
      <div className={cx(className, cs.loadUnloadItemsFooter)}>
        <TinyNotification
          className={cs.caption}
          type='loading'
          message={taskProgressMessage || defaultLoadMessage}
        />
        <Button
          type='primary'
          className={cs.button}
          label={executingButtonMessage}
          disabled
        />
      </div>
    )
  }

  return (
    <div className={cx(className, cs.loadUnloadItemsFooter)}>
      <TinyNotification
        className={cs.caption}
        type={message.type}
        message={message.message}
      />
      <Button
        type='primary'
        className={cs.button}
        label={buttonMessage}
        disabled={!!reloadOperationsOrError.error}
        onClick={onSubmit}
      />
    </div>
  )
}

export default LoadUnloadItemsFooter
