import { DesktopAPI } from '~/api/core'

const desktopIntegrationsAPI = new DesktopAPI('/api/integrations')

export default {
  sendInfoTestSlackMessage: () =>
    desktopIntegrationsAPI.post('slack/send-info-test-message/'),
  sendCriticalTestSlackMessage: () =>
    desktopIntegrationsAPI.post('slack/send-critical-test-message/'),
}
