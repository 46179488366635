import cx from 'classnames'
import { useContext, useEffect, useState } from 'react'

import { get } from 'lodash/fp'
import commonDriverAPI from '~/api/desktop/commonDriver'
import Dialog from '~/components/Dialog'
import LoadingMessage from '~/components/LoadingMessage'
import Toaster from '~/components/Toaster'
import WorkcellStatusContext from '~/pages/Workcell/WorkcellStatusContext'
import {
  getInstrumentRealTimeState,
  getInstrumentStatus,
} from '~/pages/Workcell/utils/workcellStatus'
import { getInstrumentErrorMessage } from '~/utils/instrument'
import { CloseInhecoDrawerParams } from '../LoadUnloadItemsBody'
import cs from './inheco_scila_close_drawer_dialog.scss'

export interface InhecoScilaCloseDrawerDialogProps {
  className?: string
  isOpen: boolean
  onClose: () => void
  selectedDrawer: CloseInhecoDrawerParams | null
  instrumentName: string
}

const InhecoScilaCloseDrawerDialog = ({
  className,
  isOpen,
  onClose,
  selectedDrawer,
  instrumentName,
}: InhecoScilaCloseDrawerDialogProps) => {
  const workcellStatus = useContext(WorkcellStatusContext)
  const realTimeState = getInstrumentRealTimeState(workcellStatus, instrumentName)
  const [waitingForClose, setWaitingForClose] = useState(false)

  const isSelectedDrawerClosed = () => {
    if (selectedDrawer?.drawer) {
      return get(['door_state', selectedDrawer?.drawer - 1], realTimeState) === 'Closed'
    }
    return false
  }

  useEffect(() => {
    if (isSelectedDrawerClosed() && isOpen) {
      onClose()
    } else if (isOpen && waitingForClose) {
      const instrumentStatus = getInstrumentStatus(workcellStatus, instrumentName)
      const executionState = get('execution_state', instrumentStatus)

      if (executionState === 'FAULTED') {
        onClose()
        Toaster.show({
          message: `Could not close door. ${getInstrumentErrorMessage(
            instrumentStatus,
          )}`,
          intent: 'danger',
        })
      }
    }
  }, [realTimeState])

  useEffect(() => {
    if (isOpen) {
      setWaitingForClose(false)

      if (isSelectedDrawerClosed()) {
        onClose()
      }
    }
  }, [isOpen])

  const executeDrawerClose = async () => {
    if (!selectedDrawer) return
    setWaitingForClose(true)
    await commonDriverAPI.executeControlCommand(instrumentName, 'close_door', [
      selectedDrawer?.drawer,
    ])
  }

  return (
    <Dialog
      isOpen={isOpen}
      onClose={onClose}
      className={cx(cs.inhecoScilaOpenDrawerDialog, className)}
    >
      <div className={cs.text}>
        Drawer {selectedDrawer?.drawer} on {instrumentName} is currently open. Close
        drawer {selectedDrawer?.drawer}?
      </div>
      <Dialog.Footer>
        <Dialog.FooterButton label='Keep it Open' onClick={onClose} />
        {waitingForClose ? (
          <LoadingMessage
            className={cs.closeDrawerMessage}
            label={`Closing drawer ${selectedDrawer?.drawer}...`}
          />
        ) : (
          <Dialog.FooterButton
            label={`Close Drawer ${selectedDrawer?.drawer}`}
            onClick={executeDrawerClose}
            type='primary'
          />
        )}
      </Dialog.Footer>
    </Dialog>
  )
}

export default InhecoScilaCloseDrawerDialog
