import cx from 'classnames'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'

import instrumentDriverAPI, { InstrumentDriver } from '~/api/desktop/instrumentDrivers'
import CheckboxOption from '~/components/CheckboxOption'
import Dialog from '~/components/Dialog'
import Input from '~/components/Input'
import ReadOnlyField from '~/components/ReadOnlyField'
import Button from '~/components/buttons/Button'
import AddIcon from '~/components/icons/AddIcon'
import EditIcon from '~/components/icons/EditIcon'
import TinyNotification from '~/components/notifications/TinyNotification'

import InstrumentTypeSelect from './InstrumentTypeSelect'
import cs from './edit_instrument_driver_dialog.scss'

const EditInstrumentDriverDialog = ({
  className,
  isOpen,
  onClose,
  onEditSuccess,
  create,
  driver,
}: {
  className?: string
  isOpen: boolean
  onClose: () => void
  onEditSuccess: () => void
  create: boolean
  driver: InstrumentDriver | null
}) => {
  const [name, setName] = useState('')
  const [instrumentType, setInstrumentType] = useState<string | null>(null)
  const [shouldAutostart, setShouldAutostart] = useState(true)
  const [isSimulated, setIsSimulated] = useState(false)

  const submit = async () => {
    onClose()
    if (create) {
      await instrumentDriverAPI.createAndStart({
        instrumentName: name,
        instrumentType,
        shouldAutostart,
        isSimulated,
      })
    } else if (driver) {
      await instrumentDriverAPI.partialUpdate(driver.id, {
        instrumentName: name,
        instrumentType,
        shouldAutostart,
        isSimulated,
      })
    }

    onEditSuccess()
  }

  const isValid = () => {
    return name && instrumentType
  }

  const resetFields = () => {
    setName('')
    setInstrumentType(null)
    setShouldAutostart(true)
    setIsSimulated(false)
  }

  useEffect(() => {
    if (create || !driver) {
      resetFields()
    } else {
      setName(driver.instrumentName)
      setInstrumentType(driver.instrumentType)
      setIsSimulated(driver.isSimulated)
      setShouldAutostart(driver.shouldAutostart)
    }
  }, [isOpen, driver, create])

  return (
    <Dialog
      isOpen={isOpen}
      onClose={onClose}
      className={cx(className, cs.editInstrumentDriverDialog)}
    >
      <div className={cs.title}>
        {create ? 'Add Instrument Driver' : 'Edit Instrument Driver'}
      </div>
      <div className={cs.container}>
        <div className={cs.inputBlock}>
          {create ? (
            <div className={cs.instrumentName}>
              <Input
                label='Instrument Name'
                inputClassName={cs.input}
                className={cs.inputContainer}
                value={name}
                onChange={setName}
              />
              <TinyNotification
                type='info'
                message='Instrument name cannot be changed.'
                className={cs.notification}
              />
            </div>
          ) : (
            <ReadOnlyField
              className={cs.instrumentName}
              label='Instrument Name'
              value={name}
            />
          )}
          {create ? (
            <InstrumentTypeSelect
              label='Instrument Type'
              className={cs.inputContainer}
              triggerClassName={cs.trigger}
              popoverClassName={cs.popover}
              value={instrumentType || undefined}
              onInstrumentTypeSelect={setInstrumentType}
            />
          ) : (
            <ReadOnlyField label='Instrument Type' value={instrumentType} />
          )}

          <div className={cs.checkboxOptionsContainer}>
            <CheckboxOption
              className={cs.checkboxOption}
              label='Auto-start'
              checked={shouldAutostart}
              onClick={setShouldAutostart}
              allCaps
            />
            <CheckboxOption
              className={cs.checkboxOption}
              label='Simulate'
              checked={isSimulated}
              onClick={setIsSimulated}
              allCaps
            />
          </div>
        </div>
      </div>
      <div className={cs.footer}>
        <Button
          className={cs.button}
          label={create ? 'Create Driver' : 'Edit Driver'}
          IconComponent={create ? AddIcon : EditIcon}
          type='primary'
          disabled={!isValid()}
          onClick={submit}
        />
      </div>
    </Dialog>
  )
}

EditInstrumentDriverDialog.propTypes = {
  className: PropTypes.string,
  isOpen: PropTypes.bool,
  create: PropTypes.bool,
  onClose: PropTypes.func,
  onEditSuccess: PropTypes.func,
  driver: PropTypes.shape({
    instrumentName: PropTypes.string,
    instrumentType: PropTypes.string,
    isSimulated: PropTypes.bool,
    shouldAutostart: PropTypes.bool,
    id: PropTypes.number,
  }),
}

export default EditInstrumentDriverDialog
