import cx from 'classnames'

import { keyBy, range } from 'lodash/fp'
import { PlateFormat } from '~/api/desktop/processItemsV2'
import Pill from '~/components/Pill'
import TinyMicroplate, { supportedWellColor } from '~/components/TinyMicroplate'
import { convertWellCoordsToWellName } from '~/utils/microplate'
import cs from './bulk_load_plate_preview.scss'

export interface BulkLoadPreviewPlate {
  plateBarcode: string
  plateFormat: PlateFormat
  liveWells: string[]
  isNew: boolean
}

export interface BulkLoadPlatePreviewProps {
  className?: string
  plates: BulkLoadPreviewPlate[]
}

const BulkLoadPlatePreview = ({ className, plates }: BulkLoadPlatePreviewProps) => {
  const getHighlightFns = (plateBarcode: string) => {
    // Putting these functions in the closure to make rendering more efficient.
    // fn will be called many times.
    const plate = plates.find(plate => plate.plateBarcode === plateBarcode)
    const liveWellMap = keyBy(well => well, plate?.liveWells || [])

    return [
      {
        color: 'accent' as supportedWellColor,
        fn: (row: number, col: number): boolean => {
          if (!plate || !plate.liveWells) return false
          const wellName = convertWellCoordsToWellName(row, col)
          return !!liveWellMap[wellName]
        },
      },
    ]
  }
  return (
    <div className={cx(className, cs.bulkLoadPlatePreview)}>
      <div className={cs.plates}>
        {plates.map(({ plateBarcode, plateFormat, isNew }) => (
          <div className={cs.gridItem}>
            <TinyMicroplate
              plateFormat={plateFormat}
              size='medium'
              highlights={getHighlightFns(plateBarcode)}
            />
            <div className={cs.plateName}>{plateBarcode}</div>
            {isNew && <Pill className={cs.isNewPill} type='accent' small label='New' />}
          </div>
        ))}
        {/* Add empty grid items to fill out the last row */}
        {plates.length % 4 > 0 &&
          range(0, 4 - (plates.length % 4)).map(() => {
            return <div className={cs.gridItem} />
          })}
      </div>
    </div>
  )
}

export default BulkLoadPlatePreview
