import eventsAPI from '~/pages/slasDemo/api/events'
import { DemoEvent, DemoEventLogEntry } from './DemoEvent'

export interface EventState {
  eventLog: DemoEventLogEntry[]
  lastIndex: number
}

export const INITIAL_EVENT_STATE: EventState = { lastIndex: -1, eventLog: [] }

export function pollEvents(currentState: EventState): EventState {
  const newEvents = eventsAPI.getEventsSince(currentState.lastIndex + 1)
  const allEvents = [...currentState.eventLog, ...newEvents.events]
  return {
    eventLog: allEvents,
    lastIndex: allEvents.length ? allEvents[allEvents.length - 1].index : -1,
  }
}

export function publishEvents(
  currentState: EventState,
  newEvents: DemoEvent[],
): EventState {
  eventsAPI.publish(newEvents)
  return pollEvents(currentState)
}

export function clearEvents(): EventState {
  eventsAPI.clear()
  return INITIAL_EVENT_STATE
}

// Helpful for debugging projections - you can use this from the Chrome console to reset the data
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
window.__debug_clearEventsAndRefresh = () => {
  clearEvents()
  window.location.reload()
}
