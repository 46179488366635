import Dialog from '~/components/Dialog'

import workcellRecoveryAPI from '~/api/desktop/workcellRecovery'
import JsonEditDialogTab from '~/components/Dialog/JsonEditDialogTab'
import Tabs from '~/components/Tabs'
import cs from './workcell_raw_state_edit_dialog.scss'

interface WorkcellRawStateEditDialogProps {
  isOpen: boolean
  onClose(): void
}

const WorkcellRawStateEditDialog = ({
  isOpen,
  onClose,
}: WorkcellRawStateEditDialogProps) => {
  const renderRunnerConfigEditTab = () => {
    const api = {
      setJson: async newConfig => workcellRecoveryAPI.setRunnerConfig(newConfig),
      getJson: async () => workcellRecoveryAPI.getRunnerConfig(),
    }
    return <JsonEditDialogTab api={api} onClose={onClose} />
  }

  const renderScheduleStateEditTab = () => {
    const api = {
      setJson: async newConfig => workcellRecoveryAPI.setScheduleState(newConfig),
      getJson: async () => workcellRecoveryAPI.getScheduleState(),
    }
    return <JsonEditDialogTab api={api} onClose={onClose} />
  }

  const tabs = [
    {
      key: 'schedule_state',
      title: 'Schedule State',
      panel: renderScheduleStateEditTab(),
    },
    {
      key: 'runner_config',
      title: 'Runner Config',
      panel: renderRunnerConfigEditTab(),
    },
  ]

  return (
    <Dialog isOpen={isOpen} onClose={onClose} className={cs.workcellConfigDialog}>
      <Dialog.Title>Edit Raw Execution State</Dialog.Title>
      <Dialog.Info>
        This allows you to edit the raw execution JSON that is used to start the
        workcell services. Only do this if you know what you are doing.{' '}
        <b>Make sure the workcell is not running.</b> After editing the state,{' '}
        <b>please restart the app before starting the workcell</b>.
      </Dialog.Info>
      <Tabs
        tabs={tabs}
        initialTab='schedule_state'
        className={cs.tabs}
        panelClassName={cs.tabPanel}
      />
    </Dialog>
  )
}

export default WorkcellRawStateEditDialog
