import dayjs from 'dayjs'
import { useMemo } from 'react'

import { FragmentType, gql, unmaskFragment } from '~/__generated__'
import ConfluenceGraphSidebar, {
  ConfluenceDataset,
} from '~/components/ConfluenceGraphSidebar'

const fragment = gql(`
  fragment WellConfluenceGraphSidebarFragment on WellCultureGraphQL {
    id
    well
    observationHistory {
      timestamp
      confluence
    }
  }
`)

export default function WellConfluenceGraphSidebar(props: {
  culture: FragmentType<typeof fragment>
}) {
  const culture = unmaskFragment(fragment, props.culture)

  const dataset: ConfluenceDataset = useMemo(
    () => ({
      name: `Well ${culture.well}`,
      data: culture.observationHistory
        .filter(({ confluence }) => confluence != null)
        .map(({ timestamp, confluence }) => ({
          timestamp: dayjs(timestamp),
          confluence: confluence!,
        })),
    }),
    [culture.observationHistory],
  )

  if (culture.observationHistory.length === 0) {
    return null
  }

  return (
    <ConfluenceGraphSidebar datasets={{ [culture.id]: dataset }} lineColor='#14919b' />
  )
}
