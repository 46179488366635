import { get, some } from 'lodash/fp'
import { WorkcellStatus } from '~/api/desktop/workcell.interface'
import { displayCount } from '~/utils/string'

export const getBulkInstrumentStatus = (workcellStatus: WorkcellStatus) => {
  const instrumentStatus = get('instrument_status', workcellStatus)

  const isBusy = get('is_busy', workcellStatus)
  let text = ''
  let type = ''

  if (some(['driver_state', 'STOPPED'], instrumentStatus)) {
    text = 'STOPPED'
    type = 'error'
  } else if (some(['instrument_status', 'UNREACHABLE'], instrumentStatus)) {
    text = 'UNREACHABLE'
    type = 'error'
  } else if (some(['instrument_status', 'ERROR'], instrumentStatus)) {
    text = 'ERROR'
    type = 'error'
  } else if (some(['execution_state', 'FAULTED'], instrumentStatus)) {
    text = 'FAULTED'
    type = 'error'
  } else if (some(['execution_state', 'DISCONNECTED'], instrumentStatus)) {
    text = 'DISCONNECTED'
    type = 'error'
  } else if (some(['execution_state', 'BUSY'], instrumentStatus)) {
    text = 'BUSY'
    type = 'accent'
  } else if (isBusy) {
    const wait_after_previous_step_s = workcellStatus.wait_after_previous_step_s
    if (wait_after_previous_step_s && wait_after_previous_step_s > 0) {
      text = `WAITING FOR ${displayCount('SECOND', wait_after_previous_step_s)}`
      type = 'inactive'
    } else {
      text = 'WAITING'
      type = 'inactive'
    }
  } else {
    text = 'READY'
    type = 'success'
  }
  return {
    text,
    type,
  }
}
