import { uniq } from 'lodash'
import { sortCaseInsensitive } from '~/utils/array'
import { CultureViewModelMap } from '../../events/ViewModels/CultureViewModel'
import { useCommandCenterEventContext } from '../CommandCenterEventsContext'
import { SimpleSelector, SimpleSelectorOption } from '../SimpleSelector'
import {
  getCellLineDisplayLabel,
  getExperimentLabelDisplayLabel,
} from '../displayLabel'
import cs from './live_cultures_filters.scss'

export type SelectedLiveCulturesFilter =
  | { kind: 'ALL' }
  | { kind: 'SPECIFIC_VALUE'; value: string }

export const LiveCulturesFilters = ({
  cultures,
  selectedCellLine,
  selectedExperimentLabel,
  onSelectCellLine,
  onSelectExperimentLabel,
}: {
  cultures: CultureViewModelMap
  selectedCellLine: SelectedLiveCulturesFilter
  selectedExperimentLabel: SelectedLiveCulturesFilter
  onSelectCellLine: (value: SelectedLiveCulturesFilter) => void
  onSelectExperimentLabel: (value: SelectedLiveCulturesFilter) => void
}) => {
  const { config } = useCommandCenterEventContext()
  const culturesArray = Object.values(cultures)
  const cellLines = sortCaseInsensitive(
    uniq(culturesArray.map(culture => culture.cellLine)),
  )
  const experimentLabels = sortCaseInsensitive(
    uniq(culturesArray.map(culture => culture.experimentLabel)),
  )

  const cellLineOptions = [
    { id: 'ALL', value: 'All' },
    ...cellLines.map(cellLine => ({ id: makeID(cellLine), value: cellLine })),
  ]
  const experimentLabelOptions = [
    { id: 'ALL', value: 'All' },
    ...experimentLabels.map(label => ({ id: makeID(label), value: label })),
  ]

  return (
    <div className={cs.liveCulturesFilters}>
      <SimpleSelector
        className={cs.selector}
        label={getCellLineDisplayLabel(config)}
        options={cellLineOptions}
        selectedOption={makeOption(selectedCellLine)}
        onOptionSelected={option => {
          onSelectCellLine(getValue(option))
        }}
      />
      <SimpleSelector
        className={cs.selector}
        label={getExperimentLabelDisplayLabel(config)}
        options={experimentLabelOptions}
        selectedOption={makeOption(selectedExperimentLabel)}
        onOptionSelected={option => {
          onSelectExperimentLabel(getValue(option))
        }}
      />
    </div>
  )
}

function makeOption(selectedValue: SelectedLiveCulturesFilter): SimpleSelectorOption {
  return selectedValue.kind === 'ALL'
    ? { id: 'ALL', value: 'All' }
    : { id: makeID(selectedValue.value), value: selectedValue.value }
}

function makeID(value: string): string {
  // Just need something that won't conflict with "ALL"
  return `__${value}`
}

function getValue(option: SimpleSelectorOption): SelectedLiveCulturesFilter {
  return option.id === 'ALL'
    ? { kind: 'ALL' }
    : { kind: 'SPECIFIC_VALUE', value: option.value }
}
