import cx from 'classnames'
import { useState } from 'react'
import { FutureRoutine } from '~/api/desktop/future_routine_schemas.interface'
import ServiceConfigDialog from '~/components/Dialog/ServiceConfigDialog'
import PopupMenu from '~/components/PopupMenu'
import MinimalBoldButton from '~/components/buttons/MinimalBoldButton'
import MinimalButton from '~/components/buttons/MinimalButton'
import Notification from '~/components/notifications/Notification'
import { displayCount } from '~/utils/string'
import DateHeader from '../../History/DateHeader'
import RequiresUserAttentionDialog from './RequiresUserAttentionDialog'
import cs from './future_routines_header.scss'
import {
  FutureRoutineDisplayMode,
  getFilteredFutureRoutines,
  getFutureRoutinesRequiringUserAttention,
} from './getFilteredFutureRoutines'

const FutureRoutinesHeader = ({
  className,
  routines,
  displayMode,
  setDisplayMode,
  date,
  setDate,
  onUpdateSuccess,
}: {
  className?: string
  routines: FutureRoutine[]
  displayMode: FutureRoutineDisplayMode
  setDisplayMode: (mode: FutureRoutineDisplayMode) => void
  date: Date
  setDate: (date: Date) => void
  onUpdateSuccess: () => void
}) => {
  const [configDialogOpen, setConfigDialogOpen] = useState(false)
  const [requiresUserAttentionDialogOpen, setRequiresUserAttentionDialogOpen] =
    useState(false)
  const renderPopupMenu = () => {
    return (
      <PopupMenu
        options={[
          {
            label: 'Configure...',
            action: () => setConfigDialogOpen(true),
          },
        ]}
        className={cs.menu}
      />
    )
  }

  const renderMainHeader = () => {
    if (displayMode === 'by_day') {
      return (
        <div className={cs.mainHeader}>
          <div className={cs.title}>
            <DateHeader value={date} onChange={setDate} className={cs.dateHeader} />
            {renderPopupMenu()}
          </div>
          <MinimalButton
            className={cs.toggleDisplayMode}
            type='primary'
            onClick={() => setDisplayMode('all_routines')}
            label='View All Routines'
          />
        </div>
      )
    }
    const filteredRoutines = getFilteredFutureRoutines(routines, displayMode, date)
    return (
      <div className={cs.mainHeader}>
        <div className={cs.title}>
          <div className={cs.futureRoutineCount}>
            {displayCount('Future Routine', filteredRoutines.length)}
          </div>
          {renderPopupMenu()}
        </div>
        <MinimalButton
          className={cs.toggleDisplayMode}
          type='primary'
          onClick={() => setDisplayMode('by_day')}
          label='View By Day'
        />
      </div>
    )
  }

  const renderRequiresUserAttentionNotification = () => {
    const flaggedRoutines = getFutureRoutinesRequiringUserAttention(routines)
    if (flaggedRoutines.length === 0) return null
    return (
      <Notification
        variant='mini'
        label={
          <div className={cs.notificationLabel}>
            <div>
              {displayCount('future routine', flaggedRoutines.length)} require
              {flaggedRoutines.length === 1 ? 's' : ''} your attention
            </div>
            <MinimalBoldButton
              className={cs.reviewButton}
              label='Review Routines'
              onClick={() => setRequiresUserAttentionDialogOpen(true)}
              type='danger'
            />
          </div>
        }
        type='error'
        className={cs.requiresUserAttentionNotification}
      />
    )
  }

  return (
    <div className={cx(cs.futureRoutinesHeader, className)}>
      {renderMainHeader()}
      <div className={cs.fill} />
      {renderRequiresUserAttentionNotification()}
      <ServiceConfigDialog
        isOpen={configDialogOpen}
        onClose={() => setConfigDialogOpen(false)}
        serviceName='future_routine_service'
      />
      <RequiresUserAttentionDialog
        isOpen={requiresUserAttentionDialogOpen}
        onClose={() => setRequiresUserAttentionDialogOpen(false)}
        onUpdateSuccess={onUpdateSuccess}
      />
    </div>
  )
}

export default FutureRoutinesHeader
