import cx from 'classnames'

import { compact } from 'lodash/fp'
import PopupMenu from '~/components/PopupMenu'
import { useFeatureFlag } from '~/core/featureFlags'
import cs from './live_cultures_header.scss'

export default function LiveCulturesHeader() {
  const limsCultureData2024 = useFeatureFlag('limsCultureData2024')
  const options = compact([
    limsCultureData2024 && {
      label: 'Bulk Export Culture Metadata...',
      to: '/monitor/bulk-export-culture-metadata',
    },
  ])
  return (
    <div className={cx(cs.header)}>
      <div className={cs.titleContainer}>
        <span className={cs.title}>Live Cultures</span>
        {options.length > 0 && <PopupMenu className={cs.menu} options={options} />}
      </div>
      <div className={cs.fill} />
    </div>
  )
}
