import PropTypes from 'prop-types'
import { IconProps } from './types/IconProps'

const AddIcon = ({ className, onClick }: IconProps) => (
  <svg
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    onClick={onClick}
    className={className}
  >
    <path d='M19 13H13V19H11V13H5V11H11V5H13V11H19V13Z' />
  </svg>
)

AddIcon.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
}

export default AddIcon
