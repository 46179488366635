import {
  CultureStatus,
  CultureViewModel,
  getCultureArchivedAt,
} from '../../events/ViewModels/CultureViewModel'
import { ViewModels } from '../../events/ViewModels/ViewModels'
import cs from './past_cultures.scss'

import { useState } from 'react'
import Table from '~/components/Table'
import { getRelativeTime } from '~/pages/slasDemo/events/data/eventTimes'
import { renderStatusPill } from '~/utils/statusPill'
import { displayCount } from '~/utils/string'
import {
  EVENT_TYPE_TO_USER_NAME,
  ProcessLogEventType,
} from '../../events/ViewModels/CultureProcessLogViewModel'
import { CultureDatasetImagesDialog } from '../CultureDatasetImagesDialog/CultureDatasetImagesDialog'
import { CultureGrowthChart } from '../CultureGrowthChart/CultureGrowthChart'
import { CultureLink } from '../CultureLink/CultureLink'
import { SimpleSelector } from '../SimpleSelector'

// TODO: Filter for reason
// TODO: Archive reason and terminated text/styling

type ArchiveReasonFilter = 'ALL' | 'TERMINATED' | 'CHECKED_OUT' | 'CONSUMED'

const ORDERED_FILTERS: ArchiveReasonFilter[] = [
  'ALL',
  'TERMINATED',
  'CHECKED_OUT',
  'CONSUMED',
]

const FILTER_TO_LABEL: { [filter in ArchiveReasonFilter]: string } = {
  ALL: 'All',
  CHECKED_OUT: 'Checked Out',
  CONSUMED: 'Consumed',
  TERMINATED: 'Terminated',
}

const STATUS_PILL_CONFIG = {
  'Checked Out': { type: 'default' },
  Consumed: { type: 'default' },
  Terminated: { type: 'error' },
}

const culturedArchivedAt = (today: number, culture: CultureViewModel) =>
  getRelativeTime(getCultureArchivedAt(today, culture))

interface ArchiveReason {
  filter: ArchiveReasonFilter
  // This is constructed somewhat indirectly - ideally the archive event would be
  // attached to the culture directly - but it works for now
  eventType: ProcessLogEventType
}

const STATUS_TO_ARCHIVE_REASON: {
  [status in Exclude<CultureStatus, 'ACTIVE'>]: ArchiveReason
} = {
  CONSUMED: { filter: 'CONSUMED', eventType: 'Workcell Routine' },
  TERMINATED: {
    filter: 'TERMINATED',
    eventType: 'Operator Action',
  },
}

export function PastCultures({ viewModels }: { viewModels: ViewModels }) {
  const [filter, setFilter] = useState<ArchiveReasonFilter>('ALL')
  const pastCultures = Object.values(viewModels.cultures).filter(
    culture =>
      culture.isArchived &&
      // TODO: Accessing STATUS_TO_ARCHIVE_REASON here and below is an implicit typecast
      (filter === 'ALL' || STATUS_TO_ARCHIVE_REASON[culture.status].filter === filter),
  )
  return (
    <div className={cs.pastCultures}>
      <div className={cs.title}>Past Cultures</div>
      <ArchiveReasonFilterSelector
        filter={filter}
        onFilterSelected={(filter: ArchiveReasonFilter) => {
          setFilter(filter)
        }}
      />
      <div className={cs.tableTitle}>
        {displayCount('Culture', pastCultures.length)}
      </div>
      <PastCulturesTable
        today={viewModels.today}
        filter={filter}
        pastCultures={pastCultures}
      />
    </div>
  )
}

// TODO: Refactor this with IssueTypeFilter
function ArchiveReasonFilterSelector({
  filter,
  onFilterSelected: onSelectedTypeChanged,
}: {
  filter: ArchiveReasonFilter
  onFilterSelected: (filter: ArchiveReasonFilter) => void
}) {
  const options = ORDERED_FILTERS.map(filter => ({
    id: filter,
    value: FILTER_TO_LABEL[filter],
  }))

  // TODO: This non-null assertion is unsafe
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const selectedOption = options.find(option => option.id === filter)!

  return (
    <div>
      <SimpleSelector
        label={'Archive Reason'}
        options={options}
        selectedOption={selectedOption}
        onOptionSelected={option => {
          // TODO: This type cast is somewhat unsafe but fine for now
          // Ideally we'd use generics
          onSelectedTypeChanged(option.id as ArchiveReasonFilter)
        }}
      />
    </div>
  )
}

function PastCulturesTable({
  today,
  filter,
  pastCultures,
}: {
  today: number
  filter: ArchiveReasonFilter
  pastCultures: CultureViewModel[]
}) {
  const [dialogCulture, setDialogCulture] = useState<CultureViewModel | null>(null)

  return (
    <>
      <CultureDatasetImagesDialog
        today={today}
        culture={dialogCulture}
        dataset={
          dialogCulture
            ? dialogCulture.datasets[dialogCulture.datasets.length - 1]
            : null
        }
        isOpen={dialogCulture != null}
        onClose={() => {
          setDialogCulture(null)
        }}
      />
      <div className={cs.outerTableContainer}>
        <div className={cs.innerTableContainer}>
          <Table
            columns={TABLE_COLUMNS(today)}
            data={pastCultures}
            rowPaddingVariant='rowPaddingLow'
            rowKey='id'
            hideRowBorders
            onRowClick={(culture: CultureViewModel) => {
              setDialogCulture(culture)
            }}
            noDataMessage={
              filter === 'ALL'
                ? 'No past cultures found'
                : `No cultures have been ${FILTER_TO_LABEL[filter].toLowerCase()}`
            }
            flushLeft
          />
        </div>
      </div>
    </>
  )
}

const TABLE_COLUMNS = (today: number) => [
  {
    name: 'Cell Culture',
    width: 175,
    render: (culture: CultureViewModel) => (
      <CultureLink
        id={culture.id}
        name={culture.name}
        cellLine={culture.cellLine}
        className={cs.cultureLink}
      />
    ),
  },
  {
    name: 'Archive Reason',
    width: 175,
    render: (culture: CultureViewModel) =>
      renderStatusPill(
        FILTER_TO_LABEL[STATUS_TO_ARCHIVE_REASON[culture.status].filter],
        STATUS_PILL_CONFIG,
        {
          small: true,
        },
      ),
    verticalCenter: true,
  },
  {
    name: 'Archived Date',
    width: 150,
    render: (culture: CultureViewModel) => (
      <div>{culturedArchivedAt(today, culture)}</div>
    ),
    smallText: true,
  },
  {
    name: 'Archived By',
    width: 175,
    render: (culture: CultureViewModel) => (
      <div>
        {EVENT_TYPE_TO_USER_NAME[STATUS_TO_ARCHIVE_REASON[culture.status].eventType]}
      </div>
    ),
    smallText: true,
  },
  {
    name: 'Growth',
    width: 120,
    render: (culture: CultureViewModel) => (
      <CultureGrowthChart culture={culture} className={cs.lineGraph} />
    ),
  },
  {
    name: 'Last Image',
    width: 150,
    render: (culture: CultureViewModel) => (
      <img
        className={cs.thumbnail}
        src={culture.datasets[culture.datasets.length - 1].thumbnails[0][0]}
      />
    ),
  },
]
