import { LoadParamsRequestOrError } from './LoadParamsRequestOrError.interface'
import {
  PROCESS_ITEM_TYPE_TO_PARAMS_TYPE,
  ProcessItemConfigType,
  ProcessItemParamsType,
  getProcessItemTypeConfig,
} from './processItemTypeConfig/getProcessItemTypeConfig'

export const getLoadParamsRequestOrError = <
  TType extends keyof PROCESS_ITEM_TYPE_TO_PARAMS_TYPE,
>(
  processItemParams: Partial<ProcessItemParamsType<TType>>,
  processItemType: TType,
): LoadParamsRequestOrError => {
  const processItemTypeConfig: ProcessItemConfigType<TType> =
    getProcessItemTypeConfig(processItemType)

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return processItemTypeConfig.getLoadParamsRequestOrError(processItemParams)
}
