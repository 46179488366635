/*
  Links can be used instead of MinimalButtons for links within blocks of text.

  By default, links do not change the color of enclosed elements (e.g. text),
  unless you specify a variant.
*/

import cx from 'classnames'
import { ReactNode } from 'react'
import { Link } from 'react-router-dom'

import cs from './link.scss'

export interface LinkProps {
  className?: string
  children?: ReactNode
  to: string
  external?: boolean
  variant?: 'error' | 'primary'
}

const MyLink = ({ className, children, to, external, variant }: LinkProps) => {
  if (external) {
    return (
      <a
        href={to}
        className={cx(className, cs.link, variant && cs[variant])}
        target='blank'
        rel='noopener noreferrer'
      >
        {children}
      </a>
    )
  }
  return (
    <Link className={cx(className, cs.link, variant && cs[variant])} to={to}>
      {children}
    </Link>
  )
}

export default MyLink
