import { Overlay } from '@blueprintjs/core'

import cs from './chart_tooltip.scss'

export interface TooltipParams {
  top: number
  left: number
  height: number
  width: number
}

// tooltipParams top, height, left, width refers to the TRIGGER element, not the tooltip itself.
const ChartTooltip = ({
  renderTooltipContents,
  tooltipParams,
  onTooltipClose,
  position: _position,
}: {
  renderTooltipContents: (tooltipParams: TooltipParams) => void
  tooltipParams: TooltipParams
  onTooltipClose: () => void
  position: 'right' | 'center'
}) => {
  const position = _position ?? 'right'

  // TODO(mark): Use something like popper to calculate tooltip positioning instead.
  // The below code is still not resilient and will sometimes cause the tooltip to be positioned
  // off-screen.
  const getTooltipStyle = () => {
    if (position === 'right') {
      const top = tooltipParams.top + tooltipParams.height - 10
      const left = tooltipParams.left + tooltipParams.width + 10
      let transform: string | undefined = 'translate(0, -100%)'

      if (tooltipParams.top < 250) {
        transform = undefined
      }

      return {
        top,
        left,
        transform,
      }
    }
    if (position === 'center') {
      const top = tooltipParams.top - 10
      const left = tooltipParams.left + tooltipParams.width / 2
      let translateX = '-50%'
      let translateY = '-100%'

      if (tooltipParams.top < 250) {
        translateY = '0%'
      }

      if (tooltipParams.left + tooltipParams.width / 2 > window.innerWidth - 200) {
        translateX = '-100%'
      }

      return {
        top,
        left,
        transform: `translate(${translateX}, ${translateY})`,
      }
    }
    return null
  }

  return (
    <Overlay isOpen hasBackdrop={false} onClose={onTooltipClose}>
      <div className={cs.tooltip} style={getTooltipStyle() ?? undefined}>
        {renderTooltipContents(tooltipParams)}
      </div>
    </Overlay>
  )
}

export default ChartTooltip
