import cx from 'classnames'
import { useState } from 'react'
import ServiceConfigDialog from '~/components/Dialog/ServiceConfigDialog'
import PopupMenu from '~/components/PopupMenu'
import WorkflowsIcon from '~/components/icons/WorkflowsIcon'
import cs from './workflows_instance_header.scss'
const WorkflowsInstanceHeader = ({ className }: { className?: string }) => {
  const [configDialogOpen, setConfigDialogOpen] = useState(false)

  const renderPopupMenu = () => {
    return (
      <PopupMenu
        options={[
          {
            label: 'Configure...',
            action: () => setConfigDialogOpen(true),
          },
        ]}
        className={cs.menu}
      />
    )
  }
  const renderHeader = () => {
    return (
      <div className={cs.header}>
        <div className={cs.title}>
          <WorkflowsIcon className={cs.icon} />
          Workflows
          {renderPopupMenu()}
        </div>
      </div>
    )
  }

  return (
    <div className={cx(cs.workflowsInstanceHeader, className)}>
      {renderHeader()}
      <ServiceConfigDialog
        isOpen={configDialogOpen}
        onClose={() => setConfigDialogOpen(false)}
        serviceName='workflow_service'
      />
    </div>
  )
}

export default WorkflowsInstanceHeader
