import dayjs from 'dayjs'
import { useEffect, useState } from 'react'
import { WorkflowInstanceWithCulturePlate } from '~/api/desktop/culturePlateWorkflow'
import workflowInstancesAPI, { WorkflowRoutine } from '~/api/desktop/workflowInstances'
import Dialog from '~/components/Dialog'
import HelpPopover from '~/components/HelpPopover'
import LoadingMessage from '~/components/LoadingMessage'
import Table from '~/components/Table'
import TinyNotification from '~/components/notifications/TinyNotification'
import { renderStatusPill } from '~/utils/statusPill'
import cs from './workflow_instance_viz_dialog.scss'

interface WorkflowInstanceVizDialogProps {
  isOpen: boolean
  onClose: () => void
  selectedWorkflowInstanceWithCulturePlate?: WorkflowInstanceWithCulturePlate
}

const WorkflowInstanceVizDialog = ({
  selectedWorkflowInstanceWithCulturePlate,
  isOpen,
  onClose,
}: WorkflowInstanceVizDialogProps) => {
  const [workflowRoutines, setWorkflowRoutines] = useState<
    WorkflowRoutine[] | undefined
  >(undefined)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState('')

  const TIME_FORMAT = 'YYYY/MM/DD hh:mma'

  const STATUS_PILL_CONFIG = {
    completed: { type: 'success' },
    canceled: { type: 'default' },
    failed: { type: 'error' },
    in_progress: { type: 'info' },
    skipped: { type: 'warning' },
    initialized: { type: 'default' },
  }

  const renderStatus = status =>
    renderStatusPill(status, STATUS_PILL_CONFIG, {
      className: cs.status,
    })

  useEffect(() => {
    setWorkflowRoutines(undefined)
    setError('')
    if (!selectedWorkflowInstanceWithCulturePlate) return
    setLoading(true)
    workflowInstancesAPI
      .bulkRetrieveWorkflowRoutinesByWorkflowInstanceUuid(
        selectedWorkflowInstanceWithCulturePlate.workflow_instance.uuid,
      )
      .then(response => {
        setWorkflowRoutines(response)
      })
      .catch(e => {
        setError('Could not load workflow routines for instance')
        console.error(e)
      })
    setLoading(false)
  }, [selectedWorkflowInstanceWithCulturePlate])

  const renderFormattedTime = (time: string | undefined) => {
    return time ? <div>{dayjs(time).format(TIME_FORMAT)}</div> : <div>-</div>
  }

  const nodeKeyFromUUID = (routineUUID: string) => {
    if (selectedWorkflowInstanceWithCulturePlate)
      return routineUUID.replace(
        `${selectedWorkflowInstanceWithCulturePlate.workflow_instance.uuid}__`,
        '',
      )
    else return routineUUID
  }

  const tableColumns = [
    {
      name: 'Routine',
      width: 'flex',
      render: (workflowRoutine: WorkflowRoutine) => (
        <div>
          <div className={cs.routineName}>{nodeKeyFromUUID(workflowRoutine.uuid)}</div>
          <HelpPopover
            className={cs.routineInfo}
            text={workflowRoutine.routine_name}
            popoverStyle={'minimal'}
            helpContent={JSON.stringify(workflowRoutine.routine_parameters, null, 2)}
            interactionKind={'hover'}
            placement='top'
            elementType='div'
          />
        </div>
      ),
    },
    {
      name: 'Status',
      width: 140,
      render: (workflowRoutine: WorkflowRoutine) =>
        renderStatus(workflowRoutine.status),
    },
    {
      name: 'Start At',
      width: 150,
      render: (workflowRoutine: WorkflowRoutine) =>
        workflowRoutine.adjusted_execution_time ? (
          <HelpPopover
            className={cs.adjustedExecution}
            text={renderFormattedTime(workflowRoutine.adjusted_execution_time)}
            popoverStyle={'minimal'}
            helpContent={
              'Adjusted from: ' +
              dayjs(workflowRoutine.desired_execution_time || undefined).format(
                TIME_FORMAT,
              )
            }
            interactionKind={'hover'}
            placement='top'
            elementType='div'
          />
        ) : (
          renderFormattedTime(workflowRoutine.desired_execution_time || undefined)
        ),
    },
    {
      name: 'Started At',
      width: 150,
      render: (workflowRoutine: WorkflowRoutine) =>
        renderFormattedTime(workflowRoutine.started_execution_at || undefined),
    },
    {
      name: 'Completed At',
      width: 150,
      render: (workflowRoutine: WorkflowRoutine) =>
        renderFormattedTime(workflowRoutine.completed_execution_at || undefined),
    },
  ]
  const renderContainer = () => {
    if (loading) {
      return (
        <div className={cs.container}>
          <LoadingMessage
            label='Loading Workflow Routines for Instance...'
            className={cs.loadingMessageBox}
          />
        </div>
      )
    }
    if (error) {
      return (
        <div className={cs.container}>
          <TinyNotification type='bareError' message={error} />
        </div>
      )
    }
    if (workflowRoutines) {
      return (
        <Table
          className={cs.table}
          columns={tableColumns}
          data={workflowRoutines}
          rowPaddingVariant={'rowPaddingLow'}
          heightSizing={'flexAuto'}
          rowKey={'uuid'}
        />
      )
    }
  }

  return (
    <Dialog isOpen={isOpen} onClose={onClose} className={cs.workflowInstanceVizDialog}>
      <div className={cs.title}>
        {
          selectedWorkflowInstanceWithCulturePlate?.workflow_instance
            .workflow_definition_id
        }
      </div>
      {renderContainer()}
    </Dialog>
  )
}

export default WorkflowInstanceVizDialog
