import { compact } from 'lodash/fp'
import { useEffect, useState } from 'react'
import { FutureRoutine } from '~/api/desktop/future_routine_schemas.interface'
import workflowInstancesAPI, { WorkflowInstance } from '~/api/desktop/workflowInstances'
import Table, { TableColumn } from '~/components/Table'
import StatusMessage from '~/components/messages/StatusMessage'
import { renderParameters } from '~/pages/Workcell/History/utils/routines'
import { getComparatorString } from '~/utils/array'
import WorkflowInstancePopover from './WorkflowInstancePopover'
import {
  renderFutureRoutinesProcessItem,
  renderFutureRoutinesScheduledFor,
  renderRequiresUserAttentionErrorMessage,
} from './futureRoutinePropRenderers'
import cs from './future_routine_table.scss'

interface FutureRoutineRequiresAttentionTableProps {
  loading?: boolean
  className?: string
  routines: FutureRoutine[]
  onRoutineCancel?(...args: unknown[]): unknown
  selectable?: boolean
  onRoutineUuidsSelected?: (rowsId: { [key: number]: boolean }) => void
  selectedRoutineUuids?: { [key: number]: boolean }
  disabled?: boolean
}

const FutureRoutineRequiresAttentionTable = ({
  className,
  routines,
  loading,
  selectable,
  onRoutineUuidsSelected: onRoutineUuidsSelected,
  selectedRoutineUuids: selectedRoutineUuids,
  disabled,
}: FutureRoutineRequiresAttentionTableProps) => {
  const [workflowRoutineUuidToWorkflowInstance, setWorkflowUuidToWorkflowInstance] =
    useState<{ [key: string]: WorkflowInstance | undefined } | null>(null)
  const tableColumns: TableColumn<FutureRoutine>[] = [
    {
      name: 'Routine Name',
      width: 200,
      render: row => (
        <div className={cs.routineName}>
          {row.parentWorkflowRoutineId &&
            workflowRoutineUuidToWorkflowInstance &&
            workflowRoutineUuidToWorkflowInstance[row.parentWorkflowRoutineId] && (
              <WorkflowInstancePopover
                className={cs.workflowIcon}
                workflowInstance={
                  workflowRoutineUuidToWorkflowInstance[row.parentWorkflowRoutineId]
                }
              />
            )}
          {row.name}
        </div>
      ),
    },
    {
      name: 'Process Item',
      width: 150,
      render: renderFutureRoutinesProcessItem,
    },
    {
      name: 'Scheduled For',
      width: 150,
      render: (row: FutureRoutine) => renderFutureRoutinesScheduledFor(row, true),
      smallText: true,
    },
    {
      name: 'Parameters',
      width: 150,
      render: (row: FutureRoutine) => renderParameters(row, true),
      smallText: true,
      oneLine: true,
    },
    {
      name: 'Review Reason',
      width: 'flex',
      render: (row: FutureRoutine) => renderRequiresUserAttentionErrorMessage(row),
    },
  ]

  const fetchWorkflowInstances = async (routines: FutureRoutine[]) => {
    const parentWorkflowRoutineIds = compact(
      routines.map(routine => routine.parentWorkflowRoutineId),
    )
    const response = await workflowInstancesAPI.bulkRetrieveByWorkflowRoutineUuids(
      parentWorkflowRoutineIds,
    )
    setWorkflowUuidToWorkflowInstance(
      response.workflow_routine_uuid_to_workflow_instance,
    )
  }

  useEffect(() => {
    if (routines) {
      fetchWorkflowInstances(routines)
    }
  }, [getComparatorString(routines.map(routine => routine.uuid))])
  if (routines === null) {
    return null
  }

  if (loading) {
    return <StatusMessage className={className} message='Loading routines' />
  }

  return (
    <Table
      columns={tableColumns}
      data={routines}
      rowKey='uuid'
      className={className}
      rowPaddingVariant='rowPaddingLow'
      heightSizing='flexAuto'
      noDataMessage='No routines to review'
      selectableProps={
        selectable && onRoutineUuidsSelected && selectedRoutineUuids
          ? {
              onRowsSelected: onRoutineUuidsSelected,
              selectedRowIds: selectedRoutineUuids,
            }
          : undefined
      }
      disabled={disabled}
    />
  )
}

export default FutureRoutineRequiresAttentionTable
