import { EventState } from '../EventState'

// TODO: It's not immediately obvious whether this is faster than the "startAt"
// approach used in other projections. It probably doesn't matter either way.
// We don't expect to generate a massive list of events that doesn't include any
// DayFastForwarded events.
export function projectCurrentDay(eventState: EventState): number {
  for (let i = eventState.eventLog.length - 1; i >= 0; i--) {
    const event = eventState.eventLog[i].data
    if (event.kind === 'DayFastForwarded') {
      return event.newDay
    }
  }
  return 0
}
