/*
  A tiny, grey notification, to be displayed underneath inputs in forms.
*/

import cx from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'

import BareCheckmarkIcon from '~/components/icons/BareCheckmarkIcon'
import BareWarningIcon from '~/components/icons/BareWarningIcon'
import CheckmarkIcon from '~/components/icons/CheckmarkIcon'
import WarningIcon from '~/components/icons/WarningIcon'

import LoadingIcon from '../icons/LoadingIcon'
import cs from './tiny_notification.scss'

interface TinyNotificationProps {
  className?: string
  message?: React.ReactNode
  type:
    | 'info'
    | 'warning'
    | 'success'
    | 'error'
    | 'bareInfo'
    | 'bareWarning'
    | 'bareSuccess'
    | 'bareError'
    | 'loading'
}

const TinyNotification = ({ className, message, type }: TinyNotificationProps) => {
  const renderIcon = () => {
    switch (type) {
      case 'info':
        return <WarningIcon className={cs.icon} />
      case 'warning':
        return <WarningIcon className={cs.icon} />
      case 'error':
        return <WarningIcon className={cs.icon} />
      case 'success':
        return <CheckmarkIcon className={cs.icon} />
      case 'bareInfo':
        return <BareWarningIcon className={cx(cs.icon, cs.bareInfo)} />
      case 'bareWarning':
        return <BareWarningIcon className={cx(cs.icon, cs.bareWarning)} />
      case 'bareSuccess':
        return <BareCheckmarkIcon className={cx(cs.icon, cs.bareCheckmark)} />
      case 'bareError':
        return <BareWarningIcon className={cx(cs.icon, cs.bareWarning)} />
      case 'loading':
        return <LoadingIcon className={cx(cs.icon, cs.bareInfo)} />
      default:
        return null
    }
  }
  return (
    <div className={cx(className, cs.tinyNotification, cs[type])}>
      {renderIcon()}
      <span className={cs.message}>{message}</span>
    </div>
  )
}

TinyNotification.propTypes = {
  className: PropTypes.string,
  message: PropTypes.node,
  type: PropTypes.oneOf([
    'info',
    'warning',
    'success',
    'error',
    'bareInfo',
    'bareWarning',
    'bareSuccess',
    'bareError',
  ]),
}

TinyNotification.defaultProps = {
  type: 'success',
}

export default TinyNotification
