import { createContext, useContext } from 'react'
import { DemoEvent } from '../events/DemoEvent'
import { EventState } from '../events/EventState'
import { DemoConfig } from './SlasDemoConfigDialog/DemoConfig'

export interface CommandCenterEventContextType {
  config: DemoConfig
  eventState: EventState
  publishEvents: (newEvents: DemoEvent[]) => void
  resetEventsTo: (newEvents: DemoEvent[]) => void
  onConfigUpdate: (newConfig: DemoConfig) => void
}

export const CommandCenterEventContext = createContext<
  CommandCenterEventContextType | undefined
>(undefined)

export const useCommandCenterEventContext = () => {
  const eventContext = useContext(CommandCenterEventContext)
  if (!eventContext) {
    throw new Error('No CommandCenterEventContext.Provider found')
  }
  return eventContext
}
