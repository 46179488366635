import cx from 'classnames'
import { uniqBy } from 'lodash/fp'
import memoizeOne from 'memoize-one'
import {
  LinkLotsLookupDataResponseCellLineLots,
  LinkLotsLookupDataResponseCellLines,
  LinkLotsParseDataResponseCellLineLots,
  LinkLotsParseDataResponseCellLines,
} from '~/api/operatorActions/linkCellLineLotsToCultures'
import Table from '~/components/Table'
import TextWithOverflow from '~/components/TextWithOverflow'
import { getOrderedCellLineValues } from './LinkLotsPlatePreview'
import cs from './link_lots_data_table.scss'
import {} from './lookupDataResponseExtractors'
import { getLinkLotsPreviewPlates } from './parseDataResponseExtractors'

export interface ConfirmUpdatesStepDataTableProps {
  className?: string
  lookupDataResponse:
    | LinkLotsLookupDataResponseCellLineLots
    | LinkLotsLookupDataResponseCellLines
  parseDataResponse:
    | LinkLotsParseDataResponseCellLineLots
    | LinkLotsParseDataResponseCellLines
}

interface ConfirmUpdatesLookedUpCellLineLot {
  cell_line_lot: string
  cell_line: string
  passage_number: number | null
  color_index: number
}

interface ConfirmUpdatesLookedUpCellLine {
  cell_line: string
  passage_number: number | null
  color_index: number
}

const _getOrderedCellLineValues = memoizeOne(
  (
    parseDataResponse:
      | LinkLotsParseDataResponseCellLineLots
      | LinkLotsParseDataResponseCellLines,
    uploadType: 'cell_lines' | 'cell_line_lots',
  ) => {
    const previewPlates = getLinkLotsPreviewPlates(parseDataResponse)
    return getOrderedCellLineValues(previewPlates, uploadType)
  },
)

const LinkedValue = ({ value, colorIndex }: { value: string; colorIndex: number }) => {
  return (
    <div className={cs.linkedValue}>
      <div className={cx(cs.legendBox, cs[`categorical_${colorIndex}`])} />
      <TextWithOverflow className={cs.valueText} text={value} popoverContent={value} />
    </div>
  )
}

const CELL_LINE_LOT_TABLE_COLUMNS = [
  {
    name: 'Cell Line Lot',
    width: 235,
    render: (row: ConfirmUpdatesLookedUpCellLineLot) => (
      <LinkedValue value={row.cell_line_lot} colorIndex={row.color_index} />
    ),
  },
  {
    name: 'Cell Line',
    width: 215,
    oneLine: true,
    render: (row: ConfirmUpdatesLookedUpCellLineLot) => row.cell_line,
  },
  {
    name: 'Passage #',
    width: 80,
    render: (row: ConfirmUpdatesLookedUpCellLineLot) => row.passage_number,
    rightAlign: true,
  },
]
const CELL_LINE_TABLE_COLUMNS = [
  {
    name: 'Cell Line',
    width: 'flex',
    oneLine: true,
    render: (row: ConfirmUpdatesLookedUpCellLine) => (
      <LinkedValue value={row.cell_line} colorIndex={row.color_index} />
    ),
  },
  {
    name: 'Passage #',
    width: 80,
    render: (row: ConfirmUpdatesLookedUpCellLine) => row.passage_number,
  },
]

const LinkLotsDataTable = ({
  className,
  lookupDataResponse,
  parseDataResponse,
}: ConfirmUpdatesStepDataTableProps) => {
  const renderTable = () => {
    const orderedCellLineValues = _getOrderedCellLineValues(
      parseDataResponse,
      lookupDataResponse.type,
    )
    if (lookupDataResponse.type === 'cell_line_lots') {
      const data = uniqBy('cell_line_lot', lookupDataResponse.data).map(row => ({
        cell_line_lot: row.cell_line_lot,
        cell_line: row.cell_line,
        passage_number: row.passage_number,
        color_index: orderedCellLineValues.indexOf(row.cell_line_lot) % 4,
      }))
      return (
        <Table
          columns={CELL_LINE_LOT_TABLE_COLUMNS}
          data={data}
          className={cx(cs.processStepTable)}
          rowKey='id'
          rowPaddingVariant='rowPaddingLow'
          heightSizing='flexAuto'
        />
      )
    }
    const data = uniqBy('cell_line', lookupDataResponse.data).map(row => ({
      cell_line: row.cell_line,
      passage_number: row.passage_number,
      color_index: orderedCellLineValues.indexOf(row.cell_line) % 4,
    }))
    return (
      <Table
        columns={CELL_LINE_TABLE_COLUMNS}
        data={data}
        className={cx(cs.processStepTable)}
        rowKey='id'
        rowPaddingVariant='rowPaddingLow'
        heightSizing='flexAuto'
      />
    )
  }

  return <div className={cx(className, cs.linkLotsDataTable)}>{renderTable()}</div>
}

export default LinkLotsDataTable
