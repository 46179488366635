import cx from 'classnames'
import { get, size } from 'lodash/fp'
import PropTypes from 'prop-types'
import { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

import workcellAPI, { GetLiveStepsResponse } from '~/api/desktop/workcell'
import operatorActionAPI, { EnabledOperatorConfigs } from '~/api/operatorActions/shared'
import WorkcellStatusContext from '~/pages/Workcell/WorkcellStatusContext'
import InstrumentTile from '~/pages/Workcell/components/instrument/InstrumentTile'
import {
  getInstrumentStatus,
  getNumDefaultQueuedRoutines,
  getNumSupportQueuedRoutines,
  getRoutineState,
} from '~/pages/Workcell/utils/workcellStatus'
import usePollAsync from '~/utils/hooks/usePollAsync'
import { displayCount } from '~/utils/string'

import { Instrument } from '~/common.interface'
import { Timerange } from '~/components/ProcessTimelineViz/D3ProcessTimelineViz'
import { useIsMounted } from '~/utils/hooks/useIsMounted'
import { getOperatorActionLink } from '~/utils/operatorAction'
import ProcessTimeline from './ProcessTimeline'
import cs from './live_timeline.scss'

interface LiveTimelineProps {
  instruments: Instrument[] | null
}

const LiveTimeline = ({ instruments }: LiveTimelineProps) => {
  const isMounted = useIsMounted()
  const workcellStatus = useContext(WorkcellStatusContext)
  const routineState = getRoutineState(workcellStatus)

  const [timerange, setTimerange] = useState<Timerange>('10M')
  const [processStepData, setProcessStepData] = useState<GetLiveStepsResponse | null>(
    null,
  )
  const [allEnabledOperatorConfigs, setAllEnabledOperatorConfigs] =
    useState<EnabledOperatorConfigs | null>(null)

  // Update the instrument state
  const fetchSteps = async () => {
    try {
      workcellAPI.getLiveSteps().then(response => {
        if (!isMounted()) return
        setProcessStepData(response)
      })
    } catch (error) {
      console.error('Failed to fetch workcell status') // eslint-disable-line no-console
      console.error(String(error)) // eslint-disable-line no-console
    }
  }

  const fetchAllOperatorConfigs = () => {
    operatorActionAPI.getAllEnabledConfigs().then(allEnabledConfigs => {
      if (!isMounted()) return
      setAllEnabledOperatorConfigs(allEnabledConfigs.configs)
    })
  }

  // Do this regularly.
  usePollAsync(fetchSteps, 1000)

  useEffect(() => {
    fetchSteps()
    fetchAllOperatorConfigs()
  }, [])

  if (instruments === null || !processStepData) {
    return null
  }

  const getScheduleRoutinesLink = () => {
    let link
    if (allEnabledOperatorConfigs && allEnabledOperatorConfigs.generic_schedule) {
      link = getOperatorActionLink('generic_schedule')
    }

    return link || '/workcell/protocols/routine-definitions'
  }

  const renderRoutineState = () => {
    const routineIdsInProgress = get('routine_ids_in_progress', routineState)
    const routineIdsScheduled = get('routine_ids_scheduled', routineState)
    const numDefaultQueuedRoutines = getNumDefaultQueuedRoutines(workcellStatus)
    const numSupportQueuedRoutines = getNumSupportQueuedRoutines(workcellStatus)

    if (size(routineIdsInProgress) > 0 || size(routineIdsScheduled) > 0) {
      return (
        <div className={cs.status}>
          {displayCount('routine', numDefaultQueuedRoutines)} queued&nbsp;
          {numSupportQueuedRoutines > 0
            ? `(+${numSupportQueuedRoutines} support) `
            : null}
          <Link className={cs.link} to='/workcell/schedule/queued-routines'>
            View Queued Routines
          </Link>
        </div>
      )
    }

    return (
      <div className={cs.status}>
        No routines queued.&nbsp;
        <Link className={cs.link} to={getScheduleRoutinesLink()}>
          Schedule a Routine
        </Link>
      </div>
    )
  }

  return (
    <div className={cs.liveTimeline}>
      <div className={cs.controls}>
        {renderRoutineState()}
        <div className={cs.fill} />
        <div
          className={cx(cs.timeControl, timerange === '1M' && cs.selected)}
          onClick={() => setTimerange('1M')}
        >
          1M
        </div>
        <div
          className={cx(cs.timeControl, timerange === '10M' && cs.selected)}
          onClick={() => setTimerange('10M')}
        >
          10M
        </div>
        <div
          className={cx(cs.timeControl, timerange === '1H' && cs.selected)}
          onClick={() => setTimerange('1H')}
        >
          1H
        </div>
        <div
          className={cx(cs.timeControl, timerange === '1D' && cs.selected)}
          onClick={() => setTimerange('1D')}
        >
          1D
        </div>
      </div>
      <div className={cs.workcellTimeline}>
        <div className={cs.instruments}>
          {instruments.map(instrument => (
            <Link
              to={`/workcell/instruments/${instrument.instrumentName}`}
              className={cs.link}
              key={instrument.instrumentName}
            >
              <InstrumentTile
                instrument={instrument}
                instrumentStatus={getInstrumentStatus(
                  workcellStatus,
                  instrument.instrumentName,
                )}
              />
            </Link>
          ))}
        </div>
        <ProcessTimeline
          instruments={instruments}
          timerange={timerange}
          processStepData={processStepData}
        />
      </div>
    </div>
  )
}

LiveTimeline.propTypes = {
  instruments: PropTypes.arrayOf(
    PropTypes.shape({
      instrumentName: PropTypes.string,
      instrumentType: PropTypes.string,
    }),
  ),
}

export default LiveTimeline
