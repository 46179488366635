import {
  every,
  get,
  includes,
  isArray,
  isBoolean,
  isInteger,
  isNumber,
  isObject,
  isString,
  map,
} from 'lodash/fp'
import { JsonSchema, JsonSchemaProperty } from '~/types/JsonSchema.interface'

import {
  getFieldSchemaProperty,
  getFieldType,
  getFieldTypeObjByRef,
} from '~/utils/jsonSchema'

const _parseNumber = (newValue: string, key: string): number => {
  try {
    const value = JSON.parse(newValue)
    if (isNumber(value)) return value
  } catch (error) {
    console.error(String(error)) // eslint-disable-line no-console
  }
  throw new Error(`${key} must be number`)
}

const _parseInteger = (newValue: string, key: string): number => {
  try {
    const value = JSON.parse(newValue)
    if (isInteger(value)) return value
  } catch (error) {
    console.error(String(error)) // eslint-disable-line no-console
  }
  throw new Error(`${key} must be integer`)
}

const _getMinMaxError = (key: string, property: JsonSchemaProperty): string => {
  const min = property.constraints?.min
  const max = property.constraints?.max

  if (isNumber(min) && !isNumber(max)) {
    return `${key} must be greater than or equal to ${min}`
  }
  if (!isNumber(min) && isNumber(max)) {
    return `${key} must be less than or equal to ${max}`
  }
  return `${key} must be between ${min} and ${max}`
}

const _maybeValidateNumericalConstraints = (
  newValue: number,
  dataSchema: JsonSchema,
  key: string,
): void => {
  const min = dataSchema.properties[key]?.constraints?.min
  const max = dataSchema.properties[key]?.constraints?.max
  if (isNumber(min) && newValue < min) {
    throw new Error(_getMinMaxError(key, dataSchema.properties[key]))
  }
  if (isNumber(max) && newValue > max) {
    throw new Error(_getMinMaxError(key, dataSchema.properties[key]))
  }
}

// Throw error if value fails validation.
// newValue is always a string.
// Return a converted value of the correct type.
const validateNewValue = (
  key: string,
  newValue: string,
  dataSchema?: JsonSchema,
): unknown => {
  if (newValue === '') {
    return null
  }
  if (!dataSchema) {
    // When dataSchema isn't provided, treat all values as string type, which requires no
    // validation.
    return newValue
  }
  switch (getFieldType(key, dataSchema)) {
    case 'unit_float':
      try {
        const value = JSON.parse(newValue)
        if (isNumber(value)) {
          const unit = getFieldSchemaProperty(key, 'unit', dataSchema)
          return `${value} ${unit}`
        }
      } catch (error) {
        console.error(String(error)) // eslint-disable-line no-console
      }
      throw new Error(`${key} must be number`)
    case 'number': {
      const number = _parseNumber(newValue, key)
      _maybeValidateNumericalConstraints(number, dataSchema, key)
      return number
    }

    case 'integer': {
      const integer = _parseInteger(newValue, key)
      _maybeValidateNumericalConstraints(integer, dataSchema, key)
      return integer
    }
    // NOTE(damon): `sample_plate` and `reagent` are interesting in that you can't validate them
    // without additional state.
    //
    // Benchling had the concept of "legal" values and "valid" values, where "legal" meant it
    // was of the right type, and valid meant fully correct. For example:
    //    value=10           illegal, sample_plate values are strings
    //    value="??__??"     legal, not valid because no sample plate has that ID
    //    value="SAMPLE001"  legal, valid because a plate with ID SAMPLE001 exists
    //
    // Ideally we can provide full frontend validation, but it's worth being thoughtful about how
    // the data gets piped down.
    case 'sample_plate':
    case 'assay_plate':
    case 'reagent':
    case 'string':
    case 'string_datetime':
      return newValue
    case 'boolean':
      if (isBoolean(newValue)) return newValue
      throw new Error(`${key} must be boolean`)
    // For now, we assume that enums are all string enums.
    case 'enum': {
      const actualNewValue = newValue
      const typeObj = getFieldTypeObjByRef(key, dataSchema)
      const validValues = get('enum', typeObj)
      if (includes(actualNewValue, validValues)) return actualNewValue
      throw new Error(`${key} must be ${validValues.join(', ')}`)
    }
    case 'select_from_string_choices': {
      const actualNewValue = newValue
      const choices = getFieldSchemaProperty(key, 'choices', dataSchema)
      if (includes(actualNewValue, choices)) return actualNewValue
      throw new Error(`${key} must be ${choices.join(', ')}`)
    }
    case 'int_array': {
      try {
        let newValueArray = JSON.parse(newValue)

        if (isArray(newValueArray) && every(isInteger, newValueArray)) {
          newValueArray = map(value => parseInt(value), newValueArray)
          return newValueArray
        }
      } catch (error) {
        console.error(String(error)) // eslint-disable-line no-console
      }

      throw new Error(`${key} must be an array of integers`)
    }
    case 'sample_plate_array':
    case 'string_array': {
      try {
        // Double-quotes required for JSON
        const valueWithDoubleQuote = newValue.replace(/'/gi, '"')
        const newValueArray = JSON.parse(valueWithDoubleQuote)

        if (isArray(newValueArray) && every(isString, newValueArray)) {
          return newValueArray
        }
      } catch (error) {
        console.error(String(error)) // eslint-disable-line no-console
      }
      console.error(`Expected ${key} to be an array of strings. Got ${newValue}`)
      throw new Error(`${key} must be an array of strings`)
    }
    case 'well_array_by_sample_plate': {
      try {
        const value = JSON.parse(newValue)

        if (isObject(value) && every(isArray, value) && every(every(isString), value)) {
          return value
        }
      } catch (error) {
        console.error(String(error)) // eslint-disable-line no-console
      }
      throw new Error(`${key} must be object of array of strings`)
    }
    default:
      throw new Error(`${key} is an advanced config option. Please use 'Edit as JSON'.`)
  }
}

export default validateNewValue
