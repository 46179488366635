import dayjs from 'dayjs'
import { filter, findIndex, set, sortBy } from 'lodash/fp'
import { useEffect, useState } from 'react'

import { getOrganizationUsers } from '~/api/team'
import Pill from '~/components/Pill'
import Table, { TableColumn } from '~/components/Table'
import Toaster from '~/components/Toaster'
import Button from '~/components/buttons/Button'
import AddTeamIcon from '~/components/icons/AddTeamIcon'
import UserIcon from '~/components/icons/UserIcon'

import { UserMetadata, getUserIsAdmin } from '~/core/MultiFrontendContext'
import { UserProfile } from '~/types/UserProfile.interface'
import InviteUserDialog from './InviteUserDialog'
import ModifyUserDialog from './ModifyUserDialog'
import cs from './team_dashboard.scss'

const USER_COLUMNS: TableColumn<UserProfile>[] = [
  {
    name: 'User',
    width: 300,
    render: row => (
      <div className={cs.userProfile}>
        <div className={cs.userBigImageContainer}>
          {row.picture ? (
            <img className={cs.userBigImage} src={row.picture} alt='User profile' />
          ) : (
            <div className={cs.defaultUserBigImage}>
              <UserIcon className={cs.userIcon} />
            </div>
          )}
        </div>
        <div className={cs.text}>
          <div className={cs.name}>
            {row.first_name} {row.last_name} {row.is_you && '(You)'}
          </div>
          <div className={cs.org}>{row.email}</div>
        </div>
      </div>
    ),
  },
  {
    name: 'Role',
    width: 150,
    render: row => (
      <div className={cs.pillContainer}>
        <Pill
          label={row.is_admin ? 'Admin' : 'User'}
          type={row.is_admin ? 'accent' : 'info'}
        />
      </div>
    ),
  },
  {
    name: 'Last Login',
    width: 150,
    render: row => (
      <div className={cs.lastLogin}>
        {row.last_login ? dayjs(row.last_login).fromNow() : 'Invited'}
      </div>
    ),
  },
]

const TeamDashboard = ({ userMetadata }: { userMetadata: UserMetadata }) => {
  const [users, setUsers] = useState<UserProfile[]>([])
  const [loading, setLoading] = useState(true)
  const [inviteUserOpen, setInviteUserOpen] = useState(false)
  const [modifyUserOpen, setModifyUserOpen] = useState(false)
  const [userToModify, setUserToModify] = useState(null)

  const orgName = userMetadata.orgDisplayName
  const userEmail = userMetadata.email
  const userIsAdmin = getUserIsAdmin(userMetadata)

  const fetchUsers = async () => {
    const usersResponse = await getOrganizationUsers()
    setUsers(sortBy(['name'], usersResponse))
    setLoading(false)
  }

  const handleUserInvited = async newUser => {
    setUsers([newUser, ...users])
    setInviteUserOpen(false)
    Toaster.show({ message: `Added user ${newUser.email}.`, intent: 'success' })
  }

  const handleUserUpdated = (id, firstName, lastName, email, isAdmin) => {
    const index = findIndex(['id', id], users)
    const user = users[index]

    setUsers(
      set(
        index,
        {
          ...user,
          first_name: firstName,
          last_name: lastName,
          is_admin: isAdmin === null ? user.is_admin : isAdmin,
        },
        users,
      ),
    )

    setModifyUserOpen(false)
    Toaster.show({ message: `Updated user ${email}.`, intent: 'success' })
  }

  const handleUserClicked = user => {
    if (userIsAdmin || user.email === userEmail) {
      setUserToModify(user)
      setModifyUserOpen(true)
    }
  }

  useEffect(() => {
    fetchUsers()
  }, [])

  if (loading) {
    return (
      <div className={cs.teamDashboard}>
        <div className={cs.team}>
          <div className={cs.bigMessage}>Loading users...</div>
        </div>
      </div>
    )
  }

  return (
    <>
      <div className={cs.team}>
        <div className={cs.header}>
          <div className={cs.text}>
            <div className={cs.title}>{orgName}</div>
            <div className={cs.subtext}>
              {users.length} users, {filter(['is_admin', true], users).length} admins
            </div>
          </div>
          <div className={cs.fill} />
          {userIsAdmin && (
            <Button
              type='primary'
              IconComponent={AddTeamIcon}
              label='Invite a team member'
              className={cs.addButton}
              onClick={() => setInviteUserOpen(true)}
            />
          )}
        </div>
        <Table
          rowKey='email'
          columns={USER_COLUMNS}
          data={users}
          className={cs.userTable}
          onRowClick={handleUserClicked}
        />
      </div>
      <InviteUserDialog
        open={inviteUserOpen}
        onClose={() => setInviteUserOpen(false)}
        onUserInvited={handleUserInvited}
      />
      <ModifyUserDialog
        open={modifyUserOpen}
        user={userToModify}
        onClose={() => setModifyUserOpen(false)}
        onUserModified={handleUserUpdated}
        userIsAdmin={userIsAdmin}
      />
    </>
  )
}

TeamDashboard.propTypes = {}

export default TeamDashboard
