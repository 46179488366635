import { RecordManualPassageParseDataResponseErrors } from '~/api/operatorActions/recordManualPassage'

import pluralize from 'pluralize'
import { components } from '~/api/desktop/generated-schema'
import { displayCount } from '~/utils/string'
import { ErrorGroup } from '../../GroupedErrorTable'
import { generateErrorGroups } from '../../generateErrorGroups'
import {
  ParseSpreadsheetDataError,
  getGroupHeaderForSpreadsheetErrorType,
  getSpreadsheetErrorDisplayElement,
} from '../../spreadsheetDataError'

export type RecordManualPassageParseDataError =
  | ParseSpreadsheetDataError
  | components['schemas']['ParseCultureDataNonexistentPlate']
  | components['schemas']['ParseCultureDataParentPlateAlreadyExists']
  | components['schemas']['ParseCultureDataDuplicateParentPlate']
  | components['schemas']['ParseCultureDataInvalidWell']
  | components['schemas']['ParseCultureDataWellNotAlive']
  | components['schemas']['ParseCultureDataEmptyWell']
  | components['schemas']['ParseCultureDataDuplicateWell']

const _getErrorTypeForError = (
  error: RecordManualPassageParseDataError,
): RecordManualPassageParseDataError['type'] => {
  return error.type
}

const _getGroupHeaderForErrorType = (
  errorType: RecordManualPassageParseDataError['type'],
  numErrors: number,
): string => {
  if (errorType === 'nonexistent_plate') {
    return `${displayCount('plate', numErrors)} could not be found.`
  } else if (errorType === 'invalid_well') {
    return `${displayCount('well', numErrors)} ${pluralize('is', numErrors)} invalid based on the plate dimensions.`
  } else if (errorType == 'empty_well') {
    return `${displayCount('well', numErrors)} ${pluralize('has', numErrors)} never contained a live culture.`
  } else if (errorType == 'duplicate_well') {
    return `${displayCount('well', numErrors)} ${pluralize('was', numErrors)} entered multiple times.`
  } else if (errorType == 'well_not_alive') {
    return `${displayCount('well', numErrors)} ${numErrors == 1 ? 'does' : 'do'} not contain a live culture.`
  } else if (errorType === 'duplicate_parent_plate') {
    return `${displayCount('plate', numErrors)} ${pluralize('is', numErrors)} being passaged from multiple parent plates.`
  } else if (errorType === 'parent_plate_already_exists') {
    return `${displayCount('plate', numErrors)} ${pluralize('has', numErrors)} wells with passages already recorded.`
  }

  return getGroupHeaderForSpreadsheetErrorType(errorType, numErrors)
}
const _getErrorDisplayElement = (
  error: RecordManualPassageParseDataError,
): React.ReactNode => {
  if (error.type === 'nonexistent_plate') {
    return (
      <span>
        Plate <b>{error.plate_barcode}</b> does not exist.{' '}
        <i>(Row {error.line_number})</i>
      </span>
    )
  } else if (error.type == 'empty_well') {
    return (
      <span>
        <b>{error.well}</b> in plate <b>{error.plate_barcode}</b> has never contained a
        live culture. <i>(Row {error.line_number})</i>
      </span>
    )
  } else if (error.type == 'invalid_well') {
    return (
      <span>
        <b>{error.well}</b> is not valid for plate <b>{error.plate_barcode}</b> with
        plate format {error.plate_format}. <i>(Row {error.line_number})</i>
      </span>
    )
  } else if (error.type == 'duplicate_well') {
    return (
      <span>
        <b>{error.well}</b> in plate <b>{error.plate_barcode}</b> was already entered
        earlier. <i>(Row {error.line_number})</i>
      </span>
    )
  } else if (error.type == 'well_not_alive') {
    return (
      <span>
        <b>{error.well}</b> in plate <b>{error.plate_barcode}</b> must contain a live
        culture. <i>(Row {error.line_number})</i>
      </span>
    )
  } else if (error.type === 'duplicate_parent_plate') {
    return (
      <span>
        Plate <b>{error.plate_barcode}</b> can only be passaged from one parent plate
        and <b>{error.existing_parent_plate_barcode}</b> was already specified.{' '}
        <i>(Row {error.line_number})</i>
      </span>
    )
  } else if (error.type === 'parent_plate_already_exists') {
    return (
      <span>
        Plate <b>{error.plate_barcode}</b> has wells with passages already recorded from
        parent plate <b>{error.existing_parent_plate_barcode}</b>.{' '}
        <i>(Row {error.line_number})</i>
      </span>
    )
  }
  return getSpreadsheetErrorDisplayElement(error)
}

export const getRecordManualPassageParseDataErrorGroups = (
  errors: RecordManualPassageParseDataResponseErrors,
): ErrorGroup[] => {
  return generateErrorGroups<
    RecordManualPassageParseDataError,
    RecordManualPassageParseDataError['type']
  >(
    errors.errors,
    _getErrorTypeForError,
    _getGroupHeaderForErrorType,
    _getErrorDisplayElement,
  )
}
