import { useEffect } from 'react'

import Button from './buttons/Button'
import cs from './errors.scss'
import RefreshIcon from './icons/RefreshIcon'

/**
 * An error message that takes up the entire page or a large portion of it.
 */
export function FullPageError({
  error,
  customFriendlyMessage,
  customActionButton,
}: {
  /**
   * The error that caused us to render this component. This usually contains
   * technical information that isn't helpful to show to users.
   */
  error?: Error

  /**
   * A custom message to display instead of the general error message.
   *
   * **Use this sparingly! Only provide a message if you're sure the error is
   * always actionable for the user and the action is simple to perform.** If
   * you provide a message, it should be short and explain exactly what they
   * need to do. The message should avoid causing unnecessary worries about the
   * integrity or availability of their data.
   */
  customFriendlyMessage?: string

  customActionButton?: JSX.Element
}) {
  useEffect(() => {
    if (error) {
      console.error(error)
    }
  }, [error])

  const technicalInfo = JSON.stringify({
    origin: window.location.toString(),
    userMessage: customFriendlyMessage,
    error: error
      ? {
          name: error.name,
          message: error.message.slice(0, 2000),
        }
      : null,
  })

  return (
    <div className={cs.container}>
      <p className={cs.primaryMessage}>
        {customFriendlyMessage || (
          <>
            This page is temporarily unavailable while we deploy improvements to your
            service. We sincerely apologize for the inconvenience. Please try again in a
            few minutes.
          </>
        )}
      </p>

      {customActionButton ?? (
        <Button
          onClick={() => {
            window.location.reload()
          }}
          label='Reload'
          IconComponent={RefreshIcon}
          type='primary'
        />
      )}

      <p className={cs.secondaryMessage}>
        If this issue persists, contact your Monomer Support Team or email us at{' '}
        <a
          href={`mailto:support@monomerbio.com?subject=Issue on ${window.location.hostname}&body=Please describe the issue that you experienced:%0D%0D%0DTechnical information:%0D${technicalInfo}`}
        >
          support@monomerbio.com.
        </a>
      </p>
    </div>
  )
}
