import { useContext, useEffect, useState } from 'react'
import operatorActionAPI from '~/api/operatorActions/shared'
import { getComparatorString } from '~/utils/array'
import { useIsMounted } from '~/utils/hooks/useIsMounted'
import WorkcellStatusContext from '../WorkcellStatusContext'
import {
  getEnabledOperatorActions,
  getExperimentalEnableMonitor,
  getExperimentalEnableMonitorCultureDataUpload,
} from '../utils/workcellStatus'

export function useEnabledAndUnusedOperatorActionNames() {
  const isMounted = useIsMounted()
  const workcellStatus = useContext(WorkcellStatusContext)
  const [enabledOperatorActionNames, setEnabledOperatorActionNames] = useState<
    string[]
  >([])
  const [unusedOperatorActionNames, setUnusedOperatorActionNames] = useState<string[]>(
    [],
  )

  const fetchEnabledAndUnusedOperatorActionNames = async () => {
    const enabledAndUnusedOperatorActionNames =
      await operatorActionAPI.getEnabledAndUnusedOperatorActionNames()
    if (!isMounted()) return
    setEnabledOperatorActionNames(
      enabledAndUnusedOperatorActionNames.enabled_operator_action_names,
    )
    setUnusedOperatorActionNames(
      enabledAndUnusedOperatorActionNames.unused_operator_action_names,
    )
  }

  useEffect(() => {
    fetchEnabledAndUnusedOperatorActionNames()
  }, [
    // NOTE: Any feature flags which guard operator actions should be included here.
    getComparatorString(getEnabledOperatorActions(workcellStatus)),
    getExperimentalEnableMonitor(workcellStatus),
    getExperimentalEnableMonitorCultureDataUpload(workcellStatus),
  ])

  return { enabledOperatorActionNames, unusedOperatorActionNames }
}
