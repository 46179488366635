import cx from 'classnames'
import PropTypes from 'prop-types'
import { useState } from 'react'

import { inviteUser } from '~/api/team'
import Dialog from '~/components/Dialog'
import Input from '~/components/Input'
import Pill from '~/components/Pill'
import Button from '~/components/buttons/Button'

import { UserProfile } from '~/types/UserProfile.interface'
import cs from './invite_user_dialog.scss'

interface InviteUserDialogProps {
  open: boolean
  onClose: () => void
  onUserInvited: (newUser: UserProfile) => void
}

/** @deprecated This component should be removed when we tackle SWE-1292 */
const InviteUserDialog = ({ open, onClose, onUserInvited }: InviteUserDialogProps) => {
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState('')
  const [isAdmin, setIsAdmin] = useState(false)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')

  const ERROR_MESSAGES = {
    INVALID_EMAIL: 'Email is not valid.',
    USER_ALREADY_EXISTS: 'User with email already exists.',
    ADMIN_REQUIRED: 'This action requires admin privileges.',
    DOMAIN_MISMATCH: `User email must have a matching domain.`,
    UNKNOWN_ERROR: 'An unknown error occurred. Contact support@monomerbio.com.',
  }

  const onInviteUser = async () => {
    setLoading(true)
    setError('')
    let response
    try {
      response = await inviteUser(firstName, lastName, email, isAdmin)
    } catch (inviteUserError) {
      setLoading(false)
      setError('UNKNOWN_ERROR')
    }

    if (response) {
      setLoading(false)
      if (response.error) {
        setError(response.error)
      } else {
        onUserInvited(response.userProfile)
      }
    }
  }

  return (
    <Dialog isOpen={open} onClose={onClose} className={cs.inviteUserDialog}>
      <div className={cs.title}>Invite a team member</div>
      <Input
        label='First Name'
        value={firstName}
        onChange={setFirstName}
        className={cs.inputContainer}
        inputClassName={cs.input}
      />
      <Input
        label='Last Name'
        value={lastName}
        onChange={setLastName}
        className={cs.inputContainer}
        inputClassName={cs.input}
      />
      <Input
        label='Email Address'
        value={email}
        onChange={setEmail}
        className={cs.inputContainer}
        inputClassName={cs.input}
      />
      <div className={cs.label}>Role</div>
      <div className={cs.roleOptions}>
        <Pill
          className={cx(cs.roleOption, isAdmin && cs.selected)}
          onClick={() => setIsAdmin(true)}
          type={isAdmin ? 'accent' : 'default'}
          label='Admin'
        />
        <Pill
          className={cx(cs.roleOption, !isAdmin && cs.selected)}
          onClick={() => setIsAdmin(false)}
          type={!isAdmin ? 'info' : 'default'}
          label='User'
        />
      </div>
      <div className={cs.roleDescription}>
        {!isAdmin && 'A user can use apps  but cannot manage the team.'}
        {isAdmin && 'An admin can use apps and manage the team.'}
      </div>
      <div className={cs.sendInviteButtonContainer}>
        <div className={cs.error}>{ERROR_MESSAGES[error]}</div>
        <Button
          type='primary'
          label={loading ? 'Loading' : 'Send Invite'}
          disabled={!email || !firstName || !lastName || loading}
          className={cs.sendInviteButton}
          onClick={onInviteUser}
        />
      </div>
    </Dialog>
  )
}

InviteUserDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onUserInvited: PropTypes.func,
}

export default InviteUserDialog
