import cx from 'classnames'
import { difference, get, keys } from 'lodash/fp'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'

import StructuredDataForm from '~/components/StructuredDataForm'
import Textarea from '~/components/Textarea'
import Toaster from '~/components/Toaster'
import Button from '~/components/buttons/Button'
import MinimalButton from '~/components/buttons/MinimalButton'

import { JsonSchema, JsonSchemaResponse } from '~/types/JsonSchema.interface'
import { StructuredDataFormData } from '../StructuredDataForm/StructuredDataForm'
import cs from './config_dialog_tab.scss'

interface ConfigDialogTabApi {
  getSchema: () => Promise<JsonSchemaResponse>
  setConfig: (
    newConfig: StructuredDataFormData,
    overwrite: boolean,
  ) => Promise<StructuredDataFormData>
  getConfig: () => Promise<StructuredDataFormData>
}

interface ConfigDialogTabProps {
  className?: string
  onClose: () => void
  heightSizing: 'flex' | 'default'
  omitFields?: string[]
  api: ConfigDialogTabApi
  onConfigUpdated?: () => void
}

const ConfigDialogTab = ({
  className,
  api,
  onClose,
  heightSizing,
  omitFields,
  onConfigUpdated,
}: ConfigDialogTabProps) => {
  // ui or json
  const [mode, setMode] = useState('ui')
  const [config, setConfig] = useState<StructuredDataFormData | null>(null)
  // Transient values when the user edits the configs.
  const [configEditsUi, setConfigEditsUi] = useState<StructuredDataFormData | null>(
    null,
  )
  const [configEditsJson, setConfigEditsJson] = useState<string>('')
  const [errorJson, setErrorJson] = useState('')
  const [configSchema, setConfigSchema] = useState<JsonSchema | null>(null)

  const hydrateConfigEdits = _config => {
    setConfigEditsUi(_config)
    setConfigEditsJson(JSON.stringify(_config, undefined, 2))
  }

  const initialize = async () => {
    const _globalConfigSchema = await api.getSchema()
    const _config = await api.getConfig()

    setConfigSchema(_globalConfigSchema.schema)
    setConfig(_config)
    hydrateConfigEdits(_config)
  }
  useEffect(() => {
    initialize()
  }, [])

  const handleConfigSave = async () => {
    const oldConfig = config
    let newConfig

    if (mode === 'json') {
      try {
        newConfig = JSON.parse(configEditsJson)
        setErrorJson('')
      } catch (e) {
        setErrorJson('Invalid JSON')
        return
      }
    }
    if (mode === 'ui') {
      newConfig = configEditsUi
    }
    setConfig(newConfig)
    onClose()
    try {
      await api.setConfig(
        newConfig,
        true, // overwrite
      )
    } catch (_error) {
      // No need to handle disconnect error here, since it is handled with regular updateStatus.
      Toaster.show({
        message: 'New options failed to saved.',
        intent: 'danger',
      })
      console.error('Failed to save new options', _error)
      setConfig(oldConfig)
      setConfigEditsUi(oldConfig)
      return
    }
    Toaster.show({ message: 'New options saved.', intent: 'success' })
    if (onConfigUpdated) {
      onConfigUpdated()
    }
  }

  const handleConfigUpdateUi = (key: string, newValue: unknown) => {
    if (newValue === null) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { [key]: _, ...configWithoutKey } = configEditsUi ?? {}
      setConfigEditsUi(configWithoutKey)
    } else {
      setConfigEditsUi({ ...(configEditsUi ?? {}), [key]: newValue })
    }
  }

  const toggleMode = () => {
    if (mode === 'ui') {
      setMode('json')
    }
    if (mode === 'json') {
      setMode('ui')
    }
  }

  if (!configSchema) return null

  return (
    <div className={cx(cs.configDialogTab, className, cs[heightSizing])}>
      {mode === 'ui' && (
        <StructuredDataForm
          keysToDisplay={difference(
            keys(get('properties', configSchema)),
            omitFields || [],
          )}
          data={configEditsUi}
          variant='wideValues'
          onEdit={handleConfigUpdateUi}
          dataSchema={configSchema}
          className={cs.structuredDataForm}
        />
      )}
      {mode === 'json' && (
        <Textarea
          className={cs.textarea}
          value={configEditsJson}
          onChange={setConfigEditsJson}
        />
      )}
      {errorJson && mode === 'json' && <div className={cs.error}>{errorJson}</div>}
      <div className={cs.controls}>
        <Button label='Cancel' onClick={onClose} className={cs.button} />
        <Button
          type='primary'
          label='Save Changes'
          onClick={handleConfigSave}
          className={cs.button}
        />
        <div className={cs.fill} />
        <MinimalButton
          label={mode === 'ui' ? 'Edit as JSON' : 'Edit in UI'}
          onClick={toggleMode}
        />
      </div>
    </div>
  )
}

ConfigDialogTab.propTypes = {
  className: PropTypes.string,
  api: PropTypes.shape({
    getSchema: PropTypes.func,
    setConfig: PropTypes.func,
    getConfig: PropTypes.func,
  }),
  onClose: PropTypes.func,
  // Whether to use flex to determine height.
  // If flex is used, the Table's parent element must have an explicit height.
  heightSizing: PropTypes.oneOf(['flex', 'default']),
  omitFields: PropTypes.arrayOf(PropTypes.string),
}

ConfigDialogTab.defaultProps = {
  heightSizing: 'default',
}

export default ConfigDialogTab
