import { useFragment, useQuery } from '@apollo/client'
import { EventProperties } from '@segment/analytics-next'
import cx from 'classnames'
import dayjs from 'dayjs'
import { sortBy } from 'lodash'
import { useEffect, useMemo, useState } from 'react'
import { useHistory, useLocation } from 'react-router'

import { gql } from '~/__generated__'
import { WellPageQuery } from '~/__generated__/graphql'
import Breadcrumbs from '~/components/Breadcrumbs'
import { FullPageError } from '~/components/Errors'
import MontageHero, { MontageHeroLoadingPlaceholder } from '~/components/MontageHero'
import PageNotFound from '~/components/PageNotFound'
import TinyMicroplate from '~/components/TinyMicroplate'
import {
  SUPPORTED_PLATE_DIMENSIONS,
  supportedPlateFormats,
} from '~/components/TinyMicroplate.interface'
import ZeroWidthSpaceToken from '~/components/ZeroWidthSpaceToken'
import { analytics } from '~/core/analytics'
import { CultureStatusText } from '~/pages/Monitor/pages/CulturePage/CultureStatus'
import MontageImagesDialog from '~/pages/Monitor/pages/MontageImagesDialog/MontageImagesDialog'
import { decodeURITimestamp, encodeURITimestamp } from '~/utils/date'
import {
  convertWellCoordsToWellName,
  convertWellNameToWellCoord,
} from '~/utils/microplate'

import LinearScaleIcon from '~/components/icons/LinearScaleIcon'
import { useDemoQuery } from '~/demoControls/DemoContext'
import CultureLineage from '~/pages/Cle2025/CultureLineage/CultureLineage'
import LateralNavCompass, {
  LateralNavDirection,
  LateralNavAxisMode,
} from './LateralNavCompass'
import WellConfluenceGraphSidebar from './WellConfluenceGraphSidebar'
import WellMetadataSidebar from './WellMetadataSidebar'
import cs from './well_page.scss'

const query = gql(`
  query WellPage($plateId: UUID!, $position: String!) {
    culture: wellCultureByPlateIdAndPosition(
      plateId: $plateId
      position: $position
    ) {
      id
      name
      well
      status
      observationHistory {
        timestamp
      }
      culturePlate {
        id
        wellCultures {
          id
          well
        }
        plateDimensions {
          rows
          columns
        }
        ...PlateCrumbFragment
      }
      montage {
        ...MontageImagesDialogFragment
      }
      ...MontageHeroFragment
      ...WellMetadataSidebarFragment
      ...WellConfluenceGraphSidebarFragment
    }
  }
`)

type WellObservation = NonNullable<
  WellPageQuery['culture']
>['observationHistory'][number]

export default function WellPage({
  plateID,
  pos,
  enableLineage = false,
  routePrefix = '',
}: { plateID: string; pos: string; enableLineage?: boolean; routePrefix?: string }) {
  const variables = { plateId: plateID, position: pos }
  const demoData = useDemoQuery(
    routePrefix ? 'cle' : 'monitor',
    'WellPage',
  )?.(variables)
  const { loading, error, data } = useQuery(query, {
    variables,
    skip: demoData != null,
  })
  if (demoData?.culture) {
    return (
      <Content
        culture={demoData.culture}
        enableLineage={enableLineage}
        routePrefix={routePrefix}
      />
    )
  }
  if (loading) {
    return <LoadingPlaceholder plateID={plateID} pos={pos} routePrefix={routePrefix} />
  }
  if (error) {
    return <FullPageError error={error} />
  }
  const culture = data?.culture
  if (culture == null) {
    return <PageNotFound />
  }
  return (
    <Content
      culture={culture}
      enableLineage={enableLineage}
      routePrefix={routePrefix}
    />
  )
}

function Content({
  culture,
  enableLineage,
  routePrefix,
}: {
  culture: NonNullable<WellPageQuery['culture']>
  enableLineage: boolean
  routePrefix: string
}) {
  const history = useHistory()

  // The index of the image the user clicked, or null if the image dialog is closed.
  // Note: This doesn't update when switching images within the dialog.
  const [openedImage, setOpenedImage] = useState<number | null>(null)

  const sortedObservationHistory = useMemo(
    () => sortBy(culture.observationHistory, obs => obs.timestamp),
    [culture.observationHistory],
  )

  // Find the observation specified in the URL, defaulting to the latest one if
  // not specified.
  const location = useLocation()
  const params = new URLSearchParams(location.search)
  const decodedTime = decodeURITimestamp(params.get('t'))
  const decodedTimeISOString = decodedTime?.toISOString()
  let observationIndex = decodedTimeISOString
    ? sortedObservationHistory.findIndex(obs => obs.timestamp >= decodedTimeISOString)
    : -1
  if (observationIndex < 0) {
    observationIndex = sortedObservationHistory.length - 1
  }
  const observation = sortedObservationHistory[observationIndex] as
    | WellObservation
    | undefined
  const [showLineage, setShowLineage] = useState(
    params.get('lineage') === 'show' && enableLineage,
  )

  useEffect(() => {
    analytics.page('Monitor', 'Well', {
      cultureID: culture.id,
      numCultureObservations: culture.observationHistory.length,
      observationTimestamp: observation?.timestamp,
    })
  }, [culture.id, observation?.timestamp])

  // Canonicalize the timestamp in the URL.
  useEffect(() => {
    const canonicalObservationTimestamp = observation
      ? encodeURITimestamp(dayjs(observation.timestamp))
      : ''
    if (params.get('t') !== canonicalObservationTimestamp) {
      const newParams = new URLSearchParams(params)
      if (decodedTime) {
        // URL has a valid timestamp, but it doesn't exactly match an
        // observation. Keep the param in the URL, but make it match the
        // observation that we landed on.
        newParams.set('t', canonicalObservationTimestamp)
      } else {
        // URL doesn't have a valid timestamp. Remove the param from the URL to
        // make it clear that we're not using it.
        newParams.delete('t')
      }
      history.replace({ search: newParams.toString() })
    }
  }, [observation?.timestamp])

  const plateDims = culture.culturePlate.plateDimensions
  const tinyMicroplateFormat = SUPPORTED_PLATE_DIMENSIONS.some(
    ({ rows, columns }) => rows === plateDims.rows && columns === plateDims.columns,
  )
    ? (`wells_${plateDims.rows * plateDims.columns}` as supportedPlateFormats)
    : null

  return (
    <div>
      <Breadcrumbs
        plate={culture.culturePlate}
        wellPos={culture.well}
        routePrefix={routePrefix}
      />

      <div className={cs.main}>
        <div className={cs.titleArea}>
          <div>
            <div>
              <span className={cs.title}>Well {culture.well} </span>
              <ZeroWidthSpaceToken />
              <CultureStatusText status={culture.status} className={cs.status} />
            </div>
            {enableLineage && (
              <div>
                <a
                  className={cs.lineageButton}
                  onClick={() => {
                    const newParams = new URLSearchParams(params)
                    newParams.set('lineage', 'show')
                    history.replace({ search: newParams.toString() })
                    setShowLineage(true)
                  }}
                >
                  <LinearScaleIcon className={cs.lineageIcon} />
                  View Lineage
                </a>
              </div>
            )}
          </div>

          <LateralNavControls
            currentWell={culture.well}
            availableWells={culture.culturePlate.wellCultures.map(c => c.well)}
            tinyMicroplateFormat={tinyMicroplateFormat}
            onNavigate={toWell => {
              if (toWell !== culture.well) {
                history.replace(
                  `/plate/${culture.culturePlate.id}/well/${toWell}?${params.toString()}`,
                )
              }
            }}
          />
        </div>

        <div className={cs.heroToolbarArea}>
          <HeroToolbar
            observation={observation}
            page={{
              number: observationIndex + 1,
              total: sortedObservationHistory.length,
            }}
            onNavigate={dir => {
              let newIndex
              if (dir === LateralNavDirection.LEFT) {
                newIndex = Math.max(observationIndex - 1, 0)
              } else {
                newIndex = Math.min(
                  observationIndex + 1,
                  sortedObservationHistory.length - 1,
                )
              }
              const newTimestamp = sortedObservationHistory[newIndex].timestamp
              const newParams = new URLSearchParams(params)
              newParams.set('t', encodeURITimestamp(dayjs(newTimestamp)))
              history.replace({ search: newParams.toString() })
            }}
          />
        </div>

        <div className={cs.hero}>
          <MontageHero
            culture={culture}
            observationTimestamp={observation?.timestamp}
            onClickImage={
              culture.montage
                ? idx => {
                    setOpenedImage(idx)
                  }
                : undefined
            }
          />
        </div>

        <div className={cs.sidebar}>
          <WellConfluenceGraphSidebar culture={culture} />
          <WellMetadataSidebar culture={culture} />
        </div>
      </div>

      {culture.montage && openedImage != null ? (
        // Normally we'd keep this mounted and use the `isOpen` prop to control
        // its visibility. Here, as a hack, we unmount it when closed, so that
        // it always resets to the `initialImageIndex` when opened.
        <MontageImagesDialog
          montage={culture.montage}
          isOpen
          onClose={() => {
            setOpenedImage(null)
          }}
          initialImageIndex={openedImage ?? undefined}
          linkToCulture={false}
        />
      ) : null}

      {showLineage ? (
        <CultureLineage
          onClose={() => {
            const newParams = new URLSearchParams(params)
            newParams.delete('lineage')
            history.replace({ search: newParams.toString() })
            setShowLineage(false)
          }}
        />
      ) : null}
    </div>
  )
}

const wellPageLoadingPlaceholderPlateLookupFragment = gql(`
  fragment WellPageLoadingPlaceholder_PlateLookupFragment on CulturePlateGraphQL {
    plateDimensions {
      rows
      columns
    }
    wellCultures {
      id
      well
    }
  }
`)

function LoadingPlaceholder({
  plateID,
  pos,
  routePrefix,
}: { plateID: string; pos: string; routePrefix: string }) {
  const location = useLocation()
  const params = new URLSearchParams(location.search)
  const history = useHistory()
  const { data: cachedPlate, complete } = useFragment({
    fragment: wellPageLoadingPlaceholderPlateLookupFragment,
    from: { __typename: 'CulturePlateGraphQL', id: plateID },
  })

  const tinyMicroplateFormat =
    complete &&
    SUPPORTED_PLATE_DIMENSIONS.some(
      ({ rows, columns }) =>
        rows === cachedPlate.plateDimensions.rows &&
        columns === cachedPlate.plateDimensions.columns,
    )
      ? (`wells_${cachedPlate.plateDimensions.rows * cachedPlate.plateDimensions.columns}` as supportedPlateFormats)
      : null

  return (
    <div>
      <Breadcrumbs plate={{ id: plateID }} wellPos={pos} routePrefix={routePrefix} />

      <div className={cs.main}>
        <div className={cs.titleArea}>
          <div>
            <span className={cs.title}>Well {pos}</span>
          </div>

          <LateralNavControls
            currentWell={pos}
            availableWells={cachedPlate.wellCultures?.map(c => c.well) ?? [pos]}
            tinyMicroplateFormat={tinyMicroplateFormat}
            onNavigate={toWell => {
              if (toWell !== pos) {
                history.replace(`/plate/${plateID}/well/${toWell}?${params.toString()}`)
              }
            }}
          />
        </div>

        <div className={cs.heroToolbarArea}>
          <HeroToolbar loading />
        </div>

        <div className={cs.hero}>
          <MontageHeroLoadingPlaceholder
            plateDims={complete ? cachedPlate.plateDimensions : undefined}
          />
        </div>
      </div>
    </div>
  )
}

function LateralNavControls({
  currentWell,
  availableWells,
  tinyMicroplateFormat,
  onNavigate,
}: {
  currentWell: string
  availableWells: string[]
  tinyMicroplateFormat: supportedPlateFormats | null
  onNavigate: (toWell: string) => void
}) {
  const availableWellsSet = useMemo(() => new Set(availableWells), [availableWells])
  const nextWellFor = useWellNavOrdering(currentWell, availableWells)
  const [hoveredWell, setHoveredWell] = useState<string | null>(null)

  const trackNavigatedAnalyticsEvent = (using: string, extra: EventProperties) => {
    analytics.track('Navigated from well to well', {
      using,
      plateFormat: tinyMicroplateFormat,
      ...extra,
    })
  }

  return (
    <div className={cs.lateralNavControls}>
      {
        // For visual stability, we keep showing the label regardless if the
        // user is hovering the minimap or the compass. But, to make it clear
        // that unavailable wells cannot be navigated to, we temporarily hide
        // the label if hovering over an unavailable well.
        !hoveredWell || availableWellsSet.has(hoveredWell) ? (
          <div
            className={cx(cs.label, {
              [cs.active]: !hoveredWell || hoveredWell === currentWell,
            })}
          >
            {hoveredWell ?? currentWell}
          </div>
        ) : null
      }

      {tinyMicroplateFormat ? (
        <TinyMicroplate
          className={cs.tinyMicroplate}
          plateFormat={tinyMicroplateFormat}
          highlights={[
            {
              colorRgbFn: (row: number, col: number) => {
                const well = convertWellCoordsToWellName(row, col)
                if (well === currentWell) {
                  return '#2cb1bc' // Accent
                }
                if (availableWellsSet.has(well)) {
                  return '#ccc' // Grey Light
                }
                return '#eaeaea' // Grey Lightest
              },
            },
          ]}
          onClickCell={(rowIndex, colIndex) => {
            const well = convertWellCoordsToWellName(rowIndex, colIndex)
            if (availableWellsSet.has(well)) {
              onNavigate(well)
              trackNavigatedAnalyticsEvent('minimap', { toWell: well })
            }
          }}
          onMouseOverCell={(rowIndex, colIndex) => {
            setHoveredWell(convertWellCoordsToWellName(rowIndex, colIndex))
          }}
          onMouseLeave={() => {
            setHoveredWell(null)
          }}
        />
      ) : null}

      <div className={cs.compassContainer}>
        <LateralNavCompass
          onNavigate={direction => {
            onNavigate(nextWellFor(direction))
            trackNavigatedAnalyticsEvent('direction_buttons', { direction })
          }}
        />
      </div>
    </div>
  )
}

/**
 * Return a function that returns the next well in a particular 2D direction.
 *
 * If there are no available wells, the returned function always returns the
 * current well.
 */
function useWellNavOrdering(
  currentWell: string,
  availableWells: string[],
): (dir: LateralNavDirection) => string {
  const orderings = useMemo(() => {
    const availableCoords = availableWells.map(well => convertWellNameToWellCoord(well))

    // Horizontal navigation uses row-major order: [A1, A2, A3, B1, B2, B3]
    const horizontal = availableCoords
      .toSorted((a, b) => a.colIndex - b.colIndex)
      .toSorted((a, b) => a.rowIndex - b.rowIndex)
      .map(({ rowIndex, colIndex }) => convertWellCoordsToWellName(rowIndex, colIndex))

    // Vertical navigation uses column-major order: [A1, B1, A2, B2, A3, B3]
    const vertical = availableCoords
      .toSorted((a, b) => a.rowIndex - b.rowIndex)
      .toSorted((a, b) => a.colIndex - b.colIndex)
      .map(({ rowIndex, colIndex }) => convertWellCoordsToWellName(rowIndex, colIndex))

    return { horizontal, vertical }
  }, [availableWells])

  const movement: { [key in LateralNavDirection]: [string[], number] } = {
    [LateralNavDirection.UP]: [orderings.vertical, -1],
    [LateralNavDirection.DOWN]: [orderings.vertical, +1],
    [LateralNavDirection.LEFT]: [orderings.horizontal, -1],
    [LateralNavDirection.RIGHT]: [orderings.horizontal, +1],
  }

  return (direction: LateralNavDirection) => {
    const [order, step] = movement[direction]
    if (order.length === 0) {
      return currentWell
    }

    const currentIndex = order.indexOf(currentWell)
    let newIndex = (currentIndex + step) % order.length
    if (newIndex === -1) {
      newIndex = order.length - 1
    }
    return order[newIndex]
  }
}

function HeroToolbar({
  observation,
  page,
  loading,
  onNavigate,
}: {
  observation?: WellObservation
  page?: { number: number; total: number }
  loading?: boolean
  onNavigate?: (dir: LateralNavDirection.LEFT | LateralNavDirection.RIGHT) => void
}) {
  const time = observation ? dayjs(observation.timestamp) : null
  const isThisYear = time?.year() === dayjs().year()

  const enabledDirections = [
    page != null && page.number > 1 ? LateralNavDirection.LEFT : null,
    page != null && page.number < page.total ? LateralNavDirection.RIGHT : null,
  ].filter(d => d != null)

  return (
    <div className={cs.heroToolbarContent}>
      <div>
        {time ? (
          <>
            {time.format(isThisYear ? 'MMM D' : 'MMM D, YYYY')}&ensp;
            {time.format('h:mm A')} ({time.fromNow()})
          </>
        ) : loading ? (
          <>&nbsp;</>
        ) : (
          <>No images yet.</>
        )}
      </div>

      <div className={cs.pager}>
        {page?.total ? (
          <span>
            Observation {page.number} of {page.total}
          </span>
        ) : page ? (
          <span>No observations</span>
        ) : null}

        <LateralNavCompass
          axes={LateralNavAxisMode.HORIZONTAL}
          onNavigate={d => {
            onNavigate?.(d as LateralNavDirection.LEFT | LateralNavDirection.RIGHT)
          }}
          enable={enabledDirections}
        />
      </div>
    </div>
  )
}
