import { ProcessItemLike } from '~/common.interface'
import StorageViz from '~/pages/Workcell/components/StorageViz'
import { SelectedStorageLocation } from '~/pages/Workcell/components/StorageViz/StorageVizView'
import TransferStationViz from '~/pages/Workcell/components/TransferStationViz'
import { SelectedTransferStation } from '~/pages/Workcell/components/TransferStationViz/TransferStationVizView'
import { isLiconicInstrument } from '~/pages/Workcell/components/instrument/isLiconicInstrument'
import { isStorageInstrument } from '~/utils/instrument'
import { InstrumentDescriptorForItemType } from '../InstrumentDescriptorForItemType.interface'
import {
  LoadUnloadItemsProcessItemType,
  convertLoadUnloadItemsProcessItemTypeToProcessItemType,
} from '../LoadUnloadItemsProcessItemType.interface'

export interface LoadUnloadItemsInstrumentVizViewProps {
  className?: string
  instrumentDescriptorForItemtype: InstrumentDescriptorForItemType
  selectedStorageLocations: SelectedStorageLocation[]
  onSlotClick?: (
    processItemUuid: string | null,
    shelfIndex: number,
    levelIndex: number,
    isChanged: boolean,
  ) => void
  selectedTransferStations: SelectedTransferStation[]
  onTransferStationClick?: (
    item: ProcessItemLike | null,
    transferStationId: string,
    isChanged: boolean,
  ) => void
  selectedProcessItemType: LoadUnloadItemsProcessItemType
  loadOrUnload: 'load' | 'unload'
  reloadKey?: string
  disabled?: boolean
}

const LoadUnloadItemsInstrumentVizView = ({
  instrumentDescriptorForItemtype,
  selectedStorageLocations,
  onSlotClick,
  selectedTransferStations,
  onTransferStationClick,
  selectedProcessItemType,
  loadOrUnload,
  reloadKey,
  disabled,
  className,
}: LoadUnloadItemsInstrumentVizViewProps) => {
  if (!instrumentDescriptorForItemtype) {
    return null
  }

  const clickableProcessItemType =
    convertLoadUnloadItemsProcessItemTypeToProcessItemType(selectedProcessItemType)

  if (isStorageInstrument(instrumentDescriptorForItemtype.instrument.instrumentType)) {
    const isLiconic = isLiconicInstrument(
      instrumentDescriptorForItemtype.instrument.instrumentType ?? '',
    )
    return (
      <StorageViz
        instrument={instrumentDescriptorForItemtype.instrument}
        clickablePartitions={instrumentDescriptorForItemtype.allowed_partitions}
        clickableOnItemOnly={loadOrUnload === 'unload' ? true : undefined}
        clickableOnNoItemOnly={loadOrUnload === 'load' ? true : undefined}
        clickableItemType={
          loadOrUnload === 'unload' ? clickableProcessItemType : undefined
        }
        onSlotClick={onSlotClick}
        selectedLocations={selectedStorageLocations}
        reloadKey={reloadKey}
        disabled={disabled}
        className={className}
        displaySizeDimensionThresholds={{
          mini: { numLevels: 15, numShelves: 6 },
          normal: { numLevels: 12, numShelves: 5 },
        }}
        hideLevelLabels={isLiconic}
        hideShelfLabels={isLiconic}
      />
    )
  }
  return (
    <TransferStationViz
      instrument={instrumentDescriptorForItemtype.instrument}
      clickableStations={instrumentDescriptorForItemtype.allowed_transfer_stations}
      onTransferStationClick={onTransferStationClick}
      selectedLocations={selectedTransferStations}
      clickableOnItemOnly={loadOrUnload === 'unload' ? true : undefined}
      clickableOnNoItemOnly={loadOrUnload === 'load' ? true : undefined}
      clickableItemType={
        loadOrUnload === 'unload' ? clickableProcessItemType : undefined
      }
      reloadKey={reloadKey}
      disabled={disabled}
      className={className}
    />
  )
}

export default LoadUnloadItemsInstrumentVizView
