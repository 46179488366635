import cx from 'classnames'

import PropTypes from '~/propTypes'

import Input from './Input'
import cs from './input_with_units.scss'
import TinyNotification from './notifications/TinyNotification'

export interface InputWithUnitsProps {
  className?: string
  value?: string
  unit?: string
  onChange?(...args: unknown[]): unknown
  changeOnBlur?: boolean
  errorMessage?: string
  label?: string
}

const InputWithUnits = (props: InputWithUnitsProps) => {
  const { className, value, unit, onChange, changeOnBlur, label, errorMessage } = props

  // We render the label and error message here instead of passing it into <Input>
  // (which can also render these elements) to simplify aligning the unit element
  // with the input.
  return (
    <div className={cx(cs.inputWithUnits, className)}>
      {label && <div className={cs.label}>{label}</div>}
      <div className={cs.wrapper}>
        <Input
          className={cs.innerInput}
          inputClassName={cs.innerInput}
          value={value}
          onChange={onChange}
          changeOnBlur={changeOnBlur}
        />
        {unit && <div className={cs.unit}>{unit}</div>}
      </div>
      {errorMessage && (
        <TinyNotification
          type='bareError'
          message={errorMessage}
          className={cs.errorMessage}
        />
      )}
    </div>
  )
}

InputWithUnits.propTypes = {
  className: PropTypes.string,
  value: PropTypes.string,
  unit: PropTypes.string,
  // Only fire onChange when the input is blurred.
  changeOnBlur: PropTypes.bool,
  onChange: PropTypes.func,
  label: PropTypes.string,
  errorMessage: PropTypes.string,
}

export default InputWithUnits
