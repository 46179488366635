import { Popover2 } from '@blueprintjs/popover2'
import cx from 'classnames'
import dayjs from 'dayjs'
import { memoize, omit } from 'lodash/fp'
import { useCallback, useEffect, useState } from 'react'

import CalendarIcon from '~/components/icons/CalendarIcon'
import {
  dateToDisplayString,
  datetimeToDisplayString,
  datetimeToUTCIsoStringTruncateSeconds,
} from '~/utils/date'

import DatePicker from './DatePicker'
import cs from './date_input.scss'

interface MyDateInputProps {
  className?: string
  inputClassName?: string
  label?: string
  value?: string | null
  defaultDate?: Date
  // By default, returns "2023-02-02"
  onChange?: (newDate: string | null) => void
  placeholder?: string
  minDate?: Date
  showTime?: boolean
  showActionsBar?: boolean
  disabled?: boolean
  canClearSelection?: boolean
  todayButtonText?: string
}

const MyDateInput = ({
  value,
  onChange,
  defaultDate,
  className,
  inputClassName,
  label,
  minDate,
  disabled,
  todayButtonText,
  placeholder = 'Select Date...',
  showTime = false,
  showActionsBar = false,
  canClearSelection = false,
}: MyDateInputProps) => {
  const dateDisplayFormatFn = showTime ? datetimeToDisplayString : dateToDisplayString
  const dateValueFormatFn = showTime
    ? datetimeToUTCIsoStringTruncateSeconds
    : dateToDisplayString

  const formatDate = useCallback(dateDisplayFormatFn, [showTime])
  const parseDate = useCallback(
    memoize((str: string) => dayjs(str).toDate()),
    [showTime],
  )

  useEffect(() => {
    if (!value && defaultDate) {
      handleChange(defaultDate)
    }
  }, [defaultDate])

  useEffect(() => {
    if (value) {
      handleChange(parseDate(value))
    }
  }, [showTime])
  const [open, setOpen] = useState(false)

  const handleDatePick = (_value: Date, hasUserManuallySelectedDate) => {
    if (hasUserManuallySelectedDate) {
      // If the time picker is also displayed, then don't auto-close the DatePicker on user select.
      if (!showTime) {
        setOpen(false)
      }
      handleChange(_value)
    }
  }

  const handleChange = (_value: Date) => {
    if (onChange) {
      if (_value) {
        onChange(dateValueFormatFn(!minDate || _value > minDate ? _value : minDate))
      } else {
        onChange(null)
      }
    }
  }

  const renderDatePicker = () => {
    return (
      <DatePicker
        value={value ? parseDate(value) : undefined}
        onChange={handleDatePick}
        minDate={minDate}
        showTime={showTime}
        showActionsBar={showActionsBar}
        canClearSelection={canClearSelection}
        todayButtonText={todayButtonText}
      />
    )
  }

  const renderTarget = ({ ...targetProps }) => {
    // We omit isOpen. It is passed from Popover2, but not required.
    // It also causes react to throw a warning.
    return (
      <div className={className}>
        {label && <div className={cs.label}>{label}</div>}
        <div
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...omit(['isOpen'], targetProps)}
          className={cx(
            cs.dateInput,
            inputClassName,
            !value && cs.placeholder,
            disabled && cs.disabled,
          )}
          onClick={() => !disabled && setOpen(true)}
        >
          <span className={cs.value}>
            {value ? formatDate(parseDate(value)) : placeholder}
          </span>
          <CalendarIcon className={cs.icon} />
        </div>
      </div>
    )
  }

  return (
    <Popover2
      content={renderDatePicker()}
      interactionKind='click'
      isOpen={open}
      onInteraction={setOpen}
      placement='bottom'
      renderTarget={renderTarget}
    />
  )
}

export default MyDateInput
