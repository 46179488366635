import PropTypes from 'prop-types'
import { useContext, useEffect, useState } from 'react'

import cx from 'classnames'
import { identity, keys, pickBy, size } from 'lodash/fp'
import futureRoutinesAPI from '~/api/desktop/futureRoutines'
import { FutureRoutine } from '~/api/desktop/future_routine_schemas.interface'
import { useTaskExecutor } from '~/api/useTaskExecutor'
import Dialog from '~/components/Dialog'
import MinimalButton from '~/components/buttons/MinimalButton'
import CloseIcon from '~/components/icons/CloseIcon'
import ExecuteIcon from '~/components/icons/ExecuteIcon'
import TinyNotification from '~/components/notifications/TinyNotification'
import WorkcellStatusContext from '~/pages/Workcell/WorkcellStatusContext'
import {
  areStepsScheduled,
  getFutureRoutinesLastUpdateTime,
} from '~/pages/Workcell/utils/workcellStatus'
import { useIsMounted } from '~/utils/hooks/useIsMounted'
import EditFutureRoutineSchedulePopover from '../EditFutureRoutineSchedulePopover'
import FutureRoutineRequiresAttentionTable from '../FutureRoutineTable/FutureRoutineRequiresAttentionTable'
import {
  QueueFutureRoutinesResult,
  cancelFutureRoutines,
  queueFutureRoutines,
} from '../futureRoutineActions'
import { getFutureRoutinesRequiringUserAttention } from '../getFilteredFutureRoutines'
import cs from './requires_user_attention_dialog.scss'

const RequiresUserAttentionDialog = ({
  isOpen,
  onClose,
  onUpdateSuccess,
}: {
  isOpen: boolean
  onClose: () => void
  onUpdateSuccess: () => void
}) => {
  const isMounted = useIsMounted()
  const workcellStatus = useContext(WorkcellStatusContext)
  const [routines, setRoutines] = useState<FutureRoutine[]>([])
  const [loading, setLoading] = useState(false)
  const [selectedRowUuids, setSelectedRowUuids] = useState({})
  const {
    isExecuting: isExecutingQueue,
    response: queueResponse,
    submitTask,
  } = useTaskExecutor<QueueFutureRoutinesResult>()
  const stepsScheduled = areStepsScheduled(workcellStatus)

  useEffect(() => {
    refreshFutureRoutines()
  }, [getFutureRoutinesLastUpdateTime(workcellStatus)])

  const refreshFutureRoutines = () => {
    setLoading(true)
    futureRoutinesAPI.listFutureRoutines().then(_routines => {
      if (!isMounted()) return
      setRoutines(_routines)
      setLoading(false)
      if (getFutureRoutinesRequiringUserAttention(_routines).length === 0) {
        onClose()
      }
    })
  }

  const handleUpdateSuccess = () => {
    refreshFutureRoutines()
    onUpdateSuccess()
  }

  const submit = () => {
    queueFutureRoutines(selectedRowUuidsArr, submitTask, handleUpdateSuccess)
  }

  const cancel = () => {
    cancelFutureRoutines(selectedRowUuidsArr, handleUpdateSuccess)
  }

  const selectedRowUuidsArr = keys(pickBy(identity, selectedRowUuids))

  const renderNotification = () => {
    if (isExecutingQueue) {
      return (
        <TinyNotification
          className={cs.notification}
          type='loading'
          message={'Scheduling future routines...'}
        />
      )
    }
    if (queueResponse?.error) {
      return (
        <TinyNotification
          className={cs.notification}
          type='error'
          message={queueResponse?.error}
        />
      )
    }

    return null
  }

  const getSelectedRoutines = () => {
    return routines.filter(routine => {
      return selectedRowUuids[routine.uuid]
    })
  }

  const notification = renderNotification()

  return (
    <Dialog
      isOpen={isOpen}
      onClose={onClose}
      className={cs.requiresUserAttentionDialog}
    >
      <Dialog.Title>Review Future Routines</Dialog.Title>
      <Dialog.Info>
        The following future routines could not be executed automatically as scheduled.
        Please select how to proceed.
      </Dialog.Info>
      <FutureRoutineRequiresAttentionTable
        routines={getFutureRoutinesRequiringUserAttention(routines)}
        className={cs.routineList}
        loading={loading}
        selectable
        selectedRoutineUuids={selectedRowUuids}
        onRoutineUuidsSelected={setSelectedRowUuids}
      />
      <div className={cs.actionTitle}>Select Action</div>
      {notification}
      <div className={cx(cs.controls, notification && cs.withNotification)}>
        <MinimalButton
          type='primary'
          className={cs.button}
          label='Schedule Now'
          disabled={
            isExecutingQueue || size(selectedRowUuidsArr) === 0 || stepsScheduled
          }
          onClick={submit}
          IconComponent={ExecuteIcon}
          variant='normal'
          appearNormalIfNotHovered
          popoverMessageIfDisabled={
            stepsScheduled
              ? 'There are already routines queued. Consider setting your future routines to automatically execute with Set New Schedule.'
              : undefined
          }
        />
        <MinimalButton
          type='danger'
          className={cs.button}
          label='Cancel Routines'
          disabled={isExecutingQueue || size(selectedRowUuidsArr) === 0}
          onClick={cancel}
          IconComponent={CloseIcon}
          variant='normal'
          appearNormalIfNotHovered
        />
        <EditFutureRoutineSchedulePopover
          disabled={isExecutingQueue || size(selectedRowUuidsArr) === 0}
          className={cs.editSchedulePopover}
          editExistingOrSetNew='set_new'
          selectedRoutines={getSelectedRoutines()}
          onEditSuccess={handleUpdateSuccess}
        />
      </div>
    </Dialog>
  )
}

RequiresUserAttentionDialog.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
}

export default RequiresUserAttentionDialog
