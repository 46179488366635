import PropTypes from 'prop-types'
import { IconProps } from './types/IconProps'
const UploadIcon = ({ className, onClick }: IconProps) => (
  <svg
    width='36'
    height='36'
    viewBox='0 0 36 36'
    xmlns='http://www.w3.org/2000/svg'
    onClick={onClick}
    className={className}
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M29.025 15.06C28.005 9.885 23.46 6 18 6C13.665 6 9.9 8.46 8.025 12.06C3.51 12.54 0 16.365 0 21C0 25.965 4.035 30 9 30H28.5C32.64 30 36 26.64 36 22.5C36 18.54 32.925 15.33 29.025 15.06ZM28.5 27H9C5.685 27 3 24.315 3 21C3 17.925 5.295 15.36 8.34 15.045L9.945 14.88L10.695 13.455C12.12 10.71 14.91 9 18 9C21.93 9 25.32 11.79 26.085 15.645L26.535 17.895L28.83 18.06C31.17 18.21 33 20.175 33 22.5C33 24.975 30.975 27 28.5 27ZM15.825 19.5H12L18 13.5L24 19.5H20.175V24H15.825V19.5Z'
    />
  </svg>
)

UploadIcon.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
}

export default UploadIcon
