import cx from 'classnames'
import { find } from 'lodash/fp'
import { useMemo } from 'react'

import ProcessTimelineViz from '~/components/ProcessTimelineViz'
import PropTypes from '~/propTypes'

import {
  D3ProcessTimelineVizData,
  Timerange,
} from '~/components/ProcessTimelineViz/D3ProcessTimelineViz'
import ProcessStepTooltip from './ProcessStepTooltip'
import cs from './process_timeline.scss'

interface ProcessTimelineProps {
  className?: string
  instruments: { instrumentName: string }[]
  timerange: Timerange
  processStepData: D3ProcessTimelineVizData
}

const ProcessTimeline = ({
  className,
  instruments,
  timerange,
  processStepData,
}: ProcessTimelineProps) => {
  const renderTooltipContents = params => {
    const step = find(['uuid', params.data.uuid], processStepData.steps)
    return <ProcessStepTooltip step={step} />
  }

  // Use useMemo to prevent unnecessary renders.
  const options = useMemo(
    () => ({
      instruments,
      timerange,
    }),
    [instruments, timerange],
  )

  if (!processStepData) {
    return <div className={cx(cs.processTimeline, className)} />
  }

  return (
    <div className={cx(cs.processTimeline, className)}>
      <ProcessTimelineViz
        data={processStepData}
        className={cs.processTimelineViz}
        height={instruments.length * 75 + 50}
        options={options}
        layoutOptions={{
          marginLeft: 0,
          marginRight: 0,
          marginTop: 25,
          marginBottom: 0,
        }}
        renderTooltipContents={renderTooltipContents}
        instruments={instruments}
      />
    </div>
  )
}

ProcessTimeline.propTypes = {
  className: PropTypes.string,
  height: PropTypes.number,
  instruments: PropTypes.arrayOf(
    PropTypes.shape({
      instrumentName: PropTypes.string,
      instrumentType: PropTypes.string,
    }),
  ),
  timerange: PropTypes.string,
  processStepData: PropTypes.shape({
    steps: PropTypes.arrayOf(PropTypes.ProcessStep),
  }),
}

export default ProcessTimeline
