import cx from 'classnames'
import {} from 'lodash/fp'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import operatorActionAPI from '~/api/operatorActions/shared'

import workcellAPI from '~/api/desktop/workcell'
import Table from '~/components/Table'
import Toaster from '~/components/Toaster'
import DeleteIcon from '~/components/icons/DeleteIcon'
import { remove } from '~/utils/array'
import { useIsMounted } from '~/utils/hooks/useIsMounted'
import {
  OPERATOR_ACTION_ICONS,
  OperatorAction,
  getSortedOperatorActions,
} from '~/utils/operatorAction'
import OperatorActionSelect from './OperatorActionSelect'
import cs from './add_remove_operator_action_tab.scss'

const AddRemoveOperatorActionTab = ({ className }: { className?: string }) => {
  const isMounted = useIsMounted()

  const [enabledOperatorActionNames, setEnabledOperatorActionNames] = useState<
    string[]
  >([])
  const [unusedOperatorActionNames, setUnusedOperatorActionNames] = useState<string[]>(
    [],
  )

  const fetchEnabledAndUnusedOperatorActionNames = async () => {
    const enabledAndUnusedOperatorActionNames =
      await operatorActionAPI.getEnabledAndUnusedOperatorActionNames()
    if (!isMounted) return
    setEnabledOperatorActionNames(
      enabledAndUnusedOperatorActionNames.enabled_operator_action_names,
    )
    setUnusedOperatorActionNames(
      enabledAndUnusedOperatorActionNames.unused_operator_action_names,
    )
  }

  const handleOperatorActionSelect = async (action: OperatorAction) => {
    const newEnabledOperatorActions = [...enabledOperatorActionNames, action.key]
    setEnabledOperatorActionNames(newEnabledOperatorActions)
    setUnusedOperatorActionNames(remove(action.key, unusedOperatorActionNames))
    try {
      await workcellAPI.setConfig(
        {
          enabledOperatorActions: newEnabledOperatorActions,
        },
        false,
      )
      Toaster.show({ message: `Added ${action.key}`, intent: 'success' })
    } catch (e) {
      Toaster.show({ message: `Failed to add ${action.key}`, intent: 'danger' })
    }
  }

  const handleOperatorActionRemove = async (action: OperatorAction) => {
    const newEnabledOperatorActions = remove(action.key, enabledOperatorActionNames)
    setEnabledOperatorActionNames(newEnabledOperatorActions)
    setUnusedOperatorActionNames([...unusedOperatorActionNames, action.key])
    try {
      await workcellAPI.setConfig(
        {
          enabledOperatorActions: newEnabledOperatorActions,
        },
        false,
      )
      Toaster.show({ message: `Removed ${action.key}`, intent: 'success' })
    } catch (e) {
      Toaster.show({
        message: `Failed to remove ${action.key}`,
        intent: 'danger',
      })
    }
  }

  useEffect(() => {
    fetchEnabledAndUnusedOperatorActionNames()
  }, [])

  const tableColumns = [
    {
      name: '',
      width: 40,
      render: (row: OperatorAction) =>
        'icon' in row && (
          <img
            className={cs.icon}
            src={OPERATOR_ACTION_ICONS[row.icon]}
            alt={row.name}
          />
        ),
    },
    {
      name: '',
      width: 'flex',
      render: (row: OperatorAction) => row.key,
    },
    {
      name: '',
      width: 30,
      render: (row: OperatorAction) => (
        <DeleteIcon
          className={cs.deleteIcon}
          onClick={() => handleOperatorActionRemove(row)}
        />
      ),
      showOnHover: true,
      omitCellPadding: true,
      stopPropagation: true,
    },
  ]

  const renderTable = () => {
    if (enabledOperatorActionNames.length === 0) {
      return null
    }

    const operatorActionObjects = getSortedOperatorActions(enabledOperatorActionNames)

    return (
      <Table
        columns={tableColumns}
        data={operatorActionObjects}
        className={cs.table}
        rowPaddingVariant='rowPaddingLow'
        rowKey='instrumentName'
        heightSizing='flexAuto'
      />
    )
  }

  return (
    <div className={cx(className, cs.addRemoveOperatorActionTab)}>
      <div className={cs.header}>
        <OperatorActionSelect
          className={cs.select}
          operatorActions={getSortedOperatorActions(unusedOperatorActionNames)}
          onOperatorActionSelect={handleOperatorActionSelect}
        />
      </div>
      {renderTable()}
      <div />
    </div>
  )
}

AddRemoveOperatorActionTab.propTypes = {
  className: PropTypes.string,
}

export default AddRemoveOperatorActionTab
