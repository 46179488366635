import store from 'store2'
import { DemoEvent, DemoEventLogEntry } from '../events/DemoEvent'

const eventDb = store.namespace('slas-demo')
const EVENTS_KEY = 'events'

export default {
  getEventsSince: (sinceIndex: number): { events: DemoEventLogEntry[] } => {
    const allEvents = (eventDb.get(EVENTS_KEY) ?? []) as DemoEvent[]
    const relevantEvents = allEvents.slice(sinceIndex)
    const eventsWithIndex: DemoEventLogEntry[] = relevantEvents.map((event, idx) => ({
      index: sinceIndex + idx,
      data: event,
    }))
    return { events: eventsWithIndex }
  },
  publish: (events: DemoEvent[]): void => {
    const existingEvents = eventDb.get(EVENTS_KEY)
    const updatedEvents = existingEvents ? [...existingEvents, ...events] : events
    eventDb.set(EVENTS_KEY, updatedEvents)
  },
  clear: (): void => {
    eventDb.remove(EVENTS_KEY)
  },
}
