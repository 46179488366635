import PropTypes from 'prop-types'
import { IconProps } from './types/IconProps'
const TeamIcon = ({ className, onClick }: IconProps) => (
  <svg
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    onClick={onClick}
    className={className}
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M9 12C10.9301 12 12.5 10.43 12.5 8.5C12.5 6.57001 10.9301 5 9 5C7.06995 5 5.5 6.57001 5.5 8.5C5.5 10.43 7.06995 12 9 12ZM2 17.25C2 14.92 6.66003 13.75 9 13.75C11.34 13.75 16 14.92 16 17.25V19H2V17.25ZM9 15.75C7.20996 15.75 5.17993 16.42 4.33997 17H13.6599C12.8199 16.42 10.7899 15.75 9 15.75ZM10.5 8.5C10.5 7.66998 9.82996 7 9 7C8.17004 7 7.5 7.66998 7.5 8.5C7.5 9.33002 8.17004 10 9 10C9.82996 10 10.5 9.33002 10.5 8.5ZM16.04 13.81C17.2001 14.65 18 15.77 18 17.25V19H22V17.25C22 15.23 18.5 14.08 16.04 13.81ZM18.5 8.5C18.5 10.43 16.9301 12 15 12C14.46 12 13.96 11.87 13.5 11.65C14.13 10.76 14.5 9.66998 14.5 8.5C14.5 7.33002 14.13 6.23999 13.5 5.34998C13.96 5.13 14.46 5 15 5C16.9301 5 18.5 6.57001 18.5 8.5Z'
    />
  </svg>
)

TeamIcon.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
}

export default TeamIcon
