/**
 * Presentational component for rendering an operator action that schedules routines
 *
 * It assumes the caller uses a task-executor-based API (currently all operator actions do).
 *
 * This probably isn't a great abstraction, but it's convenient for the moment. We'd probably be
 * better off splitting this along technical components, e.g. a shared component for rendering the
 * layout, a hook for managing the task-executor API, etc.
 */

import cx from 'classnames'
import { ReactNode, useState } from 'react'
import { TaskEndpoint, useTaskExecutor } from '~/api/useTaskExecutor'
import ScheduleRoutineIcon from '~/assets/images/operator_schedule_daily_media_exchange.png'
import AppHeader from '~/components/AppHeader'
import { StructuredDataFormData } from '~/components/StructuredDataForm/StructuredDataForm'
import Toaster from '~/components/Toaster'
import Button from '~/components/buttons/Button'
import TinyNotification from '~/components/notifications/TinyNotification'
import OperatorActionConfigDialog from '../OperatorActionConfigDialog'
import cs from './presentational_schedule_operator_action.scss'

// TODO: Refactor this to somewhere more generalizable
type BaseOperatorActionConfig = StructuredDataFormData & {
  displayName?: string
}

export interface OperatorActionConfigProps<TConfig extends BaseOperatorActionConfig> {
  operatorActionName: string
  config: TConfig
  // This is called to update local state. This component handles updating the config via the API.
  onConfigUpdate: (config: TConfig, overwrite: boolean) => void
}

export interface PresentationalScheduleOperatorActionProps<
  TResponse,
  TConfig extends BaseOperatorActionConfig,
> {
  // When config is given, the component adds a "Configure..." menu option that allows updating the
  // operator action config.
  operatorActionConfig?: OperatorActionConfigProps<TConfig>

  routineTitle: string
  repeats?: number
  children: ReactNode

  submitProps: {
    label?: string
    // A null taskEndpoint disables the button - these are typically coupled (e.g. can't submit a
    // valid request when a certain input hasn't been filled in)
    taskEndpoint: TaskEndpoint | null

    // Allow the parent component to reset any form state
    onSuccess: (response: TResponse) => void

    successToast?: string
    loadingMessage?: string
  }
}

export function PresentationalScheduleOperatorAction<
  TResponse,
  TConfig extends BaseOperatorActionConfig,
>(props: PresentationalScheduleOperatorActionProps<TResponse, TConfig>) {
  const { isExecuting, response, submitTask } = useTaskExecutor<TResponse>()

  function onSubmit() {
    if (props.submitProps.taskEndpoint == null) {
      return // Shouldn't happen, but safety first
    }

    submitTask?.(props.submitProps.taskEndpoint, {
      onSuccess: (result: TResponse) => {
        Toaster.show({
          message:
            props.submitProps.successToast ||
            `Scheduled ${props.routineTitle}${
              props.repeats && props.repeats > 1 ? ' (x' + props.repeats + ')' : ''
            }`,
          intent: 'success',
        })

        // TODO: This is due to the slightly inaccurate types on TaskExecutionResult. See the comment
        // above its definition.
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        props.submitProps.onSuccess(result)
      },
      onError: (error?: string) => {
        if (error && error.includes('Additional consumables required')) {
          Toaster.show({
            message: error,
            intent: 'warning',
            timeout: 0,
          })
        }
      },
    })
  }

  const renderNotification = () => {
    if (isExecuting) {
      return (
        <TinyNotification
          className={cs.notification}
          type='loading'
          message={props.submitProps.loadingMessage || 'Scheduling routines...'}
        />
      )
    }

    if (response?.error) {
      return (
        <div>
          <TinyNotification
            className={cs.notification}
            type='error'
            message={response?.error}
          />
        </div>
      )
    }

    return null
  }

  const notification = renderNotification()

  return (
    <ScheduleOperatorActionContainer
      defaultTitle={`Schedule ${props.routineTitle}`}
      operatorActionConfig={props.operatorActionConfig}
    >
      <div className={cs.body}>{props.children}</div>
      <div className={cs.footer}>
        {notification}
        <Button
          className={cx(cs.button, notification && cs.withNotification)}
          label={props.submitProps.label ?? 'Schedule Routine'}
          type='primary'
          disabled={isExecuting || props.submitProps.taskEndpoint == null}
          onClick={onSubmit}
        />
      </div>
    </ScheduleOperatorActionContainer>
  )
}

interface ScheduleOperatorActionWithErrorProps<
  TConfig extends BaseOperatorActionConfig,
> {
  operatorActionConfig: OperatorActionConfigProps<TConfig>
  title?: string
  message: string
}

export function ScheduleOperatorActionWithError<
  TConfig extends BaseOperatorActionConfig,
>({
  operatorActionConfig,
  title,
  message,
}: ScheduleOperatorActionWithErrorProps<TConfig>): JSX.Element {
  return (
    <ScheduleOperatorActionContainer
      operatorActionConfig={operatorActionConfig}
      defaultTitle={title ?? 'Schedule Routine'}
    >
      <TinyNotification
        type='bareError'
        message={message}
        className={cs.fetchingRoutinesErrorMessage}
      />
    </ScheduleOperatorActionContainer>
  )
}

interface ScheduleOperatorActionContainerProps<
  TConfig extends BaseOperatorActionConfig,
> {
  operatorActionConfig?: OperatorActionConfigProps<TConfig>

  // defaultTitle is used when configureAction.config.displayName is not set
  defaultTitle: string

  children: ReactNode
}

// TODO: This might be pretty close as-is to a generic OperatorActionLayout component
function ScheduleOperatorActionContainer<TConfig extends BaseOperatorActionConfig>({
  defaultTitle,
  children,
  operatorActionConfig,
}: ScheduleOperatorActionContainerProps<TConfig>) {
  const [configDialogOpen, setConfigDialogOpen] = useState(false)

  const displayName = operatorActionConfig?.config['displayName'] ?? ''

  return (
    <div className={cs.presentationalScheduleOperatorAction}>
      <AppHeader
        appName={displayName.length > 0 ? displayName : defaultTitle}
        iconSrc={ScheduleRoutineIcon}
        className={cs.appHeader}
        variant='mini'
        menuOptions={
          operatorActionConfig
            ? [
                {
                  label: 'Configure...',
                  action: () => setConfigDialogOpen(true),
                },
              ]
            : []
        }
      />
      <div className={cs.contents}>{children}</div>
      {operatorActionConfig ? (
        <OperatorActionConfigDialog
          isOpen={configDialogOpen}
          onClose={() => setConfigDialogOpen(false)}
          operatorActionName={operatorActionConfig.operatorActionName}
          onConfigUpdate={operatorActionConfig.onConfigUpdate}
        />
      ) : null}
    </div>
  )
}

// type, item_filters, num_wells, num_plates
