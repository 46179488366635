import cx from 'classnames'

import { find, includes, map, size } from 'lodash/fp'
import memoize from 'memoize-one'
import { Instrument } from '~/common.interface'
import CheckboxOption from '~/components/CheckboxOption'
import StatusMessage from '~/components/messages/StatusMessage'
import TinyNotification from '~/components/notifications/TinyNotification'
import {
  SelectedStorageLocation,
  isSelectedStorageLocationEqual,
} from '~/pages/Workcell/components/StorageViz/StorageVizView'
import {
  SelectedTransferStation,
  isSelectedTransferStationEqual,
} from '~/pages/Workcell/components/TransferStationViz/TransferStationVizView'
import InstrumentSelect from '~/pages/Workcell/components/instrument/InstrumentSelect'
import { isLiconicInstrument } from '~/pages/Workcell/components/instrument/isLiconicInstrument'
import { remove } from '~/utils/array'
import { isStorageInstrument } from '~/utils/instrument'
import { InstrumentDescriptorForItemType } from '../InstrumentDescriptorForItemType.interface'
import { LoadUnloadItemsConfig } from '../LoadUnloadItemsAction'
import { LoadUnloadItemsProcessItemType } from '../LoadUnloadItemsProcessItemType.interface'
import LiconicLoadSelect from './LiconicLoadSelect'
import LiconicRotateShelfToDoorButton from './LiconicRotateShelfToDoorButton'
import LoadUnloadItemsInstrumentViz from './LoadUnloadItemsInstrumentViz'
import UnloadProcessItemSelect from './UnloadProcessItemSelect'
import { getCanSelectMultipleLocations } from './getCanSelectMultipleLocations'
import cs from './load_unload_items_location_selection.scss'

// TODO(mark): Remove liconic specific code from this file.
// Note that manually move only applies to liconic instruments right now.
// It is used when the "Load Via Door" option is selected.

export interface LoadUnloadItemsLocationSelectionProps {
  className?: string
  config: LoadUnloadItemsConfig
  loadOrUnload: 'load' | 'unload'
  // Only applies to liconic instruments.
  // Used when the "Load Via Door" option is selected.manuallyMove: boolean
  manuallyMove: boolean
  setManuallyMove: (manuallyMove: boolean) => void
  useArmAssist: boolean
  setUseArmAssist: (useArmAssist: boolean) => void
  allowedInstruments: InstrumentDescriptorForItemType[]
  selectedInstrument: Instrument | null
  setSelectedInstrument: (instrument: Instrument | null) => void
  selectedStorageLocations: SelectedStorageLocation[]
  setSelectedStorageLocations: (
    selectedStorageLocations: SelectedStorageLocation[],
  ) => void
  selectedTransferStations: SelectedTransferStation[]
  setSelectedTransferStations: (
    selectedStorageLocations: SelectedTransferStation[],
  ) => void
  selectedProcessItemType: LoadUnloadItemsProcessItemType
  reloadKey?: string
  disabled?: boolean
}

const _extractInstrumentsfromAllowedInstruments = memoize(
  (allowedInstruments: InstrumentDescriptorForItemType[]) =>
    map('instrument', allowedInstruments),
)

const LoadUnloadItemsLocationSelection = ({
  className,
  config,
  loadOrUnload,
  manuallyMove,
  setManuallyMove,
  useArmAssist,
  setUseArmAssist,
  allowedInstruments,
  selectedInstrument,
  setSelectedInstrument,
  selectedStorageLocations,
  setSelectedStorageLocations,
  selectedTransferStations,
  setSelectedTransferStations,
  selectedProcessItemType,
  reloadKey,
  disabled,
}: LoadUnloadItemsLocationSelectionProps) => {
  const getSelectedInstrumentDescriptor = () => {
    return allowedInstruments.find(
      allowedInstrument =>
        allowedInstrument.instrument.instrumentName ===
        selectedInstrument?.instrumentName,
    )
  }

  const canSelectMultipleLocations = getCanSelectMultipleLocations(
    loadOrUnload,
    selectedInstrument,
    selectedProcessItemType,
    useArmAssist,
    manuallyMove,
  )

  const toggleStorageLocation = (location: SelectedStorageLocation) => {
    const existingStorageLocation = find(
      _location => isSelectedStorageLocationEqual(_location, location),
      selectedStorageLocations,
    )

    if (existingStorageLocation) {
      setSelectedStorageLocations(
        remove(existingStorageLocation, selectedStorageLocations),
      )
    } else {
      if (canSelectMultipleLocations) {
        setSelectedStorageLocations([...selectedStorageLocations, location])
      } else {
        setSelectedStorageLocations([location])
      }
    }
  }

  const handleStorageLocationSelect = (location: SelectedStorageLocation) => {
    if (
      (loadOrUnload === 'load' && !location.itemUuid) ||
      (loadOrUnload === 'unload' && location.itemUuid)
    ) {
      toggleStorageLocation(location)
    }
  }

  const toggleTransferStation = (location: SelectedTransferStation) => {
    const existingTransferStation = find(
      _location => isSelectedTransferStationEqual(_location, location),
      selectedTransferStations,
    )

    if (existingTransferStation) {
      setSelectedTransferStations(
        remove(existingTransferStation, selectedTransferStations),
      )
    } else {
      if (canSelectMultipleLocations) {
        setSelectedTransferStations([...selectedTransferStations, location])
      } else {
        setSelectedTransferStations([location])
      }
    }
  }

  const handleTransferStationSelect = (location: SelectedTransferStation) => {
    if (
      (loadOrUnload === 'load' && !location.item) ||
      (loadOrUnload === 'unload' && location.item)
    ) {
      toggleTransferStation(location)
    }
  }

  // This behavior, that setting arm assist to True sets manually move to False, is currently unintuitive.
  // We should consolidate arm assist and manually move into a single Select,
  // e.g. LiconicLoadSelect, so that it's clear that they are mutually exclusive.
  const handleSetArmAssist = (useArmAssist: boolean) => {
    setUseArmAssist(useArmAssist)
    if (useArmAssist) {
      setManuallyMove(false)
    }
  }

  // Don't allow selecting arm assist if multiple locations selected.
  const allowArmAssist =
    selectedTransferStations.length <= 1 && selectedStorageLocations.length <= 1

  const selectedInstrumentDescriptor = getSelectedInstrumentDescriptor()

  const disableStorageInstrumentForArmAssistLoad =
    useArmAssist &&
    loadOrUnload === 'load' &&
    selectedInstrumentDescriptor &&
    isStorageInstrument(selectedInstrumentDescriptor.instrument.instrumentType)

  const disableStorageInstrumentForLiconicLoad =
    !useArmAssist &&
    !manuallyMove &&
    loadOrUnload === 'load' &&
    selectedInstrumentDescriptor &&
    isLiconicInstrument(selectedInstrumentDescriptor.instrument.instrumentType)

  const showRotateShelfToDoorButton =
    manuallyMove &&
    config.enableRotateShelfToDoor &&
    selectedInstrumentDescriptor &&
    isLiconicInstrument(selectedInstrumentDescriptor.instrument.instrumentType)

  const showLoadOptionForLiconic =
    selectedInstrumentDescriptor &&
    isLiconicInstrument(selectedInstrumentDescriptor.instrument.instrumentType)

  const showUnloadProcessItemSelect =
    selectedInstrumentDescriptor &&
    includes(selectedProcessItemType, [
      'culture_plate',
      'empty_culture_plate',
      'empty_assay_plate',
    ]) &&
    loadOrUnload === 'unload'

  const showMultipleUnloadWarningForLiconic =
    loadOrUnload === 'unload' &&
    !manuallyMove &&
    selectedInstrumentDescriptor &&
    isLiconicInstrument(selectedInstrumentDescriptor.instrument.instrumentType) &&
    size(selectedStorageLocations) > 1

  if (allowedInstruments.length === 0) {
    return (
      <StatusMessage message='No instruments are configured for this process item type.' />
    )
  }

  const lastShelfSelected =
    selectedStorageLocations.length > 0
      ? selectedStorageLocations[selectedStorageLocations.length - 1].shelfIndex
      : 1

  return (
    <div
      className={cx(
        className,
        cs.loadUnloadItemsLocationSelection,
        disabled && cs.disabled,
      )}
    >
      <div className={cs.header}>
        <div className={cs.label}>
          {loadOrUnload === 'load'
            ? 'Select destination location'
            : 'Select items to unload'}
        </div>
        <div className={cs.fill} />
        {config.allowArmAssist && (
          <CheckboxOption
            className={cs.allowArmAssist}
            label='Use arm assist'
            checked={useArmAssist}
            onClick={handleSetArmAssist}
            variant='tinyText'
            disabled={disabled || !allowArmAssist}
          />
        )}
      </div>
      <div className={cs.locationSelection}>
        <InstrumentSelect
          instruments={_extractInstrumentsfromAllowedInstruments(allowedInstruments)}
          selectedInstrument={selectedInstrument}
          onInstrumentSelect={setSelectedInstrument}
          noBorder
          triggerClassName={cs.instrumentSelectTrigger}
          popoverClassName={cs.instrumentSelectPopover}
          triggerIconClassName={cs.instrumentSelectTriggerIcon}
          disabled={disabled}
        />
        {showLoadOptionForLiconic && (
          <div className={cs.loadSelectContainer}>
            <LiconicLoadSelect
              loadOrUnload={loadOrUnload}
              manuallyMove={manuallyMove}
              onManuallyMoveSelect={setManuallyMove}
              triggerClassName={cs.loadSelect}
            />
          </div>
        )}
        {disableStorageInstrumentForArmAssistLoad && (
          <TinyNotification
            type='bareSuccess'
            message='With arm assist, a storage location is automatically selected.'
            className={cs.armAssistStorageMessage}
          />
        )}
        {showMultipleUnloadWarningForLiconic && (
          <TinyNotification
            type='bareWarning'
            message='Please immediately remove unloaded plates from the Liconic to prevent plate crashes. Multiple plates will be unloaded by the Liconic without pause.'
            className={cs.armAssistStorageMessage}
          />
        )}
        {disableStorageInstrumentForLiconicLoad && (
          <TinyNotification
            type='bareSuccess'
            message='With the Liconic, a storage location is automatically selected.'
            className={cs.armAssistStorageMessage}
          />
        )}
        <div className={cs.instrumentVizContainer}>
          {showUnloadProcessItemSelect && (
            <UnloadProcessItemSelect
              className={cs.processItemSelect}
              selectedProcessItemType={selectedProcessItemType}
              selectedInstrument={selectedInstrumentDescriptor.instrument}
              selectedStorageLocations={selectedStorageLocations}
              onStorageLocationSelect={handleStorageLocationSelect}
              selectedTransferStations={selectedTransferStations}
              onTransferStationSelect={handleTransferStationSelect}
            />
          )}
          <LoadUnloadItemsInstrumentViz
            instrumentDescriptorForItemtype={selectedInstrumentDescriptor}
            selectedStorageLocations={selectedStorageLocations}
            onStorageLocationSelect={handleStorageLocationSelect}
            selectedTransferStations={selectedTransferStations}
            onTransferStationSelect={handleTransferStationSelect}
            selectedProcessItemType={selectedProcessItemType}
            loadOrUnload={loadOrUnload}
            reloadKey={reloadKey}
            disabled={
              disabled ||
              disableStorageInstrumentForArmAssistLoad ||
              disableStorageInstrumentForLiconicLoad
            }
            className={cs.instrumentViz}
          />
        </div>
        {showRotateShelfToDoorButton && (
          <LiconicRotateShelfToDoorButton
            className={cs.rotateShelfButton}
            shelf={lastShelfSelected}
            instrumentName={selectedInstrumentDescriptor.instrument.instrumentName}
          />
        )}
      </div>
    </div>
  )
}

export default LoadUnloadItemsLocationSelection
