import PropTypes from 'prop-types'

import { renderDatetime, renderTime } from '~/utils/date'

import { ISODateString } from '~/types/ISODateString.interface'
import { RoutineOrProcessStepStatus } from '../../types/RoutineOrProcessStepStatus.interface'
import cs from './process_step_time.scss'

interface RoutineOrProcessStepEndTimeProps {
  routineOrProcessStep: {
    status: RoutineOrProcessStepStatus
    startTime?: ISODateString
    endTime?: ISODateString
    estimatedStartTime?: ISODateString
    estimatedEndTime?: ISODateString
  }
  displayType?: 'datetime' | 'time'
}

const RoutineOrProcessStepEndTime = ({
  routineOrProcessStep,
  displayType = 'datetime',
}: RoutineOrProcessStepEndTimeProps) => {
  if (!routineOrProcessStep) return null

  const renderFn = displayType === 'datetime' ? renderDatetime : renderTime

  if (
    routineOrProcessStep.status === 'initialized' ||
    routineOrProcessStep.status === 'canceled'
  ) {
    return <span className={cs.noTime}>--</span>
  }
  if (
    routineOrProcessStep.status === 'scheduled' ||
    routineOrProcessStep.status === 'failed' ||
    routineOrProcessStep.status === 'in_progress'
  ) {
    return (
      <span className={cs.estimatedTime}>
        {renderFn(routineOrProcessStep.estimatedEndTime)} (est.)
      </span>
    )
  }
  if (routineOrProcessStep.status === 'completed') {
    return <span className={cs.time}>{renderFn(routineOrProcessStep.endTime)}</span>
  }
  return null
}

RoutineOrProcessStepEndTime.propTypes = {
  processStep: PropTypes.any,
  displayType: PropTypes.oneOf(['time', 'datetime']),
}

export default RoutineOrProcessStepEndTime
