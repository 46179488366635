import Button from '~/components/buttons/Button'

import cs from './diagnostics.scss'

export default function Diagnostics() {
  return (
    <div className={cs.container}>
      <h1 className={cs.title}>Diagnostic tools</h1>

      <Button
        label='Trigger Exception'
        onClick={() => {
          throw new Error('Diagnostics page: Trigger Exception button was clicked')
        }}
      />
    </div>
  )
}
