import { useContext, useState } from 'react'

import { get, noop } from 'lodash/fp'
import WorkcellStatusContext from '~/pages/Workcell/WorkcellStatusContext'
import { SelectedStorageLocation } from '~/pages/Workcell/components/StorageViz/StorageVizView'
import { SelectedTransferStation } from '~/pages/Workcell/components/TransferStationViz/TransferStationVizView'
import { getInstrumentRealTimeState } from '~/pages/Workcell/utils/workcellStatus'
import { InstrumentDescriptorForItemType } from '../InstrumentDescriptorForItemType.interface'
import { LoadUnloadItemsProcessItemType } from '../LoadUnloadItemsProcessItemType.interface'
import InhecoScilaOpenDrawerDialog from './InhecoScilaOpenDrawerDialog'
import LoadUnloadItemsInstrumentVizView from './LoadUnloadItemsInstrumentVizView'

export interface LoadUnloadItemsInhecoScilaVizProps {
  className?: string
  instrumentDescriptorForItemtype: InstrumentDescriptorForItemType
  selectedStorageLocations: SelectedStorageLocation[]
  onStorageLocationSelect: (location: SelectedStorageLocation) => void
  selectedTransferStations: SelectedTransferStation[]
  selectedProcessItemType: LoadUnloadItemsProcessItemType
  loadOrUnload: 'load' | 'unload'
  reloadKey?: string
  disabled?: boolean
}

const LoadUnloadItemsInhecoScilaViz = ({
  instrumentDescriptorForItemtype,
  selectedStorageLocations,
  onStorageLocationSelect,
  selectedTransferStations,
  selectedProcessItemType,
  loadOrUnload,
  reloadKey,
  disabled,
  className,
}: LoadUnloadItemsInhecoScilaVizProps) => {
  const workcellStatus = useContext(WorkcellStatusContext)
  const [dialogOpen, setDialogOpen] = useState(false)
  const [selectedDrawer, setSelectedDrawer] = useState<null | number>(null)
  const realTimeState = getInstrumentRealTimeState(
    workcellStatus,
    instrumentDescriptorForItemtype.instrument.instrumentName,
  )

  const isSelectedDrawerOpened = _selectedDrawer => {
    if (_selectedDrawer) {
      return get(['door_state', _selectedDrawer - 1], realTimeState) === 'Opened'
    }
    return false
  }

  const handleSlotClick = (
    itemUuid: string | null,
    shelfIndex: number,
    levelIndex: number,
  ) => {
    if (loadOrUnload === 'load' && !isSelectedDrawerOpened(levelIndex)) {
      setSelectedDrawer(levelIndex)
      setDialogOpen(true)
    } else {
      onStorageLocationSelect({ itemUuid, shelfIndex, levelIndex })
    }
  }

  const confirmDoorOpen = () => {
    if (selectedDrawer) {
      onStorageLocationSelect({
        itemUuid: null,
        shelfIndex: 1,
        levelIndex: selectedDrawer,
      })
    }
    setDialogOpen(false)
    setSelectedDrawer(null)
  }

  return (
    <>
      <InhecoScilaOpenDrawerDialog
        isOpen={dialogOpen}
        selectedDrawer={selectedDrawer}
        onCancel={() => setDialogOpen(false)}
        onConfirm={confirmDoorOpen}
        instrumentName={instrumentDescriptorForItemtype.instrument.instrumentName}
      />
      <LoadUnloadItemsInstrumentVizView
        className={className}
        instrumentDescriptorForItemtype={instrumentDescriptorForItemtype}
        selectedStorageLocations={selectedStorageLocations}
        onSlotClick={handleSlotClick}
        selectedTransferStations={selectedTransferStations}
        onTransferStationClick={noop}
        loadOrUnload={loadOrUnload}
        reloadKey={reloadKey}
        disabled={disabled}
        selectedProcessItemType={selectedProcessItemType}
      />
    </>
  )
}

export default LoadUnloadItemsInhecoScilaViz
