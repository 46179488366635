import cx from 'classnames'
import React, { useEffect, useState } from 'react'

import cs from './input_with_validation.scss'

interface InputWithValidationProps<T> {
  label?: string
  placeholder?: string
  disabled?: boolean
  value?: T
  onChange: (newDate: T | null) => void
  className?: string
  inputClassName?: string
  validateInput: (input: string) => boolean
  parseInput: (input: string) => T | null
  errorMessage: string
}

const InputWithValidation = <T,>(
  props: InputWithValidationProps<T>,
  ref: React.Ref<HTMLInputElement>,
) => {
  const {
    value,
    onChange,
    className,
    label,
    inputClassName,
    placeholder,
    disabled,
    validateInput,
    parseInput,
    errorMessage,
  } = props

  // This is exactly the string displayed in the input
  // which can diverge from 'value' prop as the user types.
  const [inputValue, setInputValue] = useState<string | null>(null)
  const [showError, setShowError] = useState(false)

  // Whenever the incoming value changes, update the input value
  useEffect(() => {
    setInputValue(value ? String(value) : null)
  }, [value])

  const onInputChange = event => {
    setInputValue(event.target.value)
  }

  // Only change on blur, otherwise parsed number may not be valid while being
  // edited
  const onInputBlur = () => {
    if (inputValue === null) {
      onChange(null)
    } else if (validateInput(inputValue)) {
      setShowError(false)
      onChange(parseInput(inputValue))
    } else {
      setShowError(true)
      setInputValue(null)
      onChange(null)
    }
  }

  return (
    <div className={className}>
      {label && <div className={cs.label}>{label}</div>}
      <input
        ref={ref}
        className={cx(inputClassName, cs.input, disabled && cs.disabled)}
        placeholder={placeholder}
        value={inputValue || ''}
        onChange={onInputChange}
        onBlur={onInputBlur}
        type='text'
        disabled={disabled}
      />
      {showError ? <div className={cs.errorMessage}>{errorMessage}</div> : null}
    </div>
  )
}

const ForwardedInputWithValidation = React.forwardRef(InputWithValidation) as <T>(
  props: InputWithValidationProps<T> & React.RefAttributes<HTMLInputElement>,
) => React.ReactElement

export default ForwardedInputWithValidation
