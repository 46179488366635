import { DesktopAPI } from '../core'

const desktopAPI = new DesktopAPI('/api/datasets')

export default {
  getDatasets: params => desktopAPI.get('', params),
  getDataset: (id, params) => desktopAPI.get(`${id}/`, params),
  getDatasetRawData: id => desktopAPI.get(`${id}/raw-data/`),
  getDatasetsAsCsv: queryParams => desktopAPI.post('get-as-csv-data/', queryParams),
  retryStructureRawData: id => desktopAPI.post(`${id}/retry-structure-raw-data/`),
}
