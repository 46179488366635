import PropTypes from 'prop-types'

import operatorActionAPI from '~/api/operatorActions/shared'
import Dialog from '~/components/Dialog'
import ConfigDialogTab from '~/components/Dialog/ConfigDialogTab'
import { snakeCaseToTitleCase } from '~/utils/string'

import cs from './operator_action_config_dialog.scss'

const OperatorActionConfigDialog = ({
  isOpen,
  onClose,
  operatorActionName,
  onConfigUpdate,
}) => {
  const api = {
    getSchema: () => operatorActionAPI.getConfigSchema(operatorActionName),
    getConfig: () => operatorActionAPI.getConfig(operatorActionName),
    setConfig: (newConfig, overwrite) => {
      if (onConfigUpdate) {
        onConfigUpdate(newConfig, overwrite)
      }
      return operatorActionAPI.setConfig(operatorActionName, newConfig, overwrite)
    },
  }

  return (
    <Dialog isOpen={isOpen} onClose={onClose} className={cs.operatorActionConfigDialog}>
      <div className={cs.title}>
        Configure {snakeCaseToTitleCase(operatorActionName)}
      </div>
      <ConfigDialogTab api={api} onClose={onClose} className={cs.configTab} />
    </Dialog>
  )
}

OperatorActionConfigDialog.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  config: PropTypes.shape({
    showExperimentSelect: PropTypes.bool,
  }),
  configSchema: PropTypes.shape({
    properties: PropTypes.objectOf(
      PropTypes.shape({
        type: PropTypes.string,
        description: PropTypes.string,
      }),
    ),
  }),
  operatorActionName: PropTypes.string,
  onConfigUpdate: PropTypes.func,
}

export default OperatorActionConfigDialog
