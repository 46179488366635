/** Utility functions for spreadsheet data errors.
 *
 * Can be used as part of generateErrorGroups helper functions.
 * See getLinkLotsParseDataErrorGroups.
 */

import pluralize from 'pluralize'
import { components } from '~/api/desktop/generated-schema'
import { displayCount } from '~/utils/string'

export type ParseSpreadsheetDataError =
  | components['schemas']['NoDataProvidedError']
  | components['schemas']['ParseCultureDataInvalidColumns']
  | components['schemas']['ParseCultureDataIncorrectNumValues']
  | components['schemas']['ParseCultureDataInvalidValue']
  | components['schemas']['ParseCultureDataInvalidSchema']

export const getGroupHeaderForSpreadsheetErrorType = (
  errorType: ParseSpreadsheetDataError['type'],
  numErrors: number,
): string => {
  if (errorType === 'invalid_columns') {
    return 'Required data columns are missing.'
  } else if (errorType === 'incorrect_num_values') {
    return `${displayCount('row', numErrors)} ${pluralize('has', numErrors)} the wrong number of values.`
  } else if (errorType === 'invalid_value') {
    return `${displayCount('value', numErrors)} ${pluralize('is', numErrors)} invalid.`
  } else if (errorType == 'invalid_schema') {
    return 'Required data columns are missing and extra columns are detected.'
  } else if (errorType == 'no_data') {
    return 'Data is missing.'
  }

  return `Errors: ${errorType} (${numErrors})`
}
export const getSpreadsheetErrorDisplayElement = (
  error: ParseSpreadsheetDataError,
): React.ReactNode => {
  if (error.type === 'invalid_columns') {
    return (
      <span>
        Columns <b>{error.missing_columns.join(', ')}</b> are required but missing.
      </span>
    )
  } else if (error.type === 'incorrect_num_values') {
    return (
      <span>
        <b>{error.line_contents}</b> contains {error.actual_number} values. Expected{' '}
        {error.expected_number}. <i>(Row {error.line_number})</i>
      </span>
    )
  } else if (error.type === 'invalid_value') {
    return (
      <span>
        <b>{error.value}</b> is not a valid {error.column_name}. Expected{' '}
        {error.expected_type}. <i>(Row {error.line_number})</i>
      </span>
    )
  } else if (error.type == 'invalid_schema') {
    return (
      <span>
        Compared to the closest match, extra columns{' '}
        <b>{error.extra_columns.join(', ')}</b> are present and columns{' '}
        <b>{error.missing_columns.join(', ')}</b> are missing.
      </span>
    )
  } else if (error.type == 'no_data') {
    return <span>Headers are valid but no data is provided.</span>
  }
}
