import cx from 'classnames'
import {} from 'lodash/fp'
import { RecordManualPassageParseDataResponse } from '~/api/operatorActions/recordManualPassage'
import { displayCount } from '~/utils/string'
import GroupedErrorTable from '../../GroupedErrorTable'
import RecordManualPassageWorklistPreview from './RecordManualPassageWorklistPreview'
import { getRecordManualPassageParseDataErrorGroups } from './getRecordManualPassageParseDataErrorGroups'
import {
  getNumDistinctCultures,
  getNumDistinctPlates,
} from './parseDataResponseExtractors'
import cs from './upload_data_step_preview.scss'

export interface UploadDataStepPreviewProps {
  className?: string
  loading: boolean
  parseDataResponse: RecordManualPassageParseDataResponse | null
}

const UploadDataStepPreview = ({
  className,
  loading,
  parseDataResponse,
}: UploadDataStepPreviewProps) => {
  if (!loading && parseDataResponse === null) {
    return null
  }

  const renderContents = () => {
    if (loading) {
      return <div className={cs.info}>Loading preview...</div>
    }

    if (parseDataResponse === null) {
      return null
    }

    if (parseDataResponse.type === 'errors') {
      return (
        <>
          <div className={cs.errorCount}>
            {displayCount('error', parseDataResponse.errors.length)} with pasted data
          </div>
          <GroupedErrorTable
            className={cs.parseDataErrorTable}
            errorGroups={getRecordManualPassageParseDataErrorGroups(parseDataResponse)}
          />
        </>
      )
    }
    if (parseDataResponse.type === 'worklists') {
      return (
        <>
          <div className={cs.info}>
            Record passage for{' '}
            {displayCount('culture', getNumDistinctCultures(parseDataResponse))} (
            {displayCount('plate', getNumDistinctPlates(parseDataResponse))})
          </div>
          <RecordManualPassageWorklistPreview
            worklists={parseDataResponse.data}
            plates={parseDataResponse.culture_plates}
            className={cs.table}
          />
        </>
      )
    }

    return null
  }

  return (
    <div className={cx(className, cs.uploadDataStepPreview)}>
      <div className={cs.sectionTitle}>Preview</div>
      <div className={cs.contents}>{renderContents()}</div>
    </div>
  )
}

export default UploadDataStepPreview
