import ButtonGroup from '~/components/buttons/ButtonGroup'
import WorkflowIcon from '~/components/icons/WorkflowsIcon'
import cs from './load_unload_items_terminate_workflows_form.scss'

const SCHEDULE_TYPE_OPTIONS = [
  {
    value: 'false',
    label: 'Keep Workflows',
  },
  {
    value: 'true',
    label: 'Terminate Workflows',
  },
]

const LoadUnloadItemsTerminateWorkflowsForm = ({
  className,
  removeFromCurrentWorkflow,
  setRemoveFromCurrentWorkflow,
  disabled,
}: {
  className?: string
  removeFromCurrentWorkflow: boolean
  setRemoveFromCurrentWorkflow: (removeFromCurrentWorkflow: boolean) => void
  disabled?: boolean
}) => {
  return (
    <div className={className}>
      <div className={cs.sectionTitle}>
        <WorkflowIcon className={cs.workflowsIcon} />
        Terminate Workflows?
      </div>
      <div className={cs.info}>
        You should terminate workflows when checking plates out permanently.
      </div>
      <ButtonGroup
        choices={SCHEDULE_TYPE_OPTIONS}
        onChange={value => setRemoveFromCurrentWorkflow(value === 'true')}
        activeChoice={String(removeFromCurrentWorkflow)}
        className={cs.scheduleTypeButtonGroup}
        choiceClassName={cs.choice}
        disabled={disabled}
      />
    </div>
  )
}

export default LoadUnloadItemsTerminateWorkflowsForm
