import cx from 'classnames'
import { get } from 'lodash/fp'
import { useState } from 'react'

import PopupMenu, { PopupMenuOption } from '~/components/PopupMenu'
import InstrumentStatusCallout from '~/pages/Workcell/WorkcellOverview/LiveStatus/InstrumentStatusCallout'
import StorageViz from '~/pages/Workcell/components/StorageViz'
import TransferStationViz from '~/pages/Workcell/components/TransferStationViz'
import PropTypes from '~/propTypes'

import { Instrument, InstrumentStatus } from '~/common.interface'
import InstrumentHeader from '../../components/instrument/InstrumentHeader'
import { isLiconicInstrument } from '../../components/instrument/isLiconicInstrument'
import cs from './instrument_status_tile.scss'

interface InstrumentStatusTileProps {
  className?: string
  instrument: Instrument
  instrumentStatus: InstrumentStatus
  reloadKey: string
  popupMenuOptions: PopupMenuOption[]
}

const InstrumentStatusTile = ({
  className,
  instrument,
  instrumentStatus,
  reloadKey,
  popupMenuOptions,
}: InstrumentStatusTileProps) => {
  const [menuOpen, setMenuOpen] = useState(false)

  const renderBody = () => {
    const instrumentType = get('instrumentType', instrument)
    if (instrumentType === 'static_storage' || isLiconicInstrument(instrumentType)) {
      return (
        <StorageViz
          instrument={instrument}
          reloadKey={reloadKey}
          hideLevelLabels
          hideShelfLabels
          displaySizeDimensionThresholds={{
            micro: { numLevels: 20, numShelves: 8 },
            mini: { numLevels: 15, numShelves: 6 },
            normal: { numLevels: 12, numShelves: 5 },
          }}
        />
      )
    }

    if (instrumentType === 'inheco_scila') {
      return (
        <>
          <div className={cs.label}>Transfer Stations</div>
          <TransferStationViz
            instrument={instrument}
            reloadKey={reloadKey}
            hideName
            displaySize='normal'
            orderNames='desc'
          />
          <div className={cx(cs.label, cs.storageLabel)}>Storage</div>
          <StorageViz
            instrument={instrument}
            reloadKey={reloadKey}
            displaySize='normal'
            hideShelfLabels
          />
        </>
      )
    }

    return <TransferStationViz instrument={instrument} reloadKey={reloadKey} />
  }

  const renderFooter = () => {
    return <InstrumentStatusCallout instrument={instrument} reloadKey={reloadKey} />
  }

  const executionState = get('execution_state', instrumentStatus)
  const isBusy = executionState === 'BUSY'

  return (
    <div
      className={cx(className, cs.instrumentStatusTile, isBusy && cs.isBusy)}
      onClick={() => setMenuOpen(true)}
    >
      <div className={cs.header}>
        <InstrumentHeader instrument={instrument} instrumentStatus={instrumentStatus} />
        <div className={cs.fill} />
        <PopupMenu
          controlled
          onClose={() => setMenuOpen(false)}
          open={menuOpen}
          options={popupMenuOptions}
          className={cs.menu}
          placement='bottom-end'
        />
      </div>
      <div className={cs.body}>{renderBody()}</div>
      <div className={cs.footer}>{renderFooter()}</div>
    </div>
  )
}

InstrumentStatusTile.propTypes = {
  className: PropTypes.string,
  instrument: PropTypes.Instrument,
  instrumentStatus: PropTypes.InstrumentStatus,
  reloadKey: PropTypes.string,
  operatorActions: PropTypes.arrayOf(PropTypes.string),
}

export default InstrumentStatusTile
