import cx from 'classnames'
import { sortBy } from 'lodash/fp'
import { useEffect, useState } from 'react'
import roboticArmAPI, {
  GetAllLocationsResponse,
  SuccessResponse,
} from '~/api/desktop/drivers/roboticArm'
import { components } from '~/api/desktop/generated-schema'
import HelpPopover from '~/components/HelpPopover'
import Select from '~/components/Select'
import Button from '~/components/buttons/Button'
import { splitAndRound } from '~/utils/string'
import cs from './safe_location_controls.scss'

interface SafeLocationControlsProps {
  instrumentName: string
  isTeachToolBusy: boolean
  teachToolBusyWrapper: (func: () => Promise<SuccessResponse | void>) => void
  currentJointLoc: string | undefined
  onLocationUpdated: () => void
  reloadKey?: string
  className?: string
}

type Location = components['schemas']['Location'] & {
  name: string
}

const SafeLocationControls = ({
  instrumentName,
  isTeachToolBusy,
  teachToolBusyWrapper,
  currentJointLoc,
  onLocationUpdated,
  reloadKey,
  className,
}: SafeLocationControlsProps) => {
  const [locations, setLocations] = useState<Location[]>([])
  const [selectedLocation, setSelectedLocation] = useState<Location>()

  const parseLocationsResponse = (res: GetAllLocationsResponse): Location[] => {
    const locations = Object.keys(res.locations).map(key => {
      const locationValue = res.locations[key]
      if (locationValue) {
        return {
          name: key,
          loc: locationValue.loc,
          loc_type: locationValue.loc_type,
          seq_file_path: locationValue.seq_file_path,
        }
      } else throw new Error('Safe location not found')
    })

    return sortBy('name', locations)
  }

  useEffect(() => {
    roboticArmAPI.calocs(instrumentName).then(res => {
      setLocations(parseLocationsResponse(res))
    })
  }, [reloadKey])

  const itemMatchesQuery = (location, queryLowerCase) =>
    location.name.toLowerCase().includes(queryLowerCase)

  const safeLocationSelect = (
    <>
      <div className={cs.controlLabel}>Safe Locations</div>
      <Select<Location>
        placeholder={'Select Safe Location'}
        items={locations}
        itemKey={'name'}
        itemLabelKey={'name'}
        activeItem={selectedLocation || null}
        onChange={setSelectedLocation}
        className={cs.select}
        triggerClassName={cs.trigger}
        popoverClassName={cs.popover}
        filterable
        itemMatchesQuery={itemMatchesQuery}
      />
    </>
  )
  const [highlightText, setHighlightText] = useState<boolean>(false)

  const locationActionButtons = (location: Location) => {
    return (
      <div className={cs.safeLocationButtons}>
        <Button
          label='Move to Location'
          onClick={async () => {
            await teachToolBusyWrapper(() =>
              roboticArmAPI
                .mloc(instrumentName, location.name)
                .catch(e => alert(`Failed to move to location: ${e.message}`)),
            )
            onLocationUpdated()
          }}
          disabled={isTeachToolBusy}
          className={cs.button}
        />
        <Button
          label='Save Current Location'
          onClick={async () => {
            await teachToolBusyWrapper(() =>
              roboticArmAPI
                .wlocj(instrumentName, location.name)
                .then(() => {
                  // async re-fetch locations
                  roboticArmAPI.calocs(instrumentName).then(res => {
                    setLocations(parseLocationsResponse(res))
                  })
                  setSelectedLocation({
                    name: selectedLocation?.name || '',
                    loc: currentJointLoc || '',
                    loc_type: 'j',
                  })
                  setTimeout(() => {
                    setHighlightText(true)
                    setTimeout(() => {
                      setHighlightText(false)
                    }, 500)
                  })
                })
                .catch(e => alert(`Failed to save safe location: ${e.message}`)),
            )
          }}
          disabled={isTeachToolBusy}
          type='primary'
        />
      </div>
    )
  }

  return (
    <div className={cx(cs.safeLocationControls, className)}>
      {safeLocationSelect}
      {selectedLocation ? (
        <div className={cs.selectedLocation}>
          <HelpPopover
            helpContent={
              <div className={cs.seqFilePathPopover}>
                {selectedLocation.seq_file_path || 'Unknown file path'}
              </div>
            }
            text='View File for Safe Location'
            interactionKind='hover'
            placement='top'
            className={cs.seqFilePath}
          />
          {locationActionButtons(selectedLocation)}
          <div className={cs.safeLocationLabel}>Safe Location (Joint)</div>
          <div
            className={
              highlightText ? cs.safeLocationTextWithHighlight : cs.safeLocationText
            }
          >
            {splitAndRound(selectedLocation.loc || '', ' ')}
          </div>
        </div>
      ) : (
        <div className={cs.selectedLocation}>
          <div className={cs.noSafeLocation}>No Safe Location Selected</div>
        </div>
      )}
    </div>
  )
}

export default SafeLocationControls
