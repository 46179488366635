import { find } from 'lodash/fp'
import { Instrument } from '~/common.interface'
import { InstrumentDescriptorForItemType } from '../InstrumentDescriptorForItemType.interface'

import {
  LoadUnloadItemsProcessItemType,
  getPreferredInstrumentNameForProcessItemType,
} from '../LoadUnloadItemsProcessItemType.interface'

const _getInstrumentWithStringInName = (
  allowedInstruments: InstrumentDescriptorForItemType[],
  string: string,
): Instrument | null => {
  const match = find(
    instrument => instrument.instrument.instrumentName.toLowerCase().includes(string),
    allowedInstruments,
  )
  return match ? match.instrument : null
}

const _getInstrumentWithInstrumentType = (
  allowedInstruments: InstrumentDescriptorForItemType[],
  instrumentType: string,
): Instrument | null => {
  const match = find(
    instrument => instrument.instrument.instrumentType === instrumentType,
    allowedInstruments,
  )
  return match ? match.instrument : null
}

const _getInstrumentWithName = (
  allowedInstruments: InstrumentDescriptorForItemType[],
  name: string,
): Instrument | null => {
  const match = find(
    instrument => instrument.instrument.instrumentName === name,
    allowedInstruments,
  )
  return match ? match.instrument : null
}

export const getPreferredInstrumentForProcessItemType = (
  allowedInstruments: InstrumentDescriptorForItemType[],
  selectedProcessItemType: LoadUnloadItemsProcessItemType,
  itemTypePreferredInstrumentNames: Record<string, string>,
): Instrument | null => {
  const configuredPreferredInstrumentName =
    getPreferredInstrumentNameForProcessItemType(
      selectedProcessItemType,
      itemTypePreferredInstrumentNames,
    )

  if (configuredPreferredInstrumentName) {
    const instrument = _getInstrumentWithName(
      allowedInstruments,
      configuredPreferredInstrumentName,
    )

    if (instrument) {
      return instrument
    }
  }

  if (selectedProcessItemType === 'reagent_plate') {
    return (
      _getInstrumentWithStringInName(allowedInstruments, 'fridge') ||
      _getInstrumentWithStringInName(allowedInstruments, 'liconic_str') ||
      _getInstrumentWithStringInName(allowedInstruments, 'hotel') ||
      _getInstrumentWithStringInName(allowedInstruments, 'liconic_lpx')
    )
  }
  if (selectedProcessItemType === 'tiprack') {
    return (
      _getInstrumentWithStringInName(allowedInstruments, 'hotel') ||
      _getInstrumentWithStringInName(allowedInstruments, 'liconic_lpx') ||
      _getInstrumentWithInstrumentType(allowedInstruments, 'ot_2')
    )
  }
  if (selectedProcessItemType === 'culture_plate') {
    return (
      _getInstrumentWithInstrumentType(allowedInstruments, 'inheco_scila') ||
      _getInstrumentWithInstrumentType(allowedInstruments, 'liconic_stx') ||
      // Note that STRs can also be incubators.
      _getInstrumentWithInstrumentType(allowedInstruments, 'liconic_str') ||
      _getInstrumentWithStringInName(allowedInstruments, 'hotel')
    )
  }
  if (selectedProcessItemType === 'experiment_plate') {
    return _getInstrumentWithStringInName(allowedInstruments, 'hotel')
  }
  if (selectedProcessItemType === 'empty_culture_plate') {
    return (
      _getInstrumentWithStringInName(allowedInstruments, 'hotel') ||
      _getInstrumentWithStringInName(allowedInstruments, 'liconic_lpx') ||
      _getInstrumentWithInstrumentType(allowedInstruments, 'liconic_stx') ||
      // Note that STRs can also be incubators.
      _getInstrumentWithInstrumentType(allowedInstruments, 'liconic_str')
    )
  }
  if (selectedProcessItemType === 'empty_assay_plate') {
    // This should probably be hotel eventually, but our primary customer that uses assay plates
    // can't transport them yet.
    return _getInstrumentWithStringInName(allowedInstruments, 'ot_2')
  }
  return null
}
