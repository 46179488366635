import { includes, sortBy } from 'lodash/fp'
import { useMemo } from 'react'
import { useRoutineDefinitionsWithDSLs } from '../../Routines/useRoutineDefinitionsWithDSLs'
import LoadingActionMessage from '../LoadingActionMessage'
import {
  OperatorActionConfigProps,
  ScheduleOperatorActionWithError,
} from './PresentationalScheduleOperatorAction'

import { useEnabledAndUnusedOperatorActionNames } from '../useEnabledAndUnusedOperatorActionNames'
import useOperatorActionConfig from '../utils/useOperatorActionConfig'
import { GenericScheduleConfig } from './GenericScheduleConfig.interface'
import GenericScheduleForm from './GenericScheduleForm'

const OPERATOR_ACTION_NAME = 'generic_schedule'

export function GenericScheduleAction(): JSX.Element | null {
  const { enabledOperatorActionNames } = useEnabledAndUnusedOperatorActionNames()
  const { config, handleConfigUpdate } =
    useOperatorActionConfig<GenericScheduleConfig>(OPERATOR_ACTION_NAME)

  if (!includes(OPERATOR_ACTION_NAME, enabledOperatorActionNames)) {
    return null
  }

  if (!config) {
    return <LoadingActionMessage />
  }

  const schedulableRoutines =
    (config.schedulableRoutines ?? []).length > 0 ? config.schedulableRoutines : null

  const operatorActionConfig = {
    operatorActionName: OPERATOR_ACTION_NAME,
    config,
    onConfigUpdate: handleConfigUpdate,
  }

  if (schedulableRoutines == null) {
    return (
      <ScheduleOperatorActionWithError
        message='No routines configured'
        operatorActionConfig={operatorActionConfig}
      />
    )
  }

  return (
    <ConfiguredGenericScheduleAction
      operatorActionConfig={operatorActionConfig}
      schedulableRoutines={schedulableRoutines}
    />
  )
}

interface ConfiguredProps {
  operatorActionConfig: OperatorActionConfigProps<GenericScheduleConfig>
  schedulableRoutines: string[]
}

function ConfiguredGenericScheduleAction({
  operatorActionConfig,
  schedulableRoutines,
}: ConfiguredProps): JSX.Element {
  // Use useMemo to make sure we pass a stable reference
  // TODO(damon): Understand better why this is needed
  const fetchDeps = useMemo(() => schedulableRoutines, schedulableRoutines)
  const {
    isLoading,
    data: routineDefinitionsWithDSLs,
    error,
  } = useRoutineDefinitionsWithDSLs(fetchDeps)

  if (isLoading) {
    return <LoadingActionMessage />
  } else if (error != null) {
    return (
      <ScheduleOperatorActionWithError
        operatorActionConfig={operatorActionConfig}
        message={error}
      />
    )
  } else if (routineDefinitionsWithDSLs.length === 0) {
    // This can happen when the configured routine wasn't found
    return (
      <ScheduleOperatorActionWithError
        operatorActionConfig={operatorActionConfig}
        message={'Configured routines could not be found'}
      />
    )
  }

  const sortedRoutineDefinitions = sortBy(
    [
      routineDefinition => (routineDefinition.type === 'qc' ? 1 : 0),
      routineDefinition => routineDefinition.name,
    ],
    routineDefinitionsWithDSLs,
  )

  return (
    <GenericScheduleForm
      operatorActionConfig={operatorActionConfig}
      schedulableRoutines={schedulableRoutines}
      routineDefinitions={sortedRoutineDefinitions}
    />
  )
}
