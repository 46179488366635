import cx from 'classnames'
import { useContext, useEffect, useState } from 'react'

import Dialog from '~/components/Dialog'
import WorkcellStatusContext from '~/pages/Workcell/WorkcellStatusContext'
import ProcessItemMetadata from '~/pages/Workcell/components/processItem/ProcessItemMetadata'
import ProcessItemVizEditable from '~/pages/Workcell/components/processItem/ProcessItemVizEditable'
import { areStepsScheduled } from '~/pages/Workcell/utils/workcellStatus'
import PropTypes from '~/propTypes'
import {
  updateProcessItemCurrentIndex,
  updateProcessItemWellArray,
} from '~/utils/processItems/plate'

import { ProcessItem } from '~/common.interface'
import cs from './edit_process_item_dialog.scss'

interface EditProcessItemDialogProps {
  className?: string
  isOpen: boolean
  onClose: () => void
  onEdit?: (processItem: ProcessItem) => void
  processItem?: ProcessItem
}

const EditProcessItemDialog = ({
  className,
  isOpen,
  onClose,
  processItem,
  onEdit,
}: EditProcessItemDialogProps) => {
  const [processItemEdits, setProcessItemEdits] = useState<ProcessItem>()
  const workcellStatus = useContext(WorkcellStatusContext)

  useEffect(() => {
    processItem && setProcessItemEdits(processItem)
  }, [isOpen])

  const handleCurrentIndexUpdate = (currentIndex: number) => {
    processItemEdits &&
      setProcessItemEdits(updateProcessItemCurrentIndex(processItemEdits, currentIndex))
  }

  const handleWellArrayUpdate = (wellArray: string[]) => {
    processItemEdits &&
      setProcessItemEdits(updateProcessItemWellArray(processItemEdits, wellArray))
  }

  const getError = () => {
    if (areStepsScheduled(workcellStatus)) {
      // We use intentionally "routines" for the user error message instead of "process steps".
      return 'Cannot edit process item while routines are scheduled.'
    }
    return null
  }

  const renderContents = () => {
    if (!processItem) return null
    return (
      <>
        <Dialog.Title>Editing {processItem.uuid}</Dialog.Title>
        <Dialog.Subtitle>
          <ProcessItemMetadata processItem={processItem} className={cs.itemMetadata} />
        </Dialog.Subtitle>

        <div className={cs.info}>Click to edit wells.</div>
        {processItemEdits?.type === 'culture_plate' ? (
          <ProcessItemVizEditable
            processItem={processItemEdits}
            className={cs.processItemViz}
            onWellArrayUpdate={handleWellArrayUpdate}
          />
        ) : (
          <ProcessItemVizEditable
            processItem={processItemEdits}
            className={cs.processItemViz}
            onCurrentIndexUpdate={handleCurrentIndexUpdate}
          />
        )}
        <Dialog.Footer error={getError()}>
          <Dialog.FooterButton label='Cancel' onClick={onClose} />
          <Dialog.FooterButton
            label='Confirm'
            type='primary'
            disabled={!processItemEdits}
            onClick={() => processItemEdits && onEdit?.(processItemEdits)}
          />
        </Dialog.Footer>
      </>
    )
  }

  return (
    <Dialog
      isOpen={isOpen}
      onClose={onClose}
      className={cx(className, cs.editProcessItemDialog)}
    >
      {renderContents()}
    </Dialog>
  )
}

EditProcessItemDialog.propTypes = {
  className: PropTypes.string,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onEdit: PropTypes.func,
  processItem: PropTypes.ProcessItem,
}

export default EditProcessItemDialog
