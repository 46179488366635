import PropTypes from 'prop-types'
import { IconProps } from './types/IconProps'

const CalculateIcon = ({ className, onClick }: IconProps) => (
  <svg
    width='24'
    height='24'
    viewBox='0 0 24 24'
    xmlns='http://www.w3.org/2000/svg'
    className={className}
    onClick={onClick}
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M7 1L17 1.00864C18.1 1.00864 19 1.77727 19 2.72727V18.2727C19 19.2227 18.1 20 17 20H7C5.9 20 5 19.2227 5 18.2727V2.72727C5 1.77727 5.9 1 7 1ZM11 8H13V10H11V8ZM11 12H13V17H11V12ZM17 18H7V6H17V18ZM7 3V4H17V3H7Z'
    />
  </svg>
)

CalculateIcon.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
}

export default CalculateIcon
