import { SelectedStorageLocation } from '~/pages/Workcell/components/StorageViz/StorageVizView'
import { SelectedTransferStation } from '~/pages/Workcell/components/TransferStationViz/TransferStationVizView'
import { InstrumentDescriptorForItemType } from '../InstrumentDescriptorForItemType.interface'
import { LoadUnloadItemsProcessItemType } from '../LoadUnloadItemsProcessItemType.interface'
import LoadUnloadItemsInhecoScilaViz from './LoadUnloadItemsInhecoScilaViz'
import LoadUnloadItemsInstrumentVizDefault from './LoadUnloadItemsInstrumentVizDefault'

export interface LoadUnloadItemsInstrumentVizProps {
  className?: string
  instrumentDescriptorForItemtype?: InstrumentDescriptorForItemType
  selectedStorageLocations: SelectedStorageLocation[]
  onStorageLocationSelect: (location: SelectedStorageLocation) => void
  selectedTransferStations: SelectedTransferStation[]
  onTransferStationSelect: (location: SelectedTransferStation) => void
  loadOrUnload: 'load' | 'unload'
  selectedProcessItemType: LoadUnloadItemsProcessItemType
  reloadKey?: string
  disabled?: boolean
}

const LoadUnloadItemsInstrumentViz = ({
  instrumentDescriptorForItemtype,
  selectedStorageLocations,
  onStorageLocationSelect,
  selectedTransferStations,
  onTransferStationSelect,
  loadOrUnload,
  selectedProcessItemType,
  reloadKey,
  disabled,
  className,
}: LoadUnloadItemsInstrumentVizProps) => {
  if (!instrumentDescriptorForItemtype) {
    return null
  }

  if (instrumentDescriptorForItemtype.instrument.instrumentType === 'inheco_scila') {
    return (
      <LoadUnloadItemsInhecoScilaViz
        className={className}
        instrumentDescriptorForItemtype={instrumentDescriptorForItemtype}
        selectedStorageLocations={selectedStorageLocations}
        onStorageLocationSelect={onStorageLocationSelect}
        selectedTransferStations={selectedTransferStations}
        loadOrUnload={loadOrUnload}
        reloadKey={reloadKey}
        disabled={disabled}
        selectedProcessItemType={selectedProcessItemType}
      />
    )
  }

  return (
    <LoadUnloadItemsInstrumentVizDefault
      className={className}
      instrumentDescriptorForItemtype={instrumentDescriptorForItemtype}
      selectedStorageLocations={selectedStorageLocations}
      onStorageLocationSelect={onStorageLocationSelect}
      selectedTransferStations={selectedTransferStations}
      onTransferStationSelect={onTransferStationSelect}
      loadOrUnload={loadOrUnload}
      reloadKey={reloadKey}
      disabled={disabled}
      selectedProcessItemType={selectedProcessItemType}
    />
  )
}

export default LoadUnloadItemsInstrumentViz
