/**
 * Useful for when a component only wants to manage the plate's ID.
 */

import { useEffect, useState } from 'react'

import { find } from 'lodash/fp'
import processItemsAPI from '~/api/desktop/processItems'
import { ProcessItem } from '~/common.interface'
import { getComparatorStringForShallowObject } from '~/utils/array'
import { ProcessItemFilters } from './ProcessItemFilters'
import ProcessItemSelect from './ProcessItemSelect'
import { getProcessItemFiltersWithDefaults } from './getProcessItemFiltersWithDefaults'
import cs from './managed_process_item_select.scss'

export interface ManagedProcessItemIDSelectProps {
  selectedPlateId: string | null
  onSelectedPlateIdChanged: (plateId: string | null) => void
  hideLabel?: boolean
  processItemFilters?: ProcessItemFilters
}

export function ManagedProcessItemIDSelect(
  props: ManagedProcessItemIDSelectProps,
): JSX.Element {
  const [processItems, setProcessItems] = useState<ProcessItem[]>([])

  const fetchProcessItems = async () => {
    return processItemsAPI.getProcessItems(
      getProcessItemFiltersWithDefaults(props.processItemFilters || {}),
    )
  }

  useEffect(() => {
    fetchProcessItems().then(items => {
      setProcessItems(items)
      props.onSelectedPlateIdChanged(null)
    })
  }, [getComparatorStringForShallowObject(props.processItemFilters)])

  const handleProcessitemSelect = (processItem: ProcessItem) => {
    props.onSelectedPlateIdChanged(processItem?.uuid ?? null)
  }

  const selectedProcessItem = find(['uuid', props.selectedPlateId], processItems)

  return (
    <ProcessItemSelect
      hideLabel={props.hideLabel}
      processItems={processItems}
      selectedProcessItem={selectedProcessItem}
      onProcessItemSelect={handleProcessitemSelect}
      className={cs.select}
      triggerClassName={cs.trigger}
      popoverClassName={cs.popover}
      renderProcessItemViz
    />
  )
}
