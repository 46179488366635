import cx from 'classnames'

import { Popover2 } from '@blueprintjs/popover2'
import { get, includes, memoize } from 'lodash/fp'
import TinyMicroplate, { supportedWellColor } from '~/components/TinyMicroplate'
import { convertWellCoordsToWellName } from '~/utils/microplate'
import {
  WELL_MAPS_BY_LABEL,
  getExperimentPlateWells,
  mapExperimentPlateWellsToLinkedPlateWells,
} from '../../../ProcessItems/LabelledWellMap'
import { ExperimentPlateMap } from '../../../ProcessItems/PlateMap'
import cs from './experiment_plate_map_hover_popover.scss'

export interface ExperimentPlateMapHoverPopoverProps {
  className?: string
  selectedPlateMap: ExperimentPlateMap
}

const highlightColors: supportedWellColor[] = ['accent', 'warning', 'primary']

export const getCaptionWellMap = (
  experimentPlateMap: ExperimentPlateMap,
): { [expPlateWell: string]: string } => {
  let captionWellMap = {}

  experimentPlateMap.labelled_well_maps.forEach(labelledWellMap => {
    captionWellMap = {
      ...captionWellMap,
      ...mapExperimentPlateWellsToLinkedPlateWells(WELL_MAPS_BY_LABEL[labelledWellMap]),
    }
  })

  return captionWellMap
}

// TODO: Support more than 3 colors, perhaps using a categorical color schema from D3.
const getHighlightColor = (index: number): supportedWellColor => {
  return highlightColors[index % highlightColors.length]
}

// TODO(mark): Omit highlights based on stain plate's Stain routine wells-to-process.
const getMicroplateVizHighlights = (selectedPlateMap: ExperimentPlateMap) => {
  return selectedPlateMap.labelled_well_maps.map((labelledWellMap, index) => ({
    color: getHighlightColor(index),
    fn: (row: number, col: number) =>
      includes(
        convertWellCoordsToWellName(row, col),
        getExperimentPlateWells(WELL_MAPS_BY_LABEL[labelledWellMap]),
      ),
  }))
}

const getCaptionFactory = memoize((selectedPlateMap: ExperimentPlateMap) => {
  const captionWellMap = getCaptionWellMap(selectedPlateMap)
  const getCaptionFn = (row, col) => {
    const wellName = convertWellCoordsToWellName(row, col)
    return get(wellName, captionWellMap)
  }

  return getCaptionFn
})

const ExperimentPlateMapHoverPopover = ({
  className,
  selectedPlateMap,
}: ExperimentPlateMapHoverPopoverProps) => {
  const getPlateMapPopover = () => {
    return (
      <div className={cs.plateMapPopover}>
        <div className={cs.name}>{selectedPlateMap.name}</div>
        <TinyMicroplate
          plateFormat={selectedPlateMap.from_format}
          size='extraLarge'
          className={cs.experimentPlateViz}
          highlights={getMicroplateVizHighlights(selectedPlateMap)}
          getCaption={getCaptionFactory(selectedPlateMap)}
        />
        <div className={cs.legend}>
          {selectedPlateMap.labelled_well_maps.map((labelledWellMap, index) => (
            <div className={cs.legendRow} key={labelledWellMap}>
              <div className={cx(cs.legendSquare, cs[getHighlightColor(index)])} />
              <div className={cs.text}>{labelledWellMap}</div>
            </div>
          ))}
        </div>
      </div>
    )
  }

  return (
    <Popover2
      className={cx(className, cs.e11ExperimentPlateMapPopover)}
      content={getPlateMapPopover()}
      interactionKind='hover'
      placement='top-start'
      hoverOpenDelay={50}
      hoverCloseDelay={0}
      minimal
      openOnTargetFocus={false}
    >
      <span className={cs.plateMapHoverText}>View Plate Map</span>
    </Popover2>
  )
}

export default ExperimentPlateMapHoverPopover
