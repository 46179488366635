import cx from 'classnames'

import LoadingIcon from './icons/LoadingIcon'
import cs from './toggle_switch.scss'

export interface ToggleSwitchProps {
  className?: string
  onLabel: string
  offLabel: string
  loadingLabel?: string
  isOn?: boolean
  loading?: boolean
  onClick?: () => void
  disabled?: boolean
}

const ToggleSwitch = ({
  className,
  onLabel,
  offLabel,
  loadingLabel,
  isOn,
  loading,
  onClick,
  disabled,
}: ToggleSwitchProps) => {
  const renderLabel = () => {
    if (loading) {
      return (
        <div className={cs.loadingContainer}>
          <div className={cs.loadingLabel}>{loadingLabel || ''}</div>
          <LoadingIcon className={cs.loadingIcon} />
        </div>
      )
    }
    if (isOn) {
      return <div className={cs.onLabel}>{onLabel}</div>
    }
    return <div className={cs.offLabel}>{offLabel}</div>
  }

  return (
    <div
      className={cx(
        className,
        cs.toggleSwitch,
        loading && cs.loading,
        isOn ? cs.on : cs.off,
        disabled && cs.disabled,
      )}
      onClick={disabled ? undefined : onClick}
    >
      <div className={cs.switchOuter}>
        <div className={cs.switchInner}></div>
      </div>
      {renderLabel()}
    </div>
  )
}

export default ToggleSwitch
