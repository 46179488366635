import PropTypes from 'prop-types'
import { IconProps } from './types/IconProps'
const LineDashesIcon = ({ className, onClick }: IconProps) => (
  <svg
    width='32'
    height='32'
    viewBox='0 0 32 32'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    onClick={onClick}
    className={className}
  >
    <path d='M12 8H27' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
    <path
      d='M12.0007 16H27'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M12.0007 24H27'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path d='M5 8H7' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
    <path
      d='M5.00073 16H6.99996'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M5.00073 24H6.99996'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)

LineDashesIcon.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
}

export default LineDashesIcon
