import createClient from 'openapi-fetch'
import { paths } from '~/api/desktop/generated-schema'
import { getDesktopDomain } from '~/api/desktop/utils'

// Client for interacting with desktop /api/v2. We use openapi-fetch which
// automatically handles type validation.
// TODO: We shouldn't need to re-instantiate the client every time but there's
//  currently a dependency from getDesktopDomain() on the appContext which queries
//  the ip address stored in localStorage.
export const client = () => {
  return createClient<paths>({ baseUrl: `${getDesktopDomain()}` })
}

type TResponse<TData, TError> = TData extends null | undefined
  ? never
  : { data: TData; error?: undefined } | { data?: undefined; error: TError }

// Unwraps the response from the client. This is to help reduce boilerplate while
// ensuring url-based type checks are still performed.
// Ideally, we can directly abstract the client().fetch functions but the typing work
// for that is non-trivial.
export async function unwrap<TData, TError>(
  promise: Promise<TResponse<TData, TError>>,
): Promise<NonNullable<TData>> {
  const response = await promise
  if (response.data == undefined) {
    return Promise.reject(response.error ?? { status: 500, message: 'Unknown error' })
  }
  return response.data
}

// TODO(mark): Merge this type with the above TError.
export interface ApiV2HttpError {
  message: string
  status: number
}
