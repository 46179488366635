import { omit } from 'lodash/fp'
import { JsonSchema } from '~/types/JsonSchema.interface'
import { RoutineDefinitionWithDSL } from './useRoutineDefinitionsWithDSLs'

export function getJsonSchemaFromRoutineDefinition(
  routineDefinitionWithDSL: RoutineDefinitionWithDSL,
  fieldsToIgnore: string[],
): JsonSchema {
  const inputs = omit(fieldsToIgnore, routineDefinitionWithDSL.dsl.inputs)
  // The inputs field is almost like an OpenAPI schema. Just enclose it in 'properties'.
  return { properties: inputs }
}
