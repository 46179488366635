import cx from 'classnames'
import { keys } from 'lodash/fp'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom'

import workcellAPI from '~/api/desktop/workcell'
import Tabs from '~/components/Tabs'
import { getComparatorString } from '~/utils/array'
import useTabsWithUrl from '~/utils/hooks/useTabsWithUrlPart'

import { Instrument, InstrumentStatus } from '~/common.interface'
import { useIsMounted } from '~/utils/hooks/useIsMounted'
import Instruments from './Instruments'
import LiveStatus from './LiveStatus'
import TransferStations from './TransferStations'
import cs from './workcell_overview.scss'

const TABS = [
  {
    key: 'live-status',
    title: 'Live Status',
  },
  {
    key: 'transfer-stations',
    title: 'Transfer Stations',
  },
  {
    key: 'instruments',
    title: 'Instruments',
  },
]

interface WorkcellOverviewProps {
  className?: string
  instrumentStatus?: Record<string, InstrumentStatus>
  live?: boolean
}

const WorkcellOverview = ({
  className,
  instrumentStatus,
  live,
}: WorkcellOverviewProps) => {
  const [instruments, setInstruments] = useState<Instrument[] | null>(null)
  const match = useRouteMatch()
  const isMounted = useIsMounted()

  const fetchInstruments = () => {
    workcellAPI.getInstruments().then(_instruments => {
      if (isMounted()) {
        setInstruments(_instruments)
      }
    })
  }

  const [tabs, selectedTabId, handleTabChange] = useTabsWithUrl(TABS)

  useEffect(() => {
    fetchInstruments()
  }, [getComparatorString(keys(instrumentStatus))])

  return (
    <div className={cx(className, cs.workcellOverview)}>
      <Tabs
        tabs={tabs}
        selectedTabId={selectedTabId}
        className={cs.tabs}
        panelClassName={cs.tabPanel}
        onlyRenderWhenViewed
        empty
        nested
        onTabChange={handleTabChange}
      />
      <Switch>
        <Route
          exact
          path={match.path}
          render={() => <Redirect to={`${match.path}/live-status`} />}
        />
        <Route path={`${match.path}/live-status`} render={() => <LiveStatus />} />
        <Route
          path={`${match.path}/transfer-stations`}
          render={() => (
            <TransferStations
              live={live}
              instruments={instruments}
              instrumentStatus={instrumentStatus}
            />
          )}
        />
        <Route
          path={`${match.path}/instruments`}
          render={() => (
            <Instruments
              instruments={instruments}
              onInstrumentsUpdate={fetchInstruments}
              instrumentStatus={instrumentStatus}
            />
          )}
        />
      </Switch>
    </div>
  )
}

WorkcellOverview.propTypes = {
  className: PropTypes.string,
  instrumentStatus: PropTypes.shape({}),
  live: PropTypes.bool,
}

export default WorkcellOverview
