import { Route, Switch, useRouteMatch } from 'react-router-dom'

import BulkLoadCulturePlatesAction from './BulkLoadCulturePlates/BulkLoadCulturePlatesAction'
import { ScheduleContinuousCultureAction } from './Demo/ScheduleContinuousCultureAction/ScheduleContinuousCultureAction'
import LoadUnloadItemsAction from './LoadUnloadItems/LoadUnloadItemsAction'
import ManuallyMoveAction from './ManuallyMoveAction'
import OperatorActionsMenu from './OperatorActionsMenu'
import { GenericScheduleAction } from './Schedule/GenericScheduleAction'
import LinkCellLineLotsToCulturesAction from './dataUploaders/LinkCellLineLotsToCultures/LinkCellLineLotsToCulturesAction'
import RecordManualPassageAction from './dataUploaders/RecordManualPassage/RecordManualPassageAction'

const OperatorActions = () => {
  const match = useRouteMatch()
  return (
    <Switch>
      <Route exact path={match.path} render={() => <OperatorActionsMenu />} />
      <Route
        path={`${match.path}/load-unload-items`}
        render={() => <LoadUnloadItemsAction />}
      />
      <Route
        path={`${match.path}/manually-move-items`}
        render={() => <ManuallyMoveAction />}
      />
      <Route
        path={`${match.path}/demo-schedule-continuous-culture`}
        render={() => <ScheduleContinuousCultureAction />}
      />
      <Route
        path={`${match.path}/link-cell-line-lots-to-cultures`}
        render={() => <LinkCellLineLotsToCulturesAction />}
      />
      <Route
        path={`${match.path}/record-manual-passage`}
        render={() => <RecordManualPassageAction />}
      />
      <Route
        path={`${match.path}/bulk-load-culture-plates`}
        render={() => <BulkLoadCulturePlatesAction />}
      />
      <Route path={`${match.path}/schedule`} render={() => <GenericScheduleAction />} />
    </Switch>
  )
}

OperatorActions.propTypes = {}

export default OperatorActions
