import { Classes, Placement, Popover2 } from '@blueprintjs/popover2'
import cx from 'classnames'
import PropTypes from 'prop-types'
import React, { useState } from 'react'

import VerticalMenuIcon from '~/components/icons/VerticalMenuIcon'

import Link from './Link'
import cs from './popup_menu.scss'

export interface PopupMenuOption {
  label: string
  action?: () => void
  to?: string
  externalLink?: string
  disabled?: boolean
}

interface PopupMenuProps {
  options: PopupMenuOption[]
  className?: string
  trigger: React.ReactNode
  iconClassName?: string
  placement?: Placement
  disabled?: boolean
  controlled?: boolean
  open?: boolean
  onClose?: () => void
  popoverClassName?: string
  minimal?: boolean
}

function PopupMenu(props) {
  const {
    options,
    className,
    iconClassName,
    trigger,
    placement,
    disabled,
    controlled,
    open,
    onClose,
    popoverClassName,
    minimal,
  }: PopupMenuProps = props

  const [_open, setOpen] = useState(false)

  const renderOption = option => {
    if (option.externalLink) {
      return (
        <Link
          to={option.externalLink}
          external
          key={option.label}
          className={cx(cs.option, Classes.POPOVER2_DISMISS)}
        >
          {option.label}
        </Link>
      )
    }

    if (option.to) {
      return (
        <Link
          to={option.to}
          key={option.label}
          className={cx(cs.option, Classes.POPOVER2_DISMISS)}
        >
          {option.label}
        </Link>
      )
    }

    return (
      <div
        className={cx(
          cs.option,
          !option.disabled && Classes.POPOVER2_DISMISS,
          option.disabled && cs.disabled,
        )}
        key={option.label}
        onClick={option.disabled ? null : option.action}
      >
        {option.label}
      </div>
    )
  }

  const isOpen = () => {
    return controlled ? open : _open
  }

  return (
    <Popover2
      content={
        <div className={cx(cs.content, popoverClassName)}>
          {options.map(renderOption)}
        </div>
      }
      interactionKind='click'
      isOpen={isOpen()}
      onInteraction={controlled ? undefined : setOpen}
      placement={placement}
      className={cx(cs.menu, className)}
      disabled={disabled}
      onClose={onClose}
      minimal={minimal}
    >
      {trigger || <VerticalMenuIcon className={cx(cs.icon, iconClassName)} />}
    </Popover2>
  )
}

PopupMenu.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      action: PropTypes.func,
      to: PropTypes.string,
      externalLink: PropTypes.string,
      disabled: PropTypes.bool,
    }),
  ),
  className: PropTypes.string,
  trigger: PropTypes.node,
  iconClassName: PropTypes.string,
  placement: PropTypes.string,
  disabled: PropTypes.bool,
  controlled: PropTypes.bool,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  popoverClassName: PropTypes.string,
  minimal: PropTypes.bool,
}

PopupMenu.defaultProps = {
  placement: 'bottom-start',
}

export default PopupMenu
