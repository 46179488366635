import PropTypes from 'prop-types'
import { IconProps } from './types/IconProps'

const RotateIcon = ({ className, onClick }: IconProps) => (
  <svg
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    onClick={onClick}
    className={className}
  >
    <path d='M17.65 17.645C19.1 16.195 20 14.205 20 11.995C20 7.575 16.42 4.005 12 4.005C7.58 4.005 4 7.575 4 11.995C4 15.725 6.55 18.835 10 19.725L10 17.645C7.67 16.825 6 14.605 6 11.995C6 8.685 8.69 5.995 12 5.995C15.31 5.995 18 8.685 18 11.995C18 13.655 17.31 15.135 16.22 16.215L13 12.995L13 19.995L20 19.995L17.65 17.645Z' />
  </svg>
)

RotateIcon.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
}

export default RotateIcon
