// This converts the number into a display string.
// Currently used to display unit_floats.

// This rounds the number.
export const toExponentialString = (float: number): string => {
  if (float === 0) return '0'
  if (Math.abs(float) < 1000 && Math.abs(float) >= 0.001) return float.toPrecision(3)

  return Number(float).toExponential(2)
}

export const withinRoundingError = (a: number, b: number): boolean => {
  return Math.abs(a - b) / a < 0.0001
}

export const lte = (a: number, b: number): boolean => {
  return a <= b || withinRoundingError(a, b)
}

export const gte = (a: number, b: number): boolean => {
  return a >= b || withinRoundingError(a, b)
}
