import PropTypes from 'prop-types'
import { IconProps } from './types/IconProps'
const LeftArrowIcon = ({ className, onClick }: IconProps) => (
  <svg
    width='8'
    height='12'
    viewBox='0 0 8 12'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    className={className}
    onClick={onClick}
  >
    <path d='M7.705 10.59L3.125 6L7.705 1.41L6.295 0L0.294998 6L6.295 12L7.705 10.59Z' />
  </svg>
)

LeftArrowIcon.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
}

export default LeftArrowIcon
