import { map } from 'lodash/fp'
import PropTypes from 'prop-types'
import { useEffect } from 'react'

import D3ChartWrapper from '~/components/d3/D3ChartWrapper'
import { getComparatorString } from '~/utils/array'
import useReloadKey from '~/utils/hooks/useReloadKey'

import { TooltipParams } from '../ChartTooltip'
import { D3ChartAxisOptions, D3ChartLayoutOptions } from '../d3/D3Chart'
import D3ProcessTimelineViz, {
  D3ProcessTimelineVizData,
  D3ProcessTimelineVizOptions,
} from './D3ProcessTimelineViz'

interface ProcessTimelineVizProps {
  className?: string
  data: D3ProcessTimelineVizData
  options?: Partial<D3ProcessTimelineVizOptions>
  layoutOptions?: Partial<D3ChartLayoutOptions>
  axisOptions?: Partial<D3ChartAxisOptions<Date, Date>>
  renderTooltipContents?: (tooltipParams: TooltipParams) => void
  height: number
  instruments: { instrumentName: string }[]
}

const ProcessTimelineViz = ({
  data,
  options,
  className,
  height,
  instruments,
  axisOptions,
  layoutOptions,
  renderTooltipContents,
}: ProcessTimelineVizProps) => {
  const [reloadKey, refreshReloadKey] = useReloadKey(undefined)

  useEffect(() => {
    refreshReloadKey()
  }, [getComparatorString(map('instrumentName', instruments))])

  if (!reloadKey) return null

  return (
    <D3ChartWrapper<D3ProcessTimelineVizData, Date, Date, D3ProcessTimelineVizOptions>
      D3ChartClass={D3ProcessTimelineViz}
      className={className}
      data={data}
      layoutOptions={layoutOptions}
      axisOptions={{
        ...axisOptions,
        enableYAxis: false,
        xAxisPosition: 'top',
        xTicks: 5,
      }}
      options={options}
      containerStyle={{ height }}
      showNoDataMessage={false}
      renderTooltipContents={renderTooltipContents}
      chartTooltipTriggerFn='onBarHover'
      tooltipPosition='center'
      reloadKey={reloadKey}
    />
  )
}

ProcessTimelineViz.propTypes = {
  className: PropTypes.string,
  data: PropTypes.shape({
    steps: PropTypes.arrayOf(PropTypes.shape({})),
    workcellStatus: PropTypes.shape({
      live: PropTypes.bool,
      status: PropTypes.string,
      state: PropTypes.shape({}),
    }),
  }),
  options: PropTypes.objectOf(PropTypes.any),
  height: PropTypes.number,
  // Allows parent to specify custom tooltip rendering.
  renderTooltipContents: PropTypes.func,
  instruments: PropTypes.arrayOf(
    PropTypes.shape({
      instrumentName: PropTypes.string,
      instrumentType: PropTypes.string,
    }),
  ),
}

export default ProcessTimelineViz
