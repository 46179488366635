import { flatten, fromPairs, map, range } from 'lodash/fp'
import { supportedPlateFormats } from '~/components/TinyMicroplate.interface'
import { convertWellCoordsToWellName, getPlateDims } from '~/utils/microplate'
import {
  CLE_PHASES,
  CellLine,
  ClePhase,
  ClePlate,
  Well,
  getConfluencyForPlate,
} from './ClePlate.interface'

const PARENT_CELL_LINES = [
  'ACS-1020',
  'ACS-1021',
  'ACS-1022',
  'ACS-1023',
  'ACS-1024',
  'ACS-1025',
  'ACS-1026',
  'ACS-1027',
  'ACS-1028',
  'ACS-1029',
  'ACS-1030',
  'ACS-1031',
  'ACS-1032',
  'ACS-1033',
  'ACS-1034',
  'ACS-1035',
  'ACS-1036',
  'ACS-1037',
  'ACS-1038',
  'ACS-1039',
  'ACS-1040',
  'ACS-1041',
  'ACS-1042',
  'ACS-1043',
  'ACS-1044',
  'ACS-1045',
  'ACS-1046',
  'ACS-1047',
  'ACS-1048',
  'ACS-1049',
]

const DESIRED_EDITS = [
  'CDK4-GOF',
  'CDK6-GOF',
  'MYC-Over',
  'P53-GOF',
  'P27-KO',
  'PTEN-KO',
  'EGFR-DEL',
  'BRCA1-KO',
  'FOXL2-Over',
  'TYR-KO',
  'SOX9-KO',
  'PIK3CA-GOF',
  'HIF1A-KO',
  'STAT3-KO',
  'KRAS-Over',
  'FOXO3A-KO',
  'MYCN-GOF',
  'SMAD4-KO',
  'ATM-KD',
  'SHH-GOF',
  'BCL2-KO',
  'FLT3-GOF',
  'RAG1-KO',
  'SHOX2-KO',
  'FOXP3-KO',
  'NRF2-Edit',
  'HDAC2-KO',
  'STAT5A-GOF',
  'NF1-KO',
  'TFEB-KO',
]

const OWNERS = [
  'Dr. Seraphina Reed',
  'Dr. Isabella Morrow',
  'Dr. Lillian Harper',
  'Dr. Felicity Wells',
  'Dr. Penelope Chang',
  'Dr. Harper Monroe',
]

const generateCellLine = (cellLineIndex: number): CellLine => {
  const parentCellLine = PARENT_CELL_LINES[cellLineIndex % PARENT_CELL_LINES.length]
  const desiredEdit = DESIRED_EDITS[cellLineIndex % DESIRED_EDITS.length]
  return {
    parentCellLine,
    desiredEdit,
    cellLineIndex,
    name: `${parentCellLine}-${desiredEdit}`,
  }
}

const generateWellsForPlate = (
  plateFormat: supportedPlateFormats,
  phase: string,
  cellLineIndex: number,
): Record<string, Well> => {
  const { numRows, numCols } = getPlateDims(plateFormat)

  const pairs = flatten(
    map(
      row =>
        map(
          col => [
            convertWellCoordsToWellName(row, col),
            {
              confluency: getConfluencyForPlate(phase, cellLineIndex),
            },
          ],
          range(0, numCols),
        ),
      range(0, numRows),
    ),
  )
  return fromPairs(pairs)
}

function makePlate(plate: Omit<ClePlate, 'id'>): ClePlate {
  return {
    ...plate,
    id: `${plate.cellLine.cellLineIndex}-${plate.phase}-${plate.plateNumber}`,
  }
}

const generateEnrichmentPlatesForEdit = (
  cellLineIndex: number,
  hasAlert: boolean,
): ClePlate[] => {
  return [
    makePlate({
      plateFormat: 'wells_96',
      hasAlert,
      cellLine: generateCellLine(cellLineIndex),
      phase: 'enrichment',
      plateNumber: 1,
      wells: generateWellsForPlate('wells_96', 'enrichment', cellLineIndex),
      owner: OWNERS[cellLineIndex % OWNERS.length],
    }),
  ]
}

const generateClonalIsolationPlatesForEdit = (
  cellLineIndex: number,
  hasAlert: boolean,
): ClePlate[] => {
  return map(
    plateNumber =>
      makePlate({
        plateFormat: 'wells_96',
        hasAlert,
        cellLine: generateCellLine(cellLineIndex),
        phase: 'clonal_isolation',
        plateNumber,
        wells: generateWellsForPlate('wells_96', 'clonal_isolation', cellLineIndex),
        owner: OWNERS[cellLineIndex % OWNERS.length],
      }),
    range(1, 4),
  )
}

const generateHitpickingPlatesForEdit = (
  cellLineIndex: number,
  hasAlert: boolean,
): ClePlate[] => {
  return flatten(
    map(
      plateNumber =>
        makePlate({
          plateFormat: 'wells_96',
          hasAlert,
          cellLine: generateCellLine(cellLineIndex),
          phase: 'hitpicking',
          plateNumber,
          wells: generateWellsForPlate('wells_96', 'hitpicking', cellLineIndex),
          owner: OWNERS[cellLineIndex % OWNERS.length],
        }),
      range(1, 4),
    ),
  )
}

const generateExpansionPlatesForEdit = (cellLineIndex: number): ClePlate[] => {
  return map(
    plateNumber =>
      makePlate({
        plateFormat: 'wells_6',
        hasAlert: false,
        cellLine: generateCellLine(cellLineIndex),
        phase: 'clonal_expansion',
        plateNumber,
        wells: generateWellsForPlate('wells_6', 'clonal_expansion', cellLineIndex),
        owner: OWNERS[cellLineIndex % OWNERS.length],
      }),
    range(1, 3),
  )
}

const generateEnrichmentPlates = (numAlerts: number): ClePlate[] => {
  return flatten(
    range(0, 6).map((cellLineIndex, index) =>
      generateEnrichmentPlatesForEdit(cellLineIndex, index < numAlerts),
    ),
  )
}

const generateClonalIsolationPlates = (numAlerts: number): ClePlate[] => {
  return flatten(
    range(6, 10).map((cellLineIndex, index) =>
      generateClonalIsolationPlatesForEdit(cellLineIndex, index < numAlerts),
    ),
  )
}

const generateHitpickingPlates = (numAlerts: number): ClePlate[] => {
  return flatten(
    range(10, 18).map((cellLineIndex, index) =>
      generateHitpickingPlatesForEdit(cellLineIndex, index < numAlerts),
    ),
  )
}

const generateExpansionPlates = (): ClePlate[] => {
  return flatten(
    map(cellLineIndex => generateExpansionPlatesForEdit(cellLineIndex), range(18, 30)),
  )
}

export const generateClePipelinePlates = (): ClePlate[] => {
  return [
    ...generateEnrichmentPlates(0),
    ...generateClonalIsolationPlates(0),
    ...generateHitpickingPlates(1),
    ...generateExpansionPlates(),
  ]
}

export const CLE_PLATES = generateClePipelinePlates()

type PlateGenerator = (cellLineIndex: number, hasAlert: boolean) => ClePlate[]

const PHASE_PLATE_GENERATORS: { [key in ClePhase]: PlateGenerator } = {
  enrichment: generateEnrichmentPlatesForEdit,
  clonal_isolation: generateClonalIsolationPlatesForEdit,
  hitpicking: generateHitpickingPlatesForEdit,
  clonal_expansion: generateExpansionPlatesForEdit,
}

export function generateArchivedPlates(
  cellLineIndex: number,
  activePhase: ClePhase,
): ClePlate[] {
  let plates: ClePlate[] = []

  const lastIndex = CLE_PHASES.indexOf(activePhase)
  for (const phase of CLE_PHASES.slice(0, lastIndex).toReversed()) {
    plates = [
      ...plates,
      ...PHASE_PLATE_GENERATORS[phase](cellLineIndex, /* hasAlert */ false),
    ]
  }

  return plates
}
