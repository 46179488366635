import { get } from 'lodash/fp'

export const getProcessItemForStep = step => {
  const routineName = get('routineName', step)

  if (routineName === 'Media Exchange') {
    return get(['routineParameters', 'plateBarcode'], step)
  }

  if (routineName === 'Image Culture Plate') {
    return get(['routineParameters', 'plateBarcode'], step)
  }
  return (
    get(['routineParameters', 'plateBarcode'], step) ||
    get(['routineParameters', 'processItem'], step)
  )
}
