import { DemoConfig } from '../../CommandCenter/SlasDemoConfigDialog/DemoConfig'
import { DemoEvent } from '../DemoEvent'
import { EventState } from '../EventState'
import { ViewModels } from '../ViewModels/ViewModels'
import { projectCultureProcessLogs } from './projectCultureProcessLogs'
import { projectCultureViewModels } from './projectCultureViewModels'
import { projectCurrentDay } from './projectCurrentDay'
import { projectMediaLotViewModels } from './projectMediaLotViewModel'
import { projectPlateViewModels } from './projectPlateViewModels'

export const INITIAL_VIEW_MODELS = {
  today: 0,
  cultures: {},
  mediaLots: {},
  plates: {},
  cultureProcessLogs: {},
  _lastIndex: -1,
}

// TODO: Each of these inner project*() follows roughly the same pattern - we could
// refactor them if we add more.
export function projectViewModels(
  eventState: EventState,
  config: DemoConfig,
  viewModels: ViewModels = INITIAL_VIEW_MODELS,
  useLocalImages: boolean,
): ViewModels {
  const eventIndex = viewModels._lastIndex + 1

  const today = projectCurrentDay(eventState)
  const cultures = projectCultureViewModels(eventState, config, useLocalImages, {
    eventIndex,
    cultures: viewModels.cultures,
  })
  const plates = projectPlateViewModels(eventState, cultures, {
    eventIndex,
    plates: viewModels.plates,
  })
  const mediaLots = projectMediaLotViewModels(eventState, cultures, {
    eventIndex,
    mediaLots: viewModels.mediaLots,
  })
  const cultureProcessLogs = projectCultureProcessLogs(eventState, {
    eventIndex,
    cultureProcessLogs: viewModels.cultureProcessLogs,
  })
  return {
    today,
    cultures,
    plates,
    mediaLots,
    cultureProcessLogs,
    _lastIndex: eventState.lastIndex,
  }
}

export function applyOptimisticEvents(
  viewModels: ViewModels,
  eventState: EventState,
  events: DemoEvent[],
  // DemoConfig.profile is currently used when naming cultures in projectCultureViewModels.
  // It could make sense to include this information in a DemoEvent in the future.
  config: DemoConfig,
  // Tech debt: Generating demo data involves generating image URLs, but we
  // shouldn't have to pass `useLocalImages` here, because we don't expect it to
  // change except on a new page load. However, we need this to allow the
  // existing CLE demo data generation architecture to work without depending on
  // the global appContext. We should revisit this if we actually build CLE,
  // perhaps as part of replacing the view models.
  useLocalImages: boolean,
): ViewModels {
  const optimisticEventState = {
    eventLog: [
      ...eventState.eventLog,
      ...events.map((ev, i) => ({
        // eventState.lastIndex is inclusive
        index: eventState.lastIndex + i + 1,
        data: ev,
      })),
    ],
    lastIndex: eventState.lastIndex + events.length,
  }

  return projectViewModels(optimisticEventState, config, viewModels, useLocalImages)
}
