import { compact, first, includes, map } from 'lodash/fp'
import { useEffect, useState } from 'react'
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom'

import { getComparatorString } from '~/utils/array'
import { getUrlAfter } from '~/utils/url'

export default function useTabsWithUrl(tabs) {
  const history = useHistory()
  const match = useRouteMatch()
  const location = useLocation()

  const [selectedTabId, setSelectedTabId] = useState(null)

  const getSelectedTabId = () => {
    let tab = getUrlAfter(match.url)

    const _tabs = map('key', tabs)
    if (tab) {
      tab = first(compact(tab.split('/')))
    }

    if (includes(tab, _tabs)) {
      return tab
    }
    return null
  }

  const handleTabChange = tabKey => {
    history.push(`${match.url}/${tabKey}`)
  }

  useEffect(() => {
    setSelectedTabId(getSelectedTabId())
  }, [location, getComparatorString(map('key', tabs))])

  return [tabs, selectedTabId, handleTabChange]
}
