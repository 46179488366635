import PropTypes from 'prop-types'
import { ReactNode, useEffect, useState } from 'react'

import Dialog from '~/components/Dialog'
import Tabs from '~/components/Tabs'
import Toaster from '~/components/Toaster'
import Notification from '~/components/notifications/Notification'

import datasetsAPI from '~/api/desktop/datasets'
import Dataset from './Dataset.interface'
import cs from './dataset_viewer_dialog.scss'

interface DatasetViewerDialogProps {
  isOpen: boolean
  onClose: () => void
  datasetId?: number
}

const DatasetViewerDialog = ({
  isOpen,
  onClose,
  datasetId,
}: DatasetViewerDialogProps) => {
  /*
    dataset: PropTypes.shape({
      name: PropTypes.string,
      structuredData: PropTypes.shape({}),
      id: PropTypes.number,
      status: PropTypes.string,
      errorMessage: PropTypes.string,
    }),
  */
  const [dataset, setDataset] = useState<Dataset | null>(null)
  const [rawData, setRawData] = useState<string | null>(null)
  const [currentTab, setCurrentTab] = useState('structured_data')

  // TODO(mark): Support situation where dataset is not co-located with the executor desktop.
  // And requires a proxy.
  // This will probably require you to pass the instrumentName together with the datasetId in
  // all dataset-related requests, so you can determine if you are on the correct server
  // containing the dataset.
  const fetchData = async () => {
    if (isOpen && datasetId) {
      const [datasetResponse, rawDataResponse] = await Promise.all([
        datasetsAPI.getDataset(datasetId, {
          verbose: '1',
        }),
        datasetsAPI.getDatasetRawData(datasetId),
      ])
      setRawData(rawDataResponse.rawData)
      setDataset(datasetResponse)
    }
    if (!isOpen) {
      setRawData(null)
      setDataset(null)
      // Tabs are unmounted and the tab is set back to structured_data.
      setCurrentTab('structured_data')
    }
  }
  useEffect(() => {
    fetchData()
  }, [datasetId, isOpen])

  if (!dataset) {
    return null
  }

  const retryStructureRawData = () => {
    onClose()
    Toaster.show({
      message: 'Retrying structure raw data...',
      intent: 'success',
    })
    datasetsAPI.retryStructureRawData(datasetId)
  }

  const renderStructuredData = () => {
    let el: ReactNode = null
    if (dataset.status === 'invalid') {
      el = (
        <Notification
          type='error'
          className={cs.desktopNotification}
          label={
            <>
              The dataset file was invalid.
              <br />
              Error: {dataset.errorMessage}
              <br />
            </>
          }
        />
      )
    } else {
      el = (
        <pre className={cs.data}>
          {JSON.stringify(dataset.structuredData, undefined, 2)}
        </pre>
      )
    }

    return (
      <>
        {el}
        {!dataset.metadata?.usesNewDatasetProcessing && (
          <div
            className={cs.retryStructureRawData}
            onClick={() => retryStructureRawData()}
          >
            Retry
          </div>
        )}
      </>
    )
  }

  const renderRawData = () => {
    return <pre className={cs.data}>{rawData}</pre>
  }

  const renderMetadata = () => {
    return (
      <pre className={cs.data}>{JSON.stringify(dataset.metadata, undefined, 2)}</pre>
    )
  }

  const tabs = [
    {
      key: 'structured_data',
      title: 'Structured Data',
      panel: renderStructuredData(),
    },
    {
      key: 'raw_data',
      title: 'Raw Data',
      panel: renderRawData(),
    },
    {
      key: 'metadata',
      title: 'Metadata',
      panel: renderMetadata(),
    },
  ]

  return (
    <Dialog isOpen={isOpen} onClose={onClose} className={cs.datasetViewerDialog}>
      <div className={cs.title}>{dataset.name}</div>
      <Tabs
        tabs={tabs}
        initialTab='structured_data'
        className={cs.tabs}
        panelClassName={cs.tabPanel}
        onTabChange={setCurrentTab}
        selectedTabId={currentTab}
      />
    </Dialog>
  )
}

DatasetViewerDialog.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  datasetId: PropTypes.number,
}

export default DatasetViewerDialog
