import cx from 'classnames'
import { useEffect, useState } from 'react'

import AppHeader from '~/components/AppHeader'

import { compact, get } from 'lodash/fp'
import { ReloadItemsRequest } from '~/api/operatorActions/reloadItems'
import { ProcessItemLike } from '~/common.interface'
import LoadingMessageBox from '~/components/LoadingMessageBox'
import Button from '~/components/buttons/Button'
import Notification from '~/components/notifications/Notification'
import TinyNotification from '~/components/notifications/TinyNotification'
import useReloadKey from '~/utils/hooks/useReloadKey'
import { isStorageInstrument } from '~/utils/instrument'
import executeTaskEndpoint, {
  TaskExecutionResult,
  TaskExecutionStartResponse,
} from '../../../../api/executeTaskEndpoint'
import StorageViz from '../../components/StorageViz'
import TransferStationViz from '../../components/TransferStationViz'
import InstrumentSelect from '../../components/instrument/InstrumentSelect'
import OperatorActionConfigDialog from '../OperatorActionConfigDialog'
import ProcessItemChange from '../ProcessItemChange'
import useOperatorActionInstrument from '../useOperatorActionInstrument'
import { ReloadItemOperatorActionConfig } from './ReloadItemOperatorActionConfig.interface'
import { ReloadChange } from './ReloadOperation.interface'
import { getReloadItemsRequestFromChanges } from './convertChangeToReloadOperation'
import cs from './reload_items_action.scss'

interface ReloadItemsActionProps {
  className?: string
  operatorActionName?: string
  actionIcon: string
  actionDefaultName: string
  actionDescription: string
  renderForm: (executingTask: boolean, changes: ReloadChange[]) => JSX.Element | null
  submitAPI: (changes: ReloadItemsRequest) => Promise<TaskExecutionStartResponse>
  getChangesForSlotClick: (
    itemUuid: string,
    shelfIndex: number,
    levelIndex: number,
    isChanged: boolean,
    instrumentName: string,
    existingChanges: ReloadChange[],
  ) => ReloadChange[]
  getChangesForTransferStationClick: (
    item: ProcessItemLike | null,
    transferStationId: string,
    isChanged: boolean,
    instrumentName: string,
    existingChanges: ReloadChange[],
  ) => ReloadChange[]
  onResetFormValues: () => void
  onSubmit?: () => void
  config: ReloadItemOperatorActionConfig
  handleConfigUpdate: (config: ReloadItemOperatorActionConfig) => void
  infoText: string
}

const ReloadItemsAction = ({
  className,
  operatorActionName,
  actionIcon,
  actionDefaultName,
  actionDescription,
  renderForm,
  onResetFormValues,
  onSubmit,
  submitAPI,
  getChangesForSlotClick,
  getChangesForTransferStationClick,
  config,
  handleConfigUpdate,
  infoText,
}: ReloadItemsActionProps) => {
  const [executingTask, setExecutingTask] = useState(false)
  const [changes, setChanges] = useState<ReloadChange[]>([])
  // TODO: Finish typing response
  const [response, setResponse] = useState<TaskExecutionResult<unknown> | null>(null)
  const [reloadKey, refreshReloadKey] = useReloadKey()
  const [operatorActionConfigDialogOpen, setOperatorActionConfigDialogOpen] =
    useState(false)

  const handleReset = () => {
    setChanges([])
  }

  const { instruments, selectedInstrument, fetchInstruments, handleSelectInstrument } =
    useOperatorActionInstrument(handleReset)

  useEffect(() => {
    fetchInstruments(config)
  }, [config])

  const handleSubmit = async () => {
    if (!selectedInstrument) return
    setResponse(null)
    const _response = await executeTaskEndpoint(
      () => submitAPI(getReloadItemsRequestFromChanges(changes)),
      setExecutingTask,
    )
    handleReset()
    onResetFormValues()
    if (onSubmit) onSubmit()
    setResponse(_response)
    refreshReloadKey()
  }

  const handleSlotClick = (itemUuid, shelfIndex, levelIndex, isChanged) => {
    if (!selectedInstrument) return
    const _changes: ReloadChange[] = getChangesForSlotClick(
      itemUuid,
      shelfIndex,
      levelIndex,
      isChanged,
      selectedInstrument.instrumentName,
      changes,
    )

    if (_changes.length !== 0) {
      setChanges([...changes, ...compact(_changes)])
      onResetFormValues()
    }
  }

  const handleTransferStationClick = (
    item: ProcessItemLike | null,
    transferStationId: string,
    isChanged: boolean,
  ) => {
    if (!selectedInstrument) return
    const _changes: ReloadChange[] = getChangesForTransferStationClick(
      item,
      transferStationId,
      isChanged,
      selectedInstrument.instrumentName,
      changes,
    )

    if (_changes.length !== 0) {
      setChanges([...changes, ...compact(_changes)])
      onResetFormValues()
    }
  }

  const getHighlightLocations = () => {
    if (response && response.success) {
      return [
        `s${get(['result', 'shelf'], response)}:l${get(['result', 'level'], response)}`,
      ]
    }
    return undefined
  }

  const renderVizForInstrument = () => {
    if (!selectedInstrument) {
      return null
    }
    if (isStorageInstrument(selectedInstrument.instrumentType)) {
      return (
        <StorageViz
          className={cs.storageViz}
          instrument={selectedInstrument}
          highlightLocations={getHighlightLocations() || undefined}
          reloadKey={reloadKey}
          clickablePartitions={config ? config.allowedPartitions : []}
          displayAddIconOnHover
          displayRemoveIconOnHover
          onSlotClick={handleSlotClick}
          changes={changes}
        />
      )
    }
    return (
      <TransferStationViz
        className={cs.storageViz}
        instrument={selectedInstrument}
        reloadKey={reloadKey}
        displayAddIconOnHover
        displayRemoveIconOnHover
        onTransferStationClick={handleTransferStationClick}
        changes={changes}
        clickableStations={config.allowedTransferStations}
      />
    )
  }

  const renderResponseBox = () => {
    if (!response) return null
    if (!response.success) {
      return (
        <Notification
          type='error'
          className={cs.notification}
          label={response.error}
          variant='mini'
        />
      )
    }
    return (
      <Notification
        type='success'
        className={cs.notification}
        label={get(['result', 'successMessage'], response)}
        variant='mini'
      />
    )
  }

  const renderChanges = () => {
    return (
      <div className={cs.changes}>
        <div className={cs.changesSummary}>{changes.length} changes</div>
        <div className={cs.changeList}>
          {changes.map((change, index) => (
            <ProcessItemChange className={cs.change} key={index} change={change} />
          ))}
        </div>
      </div>
    )
  }

  const renderContents = () => {
    return (
      <div className={cs.contents}>
        <div className={cs.left}>
          <InstrumentSelect
            instruments={instruments}
            selectedInstrument={selectedInstrument}
            onInstrumentSelect={handleSelectInstrument}
            className={cs.instrumentSelect}
            disabled={executingTask}
          />
          {renderForm(executingTask, changes)}
          {!executingTask && (
            <div className={cs.controls}>
              {infoText && (
                <TinyNotification
                  className={cs.infoNotification}
                  message={infoText}
                  type='bareInfo'
                />
              )}
              <Button
                className={cs.button}
                disabled={
                  selectedInstrument === null || executingTask || changes.length === 0
                }
                label='Save Changes'
                type='primary'
                onClick={handleSubmit}
              />
              <Button
                className={cs.button}
                disabled={
                  selectedInstrument === null || executingTask || changes.length === 0
                }
                label='Reset Changes'
                onClick={handleReset}
              />
              {response && renderResponseBox()}
              {renderChanges()}
            </div>
          )}
          {executingTask && (
            <LoadingMessageBox
              label='Saving Changes...'
              className={cs.loadingMessageBox}
            />
          )}
        </div>
        <div className={cs.right}>{renderVizForInstrument()}</div>
      </div>
    )
  }

  return (
    <div className={cx(className, cs.reloadItemsAction)}>
      <AppHeader
        appName={get('displayName', config) || actionDefaultName}
        iconSrc={actionIcon}
        className={cs.appHeader}
        variant='mini'
        menuOptions={[
          {
            label: 'Configure...',
            action: () => setOperatorActionConfigDialogOpen(true),
          },
        ]}
      />
      <div className={cs.description}>{actionDescription}</div>
      {renderContents()}
      <OperatorActionConfigDialog
        isOpen={operatorActionConfigDialogOpen}
        onClose={() => setOperatorActionConfigDialogOpen(false)}
        operatorActionName={operatorActionName}
        onConfigUpdate={handleConfigUpdate}
      />
    </div>
  )
}

export default ReloadItemsAction
