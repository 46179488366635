import Notification from '~/components/notifications/Notification'

import { Instrument } from '~/common.interface'
import MinimalBoldButton from '~/components/buttons/MinimalBoldButton'
import cs from './failed_unload_user_warning.scss'

export interface FailedUnloadUserWarningProps {
  className?: string
  selectedInstrument: Instrument | null
  onClose: () => void
}

const FailedUnloadUserWarning = ({
  className,
  selectedInstrument,
  onClose,
}: FailedUnloadUserWarningProps) => {
  const contents = (
    <div>
      {selectedInstrument?.instrumentName} faulted while unloading plates.{' '}
      <b>
        If any plates were just unloaded, please manually remove them from the software.
      </b>{' '}
      Please contact Monomer if you need assistance.
      <div>
        <MinimalBoldButton
          label='I have manually removed any unloaded plates from the software'
          onClick={onClose}
          className={cs.confirmButton}
        />
      </div>
    </div>
  )
  return <Notification className={className} label={contents} type='error' />
}

export default FailedUnloadUserWarning
