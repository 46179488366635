import cx from 'classnames'
import { useState } from 'react'

import {
  CultureDatasetViewModel,
  CultureViewModel,
  getDatasetCreatedAt,
} from '../../events/ViewModels/CultureViewModel'
import { getRelativeTime } from '../../events/data/eventTimes'
import { CultureDatasetImagesDialog } from '../CultureDatasetImagesDialog/CultureDatasetImagesDialog'
import CultureImage from './CultureImage'
import cs from './culture_image_grid.scss'

export interface CultureImageGridProps {
  className?: string
  today: number
  culture: CultureViewModel
  datasets: CultureDatasetViewModel[]
}

const CultureImageGrid = ({
  today,
  culture,
  datasets,
  className,
}: CultureImageGridProps) => {
  const [dialogDataset, setDialogDataset] = useState<CultureDatasetViewModel | null>(
    null,
  )

  return (
    <>
      <CultureDatasetImagesDialog
        today={today}
        culture={culture}
        dataset={dialogDataset}
        isOpen={dialogDataset != null}
        onClose={() => {
          setDialogDataset(null)
        }}
      />
      <div className={cx(cs.cultureImageGrid, className)}>
        {datasets.map(dataset => {
          const thumbnail = dataset.thumbnails[0][0]
          const confluency = dataset.confluency

          return (
            <div
              key={dataset.id}
              className={cs.thumbnailContainer}
              onClick={() => {
                setDialogDataset(dataset)
              }}
            >
              <CultureImage className={cs.thumbnail} thumbnail={thumbnail} />
              <div className={cs.thumbnailCaption}>
                {confluency}% confluent &middot;{' '}
                {getRelativeTime(getDatasetCreatedAt(today, dataset))}
              </div>
            </div>
          )
        })}
      </div>
    </>
  )
}

export default CultureImageGrid
