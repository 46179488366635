/** A table for displaying many errors grouped by type.
 * Accordions are used for each error group.
 */

import cx from 'classnames'
import Accordion from '~/components/Accordion'
import cs from './grouped_error_table.scss'

export interface Error {
  displayElement: React.ReactNode
}

export interface ErrorGroup {
  errorType: string
  errorGroupHeader: string
  errors: Error[]
}

export interface ErrorTableProps {
  className?: string
  errorGroups: ErrorGroup[]
}

const GroupedErrorTable = ({ className, errorGroups }: ErrorTableProps) => {
  return (
    <div className={cx(className, cs.groupedErrorTable)}>
      {errorGroups.map(errorGroup => (
        <Accordion
          key={errorGroup.errorType}
          toggleable
          startOpen
          headerClassName={cs.errorGroupHeader}
          header={errorGroup.errorGroupHeader}
          type='error'
        >
          <div className={cs.errors}>
            {errorGroup.errors.map((error, index) => (
              <div key={index} className={cs.error}>
                {error.displayElement}
              </div>
            ))}
          </div>
        </Accordion>
      ))}
    </div>
  )
}

export default GroupedErrorTable
