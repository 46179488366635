import cx from 'classnames'

import ZoomInIcon from '~/components/icons/ZoomInIcon'
import cs from './culture_image.scss'

export interface CultureImageProps {
  className?: string
  thumbnail: string
}

const CultureImage = ({ className, thumbnail }: CultureImageProps) => {
  return (
    <div className={cx(className, cs.cultureImage)}>
      <div className={cs.cultureImageInner}>
        <div className={cs.overlay}>
          <div className={cs.overlayMessage}>
            <ZoomInIcon className={cs.icon} />
            View Image
          </div>
        </div>
        <img className={cs.thumbnail} src={thumbnail} />
      </div>
    </div>
  )
}

export default CultureImage
