import HelpPopover from '~/components/HelpPopover'
import BareWarningIcon from '~/components/icons/BareWarningIcon'
import cs from './future_routine_queue_fail_popover.scss'

const FutureRoutineQueueFailPopover = ({
  queueFailMessage,
}: {
  queueFailMessage: string
}) => {
  const renderPopover = () => {
    return (
      <div className={cs.popover}>
        {queueFailMessage}
        <div className={cs.info}>Will retry in 1 minute.</div>
      </div>
    )
  }

  const renderTrigger = () => (
    <div className={cs.trigger}>
      <BareWarningIcon className={cs.icon} />
      <div className={cs.text}>Failed to Queue</div>
    </div>
  )

  return (
    <div className={cs.futureRoutineQueueFailPopover}>
      <HelpPopover
        text={renderTrigger()}
        helpContent={renderPopover()}
        interactionKind='hover'
        smallText={false}
        className={cs.iconContainer}
      />
    </div>
  )
}

export default FutureRoutineQueueFailPopover
