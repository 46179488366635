import { ProcessItemWithTransferStationId } from '../StorageViz/ProcessItemWithTransferStationId.interface'

export const hasClickableTransferStation = (
  processItems: ProcessItemWithTransferStationId[],
  clickableStations?: string[],
  clickableOnItemOnly?: boolean,
  clickableOnNoItemOnly?: boolean,
) => {
  let hasClickableLocation = false

  processItems.forEach(processItemWithTransferStationId => {
    const { processItem, transferStationId } = processItemWithTransferStationId
    const isValid =
      (!clickableStations || clickableStations.includes(transferStationId)) &&
      ((processItem && clickableOnItemOnly) || (!processItem && clickableOnNoItemOnly))

    if (isValid) {
      hasClickableLocation = true
    }
  })

  return hasClickableLocation
}
