// TODO(mark): Fix confusing styling for tabPanels.
// Specifically the overflow: auto, flex-grow: 1 interactions.

import { Tab, Tabs } from '@blueprintjs/core'
import cx from 'classnames'
import PropTypes from 'prop-types'
import { useState } from 'react'

import cs from './tabs.scss'

interface TabType {
  key: string
  title: string
  panel?: JSX.Element
}

interface TabProps {
  tabs: TabType[]
  initialTab?: string
  selectedTabId?: string
  className?: string
  panelClassName?: string
  onTabChange?: (tab: string) => void
  onlyRenderWhenViewed?: boolean
  empty?: boolean
  nested?: boolean
}

const MyTabs = (props: TabProps) => {
  const [currentTab, setCurrentTab] = useState(props.initialTab)

  const handleTabChange = (currentTab: string) => {
    const { onTabChange } = props
    if (onTabChange) {
      onTabChange(currentTab)
    }
    setCurrentTab(currentTab)
  }

  const {
    tabs,
    className,
    panelClassName,
    onlyRenderWhenViewed,
    empty,
    selectedTabId,
    nested,
  } = props

  return (
    <Tabs
      onChange={handleTabChange}
      selectedTabId={selectedTabId === undefined ? currentTab : selectedTabId}
      className={cx(cs.tabs, className, empty && cs.empty, nested && cs.nested)}
      animate={false}
    >
      {tabs.map(tab => (
        <Tab
          key={tab.key}
          id={tab.key}
          title={tab.title}
          panel={
            !onlyRenderWhenViewed || currentTab === tab.key ? tab.panel : undefined
          }
          className={cs.tab}
          panelClassName={cx(cs.tabPanel, panelClassName)}
        />
      ))}
    </Tabs>
  )
}

MyTabs.propTypes = {
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      title: PropTypes.string,
      panel: PropTypes.node,
    }),
  ),
  initialTab: PropTypes.string,
  selectedTabId: PropTypes.string,
  className: PropTypes.string,
  panelClassName: PropTypes.string,
  onTabChange: PropTypes.func,
  onlyRenderWhenViewed: PropTypes.bool,
  empty: PropTypes.bool,
  nested: PropTypes.bool,
}

MyTabs.defaultProps = {
  initialTab: null,
}

export default MyTabs
