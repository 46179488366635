import { useState } from 'react'
import cs from './load_unload_items_body.scss'

import ButtonGroup from '~/components/buttons/ButtonGroup'
import { LoadUnloadItemsConfig } from './LoadUnloadItemsAction'
import LoadUnloadItemsBody from './LoadUnloadItemsBody'

const LOAD_UNLOAD_OPTIONS = [
  {
    value: 'load',
    label: 'Load',
  },
  {
    value: 'unload',
    label: 'Unload',
  },
]

export interface LoadUnloadItemsContainerProps {
  className?: string
  config: LoadUnloadItemsConfig
}

const LoadUnloadItemsContainer = ({ config }: LoadUnloadItemsContainerProps) => {
  const [loadOrUnload, setLoadOrUnload] = useState<'load' | 'unload'>('load')

  // TODO: this is pulling state up from the child components. Probably not the right pattern
  // but just doing this to get it working here.
  const [executingTask, setExecutingTask] = useState(false)

  return (
    <div className={cs.loadUnloadItemsBody}>
      <div className={cs.loadUnloadOptions}>
        <ButtonGroup
          choices={LOAD_UNLOAD_OPTIONS}
          onChange={value => setLoadOrUnload(value as 'load' | 'unload')}
          activeChoice={loadOrUnload}
          choiceClassName={cs.choice}
          disabled={executingTask}
        />
      </div>
      <LoadUnloadItemsBody
        className={cs.loadUnloadItemsBody}
        config={config}
        loadOrUnload={loadOrUnload}
        executingTask={executingTask}
        setExecutingTask={setExecutingTask}
      />
    </div>
  )
}

export default LoadUnloadItemsContainer
