import React, { useEffect, useState } from 'react'

import { Redirect, Route, Switch, useRouteMatch } from 'react-router'
import Toaster from '~/components/Toaster'
import demoConfigAPI from '~/pages/slasDemo/api/demoConfig'
import { isQuotaExceededError } from '~/utils/error'
import { DemoEvent } from '../events/DemoEvent'
import {
  EventState,
  INITIAL_EVENT_STATE,
  clearEvents,
  pollEvents,
  publishEvents,
} from '../events/EventState'
import { ViewModels } from '../events/ViewModels/ViewModels'
import { generateEventTime } from '../events/data/eventTimes'
import { generatePlateSeededEvents } from '../events/data/generate/generatePlateSeededEvents'
import {
  INITIAL_VIEW_MODELS,
  projectViewModels,
} from '../events/projections/projectViewModels'
import { CommandCenterEventContext } from './CommandCenterEventsContext'
import CulturePage from './CulturePage/CulturePage'
import { LiveCultures } from './LiveCultures/LiveCultures'
import { NeedsAttention } from './NeedsAttention/NeedsAttention'
import { PastCultures } from './PastCultures/PastCultures'
import { ReagentPerformance } from './ReagentPerformance/ReagentPerformance'
import { DemoConfig } from './SlasDemoConfigDialog/DemoConfig'
import cs from './command_center.scss'

type SetEventStateType = React.Dispatch<React.SetStateAction<EventState>>

interface CommandCenterProps {
  useLocalAssets: boolean
}

const CommandCenter = ({ useLocalAssets }: CommandCenterProps) => {
  const [eventState, setEventState] = useState<EventState | null>(null)
  const [config, setConfig] = useState<DemoConfig | null>(null)
  const { viewModels, resetViewModels } = useViewModels(
    eventState,
    config,
    useLocalAssets,
  )

  const fetchInitialState = () => {
    const newEventState = pollEvents(INITIAL_EVENT_STATE)
    setEventState(newEventState)
    console.log('Fetched initial state', newEventState)
  }
  const fetchConfig = () => {
    const config = demoConfigAPI.getDemoConfig()
    setConfig(config)
  }

  useEffect(() => {
    fetchInitialState()
    fetchConfig()
  }, [])

  if (!eventState || !viewModels || !config) {
    return null
  }

  return (
    <InnerCommandCenter
      config={config}
      eventState={eventState}
      setEventState={setEventState as SetEventStateType}
      viewModels={viewModels}
      resetViewModels={resetViewModels}
      onConfigUpdate={setConfig}
      useLocalAssets={useLocalAssets}
    />
  )
}

function useViewModels(
  eventState: EventState | null,
  // DemoConfig.profile is currently used when naming cultures in projectCultureViewModels.
  // It could make sense to include this information in a DemoEvent in the future.
  config: DemoConfig | null,
  useLocalAssets: boolean,
): {
  viewModels: ViewModels | null
  resetViewModels: () => void
} {
  // TODO: This seems to recompute when navigating pages. Depending on how well
  // optimized this is / how many events we have, it might cause the demo to feel
  // less responsive
  const [viewModels, setViewModels] = useState<ViewModels | null>(null)

  // TODO: This hook data flow might be a little funky

  useEffect(() => {
    if (eventState != null && config != null) {
      setViewModels(
        projectViewModels(
          eventState,
          config,
          viewModels ?? INITIAL_VIEW_MODELS,
          useLocalAssets,
        ),
      )
    }
  }, [eventState, config])

  function resetViewModels() {
    setViewModels(INITIAL_VIEW_MODELS)
  }

  console.log('New view models', viewModels)
  return { viewModels, resetViewModels }
}

const InnerCommandCenter = ({
  config,
  eventState,
  setEventState,
  viewModels,
  resetViewModels,
  onConfigUpdate,
  useLocalAssets,
}: {
  config: DemoConfig
  eventState: EventState
  setEventState: SetEventStateType
  viewModels: ViewModels
  resetViewModels: () => void
  onConfigUpdate: (newConfig: DemoConfig) => void
  useLocalAssets: boolean
}) => {
  const match = useRouteMatch()

  function onChildPublishEvents(newEvents: DemoEvent[]) {
    try {
      const newEventState = publishEvents(eventState, newEvents)
      setEventState(newEventState)
      console.log('New event state', newEventState)
    } catch (e) {
      if (isQuotaExceededError(e)) {
        Toaster.show({
          message:
            'Storage quota exceeded for events, further simulation will be invalid. Please' +
            ' reset the simulation and try again.',
          intent: 'danger',
        })
      }
    }
  }

  function onChildResetEventsTo(newEvents: DemoEvent[]) {
    const initialState = clearEvents()
    resetViewModels() // TODO: This is not the right way to do this
    try {
      const newEventState = publishEvents(initialState, newEvents)
      setEventState(newEventState)
      console.log('Event state reset to', newEvents)
    } catch (e) {
      if (isQuotaExceededError(e)) {
        Toaster.show({
          message:
            'Storage quota exceeded for when resetting events, further simulation will be' +
            ' invalid. This is unexpected. Please try a different reset option and contact' +
            ' monomer support.',
          intent: 'danger',
        })
      }
    }
  }

  // After the config has changed, reset to 10 culture plates
  const resetAfterConfigUpdate = (newConfig: DemoConfig) => {
    const checkInTime = generateEventTime({
      eventDay: 0,
      eventKind: 'PlateSeeded',
    })
    onChildResetEventsTo(generatePlateSeededEvents(10, 0, checkInTime, newConfig))
  }

  const onChildConfigUpdate = (newConfig: DemoConfig) => {
    onConfigUpdate(newConfig)
    resetAfterConfigUpdate(newConfig)
  }

  return (
    <CommandCenterEventContext.Provider
      value={{
        config,
        eventState,
        publishEvents: onChildPublishEvents,
        resetEventsTo: onChildResetEventsTo,
        onConfigUpdate: onChildConfigUpdate,
      }}
    >
      <div className={cs.commandCenter}>
        <Switch>
          <Route
            exact
            path={match.path}
            render={() => <Redirect to={`${match.path}/live-cultures`} />}
          />
          <Route
            exact
            path={`${match.path}/live-cultures`}
            render={() => (
              <LiveCultures viewModels={viewModels} useLocalAssets={useLocalAssets} />
            )}
          />
          <Route
            exact
            path={`${match.path}/past-cultures`}
            render={() => <PastCultures viewModels={viewModels} />}
          />
          <Route
            path={`${match.path}/culture/:cultureId`}
            render={({
              match: {
                params: { cultureId },
              },
            }) => (
              <CulturePage
                today={viewModels.today}
                culture={viewModels.cultures[cultureId]}
                allCultures={viewModels.cultures}
                mediaLots={viewModels.mediaLots}
                cultureProcessLogs={viewModels.cultureProcessLogs}
              />
            )}
          />
          <Route
            exact
            path={`${match.path}/reagent-performance/:mediaLotID`}
            render={({
              match: {
                params: { mediaLotID },
              },
            }) => (
              <ReagentPerformance mediaLotID={mediaLotID} viewModels={viewModels} />
            )}
          />
          <Route
            exact
            path={`${match.path}/needs-attention`}
            render={() => <NeedsAttention viewModels={viewModels} />}
          />
        </Switch>
      </div>
    </CommandCenterEventContext.Provider>
  )
}

export default CommandCenter
