import { simulatedEventTime } from '../data/eventTimes'
import { CultureDatasetViewModel, CultureViewModel } from './CultureViewModel'

export const COMPONENT_NAMES = ['mTeSR', 'KnockOut', 'Kanamycin']

export interface MediaComponent {
  componentName: string
  lotNumber: string
}

export interface ReagentMediaExchange {
  culture: CultureViewModel
  nextDayDataset: CultureDatasetViewModel
  onDay: number
}

export interface MediaLotViewModel {
  id: string
  name: string
  components: MediaComponent[]

  avgDoublingTime: number
  anomaliesDetected: number
  mediaExchanges: ReagentMediaExchange[]

  _warmedAt: string
  _isSecretlyContaminated: boolean
}

export type MediaLotViewModelMap = {
  [mediaLotID: string]: MediaLotViewModel
}

export function getMediaLotCreatedAt(
  today: number,
  mediaLot: MediaLotViewModel,
): string {
  return simulatedEventTime({ today, eventTime: mediaLot._warmedAt })
}
