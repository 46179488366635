import { BulkLoadCulturePlatesWizardData } from './BulkLoadCulturePlatesWizardData.interface'
import { getValidStepDataIfComplete as getValidUploadLiveWellsStepDataIfComplete } from './UploadLiveWellsStep'
import { getValidStepDataIfComplete as getValidUploadPlateDataStepDataIfComplete } from './UploadPlateDataStep'

export const isCurrentWizardStepComplete = (
  wizardData: BulkLoadCulturePlatesWizardData,
) => {
  if (wizardData.currentStep === 'uploadPlateData') {
    return getValidUploadPlateDataStepDataIfComplete(wizardData) !== null
  }
  if (wizardData.currentStep === 'uploadLiveWells') {
    return getValidUploadLiveWellsStepDataIfComplete(wizardData) !== null
  }
  if (wizardData.currentStep === 'confirmUpdates') {
    return true
  }

  return false
}
