import { compact, map, size, uniq } from 'lodash/fp'
import {
  LinkLotsLookupDataResponseCellLineLots,
  LinkLotsLookupDataResponseCellLines,
} from '~/api/operatorActions/linkCellLineLotsToCultures'
import {} from './LinkLotsPlatePreview'

export const getNumDistinctCellLines = (
  lookupDataResponse:
    | LinkLotsLookupDataResponseCellLineLots
    | LinkLotsLookupDataResponseCellLines,
): number => {
  if (lookupDataResponse.type !== 'cell_lines') return 0
  return size(uniq(compact(map('cell_line', lookupDataResponse.data))))
}

export const getNumDistinctCellLineLots = (
  lookupDataResponse:
    | LinkLotsLookupDataResponseCellLineLots
    | LinkLotsLookupDataResponseCellLines,
): number => {
  if (lookupDataResponse.type !== 'cell_line_lots') return 0
  return size(uniq(compact(map('cell_line_lot', lookupDataResponse.data))))
}
