import { includes } from 'lodash/fp'
import PropTypes from 'prop-types'
import { useContext, useState } from 'react'

import {
  supportedPlateFormats,
  supportedSize,
} from '~/components/TinyMicroplate.interface'
import { convertWellCoordsToWellName, getPlateDims } from '~/utils/microplate'

import TinyMicroplate, { supportedWellColor } from '../TinyMicroplate'

import WorkcellStatusContext from '~/pages/Workcell/WorkcellStatusContext'
import { getAllowFullColumnsOnly } from '~/pages/Workcell/utils/workcellStatus'
import { DragState } from './DragState.interface'
import { getNewSelectedWellArrayWithDragState } from './getNewSelectedWellArrayWithDragState'
import cs from './microplate_well_select.scss'

interface MicroplateWellSelectProps {
  className?: string
  selectedWellArray: string[]
  onSelectedWellArrayUpdate(selectedWellArray: string[]): void
  plateFormat: supportedPlateFormats
  size?: supportedSize
  showWellNames?: boolean
  label?: string
  disabled?: boolean
  mustPackRightSide?: boolean
}

const MicroplateWellSelect = ({
  className,
  selectedWellArray,
  onSelectedWellArrayUpdate,
  plateFormat,
  size,
  showWellNames,
  label,
  disabled,
  mustPackRightSide,
}: MicroplateWellSelectProps) => {
  const [dragState, setDragState] = useState<DragState | null>(null)
  const workcellStatus = useContext(WorkcellStatusContext)
  const allowFullColumnsOnly = getAllowFullColumnsOnly(workcellStatus)
  const getHighlights = () => {
    return [
      {
        color: 'accent' as supportedWellColor,
        fn: (row, col) =>
          includes(convertWellCoordsToWellName(row, col), selectedWellArray),
      },
    ]
  }

  const getSelectOptions = () => ({
    dims: getPlateDims(plateFormat),
    mustPackRightSide: mustPackRightSide || false,
    allowFullColumnsOnly,
  })

  const handleMouseDownCell = (row, col, { e }) => {
    // This prevents a drag event from being initiated, which can lead to undesirable effects,
    // such as the mouseUp event being omitted.
    // See https://stackoverflow.com/questions/50162290/mouseup-event-not-firing-when-dragging-an-image-in-chrome
    e.preventDefault()

    const wellName = convertWellCoordsToWellName(row, col)
    const isOn = includes(wellName, selectedWellArray)

    const _dragState = {
      toggleOn: !isOn,
      startRow: row,
      startCol: col,
      originalSelectedWellArray: selectedWellArray,
    }

    setDragState(_dragState)
    onSelectedWellArrayUpdate(
      getNewSelectedWellArrayWithDragState(_dragState, row, col, getSelectOptions()),
    )
  }

  const handleMouseOverCell = (row, col) => {
    if (dragState) {
      onSelectedWellArrayUpdate(
        getNewSelectedWellArrayWithDragState(dragState, row, col, getSelectOptions()),
      )
    }
  }

  const handleMouseUp = () => {
    setDragState(null)
  }

  const handleMouseLeave = () => {
    setDragState(null)
  }

  if (!plateFormat) {
    return null
  }

  return (
    <div className={className}>
      {label && <div className={cs.label}>{label}</div>}
      <TinyMicroplate
        plateFormat={plateFormat}
        size={size}
        showWellNames={showWellNames}
        highlights={getHighlights()}
        onMouseDownCell={handleMouseDownCell}
        onMouseUp={handleMouseUp}
        onMouseOverCell={handleMouseOverCell}
        onMouseLeave={handleMouseLeave}
        disabled={disabled}
      />
    </div>
  )
}

MicroplateWellSelect.propTypes = {
  className: PropTypes.string,
  selectedWellArray: PropTypes.arrayOf(PropTypes.string),
  onSelectedWellArrayUpdate: PropTypes.func,
  plateFormat: PropTypes.oneOf(['wells_6', 'wells_12', 'wells_24', 'wells_96']),
  size: PropTypes.oneOf(['medium', 'large', 'extraLarge']),
  showWellNames: PropTypes.bool,
  label: PropTypes.string,
}

export default MicroplateWellSelect
