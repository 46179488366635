import { find, sortBy } from 'lodash/fp'
import roboticArmAPI, { TeachToolSeqFile } from '~/api/desktop/drivers/roboticArm'
import Select, { SelectOption } from '~/components/Select'
import TextWithOverflow from '~/components/TextWithOverflow'
import MinimalButton from '~/components/buttons/MinimalButton'

export interface RoboticArmSequenceSelectProps {
  seqFileNames: string[]
  className?: string
  label: string
  selectedSequence: string | null
  onSequenceSelect: (
    selectedSequence: string | null,
    seqFile: TeachToolSeqFile | null,
  ) => void
  seqFile: TeachToolSeqFile | null
  triggerClassName?: string
  popoverClassName?: string
  disabled?: boolean
  allowNoneOption?: boolean
  instrumentName: string
  onViewSeqFile: (seqFile: TeachToolSeqFile) => void
}

const getSeqFileNameOptions = (seqFileNames: string[]) => {
  const sortedSeqFileNames = sortBy(seqFileName => seqFileName, seqFileNames)
  return sortedSeqFileNames.map(seqFileName => ({
    key: seqFileName,
    label: seqFileName,
  }))
}

// Allow searching for multiple strings like "regrip hotel" in order.
// This is really helpful here because the sequence names are so long.
const seqNameMatchesQuery = (selectOption: SelectOption, queryLowerCase: string) => {
  const queryWords = queryLowerCase.split(' ').filter(word => word.trim() !== '') // Remove empty strings
  let lastIndex = -1

  for (const word of queryWords) {
    const index = selectOption.label.toLowerCase().indexOf(word, lastIndex + 1)
    if (index === -1) {
      return false // Word not found in order
    }
    lastIndex = index
  }

  return true // All words found in order
}

const RoboticArmSequenceSelect = ({
  className,
  triggerClassName,
  popoverClassName,
  disabled,
  seqFileNames,
  label,
  selectedSequence,
  onSequenceSelect,
  instrumentName,
  seqFile,
  onViewSeqFile,
}: RoboticArmSequenceSelectProps) => {
  const seqFileNameOptions = getSeqFileNameOptions(seqFileNames)

  const fetchSeqFile = async (fileName: string) => {
    return roboticArmAPI.get_seq_file(instrumentName, fileName)
  }

  const handleChange = async (value: SelectOption) => {
    if (value.label === null) {
      onSequenceSelect(null, null)
    } else {
      const response = await fetchSeqFile(value.label)
      onSequenceSelect(value.label, response.seq_file)
    }
  }

  return (
    <div>
      <Select<SelectOption>
        items={seqFileNameOptions}
        placeholder='Select Sequence'
        label={label}
        itemKey='key'
        itemLabelKey='label'
        activeItem={find(['label', selectedSequence], seqFileNameOptions) || null}
        onChange={handleChange}
        triggerClassName={triggerClassName}
        popoverClassName={popoverClassName}
        itemRenderer={({ label }) => <TextWithOverflow text={label} />}
        className={className}
        disabled={disabled}
        filterable
        itemMatchesQuery={seqNameMatchesQuery}
        allowNoneOption
        noneOptionText='None'
      />
      {seqFile && (
        <MinimalButton
          label='View File'
          type='primary'
          onClick={() => onViewSeqFile(seqFile)}
        />
      )}
    </div>
  )
}

export default RoboticArmSequenceSelect
