import { useContext, useState } from 'react'

import cx from 'classnames'
import { WorkflowInstance } from '~/api/desktop/workflowInstances'
import { ProcessItem } from '~/common.interface'
import MinimalButton from '~/components/buttons/MinimalButton'
import AddIcon from '~/components/icons/AddIcon'
import BareCheckmarkIcon from '~/components/icons/BareCheckmarkIcon'
import LoadingIcon from '~/components/icons/LoadingIcon'
import WorkflowIcon from '~/components/icons/WorkflowsIcon'
import WorkcellStatusContext from '~/pages/Workcell/WorkcellStatusContext'
import { getIsWorkcellBusy } from '~/pages/Workcell/utils/workcellStatus'
import CreateWorkflowDialog from './CreateWorkflowDialog'
import TerminateWorkflowDialog from './TerminateWorkflowDialog'
import cs from './culture_plate_workflow.scss'

interface CulturePlateWorkflowProps {
  className?: string
  culturePlate: ProcessItem
  workflowInstance?: WorkflowInstance
  onEditSuccess: () => void
}

const CulturePlateWorkflow = ({
  culturePlate,
  workflowInstance,
  onEditSuccess,
}: CulturePlateWorkflowProps) => {
  if (!culturePlate) return null
  const workcellStatus = useContext(WorkcellStatusContext)
  const isWorkcellBusy = getIsWorkcellBusy(workcellStatus)
  const [showCreateDialog, setShowCreateDialog] = useState(false)
  const [showTerminateDialog, setShowTerminateDialog] = useState(false)

  const renderCreateWorkflowButton = () => {
    return (
      <MinimalButton
        label='Add a Workflow'
        className={cs.addWorkflowButton}
        type='primary'
        IconComponent={AddIcon}
        onClick={() => setShowCreateDialog(true)}
        disabled={isWorkcellBusy}
        popoverMessageIfDisabled='Cannot add workflow while workcell is busy'
      />
    )
  }

  const renderTerminateWorkflowButton = () => {
    if (workflowInstance?.status === 'completed') {
      return (
        <MinimalButton
          label='Remove Completed'
          className={cs.terminateWorkflowButton}
          type='primary'
          onClick={() => setShowTerminateDialog(true)}
          disabled={isWorkcellBusy}
          popoverMessageIfDisabled='Cannot remove workflow while workcell is busy'
        />
      )
    }

    return (
      <MinimalButton
        label='Terminate'
        className={cs.terminateWorkflowButton}
        type='danger'
        onClick={() => setShowTerminateDialog(true)}
        disabled={isWorkcellBusy}
        popoverMessageIfDisabled='Cannot terminate workflow while workcell is busy'
      />
    )
  }

  if (!culturePlate.workflowInstanceUuid) {
    return (
      <>
        <div className={cs.noWorkflowText}>
          <div className={cs.noWorkflow}>No workflow</div>
          {renderCreateWorkflowButton()}
        </div>
        <CreateWorkflowDialog
          culturePlate={culturePlate}
          isOpen={showCreateDialog}
          onClose={() => setShowCreateDialog(false)}
          onCreateSuccess={onEditSuccess}
        />
      </>
    )
  }

  if (!workflowInstance) {
    return <LoadingIcon className={cs.icon} />
  }

  return (
    <div className={cs.workflowText}>
      <div
        className={cx(cs.name, workflowInstance.status === 'completed' && cs.completed)}
      >
        {workflowInstance.status === 'completed' ? (
          <BareCheckmarkIcon className={cs.successIcon} />
        ) : (
          <WorkflowIcon className={cs.icon} />
        )}
        {workflowInstance.workflow_definition_id}
      </div>
      {renderTerminateWorkflowButton()}
      <TerminateWorkflowDialog
        culturePlate={culturePlate}
        workflowInstance={workflowInstance}
        isOpen={showTerminateDialog}
        onClose={() => setShowTerminateDialog(false)}
        onTerminateSuccess={onEditSuccess}
      />
    </div>
  )
}

export default CulturePlateWorkflow
