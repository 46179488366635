import { Instrument } from '~/common.interface'
import { SelectedTransferStation } from '~/pages/Workcell/components/TransferStationViz/TransferStationVizView'
import {
  ProcessItemUnloadParamsRequest,
  ReloadOperationRequest,
  TransferStationParamsRequest,
} from '../../reloadItems/convertChangeToReloadOperation'
import { LoadUnloadItemsProcessItemType } from '../LoadUnloadItemsProcessItemType.interface'

export const getUnloadTransferStationOperation = (
  instrument: Instrument,
  storageLocation: SelectedTransferStation,
  processItemType: LoadUnloadItemsProcessItemType,
  useArmAssist: boolean,
  removeFromCurrentWorkflow: boolean,
): ReloadOperationRequest => {
  const item = storageLocation.item

  if (!item) {
    throw Error(
      `Could not find valid item for selected transfer station${storageLocation.transferStationId}`,
    )
  }

  return {
    instrument_name: instrument.instrumentName,
    command: 'unload_transfer_station',
    command_params: {
      transfer_station_id: storageLocation.transferStationId,
    } as TransferStationParamsRequest,
    type: processItemType,
    process_item_params: {
      uuid: item.uuid,
      remove_from_current_workflow: removeFromCurrentWorkflow,
    } as ProcessItemUnloadParamsRequest,
    use_arm_assist: useArmAssist,
  }
}
