import { plateConverter } from './demoPlate'
import { timepointConverter } from './demoTimepoint'
import { wellConverter } from './demoWell'

const converters = {
  plates: plateConverter,
  wells: wellConverter,
  timepoints: timepointConverter,
}

export default converters
