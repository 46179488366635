/*
  This table is designed for displaying data with a lot of rows.
  This uses the Blueprint table component, which is quite opinionated.

  Prefer using the Table component over this one.
*/

import {
  Cell,
  Column,
  ColumnHeaderCell2,
  SelectionModes,
  Table2,
} from '@blueprintjs/table'
import cx from 'classnames'
import { constant, times } from 'lodash/fp'
import PropTypes from 'prop-types'
import React from 'react'
import TextWithOverflow from '~/components/TextWithOverflow'

import cs from './grid_table.scss'

interface GridTableColumn {
  name: string
  width: number | string
  render: (row: unknown[]) => React.ReactNode
  smallText?: boolean
  oneLine?: boolean
  omitCellPadding?: boolean
  rightAlign?: boolean
  showOnHover?: boolean
  verticalCenter?: boolean
}

interface GridTableProps {
  className?: string
  data: unknown[][]
  columns: GridTableColumn[]
}

const GridTable = ({ className, data, columns }: GridTableProps) => {
  const columnRendererFactory = column => {
    return rowIndex => {
      const row = data[rowIndex]
      return (
        <Cell className={cs.cell}>
          {column.oneLine ? (
            <TextWithOverflow
              text={column.render(row)}
              popoverContent={column.render(row)}
            />
          ) : (
            column.render(row)
          )}
        </Cell>
      )
    }
  }

  const columnHeaderCellRenderer = colIndex => {
    const column = columns[colIndex]
    return (
      <ColumnHeaderCell2
        className={cs.header}
        name={column.name}
        nameRenderer={name => <TextWithOverflow text={name} popoverContent={name} />}
      />
    )
  }

  if (data.length === 0) {
    return (
      <div className={cx(cs.gridTable, className)}>
        <div className={cs.noDataMessage}>No data to display</div>
      </div>
    )
  }

  return (
    <Table2
      numRows={data.length}
      className={cx(cs.gridTable, className)}
      selectionModes={SelectionModes.NONE}
      rowHeights={times(constant(40), data.length)}
      columnWidths={times(constant(150), columns.length)}
      enableColumnResizing={false}
      enableRowResizing={false}
      enableRowHeader={false}
    >
      {columns.map(column => (
        <Column
          name={column.name}
          cellRenderer={columnRendererFactory(column)}
          columnHeaderCellRenderer={columnHeaderCellRenderer}
        />
      ))}
    </Table2>
  )
}

GridTable.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      render: PropTypes.func,
      smallText: PropTypes.bool,
      oneLine: PropTypes.bool,
      omitCellPadding: PropTypes.bool,
      rightAlign: PropTypes.bool,
      showOnHover: PropTypes.bool,
      verticalCenter: PropTypes.bool,
    }),
  ),
  className: PropTypes.string,
  data: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.any)),
}

export default GridTable
