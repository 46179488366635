import { components } from '~/api/desktop/generated-schema'
import { client, unwrap } from '~/api/desktopAPIv2'

export type GenericScheduleRequest = components['schemas']['GenericScheduleRequest']

export default {
  genericSchedule: (requests: GenericScheduleRequest) =>
    unwrap(
      client().POST('/api/v2/operator-actions/generic-schedule/submit', {
        body: requests,
      }),
    ),

  checkConsumables: (requests: GenericScheduleRequest) =>
    unwrap(
      client().POST('/api/v2/operator-actions/generic-schedule/check-consumables', {
        body: requests,
      }),
    ),
}
