import cx from 'classnames'
import PropTypes from 'prop-types'
import React, { useState } from 'react'

import cs from './accordion.scss'
import RightArrowIcon from './icons/RightArrowIcon'

interface AccordionProps {
  className?: string
  header: string
  children: React.ReactNode
  toggleable?: boolean
  iconClassName?: string
  contentClassName?: string
  startOpen?: boolean
  headerClassName?: string
  type?: 'default' | 'error'
}

const Accordion = ({
  className,
  header,
  children,
  toggleable,
  iconClassName,
  headerClassName,
  contentClassName,
  startOpen,
  type = 'default',
}: AccordionProps) => {
  const [open, setOpen] = useState(startOpen || false)

  const onToggle = () => {
    setOpen(!open)
  }

  return (
    <div className={cx(cs.accordion, className, cs[type])}>
      <div
        className={cx(
          cs.header,
          toggleable && cs.toggleable,
          open && cs.open,
          headerClassName,
        )}
        onClick={onToggle}
      >
        {toggleable && (
          <div className={cs.toggleContainer}>
            <RightArrowIcon className={cx(cs.icon, open && cs.open, iconClassName)} />
          </div>
        )}
        {header}
      </div>
      {(open || !toggleable) && (
        <div className={cx(cs.content, contentClassName)}>{children}</div>
      )}
    </div>
  )
}

Accordion.propTypes = {
  className: PropTypes.string,
  headerClassName: PropTypes.string,
  iconClassName: PropTypes.string,
  toggleable: PropTypes.bool,
  contentClassName: PropTypes.string,
  header: PropTypes.node,
  children: PropTypes.node,
}

Accordion.defaultProps = {
  toggleable: true,
}

export default Accordion
