export const assets = [
  {
    path: '231221_091518_CD318_TnnT__20231221_091517',
    plate: 'CD318_TnnT',
    date: '2023-12-21',
    time: '9:15:18',
    datetime: '2023-12-21T09:15:18',
    daysSinceStart: 0,
    mediaType: 'image',
  },
  {
    path: '231222_074159_CD318_TnnT__20231222_074158',
    plate: 'CD318_TnnT',
    date: '2023-12-22',
    time: '7:41:59',
    datetime: '2023-12-22T07:41:59',
    daysSinceStart: 1,
    mediaType: 'image',
  },
  {
    path: '231222_100017_CD322_5uM__20231222_100017',
    plate: 'CD322_5uM',
    date: '2023-12-22',
    time: '10:00:17',
    datetime: '2023-12-22T10:00:17',
    daysSinceStart: 1,
    mediaType: 'image',
  },
  {
    path: '231223_112938_CD318_TnnT__20231223_112937',
    plate: 'CD318_TnnT',
    date: '2023-12-23',
    time: '11:29:38',
    datetime: '2023-12-23T11:29:38',
    daysSinceStart: 2,
    mediaType: 'image',
  },
  {
    path: '231223_115139_CD322_5uM__20231223_115139',
    plate: 'CD322_5uM',
    date: '2023-12-23',
    time: '11:51:39',
    datetime: '2023-12-23T11:51:39',
    daysSinceStart: 2,
    mediaType: 'image',
  },
  {
    path: '231224_092755_CD318_TnnT__20231224_092755',
    plate: 'CD318_TnnT',
    date: '2023-12-24',
    time: '9:27:55',
    datetime: '2023-12-24T09:27:55',
    daysSinceStart: 3,
    mediaType: 'image',
  },
  {
    path: '231224_094955_CD322_5uM__20231224_094955',
    plate: 'CD322_5uM',
    date: '2023-12-24',
    time: '9:49:55',
    datetime: '2023-12-24T09:49:55',
    daysSinceStart: 3,
    mediaType: 'image',
  },
  {
    path: '231225_123354_CD318_TnnT__20231225_123354',
    plate: 'CD318_TnnT',
    date: '2023-12-25',
    time: '12:33:54',
    datetime: '2023-12-25T12:33:54',
    daysSinceStart: 4,
    mediaType: 'image',
  },
  {
    path: '231225_125545_CD322_5uM__20231225_125545',
    plate: 'CD322_5uM',
    date: '2023-12-25',
    time: '12:55:45',
    datetime: '2023-12-25T12:55:45',
    daysSinceStart: 4,
    mediaType: 'image',
  },
  {
    path: '231226_122541_CD318_TnnT__20231226_122541',
    plate: 'CD318_TnnT',
    date: '2023-12-26',
    time: '12:25:41',
    datetime: '2023-12-26T12:25:41',
    daysSinceStart: 5,
    mediaType: 'image',
  },
  {
    path: '231226_124736_CD322_5uM__20231226_124736',
    plate: 'CD322_5uM',
    date: '2023-12-26',
    time: '12:47:36',
    datetime: '2023-12-26T12:47:36',
    daysSinceStart: 5,
    mediaType: 'image',
  },
  {
    path: '231227_102919_CD318_TnnT__20231227_102919',
    plate: 'CD318_TnnT',
    date: '2023-12-27',
    time: '10:29:19',
    datetime: '2023-12-27T10:29:19',
    daysSinceStart: 6,
    mediaType: 'image',
  },
  {
    path: '231227_105111_CD322_5uM__20231227_105111',
    plate: 'CD322_5uM',
    date: '2023-12-27',
    time: '10:51:11',
    datetime: '2023-12-27T10:51:11',
    daysSinceStart: 6,
    mediaType: 'image',
  },
  {
    path: '231228_104806_CD318_TnnT__20231228_104805',
    plate: 'CD318_TnnT',
    date: '2023-12-28',
    time: '10:48:06',
    datetime: '2023-12-28T10:48:06',
    daysSinceStart: 7,
    mediaType: 'image',
  },
  {
    path: '231228_111004_CD322_5uM__20231228_111004',
    plate: 'CD322_5uM',
    date: '2023-12-28',
    time: '11:10:04',
    datetime: '2023-12-28T11:10:04',
    daysSinceStart: 7,
    mediaType: 'image',
  },
  {
    path: '231229_095205_CD318_TnnT__20231229_095205',
    plate: 'CD318_TnnT',
    date: '2023-12-29',
    time: '9:52:05',
    datetime: '2023-12-29T09:52:05',
    daysSinceStart: 8,
    mediaType: 'image',
  },
  {
    path: '231229_100832_CD322_5uM__20231229_100832',
    plate: 'CD322_5uM',
    date: '2023-12-29',
    time: '10:08:32',
    datetime: '2023-12-29T10:08:32',
    daysSinceStart: 8,
    mediaType: 'image',
  },
  {
    path: '231230_092822_CD318_TnnT__20231230_092821',
    plate: 'CD318_TnnT',
    date: '2023-12-30',
    time: '9:28:22',
    datetime: '2023-12-30T09:28:22',
    daysSinceStart: 9,
    mediaType: 'image',
  },
  {
    path: '231230_095017_CD322_5uM__20231230_095016',
    plate: 'CD322_5uM',
    date: '2023-12-30',
    time: '9:50:17',
    datetime: '2023-12-30T09:50:17',
    daysSinceStart: 9,
    mediaType: 'image',
  },
  // {
  //   path: '231231_080951_CD318_TnnT__20231231_080951',
  //   plate: 'CD318_TnnT',
  //   date: '2023-12-31',
  //   time: '8:09:51',
  //   datetime: '2023-12-31T08:09:51',
  //   daysSinceStart: 10,
  //   mediaType: 'video',
  // },
  {
    path: '231231_090051_CD322_5uM__20231231_090050',
    plate: 'CD322_5uM',
    date: '2023-12-31',
    time: '9:00:51',
    datetime: '2023-12-31T09:00:51',
    daysSinceStart: 10,
    mediaType: 'image',
  },
  // {
  //   path: '240101_094918_CD322_5uM__20240101_094918',
  //   plate: 'CD322_5uM',
  //   date: '2024-01-01',
  //   time: '9:49:18',
  //   datetime: '2024-01-01T09:49:18',
  //   daysSinceStart: 11,
  //   mediaType: 'video',
  // },
  {
    path: '240101_101511_CD318_TnnT__20240101_101511',
    plate: 'CD318_TnnT',
    date: '2024-01-01',
    time: '10:15:11',
    datetime: '2024-01-01T10:15:11',
    daysSinceStart: 11,
    mediaType: 'image',
  },
  {
    path: '240102_090124_CD322_5uM__20240102_090123',
    plate: 'CD322_5uM',
    date: '2024-01-02',
    time: '9:01:24',
    datetime: '2024-01-02T09:01:24',
    daysSinceStart: 12,
    mediaType: 'image',
  },
  // {
  //   path: '240102_092710_CD318_TnnT__20240102_092710',
  //   plate: 'CD318_TnnT',
  //   date: '2024-01-02',
  //   time: '9:27:10',
  //   datetime: '2024-01-02T09:27:10',
  //   daysSinceStart: 12,
  //   mediaType: 'video',
  // },
  // {
  //   path: '240103_101724_CD322_5uM__20240103_101723',
  //   plate: 'CD322_5uM',
  //   date: '2024-01-03',
  //   time: '10:17:24',
  //   datetime: '2024-01-03T10:17:24',
  //   daysSinceStart: 13,
  //   mediaType: 'video',
  // },
  {
    path: '240103_105104_CD318_TnnT__20240103_105103',
    plate: 'CD318_TnnT',
    date: '2024-01-03',
    time: '10:51:04',
    datetime: '2024-01-03T10:51:04',
    daysSinceStart: 13,
    mediaType: 'image',
  },
  // {
  //   path: '240104_084439_CD318_TnnT__20240104_084439',
  //   plate: 'CD318_TnnT',
  //   date: '2024-01-04',
  //   time: '8:44:39',
  //   datetime: '2024-01-04T08:44:39',
  //   daysSinceStart: 14,
  //   mediaType: 'video',
  // },
  {
    path: '240104_133640_CD322_5uM__20240104_133640',
    plate: 'CD322_5uM',
    date: '2024-01-04',
    time: '13:36:40',
    datetime: '2024-01-04T13:36:40',
    daysSinceStart: 14,
    mediaType: 'image',
  },
  // {
  //   path: '240105_093405_CD322_5uM__20240105_093404',
  //   plate: 'CD322_5uM',
  //   date: '2024-01-05',
  //   time: '9:34:05',
  //   datetime: '2024-01-05T09:34:05',
  //   daysSinceStart: 15,
  //   mediaType: 'video',
  // },
  {
    path: '240105_100751_CD318_TnnT__20240105_100751',
    plate: 'CD318_TnnT',
    date: '2024-01-05',
    time: '10:07:51',
    datetime: '2024-01-05T10:07:51',
    daysSinceStart: 15,
    mediaType: 'image',
  },
  // {
  //   path: '240106_081908_CD318_TnnT__20240106_081908',
  //   plate: 'CD318_TnnT',
  //   date: '2024-01-06',
  //   time: '8:19:08',
  //   datetime: '2024-01-06T08:19:08',
  //   daysSinceStart: 16,
  //   mediaType: 'video',
  // },
  {
    path: '240106_093916_CD322_5uM__20240106_093915',
    plate: 'CD322_5uM',
    date: '2024-01-06',
    time: '9:39:16',
    datetime: '2024-01-06T09:39:16',
    daysSinceStart: 16,
    mediaType: 'image',
  },
  {
    path: '240107_090846_CD322_5uM__20240107_090846',
    plate: 'CD322_5uM',
    date: '2024-01-07',
    time: '9:08:46',
    datetime: '2024-01-07T09:08:46',
    daysSinceStart: 17,
    mediaType: 'video',
  },
  {
    path: '240107_094215_CD318_TnnT__20240107_094214',
    plate: 'CD318_TnnT',
    date: '2024-01-07',
    time: '9:42:15',
    datetime: '2024-01-07T09:42:15',
    daysSinceStart: 17,
    mediaType: 'image',
  },
  {
    path: '240108_121131_CD318_TnnT__20240108_121131',
    plate: 'CD318_TnnT',
    date: '2024-01-08',
    time: '12:11:31',
    datetime: '2024-01-08T12:11:31',
    daysSinceStart: 18,
    mediaType: 'image',
  },
  {
    path: '240108_123324_CD322_5uM__20240108_123324',
    plate: 'CD322_5uM',
    date: '2024-01-08',
    time: '12:33:24',
    datetime: '2024-01-08T12:33:24',
    daysSinceStart: 18,
    mediaType: 'image',
  },
  {
    path: '240109_094815_CD318_TnnT__20240109_094814',
    plate: 'CD318_TnnT',
    date: '2024-01-09',
    time: '9:48:15',
    datetime: '2024-01-09T09:48:15',
    daysSinceStart: 19,
    mediaType: 'image',
  },
  {
    path: '240109_101009_CD322_5uM__20240109_101009',
    plate: 'CD322_5uM',
    date: '2024-01-09',
    time: '10:10:09',
    datetime: '2024-01-09T10:10:09',
    daysSinceStart: 19,
    mediaType: 'image',
  },
  {
    path: '240110_082104_CD318_TnnT__20240110_082104',
    plate: 'CD318_TnnT',
    date: '2024-01-10',
    time: '8:21:04',
    datetime: '2024-01-10T08:21:04',
    daysSinceStart: 20,
    mediaType: 'image',
  },
  {
    path: '240110_084303_CD322_5uM__20240110_084302',
    plate: 'CD322_5uM',
    date: '2024-01-10',
    time: '8:43:03',
    datetime: '2024-01-10T08:43:03',
    daysSinceStart: 20,
    mediaType: 'image',
  },
]
