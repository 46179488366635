import { get, isArray, range } from 'lodash/fp'
import {
  StorageDimensions,
  StoragePartitions,
} from './StorageInstrumentConfig.interface'
import { getRegionForSlot } from './getRegionForSlot'

export const hasClickableStorageLocation = (
  dimensions: StorageDimensions,
  regions: StoragePartitions | undefined,
  items: (string | null)[][],
  clickablePartitions?: string[],
  clickableOnItemOnly?: boolean,
  clickableOnNoItemOnly?: boolean,
) => {
  let hasClickableLocation = false

  range(0, dimensions.numShelves).forEach(shelfIndex => {
    let { numLevels } = dimensions

    if (isArray(numLevels)) {
      numLevels = numLevels[shelfIndex]
    }

    range(0, numLevels).forEach(levelIndex => {
      const regionName = getRegionForSlot(regions, shelfIndex, levelIndex)
      const item = get([shelfIndex, levelIndex], items)

      const isValid =
        (!clickablePartitions || clickablePartitions.includes(regionName)) &&
        ((item && clickableOnItemOnly) || (!item && clickableOnNoItemOnly))

      if (isValid) {
        hasClickableLocation = true
      }
    })
  })

  return hasClickableLocation
}
