// This component displays navigation for multiple wizard steps, including a Next/Submit button.
// When the user clicks the button, onCurrentStepUpdate is called with the appropriate next step.
// When the user clicks a previous step, onCurrentStepUpdate is called with that step.

import cx from 'classnames'

import Button from '../buttons/Button'
import BareCheckmarkIcon from '../icons/BareCheckmarkIcon'
import cs from './wizard_stepper.scss'

import { WIZARD_FINAL_SUBMIT_STEP } from '~/components/Wizard/useWizardData'

export interface WizardStepperStep<TWizardStep extends string> {
  title: string
  step: TWizardStep
}

export interface WizardStepperProps<TWizardStep extends string> {
  className?: string
  steps: WizardStepperStep<TWizardStep>[]
  onCurrentStepUpdate: (step: TWizardStep | typeof WIZARD_FINAL_SUBMIT_STEP) => void
  currentStep: TWizardStep | typeof WIZARD_FINAL_SUBMIT_STEP
  isCurrentStepComplete: boolean
  // For the last step, the submit button label can be customized.
  // The default is "Submit"
  submitLabel?: string
}

const WizardStepper = <TWizardStep extends string>({
  className,
  steps,
  onCurrentStepUpdate: onCurrentStepUpdate,
  currentStep,
  isCurrentStepComplete,
  submitLabel = 'Submit',
}: WizardStepperProps<TWizardStep>) => {
  if (currentStep === WIZARD_FINAL_SUBMIT_STEP) {
    return null
  }

  const currentStepIndex = steps.findIndex(({ step }) => step === currentStep)

  return (
    <div className={cx(className, cs.wizardStepper)}>
      <div className={cs.steps}>
        {steps.map(({ title, step }, index) => (
          <>
            <div
              key={step}
              className={cx(cs.step, {
                [cs.upNext]: index > currentStepIndex,
                [cs.active]: index === currentStepIndex,
                [cs.complete]: index < currentStepIndex,
              })}
              onClick={
                index < currentStepIndex ? () => onCurrentStepUpdate(step) : undefined
              }
            >
              <div className={cs.stepIndex}>
                {index < currentStepIndex ? (
                  <BareCheckmarkIcon className={cs.checkmarkIcon} />
                ) : (
                  index + 1
                )}
              </div>
              <div className={cs.stepTitle}>{title}</div>
            </div>
            <div>
              {steps.length > 1 && index < steps.length - 1 && (
                <div className={cs.connector} />
              )}
            </div>
          </>
        ))}
      </div>
      <Button
        className={cs.nextButton}
        type='primary'
        label={currentStepIndex === steps.length - 1 ? submitLabel : 'Next'}
        onClick={
          currentStepIndex === steps.length - 1
            ? () => onCurrentStepUpdate(WIZARD_FINAL_SUBMIT_STEP)
            : () => onCurrentStepUpdate(steps[currentStepIndex + 1].step)
        }
        disabled={!isCurrentStepComplete}
      />
    </div>
  )
}

export default WizardStepper
