// Clicking on this checkbox currently does nothing, because of tagName='div'.
// If tagName='div' is removed, the tag will be <label>, which causes click handlers
// on parent elements to be called twice.
// For now, we put onClick handlers on parent wrapper <div> elements.

import { Checkbox } from '@blueprintjs/core'
import cx from 'classnames'
import PropTypes from 'prop-types'

import cs from './checkbox.scss'

export interface MonomerCheckboxProps {
  checked?: boolean
  className?: string
  disabled?: boolean
}

const MonomerCheckbox = ({ checked, className, disabled }) => (
  <Checkbox
    checked={checked}
    className={cx(cs.checkbox, className)}
    disabled={disabled}
    tagName='div'
  />
)

MonomerCheckbox.propTypes = {
  checked: PropTypes.bool,
  className: PropTypes.string,
  disabled: PropTypes.bool,
}

export default MonomerCheckbox
