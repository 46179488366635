import Pill from '~/components/Pill'
import Table, { TableColumn } from '~/components/Table'
import { CleBackLink, ClePageFrame, ClePageHeader } from '../CleDemoUI/ClePageFrame'
import ClePlateViz from '../ClePlate/ClePlateViz'
import DoublingRateHistogram from '../charts/DoublingRateHistogram'
import KoScoreHistogram from '../charts/KoScoreHistogram'
import { getDoublingRateValues, getKoScoreValues } from '../charts/hitpickingData'
import { CellLine, ClePlate, getClePlateType } from '../clePlates/ClePlate.interface'
import {
  CLE_PLATES,
  generateArchivedPlates,
} from '../clePlates/generateClePipelinePlates'
import cs from './cell_line.scss'

const CellLine = ({
  cellLineName,
  useLocalAssets,
}: {
  cellLineName: string

  useLocalAssets: boolean
}) => {
  const plates = CLE_PLATES.filter(plate => plate.cellLine.name === cellLineName)
  const cellLine = plates[0].cellLine

  return (
    <ClePageFrame>
      <ClePageHeader
        title={cellLineName}
        backLink={
          <CleBackLink text='Back to Pipeline' url='/cell-line-engineering/pipeline/' />
        }
      />
      <CellLineFields cellLine={cellLine} />
      <CellLinePlates
        livePlates={plates}
        archivedPlates={generateArchivedPlates(cellLine.cellLineIndex, plates[0].phase)}
        useLocalAssets={useLocalAssets}
      />
    </ClePageFrame>
  )
}

function CellLineFields({ cellLine }: { cellLine: CellLine }) {
  // TODO: Add Edit Type and SG-RNA
  return (
    <div className={cs.cellLineFields}>
      <div className={cs.cellLineField}>
        <div className={cs.fieldLabel}>Parent Cell Line</div>
        <div>{cellLine.parentCellLine}</div>
      </div>
      <div className={cs.cellLineField}>
        <div className={cs.fieldLabel}>Desired Edit</div>
        <div>{cellLine.desiredEdit}</div>
      </div>
    </div>
  )
}

type ClePlateRow = ClePlate & { isArchived: boolean }

function CellLinePlates({
  livePlates,
  archivedPlates,
  useLocalAssets,
}: {
  livePlates: ClePlate[]
  archivedPlates: ClePlate[]
  useLocalAssets: boolean
}) {
  // TODO: Should this be sorted by something?
  // TODO: Add statistics and status columns

  const plates: ClePlateRow[] = [
    ...livePlates.map(plate => ({ ...plate, isArchived: false })),
    ...archivedPlates.map(plate => ({ ...plate, isArchived: true })),
  ]

  const tableColumns: TableColumn<ClePlateRow>[] = [
    {
      name: 'Plate Preview',
      width: 200,
      render: (plate: ClePlateRow) => (
        <ClePlateViz plate={plate} useLocalAssets={useLocalAssets} />
      ),
    },
    {
      name: 'Plate',
      width: 250,
      render: (plate: ClePlateRow) => (
        <div>
          <div className={cs.rowPlateLabel}>{getClePlateType(plate)}</div>
        </div>
      ),
    },
    {
      name: 'Status',
      width: 200,
      render: (plate: ClePlateRow) => (
        <Pill
          type={plate.isArchived ? 'inactive' : 'accent'}
          label={plate.isArchived ? 'Archived' : 'Live'}
        />
      ),
    },
    {
      name: 'Statistics',
      width: 'flex',
      render: (plate: ClePlateRow) =>
        plate.phase === 'hitpicking' ? (
          <>
            <KoScoreHistogram
              koScoreValues={getKoScoreValues(Object.keys(plate.wells).length)}
            />
            <div className={cs.chartLabel}>Knockout Score</div>
          </>
        ) : (
          <>
            <DoublingRateHistogram
              doublingRateValues={getDoublingRateValues(
                Object.keys(plate.wells).length,
              )}
            />
            <div className={cs.chartLabel}>Doubling Rate (hrs)</div>
          </>
        ),
    },
  ]

  return (
    <div className={cs.cellLinePlates}>
      <div className={cs.cellLinePlatesHeader}>Associated Plates</div>
      <Table
        columns={tableColumns}
        data={plates}
        rowKey='id'
        rowPaddingVariant='rowPaddingLow'
        heightSizing='flexAuto'
        noDataMessage='No plates associated with this cell line'
      />
    </div>
  )
}

export default CellLine
