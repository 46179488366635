import cx from 'classnames'

import { FragmentType, gql, unmaskFragment } from '~/__generated__'
import {
  MontageHeroFragmentFragment,
  PlateDimensionsGraphQl,
} from '~/__generated__/graphql'
import { warmImageURL } from '~/utils/images'
import { MONTAGE_LAYOUT_ROWS_COLS, defaultMontageLayout } from '~/utils/montage'

import cs from './montage_hero.scss'

const fragment = gql(`
  fragment MontageHeroFragment on WellCultureGraphQL {
    id
    well
    observationHistory {
      timestamp
      montage {
        id
        culture { id }
        # This is an optimization only used for the 1x1 case, for now.
        images(positions: CENTER) {
          imageUrl(size: SIZE_1500)
          montageIndex
        }
      }
    }
    culturePlate {
      id
      plateDimensions {
        rows
        columns
      }
      plateObservationHistory {
        id
        timestamp
        sprite {
          id
          imageUrl(size: SIZE_600X600)
          montageLayout
          wellSequence
        }
      }
    }
  }
`)

type WellObservation = MontageHeroFragmentFragment['observationHistory'][number]
type PlateObservation =
  MontageHeroFragmentFragment['culturePlate']['plateObservationHistory'][number]

export default function MontageHero(props: {
  culture: FragmentType<typeof fragment>
  observationTimestamp?: string
  onClickImage?: (imageIndex: number) => void
}) {
  const culture = unmaskFragment(fragment, props.culture)

  const wellObservation = culture.observationHistory.find(
    obs => obs.timestamp === props.observationTimestamp,
  ) as WellObservation | undefined
  const plateObservation = culture.culturePlate.plateObservationHistory.find(
    obs => obs.timestamp === props.observationTimestamp,
  ) as PlateObservation | undefined

  // ["A1", "A6", "B4", ...] -> {A1: 0, A6: 1, B4: 2, ...}
  const spriteWellIndices = Object.fromEntries(
    plateObservation?.sprite.wellSequence.map((well, i) => [well, i]) ?? [],
  )
  // Index of this well in the current sprite. The same well may have
  // different indexes in different sprites.
  const spriteWellIndex = spriteWellIndices[culture.well] as number | undefined

  let montageLayout =
    plateObservation?.sprite.montageLayout ??
    // This default is needed for the case where the well hasn't been imaged
    // yet. We can revisit this if we change the design so that a non-imaged
    // well doesn't show a placeholder layout.
    defaultMontageLayout(culture.culturePlate.plateDimensions)
  const [montageRows, montageColumns] = MONTAGE_LAYOUT_ROWS_COLS[montageLayout!]
  const numSitesInMontageLayout = montageRows * montageColumns

  const plateSpriteURL = plateObservation
    ? warmImageURL(plateObservation.sprite.imageUrl)
    : null
  const singleImageURL = wellObservation?.montage?.images[0]?.imageUrl
    ? warmImageURL(wellObservation.montage.images[0].imageUrl)
    : null

  return (
    <div
      className={cs.container}
      style={{
        // --sprite-num-wells is the number of wells captured in the sprite.
        // This is not always the number of wells or cultures on the plate, and
        // it can vary from one imaging run to another.
        ['--sprite-num-wells' as string]: plateObservation?.sprite.wellSequence.length,
        ['--montage-rows' as string]: montageRows,
        ['--montage-columns' as string]: montageColumns,
        ['--num-sites-per-montage' as string]: numSitesInMontageLayout,
        ['--sprite-url-2x' as string]: plateSpriteURL
          ? `url("${plateSpriteURL}")`
          : null,
      }}
    >
      <div className={cs.well}>
        <div
          className={cx(cs.layer, cs.imagery, {
            [cs.notImaged]: spriteWellIndex == null,
            [cs.singleImage]: numSitesInMontageLayout === 1,
          })}
          style={{
            ['--sprite-well-index' as string]: spriteWellIndex,
            ['--single-image-url' as string]: singleImageURL
              ? `url("${singleImageURL}")`
              : null,
          }}
        >
          {new Array(numSitesInMontageLayout).fill(null).map((_, site) => {
            return (
              <div
                key={site}
                className={cs.site}
                style={{ ['--site-index' as string]: site }}
              >
                {/* TODO(SWE-1337): Link to a dedicated view of a single image. */}
                {props.onClickImage && numSitesInMontageLayout > 1 ? (
                  // biome-ignore lint/a11y/useAnchorContent: We use aria-label here instead.
                  <a
                    className={cs.linkToImage}
                    draggable={false}
                    onClick={() => {
                      props.onClickImage!(site)
                    }}
                    aria-label='Open larger image'
                  />
                ) : null}
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export function MontageHeroLoadingPlaceholder({
  plateDims,
}: { plateDims?: PlateDimensionsGraphQl }) {
  const [montageRows, montageColumns] =
    MONTAGE_LAYOUT_ROWS_COLS[defaultMontageLayout(plateDims)]
  const numSitesInMontageLayout = montageRows * montageColumns

  return (
    <div
      className={cs.container}
      style={{
        ['--montage-rows' as string]: montageRows,
        ['--montage-columns' as string]: montageColumns,
        ['--num-sites-per-montage' as string]: numSitesInMontageLayout,
      }}
    >
      <div className={cs.well}>
        <div className={cx(cs.layer, cs.imagery, cs.notImaged)}>
          {new Array(numSitesInMontageLayout).fill(null).map((_, site) => {
            return (
              <div
                key={site}
                className={cs.site}
                style={{ ['--site-index' as string]: site }}
              ></div>
            )
          })}
        </div>
      </div>
    </div>
  )
}
