import { client, unwrap } from '~/api/desktopAPIv2'
import { components } from './generated-schema'

export type WorkflowDefinition = components['schemas']['WorkflowDefinition']
export type WorkflowDefinitionEditableFields =
  components['schemas']['WorkflowDefinitionEditableFields']
export type NodeWithExecutionTime = components['schemas']['NodeWithExecutionTime']

export type GetWorkflowDefinitionResponse =
  components['schemas']['GetWorkflowDefinitionResponse']

export type WorkflowDefinitionSchemaResponse =
  components['schemas']['WorkflowDefinitionSchemaResponse']
export type CreateWorkflowDefinitionResponse =
  components['schemas']['CreateWorkflowDefinitionResponse']
export type DeleteWorkflowDefinitionResponse =
  components['schemas']['DeleteWorkflowDefinitionResponse']
export type UpdateWorkflowDefinitionResponse =
  components['schemas']['UpdateWorkflowDefinitionResponse']
export type WorkflowDefinitionFileResponse =
  components['schemas']['WorkflowDefinitionFileResponse']
export type WorkflowDefinitionAllNodesScheduledResponse =
  components['schemas']['WorkflowDefinitionAllNodesScheduledResponse']
export type WorkflowDefinitionValidateResponse =
  components['schemas']['WorkflowDefinitionValidateResponse']
export default {
  list: (): Promise<GetWorkflowDefinitionResponse> =>
    unwrap(client().GET('/api/v2/workflows/workflow-definitions/list', {})),
  schema: (): Promise<WorkflowDefinitionSchemaResponse> =>
    unwrap(client().GET('/api/v2/workflows/workflow-definitions/schema', {})),
  create: (
    def: WorkflowDefinitionEditableFields,
  ): Promise<CreateWorkflowDefinitionResponse> =>
    unwrap(
      client().POST('/api/v2/workflows/workflow-definitions/create', {
        body: {
          workflow_definition_fields: def,
        },
      }),
    ),
  update: (
    id: number,
    def: WorkflowDefinitionEditableFields,
  ): Promise<UpdateWorkflowDefinitionResponse> =>
    unwrap(
      client().POST('/api/v2/workflows/workflow-definitions/update/{id}', {
        params: {
          path: { id: id.toString() },
        },
        body: {
          workflow_definition_fields: def,
        },
      }),
    ),
  file: (id: number): Promise<WorkflowDefinitionFileResponse> =>
    unwrap(
      client().GET('/api/v2/workflows/workflow-definitions/file/{id}', {
        params: {
          path: { id: id.toString() },
        },
      }),
    ),
  allNodesScheduled: (
    id: number,
  ): Promise<WorkflowDefinitionAllNodesScheduledResponse> =>
    unwrap(
      client().GET('/api/v2/workflows/workflow-definitions/all-nodes-scheduled/{id}', {
        params: {
          path: { id: id.toString() },
        },
      }),
    ),
  delete: (id: number): Promise<DeleteWorkflowDefinitionResponse> =>
    unwrap(
      client().DELETE('/api/v2/workflows/workflow-definitions/delete/{id}', {
        params: {
          path: { id: id.toString() },
        },
      }),
    ),
  validateWorkflowDefinition: (
    id: number,
  ): Promise<WorkflowDefinitionValidateResponse> =>
    unwrap(
      client().GET('/api/v2/workflows/workflow-definitions/validate/{id}', {
        params: {
          path: { id: id.toString() },
        },
      }),
    ),
}
