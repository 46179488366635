import { flatten, fromPairs, times } from 'lodash'
import { supportedPlateFormats } from '~/components/TinyMicroplate.interface'
import { DemoConfig } from '~/pages/slasDemo/CommandCenter/SlasDemoConfigDialog/DemoConfig'
import { getWellMatrix } from '~/utils/microplate'
import { PlateSeeded } from '../../DemoEvent'
import { makeUniqueID } from '../makeUniqueID'
import { NUM_DATASETS } from './generateDataset'
import { generateExperimentLabelsForCellLine } from './generateExperimentLabelsForCellLine'
import { generatePlateName } from './generatePlateName'

// Sample of real cell lines
// ACS-1021 - ATCC iPS cell line
// ACS-1024 - ATCC iPS cell line
// KOLF2.1J - Jackson Lab iPS cell line
// KOLF2.1S - Sanger Institute iPS cell line
const CELL_LINES = ['ACS-1021', 'ACS-1024', 'KOLF2.1J', 'KOLF2.1S']

export function generatePlateSeededEvents(
  plateCount: number,
  day: number,
  at: string,
  // DemoConfig.profile is used when generating the plate name.
  config: DemoConfig,
  plateFormat: supportedPlateFormats = 'wells_6',
): PlateSeeded[] {
  return times(plateCount, j =>
    makePlateSeededEvent({
      at,
      day,
      plateName: generatePlateName(j + 1, config),
      cellLine: CELL_LINES[0],
      experimentLabel: generateExperimentLabelsForCellLine(CELL_LINES[0], config)[0],
      plateFormat,
    }),
  )
}

export function generate100PlateSeededEvents(
  day: number,
  at: string,
  // DemoConfig.profile is used when generating the plate name.
  config: DemoConfig,
): PlateSeeded[] {
  return CELL_LINES.flatMap((cellLine, i) =>
    generateExperimentLabelsForCellLine(cellLine, config).map((experimentLabel, j) =>
      makePlateSeededEvent({
        at,
        day,
        plateName: generatePlateName(i * 25 + j + 1, config),
        cellLine,
        experimentLabel,
        plateFormat: 'wells_6',
      }),
    ),
  )
}

export function makePlateSeededEvent({
  at,
  day,
  plateName,
  cellLine,
  experimentLabel,
  plateFormat,
}: {
  at: string
  day: number
  plateName: string
  cellLine: string
  experimentLabel: string
  plateFormat: supportedPlateFormats
}): PlateSeeded {
  const plateWells = flatten(getWellMatrix(plateFormat))

  return {
    kind: 'PlateSeeded',
    at,
    day,
    plateFormat: plateFormat,
    plateID: makeUniqueID('PLATE'),
    plateName,

    experimentLabel,
    cellLine,

    wellNameToCulture: fromPairs(
      plateWells.map((well, index) => [
        well,
        {
          id: makeUniqueID('CULTURE'),
          datasetIndex: index % NUM_DATASETS,
        },
      ]),
    ),
  }
}
