import { useEffect, useState } from 'react'
import Dialog from '~/components/Dialog'

import culturePlateWorkflowAPI, {
  WorkflowInstance,
} from '~/api/desktop/culturePlateWorkflow'
import workflowInstancesAPI from '~/api/desktop/workflowInstances'
import Toaster from '~/components/Toaster'
import cs from './terminate_workflow_dialog.scss'

interface TerminateWorkflowDialogProps {
  culturePlate?: {
    uuid: string
  }
  workflowInstance?: WorkflowInstance
  isOpen: boolean
  onClose: () => void
  onTerminateSuccess: () => void
}

const TerminateWorkflowDialog = ({
  culturePlate,
  workflowInstance,
  isOpen,
  onTerminateSuccess: onTerminateSuccess,
  onClose,
}: TerminateWorkflowDialogProps) => {
  const [isWorking, setIsWorking] = useState(false)
  const [isInstanceCancellable, setIsInstanceCancellable] = useState(false)
  const [instanceNotCancellableReason, setInstanceNotCancellableReason] = useState('')
  const [delayedIsOpen, setDelayedIsOpen] = useState(false)

  useEffect(() => {
    // If the workflow instance is completed, skip the dialog and immediately terminate the workflow.
    if (isOpen) {
      if (!workflowInstance) return
      if (workflowInstance.status === 'completed') {
        handleTerminate()
        onClose()
      } else {
        workflowInstancesAPI
          .checkIfCancellable(workflowInstance.uuid)
          .then(checkCancellableResponse => {
            setIsInstanceCancellable(checkCancellableResponse.is_cancellable)
            setInstanceNotCancellableReason(checkCancellableResponse.reason)
            setDelayedIsOpen(true)
          })
      }
    } else {
      setDelayedIsOpen(false)
      setIsInstanceCancellable(false)
      setInstanceNotCancellableReason('')
    }
  }, [isOpen, workflowInstance])

  const handleTerminate = async () => {
    if (!culturePlate || !workflowInstance) return
    setIsWorking(true)
    const terminatdOrRemoveStr =
      workflowInstance.status === 'completed' ? 'remove' : 'terminate'
    try {
      await culturePlateWorkflowAPI.removeCulturePlateFromCurrentWorkflow(
        culturePlate.uuid,
      )

      onClose()
      setIsWorking(false)
      onTerminateSuccess()
      Toaster.show({
        message: `Successfully ${terminatdOrRemoveStr}d ${workflowInstance.workflow_definition_id} for ${culturePlate.uuid}`,
        intent: 'success',
      })
    } catch (e) {
      onClose()
      setIsWorking(false)
      Toaster.show({
        message: `Failed to ${terminatdOrRemoveStr} ${workflowInstance.workflow_definition_id} for ${culturePlate.uuid}`,
        intent: 'danger',
      })
    }
  }

  const renderContents = () => {
    if (!culturePlate || !workflowInstance) return null
    if (isInstanceCancellable) {
      return (
        <div className={cs.contents}>
          Are you sure you want to terminate{' '}
          <b>{workflowInstance.workflow_definition_id}</b> for{' '}
          <b>{culturePlate.uuid}</b>?
        </div>
      )
    } else {
      return (
        <div className={cs.contents}>
          <b>{workflowInstance.workflow_definition_id}</b> for{' '}
          <b>{culturePlate.uuid}</b> cannot be terminated because:{' '}
          {instanceNotCancellableReason}
        </div>
      )
    }
  }

  return (
    <Dialog
      isOpen={delayedIsOpen}
      onClose={onClose}
      className={cs.terminateWorkflowDialog}
    >
      {renderContents()}
      <Dialog.Footer loadingMessage={isWorking ? 'Terminating Workflow' : undefined}>
        <Dialog.FooterButton label='Cancel' onClick={onClose} />
        {isInstanceCancellable ? (
          <Dialog.FooterButton
            type='danger'
            label='Terminate Workflow'
            onClick={handleTerminate}
          />
        ) : (
          <></>
        )}
        <div className={cs.fill} />
      </Dialog.Footer>
    </Dialog>
  )
}

export default TerminateWorkflowDialog
