import converters from './converters/converters'

/** A union of identifiers for each kind of demo data record. */
type Kinds = keyof typeof converters

/** An array of identifiers for each kind of demo data record. */
const KINDS = Object.keys(converters) as (keyof typeof converters)[]

/**
 * The persisted form of a demo object, such as a single plate or a single well.
 * This is directly authored by sales, and converted to a derived record at
 * runtime.
 */
export type DemoSourceRecord = Record<string, string>

/**
 * Normalized source data for all demo object types. Keep it small and backwards
 * compatible.
 *
 * This is persisted not by itself, but in combination with other demo UI state
 * as defined in `DemoContext.DemoSourceState`.
 */
export type DemoSourceRecordsByKind = {
  [K in Kinds]?: DemoSourceRecordOfKind<K>[]
}

/**
 * The converted form of a demo object, ready for use in the product UI.
 *
 * For simplicity of converter implementation, a derived record is allowed to
 * contain cyclic references, unlike a real GraphQL result.
 */
export type DemoDerivedRecord = Record<string, unknown>

export type DemoDerivedRecordsByKind = {
  [K in Kinds]: DemoDerivedRecordOfKind<K>[]
}

export type DemoErrorsByKind = {
  [K in Kinds]?: string[]
}

/**
 * Prepares and normalizes the full set of demo data by invoking all converters.
 */
export function prepareDemoData(sources: DemoSourceRecordsByKind): {
  data: DemoDerivedRecordsByKind
  errors: DemoErrorsByKind
} {
  const partials = Object.fromEntries(
    KINDS.map(kind => [
      kind,
      Object.fromEntries(
        (sources[kind] ?? [])
          .map(p => converters[kind].convert(p, sources))
          .map(({ partialItem, lookupKey }) => [lookupKey, partialItem]),
      ),
    ]),
  ) as unknown as { [Kind in Kinds]: PartialDemoDerivedRecordOfKind<Kind> }

  function lookup<K extends Kinds>(
    kind: K,
    key: string,
  ): DemoDerivedRecordOfKind<K> | null {
    return (partials[kind][key] ?? null) as DemoDerivedRecordOfKind<K> | null
  }

  const errors: DemoErrorsByKind = {}
  for (const kind of KINDS) {
    for (const item of Object.values(partials[kind])) {
      try {
        converters[kind].link(item, { ...item }, lookup)
      } catch (e) {
        if (e instanceof LinkingError) {
          errors[kind] = (errors[kind] ?? []).concat([e.message])
        } else {
          throw e
        }
      }
    }
  }

  const derived = partials as unknown as {
    [Kind in Kinds]: DemoDerivedRecordOfKind<Kind>
  }

  return {
    data: Object.fromEntries(
      KINDS.map(kind => [kind, Object.values(derived[kind])]),
    ) as unknown as DemoDerivedRecordsByKind,
    errors,
  }
}

/**
 * Defines how a demo object is converted between TSV, plain object, and GraphQL
 * representations.
 */
export interface DemoObjectConverter<
  S extends DemoSourceRecord,
  P,
  D extends DemoDerivedRecord,
> {
  /**
   * An array that defines the order of columns in the TSV. Avoid changing the
   * order after sales has started using them.
   */
  tsvColumns: Array<keyof S>

  /**
   * Called before source data is converted. Throws an error to alert the demo
   * creator and prevent persisting the erroneous data.
   */
  validate(rows: S[]): void

  /**
   * Converts a source record to its corresponding derived record, but without
   * including circular references yet. This is a one-way process.
   */
  convert(
    sourceItem: S,
    others: DemoSourceRecordsByKind,
  ): { partialItem: P; lookupKey: string }

  /**
   * Populates references in a derived record with other derived records,
   * possibly creating cycles. This is called after we've obtained all partial
   * records of all types.
   *
   * Note: Calling this method on only one record might NOT correctly convert
   * that record. A given partial record will only satisfy its derived type
   * after *all* records are linked, not necessarily after *it* is linked,
   * because derived records can rely on circular references to satisfy their
   * types.
   */
  link(
    /** The record whose references should be populated. */
    derived: D,

    /** A shallow copy of the partial record, for convenience. */
    partial: P,

    /**
     * Returns the derived record with the given key, or null if the demo author
     * hasn't provided it.
     */
    lookup: <K extends keyof DemoSourceRecordsByKind>(
      kind: K,
      key: string,
    ) => DemoDerivedRecordOfKind<K> | null,
  ): void
}

/** An error that indicates the input data cannot be correctly linked. */
export class LinkingError extends Error {}

/** Utility type for looking up a converter type. */
export type ConverterOfKind<K extends Kinds> = (typeof converters)[K]

/** Utility type for looking up a source record type. */
export type DemoSourceRecordOfKind<K extends Kinds> =
  ConverterOfKind<K> extends DemoObjectConverter<infer S, any, any> ? S : never

/** Utility type for looking up a partial derived record type. */
export type PartialDemoDerivedRecordOfKind<K extends Kinds> =
  ConverterOfKind<K> extends DemoObjectConverter<any, infer P, any> ? P : never

/** Utility type for looking up a partial derived record type. */
export type DemoDerivedRecordOfKind<K extends Kinds> =
  ConverterOfKind<K> extends DemoObjectConverter<any, any, infer D> ? D : never

export function demoObjectsToTable<
  S extends DemoSourceRecord,
  P,
  D extends DemoDerivedRecord,
>(converter: DemoObjectConverter<S, P, D>, items: S[]): string[][] {
  return [
    converter.tsvColumns as string[],
    ...items.map(item => converter.tsvColumns.map(k => item[k])),
  ]
}

export function tableToDemoObjects<
  S extends DemoSourceRecord,
  P,
  D extends DemoDerivedRecord,
>(converter: DemoObjectConverter<S, P, D>, tsv: string[][]): S[] {
  const wantHeaders = converter.tsvColumns.join(' ')
  const haveHeaders = tsv[0].join(' ')
  if (haveHeaders !== wantHeaders) {
    throw new Error(
      `Column headers are incorrect: have '${haveHeaders}', want '${wantHeaders}'`,
    )
  }
  const rows = tsv.slice(1)
  const items = rows
    .filter(row => row.some(cell => cell != '' && cell != null))
    .map(row =>
      Object.fromEntries(row.map((cell, i) => [converter.tsvColumns[i], cell])),
    ) as S[]
  converter.validate(items)
  return items
}
