import { sortBy } from 'lodash/fp'
import { useEffect, useState } from 'react'
import roboticArmAPI, {
  NestResponse,
  SuccessResponse,
} from '~/api/desktop/drivers/roboticArm'
import { components } from '~/api/desktop/generated-schema'
import HelpPopover from '~/components/HelpPopover'
import Select from '~/components/Select'
import Button from '~/components/buttons/Button'
import { displayCount, splitAndRound } from '~/utils/string'
import cs from './nest_controls.scss'

interface NestControlsProps {
  instrumentName: string
  isTeachToolBusy: boolean
  teachToolBusyWrapper: (func: () => Promise<SuccessResponse | void>) => void
  currentCartesianLoc: string | undefined
  onLocationUpdated: () => void
  reloadKey?: string
}

type Nest = components['schemas']['Nest'] & {
  name: string
}

const NestControls = ({
  instrumentName,
  isTeachToolBusy,
  teachToolBusyWrapper,
  currentCartesianLoc,
  onLocationUpdated,
  reloadKey,
}: NestControlsProps) => {
  const [nests, setNests] = useState<Nest[]>([])
  const [selectedNest, setSelectedNest] = useState<Nest>()

  const parseNestResponse = (res: NestResponse): Nest[] => {
    const nests = Object.keys(res.nests).map(key => {
      const nestValue = res.nests[key]
      if (nestValue) {
        return {
          name: key,
          approach_path: nestValue.approach_path,
          location: nestValue.location,
          seq_file_path: nestValue.seq_file_path,
        }
      } else throw new Error('Nest location not found')
    })

    return sortBy('name', nests)
  }

  useEffect(() => {
    roboticArmAPI.canests(instrumentName).then(res => {
      setNests(parseNestResponse(res))
    })
  }, [reloadKey])

  const itemMatchesQuery = (nest, queryLowerCase) =>
    nest.name.toLowerCase().includes(queryLowerCase)

  const nestSelect = (
    <>
      <div className={cs.controlLabel}>Nests</div>
      <Select<Nest>
        placeholder={'Select Nest'}
        items={nests}
        itemKey={'name'}
        itemLabelKey={'name'}
        activeItem={selectedNest || null}
        onChange={setSelectedNest}
        className={cs.select}
        triggerClassName={cs.trigger}
        popoverClassName={cs.popover}
        filterable
        itemMatchesQuery={itemMatchesQuery}
      />
    </>
  )
  const [highlightText, setHighlightText] = useState<boolean>(false)

  const renderApproachPathPopover = () => {
    if (selectedNest === undefined) return undefined

    if (!selectedNest?.approach_path) return undefined
    const popoverContent = (
      <div className={cs.nestApproachPathPopover}>
        {selectedNest.approach_path.map(point => (
          <div>{splitAndRound(point || '', ' ')}</div>
        ))}
      </div>
    )

    if (selectedNest.approach_path.length === 0) {
      return <div className={cs.noNestApproachPath}>No Approach Path Set</div>
    }

    return (
      selectedNest.approach_path.length > 0 && (
        <HelpPopover
          helpContent={popoverContent}
          text={`View Approach Path (${displayCount(
            'point',
            selectedNest.approach_path.length,
          )})`}
          interactionKind='hover'
          placement='top'
          elementType='div'
          className={cs.nestApproachPath}
        />
      )
    )
  }

  const nestActionButtons = (nest: Nest) => {
    return (
      <div className={cs.nestButtons}>
        <Button
          label='Approach Nest'
          onClick={async () => {
            await teachToolBusyWrapper(() =>
              roboticArmAPI
                .anest(instrumentName, nest.name)
                .catch(e => alert(`Failed to approach nest: ${e.message}`)),
            )
            onLocationUpdated()
          }}
          disabled={isTeachToolBusy}
          className={cs.button}
        />
        <Button
          label='Leave Nest'
          onClick={async () => {
            await teachToolBusyWrapper(() =>
              roboticArmAPI
                .lnest(instrumentName, nest.name)
                .catch(e => alert(`Failed to leave nest: ${e.message}`)),
            )
            onLocationUpdated()
          }}
          disabled={isTeachToolBusy}
          className={cs.button}
        />
        <Button
          label='Save Current Location'
          onClick={async () => {
            await teachToolBusyWrapper(() =>
              roboticArmAPI
                .wnest_locc(instrumentName, nest.name)
                .then(() => {
                  // async fetch nests to update locations
                  roboticArmAPI.canests(instrumentName).then(res => {
                    setNests(parseNestResponse(res))
                  })
                  setSelectedNest({
                    ...nest,
                    location: { loc: currentCartesianLoc || '', loc_type: 'c' },
                  })
                  setTimeout(() => {
                    setHighlightText(true)
                    setTimeout(() => {
                      setHighlightText(false)
                    }, 500)
                  })
                })
                .catch(e => alert(`Failed to save nest location: ${e.message}`)),
            )
          }}
          type='primary'
          disabled={isTeachToolBusy}
        />
      </div>
    )
  }

  return (
    <div className={cs.nestControls}>
      {nestSelect}
      {selectedNest ? (
        <div className={cs.selectedNest}>
          <HelpPopover
            helpContent={
              <div className={cs.seqFilePathPopover}>
                {selectedNest.seq_file_path || 'Unknown file path'}
              </div>
            }
            text='View File for Nest'
            interactionKind='hover'
            placement='top'
            className={cs.seqFilePath}
          />
          {nestActionButtons(selectedNest)}
          <div className={cs.nestLabel}>Nest Location (Cartesian)</div>
          <div
            className={
              highlightText ? cs.nestLocationTextWithHighlight : cs.nestLocationText
            }
          >
            {splitAndRound(selectedNest.location.loc || '', ' ')}
          </div>
          {/*TODO: Expose approach paths and make them editable?*/}
          {renderApproachPathPopover()}
        </div>
      ) : (
        <div className={cs.selectedNest}>
          <div className={cs.noNest}>No Nest Selected</div>
        </div>
      )}
    </div>
  )
}

export default NestControls
