import Pill from '~/components/Pill'
import Select from '~/components/Select'
import { RoutineDefinitionWithDSL } from '../../Routines/useRoutineDefinitionsWithDSLs'
import cs from './routine_select.scss'

export function RoutineSelect(props: {
  routineDefinitions: RoutineDefinitionWithDSL[]
  currentRoutineDefinition: RoutineDefinitionWithDSL
  setCurrentRoutineDefinition: (newDefinition: RoutineDefinitionWithDSL) => void
}): JSX.Element {
  const renderRoutine = (routineDefinition: RoutineDefinitionWithDSL) => {
    return (
      <div className={cs.routine}>
        <div>{routineDefinition.name}</div>
        <div className={cs.fill} />
        {routineDefinition.type === 'qc' && <Pill type='default' label='QC' />}
      </div>
    )
  }

  return (
    <Select<RoutineDefinitionWithDSL>
      activeItem={props.currentRoutineDefinition}
      items={props.routineDefinitions}
      itemKey={'id'}
      onChange={(item: RoutineDefinitionWithDSL) =>
        props.setCurrentRoutineDefinition(item)
      }
      itemRenderer={renderRoutine}
      triggerClassName={cs.trigger}
      popoverClassName={cs.popover}
    />
  )
}
