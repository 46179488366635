import { Dialog as BlueprintDialog } from '@blueprintjs/core'
import cx from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'

import TinyNotification from '~/components/notifications/TinyNotification'

import LoadingMessage from '../LoadingMessage'
import Button from '../buttons/Button'
import cs from './dialog.scss'

interface DialogProps {
  children: React.ReactNode
  onClose: () => void
  isOpen: boolean
  noPadding?: boolean
  className?: string
  title?: string
}
const Dialog = ({
  isOpen,
  onClose,
  children,
  className,
  noPadding,
  title,
}: DialogProps) => (
  <BlueprintDialog
    isOpen={isOpen}
    onClose={onClose}
    title={title}
    className={cx(cs.dialog, className, noPadding && cs.noPadding)}
  >
    <div onClick={event => event.stopPropagation()}>{children}</div>
  </BlueprintDialog>
)

Dialog.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func,
  isOpen: PropTypes.bool,
  noPadding: PropTypes.bool,
  className: PropTypes.string,
  title: PropTypes.string,
}

interface DialogTitleProps {
  children: React.ReactNode
}

const DialogTitle = ({ children }: DialogTitleProps) => {
  return <div className={cs.title}>{children}</div>
}

DialogTitle.propTypes = {
  children: PropTypes.node,
}

interface DialogSubtitleProps {
  children: React.ReactNode
}

const DialogSubtitle = ({ children }: DialogSubtitleProps) => {
  return <div className={cs.subtitle}>{children}</div>
}

DialogSubtitle.propTypes = {
  children: PropTypes.node,
}

interface DialogInfoProps {
  children: React.ReactNode
}

const DialogInfo = ({ children }: DialogInfoProps) => {
  return <div className={cs.info}>{children}</div>
}

interface DialogFooterProps {
  children: React.ReactNode
  align?: 'left' | 'right'
  error?: string | null
  loadingMessage?: string
}

const DialogFooter = ({
  align,
  children,
  error,
  loadingMessage,
}: DialogFooterProps) => {
  if (error) {
    return (
      <div className={cx(cs.footer, align && cs[align])}>
        <TinyNotification type='bareError' message={error} className={cs.footerError} />
      </div>
    )
  }

  if (loadingMessage) {
    return (
      <div className={cx(cs.footer, align && cs[align])}>
        <LoadingMessage label={loadingMessage} />
      </div>
    )
  }

  return <div className={cx(cs.footer, align && cs[align])}>{children}</div>
}

DialogFooter.propTypes = {
  children: PropTypes.node,
  align: PropTypes.oneOf(['left', 'right']),
  error: PropTypes.string,
  loadingMessage: PropTypes.string,
}

DialogFooter.defaultProps = {
  align: 'left',
}

interface DialogFooterButtonProps {
  label: string
  type?: 'primary' | 'normal' | 'danger'
  onClick: () => void
  disabled?: boolean
}

const DialogFooterButton = ({
  label,
  onClick,
  type,
  disabled,
}: DialogFooterButtonProps) => {
  return (
    <Button
      label={label}
      onClick={onClick}
      type={type}
      disabled={disabled}
      className={cs.button}
    />
  )
}

DialogFooterButton.propTypes = {
  label: PropTypes.string,
  type: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
}

Dialog.Title = DialogTitle
Dialog.Subtitle = DialogSubtitle
Dialog.Info = DialogInfo
Dialog.Footer = DialogFooter
Dialog.FooterButton = DialogFooterButton

export default Dialog
