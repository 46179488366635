import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom'

import Tabs from '~/components/Tabs'
import useTabsWithUrl from '~/utils/hooks/useTabsWithUrlPart'

import ButtonPlayground from './ButtonPlayground'
import IconPlayground from './IconPlayground'
import MicroplatePlayground from './MicroplatePlayground'
import NotificationPlayground from './NotificationPlayground'
import cs from './ui_playground.scss'

const TABS = [
  {
    key: 'icons',
    title: 'Icons',
  },
  {
    key: 'buttons',
    title: 'Buttons',
  },
  {
    key: 'notifications',
    title: 'Notifications',
  },
  {
    key: 'microplates',
    title: 'Microplates',
  },
]

const UiPlayground = () => {
  const [tabs, selectedTabId, handleTabChange] = useTabsWithUrl(TABS)
  const match = useRouteMatch()

  return (
    <div className={cs.uiPlayground}>
      <div className={cs.title}>UI Playground</div>
      <Tabs
        tabs={tabs}
        selectedTabId={selectedTabId}
        className={cs.tabs}
        panelClassName={cs.tabPanel}
        onlyRenderWhenViewed
        empty
        onTabChange={handleTabChange}
      />
      <Switch>
        <Route
          exact
          path={match.path}
          render={() => <Redirect to={`${match.path}/icons`} />}
        />
        <Route path={`${match.path}/icons`} render={() => <IconPlayground />} />
        <Route path={`${match.path}/buttons`} render={() => <ButtonPlayground />} />
        <Route
          path={`${match.path}/notifications`}
          render={() => <NotificationPlayground />}
        />
        <Route
          path={`${match.path}/microplates`}
          render={() => <MicroplatePlayground />}
        />
      </Switch>
    </div>
  )
}

UiPlayground.propTypes = {}

export default UiPlayground
