import cx from 'classnames'
import { get, includes, reject, sortBy } from 'lodash/fp'
import { useEffect, useState } from 'react'

import commonDriverAPI from '~/api/desktop/commonDriver'
import Input from '~/components/Input'
import Select from '~/components/Select'
import Button from '~/components/buttons/Button'
import WarningIcon from '~/components/icons/WarningIcon'

import { Instrument, InstrumentStatus } from '~/common.interface'
import cs from './generic_instrument_control_box.scss'

const COMMANDS_TO_OMIT = ['acknowledge_fault', 'reconnect']

interface GenericInstrumentControlBoxProps {
  className?: string
  instrument: Instrument
  status: InstrumentStatus
  isExecutingControlCommand?: boolean
  live?: boolean
  executeCommand(...args: unknown[]): unknown
  instrumentError?: string
  commandExecuted?: string | null
  startDriver?(...args: unknown[]): unknown
  setInstrumentError(...args: unknown[]): unknown
}

// TODO: Flesh this out with the actual return
interface InstrumentCommand {
  command: string
}

const GenericInstrumentControlBox = ({
  instrument,
  className,
  status,
  isExecutingControlCommand,
  live,
  executeCommand,
  instrumentError,
  setInstrumentError,
  commandExecuted,
  startDriver,
}: GenericInstrumentControlBoxProps) => {
  // All available instrument commands.
  const [instrumentCommands, setInstrumentCommands] = useState<InstrumentCommand[]>([])
  // The currently selected command.
  const [selectedCommand, setSelectedCommand] = useState<InstrumentCommand | null>(null)
  const [params, setParams] = useState('')

  const instrumentStatus =
    get('execution_state', status) === undefined
      ? 'Disconnected'
      : status.execution_state
  const driverState = get('driver_state', status)

  const fetchInstrumentMetadata = async () => {
    if (!instrument) return
    const _commands = await commonDriverAPI.getCommandList(instrument.instrumentName)
    setInstrumentCommands(sortBy('name', _commands))
  }

  // Do this once.
  useEffect(() => {
    fetchInstrumentMetadata()
  }, [instrument])

  const executeSelectedCommand = () => {
    if (selectedCommand) {
      executeCommand(selectedCommand.command, params)
    }
  }

  const _setSelectedCommand = command => {
    setSelectedCommand(command)
    setParams('')
    setInstrumentError('')
  }

  const itemMatchesQuery = (command, queryLowerCase) =>
    command.name.toLowerCase().includes(queryLowerCase)

  let executing = false
  let loadingMessage = ''
  let busyMessage = ''
  if (isExecutingControlCommand) {
    executing = true
    loadingMessage = 'Busy...'
    if (selectedCommand) {
      busyMessage = `Executing "${get('name', selectedCommand)}"`
    } else {
      busyMessage = 'Executing command...'
    }
  } else if (live) {
    executing = true
    loadingMessage = 'Busy...'
    busyMessage = 'The workcell is currently live'
  } else if (instrumentStatus === 'BUSY') {
    executing = true
    loadingMessage = 'Busy...'
    busyMessage = 'The instrument is currently in use'
  } else if (instrumentStatus === null) {
    executing = true
    loadingMessage = 'Connecting...'
  }

  const isBusy =
    isExecutingControlCommand ||
    instrumentStatus === 'BUSY' ||
    instrumentStatus === null

  const renderExecutionCommandControls = () => {
    if (commandExecuted === 'start_driver') {
      return <div className={cs.recoveryCommandMessage}>Starting driver...</div>
    }

    if (commandExecuted === 'stop_driver') {
      return <div className={cs.recoveryCommandMessage}>Stopping driver...</div>
    }

    if (commandExecuted === 'restart_driver') {
      return <div className={cs.recoveryCommandMessage}>Restarting driver...</div>
    }

    if (instrumentStatus !== 'READY' && !isBusy) {
      return null
    }

    if (isExecutingControlCommand && commandExecuted === 'acknowledge_fault') {
      return (
        <div className={cs.recoveryCommandMessage}>
          Acknowledging instrument fault...
        </div>
      )
    }

    if (isExecutingControlCommand && commandExecuted === 'reconnect') {
      return (
        <div className={cs.recoveryCommandMessage}>
          Attempting to reconnect to subcomponents...
        </div>
      )
    }

    const filteredInstrumentCommands = reject(
      command => includes(command.command, COMMANDS_TO_OMIT),
      instrumentCommands,
    )

    return (
      <>
        <div className={cs.executionCommandControls}>
          <Select
            label='Select Command'
            loading={executing}
            loadingMessage={loadingMessage}
            items={filteredInstrumentCommands}
            placeholder='Select command...'
            itemKey='name'
            itemLabelKey='name'
            triggerClassName={cs.trigger}
            popoverClassName={cs.executionCommandSelectPopover}
            activeItem={selectedCommand}
            onChange={_setSelectedCommand}
            filterable
            itemMatchesQuery={itemMatchesQuery}
          />
          {get(['schema', 'hasParams'], selectedCommand) && (
            <Input
              className={cs.inputContainer}
              inputClassName={cs.input}
              label='Parameters (JSON)'
              value={params}
              onChange={setParams}
              disabled={executing}
              placeholder='Command parameters...'
            />
          )}
          <Button
            label='Execute Command'
            className={cs.button}
            disabled={executing}
            onClick={executeSelectedCommand}
          />
        </div>
        {busyMessage && (
          <div className={cs.busyMessageContainer}>
            <WarningIcon className={cs.busyIcon} />
            <span className={cs.busyMessage}>{busyMessage}</span>
          </div>
        )}
        {instrumentError && (
          <div className={cs.errorMessageContainer}>
            <WarningIcon className={cs.errorIcon} />
            <span className={cs.errorMessage}>{instrumentError}</span>
          </div>
        )}
      </>
    )
  }

  const renderRecoveryControls = () => {
    if (
      isBusy ||
      commandExecuted === 'start_driver' ||
      commandExecuted === 'stop_driver' ||
      commandExecuted === 'restart_driver'
    ) {
      return null
    }

    if (driverState === 'STOPPED') {
      return (
        <div className={cs.recoveryControls}>
          <div className={cs.recoveryMessage}>The instrument driver has stopped.</div>
          <Button
            label='Start Instrument Driver'
            className={cs.button}
            type='primary'
            onClick={startDriver}
          />
        </div>
      )
    }

    if (instrumentStatus === 'FAULTED') {
      return (
        <div className={cs.recoveryControls}>
          <div className={cs.recoveryMessage}>
            The instrument has faulted.
            <br />
            <br />
            Please acknowledge that any recovery steps have been taken and the
            instrument is ready to resume normal operation.
          </div>
          <Button
            label='Acknowledge Instrument Fault'
            className={cs.button}
            type='primary'
            onClick={() => executeCommand('acknowledge_fault')}
          />
        </div>
      )
    }

    if (instrumentStatus === 'DISCONNECTED') {
      return (
        <div className={cs.recoveryControls}>
          <div className={cs.recoveryMessage}>
            The instrument has encountered an issue connecting to one of its
            subcomponents. Please check the physical connection to the relevant
            subcomponent (e.g. sensor, camera).
          </div>
          <Button
            label='Retry Connections'
            className={cs.button}
            type='primary'
            onClick={() => executeCommand('reconnect')}
          />
        </div>
      )
    }

    return null
  }

  return (
    <div className={cx(cs.genericInstrumentControlBox, className)}>
      {renderExecutionCommandControls()}
      {renderRecoveryControls()}
    </div>
  )
}

export default GenericInstrumentControlBox
