import cx from 'classnames'

import { compact, keyBy, range, uniq, zipObject } from 'lodash/fp'
import memoize from 'memoize-one'
import { PlateFormat } from '~/api/desktop/processItemsV2'
import TinyMicroplate, { supportedWellColor } from '~/components/TinyMicroplate'
import { convertWellCoordsToWellName } from '~/utils/microplate'
import cs from './link_lots_plate_preview.scss'

export interface LinkLotsPreviewWell {
  well: string
  isLive: boolean
  cellLineLot?: string
  cellLine?: string
}

export interface LinkLotsPreviewPlate {
  plateBarcode: string
  plateFormat: PlateFormat
  wells: LinkLotsPreviewWell[]
}

export interface LinkLotsPlatePreviewProps {
  className?: string
  uploadType: 'cell_lines' | 'cell_line_lots'
  plates: LinkLotsPreviewPlate[]
}

const getWellValue = (
  well: LinkLotsPreviewWell,
  uploadType: 'cell_lines' | 'cell_line_lots',
) => (uploadType === 'cell_lines' ? well.cellLine : well.cellLineLot)

export const getOrderedCellLineValues = (
  plates: LinkLotsPreviewPlate[],
  uploadType: 'cell_lines' | 'cell_line_lots',
) => {
  const cellLineValues = plates.flatMap(plate =>
    plate.wells.map(well => getWellValue(well, uploadType)),
  )
  return uniq(compact(cellLineValues))
}

const _getOrderedCellLineValues = memoize(getOrderedCellLineValues)

const LinkLotsPlatePreview = ({
  className,
  plates,
  uploadType,
}: LinkLotsPlatePreviewProps) => {
  const orderedCellLineValues = _getOrderedCellLineValues(plates, uploadType)
  const getHighlightFns = (plateBarcode: string) => {
    // Putting these functions in the closure to make rendering more efficient.
    // supportedColorFn will be called many times.
    const plate = plates.find(plate => plate.plateBarcode === plateBarcode)
    const plateWells = keyBy('well', plate?.wells)
    const cellLineValueToIndex = zipObject(
      orderedCellLineValues,
      range(0, orderedCellLineValues.length),
    )

    return [
      {
        supportedColorFn: (row: number, col: number): supportedWellColor => {
          if (!plate || !plateWells) return 'default'
          const wellName = convertWellCoordsToWellName(row, col)
          const well = plateWells[wellName]
          if (!well) return 'default'

          const cellLineValue = getWellValue(well, uploadType)

          // For now, we display the same color if the well has no value being linked,
          // regardless of whether it is live or not live.
          // TODO(SWE-1433): Visually indicate that a well is not live.
          // For wells that are not live, but have a value being linked, we might
          // indicate this with a diagonal line.
          if (!cellLineValue) return 'default'

          const colorIndex = cellLineValueToIndex[cellLineValue] || 0
          return `categorical_${colorIndex % 4}` as supportedWellColor
        },
      },
    ]
  }
  return (
    <div className={cx(className, cs.linkLotsPlatePreview)}>
      <div className={cs.plates}>
        {plates.map(({ plateBarcode, plateFormat }) => (
          <div className={cs.gridItem}>
            <TinyMicroplate
              plateFormat={plateFormat}
              size='medium'
              highlights={getHighlightFns(plateBarcode)}
            />
            <div className={cs.plateName}>{plateBarcode}</div>
          </div>
        ))}
        {/* Add empty grid items to fill out the last row */}
        {plates.length % 4 > 0 &&
          range(0, 4 - (plates.length % 4)).map(() => {
            return <div className={cs.gridItem} />
          })}
      </div>
    </div>
  )
}

export default LinkLotsPlatePreview
