const getUrlAfter = path => {
  const { pathname } = new URL(window.location.href)
  if (pathname.indexOf(path) === -1) {
    // eslint-disable-next-line no-console
    console.warn(`${path} does not match actual location ${pathname}`)
    return null
  }
  return pathname.substring(path.length)
}

const getURLParams = () => {
  return new URL(window.location.href).searchParams
}

const pushURLParam = (key, value) => {
  const url = new URL(window.location.href)
  if (url.searchParams.get(key) === value) return
  if (value === null || value === undefined) {
    url.searchParams.delete(key)
  } else {
    url.searchParams.set(key, value)
  }
  window.history.pushState({}, '', url)
}

const replaceURLParam = (key, value) => {
  const url = new URL(window.location.href)
  if (url.searchParams.get(key) === value) return
  if (value === null || value === undefined) {
    url.searchParams.delete(key)
  } else {
    url.searchParams.set(key, value)
  }
  window.history.replaceState({}, '', url)
}

const clearURLParam = key => {
  const url = new URL(window.location.href)
  url.searchParams.delete(key)
  window.history.replaceState({}, '', url)
}

// Remove all url params not in the list.
const filterURLParams = keys => {
  const url = new URL(window.location.href)
  url.searchParams.forEach((_, key) => {
    if (!keys.includes(key)) {
      url.searchParams.delete(key)
    }
  })
  window.history.replaceState({}, '', url)
}

// This can be used to guard UI features that are still in development and
// not ready to be shown to users.
const hasDebugInURL = () => {
  const urlParams = getURLParams()
  return urlParams.get('debug') === '1'
}

export {
  pushURLParam,
  replaceURLParam,
  filterURLParams,
  getURLParams,
  clearURLParam,
  hasDebugInURL,
  getUrlAfter,
}
