import dayjs from 'dayjs'
import { FutureRoutine } from '~/api/desktop/future_routine_schemas.interface'

export type FutureRoutineDisplayMode = 'by_day' | 'all_routines'

export const getAllNonQueuedFutureRoutines = (futureRoutines: FutureRoutine[]) => {
  return futureRoutines.filter(routine => !routine.isQueued)
}

export const getFutureRoutinesRequiringUserAttention = (
  futureRoutines: FutureRoutine[],
) => {
  return futureRoutines.filter(routine => routine.requiresUserAttention)
}

export const getFilteredFutureRoutines = (
  futureRoutines: FutureRoutine[],
  displayMode: FutureRoutineDisplayMode,
  selectedDate: Date,
) => {
  let nonQueuedRoutines = getAllNonQueuedFutureRoutines(futureRoutines)
  nonQueuedRoutines = nonQueuedRoutines.filter(
    routine => !routine.requiresUserAttention,
  )
  if (displayMode === 'by_day') {
    return nonQueuedRoutines.filter(routine => {
      return (
        !routine.isQueued &&
        dayjs(selectedDate).startOf('day').toISOString() <=
          dayjs(routine.scheduledFor).toISOString() &&
        dayjs(selectedDate).endOf('day').toISOString() >=
          dayjs(routine.scheduledFor).toISOString()
      )
    })
  }
  return nonQueuedRoutines
}
