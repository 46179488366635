import { flatten, sortBy } from 'lodash/fp'
import { BulkExportCultureMetadataQuery } from '~/__generated__/graphql'
import { convertWellNameToWellCoord } from '~/utils/microplate'

export interface BulkExportDataTableRow {
  plateBarcode: string
  well: string
  cellLineLot?: string | null
  cellLine?: string | null
  passageNumber?: number | null
}

export const getDataRows = (
  data: BulkExportCultureMetadataQuery,
  plateBarcodesToDisplay: string[],
) => {
  return sortBy(
    [
      (row: BulkExportDataTableRow) => plateBarcodesToDisplay.indexOf(row.plateBarcode),
      (row: BulkExportDataTableRow) => {
        const { rowIndex, colIndex } = convertWellNameToWellCoord(row.well)
        // We'll make do without the plateFormat.
        return rowIndex * 1000 + colIndex
      },
    ],
    flatten(
      data.filteredCulturePlatesWithWells.map(culturePlateWithWells => {
        return culturePlateWithWells.wellCultures.map(wellCulture => {
          return {
            plateBarcode: culturePlateWithWells.barcode,
            well: wellCulture.well,
            cellLineLot: wellCulture.cellLineLot,
            cellLine: wellCulture.cellLine,
            passageNumber: wellCulture.passageNumber,
          }
        })
      }),
    ),
  )
}

export const getCSVDataFromRows = (rows: BulkExportDataTableRow[]) => {
  return [
    ['plate_barcode', 'well', 'cell_line_lot', 'cell_line', 'passage_number'],
    ...rows.map(row => [
      row.plateBarcode,
      row.well,
      row.cellLineLot || '',
      row.cellLine || '',
      row.passageNumber || '',
    ]),
  ]
}
