import cx from 'classnames'
import CheckboxOption from '~/components/CheckboxOption'
import DateInput from '~/components/DateInput'
import cs from './future_routine_schedule_for_form.scss'

const FutureRoutineScheduleForForm = ({
  className,
  scheduledFor,
  setScheduledFor,
  shouldQueueAutomatically,
  setShouldQueueAutomatically,
  disabled,
}: {
  className?: string
  scheduledFor: string | null
  setScheduledFor: (date: string | null) => void
  shouldQueueAutomatically: boolean
  setShouldQueueAutomatically: (shouldQueueAutomatically: boolean) => void
  disabled?: boolean
}) => {
  return (
    <div className={cx(className, cs.futureRoutineScheduleForForm)}>
      <div className={cs.label}>Schedule For</div>
      <DateInput
        value={scheduledFor}
        onChange={setScheduledFor}
        placeholder={'Select date'}
        defaultDate={new Date()}
        className={cs.dateInput}
        showTime={shouldQueueAutomatically}
        showActionsBar
        todayButtonText='Now'
        disabled={disabled}
      />
      <CheckboxOption
        className={cs.shouldQueueAutomatically}
        label='Automatically execute'
        checked={shouldQueueAutomatically}
        onClick={setShouldQueueAutomatically}
        disabled={disabled}
      />
    </div>
  )
}

export default FutureRoutineScheduleForForm
