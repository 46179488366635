import dayjs from 'dayjs'
import { compact, uniq } from 'lodash'
import {
  DemoConfig,
  getProfileSettings,
} from '~/pages/slasDemo/CommandCenter/SlasDemoConfigDialog/DemoConfig'
import { CultureMediaExchanged, DemoEvent, MediaLotWarmed } from '../../DemoEvent'
import { CultureViewModel } from '../../ViewModels/CultureViewModel'
import { generateEventTime } from '../eventTimes'
import { makeUniqueID } from '../makeUniqueID'

const DAY_OF_CONTAMINATED_MEDIA = 1 // 0-indexed - demo starts on day 0

// The story is that we ran out of media on this day because we messed up a few reagent plates,
// so we used another lot for two plates
const NUM_PLATES_TO_CONTAMINATE = 2

// This is a bit hacky, but the idea is to allow is to be able to demo both a single plate being
// passaged, as well as managing many cultures - some of which get contaminated.
const CULTURE_THRESHOLD_TO_USE_BAD_MEDIA = 50

export function exchangeMediaForCultures(
  cultures: CultureViewModel[],
  onDay: number,
  config: DemoConfig,
): DemoEvent[] {
  const lots = prewarmMedia(cultures.length, onDay, config)

  return [
    ...compact([lots.safeLotEvent, lots.contaminatedLotEvent]),
    ...performMediaExchanges(cultures, lots, onDay),
  ]
}

interface DemoMediaLotsForDay {
  safeLotEvent: MediaLotWarmed
  contaminatedLotEvent: MediaLotWarmed | null
}

function prewarmMedia(
  numCultures: number,
  onDay: number,
  config: DemoConfig,
): DemoMediaLotsForDay {
  const safeLot: MediaLotWarmed = {
    kind: 'MediaLotWarmed',
    at: generateEventTime({ eventDay: onDay, eventKind: 'MediaLotWarmed' }),
    mediaLotID: makeUniqueID('MEDIA'),
    isSecretlyContaminated: false,
    mediaName: generateMediaName({ onDay, isContaminated: false, config }),
  }

  return numCultures > CULTURE_THRESHOLD_TO_USE_BAD_MEDIA &&
    onDay === DAY_OF_CONTAMINATED_MEDIA &&
    getProfileSettings(config).showContamination
    ? {
        safeLotEvent: safeLot,
        contaminatedLotEvent: {
          kind: 'MediaLotWarmed',
          at: generateEventTime({
            eventDay: onDay,
            eventKind: 'MediaLotWarmed',
          }),
          mediaLotID: makeUniqueID('MEDIA'),
          isSecretlyContaminated: true,
          mediaName: generateMediaName({ onDay, isContaminated: true, config }),
        },
      }
    : { safeLotEvent: safeLot, contaminatedLotEvent: null }
}

function generateMediaName({
  onDay,
  isContaminated,
  config,
}: {
  onDay: number
  isContaminated: boolean
  config: DemoConfig
}) {
  const suffix = `_Media_${generateDateString(onDay)}${isContaminated ? '_B' : ''}`
  return getProfileSettings(config).platePrefix + suffix
}

function generateDateString(onDay: number): string {
  return dayjs().add(onDay, 'day').format('YYYY_MM_DD')
}

function performMediaExchanges(
  cultures: CultureViewModel[],
  lots: DemoMediaLotsForDay,
  onDay: number,
): CultureMediaExchanged[] {
  const contaminatedExchanges = lots.contaminatedLotEvent
    ? performContaminatedMediaExchange(
        cultures,
        lots.contaminatedLotEvent.mediaLotID,
        lots.contaminatedLotEvent.mediaName,
        onDay,
      )
    : []

  const culturesToSafelyExchange = cultures.filter(
    culture =>
      !contaminatedExchanges.some(exchange => exchange.cultureID === culture.id),
  )

  return [
    ...culturesToSafelyExchange.map(culture =>
      createMediaExchangeEvent(
        culture,
        lots.safeLotEvent.mediaLotID,
        lots.safeLotEvent.mediaName,
        onDay,
        {
          mediaWasContaminated: false,
        },
      ),
    ),
    ...contaminatedExchanges,
  ]
}

function performContaminatedMediaExchange(
  allCultures: CultureViewModel[],
  contaminatedLotID: string,
  contaminatedMediaName: string,
  onDay: number,
): CultureMediaExchanged[] {
  const plateIDs = uniq(allCultures.map(culture => culture.plateID))
  const platesToContaminate = plateIDs.slice(0, NUM_PLATES_TO_CONTAMINATE)
  const culturesToContaminate = allCultures.filter(culture =>
    platesToContaminate.includes(culture.plateID),
  )
  return culturesToContaminate.map(culture =>
    createMediaExchangeEvent(culture, contaminatedLotID, contaminatedMediaName, onDay, {
      mediaWasContaminated: true,
    }),
  )
}

function createMediaExchangeEvent(
  culture: CultureViewModel,
  mediaLotID: string,
  mediaName: string,
  onDay: number,
  { mediaWasContaminated }: { mediaWasContaminated: boolean },
): CultureMediaExchanged {
  return {
    kind: 'CultureMediaExchanged',
    at: generateEventTime({
      eventDay: onDay,
      eventKind: 'CultureMediaExchanged',
    }),
    day: onDay,
    cultureID: culture.id,
    mediaLotID: mediaLotID,
    mediaName: mediaName,
    _mediaWasContaminated: mediaWasContaminated,
  }
}
