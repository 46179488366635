import cx from 'classnames'
import { compact } from 'lodash/fp'
import { useContext, useState } from 'react'
import { WorkflowInstanceWithCulturePlate } from '~/api/desktop/culturePlateWorkflow'
import Table from '~/components/Table'
import MinimalButton from '~/components/buttons/MinimalButton'
import TinyNotification from '~/components/notifications/TinyNotification'
import WorkflowInstanceVizDialog from '~/pages/Workcell/Schedule/Workflows/WorkflowInstanceVizDialog'
import WorkcellStatusContext from '../../WorkcellStatusContext'
import TerminateWorkflowDialog from '../../components/processItem/CulturePlateWorkflow/TerminateWorkflowDialog'
import { getIsWorkcellBusy } from '../../utils/workcellStatus'
import cs from './workflows_instance_table.scss'

const WorkflowInstanceTable = ({
  className,
  workflowInstancesWithCulturePlates,
  loading,
  onWorkflowInstanceUpdate,
}: {
  className?: string
  workflowInstancesWithCulturePlates: WorkflowInstanceWithCulturePlate[] | null
  loading: boolean
  onWorkflowInstanceUpdate: () => void
}) => {
  const [
    selectedWorkflowInstanceWithCulturePlate,
    setSelectedWorkflowInstanceWithCulturePlate,
  ] = useState<WorkflowInstanceWithCulturePlate | undefined>(undefined)
  const [showTerminateDialog, setShowTerminateDialog] = useState(false)
  const [showVizDialog, setShowVizDialog] = useState(false)
  const workcellStatus = useContext(WorkcellStatusContext)
  const isWorkcellBusy = getIsWorkcellBusy(workcellStatus)

  const handleRowClicked = (
    workflowInstanceWithCulturePlate: WorkflowInstanceWithCulturePlate,
  ) => {
    setSelectedWorkflowInstanceWithCulturePlate(workflowInstanceWithCulturePlate)
    setShowVizDialog(true)
  }

  const tableColumns = compact([
    {
      name: 'Workflow',
      width: 250,
      render: (row: WorkflowInstanceWithCulturePlate) =>
        row.workflow_instance.workflow_definition_id,
    },
    {
      name: 'Process Item',
      width: 250,
      render: (row: WorkflowInstanceWithCulturePlate) => {
        if (row.culture_plate) {
          return (
            <div>
              <div className={cs.culturePlate}>{row.culture_plate.uuid}</div>
              {!row.culture_plate.is_checked_in && (
                <TinyNotification type='bareWarning' message='Checked out' />
              )}
            </div>
          )
        }
        return <TinyNotification type='bareError' message='No culture plate found' />
      },
    },
    {
      name: '',
      width: 60,
      showOnHover: true,
      omitCellPadding: true,
      stopPropagation: true,
      render: (row: WorkflowInstanceWithCulturePlate) =>
        row.culture_plate &&
        row.workflow_instance && (
          <MinimalButton
            label='Terminate'
            onClick={() => {
              setSelectedWorkflowInstanceWithCulturePlate(row)
              setShowTerminateDialog(true)
            }}
            disabled={isWorkcellBusy}
            popoverMessageIfDisabled='Cannot terminate workflow while workcell is busy'
          />
        ),
    },
  ])

  if (loading) {
    return <div className={cx(className, cs.bigMessage)}>Loading workflows...</div>
  }
  if (
    !workflowInstancesWithCulturePlates ||
    workflowInstancesWithCulturePlates.length === 0
  ) {
    return (
      <div className={cx(className, cs.bigMessage)}>No active workflows found.</div>
    )
  }

  return (
    <div className={cx(className, cs.workflowsInstanceTable)}>
      <Table<WorkflowInstanceWithCulturePlate>
        columns={tableColumns}
        data={workflowInstancesWithCulturePlates}
        className={cs.table}
        onRowClick={handleRowClicked}
        rowPaddingVariant='rowPaddingLow'
        rowKey='id'
      />
      <TerminateWorkflowDialog
        culturePlate={
          selectedWorkflowInstanceWithCulturePlate?.culture_plate || undefined
        }
        workflowInstance={selectedWorkflowInstanceWithCulturePlate?.workflow_instance}
        isOpen={showTerminateDialog}
        onClose={() => {
          setShowTerminateDialog(false)
          setSelectedWorkflowInstanceWithCulturePlate(undefined)
        }}
        onTerminateSuccess={onWorkflowInstanceUpdate}
      />
      <WorkflowInstanceVizDialog
        isOpen={showVizDialog}
        onClose={() => {
          setShowVizDialog(false)
          setSelectedWorkflowInstanceWithCulturePlate(undefined)
        }}
        selectedWorkflowInstanceWithCulturePlate={
          selectedWorkflowInstanceWithCulturePlate
        }
      />
    </div>
  )
}

export default WorkflowInstanceTable
