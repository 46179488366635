import cx from 'classnames'
import { findIndex, get, set } from 'lodash/fp'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'

import processStepsAPI from '~/api/desktop/processSteps'
import workcellAPI from '~/api/desktop/workcell'
import Dialog from '~/components/Dialog'
import Toaster from '~/components/Toaster'
import { STATUS_PILL_CONFIG } from '~/pages/Workcell/History/constants'
import ProcessStepTable from '~/pages/Workcell/components/processStep/ProcessStepTable'
import { displayDateDiff } from '~/utils/date'
import { renderStatusPill } from '~/utils/statusPill'
import { displayCount } from '~/utils/string'

import TextWithOverflow from '~/components/TextWithOverflow'
import { ProcessStep } from '../../types/ProcessStep.interface'
import { ScheduledRoutine } from '../../types/ScheduledRoutine.interface'
import { getProcessItemsDisplayForRoutine } from '../../utils/routine'
import cs from './scheduled_routine_dialog.scss'

interface ScheduledRoutineDialogProps {
  className?: string
  isOpen: boolean
  onClose: () => void
  routine: ScheduledRoutine | null
}

const ScheduledRoutineDialog = ({
  className,
  isOpen,
  onClose,
  routine,
}: ScheduledRoutineDialogProps) => {
  const [steps, setSteps] = useState<ProcessStep[]>([])

  const fetchSteps = async () => {
    if (routine) {
      const response = await processStepsAPI.get({
        routineId: routine.id,
      })
      setSteps(response)
    } else {
      setSteps([])
    }
  }

  useEffect(() => {
    fetchSteps()
  }, [routine])

  const cancelStep = async (step: ProcessStep) => {
    const index = findIndex(['id', step.id], steps)
    setSteps(set([index, 'status'], 'canceled', steps))
    try {
      await workcellAPI.cancelSteps([step.uuid])
      Toaster.show({
        message: `Cancelled step '${step.stepDisplay}'`,
        intent: 'success',
      })
    } catch {
      Toaster.show({
        message: `${step.stepDisplay} failed to cancel.`,
        intent: 'danger',
      })
    }
  }

  const retryCompletedStep = async (step: ProcessStep) => {
    const index = findIndex(['id', step.id], steps)
    setSteps(set([index, 'status'], 'scheduled', steps))
    try {
      await workcellAPI.rescheduleCompletedStep(step.uuid)
      Toaster.show({
        message: `Re-scheduled step '${step.stepDisplay}'`,
        intent: 'success',
      })
    } catch {
      Toaster.show({
        message: `${step.stepDisplay} failed to re-schedule.`,
        intent: 'danger',
      })
    }
  }

  const getRuntimeDisplay = () => {
    if (!routine) return null

    if (routine.endTime && routine.startTime) {
      return (
        <span>
          Runtime{' '}
          {displayDateDiff(new Date(routine.endTime), new Date(routine.startTime))}
        </span>
      )
    }
    const endTime = routine.endTime || routine.estimatedEndTime
    const startTime = routine.startTime || routine.estimatedStartTime
    if (endTime && startTime) {
      return (
        <span>
          Est. Runtime {displayDateDiff(new Date(endTime), new Date(startTime))}
        </span>
      )
    }

    return null
  }

  const processItemDisplayForRoutine = routine
    ? getProcessItemsDisplayForRoutine(routine)
    : ''

  return (
    <Dialog
      isOpen={isOpen}
      onClose={onClose}
      className={cx(className, cs.routineDialog)}
    >
      <div className={cs.title}>
        <div className={cs.name}>{get(['routineDefinition', 'name'], routine)}</div>
        {routine && (
          <>
            <div className={cs.parens}>&nbsp;{'('}</div>
            <TextWithOverflow
              className={cs.processItemsDisplay}
              text={processItemDisplayForRoutine}
              popoverContent={processItemDisplayForRoutine}
            />
            <div className={cs.parens}>{')'}</div>
          </>
        )}
        {routine &&
          renderStatusPill(routine.status, STATUS_PILL_CONFIG, {
            className: cs.status,
            small: true,
          })}
      </div>
      <div className={cs.subtitle}>
        {displayCount('Process Step', steps.length)} &middot; {getRuntimeDisplay()}
      </div>
      <ProcessStepTable
        steps={steps}
        className={cs.table}
        cancelStep={cancelStep}
        retryCompletedStep={retryCompletedStep}
      />
    </Dialog>
  )
}

ScheduledRoutineDialog.propTypes = {
  className: PropTypes.string,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  routine: PropTypes.shape({
    id: PropTypes.number,
    routineDefinition: PropTypes.shape({
      name: PropTypes.string,
    }),
    startTime: PropTypes.string,
    endTime: PropTypes.string,
    estimatedStartTime: PropTypes.string,
    estimatedEndTime: PropTypes.string,
    status: PropTypes.string,
  }),
}

export default ScheduledRoutineDialog
