import { compact, flatten, get } from 'lodash/fp'
import { useEffect, useState } from 'react'

import commonDriverAPI from '~/api/desktop/commonDriver'
import PropTypes from '~/propTypes'

import { Instrument } from '~/common.interface'
import { useIsMounted } from '~/utils/hooks/useIsMounted'
import cs from './instrument_status_callout.scss'

interface StorageCalloutProps {
  instrument: Instrument
  reloadKey: string
}

const StorageCallout = ({ instrument, reloadKey }: StorageCalloutProps) => {
  const isMounted = useIsMounted()
  const [instrumentState, setInstrumentState] = useState(null)

  const fetchData = () => {
    commonDriverAPI.getState(instrument.instrumentName).then(_instrumentState => {
      if (!isMounted()) return
      setInstrumentState(_instrumentState)
    })
  }

  useEffect(() => {
    fetchData()
  }, [instrument, reloadKey])

  if (!instrumentState) return null

  const items = get('storedProcessItems', instrumentState)

  const totalSlots = flatten(items).length
  const filledSlots = compact(flatten(items)).length

  return (
    <div className={cs.instrumentStatusCallout}>
      <div className={cs.label}>Capacity</div>
      <div className={cs.callout}>
        {filledSlots} / {totalSlots}{' '}
      </div>
    </div>
  )
}

StorageCallout.propTypes = {
  reloadKey: PropTypes.string,
  instrument: PropTypes.Instrument,
}

export default StorageCallout
