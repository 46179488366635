import { includes } from 'lodash/fp'
import LinkCellLineLotsToCulturesIcon from '~/assets/images/operator_link_cell_line_lots_to_cultures.png'
import AppHeader from '~/components/AppHeader'
import WizardStepper, { WizardStepperStep } from '~/components/Wizard/WizardStepper'
import useWizardData from '~/components/Wizard/useWizardData'
import { useEnabledAndUnusedOperatorActionNames } from '../../useEnabledAndUnusedOperatorActionNames'
import ConfirmUpdatesStep from './ConfirmUpdatesStep'
import { getValidStepDataIfComplete as getValidConfirmUpdatesStepDataIfComplete } from './ConfirmUpdatesStep'
import {
  LinkCellLineLotsToCulturesWizardData,
  LinkCellLineLotsToCulturesWizardStep,
} from './LinkCellLineLotsToCulturesWizardData.interface'
import LinkLotsUnknownError from './LinkLotsUnknownError'
import SubmitStep from './SubmitStep'
import UploadMetadataStep from './UploadMetadataStep'
import { getValidStepDataIfComplete as getValidUploadDataStepDataIfComplete } from './UploadMetadataStep'
import { isCurrentWizardStepComplete } from './isCurrentWizardStepComplete'
import cs from './link_cell_line_lots_to_cultures_action.scss'

const ORDERED_WIZARD_STEPS: WizardStepperStep<LinkCellLineLotsToCulturesWizardStep>[] =
  [
    {
      step: 'uploadMetadata',
      title: 'Upload Metadata',
    },
    {
      step: 'confirmUpdates',
      title: 'Confirm Updates',
    },
  ]

const LinkCellLineLotsToCulturesAction = () => {
  const { enabledOperatorActionNames } = useEnabledAndUnusedOperatorActionNames()
  const { wizardData, updateCurrentStep, updateFinalSubmitData, updateStepData } =
    useWizardData<
      LinkCellLineLotsToCulturesWizardStep,
      LinkCellLineLotsToCulturesWizardData
    >({
      currentStep: 'uploadMetadata',
      stepData: {
        uploadMetadata: {
          data: '',
          parseDataResponse: null,
        },
        confirmUpdates: {
          lookupDataResponse: null,
          lookupInProgress: false,
        },
      },
      finalSubmitData: { submitInProgress: false, addDataResponse: null },
    })

  if (!includes('link_cell_line_lots_to_cultures', enabledOperatorActionNames)) {
    return null
  }

  const renderView = () => {
    if (wizardData.currentStep === 'uploadMetadata') {
      return (
        <UploadMetadataStep
          stepData={wizardData.stepData.uploadMetadata}
          setStepData={stepData => updateStepData('uploadMetadata', stepData)}
          confirmUpdatesStepData={wizardData.stepData.confirmUpdates}
          clearConfirmUpdatesStepData={() =>
            updateStepData('confirmUpdates', {
              lookupDataResponse: null,
              lookupInProgress: false,
            })
          }
        />
      )
    }

    if (wizardData.currentStep === 'confirmUpdates') {
      // NOTE: We can potentially move this pattern (of getting valid versions of
      // previous step data, and using this function to power isCurrentStepComplete in WizardStepper)
      // into useWizardData if the pattern holds up across multiple wizards.
      const validUploadDataStepData = getValidUploadDataStepDataIfComplete(wizardData)
      // This should never happen. See isCurrentWizardStepComplete.
      if (validUploadDataStepData === null) {
        return <LinkLotsUnknownError />
      }

      return (
        <ConfirmUpdatesStep
          stepData={wizardData.stepData.confirmUpdates}
          setStepData={stepData => updateStepData('confirmUpdates', stepData)}
          uploadDataStepData={validUploadDataStepData}
          updateCurrentStep={updateCurrentStep}
        />
      )
    }

    if (wizardData.currentStep === '_finalSubmitStep') {
      const validUploadDataStepData = getValidUploadDataStepDataIfComplete(wizardData)
      const validConfirmUpdatesStepData =
        getValidConfirmUpdatesStepDataIfComplete(wizardData)
      // This should never happen. See isCurrentWizardStepComplete.
      if (validUploadDataStepData === null || validConfirmUpdatesStepData === null) {
        return <LinkLotsUnknownError />
      }

      return (
        <SubmitStep
          stepData={wizardData.finalSubmitData}
          setStepData={updateFinalSubmitData}
          uploadDataStepData={validUploadDataStepData}
          confirmUpdatesStepData={validConfirmUpdatesStepData}
        />
      )
    }

    return null
  }

  return (
    <div className={cs.linkCellLineLotsToCulturesAction}>
      <AppHeader
        className={cs.header}
        appName='Link Cell Line Lots to Cultures'
        iconSrc={LinkCellLineLotsToCulturesIcon}
        variant='inline'
      />
      <WizardStepper<LinkCellLineLotsToCulturesWizardStep>
        className={cs.stepper}
        steps={ORDERED_WIZARD_STEPS}
        currentStep={wizardData.currentStep}
        submitLabel='Save'
        isCurrentStepComplete={isCurrentWizardStepComplete(wizardData)}
        onCurrentStepUpdate={updateCurrentStep}
      />
      {renderView()}
    </div>
  )
}

export default LinkCellLineLotsToCulturesAction
