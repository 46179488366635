import cx from 'classnames'
import PropTypes from 'prop-types'
import { useState } from 'react'

import Table from '~/components/Table'
import MinimalButton from '~/components/buttons/MinimalButton'
import { STATUS_PILL_CONFIG } from '~/pages/Workcell/History/constants'
import ScheduledRoutineDialog from '~/pages/Workcell/components/routine/ScheduledRoutineDialog'
import { renderStatusPill } from '~/utils/statusPill'

import { includes } from 'lodash/fp'
import { ScheduledRoutine } from '../../types/ScheduledRoutine.interface'
import { getProcessItemsDisplayForRoutine } from '../../utils/routine'
import RoutineOrProcessStepEndTime from '../processStep/RoutineOrProcessStepEndTime'
import RoutineOrProcessStepStartTime from '../processStep/RoutineOrProcessStepStartTime'
import cs from './routine_list.scss'

export const canCancelScheduledRoutine = (routine: ScheduledRoutine) => {
  if (includes(routine.status, ['completed', 'cancelled'])) {
    return false
  }

  if (routine.status === 'in_progress' && routine.stepsInProgress) {
    return false
  }
  return true
}

interface ScheduledRoutineListProps {
  className?: string
  routines: ScheduledRoutine[]
  loading: boolean
  onRoutineCancel?: (routine: ScheduledRoutine) => void
  timeDisplayType?: 'time' | 'datetime'
}

// TODO(mark): List any delayed routines in a separate error notification.
// TODO(mark): Right now, we only display static estimated times.
// The workcell can also generate real-time estimated times, which take delays into account,
// but these are not saved to the database. We can account for these later.
const ScheduledRoutineList = ({
  className,
  routines,
  loading,
  onRoutineCancel,
  timeDisplayType,
}: ScheduledRoutineListProps) => {
  const [selectedRoutine, setSelectedRoutine] = useState<ScheduledRoutine | null>(null)
  const [showRoutineDialog, setShowRoutineDialog] = useState(false)

  const renderStatus = (row: ScheduledRoutine) =>
    renderStatusPill(row.status, STATUS_PILL_CONFIG, {
      className: cs.status,
    })

  const renderProcessItem = (row: ScheduledRoutine) => {
    const processItemDisplayForRoutine = getProcessItemsDisplayForRoutine(row)
    if (!processItemDisplayForRoutine) return null
    return <b>{processItemDisplayForRoutine}</b>
  }

  const handleRoutineSelected = (row: ScheduledRoutine) => {
    setSelectedRoutine(row)
    setShowRoutineDialog(true)
  }

  const handleShowRoutineDialogClose = () => {
    setSelectedRoutine(null)
    setShowRoutineDialog(false)
  }

  const tableColumns = [
    {
      name: 'Routine Name',
      width: 200,
      render: (row: ScheduledRoutine) =>
        row.routineDefinition?.name || <span className={cs.empty}>--</span>,
    },
    {
      name: 'Process Item',
      width: 200,
      render: renderProcessItem,
    },
    {
      name: 'Status',
      width: 200,
      render: renderStatus,
    },
    {
      name: 'Start Time',
      width: 200,
      render: (row: ScheduledRoutine) => (
        <RoutineOrProcessStepStartTime
          routineOrProcessStep={row}
          displayType={timeDisplayType}
        />
      ),
    },
    {
      name: 'End Time',
      width: 200,
      render: (row: ScheduledRoutine) => (
        <RoutineOrProcessStepEndTime
          routineOrProcessStep={row}
          displayType={timeDisplayType}
        />
      ),
    },
    {
      name: '',
      width: 50,
      showOnHover: true,
      stopPropagation: true,
      render: row =>
        canCancelScheduledRoutine(row) &&
        onRoutineCancel && (
          <MinimalButton label='Cancel' onClick={() => onRoutineCancel(row)} />
        ),
    },
  ]

  if (loading) {
    return <div className={cs.bigMessage}>Loading routines...</div>
  }

  if (!loading && routines.length === 0) {
    return <div className={cs.bigMessage}>No routines to display.</div>
  }

  return (
    <>
      <Table<ScheduledRoutine>
        columns={tableColumns}
        data={routines}
        rowKey='id'
        className={cx(cs.routineList, className)}
        rowPaddingVariant='rowPaddingLow'
        onRowClick={handleRoutineSelected}
      />
      <ScheduledRoutineDialog
        routine={selectedRoutine}
        isOpen={showRoutineDialog}
        onClose={handleShowRoutineDialogClose}
      />
    </>
  )
}

ScheduledRoutineList.propTypes = {
  className: PropTypes.string,
  routines: PropTypes.arrayOf(PropTypes.shape({})),
  loading: PropTypes.bool,
  onRoutineCancel: PropTypes.func,
  timeDisplayType: PropTypes.oneOf(['time', 'datetime']),
}

export default ScheduledRoutineList
