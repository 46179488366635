import { find, findIndex, includes, reject } from 'lodash/fp'
import { useEffect, useState } from 'react'

import integrationsAPI from '~/api/integrations'
import scispotLogo from '~/assets/images/scispot-box-logo.svg'
import slackLogo from '~/assets/images/slack_logo.svg'
import Pill, { PillType } from '~/components/Pill'
import Button from '~/components/buttons/Button'
import { UserMetadata, getUserIsAdmin } from '~/core/MultiFrontendContext'
import DeprecatedConfigureSlackDialog from '~/pages/IntegrationsDashboard/DeprecatedConfigureSlackDialog'
import {
  DeprecatedSlackIntegration,
  Integration,
  ScispotIntegration,
  SlackSettings,
} from '~/types/Integration.interface'
import ConfigureScispotDialog from './ConfigureScispotDialog'
import cs from './integrations_dashboard.scss'

const DeprecatedIntegrationsDashboard = ({
  enabledIntegrations,
  userMetadata,
}: { enabledIntegrations: string[]; userMetadata: UserMetadata }) => {
  const [integrations, setIntegrations] = useState<Integration[]>([])
  const [configureScispotOpen, setConfigureScispotOpen] = useState(false)
  const [configureSlackOpen, setConfigureSlackOpen] = useState(false)
  const [loading, setLoading] = useState(true)
  const userIsOrgAdmin = getUserIsAdmin(userMetadata)

  const updateIntegration = (integration: Integration) => {
    const newIntegrations = integrations.slice()
    const index = findIndex(['type', integration.type], integrations)
    newIntegrations[index] = integration
    setIntegrations(newIntegrations)
  }

  const updateScispotIntegration = (newScispotIntegration: ScispotIntegration) => {
    updateIntegration(newScispotIntegration)
  }

  const updateSlackIntegration = (newSlackIntegration: DeprecatedSlackIntegration) => {
    updateIntegration(newSlackIntegration)
  }

  const deleteSlackIntegration = () => {
    setIntegrations(reject(['type', 'slack'], integrations))
  }

  const initialize = async () => {
    setLoading(true)
    const _integrations = await integrationsAPI.get()
    setIntegrations(_integrations)
    setLoading(false)
  }

  useEffect(() => {
    initialize()
  }, [])

  const renderSlackPill = () => {
    const slackIntegration = find(['type', 'slack'], integrations)
    let label = 'Not Connected'
    let type: PillType = 'inactive'

    if (slackIntegration) {
      const slackSettings = slackIntegration.settings as SlackSettings
      if (!slackSettings.channel) {
        label = 'Configuration Required'
        type = 'warning'
      } else {
        label = `Connected: ${slackSettings.teamName} #${slackSettings.channel}`
        type = 'success'
      }
    }

    return <Pill label={label} type={type} small className={cs.pill} />
  }

  const hasIntegration = integration => {
    return includes(integration, enabledIntegrations)
  }

  // Handle case where integration does not exist.
  const scispotIntegration = find(
    ['type', 'scispot'],
    integrations,
  ) as ScispotIntegration
  const slackIntegration = find(
    ['type', 'slack'],
    integrations,
  ) as DeprecatedSlackIntegration

  const renderScispotIntegration = () => {
    return (
      <div className={cs.integration}>
        <img src={scispotLogo} className={cs.logo} alt='Scispot Logo' />
        <div className={cs.text}>
          <div className={cs.name}>Scispot</div>
          <div className={cs.description}>
            Post updates from your lab instruments to Scispot.
          </div>
          <div className={cs.controls}>
            <Button
              type='normal'
              label='Configure'
              onClick={() => setConfigureScispotOpen(true)}
              className={cs.button}
            />
          </div>
        </div>
      </div>
    )
  }

  const renderSlackIntegration = () => {
    return (
      <div className={cs.integration}>
        <img src={slackLogo} className={cs.logo} alt='Slack Logo' />
        <div className={cs.text}>
          <div className={cs.name}>
            Slack
            {renderSlackPill()}
          </div>
          <div className={cs.description}>
            Receive updates from your lab instruments in Slack.
          </div>
          {userIsOrgAdmin && (
            <div className={cs.controls}>
              {slackIntegration ? (
                <Button
                  type='normal'
                  label='Configure'
                  onClick={() => setConfigureSlackOpen(true)}
                  className={cs.button}
                />
              ) : (
                <Button
                  type='normal'
                  label='Link Slack Team'
                  onClick={() => {
                    window.location.href = '/login/slack?next=/integrations'
                  }}
                />
              )}
            </div>
          )}
        </div>
      </div>
    )
  }

  const renderList = () => {
    if (loading) {
      return <div className={cs.bigMessage}>Loading integrations...</div>
    }
    return (
      <div className={cs.integrations}>
        {hasIntegration('slack') && renderSlackIntegration()}
        {hasIntegration('scispot') && renderScispotIntegration()}
        <DeprecatedConfigureSlackDialog
          open={configureSlackOpen}
          onClose={() => setConfigureSlackOpen(false)}
          integration={slackIntegration}
          onIntegrationUpdate={updateSlackIntegration}
          onIntegrationDelete={deleteSlackIntegration}
        />
        <ConfigureScispotDialog
          open={configureScispotOpen}
          onClose={() => setConfigureScispotOpen(false)}
          integration={scispotIntegration}
          onIntegrationUpdate={updateScispotIntegration}
        />
      </div>
    )
  }

  return renderList()
}

DeprecatedIntegrationsDashboard.propTypes = {}

export default DeprecatedIntegrationsDashboard
