import { useFragment, useQuery } from '@apollo/client'
import cx from 'classnames'
import dayjs from 'dayjs'
import { useEffect, useState } from 'react'

import { FragmentType, gql, unmaskFragment } from '~/__generated__'
import { PlatePageQuery, WellCultureStatusGraphQl } from '~/__generated__/graphql'
import Breadcrumbs from '~/components/Breadcrumbs'
import { FullPageError } from '~/components/Errors'
import PageNotFound from '~/components/PageNotFound'
import PlateHero, { PlateHeroLoadingPlaceholder } from '~/components/PlateHero'
import PopupMenu from '~/components/PopupMenu'
import ZeroWidthSpaceToken from '~/components/ZeroWidthSpaceToken'
import { analytics } from '~/core/analytics'
import { useDemoMutation, useDemoQuery } from '~/demoControls/DemoContext'

import AppToaster from '~/components/Toaster'
import Button from '~/components/buttons/Button'
import MicroplateIcon from '~/components/icons/MicroplateIcon'
import PlateConfluenceGraphSidebar from './PlateConfluenceGraphSidebar'
import PlateMetadataSidebar from './PlateMetadataSidebar'
import cs from './plate.scss'

const GRAPHQL_QUERY = gql(`
  query PlatePage($id: UUID!) {
    culturePlate(id: $id) {
      id
      barcode
      plateObservationHistory {
        id
        timestamp
      }
      ...PlateCrumbFragment
      ...PlateStatusFragment
      ...PlateHeroFragment
      ...PlateConfluenceGraphSidebarFragment
      ...PlateMetadataSidebarFragment
    }
  }
`)

export default function PlatePage({
  id,
  enableSelection = false,
  routePrefix = '',
}: { id: string; enableSelection?: boolean; routePrefix?: string }) {
  const variables = { id }
  const demoData = useDemoQuery(
    routePrefix ? 'cle' : 'monitor',
    'PlatePage',
  )?.(variables)
  const { loading, error, data } = useQuery(GRAPHQL_QUERY, {
    variables,
    skip: demoData != null,
  })

  if (demoData?.culturePlate) {
    return (
      <Content
        plate={demoData.culturePlate}
        enableSelection={enableSelection}
        routePrefix={routePrefix}
      />
    )
  }
  if (loading) {
    return <LoadingPlaceholder id={id} routePrefix={routePrefix} />
  }
  if (error) {
    return <FullPageError error={error} />
  }
  const plate = data?.culturePlate
  if (plate == null) {
    return <PageNotFound />
  }
  return (
    <Content
      plate={plate}
      enableSelection={enableSelection}
      routePrefix={routePrefix}
    />
  )
}

function Content({
  plate,
  enableSelection,
  routePrefix,
}: {
  plate: NonNullable<PlatePageQuery['culturePlate']>
  enableSelection?: boolean
  routePrefix: string
}) {
  const [latestPlateObservationTime] = plate.plateObservationHistory
    .map(obs => obs.timestamp)
    .sort()
    .slice(-1) as [string | undefined]

  useEffect(() => {
    analytics.page('Monitor', 'Plate', {
      plateID: plate.id,
      numPlateObservations: plate.plateObservationHistory.length,
      latestPlateObservationTime: latestPlateObservationTime ?? null,
    })
  }, [])

  const time = latestPlateObservationTime ? dayjs(latestPlateObservationTime) : null
  const isThisYear = time?.year() === dayjs().year()

  const [selectionState, setSelectionState] = useState<{
    selectedWells: string[] | undefined
  }>({
    selectedWells: undefined,
  })

  const handleSelectWellsToPassage = () => {
    setSelectionState({ selectedWells: ['A1', 'B2', 'C1', 'C3'] })
  }

  const handleWellToggle = (well: string) => {
    if (selectionState.selectedWells === undefined) {
      return
    }
    setSelectionState(prevState => {
      const selectedWells = prevState.selectedWells || []
      if (selectedWells.includes(well)) {
        return { selectedWells: selectedWells.filter(w => w !== well) }
      } else {
        return { selectedWells: [...selectedWells, well] }
      }
    })
  }

  const [isDropdownOpen, setDropdownOpen] = useState(false)
  const plateActionsOptions = [
    {
      label: 'Select Wells to Passage...',
      action: handleSelectWellsToPassage,
    },
    {
      label: 'Mark Wells Contaminated...',
      action: () => {},
    },
    {
      label: 'Mark Wells Empty...',
      action: () => {},
    },
    {
      label: 'Mark Wells Multi-clonal...',
      action: () => {},
    },
  ]

  const handleCancelPassage = () => {
    setSelectionState({ selectedWells: undefined })
  }

  const updateDemoPlatesForPassage = useDemoMutation(
    'cle',
    (demoData, { selectedWells }: { selectedWells?: string[] }) => {
      if (!selectedWells?.length) {
        return demoData
      }
      return {
        ...demoData,
        plates: [
          ...(demoData.plates ?? []),
          {
            barcode: `I came from ${plate.barcode} wells ${selectedWells.join(', ')}`,
            format: '96',
          },
        ],
      }
    },
  )

  const handleSubmitPassage = () => {
    AppToaster.show({
      message: `Successfully scheduled ${selectionState.selectedWells?.length} wells for passage.`,
      intent: 'success',
    })
    setSelectionState({ selectedWells: undefined })
    updateDemoPlatesForPassage({ selectedWells: selectionState.selectedWells })
  }

  return (
    <div>
      <Breadcrumbs plate={{ id: plate.id, data: plate }} routePrefix={routePrefix} />

      <div className={cs.main}>
        <div className={cs.title}>
          {plate.barcode}
          <ZeroWidthSpaceToken />
          <PlateStatus plate={plate} />
        </div>

        {selectionState.selectedWells === undefined ? (
          <div className={cs.heroToolbar}>
            <div className={cs.heroLabel}>
              {time ? (
                <>
                  {time.format(isThisYear ? 'MMM D' : 'MMM D, YYYY')}&ensp;
                  {time.format('h:mm A')} ({time.fromNow()})
                </>
              ) : (
                <>No images yet.</>
              )}
            </div>
            {enableSelection && (
              <PopupMenu
                options={plateActionsOptions}
                trigger={
                  <a
                    className={cs.plateActions}
                    onClick={() => setDropdownOpen(!isDropdownOpen)}
                  >
                    <MicroplateIcon className={cs.plateIcon} />
                    Plate Actions
                  </a>
                }
                open={isDropdownOpen}
                onClose={() => setDropdownOpen(false)}
              />
            )}
          </div>
        ) : (
          <div className={cs.heroToolbar}>
            <div className={cs.selectionHeader}>
              <div className={cs.selectionTitle}>
                Selecting wells for passage ({selectionState.selectedWells.length} / 96)
              </div>
              <div className={cs.heroLabel}>
                Auto-selected 72 wells with &gt;70% confluence
              </div>
            </div>
            <div className={cs.plateActions}>
              <Button
                className={cs.cancelButton}
                onClick={handleCancelPassage}
                label='Cancel'
              />
              <Button
                className={cs.submitButton}
                onClick={handleSubmitPassage}
                label='Submit Passage'
                type='primary'
              />
            </div>
          </div>
        )}

        <div className={cs.unusedArea}></div>

        <div>
          <PlateHero
            plate={plate}
            selectedWells={selectionState.selectedWells}
            onWellToggle={
              selectionState.selectedWells === undefined ? undefined : handleWellToggle
            }
            routePrefix={routePrefix}
          />
        </div>

        <div>
          <PlateConfluenceGraphSidebar plate={plate} />
          <PlateMetadataSidebar plate={plate} />
        </div>
      </div>
    </div>
  )
}

const platePageLoadingPlaceholderFragment = gql(`
  fragment PlatePageLoadingPlaceholderFragment on CulturePlateGraphQL {
    barcode
    ...PlateHeroLoadingPlaceholderFragment
  }
`)

function LoadingPlaceholder({ id, routePrefix }: { id: string; routePrefix: string }) {
  const { data: cached, complete } = useFragment({
    fragment: platePageLoadingPlaceholderFragment,
    fragmentName: 'PlatePageLoadingPlaceholderFragment',
    from: { __typename: 'CulturePlateGraphQL', id },
  })

  return (
    <div>
      <Breadcrumbs plate={{ id }} routePrefix={routePrefix} />

      <div className={cs.main}>
        <div className={cs.title}>
          {cached.barcode}
          <ZeroWidthSpaceToken />
        </div>

        <div className={cs.heroLabel}>&nbsp;</div>

        <div>{complete ? <PlateHeroLoadingPlaceholder cached={cached} /> : null}</div>
      </div>
    </div>
  )
}

const plateStatusFragment = gql(`
  fragment PlateStatusFragment on CulturePlateGraphQL {
    id
    isCheckedIn
    wellCultures {
      id
      status
    }
  }
`)

function PlateStatus(props: { plate: FragmentType<typeof plateStatusFragment> }) {
  const plate = unmaskFragment(plateStatusFragment, props.plate)
  const statuses = new Set(plate.wellCultures.map(c => c.status))

  // All wells are checked out
  if (!plate.isCheckedIn) {
    return <span className={cs.status}>Checked Out</span>
  }

  // Some wells are active
  if (statuses.has(WellCultureStatusGraphQl.Active)) {
    return <span className={cx(cs.status, cs.checkedIn)}>Checked In</span>
  }

  // All wells are either consumed or terminated
  return <span className={cs.status}>Inactive</span>
}
