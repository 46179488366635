import { find, flow, map } from 'lodash/fp'
import { useEffect, useState } from 'react'

import reloadItemsAPI from '~/api/operatorActions/reloadItems'
import Select, { SelectOption } from '~/components/Select'
import { sortCaseInsensitive } from '~/utils/array'

import cs from './reagent_select.scss'

export function ReagentSelect({
  selectedReagent,
  onSelectReagent,
  hideLabel,
  wide,
  disabled,
}: {
  selectedReagent: string | undefined
  onSelectReagent: (reagent: string) => void
  hideLabel?: boolean
  wide?: boolean
  disabled?: boolean
}) {
  const [reagentOptions, setReagentOptions] = useState<SelectOption[]>([])

  const fetchReagentOptions = async () => {
    const _response = await reloadItemsAPI.getReagentOptions()
    const _reagentOptions = flow(
      sortCaseInsensitive,
      map(reagent => ({
        key: reagent,
        label: reagent,
      })),
    )(_response.reagentOptions)
    setReagentOptions(_reagentOptions)
  }

  // Do this once.
  useEffect(() => {
    fetchReagentOptions()
  }, [])

  useEffect(() => {
    if (reagentOptions && reagentOptions[0] && !selectedReagent) {
      onSelectReagent(reagentOptions[0].label)
    }
  }, [selectedReagent, reagentOptions])

  const handleChange = (value: SelectOption) => {
    onSelectReagent(value.label)
  }

  const itemMatchesQuery = (reagent: SelectOption, queryLowerCase: string) =>
    reagent.key.toLowerCase().includes(queryLowerCase)

  return (
    <div>
      <Select<SelectOption>
        label={hideLabel ? undefined : 'Select Reagent Type'}
        items={reagentOptions}
        itemKey='key'
        itemLabelKey='label'
        filterable
        itemMatchesQuery={itemMatchesQuery}
        activeItem={find(['label', selectedReagent], reagentOptions) || null}
        onChange={handleChange}
        className={cs.select}
        triggerClassName={wide ? cs.triggerWide : cs.trigger}
        popoverClassName={wide ? cs.popoverWide : cs.popover}
        disabled={disabled}
      />
    </div>
  )
}
