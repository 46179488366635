import { random, times } from 'lodash'

const LOT_NUMBER_LENGTH = 5

const LOT_NUMBER_CHARS = '0123456789'

export function generateLotNumber(): string {
  const number = times(LOT_NUMBER_LENGTH, () => LOT_NUMBER_CHARS[random(0, 9)]).join('')
  return `#${number}`
}
