import pluralize from 'pluralize'
import { components } from '~/api/desktop/generated-schema'
import { LinkLotsParseDataResponseErrors } from '~/api/operatorActions/linkCellLineLotsToCultures'
import { displayCount } from '~/utils/string'
import { ErrorGroup } from '../../GroupedErrorTable'
import { generateErrorGroups } from '../../generateErrorGroups'
import {
  ParseSpreadsheetDataError,
  getGroupHeaderForSpreadsheetErrorType,
  getSpreadsheetErrorDisplayElement,
} from '../../spreadsheetDataError'

export type LinkLotsParseDataError =
  | ParseSpreadsheetDataError
  | components['schemas']['ParseCultureDataNonexistentPlate']
  | components['schemas']['ParseCultureDataInvalidWell']
  | components['schemas']['ParseCultureDataEmptyWell']

const _getErrorTypeForError = (
  error: LinkLotsParseDataError,
): LinkLotsParseDataError['type'] => {
  return error.type
}

const _getGroupHeaderForErrorType = (
  errorType: LinkLotsParseDataError['type'],
  numErrors: number,
): string => {
  if (errorType === 'nonexistent_plate') {
    return `${displayCount('plate', numErrors)} could not be found.`
  } else if (errorType === 'invalid_well') {
    return `${displayCount('well', numErrors)} ${pluralize('is', numErrors)} invalid based on the plate dimensions.`
  } else if (errorType == 'empty_well') {
    return `${displayCount('well', numErrors)} ${pluralize('has', numErrors)} never contained a live culture.`
  }

  return getGroupHeaderForSpreadsheetErrorType(errorType, numErrors)
}
const _getErrorDisplayElement = (error: LinkLotsParseDataError): React.ReactNode => {
  if (error.type === 'nonexistent_plate') {
    return (
      <span>
        Plate <b>{error.plate_barcode}</b> does not exist.{' '}
        <i>(Row {error.line_number})</i>
      </span>
    )
  } else if (error.type == 'empty_well') {
    return (
      <span>
        <b>{error.well}</b> in plate <b>{error.plate_barcode}</b> has never contained a
        live culture. <i>(Row {error.line_number})</i>
      </span>
    )
  } else if (error.type == 'invalid_well') {
    return (
      <span>
        <b>{error.well}</b> is not valid for plate <b>{error.plate_barcode}</b> with
        plate format {error.plate_format}. <i>(Row {error.line_number})</i>
      </span>
    )
  }
  return getSpreadsheetErrorDisplayElement(error)
}

export const getLinkLotsParseDataErrorGroups = (
  errors: LinkLotsParseDataResponseErrors,
): ErrorGroup[] => {
  return generateErrorGroups<LinkLotsParseDataError, LinkLotsParseDataError['type']>(
    errors.errors,
    _getErrorTypeForError,
    _getGroupHeaderForErrorType,
    _getErrorDisplayElement,
  )
}
