import { useAuth0 } from '@auth0/auth0-react'
import * as Sentry from '@sentry/react'
import { useEffect, useState } from 'react'

function createMetrics(userId: string, email: string, orgId: string) {
  const emailHost = email.substring(email.indexOf('@') + 1)
  return {
    liveCultures: {
      open: () => {
        Sentry.metrics.set(
          'monomer.frontend.monitor.live-cultures.open.users',
          userId,
          {
            tags: { org: orgId, emailHost: emailHost },
          },
        )
        Sentry.metrics.increment(
          'monomer.frontend.monitor.live-cultures.open.count',
          1,
          {
            tags: { org: orgId, emailHost: emailHost },
          },
        )
      },
      cultureHover: (cultureId: string) => {
        Sentry.metrics.set(
          'monomer.frontend.monitor.live-cultures.culture-hover.users',
          userId,
          {
            tags: { org: orgId, emailHost: emailHost },
          },
        )
        Sentry.metrics.set(
          'monomer.frontend.monitor.live-cultures.culture-hover.cultures',
          cultureId,
          {
            tags: { org: orgId, emailHost: emailHost },
          },
        )
        Sentry.metrics.increment(
          'monomer.frontend.monitor.live-cultures.culture-hover.count',
          1,
          {
            tags: { org: orgId, emailHost: emailHost },
          },
        )
      },
    },
    culturePage: {
      open: (cultureId: string) => {
        Sentry.metrics.set('monomer.frontend.monitor.culture-page.open.users', userId, {
          tags: { org: orgId, emailHost: emailHost },
        })
        Sentry.metrics.set(
          'monomer.frontend.monitor.culture-page.open.cultures',
          cultureId,
          {
            tags: { org: orgId, emailHost: emailHost },
          },
        )
        Sentry.metrics.increment(
          'monomer.frontend.monitor.culture-page.open.count',
          1,
          {
            tags: { org: orgId, emailHost: emailHost },
          },
        )
      },
    },
    montageImages: {
      open: (cultureId: string, montageId: string) => {
        Sentry.metrics.set(
          'monomer.frontend.monitor.montage-images.open.users',
          userId,
          {
            tags: { org: orgId, emailHost: emailHost },
          },
        )
        Sentry.metrics.set(
          'monomer.frontend.monitor.montage-images.open.montages',
          `${cultureId} ${montageId}`,
          {
            tags: { org: orgId, emailHost: emailHost },
          },
        )
        Sentry.metrics.increment(
          'monomer.frontend.monitor.montage-images.open.count',
          1,
          {
            tags: { org: orgId, emailHost: emailHost },
          },
        )
      },
      select: (cultureId: string, montageId: string, image: number) => {
        Sentry.metrics.set(
          'monomer.frontend.monitor.montage-images.select.users',
          userId,
          {
            tags: { org: orgId, emailHost: emailHost },
          },
        )
        Sentry.metrics.set(
          'monomer.frontend.monitor.montage-images.select.images',
          `${cultureId} ${montageId} ${image}`,
          {
            tags: { org: orgId, emailHost: emailHost },
          },
        )
        Sentry.metrics.increment(
          'monomer.frontend.monitor.montage-images.select.count',
          1,
          {
            tags: { org: orgId, emailHost: emailHost },
          },
        )
      },
    },
  }
}

const NOOP_METRICS = {
  liveCultures: {
    open: () => {},
    cultureHover: () => {},
  },
  culturePage: {
    open: () => {},
  },
  montageImages: {
    open: () => {},
    select: () => {},
  },
} as ReturnType<typeof createMetrics>

export function useMetrics() {
  const { user } = useAuth0()
  const [metrics, setMetrics] = useState<ReturnType<typeof createMetrics>>(NOOP_METRICS)
  useEffect(() => {
    if (metrics == NOOP_METRICS) {
      if (user?.sub && user.email && user?.org_id) {
        setMetrics(createMetrics(user.sub, user.email, user.org_id))
      }
    }
  }, [user, metrics])
  return metrics
}
