import { useEffect, useState } from 'react'

import processItemsAPI from '~/api/desktop/processItems'
import { ProcessItem } from '~/common.interface'
import ProcessItemTable from '~/pages/Workcell/components/processItem/ProcessItemTable'
import { useIsMounted } from '~/utils/hooks/useIsMounted'

const CONSUMABLE_TYPES = ['reagent_plate', 'tiprack', 'experiment_plate', 'assay_plate']
const CULTURE_PLATE_TYPES = ['culture_plate']

const ProcessItemList = ({
  className,
  isCheckedIn,
  consumablesOrSamples,
  canEdit,
  searchValue,
  showWorkflowsCompletedWarning,
  showItemsWithoutLocation,
}: {
  className?: string
  isCheckedIn?: boolean
  consumablesOrSamples: 'consumables' | 'samples' | 'both'
  canEdit?: boolean
  searchValue?: string
  showWorkflowsCompletedWarning?: boolean
  showItemsWithoutLocation: boolean
}) => {
  const isMounted = useIsMounted()
  const [loading, setLoading] = useState(false)
  const [processItems, setProcessItems] = useState<ProcessItem[] | null>(null)

  const getProcessItemTypes = () => {
    if (consumablesOrSamples === 'both') {
      return [...CONSUMABLE_TYPES, ...CULTURE_PLATE_TYPES]
    }
    return consumablesOrSamples === 'consumables'
      ? CONSUMABLE_TYPES
      : CULTURE_PLATE_TYPES
  }

  const fetchProcessItems = () => {
    processItemsAPI
      .getProcessItems({
        types: getProcessItemTypes(),
        isCheckedIn,
      })
      .then(processItems => {
        if (!isMounted()) return
        setProcessItems(processItems)
        setLoading(false)
      })
  }

  const refreshProcessItems = () => {
    setProcessItems([])
    setLoading(true)
    fetchProcessItems()
  }

  useEffect(() => {
    refreshProcessItems()
  }, [consumablesOrSamples])

  return (
    <ProcessItemTable
      consumablesOrSamples={consumablesOrSamples}
      className={className}
      processItems={processItems}
      canEdit={canEdit || false}
      onProcessItemUpdate={refreshProcessItems}
      loading={loading}
      searchValue={searchValue}
      showLastCheckInTime={isCheckedIn}
      showWorkflowsCompletedWarning={showWorkflowsCompletedWarning}
      showItemsWithoutLocation={showItemsWithoutLocation}
    />
  )
}

export default ProcessItemList
