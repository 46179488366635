// Mostly copied from DatasetDownloadDialog but decoupled data fetching and added data parsing
import cx from 'classnames'
import { any, debounce, filter, map } from 'lodash/fp'
import Papa from 'papaparse'
import { useCallback, useEffect, useState } from 'react'
import { CSVLink } from 'react-csv'
import Editor from 'react-simple-code-editor'

import Dialog from '~/components/Dialog'
import GridTable from '~/components/GridTable'
import Input from '~/components/Input'
import Tabs from '~/components/Tabs'
import Button from '~/components/buttons/Button'
import DownloadIcon from '~/components/icons/DownloadIcon'

import cs from './process_log_download_dialog.scss'

interface CSVData {
  headers: string[]
  rows: object[][]
}

// Filter csv rows by a search query.
// If ANY cell contains the query, that row will match.
// Cell can be number, string, or null
const filterCsvData = (csvData, searchQuery): CSVData | null => {
  if (!csvData) return null
  const filteredRows = filter(
    row => any(cell => cell && String(cell).includes(searchQuery), row),
    csvData.rows,
  )
  return {
    headers: csvData.headers,
    rows: filteredRows,
  }
}

const getColumns = csvData => {
  return csvData.headers.map((header, index) => ({
    name: header,
    width: 150,
    render: row => row[index],
    oneLine: true,
    smallText: true,
    noShrink: true,
  }))
}

interface ProcessLogDownloadDialogProps {
  className?: string
  isOpen: boolean
  data: unknown
  datasetCount: number
  onClose: () => void
}

const ProcessLogDownloadDialog = ({
  className,
  isOpen,
  onClose,
  data,
  datasetCount,
}: ProcessLogDownloadDialogProps) => {
  const parsedCSVData = Papa.parse(Papa.unparse(data)).data
  const csvData: CSVData = { headers: parsedCSVData[0], rows: parsedCSVData.slice(1) }
  const [filteredCsvData, setFilteredCsvData] = useState<CSVData>()
  const [searchQuery, setSearchQuery] = useState('')
  const [loading, setLoading] = useState(true)

  // Only call this once every 500 ms.
  // This function can be a bit slow, so don't call it every time the user types a key.
  const refreshFilteredCsvData = useCallback(
    debounce(500, (_csvData, _searchQuery) => {
      setFilteredCsvData(_csvData && filterCsvData(_csvData, _searchQuery))
      setLoading(false)
    }),
    [],
  )

  useEffect(() => {
    setLoading(true)
    refreshFilteredCsvData(csvData, searchQuery)
    setLoading(false)
  }, [searchQuery, csvData])

  const getCSVData = () => {
    if (!filteredCsvData) return []
    return [filteredCsvData.headers, ...filteredCsvData.rows]
  }

  const renderDataTableTab = () => {
    if (!filteredCsvData) return undefined
    return (
      <div className={cs.tableContainer}>
        <GridTable
          columns={getColumns(filteredCsvData)}
          data={filteredCsvData.rows}
          className={cs.table}
        />
      </div>
    )
  }

  const getRawText = () => {
    return filteredCsvData
      ? [
          filteredCsvData.headers.join(','),
          ...map(row => row.join(','), filteredCsvData.rows),
        ].join('\n')
      : ''
  }

  const getNumRows = () => {
    if (!filteredCsvData) return 0
    return filteredCsvData.rows.length
  }

  const renderRawTextTab = () => {
    if (!filteredCsvData) return undefined

    return (
      <div className={cs.editorContainer}>
        {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          <Editor
            className={cs.editor}
            value={getRawText()}
            highlight={_code => _code}
          />
        }
      </div>
    )
  }

  const tabs = [
    {
      key: 'data_table',
      title: 'Data Table',
      panel: renderDataTableTab(),
    },
    {
      key: 'raw_text',
      title: 'Raw Text',
      panel: renderRawTextTab(),
    },
  ]

  const renderContents = () => {
    if (loading) {
      return <div className={cs.bigMessage}>Loading...</div>
    }
    if (!csvData) {
      return <div className={cs.bigMessage}>No data found.</div>
    }

    return (
      <Tabs
        tabs={tabs}
        initialTab='data_table'
        className={cs.tabs}
        panelClassName={cs.tabPanel}
      />
    )
  }

  return (
    <Dialog
      isOpen={isOpen}
      onClose={onClose}
      className={cx(cs.datasetDownloadDialog, className)}
    >
      <div className={cs.header}>
        <div className={cs.text}>
          <div className={cs.title}>Download Data</div>
          <div className={cs.subtitle}>
            {datasetCount} dataset{datasetCount === 1 ? '' : 's'}
            {!loading && (
              <span>
                ,&nbsp;
                {getNumRows()} row{getNumRows() === 1 ? '' : 's'}
              </span>
            )}
          </div>
        </div>
        <div className={cs.fill} />
        <Input
          className={cs.searchInput}
          value={searchQuery}
          onChange={setSearchQuery}
          placeholder='Search...'
        />
        {csvData && (
          <CSVLink
            data={getCSVData()}
            enclosingCharacter=''
            filename='data.csv'
            className={cs.link}
          >
            <Button
              className={cs.button}
              label='Download File (.csv)'
              IconComponent={DownloadIcon}
              type='primary'
            />
          </CSVLink>
        )}
      </div>
      {renderContents()}
    </Dialog>
  )
}

export default ProcessLogDownloadDialog
