import fp, { compact, flatten, includes, map, sortBy } from 'lodash/fp'

import { WorkcellAllowedInstrumentsForItemType } from '~/api/desktop/workcell'
import { AllowedInstrumentForItemTypeResponse } from '~/api/desktop/workcell.interface'
import { EnabledOperatorConfigs } from '~/api/operatorActions/shared'
import { Instrument, ProcessItemType } from '~/common.interface'
import { PopupMenuOption } from '~/components/PopupMenu'
import { isRoboticArm } from '~/utils/instrument'
import {
  ImplementedOperatorAction,
  getOperatorAction,
  getOperatorActionLink,
} from '~/utils/operatorAction'
import {
  convertProcessItemTypeToLoadUnloadItemsProcessItemTypes,
  getDisplayNameForLoadUnloadItemsProcessItemType,
} from '../../OperatorActions/LoadUnloadItems/LoadUnloadItemsProcessItemType.interface'

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const forEach = fp.forEach.convert({ cap: false })

const getMenuOptionForOperatorAction = (actionName, instrumentName) => {
  const action = getOperatorAction(actionName) as ImplementedOperatorAction

  if (!action || !action.name) {
    return null
  }

  return {
    label: action.name,
    to: `${action.link}/${instrumentName}`,
  }
}

const getEnabledOperatorActionsForInstrument = (
  allEnabledOperatorConfigs: EnabledOperatorConfigs,
  instrument: Instrument,
) => {
  const ans: string[] = []

  forEach((config, actionName) => {
    if (includes(instrument.instrumentName, config.instrumentNames)) {
      ans.push(actionName)
    }
  }, allEnabledOperatorConfigs)
  return ans
}

const getAllowedProcessItemTypesForInstrument = (
  workcellAllowedInstrumentsForItemType:
    | WorkcellAllowedInstrumentsForItemType
    | undefined,
  instrument: Instrument,
): ProcessItemType[] => {
  const ans: ProcessItemType[] = []

  forEach(
    (
      allowedInstruments: AllowedInstrumentForItemTypeResponse[],
      processItemType: ProcessItemType,
    ) => {
      if (
        includes(instrument.instrumentName, map('instrument_name', allowedInstruments))
      ) {
        ans.push(processItemType)
      }
    },
    workcellAllowedInstrumentsForItemType,
  )
  return ans
}

export const getReloadActionMenuOptions = (
  allEnabledOperatorConfigs: EnabledOperatorConfigs,
  workcellAllowedInstrumentsForItemType:
    | WorkcellAllowedInstrumentsForItemType
    | undefined,
  instrument: Instrument,
): PopupMenuOption[] => {
  if (allEnabledOperatorConfigs.load_unload_items) {
    const allowedProcessItemTypes: ProcessItemType[] =
      getAllowedProcessItemTypesForInstrument(
        workcellAllowedInstrumentsForItemType,
        instrument,
      )
    const allowedLoadUnloadItemsProcessItemTypes = flatten(
      allowedProcessItemTypes.map(
        convertProcessItemTypeToLoadUnloadItemsProcessItemTypes,
      ),
    )
    const operatorActions = getEnabledOperatorActionsForInstrument(
      allEnabledOperatorConfigs,
      instrument,
    )

    const hasManuallyMove = includes('move_process_items', operatorActions)

    return [
      ...sortBy(
        'label',
        map(
          processItemType => ({
            label: `Reload ${getDisplayNameForLoadUnloadItemsProcessItemType(
              processItemType,
              allEnabledOperatorConfigs.load_unload_items
                .itemTypeDisplayAliases as Record<string, string>,
            )}`,
            to: `${getOperatorActionLink(
              'load_unload_items',
            )}?itemType=${processItemType}&instrumentName=${instrument.instrumentName}`,
          }),
          allowedLoadUnloadItemsProcessItemTypes,
        ),
      ),
      ...compact(
        hasManuallyMove
          ? [
              getMenuOptionForOperatorAction(
                'move_process_items',
                instrument.instrumentName,
              ),
            ]
          : [],
      ),
    ]
  } else {
    const operatorActions = getEnabledOperatorActionsForInstrument(
      allEnabledOperatorConfigs,
      instrument,
    )

    return compact(
      map(
        action => getMenuOptionForOperatorAction(action, instrument.instrumentName),
        operatorActions,
      ),
    )
  }
}

export const getMenuOptionsForInstrumentStatusTile = (
  allEnabledOperatorConfigs: EnabledOperatorConfigs,
  workcellAllowedInstrumentsForItemType:
    | WorkcellAllowedInstrumentsForItemType
    | undefined,
  instrument: Instrument,
): PopupMenuOption[] => {
  let options = [
    ...getReloadActionMenuOptions(
      allEnabledOperatorConfigs,
      workcellAllowedInstrumentsForItemType,
      instrument,
    ),
    {
      label: 'Go to Instrument',
      to: `/workcell/instruments/${instrument.instrumentName}`,
    },
  ]

  if (isRoboticArm(instrument.instrumentType)) {
    options = [
      {
        label: 'Teach Tool',
        to: `/workcell/instruments/${instrument.instrumentName}/teach-tool`,
      },
      ...options,
    ]
  }

  return options
}
