import { useEffect, useState } from 'react'

import desktopAPI from '~/api/desktop'
import RoboticArmInstrumentPage from '~/pages/Workcell/InstrumentPages/RoboticArmInstrumentPage'
import TecanEvoDriver from '~/pages/Workcell/InstrumentPages/TecanEvoDriver/TecanEvoDriver'
import { isRoboticArm } from '~/utils/instrument'

import { isLiconicInstrument } from '../components/instrument/isLiconicInstrument'

import { InstrumentStatus } from '~/common.interface'
import CytationInstrumentPage from './CytationInstrumentPage'
import Dataman70InstrumentPage from './Dataman70InstrumentPage'
import GenericInstrumentPage from './GenericInstrumentPage'
import InhecoScilaInstrumentPage from './InhecoScilaInstrumentPage'
import LiconicInstrumentPage from './LiconicInstrumentPage'
import Ot2InstrumentPage from './Ot2InstrumentPage'
import StaticStorageInstrumentPage from './StaticStorageInstrumentPage'
import cs from './instrument_page.scss'

interface InstrumentPageProps {
  className?: string
  instrumentName: string
  status?: InstrumentStatus
  live?: boolean
}

const InstrumentPage = ({
  className,
  instrumentName,
  status,
  live,
}: InstrumentPageProps) => {
  const [instrument, setInstrument] = useState(null)
  const [loading, setLoading] = useState(true)

  const fetchAndSetInstrument = async () => {
    const _instrument = await desktopAPI.getInstrumentDriverByName(instrumentName)
    setInstrument(_instrument)
  }

  useEffect(() => {
    setLoading(true)
    fetchAndSetInstrument()
    setLoading(false)
  }, [instrumentName])

  if (loading && !instrument) {
    return <div className={cs.bigMessage}>Loading...</div>
  }

  if (!instrument) {
    return <div className={cs.bigMessage}>Instrument does not exist.</div>
  }

  if (!status) {
    return <div className={cs.bigMessage}>Instrument status is not available.</div>
  }

  const { instrumentType } = instrument

  if (instrumentType === 'ot_2') {
    return (
      <Ot2InstrumentPage
        className={className}
        instrument={instrument}
        status={status}
        live={live}
      />
    )
  }
  if (instrumentType === 'dataman_70') {
    return (
      <Dataman70InstrumentPage
        className={className}
        instrument={instrument}
        status={status}
        live={live}
      />
    )
  }
  if (isLiconicInstrument(instrumentType)) {
    return (
      <LiconicInstrumentPage
        className={className}
        instrument={instrument}
        status={status}
        live={live}
      />
    )
  }
  if (instrumentType === 'static_storage') {
    return (
      <StaticStorageInstrumentPage
        className={className}
        instrument={instrument}
        status={status}
        live={live}
      />
    )
  }
  if (instrumentType === 'inheco_scila') {
    return (
      <InhecoScilaInstrumentPage
        className={className}
        instrument={instrument}
        status={status}
        live={live}
      />
    )
  }
  if (instrumentType === 'cytation') {
    return (
      <CytationInstrumentPage
        className={className}
        instrument={instrument}
        status={status}
        live={live}
      />
    )
  }
  if (isRoboticArm(instrumentType)) {
    return (
      <RoboticArmInstrumentPage
        className={className}
        instrument={instrument}
        status={status}
        live={live}
      />
    )
  }
  if (instrumentType === 'tecan_evo') {
    return (
      <TecanEvoDriver
        className={className}
        instrument={instrument}
        status={status}
        live={live}
      />
    )
  }
  return (
    <GenericInstrumentPage
      className={className}
      instrument={instrument}
      status={status}
      live={live}
    />
  )
}

export default InstrumentPage
