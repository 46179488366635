import dayjs from 'dayjs'
import MontageHero from '~/components/MontageHero'
import CloseIcon from '~/components/icons/CloseIcon'
import TrendingFlatIcon from '~/components/icons/TrendingFlatIcon'
import { cultureLineageData } from '../data/generateClePipelinePlates'
import cs from './CultureLineage.scss'

type Neighbor = 'culture' | 'passage' | undefined

const Observation = ({
  culture,
  observationTimestamp,
  before,
  after,
}: {
  culture: (typeof cultureLineageData)[number]
  observationTimestamp: string
  before: Neighbor
  after: Neighbor
}) => {
  const checkedInAt = dayjs(culture.culturePlate.firstCheckedInAt)
  const observationAt = dayjs(observationTimestamp)
  const days = observationAt.diff(checkedInAt, 'days')
  const hours = observationAt.diff(checkedInAt, 'hours') - days * 24
  const confluence = culture.observationHistory.find(
    obs => obs.timestamp === observationTimestamp,
  )?.confluence

  return (
    <div className={cs.filmItem}>
      <div className={cs.observationTime}>
        Day {days}, Hour {hours}
      </div>
      <div className={cs.timeline}>
        <svg width='248' height='12'>
          <circle cx='124' cy='6' r='4' stroke='#2cb1bc' strokeWidth='3' fill='none' />
          {before && (
            <line
              x1='0'
              y1='6'
              x2='118'
              y2='6'
              stroke='#2cb1bc'
              strokeWidth='3'
              strokeDasharray={before === 'passage' ? '6,6' : undefined}
            />
          )}
          {after && (
            <line
              x1='130'
              y1='6'
              x2='248'
              y2='6'
              stroke='#2cb1bc'
              strokeWidth='3'
              strokeDasharray={after === 'passage' ? '6,6' : undefined}
            />
          )}
        </svg>
      </div>
      <div className={cs.observationData}>{confluence} %</div>
      <div className={cs.imagery}>
        <MontageHero
          culture={culture as any}
          observationTimestamp={observationTimestamp}
        />
      </div>
    </div>
  )
}

const Passage = () => {
  return (
    <div className={cs.filmItem}>
      <div className={cs.observationTime}></div>
      <div className={cs.timeline}>
        <svg width='48' height='12'>
          <line
            x1='0'
            y1='6'
            x2='48'
            y2='6'
            stroke='#2cb1bc'
            strokeWidth='3'
            strokeDasharray='6,6'
          />
        </svg>
      </div>
      <div className={cs.observationData}></div>
      <div className={cs.arrow}>
        <TrendingFlatIcon className={cs.arrowIcon} />
      </div>
    </div>
  )
}

const CultureLineage = ({ onClose }) => {
  const items: JSX.Element[] = []
  for (let i = 0; i < cultureLineageData.length; i++) {
    const culture = cultureLineageData[i]

    culture.observationHistory.forEach((obs, obsIndex) => {
      let before: 'culture' | 'passage' | undefined
      if (obsIndex === 0) {
        before = i === 0 ? undefined : 'passage'
      } else {
        before = 'culture'
      }

      let after: 'culture' | 'passage' | undefined
      if (obsIndex === culture.observationHistory.length - 1) {
        after = i === cultureLineageData.length - 1 ? undefined : 'passage'
      } else {
        after = 'culture'
      }

      items.push(
        <Observation
          culture={culture}
          observationTimestamp={obs.timestamp}
          before={before}
          after={after}
        />,
      )
    })

    if (i < cultureLineageData.length - 1) {
      items.push(<Passage />)
    }
  }

  return (
    <div className={cs.container}>
      <div className={cs.header}>
        <div className={cs.headerLeft}>
          <div className={cs.title}>Culture Lineage</div>
          <div className={cs.cultureName}>
            {cultureLineageData[0].culturePlate.id}, Well {cultureLineageData[0].well}
          </div>
        </div>
        <CloseIcon className={cs.closeIcon} onClick={onClose} />
      </div>
      <div className={cs.filmStrip}>{items}</div>
    </div>
  )
}

export default CultureLineage
