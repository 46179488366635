import { get, head } from 'lodash/fp'

import attuneNxtDataScript from '~/assets/exampleScripts/attune_nxt_structure_raw_data.txt'
import azentaPerceptionDataScript from '~/assets/exampleScripts/azenta_perception_hd_structure_raw_data.txt'
import cellacaDataScript from '~/assets/exampleScripts/cellaca_structure_raw_data.txt'
import cellometerDataScript from '~/assets/exampleScripts/cellometer_structure_raw_data.txt'
import cytationS3Script from '~/assets/exampleScripts/cytation_s3_upload.txt'
import cytationDataScript from '~/assets/exampleScripts/cytation_structure_raw_data.txt'
import flex2DataScript from '~/assets/exampleScripts/flex_2_structure_raw_data.txt'
import fluostarOmegaDataScript from '~/assets/exampleScripts/fluostar_omega_structure_raw_data.txt'
import attuneNxtIcon from '~/assets/images/instruments/attune_nxt_tiny.png'
import avantcoFridgeIcon from '~/assets/images/instruments/avantco_fridge_tiny.png'
import azentaPerceptionHdIcon from '~/assets/images/instruments/azenta_perception_hd_tiny.png'
import bbShakerIcon from '~/assets/images/instruments/bb_shaker_tiny.png'
import bioshakeQ1Icon from '~/assets/images/instruments/bioshake_q1_tiny.png'
import cellacaIcon from '~/assets/images/instruments/cellaca_tiny.png'
import cellometerIcon from '~/assets/images/instruments/cellometer_tiny.png'
import coldPlateIcon from '~/assets/images/instruments/coldplate_tiny.png'
import cytationIcon from '~/assets/images/instruments/cytation_tiny.png'
import dataman70Icon from '~/assets/images/instruments/dataman_70_tiny.png'
import flex2Icon from '~/assets/images/instruments/flex_2_tiny.png'
import fluostarOmegaIcon from '~/assets/images/instruments/fluostar_omega_tiny.png'
import inhecoScilaIcon from '~/assets/images/instruments/inheco_scila_tiny.png'
import kx2Icon from '~/assets/images/instruments/kx2_tiny.png'
import liconicLpxIcon from '~/assets/images/instruments/liconic_lpx_tiny.png'
import liconicStrIcon from '~/assets/images/instruments/liconic_str_tiny.png'
import liconicStxIcon from '~/assets/images/instruments/liconic_stx_tiny.png'
import nikonTi2eIcon from '~/assets/images/instruments/nikon_ti2e_tiny.png'
import okoChamberIcon from '~/assets/images/instruments/oko_chamber_tiny.png'
import otHeaterShakerIcon from '~/assets/images/instruments/opentrons_heater_shaker_tiny.png'
import ot2Icon from '~/assets/images/instruments/ot_2_tiny.png'
import otTempModuleIcon from '~/assets/images/instruments/ot_temp_tiny.png'
import pf400Icon from '~/assets/images/instruments/pf_400_tiny.png'
import staticStorageIcon from '~/assets/images/instruments/static_storage_tiny.png'
import tecanEvoIcon from '~/assets/images/instruments/tecan_evo_tiny.png'
import visionM1Icon from '~/assets/images/instruments/vision_m1_tiny.png'

// A map from instrument type to display name.
export const INSTRUMENT_DISPLAY_NAMES = {
  attune_nxt: 'Attune NXT',
  azenta_perception_hd: 'Perception HD',
  bb_shaker: 'Big Bear Plate Shaker',
  bioshake_q1: 'Bioshake Q1',
  coldplate: 'ColdPlate',
  cellaca: 'Cellaca',
  cellometer: 'Cellometer',
  cytation: 'Cytation',
  dataman_70: 'Dataman 70',
  flex_2: 'FLEX2',
  fluostar_omega: 'Fluostar Omega',
  inheco_scila: 'Inheco SCILA',
  kx_2: 'KX-2',
  liconic_lpx: 'Liconic Lpx',
  liconic_stx: 'Liconic Stx',
  liconic_str: 'Liconic Str',
  nikon_ti2e: 'Nikon Ti2-E',
  oko_chamber: 'Okolabs Chamber',
  opentrons_heater_shaker: 'Opentrons Heater Shaker',
  opentrons_temperature_module: 'Opentrons Temperature Module',
  ot_2: 'OT-2',
  pf_400: 'PF400',
  static_storage: 'Static Storage',
  tecan_evo: 'Tecan Evo',
  vision_m1: 'Vision M1',
}

export const INSTRUMENT_CATEGORY = {
  attune_nxt: 'Flow Cytometer',
  bb_shaker: 'Plate Shaker',
  bioshake_q1: 'Plate Shaker',
  coldplate: 'Plate Heater-Chiller',
  cytation: 'Microscope',
  dataman_70: 'Barcode Reader',
  inheco_scila: 'Incubator',
  kx_2: 'Robotic Arm',
  liconic_lpx: 'Storage',
  liconic_stx: 'Incubator',
  liconic_str: 'Fridge',
  nikon_ti2e: 'Microscope',
  oko_chamber: 'Automated Microscope Nest',
  opentrons_heater_shaker: 'Plate Shaker',
  opentrons_temperature_module: 'Plate Heater-Chiller',
  ot_2: 'Liquid Handler',
  pf_400: 'Robotic Arm',
  static_storage: 'Storage',
  tecan_evo: 'Liquid Handler',
  vision_m1: 'Microscope',
}

export const SCRIPT_TYPES = {
  s3Upload: 'get-s3-upload-tuples',
  structureRawData: 'structure-raw-data',
}

export const INSTRUMENT_IMAGES = {
  attune_nxt: attuneNxtIcon,
  azenta_perception_hd: azentaPerceptionHdIcon,
  bb_shaker: bbShakerIcon,
  bioshake_q1: bioshakeQ1Icon,
  coldplate: coldPlateIcon,
  cellaca: cellacaIcon,
  cellometer: cellometerIcon,
  cytation: cytationIcon,
  dataman_70: dataman70Icon,
  flex_2: flex2Icon,
  fluostar_omega: fluostarOmegaIcon,
  inheco_scila: inhecoScilaIcon,
  kx_2: kx2Icon,
  liconic_lpx: liconicLpxIcon,
  liconic_stx: liconicStxIcon,
  liconic_str: liconicStrIcon,
  nikon_ti2e: nikonTi2eIcon,
  oko_chamber: okoChamberIcon,
  opentrons_heater_shaker: otHeaterShakerIcon,
  opentrons_temperature_module: otTempModuleIcon,
  ot_2: ot2Icon,
  pf_400: pf400Icon,
  static_storage: staticStorageIcon,
  tecan_evo: tecanEvoIcon,
  vision_m1: visionM1Icon,
}

export const getInstrumentImage = instrument => {
  if (
    instrument.instrumentType === 'static_storage' &&
    instrument.instrumentName.toLowerCase().includes('fridge')
  ) {
    return avantcoFridgeIcon
  }

  return INSTRUMENT_IMAGES[instrument.instrumentType]
}

// TODO(mark): Fetch this from the instrument server.
export const getSupportedScripts = instrumentType => {
  if (instrumentType === 'cytation') {
    return [
      {
        type: SCRIPT_TYPES.structureRawData,
        metadata: {
          watchType: 'file',
        },
        example: cytationDataScript,
      },
      {
        type: SCRIPT_TYPES.s3Upload,
        metadata: {},
        example: cytationS3Script,
      },
    ]
  }

  if (instrumentType === 'cellaca') {
    return [
      {
        type: SCRIPT_TYPES.structureRawData,
        metadata: {
          watchType: 'dir',
        },
        example: cellacaDataScript,
      },
    ]
  }

  if (instrumentType === 'azenta_perception_hd') {
    return [
      {
        type: SCRIPT_TYPES.structureRawData,
        metadata: {
          watchType: 'file',
        },
        example: azentaPerceptionDataScript,
      },
    ]
  }

  if (instrumentType === 'flex_2') {
    return [
      {
        type: SCRIPT_TYPES.structureRawData,
        metadata: {
          watchType: 'file',
        },
        example: flex2DataScript,
      },
    ]
  }

  if (instrumentType === 'fluostar_omega') {
    return [
      {
        type: SCRIPT_TYPES.structureRawData,
        metadata: {
          watchType: 'file',
        },
        example: fluostarOmegaDataScript,
      },
    ]
  }

  if (instrumentType === 'cellometer') {
    return [
      {
        type: SCRIPT_TYPES.structureRawData,
        metadata: {
          watchType: 'dir',
        },
        example: cellometerDataScript,
      },
    ]
  }

  if (instrumentType === 'attune_nxt') {
    return [
      {
        type: SCRIPT_TYPES.structureRawData,
        metadata: {
          watchType: 'file',
        },
        example: attuneNxtDataScript,
      },
    ]
  }
  return []
}

// Whether the instrument is in a state where it cannot display real-time data.
export const isInstrumentInBadState = instrumentStatus => {
  const executionState = get('execution_state', instrumentStatus)
  const driverState = get('driver_state', instrumentStatus)

  return (
    executionState === 'FAULTED' ||
    executionState === 'DISCONNECTED' ||
    driverState === 'ERROR' ||
    driverState === 'STOPPED'
  )
}

export const getInstrumentErrorMessage = instrumentStatus => {
  const instrumentMessages = get('instrument_messages', instrumentStatus)
  return head(instrumentMessages) || ''
}

export const isRoboticArm = instrumentType => {
  return instrumentType === 'pf_400' || instrumentType === 'kx_2'
}
const STORAGE_INSTRUMENT_TYPES = [
  'static_storage',
  'liconic_lpx',
  'liconic_stx',
  'liconic_str',
  'inheco_scila',
]

export const isStorageInstrument = instrumentType => {
  return STORAGE_INSTRUMENT_TYPES.includes(instrumentType)
}
