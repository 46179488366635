import { flatten, mapValues, round } from 'lodash'
import { getWellMatrix } from '~/utils/microplate'
import {
  DemoConfig,
  getProfileSettings,
} from '../../CommandCenter/SlasDemoConfigDialog/DemoConfig'
import {
  CulturePassaged,
  InitialPlateCulture,
  PlateCreated,
  PlateCreatedKinds,
} from '../DemoEvent'
import { EventState } from '../EventState'
import {
  CultureDatasetViewModel,
  CultureViewModel,
  CultureViewModelMap,
} from '../ViewModels/CultureViewModel'
import {
  getConfluencyForDataset,
  getImagesForDataset,
  getThumbnailsForDataset,
} from '../data/generate/generateDataset'
import { makeUniqueID } from '../data/makeUniqueID'

export function projectCultureViewModels(
  eventState: EventState,
  config: DemoConfig,
  useLocalImages: boolean,
  startAt: { eventIndex: number; cultures: CultureViewModelMap },
): CultureViewModelMap {
  const eventsToApply = eventState.eventLog.slice(startAt.eventIndex)

  const cultures = cloneCultures(startAt.cultures)

  // Evaluating this is ~O(N), so only do it once
  let numCultures = Object.keys(cultures).length

  for (const { data: event } of eventsToApply) {
    if (event.kind === 'PlateCheckedIn' || event.kind === 'PlateSeeded') {
      handlePlateCreated(numCultures, cultures, event, config, useLocalImages)
      const numWells = flatten(getWellMatrix(event.plateFormat)).length
      numCultures += numWells
    } else if (event.kind === 'CulturePassaged') {
      handleCulturePassaged(cultures, event)
    } else if (event.kind === 'CultureMediaExchanged') {
      cultures[event.cultureID].associatedMediaLotIDs.push(event.mediaLotID)
      cultures[event.cultureID]._cultureIsContaminated ||= event._mediaWasContaminated
    } else if (event.kind === 'CultureImaged') {
      const culture = cultures[event.cultureID]
      culture.datasets.push(
        createDataset(
          culture._datasetIndex,
          event.day - culture._dayCreated,
          event.at,
          useLocalImages,
          {
            cultureIsContaminated: culture._cultureIsContaminated,
            globalDay: event.day,
          },
        ),
      )
    } else if (event.kind === 'UserScheduledPassages') {
      for (const cultureID of event.cultureIDs) {
        cultures[cultureID].nextScheduledAction = 'PASSAGE'
      }
    } else if (event.kind === 'UserTerminatedCultures') {
      for (const cultureID of event.cultureIDs) {
        cultures[cultureID].isArchived = true
        cultures[cultureID]._archivedEventTime = event.at
        cultures[cultureID].status = 'TERMINATED'
        cultures[cultureID].nextScheduledAction = null
      }
    } else if (event.kind === 'UserMarkedCulturesOkay') {
      for (const cultureID of event.cultureIDs) {
        cultures[cultureID].latestDayUserMarkedOkay = event.day
      }
    }
  }

  return cultures
}

function cloneCultures(cultures: CultureViewModelMap): CultureViewModelMap {
  // TODO: This is fragile but should be fine for now
  return mapValues(cultures, culture => ({
    ...culture,
    associatedMediaLotIDs: [...culture.associatedMediaLotIDs],
    // Datasets are immutable, so it's sufficient to just clone the list
    datasets: [...culture.datasets],
  }))
}

function handleCulturePassaged(cultures: CultureViewModelMap, event: CulturePassaged) {
  cultures[event.parentCultureID].isArchived = true
  const parentCulture = cultures[event.parentCultureID]
  parentCulture.status = 'CONSUMED'
  parentCulture._archivedEventTime = event.at
  parentCulture.nextScheduledAction = null

  for (const childCultureID of event.childCultureIDs) {
    cultures[childCultureID].parentCultureID = event.parentCultureID
    cultures[childCultureID].passageNumber = parentCulture.passageNumber + 1
  }
}

function handlePlateCreated(
  numCultures: number,
  cultures: CultureViewModelMap,
  event: PlateCreated,
  config: DemoConfig,
  useLocalImages: boolean,
) {
  // Create the cultures in the well-order, rather than relying on mapValues which doesn't
  // guarantee order.
  const plateWells = flatten(getWellMatrix(event.plateFormat))
  const newCultures = plateWells.map((well, indexInPlate) =>
    createCultureViewModel({
      numPreexistingCultures: numCultures,
      plateCreated: event,
      initialPlateCulture: event.wellNameToCulture[well],
      indexInPlate,
      wellName: well,
      eventKind: event.kind,
      config,
      useLocalImages,
    }),
  )

  for (const culture of newCultures) {
    cultures[culture.id] = culture
  }
}

function _getCultureName(cultureNum: number, config: DemoConfig): string {
  const suffix = `_${(cultureNum + 1).toString().padStart(3, '0')}`
  return getProfileSettings(config).platePrefix + suffix
}

function createCultureViewModel({
  numPreexistingCultures,
  plateCreated,
  initialPlateCulture,
  indexInPlate,
  wellName,
  eventKind,
  config,
  useLocalImages,
}: {
  numPreexistingCultures: number
  plateCreated: PlateCreated
  initialPlateCulture: InitialPlateCulture
  indexInPlate: number
  wellName: string
  eventKind: PlateCreatedKinds
  config: DemoConfig
  useLocalImages: boolean
}): CultureViewModel {
  const numExistingCultures = numPreexistingCultures + indexInPlate
  return {
    id: initialPlateCulture.id,
    name: _getCultureName(numExistingCultures, config),
    status: 'ACTIVE',

    plateID: plateCreated.plateID,
    plateName: plateCreated.plateName,
    wellName,

    cellLine: plateCreated.cellLine,
    experimentLabel: plateCreated.experimentLabel,

    // TODO: This is a bit funky because we split the passage from check-in event
    // Ideally we'd put that data on the PlateCheckedIn event, but this is fine for demo code
    parentCultureID: null,
    passageNumber: 1,

    isArchived: false,
    associatedMediaLotIDs: [],

    datasets: [
      createDataset(
        initialPlateCulture.datasetIndex,
        0,
        plateCreated.at,
        useLocalImages,
        {
          cultureIsContaminated: false,
          globalDay: plateCreated.day,
        },
      ),
    ],

    latestDayUserMarkedOkay: -1,
    nextScheduledAction: 'MEDIA_EXCHANGE',

    _dayCreated: plateCreated.day,
    _datasetIndex: initialPlateCulture.datasetIndex,
    _createdEventTime: plateCreated.at,
    _archivedEventTime: undefined,
    _cultureIsContaminated: false,
    creationKind: eventKind,
  }
}

function createDataset(
  datasetIndex: number,
  datasetDay: number,
  eventTime: string,
  useLocalImages: boolean,
  {
    cultureIsContaminated,
    globalDay,
  }: { cultureIsContaminated: boolean; globalDay: number },
): CultureDatasetViewModel {
  const cultureId = cultureIsContaminated ? 613 : 726

  const priorConfluency =
    datasetDay > 0 ? getConfluencyForDataset(datasetIndex, datasetDay - 1) : null
  const confluency = cultureIsContaminated
    ? round((priorConfluency ?? 0) * 0.44, 0)
    : getConfluencyForDataset(datasetIndex, datasetDay)

  const doublingTime =
    priorConfluency != null
      ? round(24 / Math.log2(confluency / priorConfluency), 1)
      : null
  return {
    id: makeUniqueID('DATA'),
    confluency,
    doublingTime,
    thumbnails: getThumbnailsForDataset(
      cultureId,
      datasetIndex,
      datasetDay,
      useLocalImages,
    ),
    fullImages: getImagesForDataset(
      cultureId,
      datasetIndex,
      datasetDay,
      useLocalImages,
    ),
    cellDeathDetected: confluency < (priorConfluency ?? 0),
    _datasetDay: datasetDay,
    _globalDayCaptured: globalDay,
    _eventTimeCaptured: eventTime,
  }
}
