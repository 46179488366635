import PropTypes from 'prop-types'
import { IconProps } from './types/IconProps'
const DocsIcon = ({ className, onClick }: IconProps) => (
  <svg
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    className={className}
    onClick={onClick}
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M8 2H20C21.1 2 22 2.89999 22 4V16C22 17.1 21.1 18 20 18H8C6.90002 18 6 17.1 6 16V4C6 2.89999 6.90002 2 8 2ZM2 6H4V20H18V22H4C2.90002 22 2 21.1 2 20V6ZM8 16V4H20V16H8ZM18 9H10V11H18V9ZM10 12H14V14H10V12ZM18 6H10V8H18V6Z'
    />
  </svg>
)

DocsIcon.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
}

export default DocsIcon
