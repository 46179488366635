/**
 * For now, this is a hardcoded list of all well maps that our customers use with our Experiment
 * Plates feature.
 *
 * Experiment Plates might be better off as a subcategory of Reagent Plates - they function
 * similarly to reagents, but with a few extra details:
 *
 *      1. A defining feature of experiment plates is that they can be in a user-defined plate
 *         layout, as opposed to reagent plates, which simply fill and then consume every well
 *         on a plate. This module defines those plate layouts.
 *
 *      2. Experiment plates are usually checked in with a specific purpose in mind, e.g. a
 *         specific set of antibodies for E11's immunostain routine, or FMO controls in the
 *         case of Indee Labs's Count Assay.
 *             (see https://en.wikipedia.org/wiki/Flow_cytometry#FMO_controls)
 *
 * At some point, these well maps should be user-configurable.
 */

import { flatten, indexBy, keys, values } from 'lodash/fp'
import { supportedPlateFormats } from '~/components/TinyMicroplate.interface'
import { isValidWell } from '~/utils/microplate'

export interface LabelledWellMap {
  // Since these will eventually be user-defined, it probably doesn't make sense to create a union
  // of possible labels.
  label: string

  // Maps destWell on a target plate to a list of wells on the experiment plate.
  //
  // For example, E11 has a 6-well plate of brain tissue slices (a culture_plate in our system), and
  // a 96-well plate that has some specific wells filled with antibodies that will go into the
  // wells on the brain plate (an experiment_plate in our system).
  //
  // wellMap in that case has keys A1, B1, A2, B2, A3, B3 (all possible wells on a 6-well plate -
  // the brain plate). wellMap["A1"] is a list of wells on the 96wp experiment plate that should get
  // transferred into well A1 on the brain plate.
  //
  // This was a convenient model for E11, but will likely be reworked over time as we discover more
  // use-cases, or fold it into reagent plates.
  wellMap: { [destWell: string]: string[] }

  fromFormat: supportedPlateFormats
  toFormat: supportedPlateFormats
}

// TODO: For Indee Labs's Stain assay, we'll need to support a dynamic number of FMO controls.
// Currently, experiment plates assume a fixed layout.

const _ALL_LABELLED_WELL_MAPS: LabelledWellMap[] = [
  {
    label: 'primary_immunostain',
    wellMap: {
      A1: ['A1', 'B1'],
      B1: ['D1', 'E1'],
      A2: ['G1', 'H1'],
      B2: ['A3', 'B3'],
      A3: ['D3', 'E3'],
      B3: ['G3', 'H3'],
    },
    fromFormat: 'wells_96',
    toFormat: 'wells_6',
  },
  {
    label: 'secondary_immunostain',
    wellMap: {
      A1: ['A7', 'B7'],
      B1: ['D7', 'E7'],
      A2: ['G7', 'H7'],
      B2: ['A9', 'B9'],
      A3: ['D9', 'E9'],
      B3: ['G9', 'H9'],
    },
    fromFormat: 'wells_96',
    toFormat: 'wells_6',
  },
  {
    label: 'primary_immunostain_3ml',
    wellMap: {
      A1: ['A1', 'B1', 'C1'],
      B1: ['E1', 'F1', 'G1'],
      A2: ['A3', 'B3', 'C3'],
      B2: ['E3', 'F3', 'G3'],
      A3: ['A5', 'B5', 'C5'],
      B3: ['E5', 'F5', 'G5'],
    },
    fromFormat: 'wells_96',
    toFormat: 'wells_6',
  },
  {
    label: 'secondary_immunostain_3ml',
    wellMap: {
      A1: ['A7', 'B7', 'C7'],
      B1: ['E7', 'F7', 'G7'],
      A2: ['A9', 'B9', 'C9'],
      B2: ['E9', 'F9', 'G9'],
      A3: ['A11', 'B11', 'C11'],
      B3: ['E11', 'F11', 'G11'],
    },
    fromFormat: 'wells_96',
    toFormat: 'wells_6',
  },
  {
    label: '24wp_stamp',
    wellMap: {
      A1: ['A1'],
      A2: ['A2'],
      A3: ['A3'],
      A4: ['A4'],
      A5: ['A5'],
      A6: ['A6'],
      B1: ['B1'],
      B2: ['B2'],
      B3: ['B3'],
      B4: ['B4'],
      B5: ['B5'],
      B6: ['B6'],
      C1: ['C1'],
      C2: ['C2'],
      C3: ['C3'],
      C4: ['C4'],
      C5: ['C5'],
      C6: ['C6'],
      D1: ['D1'],
      D2: ['D2'],
      D3: ['D3'],
      D4: ['D4'],
      D5: ['D5'],
      D6: ['D6'],
    },
    fromFormat: 'wells_24',
    toFormat: 'wells_24',
  },
]

export const WELL_MAPS_BY_LABEL: { [label: string]: LabelledWellMap } = indexBy(
  wellMap => wellMap.label,
  _ALL_LABELLED_WELL_MAPS,
)

export function getExperimentPlateWells(wellMap: LabelledWellMap): string[] {
  return flatten(Object.values(wellMap.wellMap))
}

export function mapExperimentPlateWellsToLinkedPlateWells(wellMap: LabelledWellMap): {
  [expPlateWell: string]: string
} {
  const pairs = Object.entries(wellMap.wellMap).map(
    ([linkedPlateWell, experimentPlateWells]) =>
      experimentPlateWells.map(expWell => [expWell, linkedPlateWell]),
  )
  return Object.fromEntries(flatten(pairs))
}

export const _validateWellMapWithFromFormat = (
  wellMap: LabelledWellMap,
  fromFormat: supportedPlateFormats,
) => {
  for (const fromWells of values(wellMap.wellMap)) {
    for (const fromWell of fromWells) {
      if (!isValidWell(fromWell, fromFormat)) {
        throw new Error(
          `LabelledWellMap ${wellMap.label} has invalid well ${fromWell} for fromFormat ${fromFormat}`,
        )
      }
    }
  }
}

export const _validateWellMapWithToFormat = (
  wellMap: LabelledWellMap,
  toFormat: supportedPlateFormats,
) => {
  for (const toWell of keys(wellMap.wellMap)) {
    if (!isValidWell(toWell, toFormat)) {
      throw new Error(
        `LabelledWellMap ${wellMap.label} has invalid well ${toWell} for toFormat ${toFormat}`,
      )
    }
  }
}

export const validateLabelledWellMap = (labelledWellMap: LabelledWellMap) => {
  _validateWellMapWithFromFormat(labelledWellMap, labelledWellMap.fromFormat)
  _validateWellMapWithToFormat(labelledWellMap, labelledWellMap.toFormat)
}
