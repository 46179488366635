import PropTypes from 'prop-types'
import { IconProps } from './types/IconProps'
const KeyIcon = ({ className, onClick }: IconProps) => (
  <svg
    version='1.1'
    xmlns='http://www.w3.org/2000/svg'
    width='32'
    height='32'
    viewBox='0 0 32 32'
    className={className}
    onClick={onClick}
  >
    <path d='M22 0c-5.523 0-10 4.477-10 10 0 0.626 0.058 1.238 0.168 1.832l-12.168 12.168v6c0 1.105 0.895 2 2 2h2v-2h4v-4h4v-4h4l2.595-2.595c1.063 0.385 2.209 0.595 3.405 0.595 5.523 0 10-4.477 10-10s-4.477-10-10-10zM24.996 10.004c-1.657 0-3-1.343-3-3s1.343-3 3-3 3 1.343 3 3-1.343 3-3 3z' />
  </svg>
)

KeyIcon.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
}

export default KeyIcon
