// This is a table with fewer properties, for "routines" that only have name and parameters.
// For db-backed queued Routines with start and end time, use ScheduledRoutineList.

import { compact } from 'lodash/fp'
import { useEffect, useState } from 'react'
import { FutureRoutine } from '~/api/desktop/future_routine_schemas.interface'
import workflowInstancesAPI, { WorkflowInstance } from '~/api/desktop/workflowInstances'
import Table, { TableColumn } from '~/components/Table'
import MinimalButton from '~/components/buttons/MinimalButton'
import StatusMessage from '~/components/messages/StatusMessage'
import { renderParameters } from '~/pages/Workcell/History/utils/routines'
import { getComparatorString } from '~/utils/array'
import { useIsMounted } from '~/utils/hooks/useIsMounted'
import FutureRoutineQueueFailPopover from './FutureRoutineQueueFailPopover'
import WorkflowInstancePopover from './WorkflowInstancePopover'
import {
  renderFutureRoutinesProcessItem,
  renderFutureRoutinesScheduledFor,
} from './futureRoutinePropRenderers'
import cs from './future_routine_table.scss'

interface FutureRoutineTableProps {
  loading?: boolean
  className?: string
  routines: FutureRoutine[]
  onRoutineCancel?(...args: unknown[]): unknown
  selectable?: boolean
  onRoutineUuidsSelected?: (rowsId: { [key: number]: boolean }) => void
  selectedRoutineUuids?: { [key: number]: boolean }
  disabled?: boolean
  // Whether to display the date for the scheduledFor field, or just the time.
  displayScheduledForDate?: boolean
}

const FutureRoutineTable = ({
  className,
  routines,
  onRoutineCancel,
  loading,
  selectable,
  onRoutineUuidsSelected: onRoutineUuidsSelected,
  selectedRoutineUuids: selectedRoutineUuids,
  disabled,
  displayScheduledForDate,
}: FutureRoutineTableProps) => {
  const isMounted = useIsMounted()
  const [workflowRoutineUuidToWorkflowInstance, setWorkflowUuidToWorkflowInstance] =
    useState<{ [key: string]: WorkflowInstance | undefined } | null>(null)
  const tableColumns: TableColumn<FutureRoutine>[] = [
    {
      name: 'Routine Name',
      width: 250,
      render: row => (
        <>
          <div className={cs.routineName}>
            {row.parentWorkflowRoutineId &&
              workflowRoutineUuidToWorkflowInstance &&
              workflowRoutineUuidToWorkflowInstance[row.parentWorkflowRoutineId] && (
                <WorkflowInstancePopover
                  className={cs.workflowIcon}
                  workflowInstance={
                    workflowRoutineUuidToWorkflowInstance[row.parentWorkflowRoutineId]
                  }
                />
              )}
            {row.name}
          </div>
          {row.queueFailMessage && (
            <FutureRoutineQueueFailPopover queueFailMessage={row.queueFailMessage} />
          )}
        </>
      ),
    },
    {
      name: 'Process Item',
      width: 200,
      render: renderFutureRoutinesProcessItem,
    },
    {
      name: 'Scheduled For',
      width: 'flex',
      verticalCenter: true,
      render: (row: FutureRoutine) =>
        renderFutureRoutinesScheduledFor(row, displayScheduledForDate),
    },
    {
      name: 'Parameters',
      width: 'flex',
      render: (row: FutureRoutine) => renderParameters(row, true),
      smallText: true,
      oneLine: true,
    },
    {
      name: '',
      width: 50,
      showOnHover: true,
      stopPropagation: true,
      render: (row: FutureRoutine) =>
        onRoutineCancel && (
          <MinimalButton label='Cancel' onClick={() => onRoutineCancel(row)} />
        ),
    },
  ]

  const fetchWorkflowInstances = (routines: FutureRoutine[]) => {
    const parentWorkflowRoutineIds = compact(
      routines.map(routine => routine.parentWorkflowRoutineId),
    )
    workflowInstancesAPI
      .bulkRetrieveByWorkflowRoutineUuids(parentWorkflowRoutineIds)
      .then(response => {
        if (isMounted()) {
          setWorkflowUuidToWorkflowInstance(
            response.workflow_routine_uuid_to_workflow_instance,
          )
        }
      })
  }

  useEffect(() => {
    if (routines) {
      fetchWorkflowInstances(routines)
    }
  }, [getComparatorString(routines.map(routine => routine.uuid))])

  if (routines === null) {
    return null
  }

  if (loading) {
    return <StatusMessage className={className} message='Loading routines' />
  }

  return (
    <Table
      columns={tableColumns}
      data={routines}
      rowKey='uuid'
      className={className}
      rowPaddingVariant='rowPaddingLow'
      heightSizing='flexAuto'
      noDataMessage='No routines to display'
      selectableProps={
        selectable && onRoutineUuidsSelected && selectedRoutineUuids
          ? {
              onRowsSelected: onRoutineUuidsSelected,
              selectedRowIds: selectedRoutineUuids,
            }
          : undefined
      }
      disabled={disabled}
    />
  )
}

export default FutureRoutineTable
