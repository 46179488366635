import cx from 'classnames'

import { ProcessItem } from '~/common.interface'
import TextWithOverflow from '~/components/TextWithOverflow'
import PropTypes from '~/propTypes'
import {
  getReagentPlateReagent,
  getTiprackTipType,
  getType,
  getUuid,
} from '~/utils/processItems/common'
import { getExperimentPlateNameDisplay } from '~/utils/processItems/experimentPlate'
import cs from './process_item_name.scss'

interface ProcessItemNameProps {
  className?: string
  processItem?: ProcessItem
}

const ProcessItemName = ({ className, processItem }: ProcessItemNameProps) => {
  if (!processItem) return null

  const type = getType(processItem)

  if (type === 'culture_plate') {
    return <b>{getUuid(processItem)}</b>
  }

  let main: string | null = null

  if (type === 'tiprack') {
    main = getTiprackTipType(processItem)
  }

  if (type === 'experiment_plate') {
    main = getExperimentPlateNameDisplay(processItem)
  }

  if (type === 'reagent_plate') {
    main = getReagentPlateReagent(processItem)
  }

  if (type === 'assay_plate') {
    main = getUuid(processItem)
  }

  return (
    <div className={cx(cs.processItemName, className)}>
      {main && (
        <div className={cs.main}>
          <b>{main}</b>
        </div>
      )}
      <TextWithOverflow
        className={cs.subtext}
        text={getUuid(processItem)}
        popoverContent={getUuid(processItem)}
      />
    </div>
  )
}

ProcessItemName.propTypes = {
  className: PropTypes.string,
  processItem: PropTypes.ProcessItem,
}

export default ProcessItemName
