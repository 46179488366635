import { useCallback, useEffect, useRef } from 'react'

// Use this hook to address potential memory leaks whenever there's an async fetch followed by a
// setState in a component. This will present itself with a React warning like:
// "Can't perform a React state update on an unmounted component."
// Derived from https://stackoverflow.com/questions/58979309/checking-if-a-component-is-unmounted-using-react-hooks
export function useIsMounted() {
  const isMountedRef = useRef(true)
  const isMounted = useCallback(() => isMountedRef.current, [])

  useEffect(() => {
    return () => void (isMountedRef.current = false)
  }, [])

  return isMounted
}
