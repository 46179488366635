import { get } from 'lodash/fp'
import { useEffect, useState } from 'react'
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom'

import workcellAPI from '~/api/desktop/workcell'
import Tabs from '~/components/Tabs'
import usePollAsync from '~/utils/hooks/usePollAsync'
import useTabsWithUrl from '~/utils/hooks/useTabsWithUrlPart'

import { WorkcellStatus } from '~/api/desktop/workcell.interface'
import { MonomerErrorBoundary } from '~/components/MonomerErrorBoundary'
import { getAllInstrumentStatus } from '~/pages/Workcell/utils/workcellStatus'
import Datasets from './Datasets'
import History from './History'
import InstrumentPage from './InstrumentPages/InstrumentPage'
import Integrations from './Integrations'
import OperatorActions from './OperatorActions'
import ProcessItems from './ProcessItems'
import Protocols from './Protocols'
import Schedule from './Schedule'
import WorkcellHeader from './WorkcellHeader'
import WorkcellOverview from './WorkcellOverview'
import WorkcellStatusContext from './WorkcellStatusContext'
import cs from './workcell.scss'

const TABS = [
  {
    key: 'overview',
    title: 'Overview',
  },
  {
    key: 'schedule',
    title: 'Schedule',
  },
  {
    key: 'operator-actions',
    title: 'Operator Actions',
  },
  {
    key: 'process-items',
    title: 'Process Items',
  },
  {
    key: 'data',
    title: 'Data',
  },
  {
    key: 'history',
    title: 'History',
  },
  {
    key: 'protocols',
    title: 'Protocols',
  },
  {
    key: 'integrations',
    title: 'Integrations',
  },
]

// TODO(mark): Show the currently executing steps.

const Workcell = () => {
  const [workcellStatus, setWorkcellStatus] = useState<WorkcellStatus>()
  const [workcellServicesFailedToInitialize, setWorkcellServicesFailedToInitialize] =
    useState(false)
  const [fetchingWorkcellStatus, setFetchingWorkcellStatus] = useState(false)

  const [tabs, selectedTabId, handleTabChange] = useTabsWithUrl(TABS)

  const updateWorkcellStatus = async () => {
    const status = await workcellAPI.status()
    if ('workcell_services_failed_to_initialize' in status) {
      setWorkcellStatus(undefined)
      setWorkcellServicesFailedToInitialize(true)
    } else {
      setWorkcellServicesFailedToInitialize(false)
      setWorkcellStatus(status as WorkcellStatus)
    }
  }

  // Update the instrument state
  const updateWorkcellStatusWrapper = async () => {
    if (fetchingWorkcellStatus) return
    setFetchingWorkcellStatus(true)
    try {
      await updateWorkcellStatus()
    } catch (error) {
      console.error('Failed to fetch workcell status') // eslint-disable-line no-console
      console.error(String(error)) // eslint-disable-line no-console
    }
    setFetchingWorkcellStatus(false)
  }

  // Do this once.
  useEffect(() => {
    updateWorkcellStatusWrapper()
  }, [])

  // Do this regularly.
  usePollAsync(updateWorkcellStatusWrapper, 500)

  return (
    <WorkcellStatusContext.Provider value={workcellStatus}>
      <div className={cs.workcell}>
        <WorkcellHeader
          className={cs.header}
          workcellStatus={workcellStatus}
          setWorkcellStatus={setWorkcellStatus}
          workcellServicesFailedToInitialize={workcellServicesFailedToInitialize}
        />
        <Tabs
          tabs={tabs}
          selectedTabId={selectedTabId}
          className={cs.tabs}
          panelClassName={cs.tabPanel}
          onlyRenderWhenViewed
          empty
          onTabChange={handleTabChange}
        />
        <MonomerErrorBoundary key={selectedTabId}>
          <WorkcellRoutes workcellStatus={workcellStatus} />
        </MonomerErrorBoundary>
      </div>
    </WorkcellStatusContext.Provider>
  )
}

interface WorkcellContentsProps {
  workcellStatus: WorkcellStatus | undefined
}

function WorkcellRoutes({ workcellStatus }: WorkcellContentsProps): JSX.Element {
  const match = useRouteMatch()
  return (
    <Switch>
      <Route
        exact
        path={match.path}
        render={() => <Redirect to={`${match.path}/overview`} />}
      />
      <Route
        path={`${match.path}/overview`}
        render={() => (
          <WorkcellOverview
            instrumentStatus={getAllInstrumentStatus(workcellStatus)}
            live={workcellStatus?.live}
          />
        )}
      />
      <Route path={`${match.path}/schedule`} render={() => <Schedule />} />
      <Route
        path={`${match.path}/operator-actions`}
        render={() => <OperatorActions />}
      />
      <Route path={`${match.path}/integrations`} render={() => <Integrations />} />
      <Route path={`${match.path}/history`} render={() => <History />} />
      <Route path={`${match.path}/process-items`} render={() => <ProcessItems />} />
      <Route
        path={`${match.path}/instruments/:instrument_name([\\w-]+)`}
        render={({ match: _match }) => {
          return (
            <InstrumentPage
              status={get(
                _match.params.instrument_name,
                workcellStatus?.instrument_status,
              )}
              instrumentName={_match.params.instrument_name}
              live={workcellStatus?.live}
            />
          )
        }}
      />
      <Route path={`${match.path}/protocols`} render={() => <Protocols />} />
      <Route path={`${match.path}/data`} render={() => <Datasets />} />
    </Switch>
  )
}

Workcell.propTypes = {}

export default Workcell
