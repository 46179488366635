import { find, map, orderBy } from 'lodash/fp'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'

import desktopAPI from '~/api/desktop'
import Select from '~/components/Select'

interface InstrumentTypeOption {
  label: string
  key: string
}

const InstrumentTypeSelect = ({
  onInstrumentTypeSelect,
  value,
  triggerClassName,
  popoverClassName,
  label,
  className,
}: {
  onInstrumentTypeSelect: (instrumentType: string) => void
  value?: string
  triggerClassName?: string
  popoverClassName?: string
  label?: string
  className?: string
}) => {
  const [instrumentTypeOptions, setInstrumentTypeOptions] = useState<
    InstrumentTypeOption[]
  >([])
  const [loadingTypes, setLoadingTypes] = useState(true)

  const fetchUsers = async () => {
    const instrumentTypes = await desktopAPI.getValidInstrumentTypes()
    setInstrumentTypeOptions(
      orderBy(
        'label',
        'asc',
        map(
          instrumentType => ({
            label: instrumentType,
            key: instrumentType,
          }),
          instrumentTypes,
        ),
      ),
    )
    setLoadingTypes(false)
  }

  useEffect(() => {
    fetchUsers()
  }, [])

  const itemMatchesQuery = (
    instrumentType: InstrumentTypeOption,
    queryLowerCase: string,
  ) => instrumentType.label.toLowerCase().includes(queryLowerCase)

  return (
    <Select<InstrumentTypeOption>
      className={className}
      label={label}
      items={instrumentTypeOptions}
      itemLabelKey='label'
      itemKey='key'
      activeItem={find(['key', value], instrumentTypeOptions) || null}
      onChange={newValue => onInstrumentTypeSelect(newValue.key)}
      loading={loadingTypes}
      loadingMessage='Loading types...'
      placeholder='Select instrument type'
      triggerClassName={triggerClassName}
      popoverClassName={popoverClassName}
      filterable
      itemMatchesQuery={itemMatchesQuery}
    />
  )
}

InstrumentTypeSelect.propTypes = {
  className: PropTypes.string,
  // Returns and accepts the instrument type as a string.
  onInstrumentTypeSelect: PropTypes.func,
  value: PropTypes.string,
  triggerClassName: PropTypes.string,
  popoverClassName: PropTypes.string,
  label: PropTypes.string,
}

export default InstrumentTypeSelect
