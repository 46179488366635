import cx from 'classnames'
import { findIndex, reject, set } from 'lodash/fp'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'

import desktopAPI from '~/api/desktop'
import { getDesktopDomain } from '~/api/desktop/utils'
import Checkbox from '~/components/Checkbox'
import Link from '~/components/Link'
import Table from '~/components/Table'
import Toaster from '~/components/Toaster'
import Button from '~/components/buttons/Button'
import AddIcon from '~/components/icons/AddIcon'
import DeleteIcon from '~/components/icons/DeleteIcon'
import EditIcon from '~/components/icons/EditIcon'
import RefreshIcon from '~/components/icons/RefreshIcon'

import cs from './event_handlers.scss'

// TODO(mark): Make an actual UI for mannging event handlers, instead of linking to the Django API.
const desktopDomain = getDesktopDomain()
const ADD_EVENT_HANDLER_LINK = `${desktopDomain}/api/event-handlers`
const getEditEventHandlerLink = id => `${desktopDomain}/api/event-handlers/${id}`

const EventHandlers = ({ className }) => {
  const [loading, setLoading] = useState(false)
  const [eventHandlers, setEventHandlers] = useState([])

  const fetchEventHandlers = async () => {
    const _eventHandlers = await desktopAPI.getEventHandlers()
    setEventHandlers(_eventHandlers)
    setLoading(false)
  }

  const refreshEventHandlers = () => {
    setEventHandlers([])
    setLoading(true)
    fetchEventHandlers()
  }

  const deleteEventHandler = async id => {
    setEventHandlers(reject(['id', id], eventHandlers))
    await desktopAPI.deleteEventHandler(id)
    Toaster.show({
      message: 'Successfully deleted event handler',
      intent: 'success',
    })
    refreshEventHandlers()
  }

  useEffect(() => {
    refreshEventHandlers()
  }, [])

  const toggleIsActive = async row => {
    const eventHandlerIndex = findIndex(['id', row.id], eventHandlers)
    setEventHandlers(set([eventHandlerIndex, 'isActive'], !row.isActive, eventHandlers))
    try {
      await desktopAPI.updateEventHandler(row.id, {
        isActive: !row.isActive,
      })
      Toaster.show({
        message: 'Successfully modified event handler',
        intent: 'success',
      })
    } catch {
      Toaster.show({
        message: 'Failed to modify event handler',
        intent: 'success',
      })
    }
  }

  const tableColumns = [
    {
      name: 'Event Type',
      width: 400,
      render: row => <div className={cs.eventType}>{row.eventType}</div>,
    },
    {
      name: 'Trigger Properties',
      width: 'flex',
      render: row => (
        <div className={cs.eventProperties}>{JSON.stringify(row.eventProperties)}</div>
      ),
      smallText: true,
    },
    {
      name: 'User Script',
      width: 300,
      render: row =>
        row.handlerMetadata && (
          <div className={cs.scriptName}>{row.handlerMetadata.scriptName}.py</div>
        ),
    },
    {
      name: 'Active',
      width: 100,
      render: row => (
        <div onClick={() => toggleIsActive(row)} className={cx(cs.checkbox, cs.header)}>
          <Checkbox checked={row.isActive} className={cs.checkboxContainer} />
        </div>
      ),
    },
    {
      name: '',
      width: 30,
      render: row => (
        <Link external to={getEditEventHandlerLink(row.id)} className={cs.button}>
          <EditIcon className={cs.icon} />
        </Link>
      ),
      showOnHover: true,
      omitCellPadding: true,
    },
    {
      name: '',
      width: 30,
      render: row => (
        <DeleteIcon className={cs.icon} onClick={() => deleteEventHandler(row.id)} />
      ),
      showOnHover: true,
      omitCellPadding: true,
    },
  ]

  const renderTable = () => {
    if (loading) {
      return <div className={cs.bigMessage}>Loading event handlers...</div>
    }
    if (!loading && (!eventHandlers || eventHandlers.length === 0)) {
      return <div className={cs.bigMessage}>No event handlers found.</div>
    }

    return (
      <Table
        columns={tableColumns}
        data={eventHandlers}
        className={cs.table}
        rowPaddingVariant='rowPaddingLow'
        rowKey='id'
      />
    )
  }

  if (eventHandlers === null) {
    return null
  }

  return (
    <div className={cx(className, cs.eventHandlers)}>
      <div className={cs.controls}>
        <div className={cs.fill} />
        <Button
          className={cs.button}
          label='Refresh Table'
          IconComponent={RefreshIcon}
          onClick={refreshEventHandlers}
        />
        <Button
          type='primary'
          label='Add Event Handler'
          IconComponent={AddIcon}
          external
          to={ADD_EVENT_HANDLER_LINK}
          className={cs.button}
        />
      </div>
      {renderTable()}
    </div>
  )
}

EventHandlers.propTypes = {
  className: PropTypes.string,
}

export default EventHandlers
