import cx from 'classnames'
import WorkflowIcon from '~/components/icons/WorkflowsIcon'

import { WorkflowInstance } from '~/api/desktop/workflowInstances'
import HelpPopover from '~/components/HelpPopover'
import cs from './workflow_instance_popover.scss'

const WorkflowInstancePopover = ({
  workflowInstance,
  className,
}: {
  workflowInstance: WorkflowInstance | undefined
  className?: string
}) => {
  if (!workflowInstance) {
    return null
  }

  // We will continue to add more useful information about the workflow here over time.
  // We may even open up a modal with more information in the future.
  const renderPopover = () => {
    return (
      <div className={cs.popover}>
        Part of workflow <b>{workflowInstance?.workflow_definition_id}</b>
      </div>
    )
  }

  return (
    <HelpPopover
      text={<WorkflowIcon className={cx(cs.workflowIcon, className)} />}
      helpContent={renderPopover()}
      interactionKind='hover'
      smallText={false}
      className={cs.iconContainer}
    />
  )
}

export default WorkflowInstancePopover
