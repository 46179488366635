import MinimalBoldButton from '~/components/buttons/MinimalBoldButton'
import EditIcon from '~/components/icons/EditIcon'
import ProcessItemViz from '~/pages/Workcell/components/processItem/ProcessItemViz'
import PropTypes from '~/propTypes'
import {
  TYPE_STATUS_PILL_CONFIG,
  getProcessItemLocationDisplayString,
  getProcessItemPercentFilled,
  getShortDisplayName,
  getTypeStatusPillLabel,
  getUuid,
  isCulturePlate,
  isExperimentPlate,
  isPooledConsumable,
  isProcessItem,
} from '~/utils/processItems/common'
import { renderStatusPill } from '~/utils/statusPill'

import { ProcessItemLike, ProcessItemLocation } from '~/common.interface'
import { getRemainingExperimentPlateExperiments } from '~/utils/processItems/experimentPlate'
import cs from './process_item_popover.scss'

interface ProcessItemPopoverProps {
  item?: ProcessItemLike
  onEditClick?: () => void
  additionalLabelInfo?: string
  processItemLocation?: ProcessItemLocation
}

const ProcessItemPopover = ({
  item,
  onEditClick,
  additionalLabelInfo,
  processItemLocation,
}: ProcessItemPopoverProps) => {
  const renderLabel = () => {
    return (
      <div className={cs.label}>
        {processItemLocation &&
          getProcessItemLocationDisplayString(processItemLocation)}
        {additionalLabelInfo && <span>, {additionalLabelInfo}</span>}
      </div>
    )
  }
  const renderItemStatusPill = (_item: ProcessItemLike) => {
    return renderStatusPill(getTypeStatusPillLabel(_item), TYPE_STATUS_PILL_CONFIG, {
      small: true,
      className: cs.itemStatusPill,
    })
  }

  const renderInfo = () => {
    if (!item || !isProcessItem(item)) return
    if (isPooledConsumable(item) || isExperimentPlate(item)) {
      const remainingExperiments = getRemainingExperimentPlateExperiments(item)
      return (
        <>
          <div className={cs.info}>ID: {getUuid(item)}</div>
          {isExperimentPlate(item) && remainingExperiments.length > 0 && (
            <div className={cs.experiments}>
              Experiments: {remainingExperiments.join(', ')}
            </div>
          )}
          {getProcessItemPercentFilled(item) === 0 && (
            <div className={cs.emptyWarning}>Plate is empty</div>
          )}
        </>
      )
    }
    if (isCulturePlate(item)) {
      const parentPlate = item.metadata?.parent_plate

      if (parentPlate) {
        return <div className={cs.info}>Passaged from {parentPlate}</div>
      }
    }
    return null
  }

  // TODO: Remove the getUuid check once we refactor the callers to this component.
  // Right now, not all callers pass a valid item prop.
  const showEmpty = !item || !getUuid(item)

  return (
    <div className={cs.processItemPopover} onClick={event => event.stopPropagation()}>
      {renderLabel()}
      {!showEmpty ? (
        <div className={cs.item}>{getShortDisplayName(item)}</div>
      ) : (
        <div className={cs.empty}>Empty</div>
      )}
      {!showEmpty && renderItemStatusPill(item)}
      {item && isProcessItem(item) && (
        <ProcessItemViz processItem={item} className={cs.itemViz} />
      )}
      {renderInfo()}
      {onEditClick && (
        <MinimalBoldButton
          className={cs.editButton}
          label='Edit'
          IconComponent={EditIcon}
          onClick={onEditClick}
        />
      )}
    </div>
  )
}

ProcessItemPopover.propTypes = {
  item: PropTypes.ProcessItem,
  onEditClick: PropTypes.func,
  additionalLabelInfo: PropTypes.string,
  processItemLocation: PropTypes.ProcessItemLocation,
}

export default ProcessItemPopover
