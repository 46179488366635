import LineGraph from '~/components/LineGraph'
import { CultureViewModel } from '../../events/ViewModels/CultureViewModel'

// TODO: For now this doesn't allow rendering axes. We could refactor this with CultureOverview
export function CultureGrowthChart({
  culture,
  className,
}: {
  culture: CultureViewModel
  className?: string
}) {
  return (
    <LineGraph
      className={className}
      layoutOptions={{
        marginTop: 3,
        marginBottom: 3,
        marginLeft: 3,
        marginRight: 3,
      }}
      axisOptions={{
        showYAxis: false,
        showXAxis: false,
      }}
      options={{
        xKey: 'day',
        yKey: 'value',
        lineColor: '#2CB1BC',
        dotSize: 2,
        xDomainMax: 5,
        yDomainMax: 100,
      }}
      data={getProcessedDataForLineGraph(culture)}
    />
  )
}

const getProcessedDataForLineGraph = (
  culture: CultureViewModel,
): { [name: string]: { day: number; value: number }[] } => {
  return {
    [culture.name]: culture.datasets.map((dataset, i) => ({
      day: i,
      value: dataset.confluency,
    })),
  }
}
