import { ReactNode } from 'react'
import Link from '~/components/Link'
import cs from './cle_page_frame.scss'

/**
 * This module is a collection of styling components to help with consistency and implementation
 */

export function ClePageFrame({ children }: { children: ReactNode }) {
  return <div className={cs.pageFrame}>{children}</div>
}

export function ClePageHeader({
  title,
  subtitle,
  backLink,
}: {
  title: ReactNode
  subtitle?: ReactNode
  backLink?: ReactNode
}) {
  return (
    <div className={cs.header}>
      {backLink ?? null}
      <div className={cs.title}>{title}</div>
      {subtitle ? <div className={cs.subtitle}>{subtitle}</div> : null}
    </div>
  )
}

export function CleBackLink({ url, text }: { url: string; text: string }) {
  return (
    <Link to={url} className={cs.goBackLabel}>
      {text}
    </Link>
  )
}
