import cx from 'classnames'
import { Bin, HistogramGeneratorNumber, bin } from 'd3-array'
import { easeLinear } from 'd3-ease'
import { filter, isNumber, merge } from 'lodash/fp'

import D3Chart from '~/components/d3/D3Chart'

import { AxisScale } from 'd3-axis'
import { ScaleLinear, scaleLinear } from 'd3-scale'
import cs from './d3_histogram.scss'

interface BarClickParams {
  top: number
  left: number
  width: number
  height: number
  low: number
  high: number
}

export interface D3HistogramOptions {
  bins: number
  hoverBins: number
  yMaxBuffer: number
  highlightBar: (bin: Bin<number, number>) => boolean
  onBarClick: (bin: BarClickParams) => void
  xDomain: [number, number]
  yDomain: [number, number]
  getBarColor?: (x0: number, x1: number) => string
}

export type D3HistogramData = number[]

const DEFAULT_OPTIONS = {
  yMaxBuffer: 1.2, // How far the y-axis should extend above the max value.
  bins: 10,
  hoverBins: 10, // How big the hover bar is. Can be different from the histogram.
  highlightBar: () => false,
  onBarClick: null,
}

export default class D3Histogram extends D3Chart<D3HistogramData, number, number> {
  chartOptions: D3HistogramOptions

  constructor(
    container,
    layoutOptions = {},
    axisOptions = {},
    chartOptions: Partial<D3HistogramOptions> = {},
  ) {
    super(container, cs.d3Histogram, layoutOptions, axisOptions)
    this.chartOptions = merge(DEFAULT_OPTIONS, chartOptions)
  }

  // Nothing to clean-up for this chart.
  teardown = () => {}

  // When a bar is clicked.
  onHoverBarClick = event => {
    const options = this.chartOptions

    if (options.onBarClick) {
      const data = event.target.__data__
      const rect = event.target.getBoundingClientRect()
      // Send data about the bar to the parent. Used to render tooltips.
      options.onBarClick({
        top: rect.top,
        left: rect.left,
        width: rect.width,
        height: rect.height,
        low: data.x0,
        high: data.x1,
      })
    }
  }

  getXScale = (xDomain: [number, number]): AxisScale<number> => {
    const dim = this.getChartDimensions()

    return scaleLinear().domain(xDomain).range([0, dim.width])
  }

  getYScale = (yDomain: [number, number]): AxisScale<number> => {
    const dim = this.getChartDimensions()

    return scaleLinear().domain(yDomain).range([dim.height, 0])
  }

  getDomains = () => {
    return {
      xDomain: this.chartOptions.xDomain,
      yDomain: this.chartOptions.yDomain,
    }
  }

  updateData = (data: D3HistogramData) => {
    const options = this.chartOptions

    this.rerenderAxis(data)

    if (!this.xScale || !this.yScale) return
    const xScale = this.xScale as ScaleLinear<number, number>
    const yScale = this.yScale

    // set the parameters for the histogram
    const histogram: HistogramGeneratorNumber<number, number> = bin()
      .value(d => d) // I need to give the vector of value
      .domain(this.chartOptions.xDomain) // then the domain of the graphic
      .thresholds(xScale.ticks(options.bins)) // then the numbers of bins

    // And apply this function to data to get the bins
    const bins: Bin<number, number>[] = histogram(data)

    const dim = this.getChartDimensions()

    const filteredBins = filter(_bin => _bin.length > 0, bins)

    const rects = this.dataLayer
      .selectAll<SVGRectElement, Bin<number, number>>('rect')
      .data(filteredBins, (d, i) => i)

    // append the bar rectangles to the svg element
    const rectsUpdate = rects
      .enter()
      .append('rect')
      .attr('x', 1)

      .attr('class', d => cx(cs.bar, options.highlightBar(d) && cs.highlight))
      .attr('transform', d =>
        isNumber(d.x0) ? `translate(${xScale(d.x0)}, ${yScale(0)})` : null,
      )
      .attr('width', d =>
        isNumber(d.x0) && isNumber(d.x1) ? xScale(d.x1) - xScale(d.x0) : null,
      )
      .attr('height', 0)
      .style('fill', d =>
        this.chartOptions.getBarColor && isNumber(d.x0) && isNumber(d.x1)
          ? this.chartOptions.getBarColor(d.x0, d.x1)
          : null,
      )
      .merge(rects)

    // Update and animate.
    rectsUpdate
      .transition()
      .duration(400)
      .ease(easeLinear)
      .attr('transform', d =>
        isNumber(d.x0) ? `translate(${xScale(d.x0)}, ${yScale(d.length)})` : null,
      )
      .attr('width', d =>
        isNumber(d.x0) && isNumber(d.x1) ? xScale(d.x1) - xScale(d.x0) : null,
      )
      .attr('height', d => dim.height - (yScale(d.length) || 0))
      .style('fill', d =>
        this.chartOptions.getBarColor && isNumber(d.x0) && isNumber(d.x1)
          ? this.chartOptions.getBarColor(d.x0, d.x1)
          : null,
      )

    // Exit animation.
    rects
      .exit<Bin<number, number>>()
      .transition()
      .duration(400)
      .attr('transform', d =>
        isNumber(d.x0) ? `translate(${xScale(d.x0)}, ${yScale(0)})` : null,
      )
      .attr('height', 0)
      .remove()

    const hoverHistogram: HistogramGeneratorNumber<number, number> = bin()
      .value(d => d) // I need to give the vector of value
      .domain(this.chartOptions.xDomain) // then the domain of the graphic
      .thresholds(xScale.ticks(options.hoverBins)) // then the numbers of bin

    const hoverBins = hoverHistogram(data)

    this.mouseEventLayer
      .selectAll('rect')
      .data(hoverBins)
      .enter()
      .append('rect')
      .attr('x', 1)
      .attr('transform', d => (isNumber(d.x0) ? `translate(${xScale(d.x0)}, 0)` : null))
      .attr('width', d =>
        isNumber(d.x0) && isNumber(d.x1) ? xScale(d.x1) - xScale(d.x0) : null,
      )
      .attr('height', dim.height)
      .attr('class', d => cx(cs.hoverBar, options.highlightBar(d) && cs.highlight))
      .on('click', this.onHoverBarClick)
  }

  updateChartOptions = options => {
    this.chartOptions = merge(DEFAULT_OPTIONS, options)
    if (this._data) {
      this.updateData(this._data)
    }
  }
}
