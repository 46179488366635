import { DatePicker } from '@blueprintjs/datetime'
import PropTypes from 'prop-types'

import './date_picker.scss'

interface MyDatePickerProps {
  value?: Date
  minDate?: Date
  onChange?(...args: unknown[]): unknown
  showTime: boolean
  showActionsBar: boolean
  canClearSelection?: boolean
  todayButtonText?: string
}

const MyDatePicker = ({
  value,
  onChange,
  minDate,
  showTime,
  showActionsBar,
  canClearSelection,
  todayButtonText,
}: MyDatePickerProps) => {
  return (
    <DatePicker
      onChange={onChange}
      value={value}
      minDate={minDate}
      highlightCurrentDay
      timePrecision={showTime ? 'minute' : undefined}
      timePickerProps={showTime ? { useAmPm: true } : undefined}
      showActionsBar={showActionsBar}
      canClearSelection={canClearSelection}
      todayButtonText={todayButtonText}
    />
  )
}

MyDatePicker.propTypes = {
  value: PropTypes.instanceOf(Date),
  minDate: PropTypes.instanceOf(Date),
  onChange: PropTypes.func,
}

export default MyDatePicker
