import cx from 'classnames'

import { WellCultureStatusGraphQl } from '~/__generated__/graphql'

import cs from './culture_status.scss'

const HUMAN_READABLE_STATUS: { [status in WellCultureStatusGraphQl]: string } = {
  [WellCultureStatusGraphQl.Active]: 'Active',
  [WellCultureStatusGraphQl.Consumed]: 'Consumed',
  [WellCultureStatusGraphQl.Terminated]: 'Terminated',
  [WellCultureStatusGraphQl.CheckedOut]: 'Checked Out',
}

export function CultureStatus({ status }: { status: WellCultureStatusGraphQl }) {
  return (
    <div>
      <span className={cs.statusLabel}>Status: </span>
      <CultureStatusText status={status} />
    </div>
  )
}

export function CultureStatusText({
  status,
  className,
}: { status: WellCultureStatusGraphQl; className?: string }) {
  return (
    <span
      className={cx(
        cs.status,
        status === WellCultureStatusGraphQl.Active && cs.status__active,
        status === WellCultureStatusGraphQl.Consumed && cs.status__consumed,
        status === WellCultureStatusGraphQl.Terminated && cs.status__terminated,
        status === WellCultureStatusGraphQl.CheckedOut && cs.status__checkedout,
        className,
      )}
    >
      {HUMAN_READABLE_STATUS[status]}
    </span>
  )
}
