import LoadingMessage from '~/components/LoadingMessage'
import cs from './loading_action_message.scss'

const LoadingActionMessage = () => {
  return (
    <LoadingMessage label='Loading Action...' className={cs.loadingActionMessage} />
  )
}

export default LoadingActionMessage
