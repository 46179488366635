import {
  CultureMediaExchanged,
  PlateSeeded,
  UserMarkedCulturesOkay,
  UserTerminatedCultures,
} from '../DemoEvent'
import { UserScheduledPassages } from '../DemoEvent'
import { PlateCheckedIn } from '../DemoEvent'
import { CultureImaged } from '../DemoEvent'

export type CultureProcessLogEvent =
  | PlateCheckedIn
  | PlateSeeded
  | CultureImaged
  | CultureMediaExchanged
  | UserScheduledPassages
  | UserTerminatedCultures
  | UserMarkedCulturesOkay
  | { kind: 'CultureConsumed'; cultureID: string; day: number; at: string }

export interface CultureProcessLog {
  events: CultureProcessLogEvent[]
}

export type CultureProcessLogMap = { [cultureID: string]: CultureProcessLog }

export type ProcessLogEventType = 'Operator Action' | 'Workcell Routine'

export interface EventPresentation {
  message: string
  eventType: ProcessLogEventType
  details?: string
}

export const EVENT_TYPE_TO_USER_NAME: { [eventType in ProcessLogEventType]: string } = {
  'Operator Action': 'Mark Zhang',
  'Workcell Routine': 'Cell Culture Workcell',
}

export function EVENT_KIND_TO_PRESENTATION(event: CultureProcessLogEvent): {
  [kind in CultureProcessLogEvent['kind']]: EventPresentation
} {
  return {
    CultureImaged: { message: 'Automated Imaging', eventType: 'Workcell Routine' },
    CultureMediaExchanged: {
      message: 'Media Exchange',
      eventType: 'Workcell Routine',
      details: `Performed using ${(event as CultureMediaExchanged).mediaName}`,
    },
    PlateCheckedIn: { message: 'Check In', eventType: 'Operator Action' },
    PlateSeeded: {
      message: 'Plate Seeded',
      eventType: 'Operator Action',
      details: `Seeded from ${(event as PlateSeeded).plateName}`,
    },
    CultureConsumed: { message: 'Culture Consumed', eventType: 'Workcell Routine' },
    UserTerminatedCultures: {
      message: 'Culture Terminated',
      eventType: 'Operator Action',
    },
    UserMarkedCulturesOkay: {
      message: 'Culture Marked Okay',
      eventType: 'Operator Action',
    },
    UserScheduledPassages: {
      message: 'Passage Scheduled',
      eventType: 'Operator Action',
    },
  }
}

export function getEventUser(event: CultureProcessLogEvent): string {
  const eventType = EVENT_KIND_TO_PRESENTATION(event)[event.kind].eventType
  return EVENT_TYPE_TO_USER_NAME[eventType]
}
