import cx from 'classnames'
import { reject, sortBy } from 'lodash/fp'
import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

import desktopAPI from '~/api/desktop'
import Table from '~/components/Table'
import Toaster from '~/components/Toaster'
import Button from '~/components/buttons/Button'
import AddIcon from '~/components/icons/AddIcon'
import DeleteIcon from '~/components/icons/DeleteIcon'
import EditIcon from '~/components/icons/EditIcon'
import RefreshIcon from '~/components/icons/RefreshIcon'
import ViewIcon from '~/components/icons/ViewIcon'

import RoutineDefinitionDialog from '../../components/routine/RoutineDefinitionDialog'

import { RoutineDefinition } from '../../types/RoutineDefinition.interface'
import RoutineDefinitionCreateOrEditDialog from './RoutineDefinitionCreateOrEditDialog'
import ScheduleRoutineDialog from './ScheduleRoutineDialog'
import cs from './routine_definitions.scss'

const RoutineDefinitions = ({ className }: { className?: string }) => {
  const [selectedRoutine, setSelectedRoutine] = useState<RoutineDefinition | undefined>(
    undefined,
  )
  const [showCreateDialog, setShowCreateDialog] = useState(false)
  const [showSchedulePlatesDialog, setShowSchedulePlatesDialog] = useState(false)
  const [showRoutineDefDialog, setShowRoutineDefDialog] = useState(false)
  const [loading, setLoading] = useState(false)
  const [routineDefinitions, setRoutineDefinitions] = useState<RoutineDefinition[]>([])
  const history = useHistory()

  const fetchRoutineDefinitions = async () => {
    const definitions = await desktopAPI.getRoutineDefinitions()
    setRoutineDefinitions(definitions)
    setLoading(false)
  }

  const refreshRoutineDefinitions = () => {
    setRoutineDefinitions([])
    setLoading(true)
    fetchRoutineDefinitions()
  }

  const deleteRoutineDefinition = async (id: number) => {
    setRoutineDefinitions(reject(['id', id], routineDefinitions))
    await desktopAPI.deleteRoutineDefinition(id)
    Toaster.show({
      message: 'Successfully deleted routine definition',
      intent: 'success',
    })
    refreshRoutineDefinitions()
  }

  const handleViewIconClicked = (routine: RoutineDefinition) => {
    setSelectedRoutine(routine)
    setShowRoutineDefDialog(true)
  }

  const handleScheduleRoutineDialogClose = () => {
    setSelectedRoutine(undefined)
    setShowSchedulePlatesDialog(false)
  }

  const handleRoutineRefDialogClose = () => {
    setSelectedRoutine(undefined)
    setShowRoutineDefDialog(false)
  }

  const handleCreateDialogClosed = () => {
    setShowCreateDialog(false)
    setSelectedRoutine(undefined)
  }

  const handleOnScheduleSuccess = () => {
    history.push('/workcell/schedule/live-timeline')
  }

  const handleRowClicked = (row: RoutineDefinition) => {
    setSelectedRoutine(row)
    setShowSchedulePlatesDialog(true)
  }

  const handleEditIconClicked = (routine: RoutineDefinition) => {
    setSelectedRoutine(routine)
    setShowCreateDialog(true)
  }

  useEffect(() => {
    refreshRoutineDefinitions()
  }, [])

  const tableColumns = [
    {
      name: 'Name',
      width: 300,
      render: (row: RoutineDefinition) => (
        <div className={cs.eventType}>{row.name}</div>
      ),
    },
    {
      name: 'Type',
      width: 200,
      render: (row: RoutineDefinition) => <div className={cs.type}>{row.type}</div>,
    },
    {
      name: 'Template File',
      width: 'flex',
      render: (row: RoutineDefinition) => (
        <div className={cs.templateFile}>{row.templateFile}</div>
      ),
    },
    {
      name: '',
      width: 30,
      render: (row: RoutineDefinition) => (
        <ViewIcon className={cs.icon} onClick={() => handleViewIconClicked(row)} />
      ),
      showOnHover: true,
      omitCellPadding: true,
      stopPropagation: true,
    },
    {
      name: '',
      width: 30,
      render: (row: RoutineDefinition) => (
        <EditIcon className={cs.icon} onClick={() => handleEditIconClicked(row)} />
      ),
      showOnHover: true,
      omitCellPadding: true,
      stopPropagation: true,
    },
    {
      name: '',
      width: 30,
      render: (row: RoutineDefinition) => (
        <DeleteIcon
          className={cs.icon}
          onClick={() => deleteRoutineDefinition(row.id)}
        />
      ),
      showOnHover: true,
      omitCellPadding: true,
      stopPropagation: true,
    },
  ]

  const renderTable = () => {
    if (loading) {
      return <div className={cs.bigMessage}>Loading routine definitions...</div>
    }
    if (!loading && (!routineDefinitions || routineDefinitions.length === 0)) {
      return <div className={cs.bigMessage}>No routine definitions found.</div>
    }

    const sortedRoutineDefinitions = sortBy('name', routineDefinitions)

    return (
      <Table
        columns={tableColumns}
        data={sortedRoutineDefinitions}
        className={cs.table}
        rowPaddingVariant='rowPaddingLow'
        rowKey='id'
        onRowClick={handleRowClicked}
      />
    )
  }

  return (
    <div className={cx(className, cs.routineDefinitions)}>
      <div className={cs.controls}>
        <div className={cs.fill} />
        <Button
          className={cs.button}
          label='Refresh Table'
          IconComponent={RefreshIcon}
          onClick={refreshRoutineDefinitions}
        />
        <Button
          type='primary'
          label='Add Routine Definition'
          IconComponent={AddIcon}
          onClick={() => setShowCreateDialog(true)}
        />
      </div>
      {renderTable()}
      <RoutineDefinitionDialog
        routine={selectedRoutine}
        isOpen={showRoutineDefDialog}
        onClose={handleRoutineRefDialogClose}
      />
      <ScheduleRoutineDialog
        routine={selectedRoutine}
        isOpen={showSchedulePlatesDialog}
        onClose={handleScheduleRoutineDialogClose}
        onScheduleSuccess={handleOnScheduleSuccess}
      />
      <RoutineDefinitionCreateOrEditDialog
        isOpen={showCreateDialog}
        onCreateSuccess={refreshRoutineDefinitions}
        onClose={handleCreateDialogClosed}
        selectedRoutineDefinition={selectedRoutine}
      />
    </div>
  )
}

export default RoutineDefinitions
