import dayjs from 'dayjs'
import { ProcessItem } from '~/common.interface'
import { parseLocationString } from '~/utils/location'

export const getProcessItemLocationDisplayString = (processItem: ProcessItem) => {
  const location = processItem.state?.location
  if (!location) {
    return 'Location Not Assigned'
  } else if (location.locationType === 'transfer_station') {
    return `${location.instrumentName} · ${location.locationParams.transferStationId}`
  } else if (location.locationType === 'storage') {
    const _location = parseLocationString(location.locationParams.locationString)
    return `${location.instrumentName} · Shelf ${_location[0]}, Level ${_location[1]}`
  }
}

export const getProcessItemLastCheckInTimeDisplayString = (
  processItem: ProcessItem,
) => {
  if (!processItem.lastCheckInTime) return null
  return `Loaded ${dayjs(processItem.lastCheckInTime).fromNow()}`
}
