import cx from 'classnames'
import {
  compact,
  find,
  flow,
  map,
  noop,
  orderBy,
  range,
  size,
  sortBy,
  take,
} from 'lodash/fp'

import ReadOnlyField from '~/components/ReadOnlyField'
import MinimalButton from '~/components/buttons/MinimalButton'

import LineGraph from '~/components/LineGraph'
import Link from '~/components/Link'
import Select, { SelectOption } from '~/components/Select'
import BareWarningIcon from '~/components/icons/BareWarningIcon'
import {
  CultureDatasetViewModel,
  CultureViewModel,
  CultureViewModelMap,
  getCultureCreatedAt,
  getCultureIssue,
  getDatasetCreatedAt,
} from '../../events/ViewModels/CultureViewModel'
import { MediaLotViewModelMap } from '../../events/ViewModels/MediaLotViewModel'
import { getRelativeTime, normalizeDayToTime } from '../../events/data/eventTimes'
import {
  getPredictedConfluencyForDataset,
  getPredictedDayForConfluency,
} from '../../events/data/generate/generateDataset'
import { useCommandCenterEventContext } from '../CommandCenterEventsContext'
import { getConfluencyColor } from '../LiveCultures/LiveCultures'
import { getProfileSettings } from '../SlasDemoConfigDialog/DemoConfig'
import {
  getCellLineDisplayLabel,
  getExperimentLabelDisplayLabel,
} from '../displayLabel'
import CultureImageGrid from './CultureImageGrid'
import { EditableNextAction } from './EditableNextAction'
import cs from './culture_overview.scss'

export interface CultureOverviewProps {
  today: number
  culture: CultureViewModel
  allCultures: CultureViewModelMap
  mediaLots: MediaLotViewModelMap
}

const CHART_FIELD_OPTIONS: SelectOption[] = [
  {
    key: 'confluency',
    label: 'Confluency (%)',
  },
]

const ERROR = [2.29, 2.27, 2.56, 1.63, 0.5, 0.37]

const CultureOverview = ({
  today,
  culture,
  allCultures,
  mediaLots,
}: CultureOverviewProps) => {
  const { config } = useCommandCenterEventContext()
  const getProcessedDataForLineGraph = () => {
    const datasets = flow(
      map((dataset: CultureDatasetViewModel) => {
        const day = dataset._datasetDay

        return {
          day,
          value: dataset.confluency,
        }
      }),
      sortBy(point => point.day),
    )(culture.datasets)

    const projectedDatasets = flow(
      map((datasetDay: number) => {
        // For the first datapoint, use value with no error for visual reasons
        if (datasetDay + culture._dayCreated == today) {
          const matchingDataset = find(['_datasetDay', datasetDay], culture.datasets)
          if (matchingDataset) {
            return {
              day: datasetDay,
              value: (matchingDataset as CultureDatasetViewModel).confluency,
              error: 0,
            }
          }
        } else {
          return {
            day: datasetDay,
            value: getPredictedConfluencyForDataset(culture._datasetIndex, datasetDay),
            // Multiply it by 3 so it's more believable.
            error: ERROR[datasetDay] * 3,
          }
        }
      }),
      sortBy(point => (point ? point.day : undefined)),
      compact,
    )(range(today - culture._dayCreated, 6))

    // Do not display projected datasets if cells are dead or no longer active
    if (
      getCultureIssue(today, culture) === 'CELL_DEATH' ||
      culture.status !== 'ACTIVE' ||
      !getProfileSettings(config).showProjectedGrowth
    ) {
      return {
        [culture.name]: datasets,
      }
    }
    return {
      [culture.name]: datasets,
      projectedConfluency: projectedDatasets,
    }
  }

  const renderCultureImages = () => {
    const datasets = orderBy(
      dataset => getDatasetCreatedAt(today, dataset),
      'desc',
      culture.datasets,
    )

    const MAX_IMAGES = 2

    const truncatedDatasets = take(MAX_IMAGES, datasets)

    return (
      <div className={cs.cultureImages}>
        <div className={cs.header}>
          <div className={cs.label}>Images</div>
          <div className={cs.fill} />
          {size(datasets) > MAX_IMAGES && (
            <MinimalButton
              label='View All Images'
              to={`/command-center/culture/${culture.id}/images`}
              type='primary'
            />
          )}
        </div>
        <CultureImageGrid
          className={cs.imageGrid}
          today={today}
          culture={culture}
          datasets={truncatedDatasets}
        />
      </div>
    )
  }

  const lastDataset = culture.datasets[culture.datasets.length - 1]
  const targetConfluencey = 80
  const predictedConfluenceDay = getPredictedDayForConfluency(
    culture._datasetIndex,
    targetConfluencey,
  )
  const daysToConfluenceDay = predictedConfluenceDay - (today - culture._dayCreated)
  const shouldPredictConfluence = today - culture._dayCreated < predictedConfluenceDay
  const displayIntercept = shouldPredictConfluence
    ? {
        // for now, use an x-intercept of max days
        // x: predictedConfluenceDay,
        x: 5,
        y: targetConfluencey,
      }
    : null

  return (
    <div className={cs.cultureOverview}>
      <div className={cs.left}>
        <div className={cs.metadata}>
          <ReadOnlyField
            label={getCellLineDisplayLabel(config)}
            value={
              <Link
                variant='primary'
                to={`/command-center/live-cultures/?cellLine=${culture.cellLine}`}
              >
                {culture.cellLine}
              </Link>
            }
            className={cs.field}
            condensed
          />
          {getProfileSettings(config).showCellBankMetadataField && (
            <ReadOnlyField
              label='Source Cell Bank'
              condensed
              value='WCB1'
              className={cs.field}
            />
          )}
          <ReadOnlyField
            label='Parent Culture'
            condensed
            value={
              culture.parentCultureID != null ? (
                <Link
                  variant='primary'
                  to={`/command-center/culture/${culture.parentCultureID}`}
                >
                  {allCultures[culture.parentCultureID].name}
                </Link>
              ) : (
                'N/A'
              )
            }
            className={cs.field}
          />
          <ReadOnlyField
            label={culture.creationKind === 'PlateSeeded' ? 'Seeded' : 'Checked In'}
            value={getRelativeTime(getCultureCreatedAt(today, culture))}
            className={cs.field}
            condensed
          />
          <ReadOnlyField
            label='Passage Number'
            value={culture.passageNumber}
            className={cs.field}
            condensed
          />
          {getProfileSettings(config).showMediaLots && (
            <ReadOnlyField
              label='Associated Media Lots'
              value={culture.associatedMediaLotIDs.map((lotID, index) => (
                <span key={lotID}>
                  <Link
                    to={`/command-center/reagent-performance/${lotID}`}
                    variant={
                      mediaLots[lotID]._isSecretlyContaminated ? 'error' : 'primary'
                    }
                  >
                    {mediaLots[lotID].name}
                  </Link>
                  {index < culture.associatedMediaLotIDs.length - 1 ? ', ' : null}
                </span>
              ))}
              className={cs.field}
              condensed
            />
          )}
          <ReadOnlyField
            label={getExperimentLabelDisplayLabel(config)}
            value={
              <Link
                variant='primary'
                to={`/command-center/live-cultures/?experimentLabel=${culture.experimentLabel}`}
              >
                {culture.experimentLabel}
              </Link>
            }
            className={cx(cs.field, cs.highlight)}
            condensed
          />
          {culture.status === 'ACTIVE' &&
          getProfileSettings(config).showNextScheduledAction ? (
            <ReadOnlyField
              label='Next Scheduled Action'
              value={<EditableNextAction today={today} culture={culture} />}
              className={cs.field}
              condensed
            />
          ) : null}
          {culture.status === 'ACTIVE' &&
          getProfileSettings(config).showTimeProjectedToBeConfluent &&
          shouldPredictConfluence ? (
            <ReadOnlyField
              label='Projected to be Confluent'
              value={getRelativeTime(normalizeDayToTime(daysToConfluenceDay))}
              className={cs.field}
              condensed
            />
          ) : null}
        </div>
        {renderCultureImages()}
      </div>
      <div className={cs.right}>
        <div className={cs.latestData}>
          <div className={cs.latestDataField}>
            <div className={cs.label}>Confluency</div>
            <div
              className={cx(cs.latestDataFieldValue, cs.fieldValue)}
              style={
                culture.status === 'ACTIVE'
                  ? {
                      color: getConfluencyColor(lastDataset.confluency, true),
                    }
                  : undefined
              }
            >
              {lastDataset.confluency > 80 && culture.status === 'ACTIVE' && (
                <div
                  className={cs.iconContainer}
                  style={{
                    fill: getConfluencyColor(lastDataset.confluency, true),
                  }}
                >
                  <BareWarningIcon className={cs.icon} />
                </div>
              )}
              {lastDataset.confluency}%
            </div>
          </div>
          {getProfileSettings(config).showDoublingTime && (
            <div className={cs.latestDataField}>
              <div className={cs.label}>Doubling Time (hrs)</div>
              <div
                className={cx(
                  cs.latestDataFieldValue,
                  (lastDataset.doublingTime ?? 0) < 0 ? cs.error : cs.fieldValue,
                )}
              >
                {lastDataset.doublingTime ?? 'N/A'}
              </div>
            </div>
          )}
        </div>
        <Select<SelectOption>
          items={CHART_FIELD_OPTIONS}
          itemKey='key'
          itemLabelKey='label'
          label='Data Field'
          activeItem={CHART_FIELD_OPTIONS[0]}
          triggerClassName={cs.chartFieldTrigger}
          popoverClassName={cs.chartFieldPopover}
          className={cs.chartSelect}
          onChange={noop}
        />
        <div className={cs.chartContainer}>
          <LineGraph
            className={cs.lineGraph}
            layoutOptions={{
              marginLeft: 50,
            }}
            axisOptions={{
              xLabel: 'Time (days)',
              yLabel: 'Confluency (%)',
              yTicks: 4,
              xTicks: 4,
              yLabelBuffer: 40,
            }}
            options={{
              xKey: 'day',
              yKey: 'value',
              yDomain: [0, 100],
              xDomain: [0, 5],
              lineColor: '#2CB1BC',
              projectedErrorKey: 'error',
              isProjected: key => key === 'projectedConfluency',
              intercept: displayIntercept ? [displayIntercept] : [],
              interceptColor: '#999',
            }}
            data={getProcessedDataForLineGraph()}
          />
        </div>
      </div>
    </div>
  )
}

export default CultureOverview
