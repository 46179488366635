import { Classes, Popover2 } from '@blueprintjs/popover2'
import cx from 'classnames'
import { get, map, set, toPairs } from 'lodash/fp'

import Table from '~/components/Table'
import MinimalBoldButton from '~/components/buttons/MinimalBoldButton'
import LoadingIcon from '~/components/icons/LoadingIcon'
import PropTypes from '~/propTypes'
import { renderStatusPill } from '~/utils/statusPill'

import { WorkcellStatus } from '~/api/desktop/workcell.interface'
import { InstrumentStatus } from '~/common.interface'
import cs from './instrument_status_popover.scss'
import { getBulkInstrumentStatus } from './utils'

const STATUS_PILL_CONFIG = {
  STOPPED: { type: 'error' },
  FAULTED: { type: 'error' },
  DISCONNECTED: { type: 'error' },
  UNREACHABLE: { type: 'error' },
  ERROR: { type: 'error' },
  BUSY: { type: 'accent' },
  READY: { type: 'success' },
}

type InstrumentStatusRow = InstrumentStatus & {
  name: string
  _link: string
}

const renderName = (row: InstrumentStatusRow) => {
  return <div className={cs.name}>{row.name}</div>
}

const renderStatus = (row: InstrumentStatusRow) => {
  let status: string | undefined = ''

  if (row.driver_state === 'STOPPED') {
    status = row.driver_state
  } else if (row.driver_state === 'UNREACHABLE') {
    status = row.driver_state
  } else if (row.driver_state === 'ERROR') {
    status = row.driver_state
  } else {
    status = row.execution_state
  }

  if (!status) return null

  return (
    <div className={cs.statusContainer}>
      {renderStatusPill(status, STATUS_PILL_CONFIG, {
        className: cs.status,
        popoverContent: get(['instrument_messages', 0], row),
      })}
    </div>
  )
}

const TABLE_COLUMNS = [
  {
    name: 'Instrument',
    width: 150,
    smallText: true,
    omitCellPadding: true,
    render: renderName,
  },
  {
    name: 'Status',
    width: 150,
    omitCellPadding: true,
    rightAlign: true,
    render: renderStatus,
  },
]

const InstrumentStatusPopover = ({
  workcellStatus,
  performInstrumentRecovery,
}: {
  workcellStatus: WorkcellStatus
  performInstrumentRecovery: () => void
}) => {
  const instrumentStatus: InstrumentStatus = get(
    'instrument_status',
    workcellStatus,
  ) as unknown as InstrumentStatus

  const { type, text } = getBulkInstrumentStatus(workcellStatus)

  const instrumentStatusList = map(
    pairs => ({
      ...pairs[1],
      name: pairs[0],
    }),
    toPairs(instrumentStatus),
  )

  const instrumentsWithLinks = map(
    instrument => set('_link', `/workcell/instruments/${instrument.name}`, instrument),
    instrumentStatusList,
  )

  const renderInstrumentStatusPopover = () => {
    return (
      <>
        <Table
          columns={TABLE_COLUMNS}
          data={instrumentsWithLinks}
          rowKey='name'
          rowPaddingVariant='rowPaddingLow'
          className={cx(cs.table, Classes.POPOVER2_DISMISS)}
          heightSizing='default'
        />
        {(text === 'FAULTED' || text === 'DISCONNECTED') && (
          <div className={cs.controls}>
            <MinimalBoldButton
              className={cx(cs.recoveryButton, Classes.POPOVER2_DISMISS)}
              label='Perform Instrument Recovery'
              onClick={performInstrumentRecovery}
            />
          </div>
        )}
      </>
    )
  }

  if (get('_tempStatus', workcellStatus) === 'LOADING') {
    return <LoadingIcon className={cs.loadingIcon} />
  }

  return (
    <Popover2 content={renderInstrumentStatusPopover()} placement='bottom'>
      <span className={cx(cs.popoverTrigger, cs[type])}>{text}</span>
    </Popover2>
  )
}

InstrumentStatusPopover.propTypes = {
  workcellStatus: PropTypes.shape({
    instrumentStatus: PropTypes.InstrumentStatus,
  }),
  performInstrumentRecovery: PropTypes.func,
}

export default InstrumentStatusPopover
