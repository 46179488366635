import { Position, Toaster } from '@blueprintjs/core'

import cs from './toaster.scss'

/** Singleton toaster instance. */
const AppToaster = Toaster.create({
  position: Position.TOP_RIGHT,
  className: cs.toaster,
})

export default AppToaster
