export const parseLocationString = (locationString: string): [number, number] => {
  const tokens = locationString.split(':')

  if (tokens.length !== 2 || tokens[0][0] !== 's' || tokens[1][0] !== 'l') {
    throw Error(`Location string malformed ${locationString}`)
  }

  return [parseInt(tokens[0].substring(1)), parseInt(tokens[1].substring(1))]
}

export const getLocationString = (shelfIndex, levelIndex): string => {
  return `s${shelfIndex}:l${levelIndex}`
}
