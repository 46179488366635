/**
 * This is a somewhat specific abstraction to the ScheduleContinuousCultureAction operator action
 * for now. It is consistent with some product patterns (e.g. hoisting a culture plate selector out
 * to be used across all routines), but isn't written in a generalizable way just yet.
 */

import { omit } from 'lodash'
import { useState } from 'react'
import {
  ContinuousCultureRoutineRequest,
  ScheduleContinuousCultureRequest,
} from '~/api/operatorActions/demoScheduleContinuousCulture'
import { ProcessItem } from '~/common.interface'
import { RoutineFormProps } from '~/pages/Workcell/Routines/RoutineParametersForm'
import { areAllParametersSpecified } from '~/pages/Workcell/Routines/areAllParametersSpecified'
import { getSingleSamplePlateInputName } from '~/pages/Workcell/Routines/getSingleSamplePlateInputName'
import {
  RoutineDefinitionWithDSL,
  useRoutineDefinitionsWithDSLs,
} from '~/pages/Workcell/Routines/useRoutineDefinitionsWithDSLs'
import { RoutineParameters } from '~/pages/Workcell/types/RoutineDefinition.interface'

// Slightly hacky - we treat this as a regular input from the form's perspective, but need to pull
// it back out when assembling the final request.
const PERFORM_EVERY_INPUT_NAME = 'perform_every'

interface UseContinuousCultureRequestAssemblerLoading {
  isLoading: true
  errorMessage?: undefined
}

interface UseContinuousCultureRequestAssemblerError {
  isLoading: false
  errorMessage: string
}

export interface UseContinuousCultureRequestAssemblerReady {
  isLoading: false
  errorMessage?: undefined

  culturePlate: ProcessItem | null
  setCulturePlate: (processItem: ProcessItem | null) => void

  // Parameters excluding the culture plate and including the `performEvery` input
  routineFormPropsArray: RoutineFormProps[]

  // Parameters to be passed to the scheduler
  scheduleRequest: ScheduleContinuousCultureRequest | null

  // Called when a schedule is successfully submitted to reset the form
  reset: () => void
}

type UseContinuousCultureRequestAssemblerResult =
  | UseContinuousCultureRequestAssemblerLoading
  | UseContinuousCultureRequestAssemblerError
  | UseContinuousCultureRequestAssemblerReady

type RoutineNameToFormParameters = { [routineName: string]: RoutineParameters }

export function useContinuousCultureRequestAssembler(
  routineNames: string[],
): UseContinuousCultureRequestAssemblerResult {
  const {
    isLoading,
    data: routineDefinitionsWithDSLs,
    error,
  } = useRoutineDefinitionsWithDSLs(routineNames)

  const [culturePlate, setCulturePlate] = useState<ProcessItem | null>(null)

  const [routineNameToFormParameters, setRoutineNameToFormParameters] =
    useState<RoutineNameToFormParameters>({})

  if (isLoading) {
    return { isLoading: true }
  } else if (error != null) {
    return {
      isLoading: false,
      errorMessage: error,
    }
  } else if (
    routineDefinitionsWithDSLs.length === 0 ||
    routineDefinitionsWithDSLs.length !== routineNames.length
  ) {
    // This can happen when the configured routines weren't found
    return {
      isLoading: false,
      errorMessage: `Configured routines could not be found. Expected routines: ${routineNames}`,
    }
  }

  return {
    isLoading: false,

    culturePlate,
    setCulturePlate,

    routineFormPropsArray: routineDefinitionsWithDSLs.map(defin =>
      makeRoutineFormProps({
        routineDefinitionWithDSL: defin,
        parameters: routineNameToFormParameters[defin.name] ?? {},

        onParametersUpdate: (key: string, newValue: unknown) => {
          setRoutineNameToFormParameters({
            ...routineNameToFormParameters,
            [defin.name]: {
              ...(routineNameToFormParameters[defin.name] ?? {}),
              [key]: newValue,
            },
          })
        },
      }),
    ),

    scheduleRequest: maybeAssembleScheduleRequest(
      routineDefinitionsWithDSLs,
      culturePlate,
      routineNameToFormParameters,
    ),

    reset: () => {
      setCulturePlate(null)
      setRoutineNameToFormParameters({})
    },
  }
}

function maybeAssembleScheduleRequest(
  routineDefinitionsWithDSLs: RoutineDefinitionWithDSL[],
  culturePlate: ProcessItem | null,
  routineNameToFormParameters: RoutineNameToFormParameters,
): ScheduleContinuousCultureRequest | null {
  if (!culturePlate) {
    return null
  }

  if (
    !routineDefinitionsWithDSLs.every(defin =>
      areAllParametersSpecified(
        updateDefinitionWithPerformEveryInput(defin),
        mergeCulturePlateWithParameters(
          defin,
          culturePlate,
          routineNameToFormParameters[defin.name] ?? {},
        ),
      ),
    )
  ) {
    return null
  }

  return {
    routine_requests: routineDefinitionsWithDSLs.map(defin =>
      assembleSingleRoutineRequest(
        defin,
        culturePlate,
        routineNameToFormParameters[defin.name] ?? {},
      ),
    ),
  }
}

function mergeCulturePlateWithParameters(
  defin: RoutineDefinitionWithDSL,
  culturePlate: ProcessItem,
  formParameters: RoutineParameters,
): RoutineParameters {
  const samplePlateInputName = getSingleSamplePlateInputName(defin)
  return samplePlateInputName == null
    ? formParameters
    : { ...formParameters, [samplePlateInputName]: culturePlate.uuid }
}

function assembleSingleRoutineRequest(
  defin: RoutineDefinitionWithDSL,
  culturePlate: ProcessItem,
  formParameters: RoutineParameters,
): ContinuousCultureRoutineRequest {
  const parameters = omit(
    mergeCulturePlateWithParameters(defin, culturePlate, formParameters ?? {}),
    PERFORM_EVERY_INPUT_NAME,
  )

  const performEvery = parseFloat(
    (formParameters[PERFORM_EVERY_INPUT_NAME] as string).replace(' hours', ''),
  )

  return {
    name: defin.name,
    parameters: parameters as Record<string, never>,
    every_n_hours: performEvery,
  }
}

function makeRoutineFormProps({
  routineDefinitionWithDSL,
  parameters,
  onParametersUpdate,
}: {
  routineDefinitionWithDSL: RoutineDefinitionWithDSL
  parameters: RoutineParameters
  onParametersUpdate: (key: string, newValue: unknown) => void
}): RoutineFormProps {
  const samplePlateInputName = getSingleSamplePlateInputName(routineDefinitionWithDSL)

  return {
    routineDefinitionWithDSL: updateDefinitionWithPerformEveryInput(
      routineDefinitionWithDSL,
    ),
    parameters,
    onParametersUpdate,
    fieldsToIgnore: samplePlateInputName != null ? [samplePlateInputName] : [],
  }
}

function updateDefinitionWithPerformEveryInput(
  routineDefinitionWithDSL: RoutineDefinitionWithDSL,
): RoutineDefinitionWithDSL {
  // TODO: Are there any typesafe ways to do this better? Lodash's set() has a value type of `any`
  // which would obscure type errors.
  return {
    ...routineDefinitionWithDSL,
    dsl: {
      ...routineDefinitionWithDSL.dsl,
      inputs: {
        ...routineDefinitionWithDSL.dsl.inputs,
        [PERFORM_EVERY_INPUT_NAME]: {
          type: 'unit_float',
          unit: 'hours',
          description: 'How often to perform this routine',
          default: null,
        },
      },
    },
  }
}
