import { get, includes } from 'lodash/fp'

import PropTypes from '~/propTypes'
import { isRoboticArm } from '~/utils/instrument'

import { Instrument } from '~/common.interface'
import { isLiconicInstrument } from '../../components/instrument/isLiconicInstrument'
import StepsExecutedCallout from './StepsExecutedCallout'
import StorageCallout from './StorageCallout'

interface InstrumentStatusCalloutProps {
  instrument: Instrument
  reloadKey: string
}

const InstrumentStatusCallout = ({
  instrument,
  reloadKey,
}: InstrumentStatusCalloutProps) => {
  const instrumentType = get('instrumentType', instrument)
  if (
    includes(instrumentType, ['static_storage', 'inheco_scila']) ||
    isLiconicInstrument(instrumentType)
  ) {
    return <StorageCallout instrument={instrument} reloadKey={reloadKey} />
  }

  // A little bit of hard-coding to exclude certain steps.
  if (includes(instrumentType, ['ot_2', 'tecan_evo'])) {
    return (
      <StepsExecutedCallout
        instrument={instrument}
        reloadKey={reloadKey}
        label='Plates Processed Today'
        stepFilter={step => get(['commandParams', 0], step) !== 'bleach_sterilization'}
      />
    )
  }
  if (includes(instrumentType, ['cytation'])) {
    return (
      <StepsExecutedCallout
        instrument={instrument}
        reloadKey={reloadKey}
        label='Plates Imaged Today'
        commandName='image_plate'
        stepFilter={step => get(['commandParams', 1], step) !== 'PREFLIGHT_PLATE'}
      />
    )
  }
  if (includes(instrumentType, ['attune_nxt'])) {
    return (
      <StepsExecutedCallout
        instrument={instrument}
        reloadKey={reloadKey}
        label='Plates Processed Today'
      />
    )
  }
  if (isRoboticArm(instrumentType)) {
    return (
      <StepsExecutedCallout
        instrument={instrument}
        reloadKey={reloadKey}
        label='Plates Moved Today'
      />
    )
  }
  if (
    includes(instrumentType, ['bb_shaker', 'opentrons_heater_shaker', 'bioshake_q1'])
  ) {
    return (
      <StepsExecutedCallout
        instrument={instrument}
        reloadKey={reloadKey}
        label='Plates Shook Today'
      />
    )
  }
  if (includes(instrumentType, ['opentrons_temperature_module', 'coldplate'])) {
    return (
      <StepsExecutedCallout
        instrument={instrument}
        reloadKey={reloadKey}
        label='Plates Temperature Controlled Today'
      />
    )
  }
  if (includes(instrumentType, ['oko_chamber'])) {
    return (
      <StepsExecutedCallout
        instrument={instrument}
        reloadKey={reloadKey}
        label='Plates Handled Today'
        commandName='lock_plate_and_close_chamber'
      />
    )
  }
  if (includes(instrumentType, ['nikon_ti2e'])) {
    return (
      <StepsExecutedCallout
        instrument={instrument}
        reloadKey={reloadKey}
        label='Plates Imaged Today'
        commandName='run_job'
      />
    )
  }
  if (includes(instrumentType, ['vision_m1'])) {
    return (
      <StepsExecutedCallout
        instrument={instrument}
        reloadKey={reloadKey}
        label='Plates Imaged Today'
        commandName='image_plate'
      />
    )
  }
  return null
}

InstrumentStatusCallout.propTypes = {
  instrument: PropTypes.Instrument,
  reloadKey: PropTypes.string,
}

export default InstrumentStatusCallout
