import { components } from '~/api/desktop/generated-schema'
import { client, unwrap } from '~/api/desktopAPIv2'

export type LinkLotsParseDataRequest = components['schemas']['LinkLotsParseDataRequest']
export type LinkLotsParseDataResponse =
  | LinkLotsParseDataResponseCellLineLots
  | LinkLotsParseDataResponseCellLines
  | LinkLotsParseDataResponseErrors

export type LinkLotsParseDataResponseCellLineLots =
  components['schemas']['LinkLotsParseDataResponseCellLineLots']
export type LinkLotsParseDataResponseCellLines =
  components['schemas']['LinkLotsParseDataResponseCellLines']
export type LinkLotsParseDataResponseErrors =
  components['schemas']['LinkLotsParseDataResponseErrors']

export type LinkLotsParsedCellLineLot =
  components['schemas']['LinkLotsParsedCellLineLot']
export type LinkLotsParsedCellLine = components['schemas']['LinkLotsParsedCellLine']

export type LinkLotsCulturePlateResponse =
  components['schemas']['LinkLotsValidatedCulturePlates']

export type LinkLotsLookupDataRequest =
  | LinkLotsLookupDataRequestCellLineLot
  | LinkLotsLookupDataRequestCellLine

export type LinkLotsLookupDataRequestCellLineLot =
  components['schemas']['LinkLotsLookupDataRequestCellLineLot']
export type LinkLotsLookupDataRequestCellLine =
  components['schemas']['LinkLotsLookupDataRequestCellLine']

export type LinkLotsLookupDataResponse =
  | LinkLotsLookupDataResponseCellLineLots
  | LinkLotsLookupDataResponseCellLines
  | LinkLotsLookupDataResponseErrors

export type LinkLotsLookupDataResponseCellLineLots =
  components['schemas']['LinkLotsLookupDataResponseCellLineLots']
export type LinkLotsLookupDataResponseCellLines =
  components['schemas']['LinkLotsLookupDataResponseCellLines']
export type LinkLotsLookupDataResponseErrors =
  components['schemas']['LinkLotsLookupDataResponseErrors']

export type LinkLotsLookedUpCellLineLot =
  components['schemas']['LinkLotsLookedUpCellLineLot']
export type LinkLotsLookedUpCellLine = components['schemas']['LinkLotsLookedUpCellLine']

export type LinkLotsAddDataRequest =
  | LinkLotsAddDataRequestCellLineLot
  | LinkLotsAddDataRequestCellLine

export type LinkLotsAddDataRequestCellLineLot =
  components['schemas']['LinkLotsAddDataRequestCellLineLot']
export type LinkLotsAddDataRequestCellLine =
  components['schemas']['LinkLotsAddDataRequestCellLine']

export type LinkLotsAddDataResponse = components['schemas']['LinkLotsAddDataResponse']

export type LinkLotsParseDataSchemasResponse =
  components['schemas']['LinkLotsParseDataSchemasResponse']

export default {
  parseData: (request: LinkLotsParseDataRequest): Promise<LinkLotsParseDataResponse> =>
    unwrap(
      client().POST(
        '/api/v2/operator-actions/link-cell-line-lots-to-cultures/parse-data',
        {
          body: request,
        },
      ),
    ),
  lookupData: (
    request: LinkLotsLookupDataRequest,
  ): Promise<LinkLotsLookupDataResponse> =>
    unwrap(
      client().POST(
        '/api/v2/operator-actions/link-cell-line-lots-to-cultures/lookup-data',
        {
          body: request,
        },
      ),
    ),
  addData: (request: LinkLotsAddDataRequest): Promise<LinkLotsAddDataResponse> =>
    unwrap(
      client().POST(
        '/api/v2/operator-actions/link-cell-line-lots-to-cultures/add-data',
        {
          body: request,
        },
      ),
    ),
  getParseDataSchemas: (): Promise<LinkLotsParseDataSchemasResponse> =>
    unwrap(
      client().GET(
        '/api/v2/operator-actions/link-cell-line-lots-to-cultures/parse-data-schemas',
      ),
    ),
}
