import { displayCount } from '~/utils/string'

export const CONFLUENCY_GRAPH_DATA = [
  { day: 0, value: 22.1 },
  { day: 1, value: 29.6 },
  { day: 2, value: 49.4 },
  { day: 3, value: 78.4 },
  { day: 4, value: 93.7 },
  { day: 5, value: 98.2 },
]

export const getSeededAt = (dayToSimulate: number): string => {
  return `${dayToSimulate === 0 ? '2 hours' : displayCount('day', dayToSimulate)} ago`
}

export const getGrowthChartData = (
  dayToSimulate: number,
): typeof CONFLUENCY_GRAPH_DATA => {
  return CONFLUENCY_GRAPH_DATA.slice(0, dayToSimulate + 1)
}
