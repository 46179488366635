import fp, { map, flatten, compact, values, set } from 'lodash/fp'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'

import commonDriverAPI from '~/api/desktop/commonDriver'
import { ProcessItem, StringOnlyProcessItem } from '~/common.interface'
import ProcessItemTable from '~/pages/Workcell/components/processItem/ProcessItemTable'
import { getLocationString } from '~/utils/location'

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const mapValues = fp.mapValues.convert({ cap: false })

const InstrumentProcessItemList = ({
  className,
  instrumentName,
  reloadKey,
  sources,
}: {
  className?: string
  instrumentName: string
  reloadKey?: string
  sources: string[]
}) => {
  const [loading, setLoading] = useState(false)
  const [processItems, setProcessItems] = useState<ProcessItem[] | null>(null)

  // For orphaned items, populate the location manually.
  const fetchProcessItemForSource = async source => {
    if (source === 'transferStations') {
      let transferStations = await commonDriverAPI.getTransferStations(instrumentName)
      transferStations = mapValues(
        (value: ProcessItem | StringOnlyProcessItem, transferStationId: string) =>
          value &&
          set(
            ['state', 'location'],
            {
              instrumentName,
              locationType: 'transfer_station',
              locationParams: {
                transferStationId,
              },
            },
            value,
          ),
        transferStations,
      )

      return compact(values(transferStations))
    }
    if (source === 'storage') {
      let _processItems = await commonDriverAPI.getProcessItems(instrumentName)

      _processItems = map(
        value =>
          set(
            ['processItem', 'state', 'location'],
            {
              instrumentName,
              locationType: 'storage',
              locationParams: {
                locationString: getLocationString(value.shelf, value.level),
              },
            },
            value,
          ),
        _processItems,
      )

      return map('processItem', _processItems)
    }
    return []
  }

  const fetchProcessItems = async () => {
    const processItemArrays = await Promise.all(map(fetchProcessItemForSource, sources))

    setProcessItems(flatten(processItemArrays))
    setLoading(false)
  }

  const refreshProcessItems = () => {
    setProcessItems([])
    setLoading(true)
    fetchProcessItems()
  }

  useEffect(() => {
    refreshProcessItems()
  }, [reloadKey])

  useEffect(() => {
    refreshProcessItems()
  }, [instrumentName])

  return (
    <ProcessItemTable
      className={className}
      processItems={processItems}
      canEdit
      loading={loading}
      consumablesOrSamples='both'
      onProcessItemUpdate={refreshProcessItems}
      showItemsWithoutLocation={true}
    />
  )
}

InstrumentProcessItemList.propTypes = {
  className: PropTypes.string,
  instrumentName: PropTypes.string,
  reloadKey: PropTypes.string,
  sources: PropTypes.arrayOf(PropTypes.oneOf(['transferStations', 'storage'])),
}

export default InstrumentProcessItemList
