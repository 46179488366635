import cx from 'classnames'
import { find, get, range } from 'lodash/fp'
import PropTypes from 'prop-types'
import { ReactNode, useEffect, useState } from 'react'

import commonDriverAPI from '~/api/desktop/commonDriver'
import { Instrument } from '~/common.interface'
import { initializeMatrix } from '~/utils/array'
import { useIsMounted } from '~/utils/hooks/useIsMounted'
import { ProcessItemWithTransferStationId } from '../StorageViz/ProcessItemWithTransferStationId.interface'
import TransferStationVizView from './TransferStationVizView'
import cs from './tecan_transfer_station_viz_view.scss'

/*

Location is [col, row]. 1-indexed. Go left, then down.

Example config:
  "deckDisplayConfig": {
    "numRows": 4,
    "numCols": 4,
    "deckSlotToLocation": {
      "CHILLER_NEST_1": [1, 1]
      "FLAT_NEST_1": [1, 2]
    }
  }
*/
interface DeckDisplayConfig {
  numRows: number
  numCols: number
  deckSlotToLocation?: {
    [deckSlot: string]: [number, number]
  }
}

type DeckSlotGrid = (string | undefined)[][]

const getDeckSlotGrid = (display: DeckDisplayConfig): DeckSlotGrid => {
  const deckSlotGrid: DeckSlotGrid = initializeMatrix<string | undefined>(
    display.numRows,
    display.numCols,
    undefined,
  )

  if (display.deckSlotToLocation) {
    for (const [deckSlotName, [x, y]] of Object.entries(display.deckSlotToLocation)) {
      if (x > 0 && x <= display.numRows && y > 0 && y <= display.numCols) {
        deckSlotGrid[x - 1][y - 1] = deckSlotName
      }
    }
  }

  return deckSlotGrid
}

interface TecanTransferStationVizViewProps {
  className?: string
  transferStations: ProcessItemWithTransferStationId[]
  renderTransferStation: (
    transferStation: ProcessItemWithTransferStationId | undefined,
  ) => ReactNode
  instrument: Instrument
  reloadKey?: string
  hideName?: boolean
}

interface TecanConfig {
  deck_display_config: {
    num_rows: number
    num_cols: number
    deck_slot_to_location?: {
      [deckSlot: string]: [number, number]
    }
  }
}

const TecanTransferStationVizView = ({
  className,
  transferStations,
  renderTransferStation,
  instrument,
  reloadKey,
  hideName,
}: TecanTransferStationVizViewProps) => {
  const isMounted = useIsMounted()
  const [config, setConfig] = useState<TecanConfig | null>(null)
  const fetchData = () => {
    const instrumentName = get('instrumentName', instrument)
    commonDriverAPI.getConfigV2(instrumentName).then(_config => {
      if (!isMounted()) return
      setConfig(_config.config as unknown as TecanConfig)
    })
  }

  useEffect(() => {
    if (instrument) {
      fetchData()
    }
  }, [instrument, reloadKey])

  const renderCell = (deckSlotGrid: DeckSlotGrid, row: number, col: number) => {
    const deckSlotName = deckSlotGrid[row][col]
    const transferStation = find(
      _transferStation => _transferStation.transferStationId === deckSlotName,
      transferStations,
    )
    return (
      <div key={`${deckSlotName}_${row}_${col}`} className={cs.cell}>
        {renderTransferStation(transferStation)}
      </div>
    )
  }

  const getDeckDisplayConfig = (config: TecanConfig): DeckDisplayConfig => {
    return {
      numRows: config.deck_display_config.num_rows,
      numCols: config.deck_display_config.num_cols,
      deckSlotToLocation: config.deck_display_config.deck_slot_to_location,
    }
  }
  if (!config) return null
  const deckDisplayConfig = getDeckDisplayConfig(config)

  if (!deckDisplayConfig) {
    return (
      <TransferStationVizView
        hideName={hideName}
        className={className}
        transferStations={transferStations}
        renderTransferStation={renderTransferStation}
      />
    )
  }
  const deckSlotGrid = getDeckSlotGrid(deckDisplayConfig)

  return (
    <div className={cx(className, cs.tecanTransferStationViz)}>
      {range(0, deckDisplayConfig.numRows).map((i: number) => (
        <div key={i} className={cs.row}>
          {range(0, deckDisplayConfig.numCols).map((j: number) =>
            renderCell(deckSlotGrid, j, i),
          )}
        </div>
      ))}
    </div>
  )
}

TecanTransferStationVizView.propTypes = {
  className: PropTypes.string,
  transferStations: PropTypes.arrayOf(
    PropTypes.shape({
      transferStationId: PropTypes.string,
    }),
  ),
  renderTransferStation: PropTypes.func,
}

export default TecanTransferStationVizView
