import { useEffect, useState } from 'react'
import Dialog from '~/components/Dialog'

import culturePlateWorkflowAPI from '~/api/desktop/culturePlateWorkflow'
import { WorkflowDefinition } from '~/api/desktop/workflowDefinitions'
import { ProcessItem } from '~/common.interface'
import DateInput from '~/components/DateInput'
import Toaster from '~/components/Toaster'
import { WorkflowDefinitionSelect } from '~/pages/Workcell/OperatorActions/LoadUnloadItems/WorkflowDefinitionSelect'
import cs from './create_workflow_dialog.scss'

interface WorkflowEditableFields {
  workflowDefinition: WorkflowDefinition
  workflowStartAt: string
}

interface CreateWorkflowDialogProps {
  culturePlate: ProcessItem
  isOpen: boolean
  onClose: () => void
  onCreateSuccess: () => void
}

const CreateWorkflowDialog = ({
  culturePlate,
  isOpen,
  onCreateSuccess,
  onClose,
}: CreateWorkflowDialogProps) => {
  const [workflowParams, setWorkflowParams] = useState<Partial<WorkflowEditableFields>>(
    {},
  )
  const [isWorking, setIsWorking] = useState(false)

  const resetFields = () => {
    setWorkflowParams({})
  }

  useEffect(() => {
    resetFields()
  }, [isOpen])

  const isValid = () => {
    return (
      workflowParams !== null &&
      !!workflowParams.workflowDefinition &&
      !!workflowParams.workflowStartAt
    )
  }
  const handleSave = async () => {
    if (!isValid()) return
    setIsWorking(true)
    try {
      if (!workflowParams.workflowDefinition || !workflowParams.workflowStartAt) return
      await culturePlateWorkflowAPI.addCulturePlateToWorkflow(culturePlate.uuid, {
        workflow_definition_name: workflowParams.workflowDefinition.name,
        start_at: workflowParams.workflowStartAt,
      })

      onClose()
      setIsWorking(false)
      onCreateSuccess()
      Toaster.show({
        message: `Successfully set workflow for ${culturePlate.uuid}`,
        intent: 'success',
      })
    } catch (e) {
      onClose()
      setIsWorking(false)
      Toaster.show({
        message: `Failed to set workflow for ${culturePlate.uuid}`,
        intent: 'danger',
      })
    }
  }

  return (
    <Dialog isOpen={isOpen} onClose={onClose} className={cs.createWorkflowDialog}>
      <Dialog.Title>Set Workflow for {culturePlate.uuid}</Dialog.Title>
      <div className={cs.contents}>
        <WorkflowDefinitionSelect
          className={cs.workflowDefinitionSelect}
          selectedWorkflowDefinition={workflowParams.workflowDefinition}
          onWorkflowDefinitionSelect={_workflowDefinition => {
            setWorkflowParams({
              ...workflowParams,
              workflowDefinition: _workflowDefinition,
            })
          }}
          triggerClassName={cs.trigger}
          popoverClassName={cs.popover}
          disabled={isWorking}
        />
        <DateInput
          value={workflowParams.workflowStartAt}
          onChange={_workflowStartAt => {
            setWorkflowParams({
              ...workflowParams,
              workflowStartAt: _workflowStartAt || undefined,
            })
          }}
          placeholder={'Select date'}
          defaultDate={new Date()}
          className={cs.dateInput}
          showTime
          showActionsBar
          label='Start Time'
          todayButtonText='Now'
          disabled={isWorking}
        />
      </div>
      <Dialog.Footer loadingMessage={isWorking ? 'Creating Workflow' : undefined}>
        <Dialog.FooterButton label='Cancel' onClick={onClose} />
        <Dialog.FooterButton
          type='primary'
          label='Set Workflow'
          onClick={handleSave}
          disabled={!isValid()}
        />
        <div className={cs.fill} />
      </Dialog.Footer>
    </Dialog>
  )
}

export default CreateWorkflowDialog
