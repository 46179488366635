import cx from 'classnames'
import {
  findIndex,
  get,
  intersection,
  keys,
  map,
  max,
  min,
  pick,
  slice,
} from 'lodash/fp'
import PropTypes from 'prop-types'
import React, { ReactNode, useEffect, useRef, useState } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import { Link } from 'react-router-dom'

import Checkbox from '~/components/Checkbox'
import TextWithOverflow from '~/components/TextWithOverflow'
import { setAll, unsetAll } from '~/utils/object'

import cs from './table.scss'

export interface TableColumn<T> {
  name?: string
  width: number | string // string must be 'flex'
  render: (row: T, hovered: boolean) => React.ReactNode
  smallText?: boolean
  oneLine?: boolean
  omitCellPadding?: boolean
  rightAlign?: boolean
  showOnHover?: boolean
  verticalCenter?: boolean
  stopPropagation?: boolean
  disabled?: boolean
  // Not compatible with oneLine for now.
  receiveHoverParamInRender?: boolean
}

interface InfiniteScrollProps {
  hasMoreData: boolean
  fetchMoreData: () => void
}

export interface TableSelectableProps {
  onRowsSelected: (rowsId: { [key: TableRowId]: boolean }) => void
  selectedRowIds: { [key: TableRowId]: boolean }
}

type TableRowId = string | number

interface TableProps<T> {
  className?: string
  columns: TableColumn<T>[]
  data: T[]
  onRowClick?: (row: T, rowIndex: number) => void
  rowKey?: string | ((row: T) => TableRowId)
  noDataMessage?: ReactNode
  widthOverride?: number
  rowPaddingVariant?: 'rowPaddingLow' | 'rowPaddingHigh'
  heightSizing?: 'flex' | 'default' | 'flexAuto'
  infiniteScrollProps?: InfiniteScrollProps
  selectableProps?: TableSelectableProps
  hideRowBorders?: boolean
  flushLeft?: boolean
  disabled?: boolean
}

const Table = <T extends object>(props: TableProps<T>) => {
  const [rowsWidth, setRowsWidth] = useState<number | null>(null)
  const [lastSelectedRowId, setLastSelectedRowId] = useState<TableRowId | null>(null)
  const rowsRef = useRef<HTMLDivElement>(null)

  const measureCurrentRowsWidth = () => {
    if (rowsRef.current) {
      // Exclude the width of the scrollbars.
      setRowsWidth(rowsRef.current.clientWidth)
    }
  }

  const getTableRowId = (row: T): TableRowId => {
    const { rowKey } = props
    if (!rowKey) {
      return findIndex(r => r === row, props.data)
    }
    if (rowKey instanceof Function) {
      return rowKey(row)
    }
    return get(rowKey, row)
  }

  useEffect(() => {
    measureCurrentRowsWidth()
    window.addEventListener('resize', measureCurrentRowsWidth)
    return () => window.removeEventListener('resize', measureCurrentRowsWidth)
  }, [])

  useEffect(() => {
    const { selectableProps } = props
    if (!selectableProps) return
    const selectedIds = keys(selectableProps.selectedRowIds)
    const allRowIds = map(row => String(getTableRowId(row)), data)

    const newSelectedIds = intersection(selectedIds, allRowIds)
    if (newSelectedIds.length === selectedIds.length) return
    const newSelectedRowIds = pick(newSelectedIds, selectableProps.selectedRowIds)
    selectableProps.onRowsSelected(newSelectedRowIds)
  }, [props.data, props.selectableProps?.selectedRowIds])

  const handleRowCheckboxClicked = (event, row) => {
    const { data, selectableProps } = props
    if (!selectableProps) return
    let { selectedRowIds } = selectableProps

    const rowId = getTableRowId(row)

    let rowIds = [rowId]

    // If the shift key is clicked, select all between currently selected and last selected.
    if (event.shiftKey && lastSelectedRowId) {
      const bounds = [
        findIndex(row => getTableRowId(row) === lastSelectedRowId, data),
        findIndex(row => getTableRowId(row) === rowId, data),
      ]
      rowIds = map(
        row => getTableRowId(row),
        slice<T>(min(bounds) as number, (max(bounds) as number) + 1, data),
      )
    }

    if (selectedRowIds[getTableRowId(row)]) {
      selectedRowIds = unsetAll(rowIds, selectedRowIds)
    } else {
      selectedRowIds = setAll(rowIds, true, selectedRowIds)
    }

    selectableProps.onRowsSelected(selectedRowIds)
    setLastSelectedRowId(getTableRowId(row))
  }

  const areAllRowsSelected = () => {
    const { data, selectableProps } = props
    if (!selectableProps) return false

    const allRowIds = map(row => String(getTableRowId(row)), data)

    const selectedIds = keys(selectableProps.selectedRowIds)

    return (
      allRowIds.length === selectedIds.length &&
      intersection(selectedIds, allRowIds).length === selectedIds.length
    )
  }

  const handleHeaderCheckboxClicked = () => {
    const { data, selectableProps } = props
    if (!selectableProps) return null

    const allSelected = areAllRowsSelected()

    if (allSelected) {
      selectableProps.onRowsSelected({})
    } else {
      const allRowIds = map(getTableRowId, data)
      const newRowIds = setAll(allRowIds, true, {})
      selectableProps.onRowsSelected(newRowIds)
    }

    setLastSelectedRowId(null)
  }

  const getRowStyle = column => {
    if (column.width === 'flex') {
      return {
        flex: '1 1 0',
        minWidth: 0,
      }
    }
    return {
      width: column.width,
    }
  }

  const getHeadersStyle = () => {
    if (rowsWidth) {
      return {
        width: rowsWidth,
      }
    }
    return {}
  }

  // This allows callers to respond to hovers.
  // This implementation feels a bit hacky, but we preferred it over an onMouseEnter/onMouseLeave approach.
  const renderCellContents = (column, row) => {
    if (column.receiveHoverParamInRender) {
      return (
        <>
          <div className={cx(cs.cellContents, cs.showOnHover)}>
            {column.render(row, true)}
          </div>
          <div className={cx(cs.cellContents, cs.hideOnHover)}>
            {column.render(row, false)}
          </div>
        </>
      )
    }

    return column.render(row, false)
  }

  const renderRow = (row, rowIndex) => {
    const { onRowClick, selectableProps, columns, hideRowBorders } = props
    const cells = columns.map((column, index) => {
      const hasLink = row._link && !column.stopPropagation
      return React.createElement(
        hasLink ? Link : 'div',
        {
          className: cx(
            cs.cell,
            column.smallText && cs.smallText,
            column.rightAlign && cs.rightAlign,
            column.omitCellPadding && cs.omitCellPadding,
            column.showOnHover && cs.showOnHover,
            column.verticalCenter && cs.verticalCenter,
          ),
          style: getRowStyle(column),
          key: `${column.name}__${index}`,
          onClick: event => column.stopPropagation && event.stopPropagation(),
          to: row._link,
        },
        column.oneLine ? (
          <TextWithOverflow
            text={column.render(row, false)}
            popoverContent={column.render(row, false)}
          />
        ) : (
          renderCellContents(column, row)
        ),
      )
    })

    const checkboxCell = selectableProps ? (
      <div
        onClick={event => {
          if (!props.disabled) {
            handleRowCheckboxClicked(event, row)
          }
          event.stopPropagation()
        }}
        className={cx(cs.checkbox, cs.cell)}
      >
        <Checkbox
          checked={!!selectableProps.selectedRowIds[getTableRowId(row)]}
          className={cs.checkboxContainer}
          disabled={props.disabled}
        />
      </div>
    ) : null

    return (
      <div
        key={getTableRowId(row)}
        className={cx(
          cs.row,
          hideRowBorders && cs.hideRowBorders,
          onRowClick && cs.withOnClick,
        )}
        onClick={onRowClick ? () => onRowClick(row, rowIndex) : undefined}
      >
        {checkboxCell}
        {cells}
      </div>
    )
  }

  const renderBody = () => {
    const { data, infiniteScrollProps } = props
    const rows = data.map(renderRow)

    if (infiniteScrollProps) {
      return (
        <InfiniteScroll
          dataLength={data.length}
          next={infiniteScrollProps.fetchMoreData}
          hasMore={infiniteScrollProps.hasMoreData}
          loader={<div className={cs.infiniteloading}>Loading...</div>}
          scrollableTarget='rowsContainer'
        >
          {rows}
        </InfiniteScroll>
      )
    }
    return rows
  }

  const {
    className,
    data,
    noDataMessage,
    widthOverride,
    heightSizing,
    rowPaddingVariant,
    columns,
    selectableProps,
    flushLeft,
  } = props

  if (data.length === 0) {
    return (
      <div
        className={cx(
          cs.table,
          className,
          rowPaddingVariant && cs[rowPaddingVariant],
          flushLeft && cs.flushLeft,
        )}
      >
        <div className={cs.noDataMessage}>{noDataMessage || 'No data to display'}</div>
      </div>
    )
  }

  const style = widthOverride
    ? {
        width: widthOverride,
      }
    : undefined

  return (
    <div
      className={cx(
        cs.table,
        className,
        rowPaddingVariant && cs[rowPaddingVariant],
        heightSizing && cs[heightSizing],
        flushLeft && cs.flushLeft,
      )}
      style={style}
    >
      <div className={cs.headers} style={getHeadersStyle()}>
        {selectableProps && (
          <div
            onClick={() => {
              if (!props.disabled) {
                handleHeaderCheckboxClicked()
              }
            }}
            className={cx(cs.checkbox, cs.header)}
          >
            <Checkbox
              checked={areAllRowsSelected()}
              className={cs.checkboxContainer}
              disabled={props.disabled}
            />
          </div>
        )}
        {columns.map((column, index) => (
          <div
            style={getRowStyle(column)}
            key={`${column.name}__${index}`}
            className={cx(
              cs.header,
              column.rightAlign && cs.rightAlign,
              column.omitCellPadding && cs.omitCellPadding,
            )}
          >
            {column.name}
          </div>
        ))}
      </div>
      <div className={cs.rows} id='rowsContainer' ref={rowsRef}>
        {renderBody()}
      </div>
    </div>
  )
}

Table.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      render: PropTypes.func,
      smallText: PropTypes.bool,
      oneLine: PropTypes.bool,
      omitCellPadding: PropTypes.bool,
      rightAlign: PropTypes.bool,
      showOnHover: PropTypes.bool,
      verticalCenter: PropTypes.bool,
    }),
  ),
  data: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
  infiniteScrollProps: PropTypes.shape({
    hasMoreData: PropTypes.bool,
    fetchMoreData: PropTypes.func,
  }),
  className: PropTypes.string,
  onRowClick: PropTypes.func,
  // The field to use for the React key prop.
  rowKey: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  noDataMessage: PropTypes.string,
  // Manually set the width of the table. Otherwise, the table will take on the width of
  // its parent element.
  // Useful if you have a wide table and want a horizontal scrollbar in the parent element.
  widthOverride: PropTypes.number,
  rowPaddingVariant: PropTypes.oneOf(['rowPaddingLow', 'rowPaddingHigh']),
  // Whether to use flex to determine height.
  // If flex is used, the Table's parent element must have an explicit height.
  // flexAuto sets flex-basis to auto, allowing for more sophisticated resizing.
  // Specifically, if max-height is 300px, the rows will not expand to 300px unless needed.
  heightSizing: PropTypes.oneOf(['flex', 'default', 'flexAuto']),
  selectableProps: PropTypes.shape({
    onRowsSelected: PropTypes.func,
    selectedRowIds: PropTypes.objectOf(PropTypes.bool),
  }),
  hideRowBorders: PropTypes.bool,
  // Set a negative margin-left so the label is flush with the left.
  flushLeft: PropTypes.bool,
}

Table.defaultProps = {
  heightSizing: 'flex',
  rowPaddingVariant: 'rowPaddingHigh',
}

export default Table
