/*
  This hook encapsulates fetching paginated results from the back-end.
*/

import { debounce } from 'lodash/fp'
import { useCallback, useEffect, useState } from 'react'

export function getNextCursor(nextUrl) {
  if (!nextUrl) {
    return null
  }
  return new URL(nextUrl).searchParams.get('page')
}

interface PaginatedResults<TResult> {
  count: number
  results: TResult[]
  next: string
}

interface PaginatedResultOptions<TQueryParams> {
  // only start fetch results if query params are valid.
  isQueryParamsValid?: (queryParams: TQueryParams) => boolean
  // the initial query params
  initialQueryParams: TQueryParams
  isResultsEmptyFromQueryParams: (queryParams: TQueryParams) => boolean
}

export default function usePaginatedResults<TResult, TQueryParams>(
  apiEndpoint: (
    params: TQueryParams & { page: string },
  ) => Promise<PaginatedResults<TResult>>,
  options: PaginatedResultOptions<TQueryParams>,
) {
  const [queryParams, setQueryParams] = useState<TQueryParams>(
    options.initialQueryParams,
  )
  const [totalCount, setTotalCount] = useState<number | null>(null)
  const [nextCursor, setNextCursor] = useState<string | null>(null)
  const [loadingInitialResults, setLoadingInitialResults] = useState(false)
  const [disconnected, setDisconnected] = useState(false)
  const [results, setResults] = useState<TResult[]>([])

  const _fetchMoreResults = useCallback(
    debounce(250, async (_queryParams, _nextCursor, _results) => {
      let response: PaginatedResults<TResult>
      try {
        response = await apiEndpoint({
          ..._queryParams,
          page: _nextCursor,
        })
      } catch {
        setDisconnected(true)
        setLoadingInitialResults(false)
        return
      }
      setResults([..._results, ...response.results])
      setNextCursor(getNextCursor(response.next))
      setTotalCount(response.count)
      setDisconnected(false)
      setLoadingInitialResults(false)
    }),
    [],
  )

  const refetchResults = () => {
    setResults([])
    setTotalCount(null)
    setNextCursor(null)
    // For now, just show 'loading' forever if query params are not valid.
    // This should just be at the very start of initial page load.
    setLoadingInitialResults(true)
    // If we can tell the results will be empty from the query params, don't bother fetching.
    // This is particularly helpful when one of the query params is an array.
    // We currently don't have a convention for sending an empty list [] as a GET url param.
    if (
      options.isResultsEmptyFromQueryParams &&
      options.isResultsEmptyFromQueryParams(queryParams)
    ) {
      setResults([])
      setNextCursor(null)
      setTotalCount(0)
      setDisconnected(false)
      setLoadingInitialResults(false)
      return
    }
    if (options.isQueryParamsValid && !options.isQueryParamsValid(queryParams)) {
      return
    }
    _fetchMoreResults(queryParams, null, [])
  }

  const fetchMorePages = () => {
    _fetchMoreResults(queryParams, nextCursor, results)
  }

  useEffect(() => {
    refetchResults()
  }, [queryParams])

  return {
    // queryParams used to fetch results.
    queryParams,
    // set all query params.
    setQueryParams,
    // whether we are loading initial results.
    loadingInitialResults,
    // whether we appear to be disconnected from the server (if desktop).
    disconnected,
    // results
    results,
    // Refetch results
    refetchResults,
    // Fetch more results.
    fetchMoreResults: fetchMorePages,
    // Whether there are more results.
    hasMoreResults: nextCursor !== null,
    // The total number of results for these query params.
    totalCount,
  }
}
