import { flatten, uniq, uniqBy } from 'lodash/fp'
import { RecordManualPassageParseDataResponseWorklists } from '~/api/operatorActions/recordManualPassage'

interface PlateCulturePair {
  well: string
  plate_barcode: string
}

// Get the number of distinct destination cultures.
export const getNumDistinctCultures = (
  parseDataResponse: RecordManualPassageParseDataResponseWorklists,
): number => {
  const plateCulturePairs = flatten(
    parseDataResponse.data.map(worklist =>
      worklist.well_transfers.map(transfer => ({
        well: transfer.well,
        plate_barcode: worklist.plate_barcode,
      })),
    ),
  )

  const uniquePairs = uniqBy(
    (pair: PlateCulturePair) => `${pair.plate_barcode}_${pair.well}`,
    plateCulturePairs,
  )

  return uniquePairs.length
}

// Get the number of distinct destination plates
export const getNumDistinctPlates = (
  parseDataResponse: RecordManualPassageParseDataResponseWorklists,
): number => {
  const plates = parseDataResponse.data.map(worklist => worklist.plate_barcode)
  return uniq(plates).length
}
