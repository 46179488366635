import { get } from 'lodash/fp'
import { AppContext } from './AppContext'

/** @deprecated */
export const getUserOrgName = (appContext: AppContext) => {
  return get(['user_metadata', 'org_name'], appContext)
}

/** @deprecated */
export const getUserPicture = (appContext: AppContext) => {
  return get(['user_metadata', 'picture'], appContext)
}

/** @deprecated */
export const getUserName = (appContext: AppContext) => {
  return get(['user_metadata', 'name'], appContext)
}

/** @deprecated */
export const getUserEmail = (appContext: AppContext) => {
  return get(['user_metadata', 'email'], appContext)
}

/** @deprecated */
export const getUserIsAdmin = (appContext: AppContext) => {
  return get(['user_metadata', 'is_admin'], appContext)
}
