import { ApolloQueryResult, useQuery } from '@apollo/client'
import { compact, uniq } from 'lodash/fp'
import { useEffect, useState } from 'react'
import { CSVLink } from 'react-csv'
import { gql } from '~/__generated__'
import { BulkExportCultureMetadataQuery } from '~/__generated__/graphql'
import Textarea from '~/components/Textarea'
import Toaster from '~/components/Toaster'
import Button from '~/components/buttons/Button'
import DownloadIcon from '~/components/icons/DownloadIcon'
import { analytics } from '~/core/analytics'
import { useFeatureFlag } from '~/core/featureFlags'
import useDebouncedAPIEndpoint from '~/utils/hooks/useDebouncedAPIEndpoint'
import BulkExportCultureMetadataPreview from './BulkExportCultureMetadataPreview'
import cs from './bulk_export_culture_metadata.scss'
import { getCSVDataFromRows, getDataRows } from './getDataRows'
import { getMissingPlates } from './getMissingPlates'

const GRAPHQL_QUERY = gql(`
  query BulkExportCultureMetadata($plateBarcodes: [String!]) {
    filteredCulturePlatesWithWells: culturePlates(barcodes: $plateBarcodes) {
      id
      barcode
      wellCultures {
        id
        well
        cellLineLot
        cellLine
        passageNumber
      }
    }
  }
`)

const BulkExportCultureMetadata = () => {
  const limsCultureData2024 = useFeatureFlag('limsCultureData2024')
  if (!limsCultureData2024) return null

  const [rawData, setRawData] = useState('')
  const [parsedData, setParsedData] = useState<BulkExportCultureMetadataQuery | null>(
    null,
  )

  const plateBarcodesToFetch = uniq(compact(rawData.split('\n')))

  const { refetch } = useQuery(GRAPHQL_QUERY, {
    variables: {
      plateBarcodes: [],
    },
  })

  const {
    response: parseDataDebouncedResponse,
    debouncedAPIEndpoint: debouncedParseData,
    loading: isParsingData,
  } = useDebouncedAPIEndpoint<
    string[],
    ApolloQueryResult<BulkExportCultureMetadataQuery>
  >(
    async (plateBarcodes: string[]) => {
      return refetch({
        plateBarcodes,
      })
    },
    500,
    !!rawData,
  )

  useEffect(() => {
    if (parseDataDebouncedResponse === null) {
      setParsedData(null)
    } else if (parseDataDebouncedResponse.type === 'response') {
      // Handle ApolloErrors.
      const { error } = parseDataDebouncedResponse.response
      if (error) {
        Toaster.show({
          message: `There was an error fetching data.`,
          intent: 'danger',
        })
        console.error(`Error fetching data: ${error.message}`)
        setParsedData(null)
      } else {
        setParsedData(parseDataDebouncedResponse.response.data)
      }
    } else if (parseDataDebouncedResponse.type === 'error') {
      // Handle HTTP errors
      Toaster.show({
        message: 'Error communicating with the server.',
        intent: 'danger',
      })
      setParsedData(null)
    }
  }, [parseDataDebouncedResponse])

  useEffect(() => {
    // Null responses must be passed through the debounced API call, so
    // they don't get overwritten by stale responses.
    debouncedParseData(plateBarcodesToFetch ? plateBarcodesToFetch : null)
  }, [rawData])

  const renderExportButton = () => {
    if (
      plateBarcodesToFetch.length === 0 ||
      parsedData === null ||
      getMissingPlates(parsedData, plateBarcodesToFetch).length > 0
    ) {
      return (
        <Button
          className={cs.button}
          type='primary'
          label='Bulk Export (.csv)'
          onClick={() => {}}
          disabled
        />
      )
    }

    const csvData = getCSVDataFromRows(getDataRows(parsedData, plateBarcodesToFetch))
    return (
      <CSVLink
        data={csvData}
        enclosingCharacter=''
        filename='monomer_culture_metadata.csv'
        className={cs.link}
      >
        <Button
          className={cs.button}
          type='primary'
          label='Bulk Export (.csv)'
          onClick={() => {
            analytics.track('Completed Bulk Export Culture Metadata', {
              numCultures: csvData.length,
              numPlates: plateBarcodesToFetch.length,
            })
          }}
        />
      </CSVLink>
    )
  }

  return (
    <div className={cs.bulkExportCultureMetadata}>
      <div className={cs.header}>
        <DownloadIcon className={cs.headerIcon} />
        <div className={cs.headerText}>Bulk Export Culture Metadata</div>
        <div className={cs.fill} />
        <Button
          className={cs.cancelButton}
          label='Cancel'
          to='/monitor/live-cultures'
        />
        {renderExportButton()}
      </div>
      <div className={cs.body}>
        <div className={cs.inputColumn}>
          <div className={cs.sectionTitle}>Paste Plate Barcodes</div>
          <Textarea
            className={cs.textarea}
            value={rawData}
            rows={8}
            onChange={value => setRawData(value)}
            placeholder={'PLATE1\nPLATE2\nPLATE3'}
          />
        </div>
        <div className={cs.previewColumn}>
          <BulkExportCultureMetadataPreview
            className={cs.preview}
            loading={isParsingData}
            data={parsedData || undefined}
            plateBarcodesToDisplay={plateBarcodesToFetch}
          />
        </div>
      </div>
    </div>
  )
}

export default BulkExportCultureMetadata
