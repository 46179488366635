/**
 * In many cases, a routine has only a single sample plate input.
 *
 * This function is useful when rendering forms for scheduling multiple at routines at once. We
 * like to hoist the single sample plate input above all of the normal forms, so that the user can
 * input it only once, and because users often think about these routines as scheduling multiple
 * routines against a single culture plate.
 */

import { RoutineDefinitionWithDSL } from './useRoutineDefinitionsWithDSLs'

export function getSingleSamplePlateInputName(
  routineDefinitionWithDSL: RoutineDefinitionWithDSL,
): string | null {
  const samplePlateInputNames = Object.entries(routineDefinitionWithDSL.dsl.inputs)
    .filter(
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      ([_, inputConfig]) => inputConfig.type === 'sample_plate',
    )
    .map(([input]) => input)

  if (samplePlateInputNames.length > 1) {
    throw new Error(
      `Did not expect routine ${routineDefinitionWithDSL.name} to have multiple sample_plate inputs`,
    )
  }

  return samplePlateInputNames.length === 1 ? samplePlateInputNames[0] : null
}
