import cx from 'classnames'
import { range } from 'lodash/fp'
import { useHistory } from 'react-router-dom'
import StatusMessage from '~/components/messages/StatusMessage'
import { ClePlate } from '../clePlates/ClePlate.interface'
import ClePipelineGridItem, { ClePipelineItemSpacer } from './ClePipelineGridItem'
import cs from './cle_pipeline_grid.scss'

const ClePipelineGrid = ({
  plates,
  useLocalAssets,
}: {
  plates: ClePlate[]

  useLocalAssets: boolean
}) => {
  const history = useHistory()
  const handleClick = (plate: ClePlate) => {
    if (plate.hasAlert && plate.phase === 'hitpicking') {
      history.push(
        `/cell-line-engineering/decisions/hitpicking/${plate.cellLine.name}/`,
      )
    } else {
      history.push(`/cell-line-engineering/cell-line/${plate.cellLine.name}/`)
    }
  }

  if (plates.length === 0) {
    return <StatusMessage message='No plates to display' />
  }
  return (
    <div className={cx(cs.clePipelineGrid)}>
      {plates.map(plate => {
        return (
          <ClePipelineGridItem
            key={plate.id}
            plate={plate}
            onClickCellLine={() => handleClick(plate)}
            onClickCell={() => handleClick(plate)}
            useLocalAssets={useLocalAssets}
          />
        )
      })}
      {range(0, 8).map(i => (
        <ClePipelineItemSpacer key={i} />
      ))}
    </div>
  )
}

export default ClePipelineGrid
