import PropTypes from 'prop-types'
import { IconProps } from './types/IconProps'
const HistoryIcon = ({ className, onClick }: IconProps) => (
  <svg
    width='21'
    height='18'
    viewBox='0 0 21 18'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    className={className}
    onClick={onClick}
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M3 9C3 4.03 7.03 0 12 0C16.97 0 21 4.03 21 9C21 13.97 16.97 18 12 18C9.51 18 7.27 16.99 5.64 15.36L7.06 13.94C8.32 15.21 10.07 16 12 16C15.87 16 19 12.87 19 9C19 5.13 15.87 2 12 2C8.13 2 5 5.13 5 9H8L3.96 13.03L3.89 12.89L0 9H3ZM11 10V5H12.5V9.15L16.02 11.24L15.25 12.52L11 10Z'
    />
  </svg>
)

HistoryIcon.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
}

export default HistoryIcon
