import PropTypes from 'prop-types'
import { IconProps } from './types/IconProps'
const ExpandIcon = ({ className, onClick }: IconProps) => (
  <svg
    width='32'
    height='32'
    viewBox='0 0 32 32'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    className={className}
    onClick={onClick}
  >
    <path
      d='M21 6H26V11'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M19 13L26 6'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M11 26H6V21'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M13 19L6 26'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M26 21V26H21'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M19 19L26 26'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path d='M6 11V6H11' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M13 13L6 6' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
  </svg>
)

ExpandIcon.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
}

export default ExpandIcon
