import { contains, find, some, toPairs } from 'lodash/fp'
import {
  StoragePartitionConfig,
  StoragePartitions,
} from './StorageInstrumentConfig.interface'

export const NO_REGION = 'NO_REGION'

const isInRegion = (
  region: StoragePartitionConfig,
  shelfIndex: number,
  levelIndex: number,
) => {
  if (region.locations) {
    return some(
      location => location[0] === shelfIndex + 1 && location[1] === levelIndex + 1,
      region.locations,
    )
  }

  if (region.shelves) {
    if (region.shelves === 'odd') {
      return (shelfIndex + 1) % 2 === 1
    }
    if (region.shelves === 'even') {
      return (shelfIndex + 1) % 2 === 0
    }
    return contains(shelfIndex + 1, region.shelves)
  }

  if (region.levels) {
    if (region.levels === 'odd') {
      return (levelIndex + 1) % 2 === 1
    }
    if (region.levels === 'even') {
      return (levelIndex + 1) % 2 === 0
    }
    return contains(levelIndex + 1, region.levels)
  }

  return false
}

export const getRegionForSlot = (
  regions: StoragePartitions | undefined,
  shelfIndex: number,
  levelIndex: number,
) => {
  if (!regions) return NO_REGION
  const matchedRegionPair = find(
    regionPair => isInRegion(regionPair[1], shelfIndex, levelIndex),
    toPairs(regions),
  )

  if (matchedRegionPair) {
    return matchedRegionPair[0]
  }

  return NO_REGION
}
