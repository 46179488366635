import {
  DemoConfig,
  getProfileSettings,
} from '~/pages/slasDemo/CommandCenter/SlasDemoConfigDialog/DemoConfig'

export function generatePlateName(plateNum: number, config: DemoConfig): string {
  const suffix = `${plateNum.toString().padStart(3, '0')}`
  const platePrefix = getProfileSettings(config).platePrefix
  return `plate_${platePrefix}_${suffix}`
}
