import { components } from '~/api/desktop/generated-schema'
import { client, unwrap } from '~/api/desktopAPIv2'

export type ContinuousCultureRoutineRequest =
  components['schemas']['ContinuousCultureRoutineRequest']

export type ScheduleContinuousCultureRequest =
  components['schemas']['ScheduleContinuousCultureRequest']

export default {
  submit: (request: ScheduleContinuousCultureRequest) =>
    unwrap(
      client().POST(
        '/api/v2/operator-actions/demo-schedule-continuous-culture/submit',
        {
          body: request,
        },
      ),
    ),
}
