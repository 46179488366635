import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom'

import CellLine from './CellLine/CellLine'
import ClePipelineView from './ClePipelineView'
import HitpickingWorkflow from './HitpickingWorkflow'
import cs from './cle_demo.scss'

const CleDemo = ({ useLocalAssets }: { useLocalAssets: boolean }) => {
  const match = useRouteMatch()

  return (
    <div className={cs.cleDemo}>
      <Switch>
        <Route
          exact
          path={match.path}
          render={() => <Redirect to={`${match.path}/pipeline`} />}
        />
        <Route
          exact
          path={`${match.path}/pipeline`}
          render={() => <ClePipelineView useLocalAssets={useLocalAssets} />}
        />
        <Route
          exact
          path={`${match.path}/decisions/hitpicking/:cellLineName`}
          render={({
            match: {
              params: { cellLineName },
            },
          }) => (
            <HitpickingWorkflow
              cellLineName={cellLineName}
              useLocalAssets={useLocalAssets}
            />
          )}
        />
        <Route
          exact
          path={`${match.path}/cell-line/:cellLineName`}
          render={({
            match: {
              params: { cellLineName },
            },
          }) => (
            <CellLine cellLineName={cellLineName} useLocalAssets={useLocalAssets} />
          )}
        />
      </Switch>
    </div>
  )
}

export default CleDemo
