import { useCallback } from 'react'

import Input from '~/components/Input'

import { CulturePlateStatusFilterGraphQl } from '~/__generated__/graphql'
import { SimpleSelector, SimpleSelectorOption } from '../SimpleSelector'
import cs from './live_cultures_filters.scss'

export type SelectedLiveCulturesFilter =
  | { kind: 'ALL' }
  | { kind: 'SPECIFIC_VALUE'; value: string }

export enum DataToDisplay {
  None = 'NONE',
  AvgConfluence = 'AVG_CONFLUENCE',
}

export function deserializeDataToDisplay(
  s: string | null,
  fallback: DataToDisplay,
): DataToDisplay {
  try {
    makeDataToDisplayOption(s as DataToDisplay)
    return s as DataToDisplay
  } catch {
    return fallback
  }
}

export const LiveCulturesFilters = ({
  selectedPlateName,
  selectedCultureStatus,
  onSelectPlateName: onSelectPlateName,
  onSelectCultureStatus: onSelectCultureStatus,
  selectedDataToDisplay,
  onSelectDataToDisplay,
}: {
  selectedPlateName: SelectedLiveCulturesFilter
  selectedCultureStatus: CulturePlateStatusFilterGraphQl
  onSelectPlateName: (value: SelectedLiveCulturesFilter) => void
  onSelectCultureStatus: (value: CulturePlateStatusFilterGraphQl) => void
  selectedDataToDisplay: DataToDisplay
  onSelectDataToDisplay: (value: DataToDisplay) => void
}) => {
  const onUpdatePlateName = useCallback(
    value => {
      if (value == '') {
        onSelectPlateName({ kind: 'ALL' })
      } else {
        onSelectPlateName({ kind: 'SPECIFIC_VALUE', value })
      }
    },
    [onSelectPlateName],
  )

  const cultureStatusOptions = [
    // TODO (SWE-896): This can be made more intelligent and conditional on applicable statuses
    // For now, we always include ALL, ACTIVE and INACTIVE as options
    { id: 'ALL', value: filterToLabel(CulturePlateStatusFilterGraphQl.All) },
    ...[
      CulturePlateStatusFilterGraphQl.Active,
      CulturePlateStatusFilterGraphQl.Inactive,
    ].map(cultureStatusFilter => ({
      id: makeID(cultureStatusFilter),
      value: filterToLabel(cultureStatusFilter),
    })),
  ]

  return (
    <div className={cs.liveCulturesFilters}>
      <Input
        className={cs.selector}
        inputClassName={cs.input}
        placeholder={'Search...'}
        label='Filter by Plate Name'
        value={selectedPlateName.kind == 'ALL' ? '' : selectedPlateName.value}
        onChange={onUpdatePlateName}
      />
      <SimpleSelector
        className={cs.selector}
        label='Filter by Culture Status'
        options={cultureStatusOptions}
        selectedOption={makeCultureStatusOption(selectedCultureStatus)}
        onOptionSelected={option => {
          onSelectCultureStatus(getCultureStatusOptionValue(option))
        }}
      />
      <SimpleSelector
        className={cs.selector}
        label='Show Data'
        options={[
          makeDataToDisplayOption(DataToDisplay.None),
          makeDataToDisplayOption(DataToDisplay.AvgConfluence),
        ]}
        selectedOption={makeDataToDisplayOption(selectedDataToDisplay)}
        onOptionSelected={option => {
          onSelectDataToDisplay(option.id as DataToDisplay)
        }}
      />
    </div>
  )
}
function filterToLabel(filter: CulturePlateStatusFilterGraphQl): string {
  return filter.charAt(0).toUpperCase() + filter.slice(1).toLowerCase()
}

function makeCultureStatusOption(
  selectedValue: CulturePlateStatusFilterGraphQl,
): SimpleSelectorOption {
  return {
    id: selectedValue,
    value: filterToLabel(selectedValue),
  }
}

function makeDataToDisplayOption(dataToDisplay: DataToDisplay): SimpleSelectorOption {
  switch (dataToDisplay) {
    case DataToDisplay.None:
      return { id: DataToDisplay.None, value: 'None' }
    case DataToDisplay.AvgConfluence:
      return {
        id: DataToDisplay.AvgConfluence,
        value: 'Avg. confluence of active wells',
      }
    default:
      throw new Error(`Invalid DataToDisplay value: ${dataToDisplay}`)
  }
}

function makeID(value: string): string {
  // Just need something that won't conflict with "ALL"
  return `__${value}`
}

function getCultureStatusOptionValue(
  option: SimpleSelectorOption,
): CulturePlateStatusFilterGraphQl {
  return CulturePlateStatusFilterGraphQl[option.value]
}
