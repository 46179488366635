import PropTypes from 'prop-types'
import { IconProps } from './types/IconProps'
const LoadingIcon = ({ className, onClick }: IconProps) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='24'
    height='30'
    viewBox='0 0 24 30'
    xmlSpace='preserve'
    className={className}
    onClick={onClick}
  >
    <rect x='0' y='0' width='4' height='10'>
      <animateTransform
        attributeType='xml'
        attributeName='transform'
        type='translate'
        values='0 0; 0 20; 0 0'
        begin='0'
        dur='0.9s'
        repeatCount='indefinite'
      />
    </rect>
    <rect x='10' y='0' width='4' height='10'>
      <animateTransform
        attributeType='xml'
        attributeName='transform'
        type='translate'
        values='0 0; 0 20; 0 0'
        begin='0.3s'
        dur='0.9s'
        repeatCount='indefinite'
      />
    </rect>
    <rect x='20' y='0' width='4' height='10'>
      <animateTransform
        attributeType='xml'
        attributeName='transform'
        type='translate'
        values='0 0; 0 20; 0 0'
        begin='0.6s'
        dur='0.9s'
        repeatCount='indefinite'
      />
    </rect>
  </svg>
)

LoadingIcon.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
}

export default LoadingIcon
