import cx from 'classnames'

import { compact, includes } from 'lodash/fp'
import { Instrument, ProcessItem } from '~/common.interface'
import SearchIcon from '~/components/icons/SearchIcon'
import { SelectedStorageLocation } from '~/pages/Workcell/components/StorageViz/StorageVizView'
import { SelectedTransferStation } from '~/pages/Workcell/components/TransferStationViz/TransferStationVizView'
import { ManagedProcessItemSelect } from '~/pages/Workcell/components/processItem/ProcessItemSelect/ManagedProcessItemSelect'
import { ProcessItemFilters } from '~/pages/Workcell/components/processItem/ProcessItemSelect/ProcessItemFilters'
import { isStorageInstrument } from '~/utils/instrument'
import { parseLocationString } from '~/utils/location'
import {
  LoadUnloadItemsProcessItemType,
  convertLoadUnloadItemsProcessItemTypeToProcessItemType,
} from '../LoadUnloadItemsProcessItemType.interface'
import cs from './unload_process_item_select.scss'

export interface UnloadProcessItemSelectProps {
  className?: string
  selectedProcessItemType: LoadUnloadItemsProcessItemType
  selectedInstrument: Instrument
  selectedTransferStations: SelectedTransferStation[]
  onTransferStationSelect: (location: SelectedTransferStation) => void
  selectedStorageLocations: SelectedStorageLocation[]
  onStorageLocationSelect: (location: SelectedStorageLocation) => void
}

// Right now, LoadUnloadItemsInstrumentViz doesn't restrict by is_empty or is_dead for culture_plate
// or empty_culture_plate, so we avoid doing that here as well, for consistency.
// Given that we will most likely change the modeling of empty_culture_plates soon,
// we will defer updating LoadUnloadItemsInstrumentViz.
// For now, it's okay to be less restrictive in the Unload interface with which plates the user can unload, though
// it's weird that you can unload Empty Culture Plates when Culture Plates is selected and vice versa.
const getProcessItemFilters = (
  selectedInstrument: Instrument,
  selectedProcessItemType: LoadUnloadItemsProcessItemType,
): ProcessItemFilters => {
  return {
    instrument_name: selectedInstrument.instrumentName,
    ...(includes(selectedProcessItemType, ['empty_culture_plate', 'culture_plate'])
      ? { is_dead: undefined, is_empty: undefined }
      : {}),
    types: [
      convertLoadUnloadItemsProcessItemTypeToProcessItemType(selectedProcessItemType),
    ],
  }
}

const UnloadProcessItemSelect = ({
  className,
  selectedProcessItemType,
  selectedInstrument,
  selectedTransferStations,
  onTransferStationSelect,
  selectedStorageLocations,
  onStorageLocationSelect,
}: UnloadProcessItemSelectProps) => {
  const processItemFilters = getProcessItemFilters(
    selectedInstrument,
    selectedProcessItemType,
  )

  const handleProcessItemUnloadSelect = (processItem: ProcessItem | null) => {
    if (!processItem) return
    const location = processItem.state?.location

    if (!location) return

    if (location.instrumentName !== selectedInstrument?.instrumentName) {
      console.error(
        `Expected process item to be on ${selectedInstrument?.instrumentName}. ` +
          `Got ${location.instrumentName}`,
      )
    }

    if (isStorageInstrument(selectedInstrument?.instrumentType)) {
      if (location.locationType !== 'storage') {
        console.error(
          `Expected process item to have storage location type. Got ${location.locationType}`,
        )
        return
      }
      try {
        const [shelfIndex, levelIndex] = parseLocationString(
          location.locationParams.locationString,
        )

        onStorageLocationSelect({
          itemUuid: processItem.uuid,
          shelfIndex,
          levelIndex,
        })
      } catch (e) {
        console.error(String(e))
        return
      }
    } else {
      if (location.locationType !== 'transfer_station') {
        console.error(
          `Expected process item to have transfer station location type. Got ${location.locationType}`,
        )
        return
      }
      onTransferStationSelect({
        item: processItem,
        transferStationId: location.locationParams.transferStationId,
      })
    }
  }

  const getSelectedProcessItemUuids = () => {
    if (isStorageInstrument(selectedInstrument?.instrumentType)) {
      return compact(selectedStorageLocations.map(location => location.itemUuid))
    }
    return compact(selectedTransferStations.map(location => location.item?.uuid))
  }

  return (
    <ManagedProcessItemSelect
      className={cx(cs.processItemSelect, className)}
      triggerClassName={cs.trigger}
      selectedProcessItem={null}
      onProcessItemSelected={handleProcessItemUnloadSelect}
      processItemFilters={processItemFilters}
      placeholder={
        <div className={cs.placeholder}>
          <SearchIcon className={cs.icon} />
          Search for Item...
        </div>
      }
      processItemUuidsToDisplayAsSelected={getSelectedProcessItemUuids()}
      hideLabel={true}
      variant='narrow'
      hideArrow
      noBorder
      stayOpenOnSelect
    />
  )
}

export default UnloadProcessItemSelect
