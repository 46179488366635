import cx from 'classnames'
import { filter, find, map } from 'lodash/fp'
import { useEffect, useState } from 'react'

import Select, { SelectOption } from '~/components/Select'
import { sortCaseInsensitiveBy } from '~/utils/array'

import { supportedPlateFormats } from '~/components/TinyMicroplate.interface'
import Notification from '~/components/notifications/Notification'
import TinyNotification from '~/components/notifications/TinyNotification'
import {
  ExperimentPlateMap,
  getExperimentPlateMaps,
  validateExperimentPlateMaps,
} from '../../../ProcessItems/PlateMap'
import ExperimentPlateMapHoverPopover from './ExperimentPlateMapHoverPopover'
import cs from './experiment_plate_map_select.scss'
import { getMatchingPlateMapWithDefault } from './getMatchingPlateMapWithDefault'

export const getExperimentPlateMapOptions = (
  experimentPlateMaps: ExperimentPlateMap[],
) => {
  const names = map(plateMap => plateMap.name, experimentPlateMaps)
  const _experimentPlateMapOptions = map(
    plateMapName => ({
      key: plateMapName,
      label: plateMapName,
    }),
    names,
  )
  return _experimentPlateMapOptions
}

export function ExperimentPlateMapSelect({
  selectedPlateMap,
  onSelectPlateMap,
  hideLabel,
  wide,
  disabled,
  className,
  hideSelect,
  fromPlateFormat,
  toPlateFormat,
  defaultPlateMapName,
}: {
  selectedPlateMap: ExperimentPlateMap | undefined
  onSelectPlateMap: (plateMap: ExperimentPlateMap | undefined) => void
  hideLabel?: boolean
  wide?: boolean
  disabled?: boolean
  className?: string
  hideSelect?: boolean // If the select is hidden, the hover popover will still show.
  fromPlateFormat?: supportedPlateFormats
  toPlateFormat?: supportedPlateFormats
  defaultPlateMapName: string
}) {
  const [experimentPlateMaps, setExperimentPlateMaps] = useState<ExperimentPlateMap[]>(
    [],
  )

  const [error, setError] = useState<string | null>(null)

  const fetchExperimentPlateMapOptions = async () => {
    // TODO(mark): Fetch from the back-end.
    const experimentPlateMaps = getExperimentPlateMaps()

    try {
      validateExperimentPlateMaps(experimentPlateMaps)
    } catch (e: any) {
      setError(`Invalid experiment plate map configuration. ${e.message}`)
      return
    }

    setExperimentPlateMaps(sortCaseInsensitiveBy(experimentPlateMaps, 'name'))
  }

  const maybeUpdateSelectedPlateMap = () => {
    if (!fromPlateFormat || !toPlateFormat) {
      onSelectPlateMap(undefined)
      return
    }

    // If the currently selected plate map is valid, don't change it.
    if (
      selectedPlateMap &&
      selectedPlateMap.from_format === fromPlateFormat &&
      selectedPlateMap.to_format === toPlateFormat
    ) {
      return
    }

    // Find a matching plate map, based on the provided plate formats.
    onSelectPlateMap(
      getMatchingPlateMapWithDefault(
        experimentPlateMaps,
        fromPlateFormat,
        toPlateFormat,
        defaultPlateMapName,
      ),
    )
  }

  // Do this once.
  useEffect(() => {
    fetchExperimentPlateMapOptions()
  }, [])

  useEffect(() => {
    if (experimentPlateMaps) {
      maybeUpdateSelectedPlateMap()
    }
  }, [selectedPlateMap, experimentPlateMaps, fromPlateFormat, toPlateFormat])

  const handleChange = (value: SelectOption) => {
    const plateMap = find(['name', value.label], experimentPlateMaps)
    if (plateMap) {
      onSelectPlateMap(plateMap)
    }
  }

  const itemMatchesQuery = (plateMap: SelectOption, queryLowerCase: string) =>
    plateMap.key.toLowerCase().includes(queryLowerCase)

  if (error) {
    return (
      <Notification
        className={cx(className, cs.notification)}
        type='error'
        label={error}
        variant='mini'
      />
    )
  }

  const plateFormatsReady = fromPlateFormat !== undefined && toPlateFormat !== undefined

  const experimentPlateOptions = getExperimentPlateMapOptions(
    filter(
      plateMap =>
        plateMap.from_format === fromPlateFormat &&
        plateMap.to_format === toPlateFormat,
      experimentPlateMaps,
    ),
  )

  return (
    <div className={className}>
      {!hideSelect && (
        <Select<SelectOption>
          label={hideLabel ? undefined : 'Select Plate Map'}
          items={experimentPlateOptions}
          itemKey='key'
          itemLabelKey='label'
          filterable
          itemMatchesQuery={itemMatchesQuery}
          activeItem={
            find(['label', selectedPlateMap?.name], experimentPlateOptions) || null
          }
          placeholder='Select Plate Map'
          onChange={handleChange}
          className={cs.select}
          triggerClassName={wide ? cs.triggerWide : cs.trigger}
          popoverClassName={wide ? cs.popoverWide : cs.popover}
          disabled={disabled || !plateFormatsReady || !experimentPlateOptions.length}
        />
      )}
      {plateFormatsReady && selectedPlateMap && (
        <ExperimentPlateMapHoverPopover
          className={cx(!hideSelect && cs.plateMapHoverText)}
          selectedPlateMap={selectedPlateMap}
        />
      )}
      {plateFormatsReady && experimentPlateOptions.length === 0 && (
        <TinyNotification
          className={cs.errorNotification}
          type='bareError'
          message='No plate maps matching sample plate and labware'
        />
      )}
    </div>
  )
}
