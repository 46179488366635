import dayjs from 'dayjs'
import { times } from 'lodash'
import {
  DemoConfig,
  getProfileSettings,
} from '~/pages/slasDemo/CommandCenter/SlasDemoConfigDialog/DemoConfig'
import { assertNever } from '~/utils/assertNever'

export function generateExperimentLabelsForCellLine(
  cellLine: string,
  config: DemoConfig,
): string[] {
  const date = dayjs().format('YYYY_MM_DD')
  const cellLineExperimentLabelsStrategy =
    getProfileSettings(config).cellLineExperimentLabelsStrategy
  if (cellLineExperimentLabelsStrategy === 'BANKGEN') {
    return [...times(25, () => `${date}_BankGen001`)]
  } else if (cellLineExperimentLabelsStrategy === 'BATCH_AND_STOCK') {
    return [
      ...times(19, () => `${date}_${cellLine}_batch`),
      ...times(6, () => `${date}_${cellLine}_stock`),
    ]
  } else {
    assertNever(cellLineExperimentLabelsStrategy)
  }
}
