import cx from 'classnames'
import PropTypes from 'prop-types'

import { ConsumableRequirements } from '~/api/desktop/futureRoutines'
import Table from '~/components/Table'
import { displayCount, snakeCaseToTitleCase } from '~/utils/string'
import cs from './consumable_reqs_table.scss'

const renderConsumable = (row: ConsumableRequirements) => {
  if (row.type === 'tiprack') {
    return (
      <span>
        Tiprack (<b>{row.tip_capacity_ul}µL</b>)
      </span>
    )
  }
  if (row.type === 'reagent_plate') {
    return (
      <span>
        Media Plate (<b>{row.reagent_name}</b>)
      </span>
    )
  }
  if (row.type === 'empty_culture_plate') {
    return (
      <span>
        Empty Culture Plate <b>({row.user_facing_id})</b>
      </span>
    )
  }
  if (row.type === 'experiment') {
    return (
      <span>
        <b>{snakeCaseToTitleCase(row.experiment_type)}</b> for{' '}
        <b>{row.linked_sample_plate}</b>
      </span>
    )
  }
  if (row.type === 'empty_assay_plate') {
    return (
      <span>
        Empty Assay Plate <b>({row.user_facing_id})</b>
      </span>
    )
  }
  return null
}

const renderRequirement = (row: ConsumableRequirements) => {
  if (row.type === 'tiprack') {
    return (
      <div>
        <div className={cs.text}>{displayCount('tiprack', row.num_plates)}</div>
        <div className={cs.subtext}>{displayCount('pipette tip', row.num_wells)}</div>
      </div>
    )
  }
  if (row.type === 'reagent_plate') {
    return (
      <div>
        <div className={cs.text}>{displayCount('plate', row.num_plates)}</div>
        <div className={cs.subtext}>{displayCount('well', row.num_wells)}</div>
      </div>
    )
  }
  if (row.type === 'empty_culture_plate') {
    return null
  }
  if (row.type === 'empty_assay_plate') {
    return (
      <div>
        <div className={cs.text}>{displayCount('assay plate', row.num_plates)}</div>
      </div>
    )
  }
  if (row.type === 'experiment') {
    return null
  }
  return null
}

const ConsumableReqsTable = ({
  className,
  consumableReqs,
}: {
  className?: string
  consumableReqs: ConsumableRequirements[]
}) => {
  const tableColumns = [
    {
      name: 'Consumable',
      width: 400,
      render: renderConsumable,
    },
    {
      name: 'Requirement',
      width: 'flex',
      render: row => renderRequirement(row),
    },
  ]

  if (consumableReqs === null) {
    return null
  }

  return (
    <Table
      columns={tableColumns}
      data={consumableReqs}
      className={cx(className, cs.consumableReqsTable)}
      rowPaddingVariant='rowPaddingLow'
      heightSizing='flexAuto'
    />
  )
}

ConsumableReqsTable.propTypes = {
  className: PropTypes.string,
  consumableReqs: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.oneOf(['tiprack', 'reagent_plate']),
      numWells: PropTypes.number,
      itemFilters: PropTypes.shape({
        tags__includes: PropTypes.string,
        tip_capacity_ul: PropTypes.number,
      }),
    }),
  ),
}

export default ConsumableReqsTable
