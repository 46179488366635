import { partition, size } from 'lodash'
import { DemoConfig } from '../../CommandCenter/SlasDemoConfigDialog/DemoConfig'
import { DemoEvent } from '../DemoEvent'
import { EventState } from '../EventState'
import {
  CultureViewModelMap,
  omitArchivedCultures,
} from '../ViewModels/CultureViewModel'
import { ViewModels } from '../ViewModels/ViewModels'
import { applyOptimisticEvents } from '../projections/projectViewModels'
import { exchangeMediaForCultures } from './cultureEvents/exchangeMediaForCultures'
import { imageCultures } from './cultureEvents/imageCultures'
import { passageCultures } from './cultureEvents/passageCultures'

export function fastForwardOneDay(
  eventState: EventState,
  viewModels: ViewModels,
  // DemoConfig.profile is used when generating the media name during media exchanges.
  config: DemoConfig,
  useLocalImages: boolean, // Tech debt. See comment on applyOptimisticEvents.
): DemoEvent[] {
  const initialCultures = omitArchivedCultures(viewModels.cultures)

  const [culturesToPassage, culturesToExchangeMedia] = partition(
    Object.values(initialCultures),
    culture => culture.nextScheduledAction === 'PASSAGE',
  )

  const numExistingPlates = size(viewModels.plates)

  const finalEventsForToday: DemoEvent[] = [
    ...exchangeMediaForCultures(culturesToExchangeMedia, viewModels.today, config),
    ...passageCultures(culturesToPassage, viewModels.today, numExistingPlates, config),
    { kind: 'DayFastForwarded', newDay: viewModels.today + 1 },
  ]

  const nextDayCultures = calculateNextDayCultures(
    eventState,
    viewModels,
    finalEventsForToday,
    config,
    useLocalImages,
  )

  return [
    ...finalEventsForToday,
    ...imageCultures(Object.values(nextDayCultures), viewModels.today + 1),
  ]
}

function calculateNextDayCultures(
  eventState: EventState,
  viewModels: ViewModels,
  finalEventsForToday: DemoEvent[],
  config: DemoConfig,
  useLocalImages: boolean,
): CultureViewModelMap {
  return omitArchivedCultures(
    applyOptimisticEvents(
      viewModels,
      eventState,
      finalEventsForToday,
      config,
      useLocalImages,
    ).cultures,
  )
}
