import cx from 'classnames'
import { indexOf, keys, sortBy } from 'lodash/fp'
import PropTypes from 'prop-types'
import { useContext, useEffect, useState } from 'react'

import workcellAPI, {
  WorkcellAllowedInstrumentsForItemType,
} from '~/api/desktop/workcell'
import operatorActionAPI, { EnabledOperatorConfigs } from '~/api/operatorActions/shared'
import InstrumentStatusTile from '~/pages/Workcell/WorkcellOverview/LiveStatus/InstrumentStatusTile'
import WorkcellStatusContext from '~/pages/Workcell/WorkcellStatusContext'
import {
  getAllInstrumentStatus,
  getProcessStepsIdsInProgress,
} from '~/pages/Workcell/utils/workcellStatus'
import { getComparatorString, padArrayForGrid } from '~/utils/array'
import useReloadKey from '~/utils/hooks/useReloadKey'
import { INSTRUMENT_CATEGORY } from '~/utils/instrument'

import { Instrument } from '~/common.interface'
import { useIsMounted } from '~/utils/hooks/useIsMounted'
import { getMenuOptionsForInstrumentStatusTile } from './getMenuOptionsForInstrumentStatusTile'
import cs from './live_status.scss'

const ORDER = [
  'Incubator',
  'Fridge',
  'Storage',
  'Liquid Handler',
  'Microscope',
  'Robotic Arm',
]

interface FillerInstrument {
  filler: boolean
  key: number
}

interface LiveStatusProps {
  className?: string
}

const LiveStatus = ({ className }: LiveStatusProps) => {
  const workcellStatus = useContext(WorkcellStatusContext)
  const instrumentStatus = getAllInstrumentStatus(workcellStatus)
  const [allEnabledOperatorConfigs, setAllEnabledOperatorConfigs] =
    useState<EnabledOperatorConfigs | null>(null)
  const [instruments, setInstruments] = useState<Instrument[] | null>(null)
  const [reloadKey, refreshReloadKey] = useReloadKey(undefined)
  const [
    workcellAllowedInstrumentsForItemType,
    setWorkcellAllowedInstrumentsForItemType,
  ] = useState<WorkcellAllowedInstrumentsForItemType | undefined>(undefined)
  const isMounted = useIsMounted()

  const fetchInstruments = async () => {
    const _instruments = await workcellAPI.getInstruments()
    if (!isMounted()) return
    setInstruments(_instruments)

    const allEnabledConfigs = await operatorActionAPI.getAllEnabledConfigs()
    if (!isMounted()) return
    setAllEnabledOperatorConfigs(allEnabledConfigs.configs)

    try {
      const response = await workcellAPI.getWorkcellAllowedInstrumentsForItemType()
      if (!isMounted()) return
      setWorkcellAllowedInstrumentsForItemType(
        // TODO: This cast is required because the openAPI generator is unable to properly
        //  handle string literals as a key
        response.workcell_allowed_instruments_for_item_type as unknown as WorkcellAllowedInstrumentsForItemType,
      )
    } catch (e) {
      if (!isMounted()) return
      setWorkcellAllowedInstrumentsForItemType(
        {} as WorkcellAllowedInstrumentsForItemType,
      )
    }
  }

  let orderedInstruments: (FillerInstrument | Instrument)[] = sortBy(instrument => {
    const index = indexOf(INSTRUMENT_CATEGORY[instrument.instrumentType], ORDER)

    if (index === -1) return ORDER.length
    return index
  }, instruments)

  orderedInstruments = padArrayForGrid(
    index => ({
      filler: true,
      key: index,
    }),
    3,
    orderedInstruments,
  )

  useEffect(() => {
    fetchInstruments()
  }, [getComparatorString(keys(instrumentStatus))])

  useEffect(() => {
    refreshReloadKey()
  }, [getComparatorString(getProcessStepsIdsInProgress(workcellStatus))])

  const renderInstrument = instrument => {
    if (
      !instrumentStatus ||
      !allEnabledOperatorConfigs ||
      !workcellAllowedInstrumentsForItemType
    )
      return null
    if (instrument.filler) {
      return <div className={cs.tile} key={instrument.key} />
    }
    return (
      <InstrumentStatusTile
        className={cs.tile}
        instrument={instrument}
        instrumentStatus={instrumentStatus[instrument.instrumentName]}
        key={instrument.instrumentName}
        reloadKey={reloadKey}
        popupMenuOptions={getMenuOptionsForInstrumentStatusTile(
          allEnabledOperatorConfigs,
          workcellAllowedInstrumentsForItemType,
          instrument,
        )}
      />
    )
  }

  if (!reloadKey) return null

  return (
    <div className={cx(className, cs.liveStatus)}>
      {orderedInstruments && orderedInstruments.map(renderInstrument)}
    </div>
  )
}

LiveStatus.propTypes = {
  className: PropTypes.string,
}

export default LiveStatus
