// Name chosen to avoid conflicting with Sentry's ErrorBoundary component

// Pulled from:
// https://react.dev/reference/react/Component#catching-rendering-errors-with-an-error-boundary
//
// It's often a good idea to pass a `key` to MonomerErrorBoundary so that we force a re-render when
// a different child is selected (e.g. another tab in a tab view).
import React, { ErrorInfo } from 'react'
import Link from './Link'
import cs from './monomer_error_boundary.scss'
import Notification from './notifications/Notification'

interface Props {
  children?: JSX.Element
  fallback?: JSX.Element
}

interface State {
  hasError: boolean
}

export class MonomerErrorBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = { hasError: false }
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  static getDerivedStateFromError(error: Error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true }
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  componentDidCatch(error: Error, info: ErrorInfo) {
    // TODO: Log the error to external service if necessary
    // Example "componentStack":
    //   in ComponentThatThrows (created by App)
    //   in ErrorBoundary (created by App)
    //   in div (created by App)
    //   in App
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        this.props.fallback ?? (
          <Notification
            type='error'
            label={
              <div>
                Sorry, an unknown error occurred. Please&nbsp;
                <Link external to='mailto:support@monomerbio.com' className={cs.link}>
                  contact Monomer support
                </Link>
              </div>
            }
          />
        )
      )
    }

    return this.props.children ?? null
  }
}
