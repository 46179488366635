import PropTypes from 'prop-types'
import { IconProps } from './types/IconProps'

const BareCheckmarkIcon = ({ className, onClick }: IconProps) => (
  <svg
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    className={className}
    onClick={onClick}
  >
    <path d='M8.79995 15.9L4.59995 11.7L3.19995 13.1L8.79995 18.7L20.8 6.7L19.4 5.3L8.79995 15.9Z' />
  </svg>
)

BareCheckmarkIcon.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
}

export default BareCheckmarkIcon
