import { UserProfile } from '~/types/UserProfile.interface'
import { API } from './core'
import { APIErrorResponse } from './types/APIErrorResponse'

const teamAPI = new API('/api/team')

const getOrganizationUsers = async (): Promise<UserProfile[]> => {
  const response = await teamAPI.get('get-organization-users/')
  return response.users
}

interface InviteUserResponse {
  success: 'true'
  userProfile: UserProfile
}

const inviteUser = (
  firstName,
  lastName,
  email,
  isAdmin,
): Promise<APIErrorResponse | InviteUserResponse> =>
  teamAPI.post('invite-user/', {
    firstName,
    lastName,
    email,
    isAdmin,
  })

const updateUser = (id, firstName, lastName, isAdmin) =>
  teamAPI.post('update-user/', {
    id,
    firstName,
    lastName,
    isAdmin,
  })

export { getOrganizationUsers, inviteUser, updateUser }
