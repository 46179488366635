import { useEffect, useState } from 'react'
import bulkLoadCulturePlatesAPI, {
  BulkLoadParseDataSchemasResponse,
} from '~/api/operatorActions/bulkLoadCulturePlates'
import Dialog from '~/components/Dialog'
import TabularDataSchemaDisplay from '../TabularDataSchemaDisplay'
import cs from './bulk_load_data_schemas_dialog.scss'

export type BulkLoadDataSchemasDialogProps = {
  isOpen: boolean
  onClose: () => void
  schema: 'plate' | 'well'
}

const BulkLoadDataSchemasDialog = ({
  isOpen,
  onClose,
  schema,
}: BulkLoadDataSchemasDialogProps) => {
  const [dataSchemas, setDataSchemas] =
    useState<BulkLoadParseDataSchemasResponse | null>(null)

  const fetchDataSchemas = async () => {
    const response = await bulkLoadCulturePlatesAPI.getParseDataSchemas()
    setDataSchemas(response)
  }

  useEffect(() => {
    fetchDataSchemas()
  }, [])

  if (dataSchemas === null) {
    return null
  }

  return (
    <Dialog isOpen={isOpen} onClose={onClose} className={cs.bulkLoadDataSchemasDialog}>
      <div className={cs.title}>Accepted Data Format</div>
      <div className={cs.subtitle}>
        .csv and .tsv formats are supported. Please include the column names.
      </div>
      <div className={cs.optionTitle}>Schema</div>
      <TabularDataSchemaDisplay
        className={cs.schemaDisplay}
        schema={schema === 'plate' ? dataSchemas.plate_schema : dataSchemas.well_schema}
      />
    </Dialog>
  )
}

export default BulkLoadDataSchemasDialog
