import dayjs from 'dayjs'
import { useMemo } from 'react'

import { FragmentType, gql, unmaskFragment } from '~/__generated__'
import LineGraph from '~/components/LineGraph'

const miniCultureGrowthChartFragment = gql(`
  fragment MiniCultureGrowthChartFragment on WellCultureGraphQL {
    id
    observationHistory {
      confluence
      timestamp
    }
    culturePlate {
      id
      checkedInAt
    }
  }
`)

// TODO: For now this doesn't allow rendering axes. We could refactor this with CultureOverview
export function MiniCultureGrowthChart(props: {
  wellCulture: FragmentType<typeof miniCultureGrowthChartFragment>
  className?: string
}) {
  const wellCulture = unmaskFragment(miniCultureGrowthChartFragment, props.wellCulture)

  interface LineGraphDatapoint {
    /** Number of days since seeding or check-in. */
    day: number
    /** Confluence in percent. */
    value: number
  }
  const data: {
    [datasetName: string]: LineGraphDatapoint[]
  } = useMemo(() => {
    const startDate = dayjs(wellCulture.culturePlate.checkedInAt)
    const data: LineGraphDatapoint[] = []
    for (const observation of wellCulture.observationHistory) {
      if (observation.confluence == null) {
        continue
      }

      const date = dayjs(observation.timestamp)
      data.push({
        day: date.diff(startDate, 'days', true),
        value: observation.confluence,
      })
    }
    return { [wellCulture.id]: data }
  }, [wellCulture])

  if (data[wellCulture.id].length < 2) {
    // Chart is not useful in this case.
    return null
  }

  return (
    <LineGraph
      className={props.className}
      layoutOptions={{
        marginTop: 3,
        marginBottom: 3,
        marginLeft: 3,
        marginRight: 3,
      }}
      axisOptions={{
        showYAxis: false,
        showXAxis: false,
      }}
      options={{
        xKey: 'day',
        yKey: 'value',
        lineColor: '#2CB1BC',
        dotSize: 2,
        xDomainMax: 5,
        yDomainMax: 100,
      }}
      // @ts-expect-error Prop type seems wrong.
      data={data}
    />
  )
}
