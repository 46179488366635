import cx from 'classnames'

import Select from '~/components/Select'
import cs from './liconic_stx_load_select.scss'

export interface LoadUnloadItemsTypeSelectProps {
  manuallyMove: boolean
  loadOrUnload: 'load' | 'unload'
  onManuallyMoveSelect: (manuallyMove: boolean) => void
  triggerClassName?: string
  popoverClassName?: string
  disabled?: boolean
}

interface Option {
  label: string
  value: boolean
}

// If we load via the Door, we set manuallyMove = true in the reload operation.
// This will still create a process item, but will not run instrument commands.
const getOptions = (loadOrUnload: 'load' | 'unload'): Option[] => {
  return [
    {
      label: `${loadOrUnload === 'load' ? 'Load' : 'Unload'} via Transfer Station`,
      value: false,
    },
    {
      label: `${loadOrUnload === 'load' ? 'Load' : 'Unload'} via Door`,
      value: true,
    },
  ]
}

const itemRenderer = (option: Option) => {
  return <div className={cs.option}>{option.label}</div>
}

const LiconicLoadSelect = ({
  manuallyMove,
  loadOrUnload,
  onManuallyMoveSelect,
  triggerClassName,
  popoverClassName,
  disabled,
}: LoadUnloadItemsTypeSelectProps) => {
  const options = getOptions(loadOrUnload)
  const activeItem = options.find(option => option.value === manuallyMove)

  return (
    <Select<Option>
      items={options}
      itemKey='value'
      itemLabelKey='label'
      activeItem={activeItem || null}
      itemRenderer={itemRenderer}
      onChange={(option: Option) => onManuallyMoveSelect(option.value)}
      className={cx(cs.loadUnloadItemsTypeSelect, disabled && cs.disabled)}
      triggerClassName={cx(triggerClassName, cs.trigger)}
      popoverClassName={popoverClassName}
      triggerIconClassName={cs.triggerIcon}
      noBorder
      disabled={disabled}
    />
  )
}

export default LiconicLoadSelect
