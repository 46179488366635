import Textarea from '~/components/Textarea'
import { BulkLoadCulturePlatesConfig } from './BulkLoadCulturePlatesAction'
import BulkLoadCulturePlatesInstrumentSelect from './BulkLoadCulturePlatesInstrumentSelect'
import {
  BulkLoadCulturePlatesWizardData,
  UploadPlateDataStepData,
  UploadPlateDataStepValidData,
} from './BulkLoadCulturePlatesWizardData.interface'

import { useEffect, useState } from 'react'
import bulkLoadCulturePlatesAPI, {
  BulkLoadParsePlateDataResponse,
} from '~/api/operatorActions/bulkLoadCulturePlates'
import Toaster from '~/components/Toaster'
import MinimalButton from '~/components/buttons/MinimalButton'
import useDebouncedAPIEndpoint from '~/utils/hooks/useDebouncedAPIEndpoint'
import BulkLoadDataSchemasDialog from './BulkLoadDataSchemasDialog'
import UploadPlateDataStepPreview from './UploadPlateDataStepPreview'
import cs from './upload_plate_data_step.scss'

interface UploadPlateDataStepProps {
  stepData: UploadPlateDataStepData
  setStepData: (stepData: Partial<UploadPlateDataStepData>) => void
  config: BulkLoadCulturePlatesConfig
}

interface ParseDataRequest {
  rawData: string
  instrumentName: string
  shelf: number
}

export const getValidStepDataIfComplete = (
  wizardData: BulkLoadCulturePlatesWizardData,
): UploadPlateDataStepValidData | null => {
  const stepData = wizardData.stepData.uploadPlateData
  if (
    stepData.data !== '' &&
    stepData.selectedInstrument !== null &&
    stepData.selectedShelf !== null &&
    stepData.parseDataResponse !== null &&
    stepData.parseDataResponse.type !== 'errors'
  ) {
    return {
      selectedInstrument: stepData.selectedInstrument,
      selectedShelf: stepData.selectedShelf,
      data: stepData.data,
      parseDataResponse: stepData.parseDataResponse,
      unknownFetchError: false,
    }
  }
  return null
}

const UploadPlateDataStep = ({
  stepData,
  setStepData,
  config,
}: UploadPlateDataStepProps) => {
  const [dataSchemasDialogOpen, setDataSchemasDialogOpen] = useState(false)
  const {
    response: parseDataDebouncedResponse,
    debouncedAPIEndpoint: debouncedParseData,
    loading: isParsingData,
  } = useDebouncedAPIEndpoint<ParseDataRequest, BulkLoadParsePlateDataResponse>(
    async (parseDataRequest: ParseDataRequest) => {
      return bulkLoadCulturePlatesAPI.parsePlateData({
        data: parseDataRequest.rawData,
        instrument_name: parseDataRequest.instrumentName,
        shelf: parseDataRequest.shelf,
      })
    },
    250,
    !!stepData.data,
  )

  useEffect(() => {
    if (parseDataDebouncedResponse === null) {
      setStepData({
        parseDataResponse: null,
      })
    } else if (parseDataDebouncedResponse.type === 'response') {
      setStepData({
        parseDataResponse: parseDataDebouncedResponse.response,
      })
    } else if (parseDataDebouncedResponse.type === 'error') {
      Toaster.show({
        message: 'Error communicating with the server.',
        intent: 'danger',
      })
      setStepData({
        parseDataResponse: null,
      })
    }
  }, [parseDataDebouncedResponse])

  useEffect(() => {
    // Null responses must be passed through the debounced API call, so
    // they don't get overwritten by stale responses.
    if (stepData.data && stepData.selectedInstrument && stepData.selectedShelf) {
      debouncedParseData({
        rawData: stepData.data,
        instrumentName: stepData.selectedInstrument.instrument.instrumentName,
        shelf: stepData.selectedShelf,
      })
    } else {
      debouncedParseData(null)
    }
  }, [stepData.data])

  return (
    <div className={cs.uploadPlateDataStep}>
      <div className={cs.inputColumn}>
        <div className={cs.instrumentSelectContainer}>
          <div className={cs.sectionTitle}>Select Destination Instrument</div>
          <BulkLoadCulturePlatesInstrumentSelect
            config={config}
            stepData={stepData}
            setStepData={setStepData}
          />
        </div>
        <div className={cs.plateDataContainer}>
          <div className={cs.sectionTitle}>Paste Plate Data</div>
          <Textarea
            className={cs.textarea}
            value={stepData.data}
            rows={8}
            onChange={value =>
              setStepData({
                data: value,
              })
            }
          />
          <MinimalButton
            className={cs.viewAcceptedDataSchemas}
            type='primary'
            onClick={() => setDataSchemasDialogOpen(true)}
            label='View Accepted Data Format'
            variant='normal'
          />
        </div>
      </div>
      <div className={cs.previewColumn}>
        <UploadPlateDataStepPreview
          className={cs.preview}
          stepData={stepData}
          loading={isParsingData}
        />
      </div>
      <BulkLoadDataSchemasDialog
        isOpen={dataSchemasDialogOpen}
        onClose={() => setDataSchemasDialogOpen(false)}
        schema='plate'
      />
    </div>
  )
}

export default UploadPlateDataStep
