import cx from 'classnames'
import PropTypes from 'prop-types'

import Checkbox from '~/components/Checkbox'

import cs from './checkbox_option.scss'

interface CheckboxOptionProps {
  className?: string
  onClick: (checked: boolean) => void
  checked: boolean
  label: string
  allCaps?: boolean
  variant?: 'default' | 'largeCheckboxRightMargin' | 'tinyText'
  disabled?: boolean
}

const CheckboxOption = ({
  className,
  onClick,
  checked,
  label,
  allCaps,
  variant,
  disabled,
}: CheckboxOptionProps) => {
  return (
    <div
      className={cx(
        cs.checkboxOption,
        className,
        allCaps && cs.allCaps,
        variant && cs[variant],
        disabled && cs.disabled,
      )}
      onClick={() => !disabled && onClick(!checked)}
    >
      <Checkbox checked={checked} className={cs.checkbox} disabled={disabled} />
      <span>{label}</span>
    </div>
  )
}

CheckboxOption.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  onClick: PropTypes.func,
  checked: PropTypes.bool,
  allCaps: PropTypes.bool,
  variant: PropTypes.oneOf(['default', 'largeCheckboxRightMargin', 'tinyText']),
}

export default CheckboxOption
