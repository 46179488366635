import {} from '~/api/operatorActions/linkCellLineLotsToCultures'
import { displayCount } from '~/utils/string'
import {
  UploadLiveWellsStepValidData,
  UploadPlateDataStepValidData,
} from './BulkLoadCulturePlatesWizardData.interface'

import StorageViz from '../../components/StorageViz'
import BulkLoadDataTable from './BulkLoadDataTable'
import { getReloadChangesForPlates } from './UploadPlateDataStepPreview'
import cs from './confirm_updates_step.scss'
import { getBulkLoadPreviewPlates, getNumPlates } from './getBulkLoadPreviewPlates'

interface ConfirmUpdatesStepProps {
  uploadPlateDataStepData: UploadPlateDataStepValidData
  uploadLiveWellsStepData: UploadLiveWellsStepValidData
}

const ConfirmUpdatesStep = ({
  uploadPlateDataStepData,
  uploadLiveWellsStepData,
}: ConfirmUpdatesStepProps) => {
  const previewPlates = getBulkLoadPreviewPlates(
    uploadPlateDataStepData,
    uploadLiveWellsStepData,
  )
  const changes = getReloadChangesForPlates(
    uploadPlateDataStepData.parseDataResponse.data,
    uploadPlateDataStepData.selectedInstrument,
    uploadPlateDataStepData.selectedShelf,
    uploadPlateDataStepData.parseDataResponse.culture_plates,
  )
  return (
    <div className={cs.confirmUpdatesStep}>
      <div className={cs.title}>
        Loading {displayCount('plate', getNumPlates(previewPlates))} onto{' '}
        {uploadPlateDataStepData.selectedInstrument.instrument.instrumentName}, shelf{' '}
        {uploadPlateDataStepData.selectedShelf}
      </div>
      <div className={cs.contents}>
        <div className={cs.instrumentColumn}>
          <StorageViz
            className={cs.storageViz}
            instrument={uploadPlateDataStepData.selectedInstrument.instrument}
            showOnlyShelfIndex={uploadPlateDataStepData.selectedShelf}
            changes={changes}
          />
        </div>
        <div className={cs.dataTableColumn}>
          <BulkLoadDataTable
            className={cs.dataTable}
            uploadPlateDataStepData={uploadPlateDataStepData}
            previewPlates={previewPlates}
          />
        </div>
      </div>
    </div>
  )
}

export default ConfirmUpdatesStep
