import { compact, findIndex, flatten, map, max, min, range, reverse } from 'lodash/fp'
import { supportedPlateFormats } from '~/components/TinyMicroplate.interface'

export interface PlateDims {
  numRows: number
  numCols: number
}

export const convertWellCoordsToWellName = (
  rowIndex: number,
  colIndex: number,
): string => {
  return `${String.fromCharCode(65 + rowIndex)}${colIndex + 1}`
}

// In a 96WP, well index goes from 0 to 95. Down, then to the right.
export const convertWellCoordsToWellIndex = (
  rowIndex: number,
  colIndex: number,
  plateDims: PlateDims,
): number => {
  return rowIndex + colIndex * plateDims.numRows
}

export const convertWellIndexToWellName = (
  wellIndex: number,
  plateDims: PlateDims,
): string => {
  const colIndex = Math.floor(wellIndex / plateDims.numRows)
  const rowIndex = wellIndex % plateDims.numRows
  return convertWellCoordsToWellName(rowIndex, colIndex)
}

export const convertWellIndexToWellCoord = (
  wellIndex: number,
  plateDims: PlateDims,
): {
  rowIndex: number
  colIndex: number
} => {
  return {
    rowIndex: wellIndex % plateDims.numRows,
    colIndex: Math.floor(wellIndex / plateDims.numRows),
  }
}

export const convertWellNameToWellCoord: (wellName: string) => {
  rowIndex: number
  colIndex: number
} = wellName => {
  const regex = /^([A-Z]+)|([0-9]+)$/g
  const matched = wellName.match(regex)
  if (!matched || matched.length != 2) {
    throw new Error(`Unable to parse well-name ${wellName}`)
  }
  const rowIndex: number = matched[0].charCodeAt(0) - 'A'.charCodeAt(0)
  const colIndex: number = parseInt(matched[1]) - 1

  return {
    rowIndex,
    colIndex,
  }
}

export const getPlateDims = (plateFormat: supportedPlateFormats): PlateDims => {
  let numRows
  let numCols
  if (plateFormat === 'wells_6') {
    numRows = 2
    numCols = 3
  }
  if (plateFormat === 'wells_12') {
    numRows = 3
    numCols = 4
  }
  if (plateFormat === 'wells_24') {
    numRows = 4
    numCols = 6
  }
  if (plateFormat === 'wells_96') {
    numRows = 8
    numCols = 12
  }

  return {
    numRows,
    numCols,
  }
}

export const getPlateFormatFromDims = (plateDims: PlateDims): supportedPlateFormats => {
  const { numRows, numCols } = plateDims
  if (numRows == 2 && numCols == 3) {
    return 'wells_6'
  }
  if (numRows == 3 && numCols == 4) {
    return 'wells_12'
  }
  if (numRows == 4 && numCols == 6) {
    return 'wells_24'
  }
  if (numRows == 8 && numCols == 12) {
    return 'wells_96'
  }
  throw new Error(
    `Unable to map numRows: ${numRows}, numCols: ${numCols} to supported plate type`,
  )
}

export const getNumWells = (plateFormat: supportedPlateFormats): number => {
  const { numRows, numCols } = getPlateDims(plateFormat)
  return numRows * numCols
}

export const getWellMatrix = (plateFormat: supportedPlateFormats): string[][] => {
  const { numRows, numCols } = getPlateDims(plateFormat)

  return map(
    row => map(col => convertWellCoordsToWellName(row, col), range(0, numCols)),
    range(0, numRows),
  )
}

export const getWellNamesBetweenWellCoords = (
  rowOne: number,
  colOne: number,
  rowTwo: number,
  colTwo: number,
) => {
  const rowMin = min([rowOne, rowTwo]) as number
  const rowMax = max([rowOne, rowTwo]) as number
  const colMin = min([colOne, colTwo]) as number
  const colMax = max([colOne, colTwo]) as number

  return flatten(
    map(
      row =>
        map(col => convertWellCoordsToWellName(row, col), range(colMin, colMax + 1)),
      range(rowMin, rowMax + 1),
    ),
  )
}

export const getWellNamesToRightOfWellIndex = (
  wellIndex: number,
  plateDims: PlateDims,
) => {
  const wellCoord = convertWellIndexToWellCoord(wellIndex, plateDims)
  return getWellNamesToRightOfWell(wellCoord.rowIndex, wellCoord.colIndex, plateDims)
}

// Given well names, determine the maximum consecutive number of wells from the right side of the plate.
export const getRightPackedWellIndexForWellNames = (
  wellNames: string[],
  plateDims: PlateDims,
) => {
  const wellIndices = wellNames.map(wellName => {
    const wellCoord = convertWellNameToWellCoord(wellName)
    return convertWellCoordsToWellIndex(
      wellCoord.rowIndex,
      wellCoord.colIndex,
      plateDims,
    )
  })

  const startWellIndex = plateDims.numCols * plateDims.numRows

  const indicesToTry = reverse(range(0, startWellIndex))
  const lastIndex = findIndex(
    wellIndex => !wellIndices.includes(wellIndex),
    indicesToTry,
  )

  return lastIndex !== -1
    ? plateDims.numCols * plateDims.numRows - indicesToTry[lastIndex] - 1
    : plateDims.numCols * plateDims.numRows
}

export const getWellNamesToRightOfWell = (
  wellRow: number,
  wellCol: number,
  plateDims: PlateDims,
) => {
  return compact(
    flatten(
      map(
        row =>
          map(
            col =>
              col > wellCol || (col == wellCol && row >= wellRow)
                ? convertWellCoordsToWellName(row, col)
                : null,
            range(0, plateDims.numCols),
          ),
        range(0, plateDims.numRows),
      ),
    ),
  ).sort()
}

export const getWellNamesBetweenColumns = (
  colOne: number,
  colTwo: number,
  plateDims: PlateDims,
) => {
  const colMin = min([colOne, colTwo]) as number
  const colMax = max([colOne, colTwo]) as number

  return flatten(
    map(
      row =>
        map(col => convertWellCoordsToWellName(row, col), range(colMin, colMax + 1)),
      range(0, plateDims.numRows),
    ),
  )
}

//  Converts contents to a well array. If content is truthy, well index will be
//  included.
export const contentsToWellArray = (contents: (string | null)[][]): number[] => {
  return contents
    .map((row, row_idx) => {
      return row
        .map((col, col_idx) => {
          return col ? col_idx * contents.length + row_idx : null
        })
        .filter((v): v is number => v != null)
    })
    .flat()
    .sort()
}

export const contentsToPlateDim = (contents: (string | null)[][]): PlateDims => {
  return {
    numRows: contents.length,
    numCols: contents[0].length,
  }
}

// Returns in column-order.
export const contentsToWellNames = (contents: (string | null)[][]): string[] => {
  const wellArray = contentsToWellArray(contents)
  const plateDim = contentsToPlateDim(contents)
  return wellArray.map(wellIndex => {
    return convertWellIndexToWellName(wellIndex, plateDim)
  })
}

export const isValidWell = (
  wellName: string,
  plateFormat: supportedPlateFormats,
): boolean => {
  const plateDims = getPlateDims(plateFormat)
  const { rowIndex, colIndex } = convertWellNameToWellCoord(wellName)
  return rowIndex < plateDims.numRows && colIndex < plateDims.numCols
}
