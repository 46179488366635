import cx from 'classnames'
import PropTypes from 'prop-types'

import cs from './text_checkbox.scss'

interface TextCheckboxProps {
  checked: boolean
  className?: string
  checkedText: string
  uncheckedText: string
  onClick: () => void
  checkedType: 'success' | 'error' | 'accent' | 'warning'
  uncheckedType: 'success' | 'error' | 'accent' | 'warning'
}

const TextCheckbox = ({
  checked,
  className,
  checkedText,
  uncheckedText,
  onClick,
  checkedType,
  uncheckedType,
}: TextCheckboxProps) => (
  <div
    onClick={onClick}
    className={cx(
      cs.textCheckbox,
      className,
      checked && cs[checkedType],
      !checked && cs[uncheckedType],
    )}
  >
    {checked ? checkedText : uncheckedText}
  </div>
)

TextCheckbox.propTypes = {
  checked: PropTypes.bool,
  className: PropTypes.string,
  onClick: PropTypes.func,
  checkedText: PropTypes.string,
  uncheckedText: PropTypes.string,
  checkedType: PropTypes.oneOf(['success', 'error', 'accent', 'warning']),
  uncheckedType: PropTypes.oneOf(['success', 'error', 'accent', 'warning']),
}

TextCheckbox.defaultProps = {
  checkedType: 'success',
  uncheckedType: 'error',
}

export default TextCheckbox
