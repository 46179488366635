import desktopAPI from '~/api/desktop'
import { UseFetchDataResult, useFetchData } from '~/api/useFetchData'
import { RoutineDSL, RoutineDefinition } from '../types/RoutineDefinition.interface'

// TODO: This isn't a good name but it is a really convenient object
export type RoutineDefinitionWithDSL = RoutineDefinition & { dsl: RoutineDSL }

export function useRoutineDefinitionsWithDSLs(
  routineDefinitionNames: string[],
): UseFetchDataResult<RoutineDefinitionWithDSL[]> {
  return useFetchData(() => fetch(routineDefinitionNames), [routineDefinitionNames])
}

async function fetch(
  routineDefinitionNames: string[],
): Promise<RoutineDefinitionWithDSL[]> {
  if (routineDefinitionNames.length === 0) return []

  const [routineDefinitions, templateJsonsByName] = await Promise.all([
    desktopAPI.getRoutineDefinitions({
      names: routineDefinitionNames,
    }),
    desktopAPI.bulkRoutineDefinitionTemplateJsonsByName(routineDefinitionNames),
  ])

  return routineDefinitions.map(defin => ({
    ...defin,
    dsl: templateJsonsByName.templateJsons[defin.name],
  }))
}
