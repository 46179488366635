import cx from 'classnames'
import { filter, get } from 'lodash/fp'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'

import workcellAPI, { GetLiveStepsResponse } from '~/api/desktop/workcell'
import Dialog from '~/components/Dialog'
import { displayCount } from '~/utils/string'

import { ProcessStep } from '../../types/ProcessStep.interface'
import CancelProcessStepWarning, {
  showCancelProcessStepDialog,
} from './CancelProcessStepWarning'
import FailedProcessStepTable from './FailedProcessStepTable'
import RetryProcessStepWarning, {
  showRetryProcessStepDialog,
} from './RetryProcessStepWarning'
import cs from './failed_process_step_dialog.scss'

const FailedProcessStepDialog = ({
  isOpen,
  onClose,
  onStepRetry,
  onStepCancel,
}: {
  isOpen: boolean
  onClose: () => void
  onStepRetry: (step: ProcessStep) => void
  onStepCancel: (step: ProcessStep) => void
}) => {
  const [processStepData, setProcessStepData] = useState<GetLiveStepsResponse | null>(
    null,
  )
  const [selectedProcessStepRetry, setSelectedProcessStepRetry] =
    useState<ProcessStep | null>(null)
  const [selectedProcessStepCancel, setSelectedProcessStepCancel] =
    useState<ProcessStep | null>(null)

  // Update the instrument state
  const fetchSteps = async () => {
    try {
      const response = await workcellAPI.getLiveSteps()
      setProcessStepData(response)
    } catch (error) {
      console.error('Failed to fetch workcell status') // eslint-disable-line no-console
      console.error(String(error)) // eslint-disable-line no-console
    }
  }
  useEffect(() => {
    if (isOpen) {
      setSelectedProcessStepRetry(null)
      setSelectedProcessStepCancel(null)
      fetchSteps()
    }
  }, [isOpen])
  const failedSteps = filter(['status', 'failed'], get('steps', processStepData))

  const handleGoBack = () => {
    setSelectedProcessStepRetry(null)
    setSelectedProcessStepCancel(null)
  }

  const handleStepCancel = (step: ProcessStep) => {
    onClose()
    onStepCancel(step)
  }

  const handleStepRetry = (step: ProcessStep) => {
    onClose()
    onStepRetry(step)
  }

  const handleStepCancelInitial = (step: ProcessStep) => {
    const show = showCancelProcessStepDialog(step)

    // If there are additional instructions, show the dialog.
    if (show) {
      setSelectedProcessStepCancel(step)
    } else {
      handleStepCancel(step)
    }
  }

  const handleStepRetryInitial = (step: ProcessStep) => {
    const show = showRetryProcessStepDialog(step)

    // If there are additional instructions, show the dialog.
    if (show) {
      setSelectedProcessStepRetry(step)
    } else {
      handleStepRetry(step)
    }
  }

  const renderContents = () => {
    if (selectedProcessStepCancel) {
      return (
        <CancelProcessStepWarning
          className={cs.table}
          step={selectedProcessStepCancel}
          onCancel={handleGoBack}
          onConfirm={handleStepCancel}
        />
      )
    }

    if (selectedProcessStepRetry) {
      return (
        <RetryProcessStepWarning
          className={cs.table}
          step={selectedProcessStepRetry}
          onCancel={handleGoBack}
          onConfirm={handleStepRetry}
        />
      )
    }

    if (failedSteps.length === 0) {
      return null
    }

    return (
      <>
        <Dialog.Title>Workcell Error Recovery</Dialog.Title>
        {failedSteps && (
          <Dialog.Subtitle>
            {displayCount('failed step', failedSteps.length)}
          </Dialog.Subtitle>
        )}
        <FailedProcessStepTable
          className={cs.table}
          failedSteps={failedSteps}
          onCancelStep={handleStepCancelInitial}
          onRetryStep={handleStepRetryInitial}
        />
      </>
    )
  }

  return (
    <Dialog
      isOpen={isOpen}
      onClose={onClose}
      className={cx(
        cs.failedProcessStepDialog,
        (selectedProcessStepCancel || selectedProcessStepRetry) && cs.narrow,
      )}
    >
      {renderContents()}
    </Dialog>
  )
}

FailedProcessStepDialog.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onStepRetry: PropTypes.func,
  onStepCancel: PropTypes.func,
}

export default FailedProcessStepDialog
