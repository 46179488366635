import PropTypes from 'prop-types'
import { IconProps } from './types/IconProps'
const LinearScaleIcon = ({ className, onClick }: IconProps) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    height='24'
    width='24'
    viewBox='0 0 24 24'
    className={className}
    onClick={onClick}
  >
    <g>
      <rect fill='none' height='24' width='24' />
    </g>
    <g>
      <g>
        <g>
          <path d='M19.5,9.5c-1.03,0-1.9,0.62-2.29,1.5h-2.92C13.9,10.12,13.03,9.5,12,9.5s-1.9,0.62-2.29,1.5H6.79 C6.4,10.12,5.53,9.5,4.5,9.5C3.12,9.5,2,10.62,2,12s1.12,2.5,2.5,2.5c1.03,0,1.9-0.62,2.29-1.5h2.92c0.39,0.88,1.26,1.5,2.29,1.5 s1.9-0.62,2.29-1.5h2.92c0.39,0.88,1.26,1.5,2.29,1.5c1.38,0,2.5-1.12,2.5-2.5S20.88,9.5,19.5,9.5z' />
        </g>
      </g>
    </g>
  </svg>
)

LinearScaleIcon.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
}

export default LinearScaleIcon
