import cx from 'classnames'
import { set, unset } from 'lodash/fp'
import PropTypes from 'prop-types'
import { useContext, useEffect, useState } from 'react'

import desktopAPI from '~/api/desktop'
import desktopIntegrationsAPI from '~/api/desktop/desktopIntegrations'
import integrationsAPIv2 from '~/api/desktop/integrations'
import StructuredDataForm from '~/components/StructuredDataForm'
import Toaster from '~/components/Toaster'
import Button from '~/components/buttons/Button'

import WorkcellStatusContext from '~/pages/Workcell/WorkcellStatusContext'
import { useNativeSlackIntegration } from '~/pages/Workcell/utils/workcellStatus'
import cs from './slack.scss'

interface SlackProps {
  className?: string
}

const Slack = ({ className }: SlackProps) => {
  // Note: This will be removed once we've fully migrated to using the native slack integration
  const workcellStatus = useContext(WorkcellStatusContext)
  const useNativeSlack = useNativeSlackIntegration(workcellStatus)

  const [configEditsUi, setConfigEditsUi] = useState(null)
  const [configSchema, setConfigSchema] = useState(null)
  const [config, setConfig] = useState(null)

  const api = {
    getSchema: desktopAPI.getGlobalConfigSchema,
    setConfig: desktopAPI.setGlobalConfig,
    getConfig: desktopAPI.getGlobalConfig,
  }

  const hydrateConfigEdits = _config => {
    setConfigEditsUi(_config)
  }

  const handleConfigSave = async () => {
    const oldConfig = config
    const newConfig = configEditsUi
    setConfig(newConfig)
    try {
      await api.setConfig(
        newConfig,
        true, // overwrite
      )
    } catch (_error) {
      // No need to handle disconnect error here, since it is handled with regular updateStatus.
      Toaster.show({
        message: 'New options failed to saved.',
        intent: 'danger',
      })
      setConfig(oldConfig)
      setConfigEditsUi(oldConfig)
      return
    }
    Toaster.show({ message: 'New options saved.', intent: 'success' })
  }

  const sendInfoTestMessage = async () => {
    const response = useNativeSlack
      ? await integrationsAPIv2.sendInfoTestSlackMessage()
      : await desktopIntegrationsAPI.sendInfoTestSlackMessage()

    if (response.success) {
      Toaster.show({
        message: 'Info test message successfully sent',
        intent: 'success',
      })
    } else {
      let errorMessage = 'Info test message failed to send.'
      if (response.error) {
        errorMessage += ' ' + response.error
      }
      Toaster.show({
        message: errorMessage,
        intent: 'danger',
      })
    }
  }

  const sendCriticalTestMessage = async () => {
    const response = useNativeSlack
      ? await integrationsAPIv2.sendCriticalTestSlackMessage()
      : await desktopIntegrationsAPI.sendCriticalTestSlackMessage()

    if (response.success) {
      Toaster.show({
        message: 'Critical test message successfully sent',
        intent: 'success',
      })
    } else {
      let errorMessage = 'Critical test message failed to send.'
      if (response.error) {
        errorMessage += ' ' + response.error
      }
      Toaster.show({
        message: errorMessage,
        intent: 'danger',
      })
    }
  }

  useEffect(() => {
    const fetchGlobalConfigSchema = async () => {
      const _globalConfigSchema = await api.getSchema()
      setConfigSchema(_globalConfigSchema.schema)
    }
    const fetchConfig = async () => {
      const _config = await api.getConfig()
      setConfig(_config)
      hydrateConfigEdits(_config)
    }

    fetchGlobalConfigSchema()
    fetchConfig()
  }, [])

  const handleConfigUpdateUi = (key, newValue) => {
    if (newValue === null) {
      // @ts-ignore, legacy
      setConfigEditsUi(unset(key, configEditsUi))
    } else {
      // @ts-ignore, legacy
      setConfigEditsUi(set(key, newValue, configEditsUi))
    }
  }

  return (
    <div className={cx(className, cs.slack)}>
      <div className={cs.title}>Configure Slack Integration</div>
      <StructuredDataForm
        keysToDisplay={[
          'monomerApiToken',
          'monomerDomain',
          'slackInfoChannel',
          'slackCriticalChannel',
          'slackEnabled',
        ]}
        data={configEditsUi}
        variant='vertical'
        onEdit={handleConfigUpdateUi}
        dataSchema={configSchema || undefined}
        inputsClassName={cs.inputs}
        fieldClassName={cs.row}
        className={cs.structuredDataForm}
      />
      <div className={cs.buttons}>
        <Button
          type='primary'
          label='Save Changes'
          onClick={handleConfigSave}
          className={cs.button}
        />
        <Button
          label='Send Info Test Message'
          onClick={sendInfoTestMessage}
          className={cs.button}
        />
        <Button
          label='Send Critical Test Message'
          onClick={sendCriticalTestMessage}
          className={cs.button}
        />
      </div>
    </div>
  )
}

Slack.propTypes = {
  className: PropTypes.string,
}

export default Slack
