import { any, isArray, isNumber } from 'lodash/fp'
import { InstrumentVizDisplaySize } from './InstrumentVizDisplaySize.interface'
import { StorageDimensions } from './StorageViz/StorageInstrumentConfig.interface'

const DISPLAY_SIZES_DESCENDING: InstrumentVizDisplaySize[] = ['micro', 'mini', 'normal']

export interface StorageThreshold {
  numShelves: number
  numLevels: number
}

export const getDisplaySizeUsingDimensionThresholds = (
  dimensions: StorageDimensions,
  thresholds: Partial<Record<InstrumentVizDisplaySize, StorageThreshold>>,
) => {
  for (const displaySize of DISPLAY_SIZES_DESCENDING) {
    const threshold = thresholds[displaySize]
    if (!threshold) {
      continue
    }
    let exceeds = false
    if (isNumber(dimensions.numLevels) && dimensions.numLevels >= threshold.numLevels) {
      exceeds = true
    }
    if (
      isArray(dimensions.numLevels) &&
      any(level => level >= threshold.numLevels, dimensions.numLevels)
    ) {
      exceeds = true
    }
    if (dimensions.numShelves && dimensions.numShelves >= threshold.numShelves) {
      exceeds = true
    }
    if (exceeds) {
      return displaySize
    }
  }
  return 'large'
}
