import { getValidStepDataIfComplete as getValidConfirmUpdatesStepDataIfComplete } from './ConfirmUpdatesStep'
import { LinkCellLineLotsToCulturesWizardData } from './LinkCellLineLotsToCulturesWizardData.interface'
import { getValidStepDataIfComplete as getValidUploadDataStepDataIfComplete } from './UploadMetadataStep'

export const isCurrentWizardStepComplete = (
  wizardData: LinkCellLineLotsToCulturesWizardData,
) => {
  if (wizardData.currentStep === 'uploadMetadata') {
    return getValidUploadDataStepDataIfComplete(wizardData) !== null
  }
  if (wizardData.currentStep === 'confirmUpdates') {
    return getValidConfirmUpdatesStepDataIfComplete(wizardData) !== null
  }
  return false
}
