import cx from 'classnames'

import { filter, includes, map } from 'lodash/fp'
import {
  BulkLoadParsedPlateWithStorageLoadLocation,
  BulkLoadValidatedCulturePlates,
} from '~/api/operatorActions/bulkLoadCulturePlates'
import { displayCount } from '~/utils/string'
import StorageViz from '../../components/StorageViz'
import GroupedErrorTable from '../GroupedErrorTable'
import { InstrumentDescriptorForItemType } from '../LoadUnloadItems/InstrumentDescriptorForItemType.interface'
import { ProcessItemType } from '../reloadItems/ReloadOperation.interface'
import { UploadPlateDataStepData } from './BulkLoadCulturePlatesWizardData.interface'
import { getBulkLoadParsePlateDataErrorGroups } from './getBulkLoadParsePlateDataErrorGroups'
import cs from './upload_plate_data_step_preview.scss'

export interface UploadPlateDataStepPreviewProps {
  className?: string
  loading: boolean
  stepData: UploadPlateDataStepData
}

export const getReloadChangesForPlates = (
  plates: BulkLoadParsedPlateWithStorageLoadLocation[],
  instrument: InstrumentDescriptorForItemType,
  shelf: number,
  culturePlates: BulkLoadValidatedCulturePlates[],
) => {
  const existingPlateBarcodes = map(
    'user_facing_id',
    filter(['already_created', true], culturePlates),
  )
  return plates.map((plate: BulkLoadParsedPlateWithStorageLoadLocation) => ({
    command: 'load_process_item',
    instrumentName: instrument.instrument.instrumentName,
    levelIndex: plate.level,
    manuallyMove: true,
    shelfIndex: shelf,
    type: 'existing_item' as ProcessItemType,
    processItemUuid: plate.plate_barcode,
    processItem: includes(plate.plate_barcode, existingPlateBarcodes)
      ? {
          uuid: plate.plate_barcode,
          isExistingItemPlaceholder: true,
          existingItemType: 'culture_plate',
        }
      : {
          uuid: plate.plate_barcode,
          isNewItemPlaceholder: true,
        },
  }))
}

const UploadPlateDataStepPreview = ({
  className,
  loading,
  stepData,
}: UploadPlateDataStepPreviewProps) => {
  const renderContents = (
    instrument: InstrumentDescriptorForItemType,
    shelf: number,
  ) => {
    if (loading) {
      return <div className={cs.info}>Loading preview...</div>
    }
    let { parseDataResponse } = stepData
    if (parseDataResponse === null) {
      return (
        <>
          <div className={cs.info}>
            Loading plates onto {instrument.instrument.instrumentName}, shelf {shelf}
          </div>
          <StorageViz
            className={cs.storageViz}
            instrument={instrument.instrument}
            showOnlyShelfIndex={shelf}
          />
        </>
      )
    }
    if (parseDataResponse.type === 'errors') {
      return (
        <>
          <div className={cs.errorCount}>
            {displayCount('error', parseDataResponse.errors.length)} with pasted data
          </div>
          <GroupedErrorTable
            className={cs.parseDataErrorTable}
            errorGroups={getBulkLoadParsePlateDataErrorGroups(parseDataResponse)}
          />
        </>
      )
    }
    const changes = getReloadChangesForPlates(
      parseDataResponse.data,
      instrument,
      shelf,
      parseDataResponse.culture_plates,
    )
    return (
      <>
        <div className={cs.info}>
          Loading {displayCount('plate', changes.length)} onto{' '}
          {instrument.instrument.instrumentName}, shelf {shelf}
        </div>
        <StorageViz
          className={cs.storageViz}
          instrument={instrument.instrument}
          showOnlyShelfIndex={shelf}
          changes={changes}
        />
      </>
    )
  }
  if (!stepData.selectedInstrument || !stepData.selectedShelf) {
    return null
  }
  return (
    <div className={cx(className, cs.uploadPlateDataStepPreview)}>
      <div className={cs.sectionTitle}>Preview</div>
      <div className={cs.contents}>
        {renderContents(stepData.selectedInstrument, stepData.selectedShelf)}
      </div>
    </div>
  )
}

export default UploadPlateDataStepPreview
