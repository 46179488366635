import cx from 'classnames'
import { useEffect } from 'react'
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom'

import Tabs from '~/components/Tabs'
import { analytics } from '~/core/analytics'
import useTabsWithUrl from '~/utils/hooks/useTabsWithUrlPart'

import CultureOverview from './CultureOverview'
import CulturePageHeader from './CulturePageHeader'

import { useQuery } from '@apollo/client'
import { gql } from '~/__generated__/gql'
import { FullPageError } from '~/components/Errors'
import { useMetrics } from '../../metrics'
import CultureImages from './CultureImages'
import cs from './culture_page.scss'

const TABS = [
  {
    key: 'overview',
    title: 'Overview',
  },
  {
    key: 'images',
    title: 'Images',
  },
]

const GRAPHQL_QUERY = gql(`
  query CulturePage($id: UUID!) {
    wellCulture(id: $id) {
      id
      well
      name
      status
      createdAt
      culturePlate {
        id
        barcode
        plateDimensions {
          rows
          columns
        }
      }
      parentCulture {
        id
        name
      }
      ...CulturePageHeaderFragment
      ...CultureOverviewFragment
      ...CultureImagesFragment
    }
  }
`)

export interface CulturePageProps {
  className?: string
  cultureId: string
}

const CulturePage = ({ className, cultureId }: CulturePageProps) => {
  const match = useRouteMatch()

  const { loading, error, data } = useQuery(GRAPHQL_QUERY, {
    variables: { id: cultureId },
  })
  const metrics = useMetrics()
  useEffect(() => metrics.culturePage.open(cultureId), [metrics, cultureId])
  useEffect(() => {
    if (data == null) {
      return
    }
    analytics.page('Monitor', 'Culture', {
      culture: cultureId,
      plate: data?.wellCulture?.culturePlate.id,
      well: data?.wellCulture?.well,
    })
  }, [data])

  const [tabs, selectedTabId, handleTabChange] = useTabsWithUrl(TABS)

  const renderContent = () => {
    if (error) {
      return <FullPageError error={error} />
    }
    if (loading) {
      return <p>Loading...</p>
    }
    if (!data?.wellCulture) {
      return (
        <p>
          No culture was found at this address. Try going back and choosing a culture
          from Live Cultures.
        </p>
      )
    }

    return (
      <>
        <CulturePageHeader wellCulture={data.wellCulture!} className={cs.header} />
        <Tabs
          tabs={tabs}
          selectedTabId={selectedTabId}
          className={cs.tabs}
          panelClassName={cs.tabPanel}
          onlyRenderWhenViewed
          empty
          onTabChange={handleTabChange}
        />
        <Switch>
          <Route
            exact
            path={match.path}
            render={() => <Redirect to={`${match.url}/overview`} />}
          />
          <Route
            path={`${match.path}/overview`}
            render={() => <CultureOverview wellCulture={data.wellCulture!} />}
          />
          <Route
            path={`${match.path}/images`}
            render={() => <CultureImages wellCulture={data.wellCulture!} />}
          />
        </Switch>
      </>
    )
  }

  return <div className={cx(className, cs.culturePage)}>{renderContent()}</div>
}

export default CulturePage
