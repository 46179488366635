import { useContext, useEffect, useState } from 'react'
import Dialog from '~/components/Dialog'

import dayjs from 'dayjs'
import futureRoutinesAPI, {
  GetConsumablesRequiredForFutureRoutinesUntilResponse,
} from '~/api/desktop/futureRoutines'
import { ApiV2HttpError } from '~/api/desktopAPIv2'
import DateInput from '~/components/DateInput'
import LoadingMessage from '~/components/LoadingMessage'
import BareCheckmarkIcon from '~/components/icons/BareCheckmarkIcon'
import BareWarningIcon from '~/components/icons/BareWarningIcon'
import TinyNotification from '~/components/notifications/TinyNotification'
import { displayCount } from '~/utils/string'
import WorkcellStatusContext from '../../WorkcellStatusContext'
import ConsumableReqsTable from '../../components/ConsumableReqsTable'
import { getNumRoutinesQueued } from '../../utils/workcellStatus'
import cs from './future_routines_consumable_planner_dialog.scss'

const FutureRoutinesConsumablePlannerDialog = ({
  isOpen,
  onClose,
}: {
  isOpen: boolean
  onClose: () => void
}) => {
  const workcellStatus = useContext(WorkcellStatusContext)
  const [scheduledFor, setScheduledFor] = useState<string | null>(null)
  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<string | null>(null)
  const [response, setResponse] =
    useState<GetConsumablesRequiredForFutureRoutinesUntilResponse | null>(null)

  const fetchMissingRequirements = async () => {
    if (!scheduledFor) {
      return
    }
    setLoading(true)
    try {
      const response =
        await futureRoutinesAPI.getConsumablesRequiredForFutureRoutinesUntil(
          scheduledFor,
        )
      setResponse(response)
    } catch (error) {
      const apiError = error as ApiV2HttpError
      setError(`Could not calculate required consumables. Error: ${apiError.message}`)
    }

    setLoading(false)
  }

  useEffect(() => {
    fetchMissingRequirements()
  }, [scheduledFor])

  const renderContents = () => {
    if (loading) {
      return (
        <LoadingMessage
          className={cs.loadingMessage}
          label='Calculating consumable requirements...'
        />
      )
    }
    if (error) {
      return <TinyNotification type='bareError' message={error} className={cs.error} />
    }
    if (!response) return null
    // Some future routines could be queued (and thus not appear in the future routines tab).
    // To minimize confusion, just call them "routines" here.
    const numFutureRoutinesMessage = (
      <div className={cs.numFutureRoutinesMessage}>
        Consumables checked for {displayCount('routine', response.num_future_routines)}
      </div>
    )

    const numRoutinesQueued = getNumRoutinesQueued(workcellStatus)
    const queuedRoutinesWarning =
      numRoutinesQueued > 0 ? (
        <TinyNotification
          type='bareWarning'
          message={
            'The above calculation does not consider currently queued routines. ' +
            'For a more accurate calculation, please retry once routines have completed.'
          }
          className={cs.queuedRoutinesWarning}
        />
      ) : null

    if (response.requirements.length === 0) {
      return (
        <>
          <div className={cs.bigSuccessMessage}>
            <BareCheckmarkIcon className={cs.checkmarkIcon} />
            No additional consumables required.
          </div>
          {numFutureRoutinesMessage}
          {queuedRoutinesWarning}
        </>
      )
    }
    return (
      <>
        <div className={cs.bigErrorMessage}>
          <BareWarningIcon className={cs.warningIcon} />
          {displayCount('missing consumable', response.requirements.length)}
        </div>
        {numFutureRoutinesMessage}
        <ConsumableReqsTable
          className={cs.consumableReqsTable}
          consumableReqs={response.requirements}
        />
        {queuedRoutinesWarning}
      </>
    )
  }

  return (
    <Dialog
      isOpen={isOpen}
      onClose={onClose}
      className={cs.futureRoutinesConsumablePlannerDialog}
    >
      <Dialog.Title>Plan Consumables For Upcoming Routines</Dialog.Title>
      <DateInput
        value={scheduledFor}
        onChange={setScheduledFor}
        label='Plan Until After'
        defaultDate={dayjs().add(1, 'day').toDate()}
        className={cs.dateInput}
        showTime
        showActionsBar
        todayButtonText='Now'
      />
      {renderContents()}
    </Dialog>
  )
}

export default FutureRoutinesConsumablePlannerDialog
