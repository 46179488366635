const deploymentModes = ['cloud', 'automation', 'hybrid']
export type DeploymentMode = (typeof deploymentModes)[number]
export function parseDeploymentMode(
  maybeDeploymentMode: string | undefined,
): DeploymentMode | undefined {
  if (maybeDeploymentMode === undefined) {
    return undefined
  }
  if (deploymentModes.includes(maybeDeploymentMode)) {
    return maybeDeploymentMode as DeploymentMode
  }
  console.error(`Unable to parse deployment mode: ${maybeDeploymentMode}`)
  return undefined
}

export interface UserMetadata {
  // Automatically managed by Auth0
  name: string
  picture: string
  email: string
  // Managed by Auth0 organization settings
  orgDisplayName: string
  // Managed by Auth0 User Management roles
  // Note: When we've consolidated the roles, we should define the types
  roles: string[]
}

// TODO(SWE=1274): OrgMetadata and UserMetadata will be moved out after Auth0 migration is complete
export interface MultiFrontendContext {
  deploymentMode: DeploymentMode
  cloudFrontend?: string
  homePage: string
  monitorEnabled: boolean
  useCoreAuth: boolean
  // TODO(SWE-1170): Temporary local variable, tbd on how this will be eventually resolved
  demoUseLocalAssets: boolean
  // Managed by fields specified in the organization Metadata section in Auth0
  orgMetadata: {
    automationEnabledIntegrations: string[]
    automationFrontend?: string
    enabledPages: string[] | 'all'
  }
  userMetadata: UserMetadata
}

// Note: This role is configured in Auth0. If we rename the role name, we need to update this
// constant as well.
export const USER_ADMIN_ROLE = 'Organization Admin'

export const getUserIsAdmin = (userMetadata: UserMetadata) => {
  // Note: Moving forward, we will likely revisit Authz with more granular controls. For now, we
  // stick with this access pattern mostly to follow existing convention.
  return userMetadata.roles.includes(USER_ADMIN_ROLE)
}
