import PropTypes from 'prop-types'
import { IconProps } from './types/IconProps'

const DownArrowIcon = ({ className, onClick }: IconProps) => (
  <svg
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    className={className}
    onClick={onClick}
  >
    <path d='M16.59 8.29501L12 12.875L7.41 8.29501L6 9.70501L12 15.705L18 9.70501L16.59 8.29501Z' />
  </svg>
)

DownArrowIcon.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
}

export default DownArrowIcon
