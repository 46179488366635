import cx from 'classnames'

import { TeachToolSeqFile } from '~/api/desktop/drivers/roboticArm'
import HelpPopover from '~/components/HelpPopover'
import CloseIcon from '~/components/icons/CloseIcon'
import cs from './sequence_file_inline_dialog.scss'

export interface SequenceFileInlineDialogProps {
  className?: string
  seqFile: TeachToolSeqFile
  onClose: () => void
}

const SequenceFileInlineDialog = ({
  className,
  seqFile,
  onClose,
}: SequenceFileInlineDialogProps) => {
  return (
    <div className={cx(className, cs.sequenceFileInlineDialog)}>
      <div className={cs.header}>
        <div className={cs.headerInner}>
          <div className={cs.label}>Viewing Sequence File</div>
          <div className={cs.seqFileName}>{seqFile.name}</div>
          <div className={cs.seqFilePath}>
            <HelpPopover
              helpContent={<div className={cs.seqFilePathPopover}>{seqFile.path}</div>}
              text='View Full Path'
              interactionKind='hover'
              placement='top'
              className={cs.seqFilePath}
            />
          </div>
        </div>
        <div className={cs.fill} />
        <CloseIcon className={cs.closeIcon} onClick={onClose} />
      </div>
      <div className={cs.seqFileContent}>
        <pre>{JSON.stringify(seqFile.seq_data, undefined, 4)}</pre>
      </div>
    </div>
  )
}

export default SequenceFileInlineDialog
