import { useMemo } from 'react'

import cx from 'classnames'
import LineGraph from '~/components/LineGraph'
import ReadOnlyField from '~/components/ReadOnlyField'
import CultureImage from '~/pages/slasDemo/CommandCenter/CulturePage/CultureImage'
import { getGrowthChartData, getSeededAt } from '../data/culturePopoverData'
import { getImagePaths } from '../data/generateClePipelinePlates'
import { ClePlate, getClePlateType } from './ClePlate.interface'
import cs from './cle_culture_popover.scss'

function CleCultureGrowthChart({
  data,
  className,
}: {
  data: {
    [name: string]: {
      day: number
      value: number
    }[]
  }
  className?: string
}) {
  return (
    <LineGraph
      className={className}
      layoutOptions={{
        marginTop: 3,
        marginBottom: 3,
        marginLeft: 3,
        marginRight: 3,
      }}
      axisOptions={{
        showYAxis: false,
        showXAxis: false,
      }}
      options={{
        xKey: 'day',
        yKey: 'value',
        lineColor: '#2CB1BC',
        dotSize: 2,
        xDomainMax: 5,
        yDomainMax: 100,
      }}
      data={data}
    />
  )
}

export function CleCulturePopover({
  clePlate,
  wellName,
  dayToSimulate,
  actualConfluency,
  useLocalAssets,
}: {
  clePlate: ClePlate
  wellName: string
  dayToSimulate: number
  actualConfluency: number
  useLocalAssets: boolean
}) {
  const imageData = useMemo(
    () => getImagePaths(useLocalAssets),
    // Note: This used to be dependent on the AppContext. Unclear if that's still required.
    [],
  )

  return (
    <div className={cs.cleCulturePopover}>
      <div className={cs.popoverHeader}>
        <div className={cs.popoverHeaderLeft}>
          <div className={cs.popoverHeaderLabel}>
            {clePlate.cellLine.name} &middot; {getClePlateType(clePlate)}
          </div>
          <div className={cs.popoverHeaderCulture}>Well {wellName}</div>
          <div className={cs.popoverHeaderMetadata}>
            Seeded {getSeededAt(dayToSimulate)}
          </div>
        </div>
        <div className={cs.popoverHeaderRight}>
          <CleCultureGrowthChart
            data={{
              [clePlate.cellLine.name]: getGrowthChartData(dayToSimulate),
            }}
            className={cs.popoverChart}
          />
        </div>
      </div>
      <div className={cs.popoverData}>
        <div className={cs.popoverDataTitle}>
          <span className={cs.confluency}>{actualConfluency}% Confluent</span>{' '}
          <span className={cs.capturedAt}>1 hour ago</span>
        </div>
        <div className={cs.thumbnailContainer}>
          <CultureImage
            className={cs.popoverImage}
            thumbnail={imageData[dayToSimulate]}
          />
        </div>
      </div>
      <div className={cs.popoverMetadata}>
        <ReadOnlyField
          label='Parent Cell Line'
          value={clePlate.cellLine.parentCellLine}
          className={cx(cs.popoverMetadataField, cs.popoverMetadataHighlight)}
          condensed
        />
        <ReadOnlyField
          label='Desired Edit'
          value={clePlate.cellLine.desiredEdit}
          className={cs.popoverMetadataField}
          condensed
        />
        <ReadOnlyField
          label='Owner'
          value={clePlate.owner}
          className={cx(cs.popoverMetadataField, cs.popoverMetadataHighlight)}
          condensed
        />
        <ReadOnlyField
          label='Next Action'
          value='Media Exchange'
          className={cs.popoverMetadataField}
          condensed
        />
      </div>
    </div>
  )
}
