import { set } from 'lodash/fp'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'

import integrationsAPI from '~/api/integrations'
import Dialog from '~/components/Dialog'
import Input from '~/components/Input'
import Toaster from '~/components/Toaster'
import Button from '~/components/buttons/Button'

import { DeprecatedSlackIntegration } from '~/types/Integration.interface'
import cs from './configure_slack_dialog.scss'

interface ConfigureSlackDialogProps {
  open: boolean
  onClose: () => void
  integration: DeprecatedSlackIntegration
  onIntegrationUpdate: (integration: DeprecatedSlackIntegration) => void
  onIntegrationDelete: () => void
}

const DeprecatedConfigureSlackDialog = ({
  open,
  onClose,
  integration,
  onIntegrationUpdate,
  onIntegrationDelete,
}: ConfigureSlackDialogProps) => {
  const [channel, setChannel] = useState('')
  const [channelEdit, setChannelEdit] = useState('')

  useEffect(() => {
    if (integration) {
      setChannel(integration.settings.channel || '')
      setChannelEdit(integration.settings.channel || '')
    }
  }, [integration])

  const updateChannel = async () => {
    await integrationsAPI.partialUpdate(integration.id, {
      settings: {
        channel: channelEdit,
      },
    })

    setChannel(channelEdit)

    onIntegrationUpdate(
      set(
        'settings',
        {
          ...integration.settings,
          channel: channelEdit,
        },
        integration,
      ),
    )
  }

  const sendTestMessage = async () => {
    const response = await integrationsAPI.sendTestSlackMessage()

    if (response.success) {
      Toaster.show({
        message: 'Test message successfully sent',
        intent: 'success',
      })
    } else {
      Toaster.show({
        message: `Test message failed to send: ${response.error}`,
        intent: 'danger',
      })
    }
  }

  const deleteIntegration = async () => {
    onClose()
    await integrationsAPI.del(integration.id)

    onIntegrationDelete()
  }

  return (
    <Dialog isOpen={open} onClose={onClose} className={cs.configureSlackDialog}>
      <div className={cs.title}>Configure Slack Integration</div>
      <Input
        label='Channel To Receive Messages'
        value={channelEdit}
        onChange={setChannelEdit}
        className={cs.inputContainer}
        inputClassName={cs.input}
      />
      <div className={cs.channelControls}>
        <Button
          label='Update'
          disabled={!channelEdit || channelEdit === channel}
          className={cs.button}
          onClick={updateChannel}
        />
        <Button
          label='Send Test Message'
          disabled={!channelEdit || channelEdit !== channel}
          className={cs.button}
          onClick={sendTestMessage}
        />
      </div>
      <div className={cs.controls}>
        <Button
          type='danger'
          label='Delete Integration'
          className={cs.button}
          onClick={deleteIntegration}
        />
      </div>
    </Dialog>
  )
}

DeprecatedConfigureSlackDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  integration: PropTypes.shape({
    settings: PropTypes.shape({
      channel: PropTypes.string,
    }),
    id: PropTypes.number,
  }),
  onIntegrationUpdate: PropTypes.func,
  onIntegrationDelete: PropTypes.func,
}

export default DeprecatedConfigureSlackDialog
