import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'

import integrationsAPIv2, { SlackIntegration } from '~/api/desktop/integrations'
import Dialog from '~/components/Dialog'
import Input from '~/components/Input'
import Toaster from '~/components/Toaster'
import Button from '~/components/buttons/Button'

import cs from './configure_slack_dialog.scss'

interface ConfigureSlackDialogProps {
  open: boolean
  onClose: () => void
  integration: SlackIntegration
  onIntegrationUpdate: (integration: SlackIntegration) => void
  onIntegrationDelete: (integrationId: string) => void
}

const ConfigureSlackDialog = ({
  open,
  onClose,
  integration,
  onIntegrationUpdate,
  onIntegrationDelete,
}: ConfigureSlackDialogProps) => {
  const [channel, setChannel] = useState('')
  const [channelEdit, setChannelEdit] = useState('')

  useEffect(() => {
    if (integration) {
      setChannel(integration.settings?.channel || '')
      setChannelEdit(integration.settings?.channel || '')
    }
  }, [integration])

  const updateChannel = async () => {
    const response = await integrationsAPIv2.configureSlack(integration.uuid, {
      channel: channelEdit,
    })
    setChannel(channelEdit)
    onIntegrationUpdate(response)
  }

  const sendTestMessage = async () => {
    const response = await integrationsAPIv2.sendTestSlackMessage()

    if (response.success) {
      Toaster.show({
        message: 'Test message successfully sent',
        intent: 'success',
      })
    } else {
      Toaster.show({
        message: `Test message failed to send: ${response.error}`,
        intent: 'danger',
      })
    }
  }

  const deleteIntegration = async () => {
    const response = await integrationsAPIv2.uninstallSlack(integration.uuid)
    if (response.success) {
      Toaster.show({
        message: 'Successfully deleted Slack integration.',
        intent: 'success',
      })
      onClose()
      onIntegrationDelete(integration.uuid)
    } else {
      Toaster.show({
        message: `Could not uninstall Slack: ${response.error}`,
        intent: 'danger',
      })
    }
  }

  return (
    <Dialog isOpen={open} onClose={onClose} className={cs.configureSlackDialog}>
      <div className={cs.title}>Configure Slack Integration</div>
      <Input
        label='Channel To Receive Messages'
        value={channelEdit}
        onChange={setChannelEdit}
        className={cs.inputContainer}
        inputClassName={cs.input}
      />
      <div className={cs.channelControls}>
        <Button
          label='Update'
          disabled={!channelEdit || channelEdit === channel}
          className={cs.button}
          onClick={updateChannel}
        />
        <Button
          label='Send Test Message'
          disabled={!channelEdit || channelEdit !== channel}
          className={cs.button}
          onClick={sendTestMessage}
        />
      </div>
      <div className={cs.controls}>
        <Button
          type='danger'
          label='Delete Integration'
          className={cs.button}
          onClick={deleteIntegration}
        />
      </div>
    </Dialog>
  )
}

ConfigureSlackDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  integration: PropTypes.shape({
    settings: PropTypes.shape({
      channel: PropTypes.string,
    }),
    id: PropTypes.number,
  }),
  onIntegrationUpdate: PropTypes.func,
  onIntegrationDelete: PropTypes.func,
}

export default ConfigureSlackDialog
