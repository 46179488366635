import cx from 'classnames'
import dayjs from 'dayjs'
import { get } from 'lodash/fp'
import PropTypes from 'prop-types'

import desktopAPI from '~/api/desktop'
import JsonWithOverflow from '~/components/JsonWithOverflow'
import Table from '~/components/Table'
import Toaster from '~/components/Toaster'
import Button from '~/components/buttons/Button'
import MinimalButton from '~/components/buttons/MinimalButton'
import RefreshIcon from '~/components/icons/RefreshIcon'
import usePaginatedResults from '~/utils/hooks/usePaginatedResults'
import { renderStatusPill } from '~/utils/statusPill'

import cs from './event_log.scss'

const STATUS_PILL_CONFIG = {
  Scheduled: { type: 'default' },
  Running: { type: 'accent' },
  Success: { type: 'success' },
  Failure: { type: 'error' },
}

const EventLog = ({ className }) => {
  const {
    loadingInitialResults,
    results: tasks,
    refetchResults: refetchEvents,
    fetchMoreResults,
    hasMoreResults,
  } = usePaginatedResults(desktopAPI.getTasks, {
    initialQueryParams: {
      types: [
        'handle_cytation__on_image_plate_dataset_ready',
        'handle_on_routine_complete',
      ],
    },
  })

  const retryEvent = async eventTask => {
    // The event task has the event type in its type, prefixed by handle_
    if (eventTask.type.substring(0, 7) !== 'handle_') {
      Toaster.show({
        message: 'Malformed event task. Could not retry.',
        intent: 'danger',
      })
      return
    }
    const eventType = eventTask.type.substring(7)
    const eventProperties = get('eventProperties', eventTask.metadata) || {}

    try {
      await desktopAPI.emitEvent(eventType, eventProperties)
      Toaster.show({
        message: 'Re-emitting event...(refresh to see latest)',
        intent: 'success',
      })
    } catch (e) {
      console.error(String(e)) // eslint-disable-line no-console
      Toaster.show({ message: 'Failed to re-emit event...', intent: 'danger' })
    }
  }

  const renderStatus = row => {
    return renderStatusPill(get('status', row), STATUS_PILL_CONFIG, {
      small: true,
      popoverContent: get('error', row),
    })
  }

  const renderEvent = row => {
    const eventType = row.type.substring(7)
    return (
      <div className={cs.event}>
        <div className={cs.type}>{eventType}</div>
        <JsonWithOverflow
          jsonObj={get(['metadata', 'eventProperties'], row)}
          className={cs.properties}
        />
      </div>
    )
  }

  const renderBarcode = row => {
    if (!row.metadata) return null
    if (row.type === 'handle_on_routine_complete') {
      return (
        <div className={cs.barcode}>
          {get(['metadata', 'eventProperties', 'plateBarcode'], row)}
        </div>
      )
    }
    if (row.type === 'handle_cytation__on_image_plate_dataset_ready') {
      return (
        <div className={cs.barcode}>
          {get(['metadata', 'eventProperties', 'plateBarcode'], row)}
        </div>
      )
    }
    return '\u2014'
  }

  const tableColumns = [
    {
      name: 'Timestamp',
      width: 125,
      render: row => dayjs(get(['createdAt'], row)).fromNow(),
      smallText: true,
    },
    {
      name: 'Barcode',
      width: 125,
      render: renderBarcode,
    },
    {
      name: 'Event',
      width: 350,
      render: renderEvent,
    },
    {
      name: 'Status',
      width: 150,
      render: row => renderStatus(row),
    },
    {
      name: 'User Script',
      width: 300,
      render: row => {
        const scriptName = get(['metadata', 'handlerMetadata', 'scriptName'], row)

        return scriptName && <div className={cs.scriptName}>{scriptName}.py</div>
      },
    },
    {
      name: '',
      width: 50,
      showOnHover: true,
      render: row =>
        get('status', row) === 'Failure' && (
          <MinimalButton label='Retry' onClick={() => retryEvent(row)} />
        ),
    },
  ]

  const renderTable = () => {
    if (loadingInitialResults) {
      return <div className={cs.bigMessage}>Loading events...</div>
    }
    if (!loadingInitialResults && (!tasks || tasks.length === 0)) {
      return <div className={cs.bigMessage}>No events found.</div>
    }

    return (
      <Table
        columns={tableColumns}
        data={tasks}
        className={cs.table}
        rowPaddingVariant='rowPaddingLow'
        rowKey='id'
        infiniteScrollProps={{
          hasMoreData: hasMoreResults,
          fetchMoreData: fetchMoreResults,
        }}
      />
    )
  }

  return (
    <div className={cx(className, cs.eventLog)}>
      <div className={cs.controls}>
        <div className={cs.fill} />
        <Button
          className={cs.button}
          label='Refresh Table'
          IconComponent={RefreshIcon}
          onClick={refetchEvents}
        />
      </div>
      {renderTable()}
    </div>
  )
}

EventLog.propTypes = {
  className: PropTypes.string,
}

export default EventLog
