import { JsonSchema } from '~/types/JsonSchema.interface'
import { getFieldConstraints } from '~/utils/jsonSchema'
import { ProcessItemFilters } from './ProcessItemFilters'

// Get process item select filters based on the jsonSchema field type and constraints
export const getProcessItemFiltersFromStructuredDataField = (
  key: string,
  fieldType: 'sample_plate' | 'assay_plate',
  schema?: JsonSchema,
): ProcessItemFilters => {
  const constraints = getFieldConstraints(key, schema)

  if (fieldType === 'sample_plate') {
    return {
      types: ['culture_plate'],
      // If is_empty is not explicitly specified as true in the routine DSL,
      // default to false
      is_empty: constraints?.is_empty || false,
      allowed_labware_names: constraints?.allowed_labware_names,
    }
  } else {
    return {
      types: ['assay_plate'],
    }
  }
}
