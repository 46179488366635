import Dialog from '~/components/Dialog'
import PropTypes from '~/propTypes'

import { ProcessStep } from '../../types/ProcessStep.interface'
import CancelProcessStepWarning from './CancelProcessStepWarning'
import cs from './cancel_process_step_dialog.scss'

const CancelProcessStepDialog = ({
  isOpen,
  onClose,
  step,
  onConfirm,
}: {
  isOpen: boolean
  onClose: () => void
  step?: ProcessStep
  onConfirm: () => void
}) => {
  return (
    <Dialog isOpen={isOpen} onClose={onClose} className={cs.cancelProcessStepDialog}>
      {step && (
        <CancelProcessStepWarning
          step={step}
          onCancel={onClose}
          onConfirm={onConfirm}
        />
      )}
    </Dialog>
  )
}

CancelProcessStepDialog.propTypes = {
  isOpen: PropTypes.bool,
  step: PropTypes.ProcessStep,
  onConfirm: PropTypes.func,
  onClose: PropTypes.func,
}

export default CancelProcessStepDialog
