import { size, without } from 'lodash/fp'
import { useState } from 'react'
import genericSchedule from '~/api/operatorActions/genericSchedule'
import { RoutineParametersForm } from '../../../Routines/RoutineParametersForm'
import { RoutineDefinitionWithDSL } from '../../../Routines/useRoutineDefinitionsWithDSLs'
import { RoutineParameters } from '../../../types/RoutineDefinition.interface'
import {
  OperatorActionConfigProps,
  PresentationalScheduleOperatorAction,
} from '../PresentationalScheduleOperatorAction'
import { RoutineSelect } from '../RoutineSelect'

import { GenericScheduleConfig } from '../GenericScheduleConfig.interface'
import QcRepeatsInput from '../QcRepeatsInput'
import RoutineInstructions from '../RoutineInstructions'
import {
  getAdvancedDialogParameters,
  getRoutineSamplePlateBarcodeParameter,
} from './routineDefinitionWithDSLAccessors'

import ButtonGroup from '~/components/buttons/ButtonGroup'
import MinimalButton from '~/components/buttons/MinimalButton'
import AdvancedRoutineParametersDialog from '~/pages/Workcell/Routines/AdvancedRoutineParametersDialog'
import { getJsonSchemaFromRoutineDefinition } from '~/pages/Workcell/Routines/getJsonSchemaFromRoutineDefinition'
import FutureRoutineScheduleForForm from '~/pages/Workcell/Schedule/FutureRoutines/FutureRoutineScheduleForForm'
import { ManagedProcessItemMultiSelect } from '~/pages/Workcell/components/processItem/ProcessItemSelect/ProcessItemMultiSelect'
import { getProcessItemFiltersFromStructuredDataField } from '~/pages/Workcell/components/processItem/ProcessItemSelect/getSelectFiltersFromStructuredDataField'
import { dateToDisplayString } from '~/utils/date'
import { displayCount } from '~/utils/string'
import {
  GenericScheduleFormState,
  ScheduleType,
} from './GenericScheduleFormState.interface'
import cs from './generic_schedule_form.scss'
import { getGenericScheduleRequest } from './getGenericScheduleRequest'
import { isFormReadyForSubmit } from './isFormReadyForSubmit'

interface GenericScheduleFormProps {
  operatorActionConfig: OperatorActionConfigProps<GenericScheduleConfig>
  schedulableRoutines: string[]
  routineDefinitions: RoutineDefinitionWithDSL[]
}

export const SCHEDULE_TYPE_OPTIONS = [
  {
    value: 'schedule_now',
    label: 'Schedule Now',
  },
  {
    value: 'schedule_future',
    label: 'Schedule Later',
  },
]

export default function GenericScheduleForm(
  props: GenericScheduleFormProps,
): JSX.Element {
  const [selectedRoutine, setCurrentRoutineDefinition] =
    useState<RoutineDefinitionWithDSL>(props.routineDefinitions[0])
  const [routineParameters, setRoutineParameters] = useState<RoutineParameters>({})
  const [qcRepeats, setQcRepeats] = useState(1)
  const [samplePlateBarcodes, setSamplePlateBarcodes] = useState<string[]>([])
  // Type is Union with null due to DateInput component requirements
  const [scheduledFor, setScheduledFor] = useState<string | null>(
    dateToDisplayString(new Date()),
  )
  const [scheduleType, setScheduleType] = useState<ScheduleType>('schedule_now')
  const [shouldQueueAutomatically, setShouldQueueAutomatically] =
    useState<boolean>(false)
  const [advancedDialogOpen, setAdvancedDialogOpen] = useState<boolean>(false)

  const isQcRoutine = selectedRoutine.type === 'qc'
  const showQcRepeatsInput =
    isQcRoutine &&
    (selectedRoutine.dsl.qc_repeatable === undefined ||
      selectedRoutine.dsl.qc_repeatable)

  // If a valid parameter for sample plate barcode can be found, allow the user
  // to select MULTIPLE barcodes to schedule.
  // Don't display this parameter in RoutineParamtersForm and instead display
  // a separate ManagedProcessItemMultiSelect.
  const samplePlateBarcodeParameter = isQcRoutine
    ? null
    : getRoutineSamplePlateBarcodeParameter(selectedRoutine)

  const advancedDialogParameters = getAdvancedDialogParameters(selectedRoutine)

  const formState: GenericScheduleFormState = {
    selectedRoutine,
    routineParameters,
    isQcRoutine,
    qcRepeats,
    samplePlateBarcodes,
    samplePlateBarcodeParameter,
    scheduleType,
    scheduledFor,
    shouldQueueAutomatically,
  }

  const resetParams = () => {
    setRoutineParameters({})
    setSamplePlateBarcodes([])
    setQcRepeats(1)
  }

  const updateCurrentRoutineDefinition = (
    routineDefinition: RoutineDefinitionWithDSL,
  ) => {
    setCurrentRoutineDefinition(routineDefinition)
    resetParams()
  }

  const getSubmitProps = () => {
    const isReady = isFormReadyForSubmit(formState)

    if (!isReady) {
      return {
        taskEndpoint: null,
        onSuccess: () => {},
        label: 'Schedule Routines',
      }
    }
    const genericScheduleRequest = getGenericScheduleRequest(formState)

    return {
      taskEndpoint: isFormReadyForSubmit(formState)
        ? () => genericSchedule.genericSchedule(genericScheduleRequest)
        : null,
      onSuccess: () => {},
      label: `Schedule ${displayCount(
        'Routine',
        genericScheduleRequest.routines.length,
      )}`,
      successToast: `Scheduled ${selectedRoutine.name}${
        genericScheduleRequest.routines.length > 1
          ? ' (x' + genericScheduleRequest.routines.length + ')'
          : ''
      }`,
      loadingMessage: `Scheduling ${selectedRoutine.name}${
        genericScheduleRequest.routines.length > 1
          ? ' (x' + genericScheduleRequest.routines.length + ')...'
          : '...'
      }`,
    }
  }

  const fieldsToIgnoreInPrimaryForm = [
    ...advancedDialogParameters,
    ...(samplePlateBarcodeParameter ? [samplePlateBarcodeParameter] : []),
  ]

  const fieldsToIgnoreInAdvancedDialog = without(
    advancedDialogParameters,
    Object.keys(selectedRoutine.dsl.inputs),
  )

  return (
    <PresentationalScheduleOperatorAction
      routineTitle='Routines'
      repeats={qcRepeats}
      submitProps={getSubmitProps()}
      operatorActionConfig={props.operatorActionConfig}
    >
      <div className={cs.genericScheduleForm}>
        <div className={cs.selectRoutineHeader}>
          <div className={cs.sectionTitle}> Select Routine </div>
          <RoutineSelect
            routineDefinitions={props.routineDefinitions}
            currentRoutineDefinition={selectedRoutine}
            setCurrentRoutineDefinition={updateCurrentRoutineDefinition}
          />
          {selectedRoutine.dsl.instructions && (
            <RoutineInstructions instructions={selectedRoutine.dsl.instructions} />
          )}
        </div>

        <div className={cs.contents}>
          <div className={cs.routineParametersColumns}>
            <div className={cs.sectionTitle}>
              Set Routine Parameters
              <div className={cs.fill} />
              <MinimalButton
                className={cs.toggleDisplayMode}
                type='normal'
                onClick={() => resetParams()}
                label='Clear All'
              />
            </div>

            {samplePlateBarcodeParameter && (
              <div className={cs.samplePlateBarcodeMultiSelect}>
                <div className={cs.label}>{samplePlateBarcodeParameter}s</div>
                <SamplePlateBarcodeMultiSelect
                  samplePlateBarcodeParameter={samplePlateBarcodeParameter}
                  setSamplePlateBarcodes={setSamplePlateBarcodes}
                  routine={selectedRoutine}
                  samplePlateBarcodes={samplePlateBarcodes}
                />
              </div>
            )}
            <RoutineParametersForm
              className={cs.routineParametersForm}
              parameters={routineParameters}
              onParametersUpdate={(key: string, newValue: unknown) => {
                setRoutineParameters({ ...routineParameters, [key]: newValue })
              }}
              routineDefinitionWithDSL={selectedRoutine}
              fieldsToIgnore={fieldsToIgnoreInPrimaryForm}
              showNoParametersRequiredMessage={
                !showQcRepeatsInput && !samplePlateBarcodeParameter
              }
            />
            {showQcRepeatsInput && (
              <QcRepeatsInput repeats={qcRepeats} setRepeats={setQcRepeats} />
            )}
            {size(advancedDialogParameters) > 0 && (
              <MinimalButton
                onClick={() => setAdvancedDialogOpen(true)}
                label='Advanced Parameters...'
              />
            )}
          </div>
          <div className={cs.routineScheduleColumn}>
            <div className={cs.sectionTitle}> Set Routine Schedule</div>
            <div className={cs.selectRoutineContainer}>
              <ButtonGroup
                choices={SCHEDULE_TYPE_OPTIONS}
                onChange={value => setScheduleType(value as ScheduleType)}
                activeChoice={scheduleType}
                className={cs.scheduleTypeButtonGroup}
                choiceClassName={cs.choice}
              />
              {scheduleType === 'schedule_future' && (
                <FutureRoutineScheduleForForm
                  className={cs.futureRoutineScheduleForForm}
                  scheduledFor={scheduledFor}
                  setScheduledFor={setScheduledFor}
                  shouldQueueAutomatically={shouldQueueAutomatically}
                  setShouldQueueAutomatically={setShouldQueueAutomatically}
                />
              )}
            </div>
          </div>
          {size(advancedDialogParameters) > 0 && (
            <AdvancedRoutineParametersDialog
              isOpen={advancedDialogOpen}
              onClose={() => setAdvancedDialogOpen(false)}
              routineDefinitionWithDSL={selectedRoutine}
              routineParameters={routineParameters}
              onRoutineParametersUpdate={(key: string, newValue: unknown) => {
                setRoutineParameters({ ...routineParameters, [key]: newValue })
              }}
              fieldsToIgnore={fieldsToIgnoreInAdvancedDialog}
            />
          )}
        </div>
      </div>
    </PresentationalScheduleOperatorAction>
  )
}

interface SamplePlateBarcodeMultiSelectProps {
  setSamplePlateBarcodes: (barcodes: string[]) => void
  routine: RoutineDefinitionWithDSL
  samplePlateBarcodeParameter: string
  samplePlateBarcodes: string[]
}

function SamplePlateBarcodeMultiSelect(
  props: SamplePlateBarcodeMultiSelectProps,
): JSX.Element {
  const dataSchema = getJsonSchemaFromRoutineDefinition(props.routine, [])

  const selectFilters = getProcessItemFiltersFromStructuredDataField(
    props.samplePlateBarcodeParameter,
    'sample_plate',
    dataSchema,
  )
  return (
    <ManagedProcessItemMultiSelect
      renderProcessItemViz={true}
      onSelectedItemUuidsUpdate={props.setSamplePlateBarcodes}
      processItemFilters={selectFilters}
      selectedItemUuids={props.samplePlateBarcodes}
    />
  )
}
