import { useState } from 'react'

import { isNumber } from 'lodash/fp'
import Input from '~/components/Input'
import cs from './qc_repeats_input.scss'

export interface QcRepeatsInputProps {
  repeats: number
  setRepeats: (repeats: number) => void
}

const QcRepeatsInput = ({ repeats, setRepeats }: QcRepeatsInputProps) => {
  const [error, setError] = useState('')

  const handleRepeatsUpdate = (number: string) => {
    let _repeats
    try {
      _repeats = JSON.parse(number)
    } catch (e) {
      setError('repeats must be number')
      return
    }
    if (!isNumber(_repeats)) {
      setError('repeats must be number')
      return
    }
    if (_repeats <= 0) {
      setError('repeats must be greater than 0')
      return
    }
    setError('')
    setRepeats(_repeats)
  }

  return (
    <Input
      label='Repeat QC Multiple Times'
      className={cs.repeatsInput}
      inputClassName={cs.inputs}
      value={repeats.toString()}
      onChange={handleRepeatsUpdate}
      changeOnBlur
      errorMessage={error}
    />
  )
}

export default QcRepeatsInput
