/* eslint-disable max-classes-per-file */
import axios, { AxiosError, AxiosRequestConfig } from 'axios'
import Cookies from 'js-cookie'
import { includes } from 'lodash/fp'

import { getDesktopDomain } from '~/api/desktop/utils'
import Toaster from '~/components/Toaster'

const getErrorResponseForDesktop = (e: AxiosError) => {
  if (e.response?.data) {
    const { data } = e.response

    if (
      data.status &&
      includes(data.status, [400, 401, 403, 404, 500]) &&
      data.message
    ) {
      return data.message
    }
  }

  return 'Could not reach server. Please try again later.'
}

const get = async (url: string, config: AxiosRequestConfig, isDesktop: boolean) => {
  try {
    const headers = {}

    if (!isDesktop) {
      headers['x-monomer-ajax'] = 'True'
    }
    const resp = await axios.get(url, {
      ...config,
      headers,
    })

    return resp.data
  } catch (e) {
    if (axios.isAxiosError(e)) {
      if (isDesktop) {
        return Promise.reject(getErrorResponseForDesktop(e))
      }
      return Promise.reject(e.response?.data)
    } else {
      throw e
    }
  }
}

const post = async (url: string, params: unknown, isDesktop: boolean) => {
  try {
    const headers = {}

    if (!isDesktop) {
      headers['x-monomer-ajax'] = 'True'
      headers['X-CSRFToken'] = Cookies.get('csrftoken')
    }

    const resp = await axios.post(url, params, {
      headers,
    })

    // Just return the data.
    // resp also contains headers, status, etc. that we might use later.
    return resp.data
  } catch (e) {
    if (axios.isAxiosError(e)) {
      if (isDesktop) {
        return Promise.reject(getErrorResponseForDesktop(e))
      }
      return Promise.reject(e.response?.data)
    } else {
      throw e
    }
  }
}

const patch = async (url: string, params: unknown, isDesktop: boolean) => {
  try {
    const headers = {}

    if (!isDesktop) {
      headers['x-monomer-ajax'] = 'True'
      headers['X-CSRFToken'] = Cookies.get('csrftoken')
    }

    const resp = await axios.patch(url, params, {
      headers,
    })

    // Just return the data.
    // resp also contains headers, status, etc. that we might use later.
    return resp.data
  } catch (e) {
    if (axios.isAxiosError(e)) {
      if (isDesktop) {
        return Promise.reject(getErrorResponseForDesktop(e))
      }
      return Promise.reject(e.response?.data)
    } else {
      throw e
    }
  }
}

const del = async (url: string, isDesktop: boolean) => {
  try {
    const headers = {}

    if (!isDesktop) {
      headers['x-monomer-ajax'] = 'True'
      headers['X-CSRFToken'] = Cookies.get('csrftoken')
    }

    const resp = await axios.delete(url, {
      headers,
    })

    // Just return the data.
    // resp also contains headers, status, etc. that we might use later.
    return resp.data
  } catch (e) {
    if (axios.isAxiosError(e)) {
      if (isDesktop) {
        return Promise.reject(getErrorResponseForDesktop(e))
      }
      return Promise.reject(e.response?.data)
    } else {
      throw e
    }
  }
}

class API {
  prefix: string | (() => string)
  isDesktop: boolean

  constructor(prefix: string | (() => string), isDesktop = false) {
    this.prefix = prefix
    this.isDesktop = isDesktop
  }

  getPrefix = () => {
    // Desktop APIs can have their network ip address stored in localStorage.
    // Passing a function allows for querying for the ip address each time.
    if (this.prefix instanceof Function) {
      return this.prefix()
    }
    return this.prefix
  }

  get = (url: string, params?: unknown, config: AxiosRequestConfig = {}) => {
    return get(
      `${this.getPrefix()}/${url}`,
      {
        params,
        ...config,
      },
      this.isDesktop,
    )
  }

  post = (url: string, params?: unknown) => {
    return post(`${this.getPrefix()}/${url}`, params, this.isDesktop)
  }

  patch = (url: string, params?: unknown) => {
    return patch(`${this.getPrefix()}/${url}`, params, this.isDesktop)
  }

  del = url => {
    return del(`${this.getPrefix()}/${url}`, this.isDesktop)
  }
}

/*
  Automatically generate a REST API for resource
*/
class RestAPI<ResourceType> {
  prefix: string | (() => string)
  resourceName: string
  isDesktop: boolean
  showToast: boolean
  _api: API

  constructor(
    prefix: string | (() => string),
    resourceName: string,
    isDesktop = false,
    showToast = true,
  ) {
    this.prefix = prefix
    this.resourceName = resourceName
    this.isDesktop = isDesktop
    this.showToast = showToast
    this._api = new API(prefix, isDesktop)
  }

  GET = (url: string, params?: unknown, config: AxiosRequestConfig = {}) =>
    this._api.get(url, params, config)
  POST = (url: string, params?: unknown) => this._api.post(url, params)
  PATCH = (url: string, params: unknown) => this._api.patch(url, params)
  DEL = url => this._api.del(url)

  getPrefix = () => {
    // Desktop APIs can have their network ip address stored in localStorage.
    // Passing a function allows for querying for the ip address each time.
    if (this.prefix instanceof Function) {
      return this.prefix()
    }
    return this.prefix
  }

  get = (params = {}) =>
    get(
      `${this.getPrefix()}/`,
      {
        params,
      },
      this.isDesktop,
    )

  retrieve = id => get(`${this.getPrefix()}/${id}/`, {}, this.isDesktop)

  create = async (values = {}): Promise<ResourceType> => {
    try {
      const response = await post(`${this.getPrefix()}/`, values, this.isDesktop)
      if (this.showToast) {
        Toaster.show({
          message: `${this.resourceName} successfully created.`,
          intent: 'success',
        })
      }
      return response
    } catch (e) {
      if (this.showToast) {
        Toaster.show({
          message: `${this.resourceName} could not be created.`,
          intent: 'danger',
        })
      }
      throw e
    }
  }

  partialUpdate = async (id, updates) => {
    try {
      const response = await patch(
        `${this.getPrefix()}/${id}/`,
        updates,
        this.isDesktop,
      )
      if (this.showToast) {
        Toaster.show({
          message: `${this.resourceName} successfully updated.`,
          intent: 'success',
        })
      }
      return response
    } catch (e) {
      if (this.showToast) {
        Toaster.show({
          message: `${this.resourceName} could not be updated.`,
          intent: 'danger',
        })
      }
      throw e
    }
  }

  del = async (id: number) => {
    try {
      await del(`${this.getPrefix()}/${id}/`, this.isDesktop)
      if (this.showToast) {
        Toaster.show({
          message: `${this.resourceName} successfully deleted.`,
          intent: 'success',
        })
      }
    } catch (e) {
      if (this.showToast) {
        Toaster.show({
          message: `${this.resourceName} could not be deleted.`,
          intent: 'danger',
        })
      }
      throw e
    }
  }
}

class DesktopAPI extends API {
  constructor(prefix) {
    super(() => {
      return `${getDesktopDomain()}${prefix}`
    }, true)
  }
}

class DesktopRestAPI<ResourceType> extends RestAPI<ResourceType> {
  constructor(prefix, resourceName: string, showToast = true) {
    super(
      () => {
        return `${getDesktopDomain()}${prefix}`
      },
      resourceName,
      true,
      showToast,
    )
  }
}

export { get, post, del, patch, RestAPI, API, DesktopAPI, DesktopRestAPI }
