import cx from 'classnames'
import { CultureViewModel } from '../../events/ViewModels/CultureViewModel'
import cs from './culture_status.scss'

export function CultureStatus({ culture }: { culture: CultureViewModel }) {
  return (
    <div>
      <span className={cs.statusLabel}>Status: </span>
      <span
        className={cx(
          cs.status,
          culture.status === 'ACTIVE' && cs.status__active,
          culture.status === 'CONSUMED' && cs.status__consumed,
          culture.status === 'TERMINATED' && cs.status__terminated,
        )}
      >
        {culture.status}
      </span>
    </div>
  )
}
