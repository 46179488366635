import cx from 'classnames'

import ProcessItemList from '~/pages/Workcell/components/processItem/ProcessItemList'
import cs from './archived_items.scss'

interface ArchivedItemsProps {
  className?: string
}

const ArchivedItems = ({ className }: ArchivedItemsProps) => {
  return (
    <div className={cx(className, cs.archivedItems)}>
      <ProcessItemList
        className={cs.table}
        showItemsWithoutLocation={true}
        consumablesOrSamples='both'
        isCheckedIn={false}
      />
    </div>
  )
}

export default ArchivedItems
