import cx from 'classnames'
import { useEffect, useState } from 'react'
import roboticArmAPI, {
  SequenceValidationRunParams,
  TeachToolSeqFile,
} from '~/api/desktop/drivers/roboticArm'

import InputNumber from '~/components/InputNumber'
import InputStorageLocationParam from './InputStorageLocationParam'
import RoboticArmSequenceSelect from './RoboticArmSequenceSelect'
import cs from './sequence_validation_form.scss'
import { sequenceRequiresStorageLocationParam } from './storageLocationParam'

export interface SequenceValidationFormValues {
  run_params: Partial<SequenceValidationRunParams>
  forward_seq_file?: TeachToolSeqFile
  reverse_seq_file?: TeachToolSeqFile
}

export interface SequenceValidationFormProps {
  className?: string
  instrumentName: string
  formParams: SequenceValidationFormValues
  setFormParams: (
    setParams: (params: SequenceValidationFormValues) => SequenceValidationFormValues,
  ) => void
  onViewSeqFile: (seqFile: TeachToolSeqFile) => void
  isTeachToolBusy: boolean
}

export const shouldAcceptRepeatParam = (formParams: SequenceValidationFormValues) => {
  return formParams.reverse_seq_file
}

const SequenceValidationForm = ({
  className,
  instrumentName,
  formParams,
  setFormParams,
  onViewSeqFile,
  isTeachToolBusy,
}: SequenceValidationFormProps) => {
  const [seqFileNames, setSeqFileNames] = useState<string[]>([])
  const fetchSeqFileNames = async () => {
    const seqFileNames = await roboticArmAPI.caseqnames(instrumentName)
    setSeqFileNames(seqFileNames.seq_file_names)
  }
  useEffect(() => {
    fetchSeqFileNames()
  }, [])
  const updateRunParams = (newParams: Partial<SequenceValidationRunParams>) => {
    setFormParams((_latestParams: SequenceValidationFormValues) => ({
      ..._latestParams,
      run_params: {
        ..._latestParams.run_params,
        ...newParams,
      },
    }))
  }

  return (
    <div className={cx(className, cs.sequenceValidationForm)}>
      <div className={cs.sectionTitle}>Set Sequences</div>

      <RoboticArmSequenceSelect
        triggerClassName={cs.trigger}
        popoverClassName={cs.popover}
        seqFileNames={seqFileNames}
        label='Forward Sequence'
        selectedSequence={formParams.run_params.forward_sequence_file_name || null}
        seqFile={formParams.forward_seq_file || null}
        onSequenceSelect={(sequence, seqFile) =>
          setFormParams((_latestParams: SequenceValidationFormValues) => ({
            ..._latestParams,
            run_params: {
              ..._latestParams.run_params,
              forward_sequence_file_name: sequence || undefined,
            },
            forward_seq_file: seqFile || undefined,
          }))
        }
        instrumentName={instrumentName}
        onViewSeqFile={onViewSeqFile}
        disabled={isTeachToolBusy}
      />

      <div className={cs.sequenceFields}>
        <InputNumber
          className={cs.startOnStepInput}
          value={formParams.run_params.start_on_step}
          onChange={start_on_step =>
            updateRunParams({ start_on_step: start_on_step || undefined })
          }
          label='Start on Step'
          inputClassName={cs.inner}
          disabled={isTeachToolBusy}
        />
        {formParams.forward_seq_file &&
          sequenceRequiresStorageLocationParam(formParams.forward_seq_file) && (
            <InputStorageLocationParam
              className={cs.storageLocationInput}
              value={formParams.run_params.storage_location || undefined}
              onChange={storage_location =>
                updateRunParams({ storage_location: storage_location || undefined })
              }
              label='Storage Location'
              placeholder='s1:l1'
              inputClassName={cs.inner}
              disabled={isTeachToolBusy}
            />
          )}
      </div>

      <RoboticArmSequenceSelect
        className={cs.reverseSelect}
        triggerClassName={cs.trigger}
        popoverClassName={cs.popover}
        seqFileNames={seqFileNames}
        label='Reverse Sequence (Optional)'
        selectedSequence={formParams.run_params.reverse_sequence_file_name || null}
        seqFile={formParams.reverse_seq_file || null}
        onSequenceSelect={(sequence, seqFile) =>
          setFormParams((_latestParams: SequenceValidationFormValues) => ({
            ..._latestParams,
            run_params: {
              ..._latestParams.run_params,
              reverse_sequence_file_name: sequence || undefined,
              // If reverse seq is set to null, set repeat back to 1.
              repeat: sequence ? _latestParams.run_params.repeat : 1,
            },
            reverse_seq_file: seqFile || undefined,
          }))
        }
        instrumentName={instrumentName}
        onViewSeqFile={onViewSeqFile}
        disabled={isTeachToolBusy}
      />
      {shouldAcceptRepeatParam(formParams) && (
        <div className={cs.validationParameters}>
          <div className={cs.sectionTitle}>Set Validation Parameters</div>
          <InputNumber
            className={cs.startOnStepInput}
            value={formParams.run_params.repeat || undefined}
            onChange={repeat => updateRunParams({ repeat: repeat || undefined })}
            label='Repeat'
            inputClassName={cs.inner}
            disabled={isTeachToolBusy}
          />
        </div>
      )}
    </div>
  )
}

export default SequenceValidationForm
