import PropTypes from 'prop-types'

const ProcessStep = PropTypes.shape({
  instrumentName: PropTypes.string,
  commandName: PropTypes.string,
  commandParams: PropTypes.arrayOf(PropTypes.any),
})

const ProcessItemLocation = PropTypes.shape({
  instrumentName: PropTypes.string,
  locationType: PropTypes.string,
  locationParams: PropTypes.shape({
    locationString: PropTypes.string,
    transferStationId: PropTypes.string,
  }),
})

const ProcessItem = PropTypes.shape({
  uuid: PropTypes.string,
  state: PropTypes.shape({
    location: ProcessItemLocation,
    contents: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.any)),
    currentIndex: PropTypes.number,
  }),
  type: PropTypes.string,
})

const Instrument = PropTypes.shape({
  instrumentName: PropTypes.string,
  instrumentType: PropTypes.string,
  isSimulated: PropTypes.bool,
  shouldAutostart: PropTypes.bool,
  id: PropTypes.number,
})

// We are in the middle of refactoring our api endpoints.
// If instrument status is fetched as part of WorkcellStatus, it will be snake_case.
// If instrument status is fetched directly from the instrument, it will still be camel case.
const InstrumentStatus = PropTypes.shape({
  driver_state: PropTypes.string,
  execution_state: PropTypes.string,
  instrument_messages: PropTypes.arrayOf(PropTypes.string),
  is_simulated: PropTypes.bool,
  transfer_stations: PropTypes.objectOf(
    PropTypes.shape({
      uuid: PropTypes.string,
    }),
  ),
})

const JsonSchema = PropTypes.shape({
  definitions: PropTypes.objectOf(
    PropTypes.shape({
      title: PropTypes.string,
      type: PropTypes.string,
      description: PropTypes.string,
      enum: PropTypes.arrayOf(PropTypes.string),
    }),
  ),
  properties: PropTypes.objectOf(
    PropTypes.shape({
      allOf: PropTypes.arrayOf(
        PropTypes.shape({
          $ref: PropTypes.string,
        }),
      ),
      title: PropTypes.string,
      type: PropTypes.string,
      description: PropTypes.string,
      default: PropTypes.any,
    }),
  ),
})

export default {
  ...PropTypes,
  ProcessStep,
  ProcessItem,
  ProcessItemLocation,
  Instrument,
  InstrumentStatus,
  JsonSchema,
}
