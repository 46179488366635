import cx from 'classnames'
import { ReactNode } from 'react'

import { getDisplayStringForField } from '~/utils/types'

import PropTypes from '~/propTypes'
import { JsonSchema } from '~/types/JsonSchema.interface'
import { getFieldDefault, getFieldDescription, getFieldType } from '~/utils/jsonSchema'
import HelpPopover from './HelpPopover'
import Pill from './Pill'
import HelpIcon from './icons/HelpIcon'
import cs from './read_only_field.scss'

export interface ReadOnlyFieldProps {
  className?: string
  label?: string
  value?: ReactNode
  schema?: JsonSchema | null
  schemaKey?: string
  condensed?: boolean
}

const ReadOnlyField = ({
  className,
  label,
  value,
  schema,
  schemaKey,
  condensed,
}: ReadOnlyFieldProps) => {
  const renderPopover = () => {
    if (!schema || !schemaKey) return null
    const content = (
      <div className={cs.helpContent}>
        <div className={cs.fieldName}>{label}</div>
        <Pill
          small
          type='accent'
          label={getFieldType(schemaKey, schema)}
          className={cs.type}
        />
        <div className={cs.description}>{getFieldDescription(schemaKey, schema)}</div>
        <div className={cs.default}>
          Default:{' '}
          {getDisplayStringForField(
            getFieldDefault(schemaKey, schema, true),
            getFieldType(schemaKey, schema),
          )}
        </div>
      </div>
    )

    return (
      <HelpPopover
        text={<HelpIcon className={cs.icon} />}
        helpContent={content}
        interactionKind='hover'
        smallText={false}
        className={cs.iconContainer}
      />
    )
  }

  return (
    <div className={cx(className, cs.readOnlyField, condensed && cs.condensed)}>
      <div className={cs.label}>
        {label}
        {renderPopover()}
      </div>
      <div className={cs.display}>{value}</div>
    </div>
  )
}

ReadOnlyField.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  schemaKey: PropTypes.string,
  value: PropTypes.node,
  schema: PropTypes.JsonSchema,
  condensed: PropTypes.bool,
}

export default ReadOnlyField
