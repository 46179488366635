import { flatten, flow, get, map, size, uniq, values } from 'lodash/fp'
import { ProcessItem } from '~/common.interface'
import { getExperimentPlateMapNameForExperiments } from '~/pages/Workcell/ProcessItems/PlateMap'

export const getExperimentPlateExperiments = (processItem: ProcessItem) =>
  get(['metadata', 'experiments'], processItem)

export const getExperimentPlateExperimentTypes = (processItem: ProcessItem) =>
  map('experiment_type', getExperimentPlateExperiments(processItem))

export const getRemainingExperimentPlateExperiments = (processItem: ProcessItem) => {
  const experimentTypes = getExperimentPlateExperimentTypes(processItem)
  const consumedExperimentIndices =
    getExperimentPlateConsumedExperimentIndices(processItem)

  return experimentTypes.filter(
    (_, index) => !consumedExperimentIndices.includes(index),
  )
}

export const getExperimentPlateMetadataDisplay = (processItem: ProcessItem) => {
  const experiments = getExperimentPlateExperiments(processItem)
  const linkedSamplePlates = uniq(map('linked_sample_plate', experiments))
  const experimentNames = map('experiment_type', experiments)
  const plateMapName = getExperimentPlateMapNameForExperiments(experimentNames)

  return `${plateMapName} for ${linkedSamplePlates.join(', ')}`
}

export const getExperimentPlateNameDisplay = (processItem: ProcessItem) => {
  const experiments = getExperimentPlateExperiments(processItem)
  const linkedSamplePlates = uniq(map('linked_sample_plate', experiments))
  const experimentNames = map('experiment_type', experiments)
  const plateMapName = getExperimentPlateMapNameForExperiments(experimentNames)

  return `${plateMapName} (${linkedSamplePlates.join(', ')})`
}

export const getExperimentPlateConsumedExperimentIndices = (processItem: ProcessItem) =>
  get(['state', 'consumedExperimentIndices'], processItem)

export const getExperimentPlatePercentFilled = (processItem: ProcessItem) => {
  const consumedExperimentIndices = size(
    uniq(getExperimentPlateConsumedExperimentIndices(processItem)),
  )
  const numExperiments = size(getExperimentPlateExperiments(processItem))

  return ((numExperiments - consumedExperimentIndices) / numExperiments) * 100
}

export const getWellsForExperiment = experiment =>
  flow(get('well_map'), values, flatten)(experiment)
