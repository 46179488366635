import cx from 'classnames'
import { Link } from 'react-router-dom'

import PropTypes from '~/propTypes'
import {
  getLocation,
  getProcessItemLocationDisplayString,
} from '~/utils/processItems/common'

import cs from './process_item_location.scss'

const ProcessItemLocation = ({ className, processItem }) => {
  if (!processItem) return null

  const location = getLocation(processItem)
  if (!location) return null

  const renderLocation = () => {
    const locationDisplayString = getProcessItemLocationDisplayString(location)

    if (locationDisplayString) {
      return <div>{locationDisplayString}</div>
    }
    return null
  }

  return (
    <div className={cx(cs.processItemLocation, className)}>
      <Link
        to={`/workcell/instruments/${location.instrumentName}`}
        className={cs.instrumentName}
      >
        {location.instrumentName}
      </Link>
      <div className={cs.location}>{renderLocation()}</div>
    </div>
  )
}

ProcessItemLocation.propTypes = {
  className: PropTypes.string,
  processItem: PropTypes.ProcessItem,
}

export default ProcessItemLocation
