import { useState } from 'react'
import integrationsAPIv2, { SlackIntegration } from '~/api/desktop/integrations'
import Dialog from '~/components/Dialog'
import Input from '~/components/Input'
import Toaster from '~/components/Toaster'
import Button from '~/components/buttons/Button'
import cs from './connect_slack_dialog.scss'

interface ConnectSlackDialogProps {
  open: boolean
  onClose: () => void
  onIntegrationUpdate: (integration: SlackIntegration) => void
}

const ConnectSlackDialog = ({
  open,
  onClose,
  onIntegrationUpdate,
}: ConnectSlackDialogProps) => {
  const [slackSettings, setSlackSettings] = useState<string>('')

  const installSlackHandler = async () => {
    try {
      const slackSettingsObj = JSON.parse(slackSettings)
      const slackIntegration = await integrationsAPIv2.installSlack(slackSettingsObj)
      Toaster.show({
        message: 'Successfully installed Slack integration.',
        intent: 'success',
      })
      onClose()
      onIntegrationUpdate(slackIntegration)
    } catch (error) {
      console.log(error)
      Toaster.show({
        message: `Slack could not be installed. Please ensure you've provided valid JSON matching
        the expected format.`,
        intent: 'danger',
      })
    }
  }

  return (
    <Dialog isOpen={open} onClose={onClose} className={cs.connectSlackDialog}>
      <div className={cs.title}>Install Slack Integration</div>
      <Input
        label='Slack Settings'
        placeholder='Paste the slack JSON config obtained from Monomer'
        value={slackSettings}
        onChange={setSlackSettings}
        className={cs.inputContainer}
        inputClassName={cs.input}
      />
      <div className={cs.controls}>
        <Button
          disabled={!slackSettings}
          type='primary'
          label='Install Slack'
          className={cs.button}
          onClick={installSlackHandler}
        />
      </div>
    </Dialog>
  )
}

export default ConnectSlackDialog
