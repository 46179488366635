import { fromPairs, max, memoize, min, range, times } from 'lodash/fp'
import {
  gaussianRandom,
  pseudoRandom,
  pseudoShuffle,
} from '~/pages/slasDemo/events/data/generate/generateDataset'
import { convertWellCoordsToWellName, getPlateDims } from '~/utils/microplate'
import { generateConfluencyValues } from './generateClePipelinePlates'

// CURRENT TIME STATE

export const getDayToSimulateForPlate = (
  phase: string,
  cellLineIndex: number,
): number => {
  return 3
}

// KO SCORE GENERATION

export const KO_SCORE_MIN = 0
export const KO_SCORE_MAX = 100

export const KO_SCORE_HETEROZYGOUS_BOUNDS = [46, 54]
export const KO_SCORE_HOMOZYGOUS_BOUNDS = [96, 100]

const koScoreHeterozygousProp = 0.3
const koScoreHomozygousProp = 0.3
const koScoreNoEditProp = 0.3
const koScoreRandomProp = 0.1

export const getKoScoreValues = (numSamples: number): number[] => {
  return [
    ...times(
      () => 50 + gaussianRandom(0, 4),
      Math.ceil(koScoreHeterozygousProp * numSamples),
    ),
    ...times(
      () => max([3 + gaussianRandom(0, 5), 0]) as number,
      Math.ceil(koScoreNoEditProp * numSamples),
    ),
    ...times(
      () => min([98 + gaussianRandom(0, 4), 100]) as number,
      Math.ceil(koScoreHomozygousProp * numSamples),
    ),
    ...times(() => pseudoRandom() * 100, Math.ceil(koScoreRandomProp * numSamples)),
  ]
}

export type KnockoutType = 'Heterozygous' | 'Homozygous'

export const getKnockoutType = (koScore: number): KnockoutType | null => {
  if (
    koScore >= KO_SCORE_HETEROZYGOUS_BOUNDS[0] &&
    koScore <= KO_SCORE_HETEROZYGOUS_BOUNDS[1]
  ) {
    return 'Heterozygous'
  } else if (
    koScore >= KO_SCORE_HOMOZYGOUS_BOUNDS[0] &&
    koScore <= KO_SCORE_HOMOZYGOUS_BOUNDS[1]
  ) {
    return 'Homozygous'
  }
  return null
}

// DOUBLING RATE GENERATION

export const DOUBLING_RATE_MIN = 14
export const DOUBLING_RATE_MAX = 24

export const DOUBLING_RATE_BEST_BOUNDS = [18.6, 19.4]
export const DOUBLING_RATE_GOOD_BOUNDS = [17.4, 20.6]

export const getDoublingRateValues = (numSamples: number): number[] => {
  return [...times(() => 20 + gaussianRandom(0, 1.5), numSamples)]
}

// PLATE AND WELL ID GENERATION

export const getPlateAndWellId = (plateNumber, row, col): string => {
  return `${plateNumber}-${convertWellCoordsToWellName(row, col)}`
}

export interface HitpickingValues {
  koScore: number
  doublingRate: number
  confluency: number
}

export const getPlateAndWellToValues = memoize(() => {
  const numPlates = 3
  const koScoreValues = pseudoShuffle(getKoScoreValues(numPlates * 24))
  const doublingRateValues = pseudoShuffle(getDoublingRateValues(numPlates * 24))
  const confluencyValues = pseudoShuffle(generateConfluencyValues(numPlates * 24))

  const { numRows, numCols } = getPlateDims('wells_24')

  const pairs: [string, HitpickingValues][] = range(1, numPlates + 1)
    .map(plate =>
      range(0, numRows).map(row =>
        range(0, numCols).map(
          col =>
            <[string, HitpickingValues]>[
              getPlateAndWellId(plate, row, col),
              {
                koScore: koScoreValues.pop(),
                doublingRate: doublingRateValues.pop(),
                confluency: confluencyValues.pop(),
              },
            ],
        ),
      ),
    )
    .flat()
    .flat()

  return fromPairs(pairs)
})
