import { size } from 'lodash/fp'
import StructuredDataForm from '~/components/StructuredDataForm'
import { RoutineParameters } from '../types/RoutineDefinition.interface'
import { RoutineDefinitionWithDSL } from './useRoutineDefinitionsWithDSLs'

import { getJsonSchemaFromRoutineDefinition } from './getJsonSchemaFromRoutineDefinition'
import cs from './routine_parameters_form.scss'

export interface RoutineFormProps {
  routineDefinitionWithDSL: RoutineDefinitionWithDSL
  fieldsToIgnore: string[]
  parameters: RoutineParameters
  onParametersUpdate: (key: string, newValue: unknown) => void
  showNoParametersRequiredMessage?: boolean
  className?: string
}

export function RoutineParametersForm({
  routineDefinitionWithDSL,
  fieldsToIgnore,
  parameters,
  onParametersUpdate,
  showNoParametersRequiredMessage = true,
  className,
}: RoutineFormProps): JSX.Element | null {
  const schema = getJsonSchemaFromRoutineDefinition(
    routineDefinitionWithDSL,
    fieldsToIgnore,
  )

  if (size(schema.properties) === 0) {
    if (showNoParametersRequiredMessage) {
      return <div className={cs.noParametersMessage}>No parameters required.</div>
    }
    return null
  }

  return (
    <StructuredDataForm
      className={className}
      dataSchema={schema}
      data={parameters}
      onEdit={onParametersUpdate}
      variant='vertical'
      inputsClassName={cs.inputs}
    />
  )
}
