import cx from 'classnames'
import { sortBy } from 'lodash/fp'
import { useEffect, useState } from 'react'

import workflowDefinitionsAPI, {
  WorkflowDefinition,
} from '~/api/desktop/workflowDefinitions'
import Table from '~/components/Table'
import Toaster from '~/components/Toaster'
import DeleteIcon from '~/components/icons/DeleteIcon'
import EditIcon from '~/components/icons/EditIcon'

import Button from '~/components/buttons/Button'
import AddIcon from '~/components/icons/AddIcon'
import RefreshIcon from '~/components/icons/RefreshIcon'
import ViewIcon from '~/components/icons/ViewIcon'
import WorkflowDefinitionVizDialog from '~/pages/Workcell/Protocols/WorkflowDefinitions/WorkflowDefinitionVizDialog'
import WorkflowDefinitionCreateOrEditDialog from './WorkflowDefinitionCreateOrEditDialog'
import cs from './workflow_definitions.scss'

const WorkflowDefinitions = ({ className }: { className?: string }) => {
  const [selectedWorkflowDefinition, setSelectedWorkflowDefinition] = useState<
    WorkflowDefinition | undefined
  >(undefined)
  const [showCreateDialog, setShowCreateDialog] = useState(false)
  const [showVizDialog, setShowVizDialog] = useState(false)
  const [loading, setLoading] = useState(false)
  const [workflowDefinitions, setWorkflowDefinitions] = useState<WorkflowDefinition[]>(
    [],
  )
  const fetchWorkflowDefinitions = async () => {
    const definitions = await workflowDefinitionsAPI.list()
    setWorkflowDefinitions(definitions.workflow_definitions)
    setLoading(false)
  }

  const refreshWorkflowDefinitions = () => {
    setWorkflowDefinitions([])
    setLoading(true)
    fetchWorkflowDefinitions()
  }

  const deleteWorkflowDefinition = async (id: number) => {
    try {
      await workflowDefinitionsAPI.delete(id)
      Toaster.show({
        message: 'Successfully deleted workflow definition',
        intent: 'success',
      })
    } catch (e) {
      Toaster.show({
        message: 'Failed to delete workflow definition',
        intent: 'danger',
      })
      return
    }
    refreshWorkflowDefinitions()
  }

  const handleEditIconClicked = (workflowDefinition: WorkflowDefinition) => {
    setSelectedWorkflowDefinition(workflowDefinition)
    setShowCreateDialog(true)
  }

  const handleViewIconClicked = (workflowDefinition: WorkflowDefinition) => {
    setSelectedWorkflowDefinition(workflowDefinition)
    setShowVizDialog(true)
  }

  const handleCreateDialogClosed = () => {
    setShowCreateDialog(false)
    setSelectedWorkflowDefinition(undefined)
  }

  const handleVizDialogClosed = () => {
    setShowVizDialog(false)
    setSelectedWorkflowDefinition(undefined)
  }

  const handleRowClicked = (workflowDefinition: WorkflowDefinition) => {
    setSelectedWorkflowDefinition(workflowDefinition)
    setShowVizDialog(true)
  }

  useEffect(() => {
    refreshWorkflowDefinitions()
  }, [])

  const tableColumns = [
    {
      name: 'Name',
      width: 300,
      render: (row: WorkflowDefinition) => <div className={cs.name}>{row.name}</div>,
    },
    {
      name: 'Definition File',
      width: 'flex',
      render: (row: WorkflowDefinition) => (
        <div className={cs.definitionFile}>{row.definition_file}</div>
      ),
    },
    {
      name: '',
      width: 30,
      render: (row: WorkflowDefinition) => (
        <ViewIcon className={cs.icon} onClick={() => handleViewIconClicked(row)} />
      ),
      showOnHover: true,
      omitCellPadding: true,
      stopPropagation: true,
    },
    {
      name: '',
      width: 30,
      render: (row: WorkflowDefinition) => (
        <EditIcon className={cs.icon} onClick={() => handleEditIconClicked(row)} />
      ),
      showOnHover: true,
      omitCellPadding: true,
      stopPropagation: true,
    },
    {
      name: '',
      width: 30,
      render: (row: WorkflowDefinition) => (
        <DeleteIcon
          className={cs.icon}
          onClick={() => deleteWorkflowDefinition(row.db_id)}
        />
      ),
      showOnHover: true,
      omitCellPadding: true,
      stopPropagation: true,
    },
  ]

  const renderTable = () => {
    if (loading) {
      return <div className={cs.bigMessage}>Loading workflow definitions...</div>
    }
    if (!loading && (!workflowDefinitions || workflowDefinitions.length === 0)) {
      return <div className={cs.bigMessage}>No workflow definitions found.</div>
    }

    const sortedWorkflowDefinitions = sortBy('name', workflowDefinitions)

    return (
      <Table
        columns={tableColumns}
        data={sortedWorkflowDefinitions}
        className={cs.table}
        rowPaddingVariant='rowPaddingLow'
        rowKey='db_id'
        onRowClick={handleRowClicked}
      />
    )
  }

  return (
    <div className={cx(className, cs.workflowDefinitions)}>
      <div className={cs.controls}>
        <div className={cs.fill} />
        <Button
          className={cs.button}
          label='Refresh Table'
          IconComponent={RefreshIcon}
          onClick={refreshWorkflowDefinitions}
        />
        <Button
          type='primary'
          label='Add Workflow Definition'
          IconComponent={AddIcon}
          onClick={() => setShowCreateDialog(true)}
        />
      </div>
      {renderTable()}
      <WorkflowDefinitionCreateOrEditDialog
        isOpen={showCreateDialog}
        onCreateSuccess={refreshWorkflowDefinitions}
        onClose={handleCreateDialogClosed}
        selectedWorkflowDefinition={selectedWorkflowDefinition}
      />
      <WorkflowDefinitionVizDialog
        isOpen={showVizDialog}
        onClose={handleVizDialogClosed}
        selectedWorkflowDefinition={selectedWorkflowDefinition}
      />
    </div>
  )
}

export default WorkflowDefinitions
