import PropTypes from 'prop-types'
import { IconProps } from './types/IconProps'
const EditIcon = ({ className, onClick }: IconProps) => (
  <svg
    width='24'
    height='24'
    viewBox='0 0 24 24'
    xmlns='http://www.w3.org/2000/svg'
    className={className}
    onClick={onClick}
  >
    <path
      id='icon/editor/mode_24px'
      fillRule='evenodd'
      clipRule='evenodd'
      d='M17.6588 3C17.4088 3 17.1487 3.10001 16.9587 3.28999L15.1288 5.12L18.8788 8.87L20.7087 7.03999C21.0988 6.64999 21.0988 6.02 20.7087 5.63L18.3687 3.28999C18.1688 3.09 17.9188 3 17.6588 3ZM14.0587 9.02L14.9788 9.94L5.91876 19H4.99875V18.08L14.0587 9.02ZM2.99875 17.25L14.0587 6.19L17.8087 9.94L6.74875 21H2.99875V17.25Z'
    />
  </svg>
)

EditIcon.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
}

export default EditIcon
