import { find, flow, map, sortBy } from 'lodash/fp'
import { useEffect, useState } from 'react'

import Select, { SelectOption } from '~/components/Select'

import workflowDefinitionsAPI, {
  WorkflowDefinition,
} from '~/api/desktop/workflowDefinitions'

const getWorkflowDefinitionOptions = (workflow_definition: WorkflowDefinition[]) => {
  return flow(
    sortBy('name'),
    map((workflow_definition: WorkflowDefinition) => ({
      key: workflow_definition.name,
      label: workflow_definition.name,
    })),
  )(workflow_definition)
}

export function WorkflowDefinitionSelect({
  selectedWorkflowDefinition: selectedWorkflowDefinition,
  onWorkflowDefinitionSelect: onWorkflowDefinitionSelect,
  defaultWorkflowDefinitionName: defaultWorkflowDefinitionName,
  overrideWorkflowDefinitionName: overrideWorkflowDefinitionName,
  className,
  triggerClassName,
  popoverClassName,
  disabled,
}: {
  selectedWorkflowDefinition?: WorkflowDefinition
  onWorkflowDefinitionSelect: (
    workflowDefinittion: WorkflowDefinition | undefined,
  ) => void
  // When selectedWorkflowDefinition is set to undefined, this workflow definition will be automatically selected.
  defaultWorkflowDefinitionName?: string
  // When this is changed, if a workflow definition matching this name is found, it will be selected.
  // NOTE: This interface could be cleaned up. It currently supports
  // when an existing process item is being reloaded and we need to force a particular workflow definition.
  overrideWorkflowDefinitionName?: string
  triggerClassName?: string
  popoverClassName?: string
  className?: string
  disabled?: boolean
}) {
  const [workflowDefinitions, setWorkflowDefinitions] = useState<WorkflowDefinition[]>(
    [],
  )

  const fetchWorkflowDefinitions = async () => {
    const _response = await workflowDefinitionsAPI.list()
    if (_response.workflow_definitions) {
      setWorkflowDefinitions(_response.workflow_definitions)
    }
  }

  // Do this once.
  useEffect(() => {
    fetchWorkflowDefinitions()
  }, [])

  useEffect(() => {
    if (!selectedWorkflowDefinition && workflowDefinitions) {
      if (workflowDefinitions && defaultWorkflowDefinitionName) {
        const defaultWorkflowDefinition = find(
          ['name', defaultWorkflowDefinitionName],
          workflowDefinitions,
        )
        if (defaultWorkflowDefinition) {
          onWorkflowDefinitionSelect(defaultWorkflowDefinition)
          return
        }
      }
    }
  }, [workflowDefinitions, selectedWorkflowDefinition])

  // TODO: If override workflow definition cannot be found, we should probably error.
  // This would indicate that we are trying to check in a process item
  // which used a workflow definition that is no longer present.
  // This currently errors on the back-end, because we will automatically attempt to change the workflow definition
  // to something else (due to it still being selected on the front-end).
  useEffect(() => {
    if (workflowDefinitions && overrideWorkflowDefinitionName) {
      const overrideWorkflowDefinition = find(
        ['name', overrideWorkflowDefinitionName],
        workflowDefinitions,
      )
      if (overrideWorkflowDefinition) {
        onWorkflowDefinitionSelect(overrideWorkflowDefinition)
      }
    }
  }, [overrideWorkflowDefinitionName])

  const handleChange = (value: SelectOption) => {
    if (value.key === null) {
      onWorkflowDefinitionSelect(undefined)
    } else {
      onWorkflowDefinitionSelect(find(['name', value.label], workflowDefinitions))
    }
  }

  const itemMatchesQuery = (workflowDefinition: SelectOption, queryLowerCase: string) =>
    workflowDefinition.key.toLowerCase().includes(queryLowerCase)

  const workflowDefinitionOptions = getWorkflowDefinitionOptions(workflowDefinitions)
  return (
    <div>
      <Select<SelectOption>
        className={className}
        items={workflowDefinitionOptions}
        itemKey='key'
        itemLabelKey='label'
        filterable
        itemMatchesQuery={itemMatchesQuery}
        activeItem={
          find(
            ['label', selectedWorkflowDefinition?.name],
            workflowDefinitionOptions,
          ) || null
        }
        onChange={handleChange}
        triggerClassName={triggerClassName}
        popoverClassName={popoverClassName}
        disabled={disabled}
        allowNoneOption
      />
    </div>
  )
}
