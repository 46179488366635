import {
  LiveCulturesQuery,
  LiveCulturesQueryVariables,
  PlatePageQuery,
  PlatePageQueryVariables,
  WellPageQuery,
  WellPageQueryVariables,
} from '~/__generated__/graphql'

import { DemoDerivedRecordsByKind } from './demoData'

/**
 * Prepares query functions for all demoable pages.
 */
export function getDemoQueries(derived: DemoDerivedRecordsByKind) {
  return {
    LiveCultures(_: LiveCulturesQueryVariables): LiveCulturesQuery {
      return { filteredCulturePlates: derived.plates }
    },

    PlatePage({ id }: PlatePageQueryVariables): PlatePageQuery {
      return { culturePlate: derived.plates.find(p => p.id === id) }
    },

    WellPage({ plateId, position }: WellPageQueryVariables): WellPageQuery {
      return {
        culture: derived.wells.find(
          w => w.culturePlate.id === plateId && w.well === position,
        ),
      }
    },
  }
}

export type DemoQueries = ReturnType<typeof getDemoQueries>
