import PropTypes from 'prop-types'
import { IconProps } from './types/IconProps'
const UnarchiveIcon = ({ className, onClick }: IconProps) => (
  <svg
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    className={className}
    onClick={onClick}
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M19.15 3.55L20.54 5.23C20.83 5.57 21 6.02 21 6.5V19C21 20.1 20.1 21 19 21H5C3.9 21 3 20.1 3 19V6.5C3 6.02 3.17 5.57 3.46 5.23L4.84 3.55C5.12 3.21 5.53 3 6 3H18C18.47 3 18.88 3.21 19.15 3.55ZM17.76 5H6.24L5.42 6H18.59L17.76 5ZM5 19V8H19V19H5ZM10.55 14H8L12 10L16 14H13.45V17H10.55V14Z'
    />
  </svg>
)

UnarchiveIcon.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
}

export default UnarchiveIcon
