// TODO: Many components still directly call executeTaskEndpoint, and manage the state themselves.
// Refactor them to use this hook instead.

import { useState } from 'react'
import executeTaskEndpoint, {
  TaskExecutionResult,
  TaskExecutionStartResponse,
} from '~/api/executeTaskEndpoint'

export type TaskEndpoint = () => Promise<TaskExecutionStartResponse>

export type UseTaskExecutorResult<TResponse> =
  | {
      // TODO: We may want to support re-executing while still rendering the most recent response,
      // i.e. isExecuting: true, response: TData | null
      isExecuting: true
      response: null
      submitTask: null
    }
  | {
      isExecuting: false
      response: TaskExecutionResult<TResponse> | null
      submitTask: (
        taskEndpoint: TaskEndpoint,
        options?: {
          onSuccess: (result: TResponse) => void
          onError?: (error?: string) => void
        },
      ) => Promise<void>
    }

export function useTaskExecutor<TResponse>(): UseTaskExecutorResult<TResponse> {
  const [response, setResponse] = useState<TaskExecutionResult<TResponse> | null>(null)
  const [isExecuting, setIsExecuting] = useState(false)

  const submitTask = async (
    taskEndpoint: TaskEndpoint,
    options?: {
      onSuccess: (result: TResponse) => void
      onError?: (error?: string) => void
    },
  ) => {
    const _response = await executeTaskEndpoint<TResponse>(taskEndpoint, setIsExecuting)
    if (_response?.success) {
      // TODO: This is due to the slightly inaccurate types on TaskExecutionResult. See the comment
      // above its definition.
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      options?.onSuccess?.(_response.result!)
    } else {
      options?.onError?.(_response?.error)
    }
    setResponse(_response)
  }

  return isExecuting
    ? { isExecuting: true, response: null, submitTask: null }
    : { isExecuting: false, response, submitTask }
}
