import cx from 'classnames'
import { useEffect, useState } from 'react'
import culturePlateWorkflowAPI, {
  WorkflowInstanceWithCulturePlate,
} from '~/api/desktop/culturePlateWorkflow'
import WorkflowsInstanceHeader from '~/pages/Workcell/Schedule/Workflows/WorkflowsInstanceHeader'
import WorkflowInstanceTable from './WorkflowsInstanceTable'
import cs from './workflow_instances.scss'

const WorkflowInstances = ({ className }: { className?: string }) => {
  const [loading, setLoading] = useState(false)
  const [
    activeWorkflowInstancesWithCulturePlates,
    setActiveWorkflowInstancesWithCulturePlates,
  ] = useState<WorkflowInstanceWithCulturePlate[] | null>(null)

  const refreshWorkflows = () => {
    setActiveWorkflowInstancesWithCulturePlates([])
    setLoading(true)
    fetchWorkflowInstancesWithCulturePlate()
  }

  const fetchWorkflowInstancesWithCulturePlate = async () => {
    const activeWorkflowInstancesWithCulturePlates =
      await culturePlateWorkflowAPI.getActiveWorkflowInstancesWithCulturePlates()
    setActiveWorkflowInstancesWithCulturePlates(
      activeWorkflowInstancesWithCulturePlates.workflow_instances_with_culture_plate,
    )
    setLoading(false)
  }

  useEffect(() => {
    fetchWorkflowInstancesWithCulturePlate()
  }, [])

  return (
    <div className={cx(className, cs.workflowInstances)}>
      <WorkflowsInstanceHeader className={className} />
      <WorkflowInstanceTable
        className={className}
        workflowInstancesWithCulturePlates={activeWorkflowInstancesWithCulturePlates}
        loading={loading}
        onWorkflowInstanceUpdate={refreshWorkflows}
      />
    </div>
  )
}

export default WorkflowInstances
