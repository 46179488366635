import { isArray, range } from 'lodash/fp'
import { StorageInstrumentConfig } from '../../components/StorageViz/StorageInstrumentConfig.interface'
import { getRegionForSlot } from '../../components/StorageViz/getRegionForSlot'

// Return all shelves that contain at least one location in an allowed partition.
// allowedShelves is 1-indexed.
export const getAllowedShelvesForInstrument = (
  config: StorageInstrumentConfig,
  allowedPartitions: string[],
) => {
  const allowedShelves: number[] = []

  range(0, config.dimensions.num_shelves).forEach(shelf => {
    const maxLevels: number = isArray(config.dimensions.num_levels)
      ? config.dimensions.num_levels[shelf]
      : config.dimensions.num_shelves

    let shelfIntersectsAllowedPartitions = false

    range(0, maxLevels).forEach(level => {
      const regionName = getRegionForSlot(config.partitions, shelf, level)
      if (allowedPartitions.includes(regionName)) {
        shelfIntersectsAllowedPartitions = true
      }
    })

    if (shelfIntersectsAllowedPartitions) {
      allowedShelves.push(shelf + 1)
    }
  })
  return allowedShelves
}
