import cx from 'classnames'
import { get } from 'lodash/fp'
import React, { useState, useEffect } from 'react'

import commonDriverAPI from '~/api/desktop/commonDriver'

import { Instrument, InstrumentStatus } from '~/common.interface'
import GenericInstrumentControlBox from './GenericInstrumentControlBox'
import GenericInstrumentHeader from './GenericInstrumentHeader'
import cs from './generic_instrument_page.scss'

// It can take a moment for the server to go from READY to BUSY.
// We ignore READY messages for this long, before assuming that the command has finished.
// Without this, it sometimes goes from BUSY to READY immediately.
const COMMAND_DELAY = 500

const RESTART_DRIVER_DELAY = 5000

interface GenericInstrumentPageProps {
  className?: string
  instrument: Instrument
  status: InstrumentStatus
  live?: boolean
  bodyContents?: React.ReactNode
  sidebarContents?: React.ReactNode
  additionalStatusText?: string | JSX.Element
  // Called whenever a control command finishes.
  onControlCommandComplete?(...args: unknown[]): unknown
  hideSideBar?: boolean
  hideInstrumentStatus?: boolean
  onConfigUpdated?: () => void
}

const GenericInstrumentPage = ({
  className,
  instrument,
  status,
  live,
  sidebarContents,
  bodyContents,
  additionalStatusText,
  hideInstrumentStatus,
  onControlCommandComplete,
  hideSideBar,
  onConfigUpdated,
}: GenericInstrumentPageProps) => {
  // Whether the current user is executing a control command via this UI.
  const [isExecutingControlCommand, setIsExecutingControlCommand] = useState(false)
  // Whether the last command started executing. Used to change the busy message.
  const [executionStartTime, setExecutionStartTime] = useState<Date | undefined>()
  const [instrumentError, setInstrumentError] = useState('')
  // The name of the command that was executed.
  const [commandExecuted, setCommandExecuted] = useState<string | null>(null)

  const instrumentStatus =
    get('execution_state', status) === undefined
      ? 'Disconnected'
      : status.execution_state
  const driverState = get('driver_state', status)

  useEffect(() => {
    if (
      instrumentStatus !== 'BUSY' &&
      isExecutingControlCommand &&
      executionStartTime &&
      +new Date() - +executionStartTime > COMMAND_DELAY
    ) {
      setIsExecutingControlCommand(false)
      if (onControlCommandComplete) {
        onControlCommandComplete(commandExecuted)
      }
    }
    if (driverState === 'RUNNING' && commandExecuted === 'start_driver') {
      setCommandExecuted(null)
    }
    if (driverState === 'STOPPED' && commandExecuted === 'stop_driver') {
      setCommandExecuted(null)
    }
    if (
      driverState === 'RUNNING' &&
      commandExecuted === 'restart_driver' &&
      executionStartTime &&
      +new Date() - +executionStartTime > RESTART_DRIVER_DELAY
    ) {
      setCommandExecuted(null)
    }
  }, [status])

  const executeCommand = async (command, params = null) => {
    setExecutionStartTime(new Date())
    setIsExecutingControlCommand(true)
    setCommandExecuted(command)
    setInstrumentError('')

    let commandParams = []
    if (params) {
      try {
        commandParams = JSON.parse(params)
      } catch (error) {
        console.error(String(error)) // eslint-disable-line no-console
        setInstrumentError('Invalid parameters')
        setIsExecutingControlCommand(false)
        setCommandExecuted(null)
        return
      }
    }

    try {
      const response = await commonDriverAPI.executeControlCommand(
        instrument.instrumentName,
        command,
        commandParams,
      )
      if (!response.success) {
        setInstrumentError('There was an issue executing the control command')
        setIsExecutingControlCommand(false)
        setCommandExecuted(null)
      }
    } catch (error) {
      setInstrumentError('There was an issue executing the control command')
      setIsExecutingControlCommand(false)
      setCommandExecuted(null)
    }
  }

  const startDriver = async () => {
    setExecutionStartTime(new Date())
    setCommandExecuted('start_driver')
    await commonDriverAPI.start(instrument.instrumentName)
  }

  const stopDriver = async () => {
    setCommandExecuted('stop_driver')
    setExecutionStartTime(new Date())
    await commonDriverAPI.stop(instrument.instrumentName)
  }

  const restartDriver = async () => {
    setCommandExecuted('restart_driver')
    setExecutionStartTime(new Date())
    await commonDriverAPI.restart(instrument.instrumentName)
  }

  if (!instrument) {
    return null
  }
  return (
    <div className={cx(className, cs.genericInstrumentPage)}>
      <GenericInstrumentHeader
        instrument={instrument}
        status={status}
        className={cs.header}
        isExecutingControlCommand={isExecutingControlCommand}
        executeCommand={executeCommand}
        startDriver={startDriver}
        restartDriver={restartDriver}
        stopDriver={stopDriver}
        hideInstrumentStatus={hideInstrumentStatus}
        additionalStatusText={additionalStatusText}
        onConfigUpdated={onConfigUpdated}
      />
      <div className={cs.body}>
        {!hideSideBar && (
          <div className={cs.leftSidebar}>
            {sidebarContents}
            <GenericInstrumentControlBox
              status={status}
              instrument={instrument}
              className={cs.controlBox}
              isExecutingControlCommand={isExecutingControlCommand}
              live={live}
              executeCommand={executeCommand}
              instrumentError={instrumentError}
              setInstrumentError={setInstrumentError}
              commandExecuted={commandExecuted}
              startDriver={startDriver}
            />
          </div>
        )}
        <div className={cs.bodyContents}>{bodyContents}</div>
      </div>
    </div>
  )
}

GenericInstrumentPage.defaultProps = {
  bodyContents: null,
  sidebarContents: null,
  hideSideBar: false,
}

export default GenericInstrumentPage
