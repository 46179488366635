import { client, unwrap } from '~/api/desktopAPIv2'
import { WorkflowCreateParams } from '~/pages/Workcell/OperatorActions/reloadItems/convertChangeToReloadOperation'
import { components } from './generated-schema'

export type WorkflowInstance = components['schemas']['WorkflowInstance']

export type AddCulturePlateToWorkflowResponse =
  components['schemas']['AddCulturePlateToWorkflowResponse']

export type RemoveCulturePlateFromCurrentWorkflowResponse =
  components['schemas']['RemoveCulturePlateFromCurrentWorkflowResponse']
export type GetActiveWorkflowInstancesWithCulturePlatesResponse =
  components['schemas']['GetActiveWorkflowInstancesWithCulturePlatesResponse']
export type WorkflowInstanceWithCulturePlate =
  components['schemas']['WorkflowInstanceWithCulturePlate']

export default {
  addCulturePlateToWorkflow: (
    culturePlateUuid: string,
    workflowCreateParams: WorkflowCreateParams,
  ): Promise<AddCulturePlateToWorkflowResponse> =>
    unwrap(
      client().POST('/api/v2/culture-plate-workflows/add-culture-plate-to-workflow', {
        body: {
          culture_plate_uuid: culturePlateUuid,
          workflow_create_params: workflowCreateParams,
        },
      }),
    ),
  removeCulturePlateFromCurrentWorkflow: (
    culturePlateUuid: string,
  ): Promise<RemoveCulturePlateFromCurrentWorkflowResponse> =>
    unwrap(
      client().POST(
        '/api/v2/culture-plate-workflows/remove-culture-plate-from-current-workflow',
        {
          body: {
            culture_plate_uuid: culturePlateUuid,
          },
        },
      ),
    ),
  getActiveWorkflowInstancesWithCulturePlates:
    (): Promise<GetActiveWorkflowInstancesWithCulturePlatesResponse> =>
      unwrap(
        client().GET(
          '/api/v2/culture-plate-workflows/get-active-workflow-instances-with-culture-plates',
          {},
        ),
      ),
}
