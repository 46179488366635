import cx from 'classnames'
import { useEffect, useState } from 'react'
import roboticArmAPI, { SuccessResponse } from '~/api/desktop/drivers/roboticArm'
import InputWithUnits from '~/components/InputWithUnits'
import ToggleSwitch from '~/components/ToggleSwitch'
import UnfoldLessIcon from '~/components/icons/UnfoldLessIcon'
import UnfoldMoreIcon from '~/components/icons/UnfoldMoreIcon'
import Jogger from '~/pages/Workcell/InstrumentPages/TeachTool/Jogger'
import NestControls from '~/pages/Workcell/InstrumentPages/TeachTool/NestControls'
import { splitAndRound } from '~/utils/string'
import SafeLocationControls from './SafeLocationControls'
import cs from './teach_panel.scss'

interface TeachPanelProps {
  instrumentName: string
  refreshGripperStatus: () => void
  refreshBodyStatus: () => void
  reloadKey?: string
  bodyFree?: boolean
  isTeachToolBusy: boolean
  teachPanelBusyWrapper: (func: () => Promise<SuccessResponse | void>) => void
}

const TeachPanel = ({
  instrumentName,
  bodyFree,
  reloadKey,
  refreshGripperStatus,
  refreshBodyStatus,
  isTeachToolBusy,
  teachPanelBusyWrapper,
}: TeachPanelProps) => {
  const [currentCartesianLoc, setCurrentCartesianLoc] = useState<string>()
  const [currentJointLoc, setCurrentJointLoc] = useState<string>()
  const [graspPlateWidth, setGraspPlateWidth] = useState<string>('125')
  const [ungraspPlateWidth, setUngraspPlateWidth] = useState<string>('133')
  const [loadingToggleRobotFree, setLoadingToggleRobotFree] = useState<boolean>(false)

  const refreshCurrentRoboticLocations = async () => {
    await refreshCartesianLoc()
    await refreshJointLoc()
  }

  const refreshCartesianLoc = async () => {
    const res = await roboticArmAPI.wc(instrumentName)
    setCurrentCartesianLoc(res)
  }

  const refreshJointLoc = async () => {
    const res = await roboticArmAPI.wj(instrumentName)
    setCurrentJointLoc(res)
  }

  useEffect(() => {
    refreshCurrentRoboticLocations()
  }, [])

  const currentLocation = () => {
    return (
      <div className={cs.currentLocations}>
        <div className={cs.location}>
          <div className={cs.locationType}>Cartesian</div>
          <div className={cs.locationText}>
            {splitAndRound(currentCartesianLoc || '', ' ')}
          </div>
        </div>
        <div className={cs.location}>
          <div className={cs.locationType}>Joint </div>
          <div className={cs.locationText}>
            {splitAndRound(currentJointLoc || '', ' ')}
          </div>
        </div>
      </div>
    )
  }

  const toggleRobotFree = async () => {
    setLoadingToggleRobotFree(true)
    if (bodyFree) {
      await teachPanelBusyWrapper(() => roboticArmAPI.uf(instrumentName))
      await refreshBodyStatus()
    } else {
      await teachPanelBusyWrapper(() => roboticArmAPI.sf(instrumentName))
      await refreshBodyStatus()
    }
    setLoadingToggleRobotFree(false)
  }

  const areMotorsOn = bodyFree !== undefined && !bodyFree
  const isRobotReady = !isTeachToolBusy && areMotorsOn && !loadingToggleRobotFree

  const freeUnfreePanel = () => {
    return (
      <div className={cs.freeUnfreePanel}>
        <ToggleSwitch
          onLabel='Motors On'
          offLabel='Motors Off'
          loadingLabel='Loading'
          isOn={areMotorsOn}
          onClick={toggleRobotFree}
          className={cs.toggleSwitch}
          loading={loadingToggleRobotFree}
          disabled={isTeachToolBusy || loadingToggleRobotFree}
        />
      </div>
    )
  }

  const gripReleasePanel = () => {
    return (
      <div className={cs.gripReleasePanel}>
        <div className={cs.gripPanel}>
          <div
            className={cx(cs.gripLabel, !isRobotReady && cs.disabled)}
            onClick={async () => {
              await teachPanelBusyWrapper(() =>
                roboticArmAPI.g(instrumentName, Number(graspPlateWidth)),
              )
              refreshGripperStatus()
            }}
          >
            <UnfoldLessIcon className={cs.icon} />
            <div className={cs.label}>Grip</div>
          </div>
          <InputWithUnits
            className={cs.input}
            value={graspPlateWidth}
            onChange={setGraspPlateWidth}
            unit={'mm'}
          />
        </div>
        <div className={cs.releasePanel}>
          <div
            className={cx(cs.releaseLabel, !isRobotReady && cs.disabled)}
            onClick={async () => {
              await teachPanelBusyWrapper(() =>
                roboticArmAPI.ug(instrumentName, Number(ungraspPlateWidth)),
              )
              refreshGripperStatus()
            }}
          >
            <UnfoldMoreIcon className={cs.icon} />
            <div className={cs.label}>Release</div>
          </div>
          <InputWithUnits
            className={cs.input}
            value={ungraspPlateWidth}
            onChange={setUngraspPlateWidth}
            unit={'mm'}
          />
        </div>
      </div>
    )
  }

  return (
    <div className={cs.teachPanel}>
      <div className={cs.leftSidebar}>
        {freeUnfreePanel()}
        <Jogger
          instrumentName={instrumentName}
          isTeachToolBusy={!isRobotReady}
          teachToolBusyWrapper={teachPanelBusyWrapper}
          onLocationUpdated={refreshCurrentRoboticLocations}
        />
        {gripReleasePanel()}
        {currentLocation()}
      </div>
      <div className={cs.bodyContents}>
        <NestControls
          instrumentName={instrumentName}
          isTeachToolBusy={!isRobotReady}
          teachToolBusyWrapper={teachPanelBusyWrapper}
          currentCartesianLoc={currentCartesianLoc}
          onLocationUpdated={refreshCurrentRoboticLocations}
          reloadKey={reloadKey}
        />
        <SafeLocationControls
          instrumentName={instrumentName}
          isTeachToolBusy={!isRobotReady}
          teachToolBusyWrapper={teachPanelBusyWrapper}
          currentJointLoc={currentJointLoc}
          onLocationUpdated={refreshCurrentRoboticLocations}
          reloadKey={reloadKey}
          className={cs.safeLocationControls}
        />
      </div>
    </div>
  )
}

export default TeachPanel
