import { useAuth0 } from '@auth0/auth0-react'
import { debounce } from 'lodash'
import { useCallback, useEffect, useState } from 'react'

import { SubImageSizeGraphQl } from '~/__generated__/graphql'
import { FullPageError } from '~/components/Errors'
import AppToaster from '~/components/Toaster'
import ToggleSwitch from '~/components/ToggleSwitch'
import Button from '~/components/buttons/Button'
import { useFeatureFlag } from '~/core/featureFlags'

import {
  DEMO_PRODUCTS,
  DemoProduct,
  useDemoDataState,
  useDemoToggleState,
} from './DemoContext'
import { plateConverter } from './converters/demoPlate'
import { timepointConverter } from './converters/demoTimepoint'
import { wellConverter } from './converters/demoWell'
import {
  DemoDerivedRecord,
  DemoObjectConverter,
  DemoSourceRecord,
  DemoSourceRecordOfKind,
  DemoSourceRecordsByKind,
  demoObjectsToTable,
  tableToDemoObjects,
} from './demoData'
import cs from './demo_controls_page.scss'
import imageSets, {
  getDemoPlateSprite,
  DemoImageSet,
  DemoPlateObservation,
} from './imageSets'

export default function DemoControlsPage() {
  const { logout } = useAuth0()
  const featureEnabled = useFeatureFlag('demoControls')
  const [toggleEnabled] = useDemoToggleState()
  const [product, setProduct] = useState<DemoProduct>(getLastUsedProduct())

  if (!featureEnabled) {
    return (
      <FullPageError
        customFriendlyMessage='Demo controls are not enabled for this user or organization.'
        customActionButton={
          <Button
            onClick={() =>
              logout({ logoutParams: { returnTo: window.location.origin } })
            }
            label='Log Out'
            type='primary'
          />
        }
      />
    )
  }

  return (
    <div className={cs.page}>
      <h1>Demo Controls</h1>

      <p>Use this page to customize the data displayed in the Monitor.</p>

      <div className={cs.layout}>
        <div className={cs.sidebar}>
          <UseDemoDataSwitch />
          <div className={cs.sticky}>
            {toggleEnabled ? (
              <>
                <ProductSelector
                  currentProduct={product}
                  onChange={newProduct => {
                    setProduct(newProduct)
                    setLastUsedProduct(newProduct)
                  }}
                />
                <Presets product={product} />
              </>
            ) : null}
          </div>
        </div>

        {toggleEnabled ? (
          <div className={cs.content}>
            <InputDemoDataControls product={product} />
            <hr />
            <AvailableDemoImages />
          </div>
        ) : null}
      </div>
    </div>
  )
}

function UseDemoDataSwitch() {
  const [value, setValue] = useDemoToggleState()

  return (
    <fieldset>
      <legend>Use demo data</legend>

      <ToggleSwitch
        onLabel='On'
        offLabel='Off'
        isOn={value}
        onClick={() => {
          setValue(!value)
        }}
      />

      <p>
        This switch controls whether demo data is shown in the product. When demo data
        is shown, real data from the cloud will be hidden.
      </p>

      <p>
        Some pages aren't capable of showing demo data. Such pages will always show real
        data from the cloud, even if this switch is on. If you're signed into a demo
        organization, the cloud typically won't have any data to show.
      </p>
    </fieldset>
  )
}

function ProductSelector({
  currentProduct,
  onChange,
}: { currentProduct: DemoProduct; onChange: (newProduct: DemoProduct) => void }) {
  return (
    <fieldset>
      <legend>Edit data for</legend>

      {DEMO_PRODUCTS.map(product => (
        <div key={product} className={cs.productOption}>
          <input
            type='radio'
            id={'demo_product_option_' + product}
            name={product}
            value={product}
            checked={product === currentProduct}
            onChange={e => {
              onChange(e.currentTarget.value as DemoProduct)
            }}
          />
          <label htmlFor={'demo_product_option_' + product}>{product}</label>
        </div>
      ))}

      <p>Select the product for which you want to edit demo data.</p>

      <p>
        This only changes which product you're editing on this page. When the demo
        switch is turned on, all products will show their respective demo data, not just
        the product that is selected here.
      </p>
    </fieldset>
  )
}

function Presets({ product }: { product: DemoProduct }) {
  const [_, setData] = useDemoDataState(product)

  return (
    <fieldset>
      <legend>Apply a preset</legend>

      {PRESETS.filter(preset => preset.products.includes(product)).map(preset => (
        <Button
          className={cs.presetButton}
          key={preset.name}
          label={preset.name}
          onClick={() => {
            setData(preset.data)
            // Hack: For now, reload the page to avoid the extra complexity of
            // mixing controlled and uncontrolled textarea state.
            location.reload()
          }}
        />
      ))}
    </fieldset>
  )
}

function InputDemoDataControls({ product }: { product: DemoProduct }) {
  const [data] = useDemoDataState(product)

  return (
    <div>
      <div className={cs.toolbar}>
        <h2>Input Demo Data</h2>
      </div>

      <div className={cs.instructions}>
        <p>
          Input your data in TSV (tab-separated values) format. It's easiest to copy and
          paste the data from a spreadsheet. (If you prefer, you can also manually edit
          each line of data. The text boxes will accept the tab key as input.) You must
          provide data for all required columns — leaving a column blank can lead to
          errors during your demo.
        </p>

        <p>
          <b>Demo data is stored in your browser, not in the cloud.</b> If you want to
          use your demo on a different device, you'll need to input your data on that
          device.
        </p>

        <p>
          This page will auto-save as you type, and the data will stay here even if you
          close your browser. However, if you haven't used the demo in a while, your
          browser might clear the data automatically.{' '}
          <b>
            Always save your spreadsheets somewhere else. Don't rely on your data being
            saved here, and always check your demo before a meeting.
          </b>
        </p>

        <p>
          Errors are shown in <span style={{ color: 'red' }}>red</span>. If there is an
          error, your changes might not auto-save until the error is resolved.
        </p>

        <p>
          Don't open this page in multiple tabs at the same time, otherwise your edits
          could overwrite each other. If you change the data in another browser tab,
          whether by editing this page or by interacting with the actual product, you'll
          need to refresh to see your changes.
        </p>
      </div>

      <Controls
        product={product}
        kindKey='plates'
        converter={plateConverter}
        kindLabel='Plates'
      />
      <Controls
        product={product}
        kindKey='wells'
        converter={wellConverter}
        kindLabel='Wells'
      />
      <Controls
        product={product}
        kindKey='timepoints'
        converter={timepointConverter}
        kindLabel='Timepoints'
      />
    </div>
  )
}

function Controls<
  K extends keyof DemoSourceRecordsByKind,
  P,
  D extends DemoDerivedRecord,
>({
  product,
  kindKey,
  kindLabel,
  converter,
}: {
  product: DemoProduct
  kindKey: K
  kindLabel: string
  converter: DemoObjectConverter<DemoSourceRecordOfKind<K>, P, D>
}) {
  const [data, setSourceData] = useDemoDataState(product)
  const canonicalText = demoObjectsToTSV(converter, data.sourceData[kindKey] ?? [])
  const [text, setText] = useState(canonicalText)
  const [parsingError, setParsingError] = useState('')

  // Reset the text when changing the product, but not when making normal edits.
  useEffect(() => {
    setText(canonicalText)
  }, [product])

  const updateDemoData = useCallback(
    debounce((text: string) => {
      const rows = text.split('\n').map(line => line.split('\t'))
      try {
        const parsed = tableToDemoObjects(converter, rows)
        setSourceData({ ...data.sourceData, [kindKey]: parsed })
        setParsingError('')
      } catch (e) {
        if (e instanceof Error) {
          setParsingError(e.toString())
        } else {
          throw e
        }
      }
    }, 200),
    [converter, setSourceData, data, kindKey, setParsingError],
  )

  return (
    <div>
      <h3>{kindLabel}</h3>

      <textarea
        rows={10}
        cols={120}
        value={text}
        onChange={e => {
          setText(e.currentTarget.value)
          updateDemoData(e.currentTarget.value)
        }}
        onKeyDown={e => {
          if (e.key == 'Tab') {
            e.preventDefault()
            insertTab(e.currentTarget)
            setText(e.currentTarget.value)
            updateDemoData(e.currentTarget.value)
          }
        }}
      />

      {parsingError ? <p className={cs.error}>{parsingError}</p> : null}
      {data.errors?.[kindKey]?.map(e => (
        <p className={cs.error}>{e}</p>
      ))}

      <details>
        <summary>TSV interpretation</summary>
        <TSVTable tsv={text} />
      </details>

      <details>
        <summary>Processed data</summary>

        {!data.sourceData[kindKey]?.length ? 'None.' : null}
        <ol>
          {(data.sourceData[kindKey] ?? []).map((item, i) => (
            <li key={i}>{JSON.stringify(item)}</li>
          ))}
        </ol>
      </details>
    </div>
  )
}

function demoObjectsToTSV<S extends DemoSourceRecord, P, D extends DemoDerivedRecord>(
  converter: DemoObjectConverter<S, P, D>,
  items: S[],
): string {
  return demoObjectsToTable(converter, items)
    .map(row => row.join('\t'))
    .join('\n')
}

function TSVTable({ tsv }: { tsv: string }) {
  const rows = tsv.split('\n').map(line => line.split('\t'))
  return (
    <table>
      <thead>
        <tr>
          {rows[0].map((cell, i) => (
            <th key={i}>{cell}</th>
          ))}
        </tr>
      </thead>

      <tbody>
        {rows.slice(1).map((row, r) => (
          <tr key={r}>
            {row.map((cell, c) => (
              <td key={c}>{cell || <span className={cs.blank}>(blank)</span>}</td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  )
}

function insertTab(el: HTMLTextAreaElement) {
  let start = el.selectionStart
  let end = el.selectionEnd
  el.value = el.value.substring(0, start) + '\t' + el.value.substring(end)
  el.selectionStart = el.selectionEnd = start + 1
}

function AvailableDemoImages() {
  const imageSetsWithPlateObservations = imageSets.filter(
    is => is.plateObservations != null,
  )
  const imageSetsWithoutPlateObservations = imageSets.filter(
    is => is.plateObservations == null,
  )

  return (
    <div>
      <h2>Available Demo Images</h2>

      {imageSetsWithPlateObservations.map((imageSet, i) => (
        <ImageSetDetails key={i} imageSet={imageSet} />
      ))}

      <hr />

      <details>
        <summary>WIP image sets</summary>

        <p>
          These image sets haven't been stitched into plates and montages yet, and are
          not available to use in the Monitor demos.
        </p>

        {imageSetsWithoutPlateObservations.map((imageSet, i) => (
          <ImageSetDetails key={i} imageSet={imageSet} />
        ))}
      </details>
    </div>
  )
}

function ImageSetDetails({ imageSet }: { imageSet: DemoImageSet }) {
  const [showAllIndividual, setShowAllIndividual] = useState(
    imageSet.originals && imageSet.originals.length <= 12,
  )
  const originalsToShow =
    imageSet.originals?.slice(0, showAllIndividual ? undefined : 11) || []

  return (
    <div className={cs.imageSetDetails}>
      <h3>{imageSet.name}</h3>

      <p>{imageSet.description}</p>

      <p>
        <b>License:</b> {imageSet.license}
      </p>

      <p>
        <b>Required citation:</b> {imageSet.citation}. Licensed under {imageSet.license}
        .
      </p>

      {imageSet.originals ? (
        <div className={cs.imageGallery}>
          {originalsToShow.map(path => (
            <GalleryImage key={path} originalFilePath={path} />
          ))}
          {!showAllIndividual && (
            <Button
              label={`Show all ${imageSet.originals?.length}`}
              onClick={() => {
                setShowAllIndividual(true)
              }}
            />
          )}
        </div>
      ) : null}

      {imageSet.plateObservations ? (
        <div className={cs.spriteGallery}>
          {imageSet.plateObservations.map(obs => (
            <GallerySprite key={obs.spriteDirectory} plateObservation={obs} />
          ))}
        </div>
      ) : null}
    </div>
  )
}

function GalleryImage({ originalFilePath }: { originalFilePath: string }) {
  const jpgPath = originalFilePath.split('.').slice(0, -1).concat(['jpg']).join('.')
  const smallURL =
    'https://monomer-external-assets-public.s3.us-west-2.amazonaws.com/image_fixtures/200/' +
    jpgPath
  const largeURL =
    'https://monomer-external-assets-public.s3.us-west-2.amazonaws.com/image_fixtures/2000/' +
    jpgPath

  return (
    <a href={largeURL} target='_blank' rel='noreferrer'>
      <img loading='lazy' src={smallURL} />
    </a>
  )
}

function GallerySprite({
  plateObservation,
}: { plateObservation: DemoPlateObservation }) {
  const { spriteDirectory, montageLayout, wellSequence } = plateObservation
  const large = getDemoPlateSprite(spriteDirectory, SubImageSizeGraphQl.Size_600X600)
  const small = getDemoPlateSprite(spriteDirectory, SubImageSizeGraphQl.Size_200X200)
  const name = spriteDirectory.split('/').slice(-1)[0]

  return (
    <div>
      <a href={large.url} target='_blank' rel='noreferrer'>
        <img loading='lazy' src={small.url} />
      </a>

      <div
        className={cs.identifier}
        onClick={async () => {
          await navigator.clipboard.writeText(spriteDirectory)
          AppToaster.show({ message: `Copied "${spriteDirectory}"` })
        }}
      >
        {name}
      </div>

      <div className={cs.extra}>
        {wellSequence.length} wells • {montageLayout} montage
      </div>
    </div>
  )
}

function getLastUsedProduct(): DemoProduct {
  const value =
    localStorage.getItem('monomer_demo_controls_last_used_product') ?? 'monitor'
  if (!DEMO_PRODUCTS.includes(value as DemoProduct)) {
    return DEMO_PRODUCTS[0]
  }
  return value as DemoProduct
}

function setLastUsedProduct(product: DemoProduct) {
  localStorage.setItem('monomer_demo_controls_last_used_product', product)
}

const PRESETS: {
  name: string
  products: DemoProduct[]
  data: DemoSourceRecordsByKind
}[] = [
  {
    name: 'Basic example',
    products: ['monitor'],
    data: {
      plates: [
        { barcode: 'p1', format: '96' },
        { barcode: 'p2', format: '6' },
      ],
      wells: ['A1', 'A2', 'A3', 'B1', 'B2', 'B3'].map(position => ({
        plateBarcode: 'p2',
        position,
        parentWellPlateBarcode: '',
        parentWellPosition: '',
        cellLine: '',
        cellLineLot: '',
        passageNumber: '',
      })),
      timepoints: ['A1', 'A2', 'A3', 'B1', 'B2', 'B3'].map(position => ({
        plateBarcode: 'p2',
        wellPosition: position,
        timestamp: '2024-12-12T16:00:00.000Z',
        plateImageSet:
          imageSets.find(is => is.plateObservations?.[0].wellSequence.length === 6)
            ?.plateObservations?.[0].spriteDirectory ?? '',
        confluence: '34',
      })),
    },
  },
  { name: 'Clear Data', products: ['monitor', 'cle'], data: {} },
]
