import { client, unwrap } from '~/api/desktopAPIv2'
import { components } from './generated-schema'

export type SetServiceConfigRequest = components['schemas']['SetServiceConfigRequest']
export type DefaultSuccessResponse = components['schemas']['DefaultSuccessResponse']
export type GetServiceConfigResponse = components['schemas']['GetServiceConfigResponse']
export type GetServiceConfigSchemaResponse =
  components['schemas']['GetServiceConfigSchemaResponse']

export default {
  getConfig: (serviceName: string): Promise<GetServiceConfigResponse> =>
    unwrap(
      client().GET('/api/v2/service-configs/{service_name}/get', {
        params: {
          path: { service_name: serviceName },
        },
      }),
    ),
  getConfigSchema: (serviceName: string): Promise<GetServiceConfigSchemaResponse> =>
    unwrap(
      client().GET('/api/v2/service-configs/{service_name}/get-schema', {
        params: {
          path: { service_name: serviceName },
        },
      }),
    ),
  setConfig: (
    serviceName: string,
    config: Record<string, never>,
  ): Promise<DefaultSuccessResponse> =>
    unwrap(
      client().POST('/api/v2/service-configs/{service_name}/set', {
        params: {
          path: { service_name: serviceName },
        },
        body: {
          service_config: config,
        },
      }),
    ),
}
