import MicroplateIcon from '~/components/icons/MicroplateIcon'
import LoadUnloadItemsExperimentPlateForm, {
  getExperimentPlateLoadParamsRequestOrError,
} from '../LoadUnloadItemsProcessItemForm/LoadUnloadItemsExperimentPlateForm'
import { ProcessItemTypeConfig } from './ProcessItemTypeConfig.interface'

export const LOAD_UNLOAD_ITEMS_EXPERIMENT_PLATE_CONFIG: ProcessItemTypeConfig<'experiment_plate'> =
  {
    getLoadParamsRequestOrError: getExperimentPlateLoadParamsRequestOrError,
    formComponent: LoadUnloadItemsExperimentPlateForm,
    iconComponent: MicroplateIcon,
  }
