import { DesktopAPI } from '~/api/core'
import { components } from '../desktop/generated-schema'
import { client, unwrap } from '../desktopAPIv2'

const operatorActionAPI = new DesktopAPI('/api/operator-actions')

export type EnabledOperatorConfigs = Record<string, Record<string, unknown>>
export interface GetAllEnabledConfigsResponse {
  configs: EnabledOperatorConfigs
}

export type OperatorActionsEnabledAndUnusedByNameResponse =
  components['schemas']['OperatorActionsEnabledAndUnusedByNameResponse']

export default {
  getConfig: operatorActionName =>
    operatorActionAPI.get(`${operatorActionName}/config/`),
  getConfigSchema: operatorActionName =>
    operatorActionAPI.get(`${operatorActionName}/config-schema/`),
  setConfig: (operatorActionName, newConfig, overwrite) =>
    operatorActionAPI.post(`${operatorActionName}/config/`, {
      config: newConfig,
      overwrite,
    }),
  getAllEnabledConfigs: (): Promise<GetAllEnabledConfigsResponse> =>
    operatorActionAPI.get('all-enabled-configs'),
  // NOTE: Operator actions that are disabled due to feature flags are omitted
  // from this response.
  getEnabledAndUnusedOperatorActionNames:
    (): Promise<OperatorActionsEnabledAndUnusedByNameResponse> =>
      unwrap(client().GET('/api/v2/operator-actions/enabled-and-unused-by-name')),
}
