import PropTypes from 'prop-types'
import { IconProps } from './types/IconProps'
const FileIcon = ({ className, onClick }: IconProps) => (
  <svg
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    className={className}
    onClick={onClick}
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M14 2H6C4.90002 2 4 2.89999 4 4V20C4 21.1 4.89001 22 5.98999 22H18C19.1 22 20 21.1 20 20V8L14 2ZM16 12H8V14H16V12ZM16 16H8V18H16V16ZM6 20H18V9H13V4H6V20Z'
    />
  </svg>
)

FileIcon.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
}

export default FileIcon
