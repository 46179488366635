import { includes } from 'lodash/fp'

import { Instrument } from '~/common.interface'
import { isLiconicInstrument } from '~/pages/Workcell/components/instrument/isLiconicInstrument'
import { LoadUnloadItemsProcessItemType } from '../LoadUnloadItemsProcessItemType.interface'

export const canLoadOrUnloadMultipleForInstrument = (
  instrument: Instrument | null,
  manuallyMove: boolean,
  loadOrUnload: 'load' | 'unload',
) => {
  // The Inheco Scila has a check-in command, so only one process item can be loaded at a time.
  if (
    instrument?.instrumentType != null &&
    instrument.instrumentType === 'inheco_scila'
  ) {
    return false
  }
  // The Liconic normally uses a transfer station, so we aren't selecting locations when loading normally.
  //
  // If manuallyMove is true, then we are loading via the door, and can load multiple.
  //
  // We also allow unloading multiple via the transfer station,
  // and we display a warning to the user to immediately remove plates from the liconic when unloaded.
  if (
    instrument?.instrumentType != null &&
    isLiconicInstrument(instrument?.instrumentType) &&
    !(manuallyMove || loadOrUnload === 'unload')
  ) {
    return false
  }

  return true
}

export const canLoadMultipleForItemType = (
  _processItemType: LoadUnloadItemsProcessItemType,
) => {
  // Attempting to load multiple of these would result in a duplicate uuid.
  if (
    includes(_processItemType, [
      'empty_culture_plate',
      'empty_assay_plate',
      'experiment_plate',
      'culture_plate',
    ])
  ) {
    return false
  }
  return true
}

// Any item type can be unloaded in bulk using the LoadUnload interface.
// The user selects multiple locations, and we figure out the uuid of the items
// in those locations.
export const canUnloadMultipleForItemType = () => {
  return true
}

export const getCanSelectMultipleLocations = (
  loadOrUnload: 'load' | 'unload',
  selectedInstrument: Instrument | null,
  selectedProcessItemType: LoadUnloadItemsProcessItemType,
  useArmAssist: boolean,
  manuallyMove: boolean,
) => {
  if (loadOrUnload === 'load') {
    return (
      canLoadOrUnloadMultipleForInstrument(
        selectedInstrument,
        manuallyMove,
        loadOrUnload,
      ) &&
      canLoadMultipleForItemType(selectedProcessItemType) &&
      !useArmAssist
    )
  }

  return (
    canLoadOrUnloadMultipleForInstrument(
      selectedInstrument,
      manuallyMove,
      loadOrUnload,
    ) &&
    canUnloadMultipleForItemType() &&
    !useArmAssist
  )
}
