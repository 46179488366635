import { includes } from 'lodash/fp'
import { useEffect, useState } from 'react'

import { ReagentSelect } from '~/pages/Workcell/components/processItem/ReagentSelect'
import { getNumWells } from '~/utils/microplate'
import { ReagentPlateLoadParamsRequest } from '../../reloadItems/convertChangeToReloadOperation'
import { LabwareSelect } from '../LabwareSelect'
import { LoadParamsRequestOrError } from '../LoadParamsRequestOrError.interface'
import { LoadUnloadItemsReagentPlateParams } from '../LoadUnloadItemsProcessItemParams.interface'
import { LoadUnloadItemsProcessItemFormComponentProps } from './LoadUnloadItemsProcessItemFormComponentProps.interface'
import RightPackedConsumableWellSelect from './RightPackedConsumableWellSelect'
import cs from './load_unload_items_reagent_plate_form.scss'

const LoadUnloadItemsReagentPlateForm = ({
  className,
  config,
  processItemParams,
  setProcessItemParams,
  disabled,
}: LoadUnloadItemsProcessItemFormComponentProps<'reagent_plate'>) => {
  const [lastSelectedLabwareName, setLastSelectedLabwareName] = useState<
    string | undefined
  >(undefined)

  useEffect(() => {
    if (processItemParams.selectedLabware) {
      setProcessItemParams(
        (_latestProcessItemParams: Partial<LoadUnloadItemsReagentPlateParams>) => ({
          ..._latestProcessItemParams,
          ...(_latestProcessItemParams.selectedLabware?.plate_format
            ? {
                initialNumWells: getNumWells(
                  _latestProcessItemParams.selectedLabware.plate_format,
                ),
              }
            : {}),
        }),
      )
    }
  }, [processItemParams.selectedLabware])

  return (
    <div className={className}>
      <ReagentSelect
        wide
        selectedReagent={processItemParams.selectedReagent}
        onSelectReagent={(reagent: string) => {
          setProcessItemParams(
            (_latestProcessItemParams: Partial<LoadUnloadItemsReagentPlateParams>) => ({
              ..._latestProcessItemParams,
              selectedReagent: reagent,
            }),
          )
        }}
        disabled={disabled}
      />
      <LabwareSelect
        processItemType='reagent_plate'
        className={cs.labwareSelect}
        defaultLabwareName={lastSelectedLabwareName}
        selectedLabware={processItemParams.selectedLabware}
        onLabwareSelect={labware => {
          setLastSelectedLabwareName(labware?.labware_name)
          setProcessItemParams(
            (_latestProcessItemParams: Partial<LoadUnloadItemsReagentPlateParams>) => ({
              ..._latestProcessItemParams,
              selectedLabware: labware,
            }),
          )
        }}
        triggerClassName={cs.trigger}
        popoverClassName={cs.popover}
        disabled={disabled}
      />
      {includes('reagent_plate', config.itemTypesWithSelectableWells) && (
        <RightPackedConsumableWellSelect
          className={cs.wellSelect}
          processItemParams={processItemParams}
          setProcessItemParams={newParams => {
            setProcessItemParams(
              (
                _latestProcessItemParams: Partial<LoadUnloadItemsReagentPlateParams>,
              ) => ({
                ..._latestProcessItemParams,
                ...newParams,
              }),
            )
          }}
        />
      )}
    </div>
  )
}

export const getReagentPlateLoadParamsRequestOrError = (
  processItemParams: Partial<LoadUnloadItemsReagentPlateParams>,
): LoadParamsRequestOrError => {
  if (!processItemParams.selectedReagent) {
    return {
      error: 'Please select a reagent type',
    }
  }
  if (!processItemParams.selectedLabware) {
    return {
      error: 'Please select a labware.',
    }
  }
  return {
    params: {
      labware_name: processItemParams.selectedLabware.labware_name,
      tags: [processItemParams.selectedReagent],
      initial_num_wells: processItemParams.initialNumWells,
    } as ReagentPlateLoadParamsRequest,
  }
}

export default LoadUnloadItemsReagentPlateForm
