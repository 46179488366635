import cx from 'classnames'
import { get, isNumber } from 'lodash/fp'
import { useContext, useState } from 'react'

import TinyMicroplate from '~/components/TinyMicroplate'

import { ProcessItem } from '~/common.interface'
import MinimalButton from '~/components/buttons/MinimalButton'
import { convertWellCoordsToWellName, getPlateFormatFromDims } from '~/utils/microplate'
import WorkcellStatusContext from '../../WorkcellStatusContext'
import { getAllowFullColumnsOnly } from '../../utils/workcellStatus'
import cs from './process_item_contiguous_edit.scss'

interface ProcessItemContiguousEditProps {
  className?: string
  processItem?: ProcessItem
  onCurrentIndexUpdate(hoverIndex?: number): void
}

// Assumes that items can only be consumed in order.
const ProcessItemContiguousEdit = ({
  className,
  processItem,
  onCurrentIndexUpdate,
}: ProcessItemContiguousEditProps) => {
  const EDITABLE_PROCESS_ITEMS = new Set(['tiprack', 'reagent_plate'])

  const workcellStatus = useContext(WorkcellStatusContext)
  const allowFullColumnsOnly = getAllowFullColumnsOnly(workcellStatus)
  const [hoverIndex, setHoverIndex] = useState<number>()

  const handleClickCell = () => {
    if (hoverIndex === undefined) return
    if (allowFullColumnsOnly) {
      const numRows = get(['metadata', 'num_rows'], processItem)
      onCurrentIndexUpdate(hoverIndex - (hoverIndex % numRows))
    } else {
      onCurrentIndexUpdate(hoverIndex)
    }
  }
  const handleMouseOverCell = (row, col) => {
    const numRows = get(['metadata', 'num_rows'], processItem)
    setHoverIndex(row + numRows * col)
  }

  const handleMouseLeave = () => {
    setHoverIndex(undefined)
  }

  if (!processItem) return null

  const isFilled = (numRows: number) => {
    return (_row, col) => {
      const _index = _row + numRows * col
      const currentIndex = get(['state', 'currentIndex'], processItem)
      if (!isNumber(currentIndex)) return false
      return _index >= currentIndex
    }
  }
  const isHovered = (numRows: number) => {
    return (_row, col) => {
      const _index = _row + numRows * col
      return hoverIndex === _index
    }
  }

  const getPopoverContent = (rowIndex: number, colIndex: number): JSX.Element => {
    return (
      <div className={cs.popover}>
        {convertWellCoordsToWellName(rowIndex, colIndex)}
      </div>
    )
  }

  if (processItem.type && EDITABLE_PROCESS_ITEMS.has(processItem.type)) {
    const numRows = get(['metadata', 'num_rows'], processItem)
    const numCols = get(['metadata', 'num_cols'], processItem)
    const plateFormat = getPlateFormatFromDims({ numRows, numCols })
    return (
      <div className={cx(cs.processItemVizEditable, className)}>
        <TinyMicroplate
          plateFormat={plateFormat}
          highlights={[
            {
              color: 'accentLight',
              fn: isHovered(numRows),
            },
            {
              color: 'accent',
              fn: isFilled(numRows),
            },
          ]}
          onClickCell={handleClickCell}
          onMouseOverCell={handleMouseOverCell}
          onMouseLeave={handleMouseLeave}
          size='medium'
          getPopoverContent={getPopoverContent}
          hidePopoverArrow
          popoverModifiers={{
            offset: {
              enabled: true,
              options: {
                offset: [0, 5],
              },
            },
          }}
        />
        <MinimalButton
          className={cs.emptyButton}
          label='Set to empty'
          type='primary'
          onClick={() => onCurrentIndexUpdate(numRows * numCols)}
        />
      </div>
    )
  } else {
    return null
  }
}

export default ProcessItemContiguousEdit
