import { DesktopRestAPI } from '~/api/core'

export interface InstrumentDriver {
  instrumentName: string
  instrumentType: string
  isDataSource: boolean
  id: number
  isSimulated: boolean
  shouldAutostart: boolean
}

class InstrumentDriversAPI<ResourceType> extends DesktopRestAPI<ResourceType> {
  constructor(prefix: string | (() => string), resourceName: string, showToast = true) {
    super(prefix, resourceName, showToast)
  }

  createAndStart = params => {
    return instrumentDriverAPI.POST('create-and-start/', params)
  }

  deleteAndStop = params => {
    return instrumentDriverAPI.POST('delete-and-stop/', params)
  }
}

const instrumentDriverAPI = new InstrumentDriversAPI<InstrumentDriver>(
  '/api/instrument-drivers',
  'Instrument Driver',
  true,
)

export default instrumentDriverAPI
