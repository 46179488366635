import { flatten, fromPairs, map, range, times } from 'lodash/fp'
import { supportedPlateFormats } from '~/components/TinyMicroplate.interface'
import { DemoSourcePlate } from '~/demoControls/converters/demoPlate'
import { DemoSourceTimepoint } from '~/demoControls/converters/demoTimepoint'
import { DemoSourceWell } from '~/demoControls/converters/demoWell'
import { DemoSourceRecordsByKind } from '~/demoControls/demoData'
import {
  fittedGrowthCurveConstants,
  gaussianRandom,
  logisticFun,
} from '~/pages/slasDemo/events/data/generate/generateDataset'
import { convertWellCoordsToWellName, getPlateDims } from '~/utils/microplate'
import resolveSlasDemoAssetsPath from '~/utils/pathResolver'
import { CellLine, ClePlate, Well } from '../clePlates/ClePlate.interface'

// CELL LINE GENERATION

const PARENT_CELL_LINES = ['ACS-1020']

const DESIRED_EDITS = ['LDK1-KO']

const OWNERS = ['Dr. Isabella Morrow']

const generateCellLine = (cellLineIndex: number): CellLine => {
  const parentCellLine = PARENT_CELL_LINES[cellLineIndex % PARENT_CELL_LINES.length]
  const desiredEdit = DESIRED_EDITS[cellLineIndex % DESIRED_EDITS.length]
  return {
    parentCellLine,
    desiredEdit,
    cellLineIndex,
    name: `${parentCellLine}-${desiredEdit}`,
  }
}

// CONFLUENCY GENERATION

export const generateConfluencyForPlate = (
  phase: string,
  cellLineIndex: number,
): number => {
  return generateConfluencyForDataset(2)
}

export const generateConfluencyValues = (numSamples: number): number[] => {
  return [...times(() => generateConfluencyForDataset(2), numSamples)]
}
export function generateConfluencyForDataset(day: number): number {
  // Try a standard deviation of 300 minutes for lots of noise.
  try {
    const stdDevInDays = 300 / 60 / 24
    const imagingTime = day + gaussianRandom(0, stdDevInDays)
    return Number(logisticFun(fittedGrowthCurveConstants[0])(imagingTime).toFixed(1))
  } catch (e) {
    throw new Error(`Failed to get confluence for ${day}`)
  }
}

// IMAGE GENERATION

export const getImagePaths = (useLocalAssets: boolean) => {
  return [
    '726__20221025_145714_Bright%20Field_A3_1_001_thumbnail.png',
    '726__20221026_133235_Bright%20Field_A3_1_001_thumbnail.png',
    '726__20221027_132839_Bright%20Field_A3_1_001_thumbnail.png',
    '726__20221028_122614_Bright%20Field_A3_1_001_thumbnail.png',
    '726__20221029_152421_Bright%20Field_A3_1_001_thumbnail.png',
    '726__20221030_134054_Bright%20Field_A3_1_001_thumbnail.png',
  ].map(path => {
    return resolveSlasDemoAssetsPath(`${path}`, useLocalAssets)
  })
}

// PLATE GENERATION

const generateWellsForPlate = (
  plateFormat: supportedPlateFormats,
  phase: string,
  cellLineIndex: number,
): Record<string, Well> => {
  const { numRows, numCols } = getPlateDims(plateFormat)

  const pairs = flatten(
    map(
      row =>
        map(
          col => [
            convertWellCoordsToWellName(row, col),
            {
              confluency: generateConfluencyForPlate(phase, cellLineIndex),
            },
          ],
          range(0, numCols),
        ),
      range(0, numRows),
    ),
  )
  return fromPairs(pairs)
}

function makePlate(plate: Omit<ClePlate, 'id'>): ClePlate {
  return {
    ...plate,
    id: `${plate.cellLine.cellLineIndex}-${plate.phase}-${plate.plateNumber}`,
  }
}

const generateSeedingPlatesForEdit = (
  cellLineIndex: number,
  hasAlert: boolean,
): ClePlate[] => {
  return [
    makePlate({
      plateFormat: 'wells_96',
      hasAlert,
      cellLine: generateCellLine(cellLineIndex),
      phase: 'seeding',
      plateNumber: 1,
      wells: generateWellsForPlate('wells_96', 'seeding', cellLineIndex),
      owner: OWNERS[cellLineIndex % OWNERS.length],
    }),
  ]
}

const generate24wpExpansionPlatesForEdit = (
  cellLineIndex: number,
  hasAlert: boolean,
): ClePlate[] => {
  return map(
    plateNumber =>
      makePlate({
        plateFormat: 'wells_24',
        hasAlert,
        cellLine: generateCellLine(cellLineIndex),
        phase: '24wp_expansion',
        plateNumber,
        wells: generateWellsForPlate('wells_24', '24wp_expansion', cellLineIndex),
        owner: OWNERS[cellLineIndex % OWNERS.length],
      }),
    range(1, 4),
  )
}

const generate6wpExpansionPlatesForEdit = (cellLineIndex: number): ClePlate[] => {
  return map(
    plateNumber =>
      makePlate({
        plateFormat: 'wells_6',
        hasAlert: false,
        cellLine: generateCellLine(cellLineIndex),
        phase: '6wp_expansion',
        plateNumber,
        wells: generateWellsForPlate('wells_6', '6wp_expansion', cellLineIndex),
        owner: OWNERS[cellLineIndex % OWNERS.length],
      }),
    range(1, 3),
  )
}

const generateSeedingPlates = (numAlerts: number): ClePlate[] => {
  return flatten(
    range(0, 1).map((cellLineIndex, index) =>
      generateSeedingPlatesForEdit(cellLineIndex, index < numAlerts),
    ),
  )
}

const generate24wpExpansionPlates = (numAlerts: number): ClePlate[] => {
  return flatten(
    range(1, 2).map((cellLineIndex, index) =>
      generate24wpExpansionPlatesForEdit(cellLineIndex, index < numAlerts),
    ),
  )
}

const generate6wpExpansionPlates = (): ClePlate[] => {
  return flatten(
    map(cellLineIndex => generate6wpExpansionPlatesForEdit(cellLineIndex), range(4, 5)),
  )
}

export const generateClePipelinePlates = (): ClePlate[] => {
  return [
    ...generateSeedingPlates(0),
    ...generate24wpExpansionPlates(1),
    ...generate6wpExpansionPlates(),
  ]
}

export const CLE_PLATES = generateClePipelinePlates()

// CULTURE LINEAGE DATA
export const cultureLineageData = [
  {
    id: 'culture-123',
    well: 'A1',
    observationHistory: [
      {
        timestamp: '2024-01-15T10:00:00Z',
        confluence: 30,
        montage: {
          id: 'montage-1',
          culture: { id: 'culture-123' },
          images: [
            {
              imageUrl:
                'https://monomer-external-assets-public.s3.us-west-2.amazonaws.com/image_fixtures/2000/bbbc/BBBC019v2/HEK293/Yaniv__dic_L1_Sum001.jpg',
              montageIndex: 0,
            },
          ],
        },
      },
      {
        timestamp: '2024-01-16T10:00:00Z',
        confluence: 50,
        montage: {
          id: 'montage-1',
          culture: { id: 'culture-123' },
          images: [
            {
              imageUrl:
                'https://monomer-external-assets-public.s3.us-west-2.amazonaws.com/image_fixtures/2000/bbbc/BBBC019v2/HEK293/Yaniv__dic_L5_Sum010.jpg',
              montageIndex: 0,
            },
          ],
        },
      },
    ],
    culturePlate: {
      id: 'plate-456',
      plateDimensions: {
        rows: 4,
        columns: 6,
      },
      firstCheckedInAt: '2024-01-15T00:00:00Z',
      plateObservationHistory: [
        {
          id: 'obs-1-1',
          timestamp: '2024-01-15T10:00:00Z',
          sprite: {
            id: 'sprite-2',
            imageUrl:
              'https://monomer-external-assets-public.s3.us-west-2.amazonaws.com/image_fixtures/2000/bbbc/BBBC019v2/HEK293/Yaniv__dic_L1_Sum001.jpg',
            montageLayout: 'SQUARE_2X2',
            wellSequence: ['A1', 'A2', 'A3', 'B1', 'B2', 'B3'],
          },
        },
        {
          id: 'obs-1-2',
          timestamp: '2024-01-16T10:00:00Z',
          sprite: {
            id: 'sprite-3',
            imageUrl:
              'https://monomer-external-assets-public.s3.us-west-2.amazonaws.com/image_fixtures/2000/bbbc/BBBC019v2/HEK293/Yaniv__dic_L5_Sum010.jpg',
            montageLayout: 'SQUARE_2X2',
            wellSequence: ['A1', 'A2', 'A3', 'B1', 'B2', 'B3'],
          },
        },
      ],
    },
  },
  {
    id: 'culture-246',
    well: 'A1',
    observationHistory: [
      {
        timestamp: '2024-01-17T10:00:00Z',
        confluence: 90,
        montage: {
          id: 'montage-3',
          culture: { id: 'culture-246' },
          images: [
            {
              imageUrl:
                'https://monomer-external-assets-public.s3.us-west-2.amazonaws.com/image_fixtures/2000/bbbc/BBBC019v2/HEK293/Yaniv__dic_L7_Sum020.jpg',
              montageIndex: 0,
            },
          ],
        },
      },
    ],
    culturePlate: {
      id: 'plate-567',
      plateDimensions: {
        rows: 2,
        columns: 3,
      },
      firstCheckedInAt: '2024-01-17T00:00:00Z',
      plateObservationHistory: [
        {
          id: 'obs-2-1',
          timestamp: '2024-01-17T10:00:00Z',
          sprite: {
            id: 'sprite-3',
            imageUrl:
              'https://monomer-external-assets-public.s3.us-west-2.amazonaws.com/image_fixtures/2000/bbbc/BBBC019v2/HEK293/Yaniv__dic_L7_Sum020.jpg',
            montageLayout: 'SQUARE_4X4',
            wellSequence: ['A1', 'A2', 'A3', 'B1', 'B2', 'B3'],
          },
        },
      ],
    },
  },
]

export const generatedDemoPlates: DemoSourcePlate[] = []
export const generatedDemoWells: DemoSourceWell[] = []
export const generatedDemoTimepoints: DemoSourceTimepoint[] = []

export const demoInitialData: DemoSourceRecordsByKind = {
  plates: generatedDemoPlates,
  wells: generatedDemoWells,
  timepoints: generatedDemoTimepoints,
}
