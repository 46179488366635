import { sortBy } from 'lodash/fp'
import { useContext } from 'react'

import workcellAPI from '~/api/desktop/workcell'
import { useFetchData } from '~/api/useFetchData'
import LoadingMessage from '~/components/LoadingMessage'
import TextWithOverflow from '~/components/TextWithOverflow'
import { getComparatorString } from '~/utils/array'
import { getInstrumentImage } from '~/utils/instrument'
import { getURLParams } from '~/utils/url'

import WorkcellStatusContext from '../WorkcellStatusContext'
import { ProcessStep } from '../types/ProcessStep.interface'
import cs from './execution_status_box.scss'

function useSteps() {
  const workcellStatus = useContext(WorkcellStatusContext)
  const { step_uuids_in_progress, step_uuids_scheduled, step_uuids_cancelled } =
    // TODO: Correct type for WorkcellStatus.state.
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (workcellStatus?.state as any)?.step_state ?? {}

  const { isLoading, data, error } = useFetchData(async () => {
    return await workcellAPI.getLiveSteps()
  }, [
    getComparatorString([
      step_uuids_in_progress,
      step_uuids_scheduled,
      step_uuids_cancelled,
    ]),
  ])
  if (isLoading && data == null) {
    return null
  }
  if (error) {
    console.error(error)
    return null
  }

  const steps = data?.steps ?? []
  const queuedSteps = steps.filter(step => step.status !== 'canceled')
  const scientificSteps = sortBy(
    step => step.startTime,
    queuedSteps.filter(step => step.routineType === 'default'),
  )

  return { queuedSteps, scientificSteps }
}

export default function ExecutionStatusBox() {
  const steps = useSteps()
  if (!steps) {
    return null
  }

  const { queuedSteps, scientificSteps } = steps
  const currentStep = scientificSteps[0]

  if (!getURLParams().get('enableExecutionStatusBox')) {
    return null
  }

  if (currentStep == null && queuedSteps.length > 0) {
    // TODO: Decide on design and text for this state.
    return <div>'Cleaning up...'</div>
  }

  const instrumentImage = currentStep ? (
    <img
      className={cs.instrumentImage}
      src={getInstrumentImage({
        instrumentName: currentStep.instrumentName,
        instrumentType: currentStep.instrumentType,
      })}
      alt={`${currentStep.instrumentType}`}
    />
  ) : null

  return (
    <div className={cs.box}>
      {instrumentImage}

      <div className={cs.info}>
        <div className={cs.routineHeading}>
          <TextWithOverflow
            className={cs.routineName}
            text={currentStep?.routineName}
            popoverContent={currentStep?.routineName}
          />
          {/*
            TODO: Compute count of remaining scientific routines by finding
            unique routine IDs in steps array. (Need to add routine IDs to
            the live steps API response.)

            <div className={cs.upcomingRoutinesCount}>+ 4 more</div>
          */}
        </div>

        <TextWithOverflow
          className={cs.currentStep}
          text={currentStep?.stepDisplay}
          popoverContent={currentStep?.stepDisplay}
        />

        <StatusIndicator step={currentStep} />
      </div>
    </div>
  )
}

function StatusIndicator({ step }: { step: ProcessStep | null }) {
  if (step == null) {
    return null
  }

  if (step.status === 'in_progress') {
    return <LoadingMessage label='Working...' type='accent' className={cs.progress} />
  }

  if (step.status === 'scheduled') {
    return <span className={cs.scheduledIndicator}>Scheduled</span>
  }

  return null
}
