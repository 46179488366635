import { Classes, Popover2 } from '@blueprintjs/popover2'
import cx from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import { useState } from 'react'
import { Link } from 'react-router-dom'

import Pill from '~/components/Pill'
import IntegrationIcon from '~/components/icons/IntegrationIcon'
import KeyIcon from '~/components/icons/KeyIcon'
import LogoutIcon from '~/components/icons/LogoutIcon'
import TeamIcon from '~/components/icons/TeamIcon'

import { useAuth0 } from '@auth0/auth0-react'
import { MultiFrontendContext, getUserIsAdmin } from './MultiFrontendContext'
import cs from './user_menu.scss'

interface UserMenuProps {
  className?: string
  frontendContext: MultiFrontendContext
}

function UserMenu(props: UserMenuProps) {
  const { className, frontendContext } = props

  const [open, setOpen] = useState(false)

  const orgName = frontendContext.userMetadata.orgDisplayName
  const picture = frontendContext.userMetadata.picture
  const name = frontendContext.userMetadata.name
  const isAdmin = getUserIsAdmin(frontendContext.userMetadata)

  let logoutComponent: React.ReactElement
  if (props.frontendContext.useCoreAuth) {
    logoutComponent = (
      <a href='/logout'>
        <div className={cx(cs.option, Classes.POPOVER2_DISMISS)}>
          <LogoutIcon className={cs.optionIcon} />
          Log Out
        </div>
      </a>
    )
  } else {
    const { logout } = useAuth0()
    logoutComponent = (
      <a onClick={() => logout({ logoutParams: { returnTo: window.location.origin } })}>
        <div className={cx(cs.option, Classes.POPOVER2_DISMISS)}>
          <LogoutIcon className={cs.optionIcon} />
          Log Out
        </div>
      </a>
    )
  }

  const renderUserMenu = () => (
    <div className={cs.content}>
      <div className={cs.userProfile}>
        <div className={cs.userBigImageContainer}>
          {picture && (
            <img className={cs.userBigImage} src={picture} alt='User profile' />
          )}
        </div>
        <div className={cs.text}>
          <div className={cs.name}>{name}</div>
          <div className={cs.orgContainer}>
            <div className={cs.org}>{orgName}</div>
            {
              <Pill
                type={isAdmin ? 'accent' : 'info'}
                label={isAdmin ? 'Admin' : 'User'}
                small
                className={cs.role}
              />
            }
          </div>
        </div>
      </div>
      {orgName && frontendContext.useCoreAuth && (
        <Link to='/team'>
          <div className={cx(cs.option, Classes.POPOVER2_DISMISS)}>
            <TeamIcon className={cs.optionIcon} />
            Team
          </div>
        </Link>
      )}
      {orgName && (
        <Link to='/integrations'>
          <div className={cx(cs.option, Classes.POPOVER2_DISMISS)}>
            <IntegrationIcon className={cs.optionIcon} />
            Integrations
          </div>
        </Link>
      )}
      {orgName && frontendContext.useCoreAuth && (
        <Link to='/auth-tokens'>
          <div className={cx(cs.option, Classes.POPOVER2_DISMISS)}>
            <KeyIcon className={cs.optionIcon} />
            API Auth Tokens
          </div>
        </Link>
      )}
      {logoutComponent}
    </div>
  )

  const renderTarget = ({ ...targetProps }) => (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <div {...targetProps} className={cs.userMenuContainer}>
      {picture && <img className={cs.userImage} src={picture} alt='User profile' />}
      <div className={cs.userName}>{name}</div>
    </div>
  )

  return (
    <Popover2
      content={renderUserMenu()}
      interactionKind='click'
      isOpen={open}
      onInteraction={setOpen}
      placement='top-end'
      modifiers={{
        offset: {
          enabled: true,
          options: {
            offset: [0, 10],
          },
        },
      }}
      usePortal
      fill
      minimal
      renderTarget={renderTarget}
      className={cx(cs.menu, className)}
    />
  )
}

UserMenu.propTypes = {
  className: PropTypes.string,
}

export default UserMenu
