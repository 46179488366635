import cx from 'classnames'
import { identity, keys, pickBy, size } from 'lodash/fp'
import PropTypes from 'prop-types'
import { useContext, useEffect, useState } from 'react'

import futureRoutinesAPI from '~/api/desktop/futureRoutines'
import Button from '~/components/buttons/Button'
import {
  areStepsScheduled,
  getFutureRoutinesLastUpdateTime,
} from '~/pages/Workcell/utils/workcellStatus'
import { displayCount } from '~/utils/string'

import { FutureRoutine } from '~/api/desktop/future_routine_schemas.interface'
import { useTaskExecutor } from '~/api/useTaskExecutor'
import MinimalButton from '~/components/buttons/MinimalButton'
import CloseIcon from '~/components/icons/CloseIcon'
import MicroplateIcon from '~/components/icons/MicroplateIcon'
import TinyNotification from '~/components/notifications/TinyNotification'
import { useIsMounted } from '~/utils/hooks/useIsMounted'
import WorkcellStatusContext from '../../WorkcellStatusContext'
import EditFutureRoutineSchedulePopover from './EditFutureRoutineSchedulePopover'
import FutureRoutineTable from './FutureRoutineTable/FutureRoutineTable'
import FutureRoutinesConsumablePlannerDialog from './FutureRoutinesConsumablePlannerDialog'
import FutureRoutinesHeader from './FutureRoutinesHeader'
import {
  QueueFutureRoutinesResult,
  cancelFutureRoutines,
  queueFutureRoutines,
} from './futureRoutineActions'
import cs from './future_routines.scss'
import {
  FutureRoutineDisplayMode,
  getFilteredFutureRoutines,
} from './getFilteredFutureRoutines'

interface FutureRoutinesProps {
  className?: string
}

const FutureRoutines = ({ className }: FutureRoutinesProps) => {
  const isMounted = useIsMounted()
  const workcellStatus = useContext(WorkcellStatusContext)
  const [displayMode, setDisplayMode] = useState<FutureRoutineDisplayMode>('by_day')
  const [
    futureRoutineConsumablePlannerDialogOpen,
    setFutureRoutineConsumablePlannerDialogOpen,
  ] = useState(false)
  const [date, setDate] = useState(new Date())
  const [routines, setRoutines] = useState<FutureRoutine[]>([])
  const [loading, setLoading] = useState(false)
  const [selectedRowUuids, setSelectedRowUuids] = useState({})
  const {
    isExecuting: isExecutingQueue,
    response: queueResponse,
    submitTask,
  } = useTaskExecutor<QueueFutureRoutinesResult>()

  const stepsScheduled = areStepsScheduled(workcellStatus)

  const refreshFutureRoutines = () => {
    setLoading(true)
    futureRoutinesAPI.listFutureRoutines().then(_routines => {
      if (!isMounted()) return
      setRoutines(_routines)
      setLoading(false)
    })
    setLoading(false)
  }

  const submit = () => {
    queueFutureRoutines(selectedRowUuidsArr, submitTask, refreshFutureRoutines)
  }

  useEffect(() => {
    refreshFutureRoutines()
  }, [getFutureRoutinesLastUpdateTime(workcellStatus)])

  const handleClearRoutine = async (routine: FutureRoutine) => {
    cancelFutureRoutines([routine.uuid], refreshFutureRoutines)
  }

  const cancel = () => {
    cancelFutureRoutines(selectedRowUuidsArr, refreshFutureRoutines)
  }

  const selectedRowUuidsArr = keys(pickBy(identity, selectedRowUuids))

  const getButtonMessage = () => {
    if (size(selectedRowUuidsArr) === 0) {
      return 'Schedule Routines Now'
    }
    return `Schedule ${displayCount('Routine', size(selectedRowUuidsArr))} Now`
  }

  const renderNotification = () => {
    if (isExecutingQueue) {
      return (
        <TinyNotification
          className={cs.notification}
          type='loading'
          message={'Scheduling future routines...'}
        />
      )
    }
    if (queueResponse?.error) {
      return (
        <TinyNotification
          className={cs.notification}
          type='error'
          message={queueResponse?.error}
        />
      )
    }

    return null
  }

  const notification = renderNotification()

  const getSelectedRoutines = () => {
    return routines.filter(routine => {
      return selectedRowUuids[routine.uuid]
    })
  }

  return (
    <div className={cx(className, cs.futureRoutines)}>
      <FutureRoutinesHeader
        routines={routines}
        displayMode={displayMode}
        setDisplayMode={setDisplayMode}
        date={date}
        setDate={setDate}
        onUpdateSuccess={refreshFutureRoutines}
      />
      <FutureRoutineTable
        routines={getFilteredFutureRoutines(routines, displayMode, date)}
        className={cs.routineList}
        onRoutineCancel={handleClearRoutine}
        loading={loading}
        selectable
        selectedRoutineUuids={selectedRowUuids}
        onRoutineUuidsSelected={setSelectedRowUuids}
        disabled={isExecutingQueue}
        displayScheduledForDate={displayMode === 'all_routines'}
      />
      <div className={cx(className, cs.footer)}>
        {notification}
        <div className={cx(cs.controls, notification && cs.withNotification)}>
          <Button
            type='primary'
            className={cs.button}
            label={getButtonMessage()}
            disabled={
              isExecutingQueue || size(selectedRowUuidsArr) === 0 || stepsScheduled
            }
            onClick={submit}
            popoverMessageIfDisabled={
              stepsScheduled
                ? 'There are already routines queued. Consider setting your future routines to automatically execute with Edit Schedule.'
                : undefined
            }
          />
          <MinimalButton
            type='danger'
            className={cs.cancelButton}
            label='Cancel Routines'
            disabled={isExecutingQueue || size(selectedRowUuidsArr) === 0}
            onClick={cancel}
            IconComponent={CloseIcon}
            variant='normal'
            appearNormalIfNotHovered
          />
          <EditFutureRoutineSchedulePopover
            disabled={isExecutingQueue || size(selectedRowUuidsArr) === 0}
            className={cs.editSchedulePopover}
            editExistingOrSetNew='edit_existing'
            selectedRoutines={getSelectedRoutines()}
            onEditSuccess={refreshFutureRoutines}
          />
          <div className={cs.fill} />
          <MinimalButton
            type='normal'
            className={cs.cancelButton}
            label='Plan Consumables For Upcoming Routines...'
            onClick={() => setFutureRoutineConsumablePlannerDialogOpen(true)}
            IconComponent={MicroplateIcon}
            variant='normal'
          />
        </div>
      </div>
      <FutureRoutinesConsumablePlannerDialog
        isOpen={futureRoutineConsumablePlannerDialogOpen}
        onClose={() => setFutureRoutineConsumablePlannerDialogOpen(false)}
      />
    </div>
  )
}

FutureRoutines.propTypes = {
  className: PropTypes.string,
}

export default FutureRoutines
