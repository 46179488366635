import { LOAD_UNLOAD_ITEMS_EMPTY_ASSAY_PLATE_CONFIG } from '~/pages/Workcell/OperatorActions/LoadUnloadItems/processItemTypeConfig/loadUnloadItemsEmptyAssayPlateConfig'
import {
  LoadUnloadItemCulturePlateParams,
  LoadUnloadItemEmptyAssayPlateParams,
  LoadUnloadItemEmptyCulturePlateParams,
  LoadUnloadItemExperimentPlateParams,
  LoadUnloadItemTiprackParams,
  LoadUnloadItemsReagentPlateParams,
} from '../LoadUnloadItemsProcessItemParams.interface'
import { LoadUnloadItemsProcessItemType } from '../LoadUnloadItemsProcessItemType.interface'
import { ProcessItemTypeConfig } from './ProcessItemTypeConfig.interface'
import { LOAD_UNLOAD_ITEMS_CULTURE_PLATE_CONFIG } from './loadUnloadItemsCulturePlateConfig'
import { LOAD_UNLOAD_ITEMS_EMPTY_CULTURE_PLATE_CONFIG } from './loadUnloadItemsEmptyCulturePlateConfig'
import { LOAD_UNLOAD_ITEMS_EXPERIMENT_PLATE_CONFIG } from './loadUnloadItemsExperimentPlateConfig'
import { LOAD_UNLOAD_ITEMS_REAGENT_PLATE_CONFIG } from './loadUnloadItemsReagentPlateConfig'
import { LOAD_UNLOAD_ITEMS_TIPRACK_CONFIG } from './loadUnloadItemsTiprackConfig'

// NOTE: When adding a new process item type, also update
// LoadUnloadItemsProcessItemParams.interface.tsx
// LoadUnloadItemsProcessItemType.interface.tsx
// LoadParamsRequestOrError.interface.tsx

export type PROCESS_ITEM_TYPE_TO_PARAMS_TYPE = {
  reagent_plate: LoadUnloadItemsReagentPlateParams
  tiprack: LoadUnloadItemTiprackParams
  empty_assay_plate: LoadUnloadItemEmptyAssayPlateParams
  experiment_plate: LoadUnloadItemExperimentPlateParams
  empty_culture_plate: LoadUnloadItemEmptyCulturePlateParams
  culture_plate: LoadUnloadItemCulturePlateParams
}

// TODO: Conside refactoring out e.g. AllProcessItemTypes = keyof PROCESS_ITEM_TYPE_TO_PARAMS_TYPE
export type ProcessItemParamsType<TType> =
  TType extends keyof PROCESS_ITEM_TYPE_TO_PARAMS_TYPE
    ? PROCESS_ITEM_TYPE_TO_PARAMS_TYPE[TType]
    : never

export type ProcessItemConfigType<TType> =
  TType extends keyof PROCESS_ITEM_TYPE_TO_PARAMS_TYPE
    ? ProcessItemTypeConfig<TType>
    : never

const LOAD_UNLOAD_ITEM_PROCESS_ITEM_CONFIG: {
  [TType in keyof PROCESS_ITEM_TYPE_TO_PARAMS_TYPE]: ProcessItemConfigType<TType>
} = {
  tiprack: LOAD_UNLOAD_ITEMS_TIPRACK_CONFIG,
  reagent_plate: LOAD_UNLOAD_ITEMS_REAGENT_PLATE_CONFIG,
  empty_assay_plate: LOAD_UNLOAD_ITEMS_EMPTY_ASSAY_PLATE_CONFIG,
  experiment_plate: LOAD_UNLOAD_ITEMS_EXPERIMENT_PLATE_CONFIG,
  empty_culture_plate: LOAD_UNLOAD_ITEMS_EMPTY_CULTURE_PLATE_CONFIG,
  culture_plate: LOAD_UNLOAD_ITEMS_CULTURE_PLATE_CONFIG,
}

export const getProcessItemTypeConfig = <TType extends LoadUnloadItemsProcessItemType>(
  processItemType: TType,
): ProcessItemConfigType<TType> => {
  if (processItemType in LOAD_UNLOAD_ITEM_PROCESS_ITEM_CONFIG) {
    return LOAD_UNLOAD_ITEM_PROCESS_ITEM_CONFIG[processItemType]
  }
  throw new Error(`No process item type config found for ${processItemType}`)
}
