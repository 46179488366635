import { all, get, some } from 'lodash/fp'

import { toExponentialString } from '~/utils/number'

export const hasField = (key, data) => {
  const value = get(key, data)
  return value !== undefined && value !== null && value !== ''
}

export const missingAnyFields = (keys, data) => {
  return some(key => !hasField(key, data), keys)
}

export const hasAllFields = (keys, data) => {
  return all(key => hasField(key, data), keys)
}

export const parseUnitFloatValue = (unitFloat: string): number => {
  const tokens = unitFloat.split(' ')
  return parseFloat(tokens[0])
}

export const serializeUnitFloatValue = (value, unit): string => {
  return `${value} ${unit}`
}

export const getUnitFloatDisplay = (
  unitFloatString: string | undefined | null,
): string | null => {
  if (
    unitFloatString === '' ||
    unitFloatString === undefined ||
    unitFloatString === null
  ) {
    return null
  }
  const tokens = unitFloatString.split(' ')
  return `${toExponentialString(parseFloat(tokens[0]))} ${tokens[1]}`
}
