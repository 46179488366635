import cx from 'classnames'
import { compact, get } from 'lodash/fp'
import { useState } from 'react'

import PopupMenu from '~/components/PopupMenu'
import DriverConfigDialog from '../components/DriverConfigDialog'

import { Instrument, InstrumentStatus } from '~/common.interface'
import InstrumentHeader from '../components/instrument/InstrumentHeader'
import cs from './generic_instrument_header.scss'

interface GenericInstrumentHeaderProps {
  className?: string
  instrument: Instrument
  status: InstrumentStatus
  isExecutingControlCommand?: boolean
  executeCommand(...args: unknown[]): unknown
  startDriver(...args: unknown[]): unknown
  stopDriver(...args: unknown[]): unknown
  restartDriver(...args: unknown[]): unknown
  hideInstrumentStatus?: boolean
  additionalStatusText?: string | JSX.Element
  onConfigUpdated?: () => void
}

const GenericInstrumentHeader = ({
  className,
  instrument,
  status,
  isExecutingControlCommand,
  executeCommand,
  startDriver,
  stopDriver,
  restartDriver,
  hideInstrumentStatus,
  additionalStatusText,
  onConfigUpdated,
}: GenericInstrumentHeaderProps) => {
  const [configDialogOpen, setConfigDialogOpen] = useState(false)
  const instrumentStatus = get('execution_state', status)
  const driverState = get('driver_state', status)

  const isStopped = driverState === 'STOPPED'
  const isSimulated = get('is_simulated', status)
  const isBusy =
    isExecutingControlCommand ||
    instrumentStatus === 'BUSY' ||
    instrumentStatus === null

  const renderSimulatedOption = () => {
    const simulatedOption = {
      disabled: isBusy || isStopped,
      label: isSimulated ? 'Turn off Simulated' : 'Turn on Simulated',
      action: () => executeCommand('set_simulated', isSimulated ? '[false]' : '[true]'),
    }
    return simulatedOption
  }
  const options = compact([
    ...[
      isStopped && {
        disabled: isBusy,
        label: 'Start Driver',
        action: () => startDriver(),
      },
    ],
    {
      disabled: isBusy || isStopped,
      label: 'Configure Driver...',
      action: () => setConfigDialogOpen(true),
    },
    ...[
      !isStopped && {
        disabled: isBusy || isStopped,
        label: 'Stop Driver',
        action: () => stopDriver(),
      },
    ],
    ...[
      !isStopped && {
        disabled: isBusy || isStopped,
        label: 'Restart Driver',
        action: () => restartDriver(),
      },
    ],
    {
      disabled: isBusy || isStopped,
      label: 'Refresh Item Cache',
      action: () => executeCommand('refresh_item_cache_command'),
    },
    {
      disabled: isBusy || isStopped,
      label: 'Reconnect',
      action: () => executeCommand('reconnect'),
    },
    {
      disabled: isBusy || isStopped,
      label: 'Acknowledge Fault',
      action: () => executeCommand('acknowledge_fault'),
    },
    renderSimulatedOption(),
  ])

  return (
    <div className={cx(className, cs.genericInstrumentHeader)}>
      <div className={cs.headerInner}>
        <InstrumentHeader
          instrument={instrument}
          instrumentStatus={status}
          big
          isExecutingControlCommand={isExecutingControlCommand}
          additionalStatusText={additionalStatusText}
          hideInstrumentStatus={hideInstrumentStatus}
        />
        <PopupMenu options={options} className={cs.menu} />
      </div>
      <DriverConfigDialog
        instrumentName={instrument.instrumentName}
        instrumentType={instrument.instrumentType}
        isOpen={configDialogOpen}
        onClose={() => setConfigDialogOpen(false)}
        onConfigUpdated={onConfigUpdated}
      />
    </div>
  )
}

export default GenericInstrumentHeader
