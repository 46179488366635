import cx from 'classnames'

import Button from '~/components/buttons/Button'
import TinyNotification from '~/components/notifications/TinyNotification'
import { SequenceValidationRunParamsOrError } from './SequenceValidationRunParamsOrError.interface'
import cs from './validate_panel_footer.scss'

export interface ValidatePanelFooterProps {
  className?: string
  sequenceValidationParamsOrError: SequenceValidationRunParamsOrError | null
  isTeachToolBusy: boolean
  onSubmit: () => void
}

interface Message {
  message: string
  type: 'bareSuccess' | 'bareError'
}

const getMessage = (
  sequenceValidationParamsOrError: SequenceValidationRunParamsOrError | null,
): Message | null => {
  if (!sequenceValidationParamsOrError) return null
  if (sequenceValidationParamsOrError.error) {
    return {
      message: sequenceValidationParamsOrError.error,
      type: 'bareError',
    }
  }
  return {
    message:
      'Please ensure a test plate has been placed on the appropriate nest ' +
      'and the robotic arm is in a safe location before starting the runs.',
    type: 'bareSuccess',
  }
}

const ValidatePanelFooter = ({
  className,
  sequenceValidationParamsOrError,
  isTeachToolBusy,
  onSubmit,
}: ValidatePanelFooterProps) => {
  const message = getMessage(sequenceValidationParamsOrError)

  return (
    <div className={cx(className, cs.validatePanelFooter)}>
      {message && (
        <TinyNotification
          className={cs.caption}
          type={message.type}
          message={message.message}
        />
      )}
      <Button
        type='primary'
        className={cs.button}
        label='Run Sequence Validation'
        disabled={
          !sequenceValidationParamsOrError ||
          !!sequenceValidationParamsOrError.error ||
          isTeachToolBusy
        }
        onClick={onSubmit}
      />
    </div>
  )
}

export default ValidatePanelFooter
