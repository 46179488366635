import cx from 'classnames'
import PropTypes from 'prop-types'

import Select from '~/components/Select'
import { OPERATOR_ACTION_ICONS, OperatorAction } from '~/utils/operatorAction'

import cs from './operator_action_select.scss'

const OperatorActionSelect = ({
  className,
  operatorActions,
  onOperatorActionSelect,
}) => {
  const renderInstrument = (action: OperatorAction) => {
    return (
      <div className={cs.action}>
        <div className={cs.iconContainer}>
          {'icon' in action && (
            <img
              className={cs.icon}
              src={OPERATOR_ACTION_ICONS[action.icon]}
              alt={action.name}
            />
          )}
        </div>
        <div className={cs.name}>{action.key}</div>
      </div>
    )
  }
  return (
    <Select<OperatorAction>
      activeItem={null}
      items={operatorActions}
      itemKey='key'
      itemLabelKey='key'
      onChange={onOperatorActionSelect}
      triggerClassName={cs.trigger}
      popoverClassName={cs.popover}
      className={cx(cs.operatorActionSelect, className)}
      itemRenderer={renderInstrument}
      placeholder='Add an Operator Action...'
    />
  )
}

OperatorActionSelect.propTypes = {
  className: PropTypes.string,
  operatorActions: PropTypes.arrayOf(
    PropTypes.shape({
      instrumentName: PropTypes.string,
      instrumentType: PropTypes.string,
    }),
  ),
  onOperatorActionSelect: PropTypes.func,
}

export default OperatorActionSelect
