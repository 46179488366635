import cx from 'classnames'
import PropTypes from 'prop-types'

import cs from './button_group.scss'

interface ButtonGroupChoice {
  label: string
  value: string
}

interface ButtonGroupProps {
  className?: string
  choiceClassName?: string
  choices: ButtonGroupChoice[]
  activeChoice: string
  onChange: (value: string) => void
  disabled?: boolean
}

const ButtonGroup = ({
  className,
  choices,
  onChange,
  activeChoice,
  choiceClassName,
  disabled,
}: ButtonGroupProps) => {
  if (!choices) return null
  return (
    <div className={cx(className, cs.buttonGroup, disabled && cs.disabled)}>
      {choices.map(choice => (
        <div
          key={choice.value}
          className={cx(
            cs.choice,
            activeChoice === choice.value && cs.active,
            choiceClassName,
          )}
          onClick={() => !disabled && onChange(choice.value)}
        >
          {choice.label}
        </div>
      ))}
    </div>
  )
}

ButtonGroup.propTypes = {
  className: PropTypes.string,
  choices: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    }),
  ),
  // Returns the value
  activeChoice: PropTypes.string,
  onChange: PropTypes.func,
}

export default ButtonGroup
