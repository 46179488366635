/*
  Data is an array of numbers, which the Histogram places into bins before displaying.
*/

import PropTypes from 'prop-types'

import D3ChartWrapper from '~/components/d3/D3ChartWrapper'

import { TooltipParams } from '../ChartTooltip'
import { D3ChartAxisOptions, D3ChartLayoutOptions } from '../d3/D3Chart'
import D3Histogram, { D3HistogramData, D3HistogramOptions } from './D3Histogram'

interface HistogramProps {
  className?: string
  data: D3HistogramData
  options?: Partial<D3HistogramOptions>
  layoutOptions: Partial<D3ChartLayoutOptions>
  axisOptions: Partial<D3ChartAxisOptions<number, number>>
  renderTooltipContents?: (tooltipParams: TooltipParams) => void
}

const Histogram = ({
  data,
  options,
  className,
  renderTooltipContents,
  axisOptions,
  layoutOptions,
}: HistogramProps) => {
  return (
    <D3ChartWrapper<D3HistogramData, number, number, D3HistogramOptions>
      D3ChartClass={D3Histogram}
      className={className}
      data={data}
      layoutOptions={layoutOptions}
      axisOptions={axisOptions}
      options={options}
      renderTooltipContents={renderTooltipContents}
      chartTooltipTriggerFn='onBarClick'
    />
  )
}

Histogram.propTypes = {
  className: PropTypes.string,
  data: PropTypes.arrayOf(PropTypes.number),
  options: PropTypes.objectOf(PropTypes.any),
  renderTooltipContents: PropTypes.func,
}

export default Histogram
