/*
  This input lets users type in a barcode and performs various validation.
  Such as checking whether the barcode is already in use.

  At the moment, for various process item types, the barcode is the process item uuid.
*/

import cx from 'classnames'
import { useCallback, useEffect, useState } from 'react'

import { debounce } from 'lodash/fp'
import processItemsAPI from '~/api/desktop/processItems'
import Input from '~/components/Input'
import TinyNotification from '~/components/notifications/TinyNotification'
import { snakeCaseToLowerCase } from '~/utils/string'
import { ExistingPlateOrError } from './ExistingPlateOrError.interface'
import { ValidationOptions } from './ValidationOptions.interface'
import cs from './plate_barcode_input.scss'
import { validateExistingProcessItem } from './validateExistingProcessItem'

export interface PlateBarcodeInputProps {
  className?: string
  inputClassName?: string
  onChange: (barcode: string) => void
  value?: string
  existingPlateOrError: ExistingPlateOrError
  onExistingPlateOrErrorUpdate: (existingPlateOrError: ExistingPlateOrError) => void
  disabled?: boolean
  validationOptions?: ValidationOptions
}

const PlateBarcodeInput = ({
  className,
  inputClassName,
  onChange,
  value,
  disabled,
  existingPlateOrError,
  onExistingPlateOrErrorUpdate,
  validationOptions = {},
}: PlateBarcodeInputProps) => {
  const [loading, setLoading] = useState(false)

  const verifyBarcodeAvailable = useCallback(
    debounce(500, async (_barcode, _onExistingPlateOrErrorUpdate) => {
      if (!_barcode) {
        _onExistingPlateOrErrorUpdate({
          existingPlate: undefined,
          error: undefined,
        })
        setLoading(false)
        return
      }

      if (_barcode.match(/[\\\\/]/)) {
        _onExistingPlateOrErrorUpdate({
          existingPlate: undefined,
          error: `Barcode '${_barcode}' cannot contain slashes`,
        })
        setLoading(false)
        return
      }

      let processItem = null

      try {
        const response = await processItemsAPI.getProcessItemByUserFacingId(_barcode)
        processItem = response.processItem
      } catch (e) {
        _onExistingPlateOrErrorUpdate({
          existingPlate: undefined,
          error: `Barcode '${_barcode}' is not valid`,
        })
      }

      _onExistingPlateOrErrorUpdate(
        validateExistingProcessItem(processItem, validationOptions),
      )
      setLoading(false)
    }),
    [],
  )

  useEffect(() => {
    setLoading(true)
    verifyBarcodeAvailable(value, onExistingPlateOrErrorUpdate)
  }, [value])

  const renderNotification = () => {
    if (!value) return null

    if (loading) {
      return (
        <TinyNotification
          type='loading'
          message={`Checking barcode ${value}`}
          className={cs.message}
        />
      )
    }

    if (existingPlateOrError.error) {
      return (
        <TinyNotification
          type='bareError'
          message={existingPlateOrError.error}
          className={cs.message}
        />
      )
    }
    if (existingPlateOrError.existingPlate) {
      return (
        <TinyNotification
          type='bareSuccess'
          message={`Existing ${snakeCaseToLowerCase(
            validationOptions.processItemType || 'plate',
          )} '${existingPlateOrError.existingPlate.uuid}' found`}
          className={cs.message}
        />
      )
    }
    return (
      <TinyNotification
        type='bareSuccess'
        message={`Barcode '${value}' is available`}
        className={cs.message}
      />
    )
  }

  return (
    <div className={cx(cs.plateBarcodeInput, className)}>
      <Input
        label='Barcode'
        inputClassName={cx(cs.uuidInput, inputClassName)}
        value={value}
        changeOnBlur
        onChange={onChange}
        disabled={disabled}
      />
      {renderNotification()}
    </div>
  )
}

export default PlateBarcodeInput
