import PropTypes from 'prop-types'
import { IconProps } from './types/IconProps'
const BareWarningIcon = ({ className, onClick }: IconProps) => (
  <svg
    width='24'
    height='24'
    viewBox='0 0 24 24'
    xmlns='http://www.w3.org/2000/svg'
    className={className}
    onClick={onClick}
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M14 3H10V15H14V3ZM10 19C10 17.8954 10.8954 17 12 17C13.1046 17 14 17.8954 14 19C14 20.1046 13.1046 21 12 21C10.8954 21 10 20.1046 10 19Z'
    />
  </svg>
)

BareWarningIcon.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
}

export default BareWarningIcon
