import { uniqueId } from 'lodash/fp'
import { useState } from 'react'

export default function useReloadKey(
  initialValue = uniqueId('reloadKey'),
): [string, () => void] {
  const [reloadKey, setReloadKey] = useState(initialValue)

  const refreshReloadKey = () => {
    setReloadKey(uniqueId('reloadKey'))
  }

  return [reloadKey, refreshReloadKey]
}
