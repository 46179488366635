// Methods that are common to all instrument drivers.

import { DesktopAPI } from '~/api/core'
import { ProcessItem, StringOnlyProcessItem } from '~/common.interface'
import { client, unwrap } from '../desktopAPIv2'
import { components } from './generated-schema'

const commonDriverAPI = new DesktopAPI('/api/drivers/common')

export type DefaultSuccessResponse = components['schemas']['DefaultSuccessResponse']
export type GetDriverConfigResponse = components['schemas']['GetDriverConfigResponse']

export default {
  status: instrumentName => commonDriverAPI.get(`${instrumentName}/`),
  getState: instrumentName => commonDriverAPI.get(`${instrumentName}/state/`),
  setState: (instrumentName, newState, overwrite) =>
    commonDriverAPI.post(`${instrumentName}/state/`, {
      state: newState,
      overwrite,
    }),
  getScript: (instrumentName, scriptType) =>
    commonDriverAPI.get(`${instrumentName}/scripts/${scriptType}/`),
  setScript: (instrumentName, scriptType, script) =>
    commonDriverAPI.post(`${instrumentName}/scripts/${scriptType}/`, {
      code: script,
    }),
  getCommandList: instrumentName =>
    commonDriverAPI.get(`${instrumentName}/command-list/`),
  executeControlCommand: (instrumentName, command, params) =>
    commonDriverAPI.post(`${instrumentName}/execute-control-command/`, {
      command,
      params,
    }),
  start: instrumentName => commonDriverAPI.post(`${instrumentName}/start/`),
  stop: instrumentName => commonDriverAPI.post(`${instrumentName}/stop/`),
  restart: instrumentName => commonDriverAPI.post(`${instrumentName}/restart/`),
  getProcessItems: instrumentName =>
    commonDriverAPI.get(`${instrumentName}/process-items/`),
  getTransferStations: (
    instrumentName: string,
  ): Promise<Record<string, ProcessItem | StringOnlyProcessItem>> =>
    commonDriverAPI.get(`${instrumentName}/transfer-stations/`),
  getConfigV2: (instrumentName: string): Promise<GetDriverConfigResponse> =>
    unwrap(
      client().GET('/api/v2/drivers/common/{driver_name}/config', {
        params: {
          path: { driver_name: instrumentName },
        },
      }),
    ),
  setConfigV2: (
    instrumentName: string,
    newConfig,
    overwrite,
    waitUntilReady = false,
  ): Promise<DefaultSuccessResponse> =>
    unwrap(
      client().POST('/api/v2/drivers/common/{driver_name}/config', {
        params: {
          path: { driver_name: instrumentName },
        },
        body: {
          config: newConfig,
          overwrite,
          wait_until_ready: waitUntilReady,
        },
      }),
    ),

  getConfigSchemaV2: (instrumentName: string) =>
    unwrap(
      client().GET('/api/v2/drivers/common/{driver_name}/config-schema', {
        params: {
          path: { driver_name: instrumentName },
        },
      }),
    ),
}
