import cx from 'classnames'

import Link from '~/components/Link'
import { useFeatureFlag } from '~/core/featureFlags'

import { useDemoToggleState } from './DemoContext'
import cs from './demo_indicator.scss'

export default function DemoIndicator() {
  const featureEnabled = useFeatureFlag('demoControls')
  const [demoDataActive] = useDemoToggleState()

  if (!featureEnabled) {
    return null
  }

  return (
    <Link
      className={cx(cs.container, { [cs.active]: demoDataActive })}
      to='/demo-controls'
    >
      {demoDataActive ? 'Demo' : 'Demo Off'}
    </Link>
  )
}
