import { constant, times } from 'lodash/fp'
import { GenericScheduleRequest } from '~/api/operatorActions/genericSchedule'
import { datetimeToUTCIsoString } from '~/utils/date'
import { GenericScheduleFormState } from './GenericScheduleFormState.interface'

const _getRoutinesToSchedule = (formState: GenericScheduleFormState) => {
  if (formState.isQcRoutine) {
    return times(
      constant({
        name: formState.selectedRoutine.name,
        parameters: formState.routineParameters as Record<string, never>,
      }),
      formState.qcRepeats,
    )
  } else {
    if (formState.samplePlateBarcodeParameter) {
      return formState.samplePlateBarcodes.map(barcode => ({
        name: formState.selectedRoutine.name,
        parameters: {
          ...formState.routineParameters,
          [formState.samplePlateBarcodeParameter as string]: barcode,
        } as Record<string, never>,
      }))
    } else {
      return [
        {
          name: formState.selectedRoutine.name,
          parameters: formState.routineParameters as Record<string, never>,
        },
      ]
    }
  }
}

export const getGenericScheduleRequest = (
  formState: GenericScheduleFormState,
): GenericScheduleRequest => {
  const scheduleParams =
    formState.scheduleType === 'schedule_future'
      ? {
          schedule_type: formState.scheduleType,
          scheduled_for: datetimeToUTCIsoString(formState.scheduledFor as string),
          should_queue_automatically: formState.shouldQueueAutomatically,
        }
      : {
          schedule_type: formState.scheduleType,
        }

  return {
    routines: _getRoutinesToSchedule(formState),
    schedule_params: scheduleParams,
  }
}
