import cx from 'classnames'
import { flatten, get } from 'lodash/fp'
import { useEffect, useState } from 'react'
import { ProcessItem } from '~/common.interface'
import MicroplateWellSelect from '~/components/MicroplateWellSelect'
import {
  contentsToWellArray,
  convertWellIndexToWellName,
  getPlateFormatFromDims,
  getWellMatrix,
} from '~/utils/microplate'

interface ProcessItemMultiWellEditProps {
  className?: string
  processItem: ProcessItem
  onWellArrayUpdate(wellArray: string[]): void
}

const ProcessItemMultiWellEdit = ({
  className,
  processItem,
  onWellArrayUpdate,
}: ProcessItemMultiWellEditProps) => {
  const numRows = get(['metadata', 'num_rows'], processItem)
  const numCols = get(['metadata', 'num_cols'], processItem)
  const plateFormat = getPlateFormatFromDims({ numRows, numCols })
  const [selectedWellArray, setSelectedWellArray] = useState<string[]>()

  useEffect(() => {
    if (processItem.state?.contents) {
      const wellArray = contentsToWellArray(processItem.state.contents).map(idx =>
        convertWellIndexToWellName(idx, { numRows, numCols }),
      )
      setSelectedWellArray(wellArray)
    } else {
      setSelectedWellArray(flatten(getWellMatrix(plateFormat)))
    }
  }, [processItem])

  return (
    <div className={cx(className)}>
      <MicroplateWellSelect
        plateFormat={plateFormat}
        size='medium'
        selectedWellArray={selectedWellArray || []}
        onSelectedWellArrayUpdate={onWellArrayUpdate}
        showWellNames={false}
      />
    </div>
  )
}

export { ProcessItemMultiWellEdit }
