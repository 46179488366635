import { useState } from 'react'

import cs from './hitpicking_workflow.scss'

import { identity, keys, pickBy, size } from 'lodash/fp'
import { useHistory } from 'react-router'
import Toaster from '~/components/Toaster'
import Button from '~/components/buttons/Button'
import { displayCount } from '~/utils/string'
import { CleBackLink, ClePageFrame, ClePageHeader } from '../CleDemoUI/ClePageFrame'
import DoublingRateHistogram from '../charts/DoublingRateHistogram'
import KoScoreHistogram from '../charts/KoScoreHistogram'
import { getPlateAndWellToValues } from '../charts/hitpickingData'
import HitpickingDataTable from './HitpickingDataTable'
import HitpickingMicroplate from './HitpickingMicroplate'

export interface HitpickingWorkflowProps {
  cellLineName: string
  useLocalAssets: boolean
}
const HitpickingWorkflow = ({
  cellLineName,
  useLocalAssets,
}: HitpickingWorkflowProps) => {
  const history = useHistory()
  const plateAndWellToValues = getPlateAndWellToValues()
  const [selectedRowIds, setSelectedRowIds] = useState({})

  const handleClick = () => {
    const selectedRowIdsArr = keys(pickBy(identity, selectedRowIds))

    Toaster.show({
      message: `${displayCount(
        'hit',
        size(selectedRowIdsArr),
      )} submitted for ${cellLineName}`,
      intent: 'success',
    })
    history.push('/cell-line-engineering/pipeline/')
  }

  const selectedRowIdsArr = keys(pickBy(identity, selectedRowIds))

  return (
    <ClePageFrame>
      <ClePageHeader
        title='Pick the Best Hits'
        subtitle={`Cell line: ${cellLineName}`}
        backLink={
          <CleBackLink
            url='/cell-line-engineering/pipeline/'
            text='Back to Pipeline View'
          />
        }
      />
      <div className={cs.charts}>
        <div>
          <div className={cs.label}>KO Score</div>
          <KoScoreHistogram
            koScoreValues={Object.values(plateAndWellToValues).map(
              value => value.koScore,
            )}
          />
        </div>
        <div>
          <div className={cs.label}>Doubling Rate (Hrs)</div>
          <DoublingRateHistogram
            doublingRateValues={Object.values(plateAndWellToValues).map(
              value => value.doublingRate,
            )}
          />
        </div>
      </div>
      <div className={cs.plates}>
        <HitpickingMicroplate
          className={cs.plate}
          plateNumber={1}
          plateAndWellToValues={plateAndWellToValues}
          selectedRowIds={selectedRowIds}
          setSelectedRowIds={setSelectedRowIds}
          cellLineName={cellLineName}
          useLocalAssets={useLocalAssets}
        />
        <HitpickingMicroplate
          className={cs.plate}
          plateNumber={2}
          plateAndWellToValues={plateAndWellToValues}
          selectedRowIds={selectedRowIds}
          setSelectedRowIds={setSelectedRowIds}
          cellLineName={cellLineName}
          useLocalAssets={useLocalAssets}
        />
        <HitpickingMicroplate
          className={cs.plate}
          plateNumber={3}
          plateAndWellToValues={plateAndWellToValues}
          selectedRowIds={selectedRowIds}
          setSelectedRowIds={setSelectedRowIds}
          cellLineName={cellLineName}
          useLocalAssets={useLocalAssets}
        />
      </div>
      <div className={cs.bottom}>
        <div className={cs.selection}>
          <div className={cs.selectionText}>
            {displayCount('hit', size(selectedRowIdsArr))} selected
          </div>
          <div className={cs.fill} />
          <Button
            type='primary'
            label='Submit Hits'
            className={cs.button}
            onClick={handleClick}
          />
        </div>
        <HitpickingDataTable
          className={cs.dataTable}
          plateAndWellToValues={plateAndWellToValues}
          selectedRowIds={selectedRowIds}
          setSelectedRowIds={setSelectedRowIds}
        />
      </div>
    </ClePageFrame>
  )
}

export default HitpickingWorkflow
