import { filter, get } from 'lodash/fp'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'

import workcellAPI, { GetLiveStepsResponse } from '~/api/desktop/workcell'
import TextWithOverflow from '~/components/TextWithOverflow'
import Toaster from '~/components/Toaster'
import MinimalBoldButton from '~/components/buttons/MinimalBoldButton'
import MinimalButton from '~/components/buttons/MinimalButton'
import Notification from '~/components/notifications/Notification'
import CancelProcessStepDialog from '~/pages/Workcell/components/FailedProcessStepNotification/CancelProcessStepDialog'
import { showCancelProcessStepDialog } from '~/pages/Workcell/components/FailedProcessStepNotification/CancelProcessStepWarning'
import FailedProcessStepDialog from '~/pages/Workcell/components/FailedProcessStepNotification/FailedProcessStepDialog'
import RetryProcessStepDialog from '~/pages/Workcell/components/FailedProcessStepNotification/RetryProcessStepDialog'
import { showRetryProcessStepDialog } from '~/pages/Workcell/components/FailedProcessStepNotification/RetryProcessStepWarning'
import { getProcessStepDisplay } from '~/pages/Workcell/utils/processStepDisplay'
import { getComparatorString } from '~/utils/array'

import { ProcessStep } from '../../types/ProcessStep.interface'
import cs from './failed_process_step_notification.scss'

const FailedProcessStepNotification = ({
  className,
  stepUuidsFailed,
}: {
  className?: string
  stepUuidsFailed: string[]
}) => {
  const [processStepData, setProcessStepData] = useState<GetLiveStepsResponse | null>(
    null,
  )
  const [showFailedProcessStepDialog, setShowFailedProcessStepDialog] = useState(false)
  const [selectedProcessStepRetry, setSelectedProcessStepRetry] =
    useState<ProcessStep | null>(null)
  const [selectedProcessStepCancel, setSelectedProcessStepCancel] =
    useState<ProcessStep | null>(null)

  const fetchSteps = async () => {
    try {
      const response = await workcellAPI.getLiveSteps()
      setProcessStepData(response)
    } catch (error) {
      console.error('Failed to fetch workcell status') // eslint-disable-line no-console
      console.error(String(error)) // eslint-disable-line no-console
    }
  }

  // Refetch whenever the set of failed steps changes.
  useEffect(() => {
    fetchSteps()
  }, [getComparatorString(stepUuidsFailed)])

  const handleStepRetry = async (step: ProcessStep) => {
    try {
      await workcellAPI.rescheduleFailedStep(step.uuid)
      Toaster.show({
        message: `Re-scheduled step '${getProcessStepDisplay(step)}'`,
        intent: 'success',
      })
    } catch (e) {
      Toaster.show({
        message: `Failed to re-schedule step ${getProcessStepDisplay(step)}`,
        intent: 'danger',
      })
    }
  }

  const handleStepCancel = async (step: ProcessStep) => {
    try {
      await workcellAPI.cancelSteps([step.uuid])
      Toaster.show({
        message: `Cancelled step '${getProcessStepDisplay(step)}'`,
        intent: 'success',
      })
    } catch (e) {
      Toaster.show({
        message: `Failed to cancel step ${getProcessStepDisplay(step)}`,
        intent: 'danger',
      })
    }
  }

  const handleStepCancelInitial = (step: ProcessStep) => {
    const show = showCancelProcessStepDialog(step)

    // If there are additional instructions, show the dialog.
    if (show) {
      setSelectedProcessStepCancel(step)
    } else {
      handleStepCancel(step)
    }
  }

  const handleStepRetryInitial = (step: ProcessStep) => {
    const show = showRetryProcessStepDialog(step)

    // If there are additional instructions, show the dialog.
    if (show) {
      setSelectedProcessStepRetry(step)
    } else {
      handleStepRetry(step)
    }
  }

  const failedSteps = filter(['status', 'failed'], get('steps', processStepData))

  if (failedSteps.length === 0) {
    return null
  }

  const renderStepErrorMessage = (failedStep: ProcessStep) => {
    const error = failedStep.errorMessage
    if (!error) return null
    return <TextWithOverflow className={cs.error} text={error} popoverContent={error} />
  }

  const getLabel = (_failedSteps: ProcessStep[]) => {
    let message: JSX.Element | null = null
    let controls: JSX.Element[] = []
    if (_failedSteps.length === 1) {
      const step = _failedSteps[0]
      const _message = (
        <span>
          Error while executing <b>{getProcessStepDisplay(step)}</b>
          &nbsp;on <b>{step.instrumentName}</b>
        </span>
      )
      message = <TextWithOverflow text={_message} popoverContent={_message} />
    } else {
      message = <div>{_failedSteps.length} process steps have failed</div>
    }

    if (_failedSteps.length === 1) {
      controls = [
        <MinimalBoldButton
          label='Cancel Step'
          onClick={() => handleStepCancelInitial(_failedSteps[0])}
        />,
        <MinimalBoldButton
          label='Retry Step'
          className={cs.link}
          onClick={() => handleStepRetryInitial(_failedSteps[0])}
        />,
      ]
    } else {
      controls = [
        <MinimalButton
          label='Perform Error Recovery'
          onClick={() => setShowFailedProcessStepDialog(true)}
        />,
      ]
    }

    return (
      <div>
        {message}
        {renderStepErrorMessage(_failedSteps[0])}
        <div className={cs.controls}>{controls}</div>
      </div>
    )
  }

  return (
    <>
      <Notification className={className} label={getLabel(failedSteps)} type='error' />
      <FailedProcessStepDialog
        isOpen={showFailedProcessStepDialog}
        onClose={() => setShowFailedProcessStepDialog(false)}
        onStepRetry={handleStepRetry}
        onStepCancel={handleStepCancel}
      />
      <CancelProcessStepDialog
        isOpen={!!selectedProcessStepCancel}
        step={selectedProcessStepCancel || undefined}
        onClose={() => setSelectedProcessStepCancel(null)}
        onConfirm={() => {
          if (selectedProcessStepCancel) {
            handleStepCancel(selectedProcessStepCancel)
          }
          setSelectedProcessStepCancel(null)
        }}
      />
      <RetryProcessStepDialog
        isOpen={!!selectedProcessStepRetry}
        step={selectedProcessStepRetry || undefined}
        onClose={() => setSelectedProcessStepRetry(null)}
        onConfirm={() => {
          if (selectedProcessStepRetry) {
            handleStepRetry(selectedProcessStepRetry)
          }
          setSelectedProcessStepRetry(null)
        }}
      />
    </>
  )
}

FailedProcessStepNotification.propTypes = {
  className: PropTypes.string,
  stepUuidsFailed: PropTypes.arrayOf(PropTypes.string),
}

export default FailedProcessStepNotification
