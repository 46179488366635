import dayjs, { Dayjs } from 'dayjs'

let cache: {
  [base: string]: { url: string; validUntil: Dayjs; lastUsed: Dayjs }
} = {}

/**
 * Return an older (but still valid) URL for the given image, if one is known.
 * Otherwise, save the given URL and return it.
 */
export function warmImageURL(coldURL: string): string {
  const url = new URL(coldURL)
  if (!url.searchParams.has('X-Amz-Signature')) {
    console.warn('useWarmImageURL called with non-signed URL')
    return coldURL
  }

  const base = url.origin + url.pathname
  const cached = cache[base]
  if (cached && cached.validUntil.diff(dayjs(), 'minute') > 1) {
    cached.lastUsed = dayjs()
    return cached.url
  }

  const date = dayjs(url.searchParams.get('X-Amz-Date'), 'YYYYMMDDTHHmmssZ')
  const ttl = parseInt(url.searchParams.get('X-Amz-Expires') ?? '0')
  if (!date.isValid() || Number.isNaN(ttl) || ttl <= 0) {
    console.warn('useWarmImageURL called with invalid URL params')
    return coldURL
  }
  cache[base] = {
    url: coldURL,
    validUntil: date.add(ttl, 'seconds'),
    lastUsed: dayjs(),
  }
  return coldURL
}

/**
 * Clear the least recently used URLs from the cache.
 */
function pruneCache() {
  const times = Object.values(cache)
    .map(item => item.lastUsed.unix())
    .sort()
    .reverse()
  const cutoff = times[Math.min(1000, times.length - 1)]
  for (let base in cache) {
    if (cache[base].lastUsed.unix() < cutoff) {
      delete cache[base]
    }
  }
}

if (process.env.NODE_ENV !== 'test') {
  setInterval(pruneCache, 30 * 60 * 1000)
}

/** Clear the cache for testing purposes. */
export function clearCache() {
  cache = {}
}
