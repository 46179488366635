import PropTypes from 'prop-types'

import TextWithOverflow from '~/components/TextWithOverflow'

import cs from './json_with_overflow.scss'

interface JsonWithOverflowProps {
  className?: string
  jsonObj?: object
}

const JsonWithOverflow = ({ className, jsonObj }: JsonWithOverflowProps) => {
  const jsonString = JSON.stringify(jsonObj)
  const jsonStringFull = (
    <div className={cs.popover}>{JSON.stringify(jsonObj, undefined, 4)}</div>
  )

  return (
    <TextWithOverflow
      className={className}
      text={jsonString}
      popoverContent={jsonStringFull}
    />
  )
}

JsonWithOverflow.propTypes = {
  className: PropTypes.string,
  jsonObj: PropTypes.shape({}),
}

export default JsonWithOverflow
