import { components } from '../desktop/generated-schema'
import { client, unwrap } from '../desktopAPIv2'

export type BulkLoadParseDataSchemasResponse =
  components['schemas']['BulkLoadParseDataSchemasResponse']

export type BulkLoadParsePlateDataRequest =
  components['schemas']['BulkLoadParsePlateDataRequest']
export type BulkLoadParsePlateDataResponse =
  | BulkLoadParsePlateDataResponsePlates
  | BulkLoadParsePlateDataResponseErrors

export type BulkLoadParsedPlateWithStorageLoadLocation =
  components['schemas']['BulkLoadParsedPlateWithStorageLoadLocation']
export type BulkLoadParsePlateDataResponsePlates =
  components['schemas']['BulkLoadParsePlateDataResponsePlates']
export type BulkLoadParsePlateDataResponseErrors =
  components['schemas']['BulkLoadParsePlateDataResponseErrors']

export type BulkLoadParseWellDataRequest =
  components['schemas']['BulkLoadParseWellDataRequest']
export type BulkLoadParseWellDataResponse =
  | BulkLoadParseWellDataResponsePlates
  | BulkLoadParseWellDataResponseErrors

export type BulkLoadParseWellDataResponsePlates =
  components['schemas']['BulkLoadParseWellDataResponsePlates']
export type BulkLoadParseWellDataResponseErrors =
  components['schemas']['BulkLoadParseWellDataResponseErrors']
export type BulkLoadParsedPlateWithLiveWells =
  components['schemas']['BulkLoadParsedPlateWithLiveWells']
export type BulkLoadValidatedCulturePlates =
  components['schemas']['BulkLoadValidatedCulturePlates']

export default {
  getParseDataSchemas: (): Promise<BulkLoadParseDataSchemasResponse> =>
    unwrap(
      client().GET(
        '/api/v2/operator-actions/bulk-load-culture-plates/parse-data-schemas',
      ),
    ),
  parsePlateData: (
    request: BulkLoadParsePlateDataRequest,
  ): Promise<BulkLoadParsePlateDataResponse> =>
    unwrap(
      client().POST(
        '/api/v2/operator-actions/bulk-load-culture-plates/parse-plate-data',
        {
          body: request,
        },
      ),
    ),
  parseWellData: (
    request: BulkLoadParseWellDataRequest,
  ): Promise<BulkLoadParseWellDataResponse> =>
    unwrap(
      client().POST(
        '/api/v2/operator-actions/bulk-load-culture-plates/parse-well-data',
        {
          body: request,
        },
      ),
    ),
}
