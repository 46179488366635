import cx from 'classnames'
import PropTypes from 'prop-types'

import LoadingMessage from '~/components/LoadingMessage'
import cs from './loading_message_box.scss'

interface LoadingMessageBoxProps {
  className?: string
  label?: string
  type?: 'black'
  variant?: 'default' | 'large'
}

const LoadingMessageBox = ({
  className,
  label,
  variant,
  type,
}: LoadingMessageBoxProps) => {
  return (
    <div className={cx(className, cs.loadingMessageBox, type && cs[type])}>
      <LoadingMessage label={label} variant={variant} />
    </div>
  )
}

LoadingMessageBox.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  type: PropTypes.oneOf(['black']),
  variant: PropTypes.oneOf(['default', 'large']),
}

export default LoadingMessageBox
