import MicroplateWellSelect from '~/components/MicroplateWellSelect'
import {
  getPlateDims,
  getRightPackedWellIndexForWellNames,
  getWellNamesToRightOfWellIndex,
} from '~/utils/microplate'
import {
  LoadUnloadItemTiprackParams,
  LoadUnloadItemsReagentPlateParams,
} from '../LoadUnloadItemsProcessItemParams.interface'

export interface RightPackedConsumableWellSelectProps {
  className?: string
  processItemParams: Partial<
    LoadUnloadItemsReagentPlateParams | LoadUnloadItemTiprackParams
  >
  setProcessItemParams: (
    params: Partial<LoadUnloadItemsReagentPlateParams | LoadUnloadItemTiprackParams>,
  ) => void
  disabled?: boolean
}

const RightPackedConsumableWellSelect = ({
  className,
  processItemParams,
  setProcessItemParams,
  disabled,
}: RightPackedConsumableWellSelectProps) => {
  const plateFormat = processItemParams.selectedLabware?.plate_format
  if (!plateFormat) return null
  const plateDims = getPlateDims(plateFormat)
  return (
    <MicroplateWellSelect
      className={className}
      label='Select wells'
      plateFormat={plateFormat}
      size='large'
      selectedWellArray={
        processItemParams.initialNumWells
          ? getWellNamesToRightOfWellIndex(
              plateDims.numCols * plateDims.numRows - processItemParams.initialNumWells,
              getPlateDims(plateFormat),
            )
          : []
      }
      onSelectedWellArrayUpdate={specificWellsToFill => {
        setProcessItemParams({
          ...processItemParams,
          initialNumWells: getRightPackedWellIndexForWellNames(
            specificWellsToFill,
            getPlateDims(plateFormat),
          ),
        })
      }}
      showWellNames={false}
      mustPackRightSide
      disabled={disabled}
    />
  )
}

export default RightPackedConsumableWellSelect
