import { difference } from 'lodash/fp'
import { BulkExportCultureMetadataQuery } from '~/__generated__/graphql'

export const getMissingPlates = (
  data: BulkExportCultureMetadataQuery | null,
  plateBarcodesToDisplay: string[],
) => {
  if (!data) return plateBarcodesToDisplay

  const plateBarcodesInData = data.filteredCulturePlatesWithWells.map(
    plate => plate.barcode,
  )
  const missingPlates = difference(plateBarcodesToDisplay, plateBarcodesInData)

  return missingPlates
}
