import cx from 'classnames'

import InstrumentNameWithStatus from '~/pages/Workcell/components/instrument/InstrumentNameWithStatus'
import PropTypes from '~/propTypes'
import { getInstrumentImage } from '~/utils/instrument'

import cs from './instrument_tile.scss'

const InstrumentTile = ({ className, instrument, instrumentStatus, variant, mini }) => {
  return (
    <div
      className={cx(className, cs.instrumentTile, mini && cs.mini, cs[variant])}
      key={instrument.instrumentName}
    >
      <div className={cs.instrumentText}>
        <div className={cs.instrumentType}>{instrument.instrumentType}</div>
        <InstrumentNameWithStatus
          instrument={instrument}
          instrumentStatus={instrumentStatus}
          className={cs.instrumentNameWithStatus}
        />
      </div>
      <img
        className={cs.instrumentImage}
        src={getInstrumentImage(instrument)}
        alt={`${instrument.instrumentType}`}
      />
    </div>
  )
}

InstrumentTile.propTypes = {
  className: PropTypes.string,
  instrument: PropTypes.Instrument,
  // Instrument status is optional.
  instrumentStatus: PropTypes.InstrumentStatus,
  variant: PropTypes.oneOf(['leftAligned']),
  mini: PropTypes.bool,
}

export default InstrumentTile
