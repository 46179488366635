import cx from 'classnames'

import Checkbox from '~/components/Checkbox'
import Table from '~/components/Table'
import TextCheckbox from '~/components/TextCheckbox'
import DeleteIcon from '~/components/icons/DeleteIcon'
import EditIcon from '~/components/icons/EditIcon'
import InstrumentTile from '~/pages/Workcell/components/instrument/InstrumentTile'
import PropTypes from '~/propTypes'

import { InstrumentDriver } from '~/api/desktop/instrumentDrivers'
import cs from './instrument_driver_table.scss'

const InstrumentDriverTable = ({
  className,
  onInstrumentDelete,
  onInstrumentEdit,
  onInstrumentEditIconClick,
  instrumentDrivers,
}: {
  className?: string
  instrumentDrivers: InstrumentDriver[]
  onInstrumentEdit: (id: number, data: Partial<InstrumentDriver>) => void
  onInstrumentDelete: (id: number) => void
  onInstrumentEditIconClick: (id: number) => void
}) => {
  const toggleIsSimulated = async (row: InstrumentDriver) => {
    onInstrumentEdit(row.id, {
      isSimulated: !row.isSimulated,
    })
  }
  const toggleShouldAutostart = async (row: InstrumentDriver) => {
    onInstrumentEdit(row.id, {
      shouldAutostart: !row.shouldAutostart,
    })
  }

  const tableColumns = [
    {
      name: 'Name',
      width: 300,
      render: (row: InstrumentDriver) => (
        <InstrumentTile instrument={row} variant='leftAligned' mini />
      ),
    },
    {
      name: 'Simulated',
      width: 200,
      render: (row: InstrumentDriver) => (
        <TextCheckbox
          checkedText='Simulated'
          uncheckedText='Production'
          checked={row.isSimulated}
          onClick={() => toggleIsSimulated(row)}
          className={cx(cs.checkbox, cs.header)}
          checkedType='warning'
          uncheckedType='accent'
        />
      ),
    },
    {
      name: 'Auto-start',
      width: 200,
      render: (row: InstrumentDriver) => (
        <div onClick={() => toggleShouldAutostart(row)}>
          <Checkbox checked={row.shouldAutostart} />
        </div>
      ),
    },
    {
      name: '',
      width: 30,
      render: (row: InstrumentDriver) => (
        <EditIcon
          className={cs.icon}
          onClick={() => onInstrumentEditIconClick(row.id)}
        />
      ),
      showOnHover: true,
      omitCellPadding: true,
      stopPropagation: true,
    },
    {
      name: '',
      width: 30,
      render: (row: InstrumentDriver) => (
        <DeleteIcon className={cs.icon} onClick={() => onInstrumentDelete(row.id)} />
      ),
      showOnHover: true,
      omitCellPadding: true,
      stopPropagation: true,
    },
  ]

  return (
    <Table
      columns={tableColumns}
      data={instrumentDrivers}
      className={cx(className, cs.instrumentDriverTable)}
      rowPaddingVariant='rowPaddingLow'
      rowKey='id'
    />
  )
}

InstrumentDriverTable.propTypes = {
  className: PropTypes.string,
  instrumentDrivers: PropTypes.arrayOf(PropTypes.Instrument),
  onInstrumentEdit: PropTypes.func,
  onInstrumentDelete: PropTypes.func,
  onInstrumentEditIconClick: PropTypes.func,
}

export default InstrumentDriverTable
