import PropTypes from 'prop-types'

import logo from '~/assets/images/logo_monomer_full.svg'

interface LogoProps {
  className?: string
}

const Logo = ({ className }: LogoProps) => {
  return <img src={logo} alt='Monomer Bio logo' className={className} />
}

Logo.propTypes = {
  className: PropTypes.string,
}

export default Logo
