import { MenuItem2 } from '@blueprintjs/popover2'
import { MultiSelect2 } from '@blueprintjs/select'
import cx from 'classnames'
import { get, includes } from 'lodash/fp'
import React from 'react'
import TextWithOverflow from './TextWithOverflow'
import DownArrowIcon from './icons/DownArrowIcon'
import cs from './multi_select.scss'

interface MultiSelectProps<T> {
  itemKey: string
  itemLabelKey: string
  itemRenderer?: (item: T) => React.ReactNode
  placeholder?: string
  tagRenderer: (item: T) => React.ReactNode
  selectableItems: T[]
  onSelectedItemsUpdate(items: T[]): void
  selectedItems: T[]
  itemPredicate: (query: string, item: T) => boolean
  disabled?: boolean
}

const MonomerMultiSelect = <T,>(props: MultiSelectProps<T>) => {
  const {
    itemKey,
    itemLabelKey,
    itemRenderer,
    placeholder,
    tagRenderer,
    selectableItems,
    onSelectedItemsUpdate,
    selectedItems,
    itemPredicate,
    disabled,
  } = props

  const optionRenderer = (item, { handleClick, modifiers: { active } }) => {
    return (
      <div
        key={get(itemKey || itemLabelKey, item)}
        className={cx(cs.option, active && cs.active)}
        onClick={handleClick}
      >
        {itemRenderer ? (
          itemRenderer(item)
        ) : (
          <TextWithOverflow text={get(itemLabelKey, item)} />
        )}
      </div>
    )
  }

  const onItemSelect = (item: T) => {
    // This assumes that an item can only be selected at most once
    if (selectedItems.includes(item)) {
      return
    }
    onSelectedItemsUpdate([...selectedItems, item])
  }

  const onItemRemove = (item: T) => {
    const updatedItems = selectedItems.filter(i => i !== item)
    onSelectedItemsUpdate(updatedItems)
  }

  const filteredSelectableItems = selectableItems.filter(
    item => !includes(item, selectedItems),
  )

  const renderMultiSelect = () => {
    // If activeItem is not forced to null, the MultiSelect jumps to the top whenever
    // it is scrolled. We should investigate this further.
    return (
      <MultiSelect2<T>
        activeItem={null}
        placeholder={placeholder}
        itemRenderer={optionRenderer}
        // TODO Remove/reconsider this
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        items={filteredSelectableItems}
        onItemSelect={onItemSelect}
        selectedItems={selectedItems}
        // TODO: Probably choose a better component for this
        tagRenderer={tagRenderer}
        onRemove={(_, index) => {
          onItemRemove(selectedItems[index])
        }}
        disabled={disabled}
        itemPredicate={itemPredicate}
        noResults={<MenuItem2 disabled={true} text='No results.' />}
        popoverProps={{
          matchTargetWidth: true,
          minimal: true,
          popoverClassName: cx(cs.popover, itemRenderer && cs.noPadding),
        }}
        resetOnSelect
        className={cs.multiSelect}
      />
    )
  }

  return (
    <div className={cs.multiSelectInput}>
      <span className={cs.value}>{renderMultiSelect()}</span>
      <DownArrowIcon className={cx(cs.icon)} />
    </div>
  )
}

export default MonomerMultiSelect
