import { Popover2 } from '@blueprintjs/popover2'
import cx from 'classnames'
import { any, map, min, noop } from 'lodash/fp'
import { useEffect, useState } from 'react'
import futureRoutinesAPI from '~/api/desktop/futureRoutines'
import { FutureRoutine } from '~/api/desktop/future_routine_schemas.interface'
import LoadingMessage from '~/components/LoadingMessage'
import Toaster from '~/components/Toaster'
import Button from '~/components/buttons/Button'
import MinimalButton from '~/components/buttons/MinimalButton'
import EditIcon from '~/components/icons/EditIcon'
import { getComparatorString } from '~/utils/array'
import { datetimeToUTCIsoString } from '~/utils/date'
import { displayCount } from '~/utils/string'
import FutureRoutineScheduleForForm from './FutureRoutineScheduleForForm'
import cs from './edit_future_routine_schedule_popover.scss'

// TODO: This component should also support should_queue_automatically = False.
// That will require a rework, which may include splitting up the DateInput and
// creating a new TimeInput component.
// These components, once created, can also be used in Generic Schedule.
const EditFutureRoutineSchedulePopover = ({
  disabled,
  className,
  editExistingOrSetNew,
  selectedRoutines,
  onEditSuccess,
}: {
  disabled: boolean
  className?: string
  editExistingOrSetNew: 'edit_existing' | 'set_new'
  selectedRoutines: FutureRoutine[]
  onEditSuccess: () => void
}) => {
  const [open, setOpen] = useState(false)
  const [scheduledFor, setScheduledFor] = useState<string | null>(null)
  const [shouldQueueAutomatically, setShouldQueueAutomatically] = useState(false)
  const [loading, setLoading] = useState(false)
  const isEditExisting = editExistingOrSetNew === 'edit_existing'
  const renderTarget = ({ ...targetProps }) => {
    return (
      <div
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...targetProps}
        className={cs.target}
      >
        <MinimalButton
          type='primary'
          className={cx(cs.cancelButton, className)}
          label={isEditExisting ? 'Edit Schedule...' : 'Set New Schedule...'}
          IconComponent={EditIcon}
          disabled={disabled}
          variant='normal'
          appearNormalIfNotHovered
          onClick={noop} // Display a cursor on hover.
        />
      </div>
    )
  }

  const submitEdit = async () => {
    setLoading(true)
    try {
      await futureRoutinesAPI.manuallyUpdateScheduleForFutureRoutines(
        map('uuid', selectedRoutines),
        datetimeToUTCIsoString(scheduledFor as string),
        shouldQueueAutomatically,
      )
      onEditSuccess()
      Toaster.show({
        message: `Successfully ${
          isEditExisting ? 'edited' : 'set'
        } schedule for ${displayCount('routine', selectedRoutines.length)}.`,
        intent: 'success',
      })
    } catch {
      Toaster.show({
        message: `Failed to ${
          isEditExisting ? 'edit' : 'set'
        } schedule for ${displayCount('routine', selectedRoutines.length)}.`,
        intent: 'danger',
      })
    }
    setLoading(false)
    setOpen(false)
  }

  useEffect(() => {
    const minimumScheduledFor = min(selectedRoutines.map(r => r.scheduledFor))
    setScheduledFor(minimumScheduledFor || null)
    const shouldQueueAutomatically = any(
      r => r.shouldQueueAutomatically,
      selectedRoutines,
    )
    setShouldQueueAutomatically(shouldQueueAutomatically)
  }, [getComparatorString(map('uuid', selectedRoutines))])

  const renderPopover = () => {
    return (
      <div className={cs.popover}>
        <FutureRoutineScheduleForForm
          className={cs.futureRoutineScheduleForForm}
          scheduledFor={scheduledFor}
          setScheduledFor={setScheduledFor}
          shouldQueueAutomatically={shouldQueueAutomatically}
          setShouldQueueAutomatically={setShouldQueueAutomatically}
          disabled={loading}
        />

        {loading ? (
          <LoadingMessage
            className={cs.loadingMessage}
            label={`${isEditExisting ? 'Editing' : 'Setting new'} schedules...`}
          />
        ) : (
          <Button
            label={`${isEditExisting ? 'Edit' : 'Set'} for ${displayCount(
              'Routine',
              selectedRoutines.length,
            )}`}
            onClick={submitEdit}
            type='primary'
            disabled={disabled}
            className={cs.button}
            variant='minimalBold'
          />
        )}
      </div>
    )
  }
  return (
    <Popover2
      disabled={disabled}
      content={renderPopover()}
      interactionKind='click'
      isOpen={open}
      onInteraction={setOpen}
      placement='top'
      modifiers={{
        offset: {
          enabled: true,
          options: {
            offset: [0, 10],
          },
        },
      }}
      usePortal
      fill
      renderTarget={renderTarget}
      openOnTargetFocus={false}
    />
  )
}

export default EditFutureRoutineSchedulePopover
