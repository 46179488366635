import PropTypes from 'prop-types'

import { getDesktopDomainDefault } from '~/api/desktop/utils'
import workcellAPI from '~/api/desktop/workcell'
import Dialog from '~/components/Dialog'
import ConfigDialogTab from '~/components/Dialog/ConfigDialogTab'
import Tabs from '~/components/Tabs'

import AddRemoveOperatorActionTab from './AddRemoveOperatorActionTab'
import TechnicianToolsTab from './TechnicianToolsTab'
import WorkcellLayoutDialogTab from './WorkcellLayoutDialogTab'
import WorkcellProfileDialogTab from './WorkcellProfileDialogTab'
import cs from './workcell_config_dialog.scss'

const WorkcellConfigDialog = ({
  isOpen,
  onClose,
  onShowWorkcellRawStateEditDialogClick,
  onShowGlobalConfigEditDialogClick,
}: {
  isOpen: boolean
  onClose(): void
  onShowWorkcellRawStateEditDialogClick(): void
  onShowGlobalConfigEditDialogClick(): void
}) => {
  const renderWorkcellSettings = () => {
    const api = {
      getSchema: workcellAPI.getConfigSchema,
      setConfig: workcellAPI.setConfig,
      getConfig: workcellAPI.getConfig,
    }
    return (
      <ConfigDialogTab
        api={api}
        onClose={onClose}
        omitFields={['instruments', 'enabledOperatorActions']}
      />
    )
  }

  const renderWorkcellProfile = () => {
    return <WorkcellProfileDialogTab onClose={onClose} />
  }

  const renderOperatorActions = () => {
    return <AddRemoveOperatorActionTab />
  }

  const renderWorkcellLayout = () => {
    return <WorkcellLayoutDialogTab onClose={onClose} />
  }

  const renderNetworkSettings = () => {
    const api = {
      getSchema: async () => ({
        schema: {
          title: 'WorkcellNetworkConfig',
          type: 'object',
          properties: {
            networkAddress: {
              title: 'Network Address',
              description:
                'The hostname of the machine (e.g. http://192.168.2.23:8081) that hosts the workcell ' +
                'on the network.',
              default: getDesktopDomainDefault(),
              type: 'string',
            },
          },
        },
      }),
      setConfig: async config => {
        window.localStorage.setItem('localWorkcellConfig', JSON.stringify(config))
        return config
      },
      getConfig: async () => {
        const configRaw = window.localStorage.getItem('localWorkcellConfig')
        if (!configRaw) return {}
        return JSON.parse(configRaw)
      },
    }
    return <ConfigDialogTab api={api} onClose={onClose} />
  }

  const handleRawStateEditClick = () => {
    onClose()
    onShowWorkcellRawStateEditDialogClick()
  }

  const handleGlobalConfigEditClick = () => {
    onClose()
    onShowGlobalConfigEditDialogClick()
  }

  const renderTechnicianTools = () => (
    <TechnicianToolsTab
      onRawStateEditClick={handleRawStateEditClick}
      onGlobalConfigEditClick={handleGlobalConfigEditClick}
    />
  )

  const tabs = [
    {
      key: 'workcell_settings',
      title: 'Workcell Settings',
      panel: renderWorkcellSettings(),
    },
    {
      key: 'operator_actions',
      title: 'Operator Actions',
      panel: renderOperatorActions(),
    },
    {
      key: 'workcell_layout',
      title: 'Workcell Layout',
      panel: renderWorkcellLayout(),
    },
    {
      key: 'workcell_profile',
      title: 'Workcell Profile',
      panel: renderWorkcellProfile(),
    },
    {
      key: 'network_settings',
      title: 'Network Settings',
      panel: renderNetworkSettings(),
    },
    {
      key: 'technician_tools',
      title: 'Technician Tools',
      panel: renderTechnicianTools(),
    },
  ]

  return (
    <Dialog isOpen={isOpen} onClose={onClose} className={cs.workcellConfigDialog}>
      <div className={cs.title}>Configure Workcell</div>
      <Tabs
        tabs={tabs}
        onlyRenderWhenViewed
        initialTab='workcell_settings'
        className={cs.tabs}
        panelClassName={cs.tabPanel}
      />
    </Dialog>
  )
}

WorkcellConfigDialog.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onShowWorkcellRawStateEditDialogClick: PropTypes.func,
}

export default WorkcellConfigDialog
