import cx from 'classnames'
import { Instrument, InstrumentStatus } from '~/common.interface'
import { getInstrumentImage } from '~/utils/instrument'
import InstrumentCategory from './InstrumentCategory'
import InstrumentNameWithStatus from './InstrumentNameWithStatus'
import InstrumentStatusText from './InstrumentStatusText'
import cs from './instrument_header.scss'

interface InstrumentHeaderProps {
  instrument: Instrument
  instrumentStatus: InstrumentStatus
  big?: boolean
  isExecutingControlCommand?: boolean
  additionalStatusText?: string | JSX.Element
  // This is used on the Teach Tool page, where the teach tool status is displayed
  // instrument of the instrument status.
  hideInstrumentStatus?: boolean
}

const InstrumentHeader = ({
  instrument,
  instrumentStatus,
  big,
  isExecutingControlCommand,
  additionalStatusText,
  hideInstrumentStatus,
}: InstrumentHeaderProps) => {
  return (
    <div className={cx(cs.instrumentHeader, big && cs.big)}>
      <img
        className={cs.instrumentImage}
        src={getInstrumentImage(instrument)}
        alt={`${instrument.instrumentType}`}
      />
      <div className={cs.instrumentText}>
        <InstrumentCategory instrument={instrument} className={cs.instrumentCategory} />
        <InstrumentNameWithStatus
          instrument={instrument}
          instrumentStatus={instrumentStatus}
          big={big}
        />
        {hideInstrumentStatus ? (
          <div className={cs.instrumentStatusText}>{additionalStatusText}</div>
        ) : (
          <InstrumentStatusText
            instrumentStatus={instrumentStatus}
            isExecutingControlCommand={isExecutingControlCommand}
            additionalStatusText={additionalStatusText}
            className={cs.instrumentStatusText}
          />
        )}
      </div>
    </div>
  )
}

export default InstrumentHeader
