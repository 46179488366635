import { includes } from 'lodash/fp'
import { useEffect, useState } from 'react'
import {
  RecordManualPassageParseDataResponse,
  RecordManualPassageParsedWorklist,
  RecordManualPassageSubmitDataRequest,
  RecordManualPassageSubmitDataResponse,
} from '~/api/operatorActions/recordManualPassage'
import recordManualPassageAPI from '~/api/operatorActions/recordManualPassage'
import RecordManualPassageIcon from '~/assets/images/operator_record_manual_passage.png'
import AppHeader from '~/components/AppHeader'
import LoadingMessageBox from '~/components/LoadingMessageBox'
import Toaster from '~/components/Toaster'
import Button from '~/components/buttons/Button'
import { analytics } from '~/core/analytics'
import { useIsMounted } from '~/utils/hooks/useIsMounted'
import { useEnabledAndUnusedOperatorActionNames } from '../../useEnabledAndUnusedOperatorActionNames'
import RecordManualPassageUnknownError from './RecordManualPassageUnknownError'
import SuccessStep from './SuccessStep'
import UploadDataStep from './UploadDataStep'
import cs from './record_manual_passage_action.scss'

const RecordManualPassageAction = () => {
  const { enabledOperatorActionNames } = useEnabledAndUnusedOperatorActionNames()

  const isMounted = useIsMounted()
  const [rawData, setRawData] = useState('')
  const [parseDataResponse, setParseDataResponse] =
    useState<RecordManualPassageParseDataResponse | null>(null)
  const [submitInProgress, setSubmitInProgress] = useState(false)
  const [submitDataResponse, setSubmitDataResponse] =
    useState<RecordManualPassageSubmitDataResponse | null>(null)

  useEffect(() => {
    if (
      submitDataResponse?.success &&
      parseDataResponse &&
      parseDataResponse.type === 'worklists'
    ) {
      const totalWellTransfers = parseDataResponse.data.reduce(
        (acc: number, value: RecordManualPassageParsedWorklist) =>
          acc + value.well_transfers.length,
        0,
      )
      analytics.track('Completed Record Manual Passage', {
        numPlates: parseDataResponse.culture_plates.length,
        numWellTransfers: totalWellTransfers,
      })
    }
  }, [submitDataResponse])

  if (!includes('record_manual_passage', enabledOperatorActionNames)) {
    return null
  }

  const renderView = () => {
    if (submitInProgress) {
      return (
        <LoadingMessageBox
          label='Recording Manual Passages...'
          className={cs.loadingMessageBox}
        />
      )
    }

    if (submitDataResponse) {
      if (parseDataResponse && parseDataResponse.type === 'worklists') {
        return <SuccessStep parseDataResponse={parseDataResponse} />
      }
      return <RecordManualPassageUnknownError />
    }

    return (
      <UploadDataStep
        rawData={rawData}
        setRawData={setRawData}
        parseDataResponse={parseDataResponse}
        setParseDataResponse={setParseDataResponse}
      />
    )
  }

  const getWorklistsIfReady = () => {
    if (parseDataResponse && parseDataResponse.type === 'worklists') {
      return parseDataResponse.data
    }
    return null
  }

  const saveManualPassages = async (worklists: RecordManualPassageParsedWorklist[]) => {
    const request: RecordManualPassageSubmitDataRequest = {
      worklists,
    }

    let submitDataResponse: RecordManualPassageSubmitDataResponse | null = null
    try {
      submitDataResponse = await recordManualPassageAPI.submitData(request)
    } catch (e) {
      Toaster.show({
        message: 'Error communicating with the server.',
        intent: 'danger',
      })
      if (!isMounted()) {
        return
      }
      setSubmitDataResponse(null)
      setSubmitInProgress(false)
      return
    }
    if (!isMounted()) {
      return
    }
    if (!submitDataResponse.success) {
      Toaster.show({
        message: `Error saving data: ${submitDataResponse.error}`,
        intent: 'danger',
      })
      setSubmitDataResponse(null)
      setSubmitInProgress(false)
      return
    }
    setSubmitDataResponse(submitDataResponse)
    setSubmitInProgress(false)
  }

  const handleSave = () => {
    const worklists = getWorklistsIfReady()
    if (worklists) {
      setSubmitInProgress(true)
      saveManualPassages(worklists)
    }
  }

  return (
    <div className={cs.recordManualPassageAction}>
      <div className={cs.actionHeader}>
        <AppHeader
          className={cs.header}
          appName='Record Manual Passages'
          iconSrc={RecordManualPassageIcon}
          variant='inline'
        />
        <div className={cs.fill} />
        {!submitInProgress && !submitDataResponse && (
          <Button
            className={cs.button}
            type='primary'
            label='Save'
            onClick={handleSave}
            disabled={!getWorklistsIfReady()}
          />
        )}
      </div>

      {renderView()}
    </div>
  )
}

export default RecordManualPassageAction
