import dayjs from 'dayjs'
import { reject } from 'lodash/fp'
import { useEffect, useState } from 'react'

import { users } from '~/api/user'
import PopupMenu from '~/components/PopupMenu'
import Table, { TableColumn } from '~/components/Table'
import Button from '~/components/buttons/Button'
import KeyIcon from '~/components/icons/KeyIcon'

import { AuthToken } from '~/types/AuthToken.interface'
import cs from './auth_token_dashboard.scss'

const AuthTokenDashboard = () => {
  const [authTokens, setAuthTokens] = useState<AuthToken[]>([])
  const [loading, setLoading] = useState(true)

  const fetchAuthTokens = async () => {
    const authTokensResponse = await users.authTokens.get()
    setAuthTokens(authTokensResponse)
    setLoading(false)
  }

  useEffect(() => {
    fetchAuthTokens()
  }, [])

  const create = async () => {
    const response = await users.authTokens.create()
    setAuthTokens([...authTokens, response])
  }

  const deleteToken = async (userAuthTokenId: number) => {
    await users.authTokens.del(userAuthTokenId)
    setAuthTokens(reject(['id', userAuthTokenId], authTokens))
  }

  const AUTH_TOKEN_COLUMNS: TableColumn<AuthToken>[] = [
    {
      name: '',
      width: 50,
      smallText: true,
      omitCellPadding: true,
      render: row => (
        <PopupMenu
          options={[
            {
              label: 'Delete',
              action: () => deleteToken(row.id),
            },
          ]}
          iconClassName={cs.icon}
        />
      ),
    },
    {
      name: 'API Token',
      width: 450,
      render: row => <div className={cs.apiToken}>{row.secret}</div>,
    },
    {
      name: 'Created',
      width: 300,
      smallText: true,
      render: row => dayjs(row.createdAt).fromNow(),
    },
  ]

  if (loading) {
    return (
      <div className={cs.authTokenDashboard}>
        <div className={cs.authTokens}>
          <div className={cs.bigMessage}>Loading tokens...</div>
        </div>
      </div>
    )
  }

  return (
    <div className={cs.authTokenDashboard}>
      <div className={cs.authTokens}>
        <div className={cs.header}>
          <div className={cs.text}>
            <div className={cs.title}>API Auth Tokens</div>
          </div>
          <div className={cs.fill} />
          <Button
            type='primary'
            IconComponent={KeyIcon}
            label='Generate New Token'
            className={cs.addButton}
            onClick={create}
          />
        </div>
        <Table
          columns={AUTH_TOKEN_COLUMNS}
          data={authTokens}
          className={cs.authTokenTable}
          rowKey='id'
        />
      </div>
    </div>
  )
}

export default AuthTokenDashboard
