import { useEffect, useState } from 'react'
import recordManualPassageAPI, {
  RecordManualPassageDataSchemasResponse,
} from '~/api/operatorActions/recordManualPassage'
import Dialog from '~/components/Dialog'
import TabularDataSchemaDisplay from '../../TabularDataSchemaDisplay'
import cs from './record_manual_passage_data_schemas_dialog.scss'

export type RecordManualPassageDataSchemasDialogProps = {
  isOpen: boolean
  onClose: () => void
}

const RecordManualPassageDataSchemasDialog = ({
  isOpen,
  onClose,
}: RecordManualPassageDataSchemasDialogProps) => {
  const [dataSchemas, setDataSchemas] =
    useState<RecordManualPassageDataSchemasResponse | null>(null)

  const fetchDataSchemas = async () => {
    const response = await recordManualPassageAPI.getParseDataSchemas()
    setDataSchemas(response)
  }

  useEffect(() => {
    fetchDataSchemas()
  }, [])

  if (dataSchemas === null) {
    return null
  }

  return (
    <Dialog
      isOpen={isOpen}
      onClose={onClose}
      className={cs.recordManualPassageDataSchemasDialog}
    >
      <div className={cs.title}>Accepted Data Format</div>
      <div className={cs.subtitle}>
        .csv and .tsv formats are supported. Please include the column names.
      </div>
      <div className={cs.optionTitle}>Schema</div>
      <TabularDataSchemaDisplay
        className={cs.schemaDisplay}
        schema={dataSchemas.transfers_schema}
      />
    </Dialog>
  )
}

export default RecordManualPassageDataSchemasDialog
