import cx from 'classnames'
import PropTypes from 'prop-types'

import Table from '~/components/Table'
import Button from '~/components/buttons/Button'
import ProcessStepName from '~/pages/Workcell/components/processStep/ProcessStepName'

import { ProcessStep } from '../../types/ProcessStep.interface'
import cs from './failed_process_step_table.scss'

const FailedProcessStepTable = ({
  className,
  failedSteps,
  onRetryStep,
  onCancelStep,
}: {
  className?: string
  failedSteps: ProcessStep[]
  onRetryStep: (step: ProcessStep) => void
  onCancelStep: (step: ProcessStep) => void
}) => {
  const tableColumns = [
    {
      name: 'Step',
      width: 250,
      render: (row: ProcessStep) => <ProcessStepName processStep={row} />,
    },
    {
      name: 'Params',
      width: 'flex',
      render: (row: ProcessStep) => JSON.stringify(row.commandParams),
      smallText: true,
      oneLine: true,
    },
    {
      name: '',
      width: 120,
      render: (row: ProcessStep) => (
        <Button
          type='primary'
          label='Retry Step'
          onClick={() => onRetryStep(row)}
          className={cs.button}
        />
      ),
    },
    {
      name: '',
      width: 120,
      render: (row: ProcessStep) => (
        <Button
          className={cs.button}
          type='danger'
          label='Cancel Step'
          onClick={() => onCancelStep(row)}
        />
      ),
    },
  ]

  if (!failedSteps) return null

  return (
    <Table
      columns={tableColumns}
      data={failedSteps}
      rowKey='id'
      rowPaddingVariant='rowPaddingLow'
      heightSizing='default'
      className={cx(cs.failedProcessStepTable, className)}
    />
  )
}

FailedProcessStepTable.propTypes = {
  className: PropTypes.string,
  failedSteps: PropTypes.arrayOf(
    PropTypes.shape({
      instrumentName: PropTypes.string,
      commandName: PropTypes.string,
      commandParams: PropTypes.shape({}),
    }),
  ),
  onRetryStep: PropTypes.func,
  onCancelStep: PropTypes.func,
}

export default FailedProcessStepTable
