import cx from 'classnames'
import { get } from 'lodash/fp'
import PropTypes from 'prop-types'
import { useContext } from 'react'

import WorkcellStatusContext from '../../WorkcellStatusContext'
import { getInstrumentRealTimeState } from '../../utils/workcellStatus'

import cs from './inheco_scila_live_door_status.scss'

const InhecoScilaLiveDoorStatus = ({ instrumentName, className }) => {
  const workcellStatus = useContext(WorkcellStatusContext)
  const realTimeState = getInstrumentRealTimeState(workcellStatus, instrumentName)

  const getDoorStatus = door => {
    const isOpen = get(['door_state', door - 1], realTimeState) === 'Opened'

    return (
      <span className={cx(isOpen ? cs.doorStatusOpened : cs.doorStatusClosed)}>
        {door}
      </span>
    )
  }

  return (
    <span className={cx(cs.inhecoScilaLiveDoorStatus, className)}>
      Drawers: {getDoorStatus(1)} {getDoorStatus(2)} {getDoorStatus(3)}{' '}
      {getDoorStatus(4)}
    </span>
  )
}

InhecoScilaLiveDoorStatus.propTypes = {
  instrumentName: PropTypes.string,
  className: PropTypes.string,
}

export default InhecoScilaLiveDoorStatus
