import cx from 'classnames'
import Notification from '~/components/notifications/Notification'
import cs from './record_manual_passage_unknown_error.scss'

export interface RecordManualPassageUnknownErrorProps {
  className?: string
}

const RecordManualPassageUnknownError = ({
  className,
}: RecordManualPassageUnknownErrorProps) => {
  return (
    <Notification
      className={cx(cs.recordManualPassageUnknownError, className)}
      variant='mini'
      type='error'
      label='An unknown error has occurred'
    />
  )
}

export default RecordManualPassageUnknownError
