// Originally a developer board icon.
// Ideally, we want an icon that suggests a microscope or liquid handler.

import PropTypes from 'prop-types'
import { IconProps } from './types/IconProps'
const JankyDeviceIcon = ({ className, onClick }: IconProps) => (
  <svg
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    onClick={onClick}
    className={className}
  >
    <path
      id='icon/hardware/router_24px'
      fillRule='evenodd'
      clipRule='evenodd'
      d='M16 4.2C17.5 4.2 19 4.8 20.2 5.9L21 5.1C19.6 3.7 17.8 3 16 3C14.2 3 12.4 3.7 11 5.1L11.8 5.9C13 4.8 14.5 4.2 16 4.2ZM12.7 6.7L13.5 7.5C14.2 6.8 15.1 6.5 16 6.5C16.9 6.5 17.8 6.8 18.5 7.5L19.3 6.7C18.4 5.8 17.2 5.3 16 5.3C14.8 5.3 13.6 5.8 12.7 6.7ZM17 13H19C20.1 13 21 13.9 21 15V19C21 20.1 20.1 21 19 21H5C3.9 21 3 20.1 3 19V15C3 13.9 3.9 13 5 13H15V9H17V13ZM19 19H5V15H19V19ZM8 16H6V18H8V16ZM9.5 16H11.5V18H9.5V16ZM15 16H13V18H15V16Z'
    />
  </svg>
)

JankyDeviceIcon.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
}

export default JankyDeviceIcon
