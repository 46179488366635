import { Select } from '@blueprintjs/select'
import cx from 'classnames'
import { filter, get, isString, take } from 'lodash/fp'
import PropTypes from 'prop-types'
import React from 'react'
import { useMemo, useState } from 'react'

import TextWithOverflow from '~/components/TextWithOverflow'
import DownArrowIcon from '~/components/icons/DownArrowIcon'

import cs from './select.scss'

export interface SelectOption {
  key: string
  label: string
}

const NONE_OPTION = 'NONE_OPTION'

interface SelectProps<T> {
  label?: string
  items: T[]
  itemKey: string
  itemLabelKey?: string
  itemRenderer?: (item: T) => React.ReactNode
  activeItem: T | null
  onChange: (item: T) => void
  loading?: boolean
  loadingMessage?: string
  placeholder?: React.ReactNode
  className?: string
  triggerClassName?: string
  popoverClassName?: string
  triggerIconClassName?: string
  filterable?: boolean
  itemMatchesQuery?: (item: T, query: string) => boolean
  maxToDisplay?: number
  disabled?: boolean
  noBorder?: boolean
  hideArrow?: boolean
  allowNoneOption?: boolean
  noneOptionText?: string
}

const MonomerSelect = <T,>(props: SelectProps<T>) => {
  const {
    label,
    items,
    itemKey,
    itemLabelKey = 'label',
    itemRenderer,
    activeItem,
    onChange,
    loading,
    loadingMessage,
    placeholder,
    className,
    triggerClassName,
    popoverClassName,
    filterable = false,
    itemMatchesQuery,
    maxToDisplay = 1000,
    disabled,
    noBorder,
    hideArrow = false,
    triggerIconClassName,
    // Display a None option. When selected, will return
    // { [itemKey]: null, [itemLabelKey]: null }.
    allowNoneOption,
    // If not specified, defaults to None
    noneOptionText,
  } = props

  const [query, updateQuery] = useState('')

  const queryLowerCase = query.toLowerCase()

  const getNoneOption = () =>
    ({
      [itemKey]: NONE_OPTION,
      [itemLabelKey]: noneOptionText || 'None',
    }) as T

  const filteredItems: T[] = useMemo(() => {
    const _items = take(
      maxToDisplay,
      filterable && itemMatchesQuery
        ? filter(item => itemMatchesQuery(item, queryLowerCase), items)
        : items,
    )
    return [...(allowNoneOption ? [getNoneOption()] : []), ..._items]
  }, [maxToDisplay, filterable, items, queryLowerCase])

  // Renders each option in the select.
  // Customize the contents of the option by passing in an itemRenderer function.
  const optionRenderer = (item: T, { handleClick, modifiers: { active } }) => {
    return (
      <div
        key={get(itemKey || itemLabelKey, item)}
        className={cx(
          cs.option,
          active && cs.active,
          get(itemKey, item) == NONE_OPTION && cs.noneOption,
        )}
        onClick={handleClick}
      >
        {itemRenderer ? (
          itemRenderer(item)
        ) : (
          <TextWithOverflow text={get(itemLabelKey, item)} />
        )}
      </div>
    )
  }

  const noResults = <div className={cs.noResults}>No results found.</div>

  // Render the text that displays on the trigger element.
  const renderTriggerText = () => {
    if (loading) {
      return <span className={cs.placeholder}>{loadingMessage}</span>
    }

    let _item
    let isNoneItem = false
    if (activeItem) {
      _item = activeItem
    } else if (allowNoneOption) {
      _item = getNoneOption()
      isNoneItem = true
    }

    if (_item) {
      if (itemRenderer && !isNoneItem) {
        return itemRenderer(_item)
      }
      return (
        <span className={cx(isNoneItem && cs.noneTriggerText)}>
          {get(itemLabelKey, _item)}
        </span>
      )
    }

    if (isString(placeholder)) {
      return <span className={cs.placeholder}>{placeholder}</span>
    }
    return placeholder
  }

  const handleChange = (value: T) => {
    if (get(itemKey, value) === NONE_OPTION) {
      onChange({
        [itemKey]: null,
        [itemLabelKey]: null,
      } as T)
    } else {
      onChange(value)
    }
  }

  return (
    <div className={className}>
      {label && <div className={cs.label}>{label}</div>}
      <Select
        query={query}
        onQueryChange={updateQuery}
        activeItem={null}
        items={filteredItems}
        itemRenderer={optionRenderer}
        onItemSelect={handleChange}
        popoverProps={{
          minimal: true,
          // See https://github.com/palantir/blueprint/issues/4047
          // Without this, the popover is not aligned when inside a scrollable div.
          boundary: 'viewport',
          popoverClassName: cx(
            popoverClassName,
            cs.popover,
            itemRenderer && cs.noPadding,
          ),
        }}
        noResults={noResults}
        disabled={loading || disabled}
        resetOnSelect
        filterable={filterable}
      >
        <div
          className={cx(
            triggerClassName,
            cs.select,
            noBorder && cs.noBorder,
            (disabled || loading) && cs.disabled,
          )}
        >
          <div className={cx(cs.selectedName, !itemRenderer && cs.isText)}>
            {renderTriggerText()}
          </div>
          <div className={cs.fill} />
          {!hideArrow && (
            <DownArrowIcon className={cx(cs.icon, triggerIconClassName)} />
          )}
        </div>
      </Select>
    </div>
  )
}

MonomerSelect.propTypes = {
  label: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.any),
  // The key to use for the key prop.
  itemKey: PropTypes.string,
  // The key to use for the item label.
  itemLabelKey: PropTypes.string,
  // Optional renderer. Otherwise, itemLabelKey will be used to display the item.
  itemRenderer: PropTypes.func,
  // The currently active item, which will be highlighted.
  activeItem: PropTypes.any,
  onChange: PropTypes.func,
  // If loading, a loading placeholder message will be shown and the button will be disabled.
  loading: PropTypes.bool,
  loadingMessage: PropTypes.string,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  // Used primarily to control the width of the trigger and popover elements.
  // Unfortunately, there isn't an easy way to synchronize the width of the trigger and popover.
  triggerClassName: PropTypes.string,
  popoverClassName: PropTypes.string,
  // Whether a search box should be shown in the select menu.
  filterable: PropTypes.bool,
  // A function that receives (item, searchQuery) and returns whether the item matches the query.
  itemMatchesQuery: PropTypes.func,
  // The max number of items to display.
  maxToDisplay: PropTypes.number,
  disabled: PropTypes.bool,
  bare: PropTypes.bool,
}

export default MonomerSelect
