import PropTypes from 'prop-types'
import { IconProps } from './types/IconProps'
const LaunchIcon = ({ className, onClick }: IconProps) => (
  <svg
    version='1.1'
    xmlns='http://www.w3.org/2000/svg'
    width='24'
    height='24'
    viewBox='0 0 24 24'
    className={className}
    onClick={onClick}
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M5 5V19H19V12H21V19C21 20.1 20.1 21 19 21H5C3.89001 21 3 20.1 3 19V5C3 3.9 3.89001 3 5 3H12V5H5ZM14 5V3H21V10H19V6.41L9.17004 16.24L7.76001 14.83L17.59 5H14Z'
    />
  </svg>
)

LaunchIcon.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
}

export default LaunchIcon
