import React, { useEffect } from 'react'
import { Route, Switch, useRouteMatch } from 'react-router-dom'
import roboticArmAPI from '~/api/desktop/drivers/roboticArm'
import { Instrument, InstrumentStatus } from '~/common.interface'
import Button from '~/components/buttons/Button'
import GenericInstrumentPage from '~/pages/Workcell/InstrumentPages/GenericInstrumentPage'
import TeachTool from '~/pages/Workcell/InstrumentPages/TeachTool/TeachTool'
import useReloadKey from '~/utils/hooks/useReloadKey'
import cs from './robotic_arm_instrument_page.scss'

interface RoboticArmInstrumentPageProps {
  className?: string
  instrument: Instrument
  status: InstrumentStatus
  live?: boolean
}

const RoboticArmInstrumentPage = ({
  className,
  instrument,
  status,
  live,
}: RoboticArmInstrumentPageProps) => {
  const match = useRouteMatch()

  const [isTeachToolBusy, setIsTeachToolBusy] = React.useState<boolean>(false)
  const [gripperOpen, setGripperOpen] = React.useState<boolean | undefined>(undefined)
  const [bodyFree, setBodyFree] = React.useState<boolean | undefined>(undefined)
  const [showBodyGripperStatus, setShowBodyGripperStatus] = React.useState(true)
  const [reloadKey, refreshReloadKey] = useReloadKey()

  const renderGripperStatus = () => {
    if (gripperOpen === undefined || gripperOpen === null) {
      return <span className={cs.unknown}>Unknown</span>
    }
    return gripperOpen ? (
      <span className={cs.open}>Open</span>
    ) : (
      <span className={cs.closed}>Closed</span>
    )
  }

  const refreshGripperStatus = async () => {
    const res = await roboticArmAPI.is_plate_gripped(instrument.instrumentName)
    setGripperOpen(res.is_plate_gripped === null ? undefined : !res.is_plate_gripped)
  }

  const renderBodyStatus = () => {
    if (bodyFree === undefined || bodyFree === null) {
      return <span className={cs.unknown}>Unknown</span>
    }
    return bodyFree ? (
      <span className={cs.motorsOff}>Off</span>
    ) : (
      <span className={cs.motorsOn}>On</span>
    )
  }

  const renderTeachToolStatus = () => {
    return isTeachToolBusy ? (
      <span className={cs.teachToolBusy}>Busy</span>
    ) : (
      <span className={cs.teachToolReady}>Ready</span>
    )
  }

  const refreshRobotStatus = () => {
    refreshGripperStatus()
    refreshBodyStatus()
  }

  const refreshBodyStatus = async () => {
    const res = await roboticArmAPI.is_body_free(instrument.instrumentName)
    setBodyFree(res.is_body_free === null ? undefined : res.is_body_free)
  }

  useEffect(() => {
    refreshRobotStatus()
  }, [])

  const handleConfigUpdated = () => {
    refreshReloadKey()
  }

  const getAdditionalStatus = (isTeachTool: boolean): JSX.Element | undefined => {
    const elements: JSX.Element[] = []

    if (isTeachTool) {
      elements.push(
        <span className={cs.teachToolStatusText}>
          Teach Tool: {renderTeachToolStatus()}
        </span>,
      )
    }

    if (!isTeachTool || showBodyGripperStatus) {
      elements.push(
        <span className={cs.gripperStatusText}>Gripper: {renderGripperStatus()}</span>,
      )
      elements.push(
        <span className={cs.bodyStatusText}>Motors: {renderBodyStatus()}</span>,
      )
    }

    if (elements.length === 0) {
      return undefined
    }

    // Add a middot between each element
    const statusElements = elements.reduce((acc: JSX.Element[], curr: JSX.Element) => {
      if (acc.length > 0) {
        acc.push(<span>&nbsp;&middot;&nbsp;</span>)
      }
      acc.push(curr)
      return acc
    }, [])

    return <span>{statusElements}</span>
  }

  const renderSidebarContents = () => {
    return (
      <Button
        to={`/workcell/instruments/${instrument.instrumentName}/teach-tool`}
        className={cs.teachToolButton}
        type='primary'
        label='Teach Tool'
      />
    )
  }

  return (
    <Switch>
      <Route
        path={`${match.path}/teach-tool`}
        render={() => (
          <GenericInstrumentPage
            className={className}
            instrument={instrument}
            status={status}
            live={live}
            hideSideBar={true}
            hideInstrumentStatus={true}
            additionalStatusText={getAdditionalStatus(true)}
            bodyContents={
              <TeachTool
                instrumentName={instrument.instrumentName}
                refreshGripperStatus={refreshGripperStatus}
                refreshBodyStatus={refreshBodyStatus}
                reloadKey={reloadKey}
                bodyFree={bodyFree}
                isTeachToolBusy={isTeachToolBusy}
                setIsTeachToolBusy={setIsTeachToolBusy}
                setShowBodyGripperStatus={setShowBodyGripperStatus}
              />
            }
            onConfigUpdated={handleConfigUpdated}
          />
        )}
      />
      <Route
        path={`${match.path}*`}
        render={() => (
          <GenericInstrumentPage
            className={className}
            instrument={instrument}
            status={status}
            live={live}
            additionalStatusText={getAdditionalStatus(false)}
            sidebarContents={renderSidebarContents()}
            onControlCommandComplete={refreshRobotStatus}
          />
        )}
      />
    </Switch>
  )
}

export default RoboticArmInstrumentPage
