import { supportedPlateFormats } from '~/components/TinyMicroplate.interface'

export type ClePhase = 'seeding' | '24wp_expansion' | '6wp_expansion'
export const CLE_PHASES: ClePhase[] = ['seeding', '24wp_expansion', '6wp_expansion']

export interface CellLine {
  cellLineIndex: number
  parentCellLine: string
  desiredEdit: string
  name: string
}

export interface Well {
  confluency: number
}

export interface ClePlate {
  id: string
  plateFormat: supportedPlateFormats
  hasAlert: boolean
  cellLine: CellLine
  phase: ClePhase
  plateNumber: number
  wells: Record<string, Well>
  owner: string
}

export const getCellLineName = (cellLine: CellLine): string => {
  return `${cellLine.parentCellLine}-${cellLine.desiredEdit}`
}

export const plateNumberToChar = (plateNumber: number): string => {
  return String.fromCharCode(plateNumber + 64)
}

export const getClePlateType = (plate: ClePlate): string => {
  if (plate.phase === 'seeding') {
    return 'Seeding'
  }
  if (plate.phase === '24wp_expansion') {
    return '24wp Expansion'
  }
  return '6wp Expansion'
}
