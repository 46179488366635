import { times } from 'lodash'
import resolveSlasDemoAssetsPath from '~/utils/pathResolver'

// Note that this dataset isn't directly rendered, it's used to generate the growth
// curve function.
// Day 0 numbers are doctored to be down by 2 for greater contrast
const IPSC_CULTURE_CONFLUENCY_DATASETS = [
  [21, 31, 50, 77, 97, 96],
  [24, 32, 52, 78, 96, 96],
  [22, 28, 51, 76, 97, 96],
  [24, 34, 56, 78, 97, 96],
  [19, 35, 57, 79, 96, 96],
  [18, 33, 54, 74, 96, 95],
]

export const NUM_DATASETS = IPSC_CULTURE_CONFLUENCY_DATASETS.length

// We only have 6 data points per image - don't run imaging more than that
export const DAYS_TO_IMAGE = 6

// Each well gets 12 images
export const NUM_WELL_IMAGES_VERTICAL = 3
export const NUM_WELL_IMAGES_HORIZONTAL = 4

const IMAGE_DATES = {
  726: {
    0: '20221025_145714',
    1: '20221026_133235',
    2: '20221027_132839',
    3: '20221028_122614',
    4: '20221029_152421',
    5: '20221030_134054',
  },
  // These are used as contaminated images.
  613: {
    // Cultures are never contaminated on day 0, so it is okay to repeat here.
    0: '20221001_115452',
    1: '20221001_115452',
    2: '20221002_104735',
    3: '20221003_131613',
    4: '20221004_125007',
    5: '20221005_103803',
  },
}

const IMAGE_WELLS = { 0: 'A1', 1: 'A2', 2: 'A3', 3: 'B1', 4: 'B2', 5: 'B3' }

function getImageURL({
  cultureId,
  datasetIndex,
  day,
  row,
  col,
  local,
}: {
  cultureId: number
  datasetIndex: number
  day: number
  row: number
  col: number
  local: boolean
}): string {
  const imageIndex = col * 3 + row + 1
  switch (cultureId) {
    case 726:
      return resolveSlasDemoAssetsPath(
        `${cultureId}__${IMAGE_DATES[cultureId][day]}_` +
          `Bright Field_${IMAGE_WELLS[datasetIndex]}_${imageIndex}_001.png`,
        local,
      )
    case 613:
      return resolveSlasDemoAssetsPath(
        `${cultureId}__${IMAGE_DATES[cultureId][day]}_` +
          `Stitched_BF_${IMAGE_WELLS[datasetIndex]}_CROP${imageIndex}_001.png`,
        local,
      )
    default:
      throw new Error('Not found')
  }
}

function getThumbnailURL({
  cultureId,
  datasetIndex,
  day,
  row,
  col,
  local,
}: {
  cultureId: number
  datasetIndex: number
  day: number
  row: number
  col: number
  local: boolean
}): string {
  const imageIndex = col * 3 + row + 1
  switch (cultureId) {
    case 726:
      return resolveSlasDemoAssetsPath(
        `${cultureId}__${IMAGE_DATES[cultureId][day]}_` +
          `Bright Field_${IMAGE_WELLS[datasetIndex]}_${imageIndex}_001_thumbnail.png`,
        local,
      )
    case 613:
      return resolveSlasDemoAssetsPath(
        `${cultureId}__${IMAGE_DATES[cultureId][day]}_` +
          `Stitched_BF_${IMAGE_WELLS[datasetIndex]}_CROP${imageIndex}_001_thumbnail.png`,
        local,
      )
    default:
      throw new Error('Not found')
  }
}

export function getImagesForDataset(
  cultureId: number,
  datasetIndex: number,
  day: number,
  local: boolean,
): string[][] {
  return times(NUM_WELL_IMAGES_VERTICAL, (row: number) =>
    times(NUM_WELL_IMAGES_HORIZONTAL, (col: number) =>
      getImageURL({ cultureId, datasetIndex, day, row, col, local }),
    ),
  )
}

// TODO: Refactor this API - two numbers back-to-back can make callers look unclear

export function getThumbnailsForDataset(
  cultureId: number,
  datasetIndex: number,
  day: number,
  local: boolean,
): string[][] {
  return times(NUM_WELL_IMAGES_VERTICAL, (row: number) =>
    times(NUM_WELL_IMAGES_HORIZONTAL, (col: number) =>
      getThumbnailURL({ cultureId, datasetIndex, day, row, col, local }),
    ),
  )
}

// Note[yang]: This is probably overly complex for demo code
let RANDOM_SEED = 1
export function logisticFun({
  A,
  L,
  k,
  t0,
}: {
  A: number
  L: number
  k: number
  t0: number
}): (t: number) => number {
  // This is a simplification of the generalised logistic function
  // https://en.wikipedia.org/wiki/Generalised_logistic_function
  return t => A + (L - A) / (1 + Math.exp(-k * (t - t0)))
}

export function pseudoRandom(): number {
  // Not truly random, but good enough for demo code
  const x = Math.sin(RANDOM_SEED++) * 10000
  return x - Math.floor(x)
}

export function pseudoShuffle<T>(array: T[]): T[] {
  let currentIndex = array.length,
    randomIndex

  // While there remain elements to shuffle.
  while (currentIndex > 0) {
    // Pick a remaining element.
    randomIndex = Math.floor(pseudoRandom() * currentIndex)
    currentIndex--

    // And swap it with the current element.
    ;[array[currentIndex], array[randomIndex]] = [
      array[randomIndex],
      array[currentIndex],
    ]
  }

  return array
}

export function gaussianRandom(mean = 0, stdDev = 1) {
  // Standard Normal variate using Marsaglia polar method
  // https://en.wikipedia.org/wiki/Marsaglia_polar_method
  // Standard implementation without spare
  let u, v, s
  do {
    u = pseudoRandom() * 2 - 1
    v = pseudoRandom() * 2 - 1
    s = u * u + v * v
  } while (s >= 1 || s == 0)
  s = Math.sqrt((-2.0 * Math.log(s)) / s)
  return mean + stdDev * u * s
}

// These were manually fitted using the IPSC culture confluency datasets.
export const fittedGrowthCurveConstants = [
  { A: 19.52, L: 99.74, k: 1.48, t0: 2.32 },
  { A: 22.19, L: 98.87, k: 1.52, t0: 2.3 },
  { A: 19.47, L: 99.35, k: 1.52, t0: 2.33 },
  { A: 20.12, L: 99.81, k: 1.34, t0: 2.17 },
  { A: 10.44, L: 100.54, k: 1.14, t0: 1.92 },
  { A: 9.74, L: 101.33, k: 1.08, t0: 2.07 },
]

export function getConfluencyForDataset(datasetIndex: number, day: number): number {
  // To inject noise, let's imagine a scenario where the dataset imaging time is the
  // variate. Try a standard deviation of 30 minutes.
  try {
    const stdDevInDays = 30 / 60 / 24
    const imagingTime = day + gaussianRandom(0, stdDevInDays)
    return Number(
      logisticFun(fittedGrowthCurveConstants[datasetIndex])(imagingTime).toFixed(1),
    )
  } catch (e) {
    throw new Error(`Failed to get confluence for ${datasetIndex}, ${day}`)
  }
}

export function getPredictedConfluencyForDataset(
  datasetIndex: number,
  day: number,
): number {
  // Growth curve function without noise
  try {
    return Number(logisticFun(fittedGrowthCurveConstants[datasetIndex])(day).toFixed(1))
  } catch (e) {
    throw new Error(`Failed to get confluence for ${datasetIndex}, ${day}`)
  }
}

export function getPredictedDayForConfluency(
  datasetIndex: number,
  confluency: number,
): number {
  // Naive search for now. Really we should be deriving and using the inverse function
  const searchStep = 0.01
  const searchRange = [2, 4]
  const tolerance = 0.1

  for (let day = searchRange[0]; day < searchRange[1]; day += searchStep) {
    const predictedConfluence = Number(
      logisticFun(fittedGrowthCurveConstants[datasetIndex])(day),
    )
    const diff = predictedConfluence - confluency
    if (Math.abs(diff) <= tolerance) {
      return day
    }
  }
  throw new Error(`Failed to get predicted day for confluency`)
}
