import { has } from 'lodash/fp'
import { ProcessItemFilters } from './ProcessItemFilters'

export const getProcessItemFiltersWithDefaults = (
  processItemFilters: ProcessItemFilters,
): ProcessItemFilters => {
  return {
    ...processItemFilters,
    // This allows processItemFilters to explicitly specify is_checked_in: undefined
    // which allows BOTH true and false.
    ...(!has('is_checked_in', processItemFilters) ? { is_checked_in: true } : {}),
    ...(!has('is_dead', processItemFilters) ? { is_dead: false } : {}),
  }
}
