import { difference, union } from 'lodash/fp'
import {
  getWellNamesBetweenColumns,
  getWellNamesBetweenWellCoords,
  getWellNamesToRightOfWell,
} from '~/utils/microplate'
import { DragState } from './DragState.interface'
import { SelectOptions } from './SelectOptions.interface'

export const getNewSelectedWellArrayWithDragState = (
  dragState: DragState,
  row: number,
  col: number,
  selectOptions: SelectOptions,
) => {
  if (!selectOptions.mustPackRightSide) {
    const wellNamesToToggle = selectOptions.allowFullColumnsOnly
      ? getWellNamesBetweenColumns(dragState.startCol, col, selectOptions.dims)
      : getWellNamesBetweenWellCoords(dragState.startRow, dragState.startCol, row, col)

    if (dragState.toggleOn) {
      return union(dragState.originalSelectedWellArray, wellNamesToToggle)
    } else {
      return difference(dragState.originalSelectedWellArray, wellNamesToToggle)
    }
  }
  return selectOptions.allowFullColumnsOnly
    ? getWellNamesBetweenColumns(
        col,
        selectOptions.dims.numCols - 1,
        selectOptions.dims,
      )
    : getWellNamesToRightOfWell(row, col, selectOptions.dims)
}
