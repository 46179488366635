// Use this hook to execute control commands on an instrument.
// NOTE: This only works for "normal" control commands, where the instrument is BUSY
// while the command is executing and READY when the command has succeeded.
// This will not work for commands like start_driver, stop_driver, and restart_driver.
import { useContext, useEffect, useState } from 'react'

import commonDriverAPI from '~/api/desktop/commonDriver'
import WorkcellStatusContext from '~/pages/Workcell/WorkcellStatusContext'
import {
  getInstrumentStatus,
  getIsInstrumentErrored,
  getIsInstrumentReady,
} from '~/pages/Workcell/utils/workcellStatus'
import { getInstrumentErrorMessage } from '../instrument'

// It can take a moment for the server to transition from READY to BUSY.
// We ignore READY statuses within a delay, before assuming that the command has finished.
const COMMAND_DELAY = 2000

interface InstrumentControlCommandExecutorResult {
  // Whether the instrument is ready to accept a new command.
  instrumentIsReady: boolean
  // The name of the command that is currently being executed.
  currentCommandExecuted: string | null
  // Execute a control command on the instrument.
  executeCommand: (command: string, commandParams: unknown[]) => void
}

export default function useInstrumentControlCommandExecutor(
  // The name of the instrument to execute commands on.
  instrumentName: string,
  // Callback to execute when the command has succeeded.
  onCommandSuccess: () => void,
  // Callback to execute when the command has failed.
  onCommandError: (error: string) => void,
): InstrumentControlCommandExecutorResult {
  const [currentCommandExecuted, setCurrentCommandExecuted] = useState<string | null>(
    null,
  )
  // Whether the last command started executing. See note above regarding the command delay.
  const [executionStartTime, setExecutionStartTime] = useState<Date | null>(null)

  const workcellStatus = useContext(WorkcellStatusContext)
  const instrumentStatus = getInstrumentStatus(workcellStatus, instrumentName)

  useEffect(() => {
    if (currentCommandExecuted) {
      if (getIsInstrumentErrored(workcellStatus, instrumentName)) {
        setCurrentCommandExecuted(null)
        onCommandError(getInstrumentErrorMessage(instrumentStatus))
      } else if (
        getIsInstrumentReady(workcellStatus, instrumentName) &&
        executionStartTime &&
        new Date().getTime() - executionStartTime.getTime() > COMMAND_DELAY
      ) {
        setCurrentCommandExecuted(null)
        onCommandSuccess()
      }
    }
  }, [instrumentStatus])

  const executeCommand = async (command: string, commandParams: unknown[] = []) => {
    setExecutionStartTime(new Date())
    setCurrentCommandExecuted(command)

    try {
      const response = await commonDriverAPI.executeControlCommand(
        instrumentName,
        command,
        commandParams,
      )
      if (!response.success) {
        onCommandError('There was an issue executing the control command')
        setCurrentCommandExecuted(null)
      }
    } catch (error) {
      onCommandError('There was an issue executing the control command')
      setCurrentCommandExecuted(null)
    }
  }

  const instrumentIsReady = getIsInstrumentReady(workcellStatus, instrumentName)

  return { instrumentIsReady, currentCommandExecuted, executeCommand }
}
