import cx from 'classnames'
import { Link } from 'react-router-dom'

import { CultureViewModel } from '../../events/ViewModels/CultureViewModel'
import { CultureIssue } from './CultureIssue'
import { CultureStatus } from './CultureStatus'
import cs from './culture_page_header.scss'

export interface CulturePageHeaderProps {
  className?: string
  culture: CultureViewModel
  today: number
}

const CulturePageHeader = ({ culture, className, today }: CulturePageHeaderProps) => {
  return (
    <div className={cx(className, cs.culturePageHeader)}>
      <Link to={'/command-center/live-cultures/'} className={cs.goBackLabel}>
        Back to Live Cultures
      </Link>
      <div className={cs.issue}>
        <CultureIssue culture={culture} day={today} />
      </div>
      <div className={cs.title}>
        <div className={cs.titleInner}>{culture.name}</div>
      </div>
      <CultureStatus culture={culture} />
    </div>
  )
}

export default CulturePageHeader
