/*
  In order to smoothly slide the timeline left on every tick,
  while still allowing for larger animations, we use a weird trick.

  We use both the x property and the translateX property when rendering
  the x coordinate of a bar.

  After the viz has been visible for X seconds,
  translateX shifts each bar to the left by X seconds.
  We need to make sure the x property does NOT move the bar left.

  If we kept the x property constant, the bar would move left,
  because the xScale is shifting to the left.

  If we kept the x property constant and increased the translateX,
  the bar would move to the left twice as fast as it should.

  So we increase the translateX (to smoothly animate), and decrease
  the X value by the same amount.

  This allows us to perform animates on the X value, while updating
  the translateX every tick for a real-time effect.

  Bars normally keep the same startTime and move to the left.

  Bars can sometimes be paused, for example if the workcell is paused.
  This means their startTime keeps increasing in real-time.

  Since we re-fetch the startTime every second, the startTime would
  suddenly increase every second, causing a jump on the bar.
  To prevent this in most cases, we identify bars that are paused,
  and maintain a xPauseOffsetMs prop which properly offsets the bar.
*/

import cx from 'classnames'
import { Axis, axisBottom, axisTop } from 'd3-axis'
import { easeLinear } from 'd3-ease'
import { scaleTime } from 'd3-scale'
import { timeHour, timeMinute, timeSecond } from 'd3-time'
import { timeFormat } from 'd3-time-format'
import { Timer, interval } from 'd3-timer'
import { active } from 'd3-transition'
import dayjs from 'dayjs'
import { any, keyBy, map, merge, noop, reject } from 'lodash/fp'

import D3Chart, { D3ChartDomain } from '~/components/d3/D3Chart'
import { getRectWithinContainer } from '~/utils/svg'

import { GetLiveStepsResponse, ProcessStepStatus } from '~/api/desktop/workcell'
import { ISODateString } from '~/types/ISODateString.interface'
import cs from './d3_process_timeline_viz.scss'

interface TimelineProcessStep {
  startTime?: ISODateString
  endTime?: ISODateString
  status: ProcessStepStatus
  uuid: string
  instrumentName: string
  widthPauseOffsetMs: number
  xPauseOffsetMs: number
}

type WorkcellStatus = {
  live: boolean
}

interface BarHoverParams {
  top: number
  left: number
  width: number
  height: number
  data: TimelineProcessStep
}

// For now, D3ProcessTimelineViz depends on this exact response structure.
export type D3ProcessTimelineVizData = GetLiveStepsResponse

export type Timerange = '1M' | '10M' | '1H' | '1D'

export interface D3ProcessTimelineVizOptions {
  bandHeight: number
  onBarHover?: (step: BarHoverParams | null) => void
  instruments: {
    instrumentName: string
  }[]
  timerange: Timerange
}

type BarState = 'paused' | 'stalled' | 'default'

const DEFAULT_OPTIONS: D3ProcessTimelineVizOptions = {
  bandHeight: 75,
  onBarHover: noop,
  instruments: [],
  timerange: '10M',
}

const PADDING_TOP = 75
const BAR_HEIGHT = 16

export default class D3ProcessTimelineViz extends D3Chart<
  D3ProcessTimelineVizData,
  Date,
  Date
> {
  options: Partial<D3ProcessTimelineVizOptions>
  timer: Timer
  instrumentIndices: { [key: string]: number }
  workcellStatus: WorkcellStatus | null
  data: TimelineProcessStep[] | null
  totalElapsedMs: number
  barState: { [key: string]: BarState }
  timerangeChanged: boolean
  timerangeChangedAxis: boolean
  lastTick: number
  processLinesLayer!: d3.Selection<SVGGElement, unknown, null, undefined>
  barsLayer!: d3.Selection<SVGGElement, unknown, null, undefined>
  curTimeMarkerLayer!: d3.Selection<SVGGElement, unknown, null, undefined>
  curTimeMarker!: d3.Selection<SVGLineElement, unknown, null, undefined>

  constructor(
    container,
    layoutOptions = {},
    axisOptions = {},
    chartOptions: Partial<D3ProcessTimelineVizOptions> = {},
  ) {
    super(container, cs.d3ProcessTimelineViz, layoutOptions, axisOptions)
    this.options = chartOptions

    this.initializeLayers()

    this.timer = interval(this.onTimer, 50)
    this.instrumentIndices = {}

    if (chartOptions.instruments) {
      chartOptions.instruments.forEach((instrument, index) => {
        this.instrumentIndices[instrument.instrumentName] = index
      })
    }

    /*
      {
        live,
        status,
        state,
      }
    */
    this.workcellStatus = null
    this.data = null

    // The amount of time since this viz was first initialized.
    // We use this trick to get smoothly animating, real-time bars.
    // As time passes, we shift all bars to the left with transform,
    // and shift them back to the right on the x attribute.
    this.totalElapsedMs = 0

    // Whether the bar is currently stalled or paused.
    this.barState = {}

    this.timerangeChanged = false
    this.timerangeChangedAxis = false

    this.lastTick = Date.now()
  }

  teardown = () => {
    this.timer.stop()
  }

  getOptions = () => {
    return merge(DEFAULT_OPTIONS, this.options)
  }

  initializeLayers = () => {
    this.processLinesLayer = this.dataLayer
      .append('g')
      .attr('class', cs.processLinesLayer)
    const dim = this.getChartDimensions()
    const options = this.getOptions()

    // append the bar rectangles to the svg element
    this.processLinesLayer
      .selectAll('line')
      .data(options.instruments)
      .enter()
      .append('line')
      .attr('x1', 0)
      .attr('y1', (d, i) => options.bandHeight * i + PADDING_TOP)
      .attr('x2', dim.width)
      .attr('y2', (d, i) => options.bandHeight * i + PADDING_TOP)
      .attr('class', cs.processLine)

    this.barsLayer = this.dataLayer.append('g').attr('class', 'barsLayer')
    this.curTimeMarkerLayer = this.dataLayer
      .append('g')
      .attr('class', cs.curTimeMarkerLayer)

    this.curTimeMarker = this.curTimeMarkerLayer.append('line')
  }

  getDomains = () => {
    const currentTime = dayjs()
    let xDomain = [
      currentTime.subtract(dayjs.duration(5, 's')).toDate(),
      currentTime.add(dayjs.duration(1, 'm')).toDate(),
    ]
    const options = this.getOptions()

    if (options.timerange === '10M') {
      xDomain = [
        currentTime.subtract(dayjs.duration(50, 's')).toDate(),
        currentTime.add(dayjs.duration(10, 'm')).toDate(),
      ]
    }

    if (options.timerange === '1H') {
      xDomain = [
        currentTime.subtract(dayjs.duration(5, 'm')).toDate(),
        currentTime.add(dayjs.duration(1, 'h')).toDate(),
      ]
    }

    if (options.timerange === '1D') {
      xDomain = [
        currentTime.subtract(dayjs.duration(2, 'h')).toDate(),
        currentTime.add(dayjs.duration(1, 'd')).toDate(),
      ]
    }
    return {
      xDomain: xDomain as D3ChartDomain<Date>,
      yDomain: [new Date(), new Date()] as D3ChartDomain<Date>,
    }
  }

  getXScale = xDomain => {
    const dim = this.getChartDimensions()

    return scaleTime().domain(xDomain).range([0, dim.width])
  }

  // TODO(mark): Maybe D3Chart shouldn't require getYScale.
  getYScale = () => scaleTime()

  setXTicks = xAxis => {
    const options = this.getOptions()
    if (options.timerange === '1M') {
      return xAxis.ticks(timeSecond.every(10)).tickFormat(timeFormat('%I:%M:%S %p'))
    }
    if (options.timerange === '10M') {
      return xAxis.ticks(timeMinute.every(1)).tickFormat(timeFormat('%I:%M %p'))
    }

    if (options.timerange === '1H') {
      return xAxis.ticks(timeMinute.every(10)).tickFormat(timeFormat('%I:%M %p'))
    }

    if (options.timerange === '1D') {
      return xAxis.ticks(timeHour.every(4)).tickFormat(timeFormat('%I:%M %p'))
    }
    return xAxis
  }

  getXAxis = xScale => {
    let xAxis: Axis<Date>
    const options = this.axisOptions
    if (options.xAxisPosition === 'bottom') {
      xAxis = axisBottom(xScale)
    } else {
      // Assume top
      xAxis = axisTop(xScale)
    }

    xAxis = xAxis.ticks(timeSecond.every(10)).tickFormat(timeFormat('%I:%M:%S %p'))

    return xAxis
  }

  // Get the x value for a particular time.
  getX = (time, additionalOffset = 0) => {
    let x = dayjs(time)

    // Always add the total elapsed time.
    // Also add an additional offset.
    x = x.add(this.totalElapsedMs + additionalOffset, 'ms')
    const returnX = this.xScale && this.xScale(x.toDate())
    return returnX || 0
  }

  getStartX = (step, additionalOffset = 0) => {
    return this.getX(step.startTime, step.xPauseOffsetMs + additionalOffset) || 0
  }

  getWidth = (step, additionalOffset = 0) => {
    return (
      this.getX(step.endTime, step.widthPauseOffsetMs + additionalOffset) -
      this.getX(step.startTime)
    )
  }

  getClasses = step => {
    return cx(
      cs.processRect,
      step.status === 'in_progress' && cs.inProgress,
      step.status === 'failed' && cs.failed,
      step.routineType !== 'default' && cs.support,
    )
  }

  updateChartOptions = options => {
    // Detect if the timerange changed, which requires a special animation.
    if (this.options.timerange !== options.timerange) {
      this.timerangeChanged = true
      this.timerangeChangedAxis = true
    }

    this.options = options
    if (this._data) {
      this.updateData(this._data)
    }
  }

  isWorkcellDelayed = steps => {
    // For now, if any step is delayed, the workcell is considered delayed.
    // Later, we may need to only delay steps with a dependency on a delayed step.
    const curTime = dayjs()
    const delays = any(
      d =>
        (d.status === 'scheduled' && dayjs(d.startTime) <= curTime) ||
        (d.status === 'in_progress' && dayjs(d.endTime) <= curTime) ||
        (d.status === 'failed' && dayjs(d.endTime) <= curTime),
      steps,
    )

    const noExecution =
      !any(d => d.status === 'in_progress' || d.status === 'failed', steps) &&
      !(this.workcellStatus && this.workcellStatus.live)

    return noExecution || delays
  }

  stepsFailed = steps => {
    return any(d => d.status === 'failed', steps)
  }

  // Determine which bars are stalled or paused, for animation purpuses.
  updateBarState = () => {
    this.barState = {}
    if (!this.data) {
      return
    }

    const isWorkcellDelayed = this.isWorkcellDelayed(this.data)
    const curTime = dayjs()

    this.data.forEach(step => {
      if (
        (step.status === 'in_progress' || step.status === 'failed') &&
        dayjs(step.endTime) <= curTime
      ) {
        this.barState[step.uuid] = 'stalled'
      } else if (isWorkcellDelayed) {
        this.barState[step.uuid] = 'paused'
      } else {
        this.barState[step.uuid] = 'default'
      }
    })
  }

  updateData = (data: D3ProcessTimelineVizData) => {
    const options = this.getOptions()
    this.workcellStatus = data.workcellStatus
    const oldSteps = this.data
    const oldStepMap = keyBy('uuid', this.data)
    const stepMap = keyBy('uuid', data.steps)

    let newData = data.steps

    // Hide any bars with length 0.
    // Certain bars such as data commands have length 0
    // to hide them from the viz.
    newData = reject(datum => {
      return datum.status === 'canceled' || datum.endTime === datum.startTime
    }, newData)

    this.data = map(datum => {
      return {
        ...datum,
        xPauseOffsetMs: 0,
        widthPauseOffsetMs: 0,
      }
    }, newData)

    this.updateBarState()
    this.rerenderAxisHelper()

    if (!this.data || !this.xScale) return

    const xScale = this.xScale

    // append the bar rectangles to the svg element
    const bars = this.barsLayer
      .selectAll<SVGRectElement, TimelineProcessStep>('rect')
      .data(this.data, d => d.uuid)

    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const _viz: D3ProcessTimelineViz = this
    let barsUpdate = bars
      .enter()
      .append('rect')
      .on('mouseover', function handleMouseOver() {
        // Provide data to tooltip handler.
        if (_viz.options.onBarHover) {
          if (!_viz.svg) return
          const node = _viz.svg.node()
          if (!node) return
          // @ts-expect-error: d3 adds __data__ to SVGRectElement
          const barData = this.__data__
          const rect = getRectWithinContainer(
            this.getBoundingClientRect(),
            node.getBoundingClientRect(),
          )
          _viz.options.onBarHover({
            top: rect.top,
            left: rect.left,
            width: rect.width,
            height: rect.height,
            data: barData,
          })
        }
      })
      .on('mouseout', () => {
        if (_viz.options.onBarHover) {
          _viz.options.onBarHover(null)
        }
      })
      // Set the bar a little to the right so we do a small left animation.
      .attr('x', d => (this.getStartX(d) || 0) + 25)
      .attr(
        'y',
        d =>
          this.instrumentIndices[d.instrumentName] * options.bandHeight +
          PADDING_TOP -
          BAR_HEIGHT / 2,
      )
      .attr('width', this.getWidth)
      .attr('height', BAR_HEIGHT)
      // Update the transform immediately.
      .attr('transform', () => {
        const curTime = dayjs()
        const curTimeOther = curTime.add(this.totalElapsedMs, 'ms')
        const dx =
          (xScale(curTime.toDate()) || 0) - (xScale(curTimeOther.toDate()) || 0)
        return `translate(${dx}, 0)`
      })
      .attr('opacity', 0)
      .merge(bars)

    // If it's been too long since the last update,
    // this means the browser tab is closed.
    // Skip animations so animations don't pile up.
    // There SHOULD be a tick every 50 ms.
    const skipAnimation = Date.now() - this.lastTick > 2000

    // Enter and update animation.
    if (!skipAnimation) {
      // @ts-expect-error: Typescript complains that Transition is not the same as Selection.
      // The code itself works.
      barsUpdate = barsUpdate
        // We are about to apply a transition on the x property.
        // Only apply the transition to certain elements.
        .filter(d => {
          // If bar has just entered, transition.
          if (!oldStepMap[d.uuid] && stepMap[d.uuid]) return true

          if (this.stepsFailed(oldSteps) && !this.stepsFailed(this.data)) return true

          // If we were paused before and are still paused, do not transition.
          // It will cause unnecessary jitter.
          if (this.isWorkcellDelayed(oldSteps) && this.isWorkcellDelayed(this.data))
            return false
          // Only animate if the timing has changed. Otherwise, no reason.
          return (
            oldStepMap[d.uuid].startTime !== stepMap[d.uuid].startTime ||
            oldStepMap[d.uuid].endTime !== stepMap[d.uuid].endTime ||
            oldStepMap[d.uuid].status !== stepMap[d.uuid].status
          )
        })
        .transition()
        .duration(400)
        .ease(easeLinear)
    }

    barsUpdate
      .attr('class', d => this.getClasses(d))
      .attr('x', d => {
        // Try to anticipate where the transform will be when the transition ends.
        // Unfortunately, there can still be a small jump when the transition ends.
        return this.getStartX(d, this.isBarPaused(d) ? 300 : 0)
      })
      .attr('width', d => {
        // Try to anticipate where the transform will be when the transition ends.
        // Unfortunately, there can still be a small jump when the transition ends.
        return this.getWidth(d, this.isBarStalled(d) ? 300 : 0)
      })
      .attr('opacity', 1)

    // Exit animation.
    bars
      .exit()
      .transition()
      .duration(400)
      .attr('x', d => {
        return (this.getStartX(d) || 0) - 25
      })
      .attr('opacity', 0)
      .remove()
  }

  // This is called on every frame.
  rerenderData = () => {
    const curTime = dayjs()
    const dim = this.getChartDimensions()

    if (!this.data || !this.xScale) return

    const xScale = this.xScale
    this.curTimeMarker
      .attr('class', cs.curTimeMarker)
      .attr('x1', xScale(curTime.toDate()) || 0)
      .attr('x2', xScale(curTime.toDate()) || 0)
      .attr('y1', 0)
      .attr('y2', dim.height)

    // Only do an animation if the time range changed.
    if (this.timerangeChanged) {
      this.timerangeChanged = false
      this.barsLayer
        .selectAll<SVGRectElement, TimelineProcessStep>('rect')
        .data(this.data, d => d.uuid)
        .transition()
        .duration(200)
        .ease(easeLinear)
        .attr('transform', () => {
          // Try to anticipate where the transform will be when the transition ends.
          // Unfortunately, there can still be a small jump when the transition ends.
          const curTimeOther = curTime.add(this.totalElapsedMs + 400, 'ms')
          const dx =
            (xScale(curTime.toDate()) || 0) - (xScale(curTimeOther.toDate()) || 0)
          return `translate(${dx}, 0)`
        })
        .attr('x', d => {
          // Try to anticipate where the bar will be in the future.
          return this.getStartX(d, this.isBarPaused(d) ? 300 : 0)
        })
        .attr('width', d => {
          // Try to anticipate where the transform will be when the transition ends.
          // Unfortunately, there can still be a small jump when the transition ends.
          return this.getWidth(d, this.isBarStalled(d) ? 300 : 0)
        })
    } else {
      this.barsLayer
        .selectAll<SVGRectElement, TimelineProcessStep>('rect')
        .data(this.data, d => d.uuid)
        // Translate the rects forward for a smooth real-time effect.
        .attr('transform', () => {
          const curTimeOther = curTime.add(this.totalElapsedMs, 'ms')
          const dx =
            (xScale(curTime.toDate()) || 0) - (xScale(curTimeOther.toDate()) || 0)
          return `translate(${dx}, 0)`
        })
        // Don't update if there's an active transition happening.
        .filter(function hasActiveTransition() {
          return active(this) === null
        })
        // Note: This doesn't cancel the animation if the same value is passed over and over.
        // Only update x if bar is paused.
        .filter(d => this.isBarPaused(d) || this.isBarStalled(d))
        .attr('x', this.getStartX)
        .attr('width', this.getWidth)
    }
  }

  isBarPaused = step => {
    return this.barState[step.uuid] === 'paused'
  }

  isBarStalled = step => {
    return this.barState[step.uuid] === 'stalled'
  }

  // Update xPauseOffset and widthPauseOffset for bars.
  updateDataPausedMs = dt => {
    this.data = map(data => {
      if (this.isBarPaused(data)) {
        return {
          ...data,
          xPauseOffsetMs: data.xPauseOffsetMs + dt,
        }
      }
      if (this.isBarStalled(data)) {
        return {
          ...data,
          widthPauseOffsetMs: data.widthPauseOffsetMs + dt,
        }
      }
      return data
    }, this.data)
  }

  // This calls on every frame.
  // This is NOT called when the browser tab is hidden.
  onTimer = elapsedTime => {
    const dt = elapsedTime - this.totalElapsedMs
    this.updateBarState()
    this.updateDataPausedMs(dt)
    this.totalElapsedMs = elapsedTime
    this.rerenderAxisHelper()
    this.rerenderData()
    this.lastTick = Date.now()
  }

  rerenderAxisHelper = () => {
    // We only want to trigger an animation on the axis once,
    // when the timerange changes.
    if (this.timerangeChangedAxis) {
      // @ts-expect-error: This chart doesn't need data to rerender the axis.
      this.rerenderAxis(null, 200)
      this.timerangeChangedAxis = false
    } else {
      // @ts-expect-error: This chart doesn't need data to rerender the axis.
      this.rerenderAxis()
    }
  }
}
