import React from 'react'

import { LoadUnloadItemsConfig } from '../LoadUnloadItemsAction'
import {
  PROCESS_ITEM_TYPE_TO_PARAMS_TYPE,
  ProcessItemParamsType,
  getProcessItemTypeConfig,
} from '../processItemTypeConfig/getProcessItemTypeConfig'
import { LoadUnloadItemsProcessItemFormComponentProps } from './LoadUnloadItemsProcessItemFormComponentProps.interface'

export interface LoadUnloadItemsProcessItemFormProps<
  TType extends keyof PROCESS_ITEM_TYPE_TO_PARAMS_TYPE,
> {
  className?: string
  selectedType: TType
  disabled?: boolean
  processItemParams: Partial<ProcessItemParamsType<TType>>
  setProcessItemParams: (
    setParams: (
      params: Partial<ProcessItemParamsType<TType>>,
    ) => Partial<ProcessItemParamsType<TType>>,
  ) => void
  config: LoadUnloadItemsConfig
}

const LoadUnloadItemsProcessItemForm = <
  TType extends keyof PROCESS_ITEM_TYPE_TO_PARAMS_TYPE,
>({
  className,
  selectedType,
  processItemParams,
  setProcessItemParams,
  disabled,
  config,
}: LoadUnloadItemsProcessItemFormProps<TType>) => {
  const processItemTypeConfig = getProcessItemTypeConfig(selectedType)
  // formComponent has this type in ProcessItemTypeConfig.interface.ts.
  // But TS is giving me an error.
  // Adding "as unknown" here as a workaround until we figure out this typing.
  const FormComponent =
    processItemTypeConfig.formComponent as unknown as React.FunctionComponent<
      LoadUnloadItemsProcessItemFormComponentProps<TType>
    >

  return (
    <FormComponent
      className={className}
      processItemParams={processItemParams}
      setProcessItemParams={setProcessItemParams}
      disabled={disabled}
      config={config}
    />
  )
}

export default LoadUnloadItemsProcessItemForm
