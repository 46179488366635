import cx from 'classnames'
import PropTypes from 'prop-types'
import { useState } from 'react'

import cs from './textarea.scss'

interface TextareaProps {
  className?: string
  value: string
  onChange: (value: string) => void
  changeOnBlur?: boolean
  disabled?: boolean
  rows?: number
  placeholder?: string
}

function Textarea(props: TextareaProps) {
  const { value, onChange, changeOnBlur, className, rows, disabled, placeholder } =
    props
  const [storedValue, setStoredValue] = useState(null)

  const onInputChange = event => {
    if (!changeOnBlur) {
      onChange(event.target.value)
    } else {
      setStoredValue(event.target.value)
    }
  }

  const onInputBlur = () => {
    if (changeOnBlur) {
      if (storedValue !== null) {
        onChange(storedValue)
        setStoredValue(null)
      }
    }
  }

  return (
    <textarea
      className={cx(className, cs.textarea, disabled && cs.disabled)}
      value={storedValue !== null ? storedValue : value}
      onChange={onInputChange}
      onBlur={onInputBlur}
      rows={rows}
      disabled={disabled}
      placeholder={placeholder}
    />
  )
}

Textarea.propTypes = {
  className: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  changeOnBlur: PropTypes.bool,
  disabled: PropTypes.bool,
  rows: PropTypes.number,
}

export default Textarea
