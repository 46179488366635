import cx from 'classnames'
import { compact, entries, flatten, flow, get, map, orderBy } from 'lodash/fp'

import Table from '~/components/Table'
import Notification from '~/components/notifications/Notification'

import { Instrument, InstrumentStatus } from '~/common.interface'
import ProcessItem from './ProcessItem'
import cs from './transfer_stations.scss'

interface TransferStationsProps {
  className?: string
  instruments: Instrument[] | null
  instrumentStatus?: Record<string, InstrumentStatus>
  live?: boolean
}

interface TransferStationRow {
  transferStationName: string
  processItem: string
  instrumentName: string
  rowKey: string
}

const TransferStations = ({
  className,
  instruments,
  instrumentStatus,
  live,
}: TransferStationsProps) => {
  if (!instruments || !instrumentStatus) return null

  const transferStations: TransferStationRow[] = flow(
    entries,
    map(([instrumentName, status]: [string, InstrumentStatus]) =>
      status.transfer_stations
        ? map(
            ([transferStationName, processItem]) => ({
              transferStationName,
              processItem,
              instrumentName,
              rowKey: `${instrumentName}_${transferStationName}`,
            }),
            entries(status.transfer_stations),
          )
        : null,
    ),
    flatten,
    compact,
    orderBy([get('instrumentName'), get('transferStationName')], ['asc', 'asc']),
  )(instrumentStatus)

  const tableColumns = [
    {
      name: 'Instrument',
      width: 200,
      render: (row: TransferStationRow) => row.instrumentName,
      smallText: true,
    },
    {
      name: 'Transfer Station',
      width: 250,
      render: (row: TransferStationRow) => <b>{row.transferStationName}</b>,
    },
    {
      name: 'Process Item',
      width: 300,
      render: (row: TransferStationRow) => (
        <ProcessItem className={cs.processItem} processItem={row} live={live} />
      ),
    },
  ]

  return (
    <div className={cx(className, cs.transferStations)}>
      {live && (
        <Notification
          type='accent'
          label='Workcell is live. Cannot unassign items from transfer stations.'
          className={cs.liveNotification}
        />
      )}
      <Table<TransferStationRow>
        columns={tableColumns}
        data={transferStations}
        className={cs.table}
        heightSizing='default'
        rowPaddingVariant='rowPaddingLow'
        rowKey='rowKey'
      />
    </div>
  )
}

export default TransferStations
