import store from 'store2'

const configDb = store.namespace('slas-demo')
const CONFIG_KEY = 'config'

export default {
  getDemoConfigSchema: () => {
    // Note: This was directly copied from the current schema response. Long-term we will
    // want to revisit this pattern and type this natively.
    return {
      schema: {
        title: 'DemoConfig',
        type: 'object',
        properties: {
          profile: {
            $ref: '#/definitions/ProfileName',
          },
        },
        required: ['profile'],
        definitions: {
          ProfileName: {
            title: 'ProfileName',
            description: 'An enumeration.',
            enum: ['slas_demo', 'ipsc_monitor', 'slas_demo_no_contam'],
            type: 'string',
          },
        },
      },
    }
  },
  getDemoConfig: () => {
    // Default config based on legacy behavior
    const defaultConfig = { profile: 'slas_demo' }
    return configDb.get(CONFIG_KEY) ?? defaultConfig
  },
  setDemoConfig: config => {
    const existingConfig = configDb.get(CONFIG_KEY)
    configDb.set('config', { ...existingConfig, ...config })
    // Note: This mimics the legacy response from a successful update
    return { success: true }
  },
}

// Helpful for debugging projections - you can use this from the Chrome console to reset the data
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
window.__debug_clearDemoConfigAndRefresh = () => {
  configDb.remove(CONFIG_KEY)
  window.location.reload()
}
