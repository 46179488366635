import PropTypes from 'prop-types'
import { IconProps } from './types/IconProps'
const DownloadIcon = ({ className, onClick }: IconProps) => (
  <svg
    width='24'
    height='24'
    viewBox='0 0 24 24'
    xmlns='http://www.w3.org/2000/svg'
    onClick={onClick}
    className={className}
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M19.35 10.04C18.67 6.59 15.64 4 12 4C9.10999 4 6.60001 5.64 5.35001 8.04C2.34 8.36 0 10.91 0 14C0 17.31 2.69 20 6 20H19C21.76 20 24 17.76 24 15C24 12.36 21.95 10.22 19.35 10.04ZM19 18H6C3.79001 18 2 16.21 2 14C2 11.95 3.53 10.24 5.56 10.03L6.63 9.92L7.13 8.97C8.07999 7.14 9.94 6 12 6C14.62 6 16.88 7.86 17.39 10.43L17.69 11.93L19.22 12.04C20.78 12.14 22 13.45 22 15C22 16.65 20.65 18 19 18ZM10.55 10H13.45V13H16L12 17L8 13H10.55V10Z'
    />
  </svg>
)

DownloadIcon.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
}

export default DownloadIcon
