// See /ui/buttons for Button playground

import cx from 'classnames'
import { includes } from 'lodash/fp'
import PropTypes from 'prop-types'
import React, { MouseEventHandler } from 'react'
import { Link } from 'react-router-dom'

import HelpPopover from '../HelpPopover'
import csDefault from './button.scss'
import csBare from './button_minimal.scss'

interface ButtonProps {
  className?: string
  label?: string
  to?: string
  IconComponent?: React.ElementType
  onClick?: MouseEventHandler<HTMLButtonElement> | ((...args: unknown[]) => unknown)
  type?: 'primary' | 'normal' | 'danger'
  variant?: 'default' | 'minimal' | 'minimalBold' | 'minimalTiny'
  disabled?: boolean
  external?: boolean
  appearNormalIfNotHovered?: boolean
  popoverMessageIfDisabled?: string
}

const Button = ({
  label,
  onClick,
  type = 'normal',
  className,
  IconComponent,
  disabled,
  variant = 'default',
  to,
  external,
  appearNormalIfNotHovered,
  popoverMessageIfDisabled,
}: ButtonProps) => {
  let cs = csDefault
  if (includes(variant, ['minimalBold', 'minimal', 'minimalTiny'])) {
    cs = csBare
  }
  const button = (
    <button
      type='button'
      className={cx(
        className,
        cs.button,
        cs[type],
        cs[variant],
        (onClick || to) && !disabled && cs.clickable,
        disabled && cs.disabled,
        appearNormalIfNotHovered && cs.appearNormalIfNotHovered,
      )}
      onClick={!disabled ? onClick : undefined}
    >
      <div className={cs.inner}>
        {IconComponent && <IconComponent className={cs.icon} />}
        <div className={cs.label}>{label}</div>
      </div>
    </button>
  )

  let element = button

  if (to) {
    if (external) {
      element = (
        <a
          href={to}
          className={cx(className, cs.link)}
          target='blank'
          rel='noopener noreferrer'
        >
          {button}
        </a>
      )
    } else {
      element = <Link to={to}>{button}</Link>
    }
  }

  if (disabled && popoverMessageIfDisabled) {
    return (
      <HelpPopover
        text={element}
        helpContent={popoverMessageIfDisabled}
        interactionKind='hover'
        smallText={false}
      />
    )
  }

  return element
}

Button.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  // If to is provided, wrap button in a Link.
  to: PropTypes.string,
  IconComponent: PropTypes.oneOfType([
    PropTypes.instanceOf(React.Component),
    PropTypes.func,
  ]),
  onClick: PropTypes.func,
  type: PropTypes.oneOf(['primary', 'normal', 'danger']),
  variant: PropTypes.oneOf(['default', 'minimal', 'minimalTiny', 'minimalBold']),
  disabled: PropTypes.bool,
}

Button.defaultProps = {
  type: 'normal',
  variant: 'default',
}

export default Button
