import dayjs from 'dayjs'

export const parseCronString = cronString => {
  try {
    const tokens = cronString.split(' ')
    return dayjs().minute(tokens[0]).hour(tokens[1]).toISOString()
  } catch (error) {
    console.error(String(error)) // eslint-disable-line no-console
  }
  return null
}

export const serializeCronString = isoValue => {
  const date = dayjs(isoValue)
  return `${date.minute()} ${date.hour()} * * *`
}
