import PropTypes from 'prop-types'
import { IconProps } from './types/IconProps'
const TiprackIcon = ({ className, onClick }: IconProps) => (
  <svg
    width='20'
    height='18'
    viewBox='0 0 20 18'
    xmlns='http://www.w3.org/2000/svg'
    className={className}
    onClick={onClick}
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M4.34783 0.378021H0V13.0411L2.17391 17.3889L4.34783 13.0411V0.378021ZM12.1739 0.378021H7.82609V13.0411L10 17.3889L12.1739 13.0411V0.378021ZM15.6522 0.378021H20V13.0411L17.8261 17.3889L15.6522 13.0411V0.378021Z'
    />
  </svg>
)

TiprackIcon.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
}

export default TiprackIcon
