import React from 'react'
import InputWithValidation from '~/components/InputWithValidation'

interface InputStorageLocationParamProps {
  value?: string
  onChange: (newValue: string | null) => void
  className?: string
  label?: string
  inputClassName?: string
  placeholder?: string
  disabled?: boolean
}

const storageLocationParamRegex = /^s\d+:l\d+$/

const InputStorageLocationParam = React.forwardRef<
  HTMLInputElement,
  InputStorageLocationParamProps
>((props, ref) => {
  const { value, onChange, className, label, inputClassName, placeholder, disabled } =
    props

  const validateStorageLocationParamString = (v: string) => {
    return storageLocationParamRegex.test(v)
  }

  const parseStorageLocationParamString = (v: string) => {
    return v
  }

  return (
    <InputWithValidation<string>
      ref={ref}
      validateInput={validateStorageLocationParamString}
      parseInput={parseStorageLocationParamString}
      errorMessage='Invalid storage location'
      value={value}
      onChange={onChange}
      className={className}
      label={label}
      inputClassName={inputClassName}
      placeholder={placeholder}
      disabled={disabled}
    />
  )
})

export default InputStorageLocationParam
