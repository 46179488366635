import cx from 'classnames'

import PropTypes from '~/propTypes'
import { INSTRUMENT_CATEGORY, INSTRUMENT_DISPLAY_NAMES } from '~/utils/instrument'

import cs from './instrument_category.scss'

const InstrumentCategory = ({ className, instrument }) => {
  return (
    <div className={cx(className, cs.instrumentCategory)}>
      {INSTRUMENT_CATEGORY[instrument.instrumentType] && (
        <>{INSTRUMENT_CATEGORY[instrument.instrumentType]} &middot;&nbsp;</>
      )}
      {INSTRUMENT_DISPLAY_NAMES[instrument.instrumentType]}
    </div>
  )
}

InstrumentCategory.propTypes = {
  className: PropTypes.string,
  instrument: PropTypes.Instrument,
}

export default InstrumentCategory
