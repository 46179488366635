import cx from 'classnames'
import { useState } from 'react'

import { FragmentType, gql, unmaskFragment } from '~/__generated__'

import { round } from 'lodash'
import CultureRelativeTimestamp from '../../components/CultureRelativeTimestamp'
import MontageImagesDialog from '../MontageImagesDialog/MontageImagesDialog'
import CultureImage from './CultureImage'
import cs from './culture_image_grid.scss'

const cultureImageGridFragment = gql(`
  fragment CultureImageGridFragment on WellCultureGraphQL {
    createdAt
    status
    observationHistory {
      montage {
        id
        culture {
          id
        }
        ...MontageImagesDialogFragment
        images(positions: ALL) {
          imageUrl(size: SIZE_600)
        }
      }
      confluence
      timestamp
    }
  }
`)

const CultureImageGrid = (props: {
  wellCulture: FragmentType<typeof cultureImageGridFragment>
  maxMontagesToShow?: number
  className?: string
}) => {
  let { observationHistory, status, createdAt } = unmaskFragment(
    cultureImageGridFragment,
    props.wellCulture,
  )
  if (props.maxMontagesToShow != null) {
    observationHistory = observationHistory.slice(0, props.maxMontagesToShow)
  }

  const [selectedMontage, setSelectedMontage] = useState<
    (typeof observationHistory)[0] | null
  >(null)

  return (
    <>
      {selectedMontage?.montage ? (
        <MontageImagesDialog
          montage={selectedMontage.montage}
          confluence={selectedMontage.confluence ?? undefined}
          timestamp={selectedMontage.timestamp ?? undefined}
          isOpen={selectedMontage != null}
          onClose={() => {
            setSelectedMontage(null)
          }}
        />
      ) : null}
      <div className={cx(cs.cultureImageGrid, props.className)}>
        {observationHistory.map(observation => {
          // TODO(SWE-788): Only fetch one thumbnail for this UI.
          const thumbnail = observation.montage?.images[0]?.imageUrl ?? '#' // TODO(SWE-778): Add placeholder UI.

          if (observation.montage == null) {
            return null
          }
          return (
            <div
              key={observation.montage.id}
              className={cs.thumbnailContainer}
              onClick={() => {
                setSelectedMontage(observation)
              }}
            >
              <CultureImage className={cs.thumbnail} thumbnail={thumbnail} />
              <div className={cs.thumbnailCaption}>
                {observation.confluence && observation.timestamp ? (
                  <>
                    <span className={cs.emphasis}>
                      Confluence: {round(observation.confluence)}%
                    </span>{' '}
                    &middot;{' '}
                    <CultureRelativeTimestamp
                      status={status}
                      createdAt={createdAt}
                      timestamp={observation.timestamp}
                      className={cs.capturedAt}
                    />
                  </>
                ) : null}
              </div>
            </div>
          )
        })}
      </div>
    </>
  )
}

export default CultureImageGrid
