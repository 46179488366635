import { flow, get, isArray, map, omit, toPairs } from 'lodash/fp'
import { RoutineDescriptor } from '../../types/RoutineDescriptor.interface'

// TODO(mark): Make this more resilient, for example by looking for the routine parameter of type sample_plate.
// (this would involve fetching the routine template json for each routine)

// These are all possible parameter names that we use for the main process item
// (e.g. the sample plate) for a given routine.
export const plateBarcodeInputParameterNames = [
  'plateBarcode',
  'processItem',
  'stainPlateBarcode',
  'samplePlateBarcode',
  'sourcePlateBarcode',
  'culturePlateBarcode',
  'samplePlate',
  'process_item',
  'plate_barcode',
  'sample_plate_barcode',
  'source_plate_barcode',
  'culture_plate_barcode',
]

export const _plural = (plate_barcode_name: string) => {
  return `${plate_barcode_name}s`
}

const renderParameter = pair => {
  if (pair[0] === 'wellArrayToProcess' && isArray(pair[1])) {
    return `${pair[0]}: (${pair[1].length} well${pair[1].length === 1 ? '' : 's'})`
  }

  return `${pair[0]}: ${pair[1]}`
}

export const renderParameters = (
  routine: RoutineDescriptor,
  omitSample = false,
): string => {
  let parameters = get('parameters', routine)

  // We are currently converting our APIs and future routine parameters use snake case.
  if (omitSample) {
    parameters = omit(
      [
        ...plateBarcodeInputParameterNames,
        ...map(_plural, plateBarcodeInputParameterNames),
      ],
      parameters,
    )
  }
  return flow(toPairs, map(renderParameter), arr => arr.join(', '))(parameters)
}
