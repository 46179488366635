import { mapValues, round, sum } from 'lodash'
import { EventState } from '../EventState'
import { CultureViewModelMap } from '../ViewModels/CultureViewModel'
import {
  COMPONENT_NAMES,
  MediaLotViewModel,
  MediaLotViewModelMap,
} from '../ViewModels/MediaLotViewModel'
import { generateLotNumber } from '../data/generate/generateLotNumber'

export function projectMediaLotViewModels(
  eventState: EventState,
  cultures: CultureViewModelMap,
  startAt: { eventIndex: number; mediaLots: MediaLotViewModelMap },
): MediaLotViewModelMap {
  const eventsToApply = eventState.eventLog.slice(startAt.eventIndex)

  const mediaLots = cloneMediaLots(startAt.mediaLots)

  const mediaIDToCultureIDToDay: {
    [mediaLotID: string]: { [cultureID: string]: number }
  } = {}

  for (const { data: event } of eventsToApply) {
    if (event.kind === 'MediaLotWarmed') {
      mediaLots[event.mediaLotID] = {
        id: event.mediaLotID,
        name: event.mediaName,
        components: COMPONENT_NAMES.map(componentName => ({
          componentName,
          lotNumber: generateLotNumber(),
        })),

        avgDoublingTime: 0,
        anomaliesDetected: 0,
        mediaExchanges: [],

        _warmedAt: event.at,
        _isSecretlyContaminated: event.isSecretlyContaminated,
      }
    } else if (event.kind === 'CultureMediaExchanged') {
      if (mediaIDToCultureIDToDay[event.mediaLotID] == null) {
        mediaIDToCultureIDToDay[event.mediaLotID] = {}
      }
      mediaIDToCultureIDToDay[event.mediaLotID][event.cultureID] = event.day
    }
  }

  for (const [mediaLotID, cultureIDToDay] of Object.entries(mediaIDToCultureIDToDay)) {
    analyzeMediaExchanges(mediaLots[mediaLotID], cultures, cultureIDToDay)
  }

  return mediaLots
}

function cloneMediaLots(mediaLots: MediaLotViewModelMap): MediaLotViewModelMap {
  return mapValues(mediaLots, mediaLot => ({
    ...mediaLot,
    // Media exchanges are effectively immutable, so cloning the list is sufficient
    mediaExchanges: [...mediaLot.mediaExchanges],
  }))
}

function analyzeMediaExchanges(
  mediaLot: MediaLotViewModel,
  cultures: CultureViewModelMap,
  cultureIDToDay: { [cultureID: string]: number },
) {
  for (const [cultureID, day] of Object.entries(cultureIDToDay)) {
    const culture = cultures[cultureID]
    const nextDayDataset = cultures[cultureID].datasets.find(
      dataset => dataset._globalDayCaptured === day + 1,
    )
    if (nextDayDataset != null) {
      mediaLot.mediaExchanges.push({ culture, nextDayDataset, onDay: day })
    }
  }

  mediaLot.anomaliesDetected = mediaLot.mediaExchanges.filter(
    exchange => exchange.nextDayDataset.cellDeathDetected,
  ).length

  mediaLot.avgDoublingTime = round(
    sum(mediaLot.mediaExchanges.map(exchange => exchange.nextDayDataset.doublingTime)) /
      mediaLot.mediaExchanges.length,
    1,
  )
}
