import { useEffect, useState } from 'react'
import Dialog from '~/components/Dialog'

import desktopAPI from '~/api/desktop'
import StructuredDataForm from '~/components/StructuredDataForm'
import Toaster from '~/components/Toaster'
import { JsonSchema } from '~/types/JsonSchema.interface'
import { RoutineDefinition } from '../../types/RoutineDefinition.interface'
import cs from './routine_definition_create_or_edit_dialog.scss'

interface RoutineDefinitionEditableFields {
  name: string
  template_file: string
  type: 'default' | 'preflight' | 'manage_consumables' | 'sterilize' | 'qc'
}

interface RoutineDefinitionCreateOrEditDialogProps {
  isOpen: boolean
  selectedRoutineDefinition?: RoutineDefinition
  onClose: () => void
  onCreateSuccess: () => void
}

const RoutineDefinitionCreateOrEditDialog = ({
  isOpen,
  selectedRoutineDefinition,
  onCreateSuccess,
  onClose,
}: RoutineDefinitionCreateOrEditDialogProps) => {
  const [routineDefinitionParams, setRoutineDefinitionParams] =
    useState<Partial<RoutineDefinitionEditableFields> | null>(null)
  const [schema, setSchema] = useState<JsonSchema | undefined>(undefined)

  const isEditExisting = !!selectedRoutineDefinition

  const initialize = async () => {
    const response = await desktopAPI.getRoutineDefinitionSchema()

    setSchema(response.json_schema as unknown as JsonSchema)
  }

  const resetFields = () => {
    if (!selectedRoutineDefinition) {
      setRoutineDefinitionParams({
        name: '',
        template_file: '',
        type: 'default',
      })
    } else {
      setRoutineDefinitionParams({
        name: selectedRoutineDefinition.name,
        template_file: selectedRoutineDefinition.templateFile,
        type: selectedRoutineDefinition.type,
      })
    }
  }

  useEffect(() => {
    resetFields()
  }, [isOpen, selectedRoutineDefinition])

  useEffect(() => {
    initialize()
  }, [])

  const isValid = () => {
    return (
      routineDefinitionParams !== null &&
      !!routineDefinitionParams.name &&
      !!routineDefinitionParams.template_file &&
      !!routineDefinitionParams.type
    )
  }

  const handleConfigUpdateUi = (key: string, newValue: unknown) => {
    setRoutineDefinitionParams({
      ...(routineDefinitionParams ?? {}),
      [key]: newValue,
    })
  }

  const handleSave = async () => {
    if (!isValid()) return
    onClose()
    try {
      if (isEditExisting) {
        if (!selectedRoutineDefinition) return
        await desktopAPI.updateRoutineDefinition(
          selectedRoutineDefinition.id,
          routineDefinitionParams as RoutineDefinitionEditableFields,
        )
      } else {
        await desktopAPI.createRoutineDefinition(
          routineDefinitionParams as RoutineDefinitionEditableFields,
        )
      }
      onCreateSuccess()
      Toaster.show({
        message: `Successfully ${
          isEditExisting ? 'updated' : 'created'
        } routine definition`,
        intent: 'success',
      })
    } catch (e) {
      Toaster.show({
        message: `Failed to ${isEditExisting ? 'update' : 'create'} routine definition`,
        intent: 'danger',
      })
    }
  }

  return (
    <Dialog
      isOpen={isOpen}
      onClose={onClose}
      className={cs.routineDefinitionCreateOrEditDialog}
    >
      <Dialog.Title>
        {isEditExisting ? 'Edit' : 'Create'} Routine Definition
      </Dialog.Title>
      <StructuredDataForm
        data={routineDefinitionParams}
        variant='wideValues'
        onEdit={handleConfigUpdateUi}
        dataSchema={schema}
        className={cs.structuredDataForm}
      />
      <Dialog.Footer>
        <Dialog.FooterButton label='Cancel' onClick={onClose} />
        <Dialog.FooterButton
          type='primary'
          label='Save Changes'
          onClick={handleSave}
          disabled={!isValid()}
        />
        <div className={cs.fill} />
      </Dialog.Footer>
    </Dialog>
  )
}

export default RoutineDefinitionCreateOrEditDialog
