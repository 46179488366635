import { ProcessItemType } from '~/common.interface'
import { snakeCaseToCamelCase, snakeCaseToTitleCase } from '~/utils/string'

export const LOAD_UNLOAD_ITEMS_PROCESS_ITEM_TYPES = [
  'reagent_plate',
  'tiprack',
  'empty_assay_plate',
  'empty_culture_plate',
  'culture_plate',
  'experiment_plate',
] as const
export type LoadUnloadItemsProcessItemType =
  (typeof LOAD_UNLOAD_ITEMS_PROCESS_ITEM_TYPES)[number]

export const PLATE_FORMATS = ['wells_6', 'wells_12', 'wells_24', 'wells_96'] as const

export type PlateFormat = (typeof PLATE_FORMATS)[number]

const loadUnloadItemsProcessItemTypeToProcessItemType = {
  reagent_plate: 'reagent_plate',
  tiprack: 'tiprack',
  empty_assay_plate: 'assay_plate',
  empty_culture_plate: 'culture_plate',
  culture_plate: 'culture_plate',
  experiment_plate: 'experiment_plate',
} as Record<LoadUnloadItemsProcessItemType, ProcessItemType>

export const convertLoadUnloadItemsProcessItemTypeToProcessItemType = (
  loadUnloadItemsProcessItemType: LoadUnloadItemsProcessItemType,
): ProcessItemType => {
  return loadUnloadItemsProcessItemTypeToProcessItemType[loadUnloadItemsProcessItemType]
}

export const convertProcessItemTypeToLoadUnloadItemsProcessItemTypes = (
  processItemType: ProcessItemType,
): LoadUnloadItemsProcessItemType[] => {
  const ans: LoadUnloadItemsProcessItemType[] = []
  for (const loadUnloadItemsProcessItemType of LOAD_UNLOAD_ITEMS_PROCESS_ITEM_TYPES) {
    if (
      convertLoadUnloadItemsProcessItemTypeToProcessItemType(
        loadUnloadItemsProcessItemType,
      ) === processItemType
    ) {
      ans.push(loadUnloadItemsProcessItemType)
    }
  }
  return ans
}

export const getDisplayNameForLoadUnloadItemsProcessItemType = (
  processItemType: LoadUnloadItemsProcessItemType,
  itemTypeDisplayAliases: Record<string, string>,
) => {
  if (itemTypeDisplayAliases[processItemType]) {
    return itemTypeDisplayAliases[processItemType]
  }
  // Because of automatic snake case to camel case conversion.
  if (itemTypeDisplayAliases[snakeCaseToCamelCase(processItemType)]) {
    return itemTypeDisplayAliases[snakeCaseToCamelCase(processItemType)]
  }
  if (processItemType === 'empty_assay_plate') {
    return 'Assay Plate'
  }
  return snakeCaseToTitleCase(processItemType)
}

export const getPreferredInstrumentNameForProcessItemType = (
  processItemType: LoadUnloadItemsProcessItemType,
  itemTypePreferredInstrumentNames: Record<string, string>,
): string | null => {
  if (itemTypePreferredInstrumentNames[processItemType]) {
    return itemTypePreferredInstrumentNames[processItemType]
  }
  // Because of automatic snake case to camel case conversion.
  if (itemTypePreferredInstrumentNames[snakeCaseToCamelCase(processItemType)]) {
    return itemTypePreferredInstrumentNames[snakeCaseToCamelCase(processItemType)]
  }
  return null
}
