import { components } from '~/api/desktop/generated-schema'
import { client, unwrap } from '~/api/desktopAPIv2'

export type DefaultIntegrationsResponse =
  components['schemas']['DefaultIntegrationsResponse']
export type PostToChannelRequest = components['schemas']['PostToChannelRequest']
export type SlackIntegration = components['schemas']['SlackIntegration']
export type SlackSettings = components['schemas']['SlackSettings']
export type ConfigurableSlackSettings =
  components['schemas']['ConfigurableSlackSettings']

export default {
  getIntegrations: (): Promise<SlackIntegration[]> =>
    unwrap(client().GET('/api/v2/integrations/')),
  installSlack: (slackSettings: SlackSettings): Promise<SlackIntegration> =>
    unwrap(
      client().POST('/api/v2/integrations/slack/install', {
        body: slackSettings,
      }),
    ),
  uninstallSlack: (integrationId: string): Promise<DefaultIntegrationsResponse> =>
    unwrap(
      client().POST('/api/v2/integrations/slack/uninstall/{integration_id}', {
        params: {
          path: { integration_id: integrationId },
        },
      }),
    ),
  configureSlack: (
    integrationId: string,
    settings: ConfigurableSlackSettings,
  ): Promise<SlackIntegration> =>
    unwrap(
      client().POST('/api/v2/integrations/slack/configure/{integration_id}', {
        params: {
          path: { integration_id: integrationId },
        },
        body: settings,
      }),
    ),
  sendTestSlackMessage: (): Promise<DefaultIntegrationsResponse> =>
    unwrap(client().POST('/api/v2/integrations/slack/send-test-message')),
  sendInfoTestSlackMessage: (): Promise<DefaultIntegrationsResponse> =>
    unwrap(client().POST('/api/v2/integrations/slack/send-info-test-message')),
  sendCriticalTestSlackMessage: (): Promise<DefaultIntegrationsResponse> =>
    unwrap(client().POST('/api/v2/integrations/slack/send-critical-test-message')),
  postBlocksToSlackChannel: (
    channel: string,
    blocks: PostToChannelRequest,
  ): Promise<DefaultIntegrationsResponse> =>
    unwrap(
      client().POST('/api/v2/integrations/slack/post/{channel}', {
        params: {
          path: { channel: channel },
        },
        body: blocks,
      }),
    ),
}
