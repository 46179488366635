import cx from 'classnames'
import PropTypes from 'prop-types'

import Table from '~/components/Table'
import MinimalButton from '~/components/buttons/MinimalButton'
import ProcessStepName from '~/pages/Workcell/components/processStep/ProcessStepName'
import { renderStatusPill } from '~/utils/statusPill'

import { includes } from 'lodash/fp'
import { ProcessStep } from '../../types/ProcessStep.interface'
import RoutineOrProcessStepEndTime from './RoutineOrProcessStepEndTime'
import RoutineOrProcessStepStartTime from './RoutineOrProcessStepStartTime'
import cs from './process_step_table.scss'

export const canCancelProcessStep = (routine: ProcessStep) => {
  if (includes(routine.status, ['completed', 'cancelled', 'in_progress'])) {
    return false
  }

  return true
}

const STATUS_PILL_CONFIG = {
  completed: { type: 'success' },
  canceled: { type: 'default' },
  failed: { type: 'error' },
  in_progress: { type: 'info' },
  scheduled: { type: 'warning' },
  initialized: { type: 'default' },
}

const renderStatus = status =>
  renderStatusPill(status, STATUS_PILL_CONFIG, {
    className: cs.status,
  })

interface ProcessStepTableProps {
  className?: string
  steps: ProcessStep[]
  cancelStep: (step: ProcessStep) => void
  retryCompletedStep: (step: ProcessStep) => void
}

const ProcessStepTable = ({
  className,
  steps,
  cancelStep,
  retryCompletedStep,
}: ProcessStepTableProps) => {
  const tableColumns = [
    {
      name: 'Command',
      width: 250,
      render: (row: ProcessStep) => <ProcessStepName processStep={row} />,
    },
    {
      name: 'Status',
      width: 140,
      render: (row: ProcessStep) => renderStatus(row.status),
    },
    {
      name: 'Start Time',
      width: 150,
      render: (row: ProcessStep) => (
        <RoutineOrProcessStepStartTime routineOrProcessStep={row} />
      ),
    },
    {
      name: 'End Time',
      width: 150,
      render: (row: ProcessStep) => (
        <RoutineOrProcessStepEndTime routineOrProcessStep={row} />
      ),
    },
    {
      name: 'Params',
      width: 'flex',
      render: (row: ProcessStep) => JSON.stringify(row.commandParams),
      smallText: true,
      oneLine: true,
    },
    {
      name: '',
      width: 50,
      render: row => {
        if (canCancelProcessStep(row)) {
          return <MinimalButton label='Cancel' onClick={() => cancelStep(row)} />
        }
        // For failed steps, you can retry on the Live Timeline view.
        // Just showing retry for completed steps for now.
        if (row.status === 'completed') {
          return <MinimalButton label='Retry' onClick={() => retryCompletedStep(row)} />
        }
        return null
      },
      showOnHover: true,
    },
  ]

  if (!steps) {
    return <div className={cs.bigMessage}>No steps found.</div>
  }

  return (
    <Table
      columns={tableColumns}
      data={steps}
      className={cx(cs.processStepTable, className)}
      rowKey='id'
      rowPaddingVariant='rowPaddingLow'
      heightSizing='flexAuto'
    />
  )
}

ProcessStepTable.propTypes = {
  className: PropTypes.string,
  steps: PropTypes.arrayOf(PropTypes.shape({})),
  cancelStep: PropTypes.func,
  retryCompletedStep: PropTypes.func,
}

export default ProcessStepTable
