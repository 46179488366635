import {
  get,
  identity,
  map,
  matchesProperty,
  range,
  sortBy,
  upperCase,
} from 'lodash/fp'

// Split [1, 2, 3] into [[1, 2], [3]] if chunk size is 2.
export const chunk = <T>(arr: Array<T>, chunkSize: number): Array<Array<T>> => {
  const res: Array<Array<T>> = []
  for (let i = 0; i < arr.length; i += chunkSize) {
    const curChunk = arr.slice(i, i + chunkSize)
    res.push(curChunk)
  }
  return res
}

// Sort an array alphabetically, returning a new array.
export const sort = <T>(arr: Array<T>): Array<T> => {
  return sortBy(identity, arr)
}

export const sortCaseInsensitive = (arr: Array<string>): Array<string> => {
  return sortBy(upperCase, arr)
}

export const sortCaseInsensitiveBy = <T>(arr: Array<T>, field: string): Array<T> => {
  return sortBy(element => get(field, element), arr)
}

// See https://github.com/facebook/react/issues/14476#issuecomment-471199055
export const getComparatorString = <T>(arr: Array<T>): string => {
  return JSON.stringify(sort(arr))
}

// This is intended to be used for shallow objects that are one level deep. This does not
// attempt to sort nested objects or arrays.
// This can be useful when config objects are passed as props to a component, and you want to
// avoid unnecessary re-renders when the config object is updated.
export const getComparatorStringForShallowObject = (
  obj: object | undefined,
): string => {
  if (!obj) return '{}'
  const objectWithSortedKeys = Object.keys(obj)
    .sort()
    .reduce((_obj, key) => {
      _obj[key] = obj[key]
      return _obj
    }, {})
  return JSON.stringify(objectWithSortedKeys)
}

export const remove = <T>(item: T, arr: Array<T>) => {
  return arr.filter(e => e !== item)
}

export const updateByProperty = ([key, value], updateFn, arr) => {
  return map(_value => {
    if (matchesProperty(key, value)(_value)) {
      return updateFn(_value)
    }
    return _value
  }, arr)
}

export const padArrayForGrid = <T>(
  getFillerElement: (number) => T,
  modulo: number,
  arr: Array<T>,
) => {
  let numToPad = arr.length % modulo

  if (numToPad !== 0) {
    numToPad = modulo - numToPad
  }

  return [...arr, ...range(0, numToPad).map(getFillerElement)]
}

export const initializeMatrix = <T>(rows: number, cols: number, value: T): T[][] => {
  return range(0, rows).map(() => range(0, cols).map(() => value))
}
