import { ProcessItemLike } from '~/common.interface'
import { SelectedStorageLocation } from '~/pages/Workcell/components/StorageViz/StorageVizView'
import { SelectedTransferStation } from '~/pages/Workcell/components/TransferStationViz/TransferStationVizView'
import { InstrumentDescriptorForItemType } from '../InstrumentDescriptorForItemType.interface'
import { LoadUnloadItemsProcessItemType } from '../LoadUnloadItemsProcessItemType.interface'
import LoadUnloadItemsInstrumentVizView from './LoadUnloadItemsInstrumentVizView'

export interface LoadUnloadItemsInstrumentVizDefaultProps {
  className?: string
  instrumentDescriptorForItemtype: InstrumentDescriptorForItemType
  selectedStorageLocations: SelectedStorageLocation[]
  onStorageLocationSelect: (location: SelectedStorageLocation) => void
  selectedTransferStations: SelectedTransferStation[]
  onTransferStationSelect: (location: SelectedTransferStation) => void
  selectedProcessItemType: LoadUnloadItemsProcessItemType
  loadOrUnload: 'load' | 'unload'
  reloadKey?: string
  disabled?: boolean
}

const LoadUnloadItemsInstrumentVizDefault = ({
  instrumentDescriptorForItemtype,
  selectedStorageLocations,
  onStorageLocationSelect,
  selectedTransferStations,
  onTransferStationSelect,
  selectedProcessItemType,
  loadOrUnload,
  reloadKey,
  disabled,
  className,
}: LoadUnloadItemsInstrumentVizDefaultProps) => {
  const handleSlotClick = (
    itemUuid: string | null,
    shelfIndex: number,
    levelIndex: number,
  ) => {
    onStorageLocationSelect({ itemUuid, shelfIndex, levelIndex })
  }

  const handleTransferStationClick = (
    item: ProcessItemLike | null,
    transferStationId: string,
  ) => {
    onTransferStationSelect({ item, transferStationId })
  }

  return (
    <LoadUnloadItemsInstrumentVizView
      className={className}
      instrumentDescriptorForItemtype={instrumentDescriptorForItemtype}
      selectedStorageLocations={selectedStorageLocations}
      onSlotClick={handleSlotClick}
      selectedTransferStations={selectedTransferStations}
      onTransferStationClick={handleTransferStationClick}
      loadOrUnload={loadOrUnload}
      reloadKey={reloadKey}
      disabled={disabled}
      selectedProcessItemType={selectedProcessItemType}
    />
  )
}

export default LoadUnloadItemsInstrumentVizDefault
