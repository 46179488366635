import { every, mapValues } from 'lodash'
import { PlateCreated } from '../DemoEvent'
import { EventState } from '../EventState'
import { CultureViewModelMap } from '../ViewModels/CultureViewModel'
import { PlateViewModel, PlateViewModelMap } from '../ViewModels/PlateViewModel'

export function projectPlateViewModels(
  eventState: EventState,
  cultures: CultureViewModelMap,
  startAt: { eventIndex: number; plates: PlateViewModelMap },
): PlateViewModelMap {
  const eventsToApply = eventState.eventLog.slice(startAt.eventIndex)

  const plates = clonePlates(startAt.plates)

  for (const { data: event } of eventsToApply) {
    if (event.kind === 'PlateCheckedIn' || event.kind === 'PlateSeeded') {
      plates[event.plateID] = createPlateViewModelFromCreate(cultures, event)
    }
  }

  // Recalculate plate view models since cultures may have changed
  for (const plate of Object.values(plates)) {
    plate.wellNameToCulture = mapValues(
      plate.wellNameToCulture,
      culture => cultures[culture.id],
    )
    plate.isArchived = every(
      mapValues(plate.wellNameToCulture, culture => cultures[culture.id].isArchived),
    )
  }

  return plates
}

function clonePlates(plates: PlateViewModelMap): PlateViewModelMap {
  return mapValues(plates, plate => ({
    ...plate,
    wellNameToCulture: mapValues(plate.wellNameToCulture, culture => culture),
  }))
}

function createPlateViewModelFromCreate(
  cultures: CultureViewModelMap,
  ev: PlateCreated,
): PlateViewModel {
  return {
    id: ev.plateID,
    type: ev.plateFormat,
    name: ev.plateName,
    experimentLabel: ev.experimentLabel,

    wellNameToCulture: mapValues(
      ev.wellNameToCulture,
      initialCulture => cultures[initialCulture.id],
    ),

    isArchived: false,
  }
}
