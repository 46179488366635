import { components } from '~/api/desktop/generated-schema'
import { client, unwrap } from '../desktopAPIv2'
export type ReloadItemsRequest = components['schemas']['ReloadItemsRequest']

export default {
  submit: (request: ReloadItemsRequest) =>
    unwrap(
      client().POST('/api/v2/operator-actions/reload-items/submit', {
        body: request,
      }),
    ),
  getReagentOptions: () =>
    unwrap(
      client().GET('/api/v2/operator-actions/reload-items/get-reagent-options', {}),
    ),
}
