import cx from 'classnames'
import PropTypes from 'prop-types'
import React, { useState } from 'react'

import TinyNotification from '~/components/notifications/TinyNotification'

import cs from './input.scss'

interface InputProps {
  className?: string
  value?: string
  onChange?(string): void
  onInputBlur?(...args: unknown[]): unknown
  onInputFocus?(...args: unknown[]): unknown
  changeOnBlur?: boolean
  label?: string
  inputClassName?: string
  placeholder?: string
  disabled?: boolean
  onSubmit?(...args: unknown[]): unknown
  errorMessage?: string
}

const Input = React.forwardRef<HTMLInputElement, InputProps>((props, ref) => {
  const {
    value,
    onChange,
    changeOnBlur,
    className,
    label,
    inputClassName,
    placeholder,
    disabled,
    onSubmit,
    errorMessage,
    onInputFocus,
    onInputBlur,
  } = props
  const [storedValue, setStoredValue] = useState(null)

  const onInputChange = event => {
    if (!changeOnBlur && onChange) {
      onChange(event.target.value)
    } else {
      setStoredValue(event.target.value)
    }
  }

  const handleInputBlur = () => {
    if (changeOnBlur && onChange) {
      if (storedValue !== null) {
        onChange(storedValue)
        setStoredValue(null)
      }
    }
    if (onInputBlur) onInputBlur()
  }

  const handleKeyDown = event => {
    if (event.key === 'Enter' && onSubmit) {
      onSubmit()
    }
  }

  let inputValue = storedValue !== null ? storedValue : value
  // If value is undefined, pass an empty string into <input> so it clears properly and
  // stays controlled.
  if (!inputValue) {
    inputValue = ''
  }

  return (
    <div className={className}>
      {label && <div className={cs.label}>{label}</div>}
      <input
        ref={ref}
        className={cx(inputClassName, cs.input, disabled && cs.disabled)}
        placeholder={placeholder}
        value={inputValue}
        onChange={onInputChange}
        onKeyDown={handleKeyDown}
        onBlur={handleInputBlur}
        onFocus={onInputFocus}
        type='text'
        disabled={disabled}
      />
      {errorMessage && (
        <TinyNotification
          type='bareError'
          message={errorMessage}
          className={cs.errorMessage}
        />
      )}
    </div>
  )
})

Input.propTypes = {
  className: PropTypes.string,
  inputClassName: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  // When the user presses "Enter" in the Input, fire onSubmit.
  onSubmit: PropTypes.func,
  // Only fire onChange when the input is blurred.
  changeOnBlur: PropTypes.bool,
  errorMessage: PropTypes.string,
}

export default Input
