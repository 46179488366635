import cx from 'classnames'
import { compact, flatten, values } from 'lodash/fp'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'

import commonDriverAPI from '~/api/desktop/commonDriver'
import useReloadKey from '~/utils/hooks/useReloadKey'
import { isInstrumentInBadState } from '~/utils/instrument'

import InhecoScilaLiveDoorStatus from '../../components/instrument/InhecoScilaLiveDoorStatus'
import GenericInstrumentBody from '../GenericInstrumentBody'
import GenericInstrumentPage from '../GenericInstrumentPage'

import { ProcessItem, StringOnlyProcessItem } from '~/common.interface'
import InhecoScilaLastEnvironmentalDataPopover from './InhecoScilaLastEnvironmentalDataPopover'
import cs from './inheco_scila_instrument_page.scss'

const InhecoScilaInstrumentPage = ({ className, status, live, instrument }) => {
  const [transferStations, setTransferStations] = useState<Record<
    string,
    ProcessItem | StringOnlyProcessItem
  > | null>(null)
  const [reloadKey, refreshReloadKey] = useReloadKey()

  const fetchInstrumentInfo = async () => {
    const _transferStations = await commonDriverAPI.getTransferStations(
      instrument.instrumentName,
    )

    setTransferStations(_transferStations)
  }

  const handleControlCommandComplete = commandName => {
    if (
      commandName === 'load_plate' ||
      commandName === 'unload_plate' ||
      commandName === 'refresh_item_cache_command'
    ) {
      fetchInstrumentInfo()
      refreshReloadKey()
    }
  }
  useEffect(() => {
    fetchInstrumentInfo()
  }, [])

  const renderBodyContents = () => {
    return (
      <GenericInstrumentBody
        instrument={instrument}
        reloadKey={reloadKey}
        views={['storage', 'transfer_stations', 'list']}
        defaultView='storage'
        className={cs.bodyContents}
        transferStationNameOrder='desc'
      />
    )
  }

  const items = values(transferStations)
  const totalSlots = flatten(items).length
  const filledSlots = compact(flatten(items)).length

  const additionalStatusText = (
    <span>
      {filledSlots} / {totalSlots} plates
      {!isInstrumentInBadState(status) && (
        <>
          &nbsp;&middot;&nbsp;
          <InhecoScilaLiveDoorStatus instrumentName={instrument.instrumentName} />
        </>
      )}
    </span>
  )

  const renderLatestEnvironmentalDataTooltip = () => {
    return (
      <InhecoScilaLastEnvironmentalDataPopover
        instrumentName={instrument.instrumentName}
      />
    )
  }

  return (
    <div className={cx(className, cs.inhecoScilaInstrumentPage)}>
      <GenericInstrumentPage
        instrument={instrument}
        bodyContents={renderBodyContents()}
        status={status}
        live={live}
        additionalStatusText={additionalStatusText}
        onControlCommandComplete={handleControlCommandComplete}
        className={cs.genericInstrumentPage}
        sidebarContents={renderLatestEnvironmentalDataTooltip()}
      />
    </div>
  )
}

InhecoScilaInstrumentPage.propTypes = {
  className: PropTypes.string,
  instrument: PropTypes.shape({
    instrumentName: PropTypes.string,
    instrumentType: PropTypes.string,
  }),
  status: PropTypes.shape({
    execution_state: PropTypes.oneOf(['FAULTED', 'DISCONNECTED', 'BUSY', 'READY']),
    driver_state: PropTypes.oneOf(['STOPPED', 'RUNNING']),
  }),
  live: PropTypes.bool,
}

export default InhecoScilaInstrumentPage
