import PropTypes from 'prop-types'
import { IconProps } from './types/IconProps'
const ScanVolumesIcon = ({ className, onClick }: IconProps) => (
  <svg
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    onClick={onClick}
    className={className}
  >
    <path
      id='icon/image/blur_linear_24px'
      fillRule='evenodd'
      clipRule='evenodd'
      d='M3 3V5H21V3H3ZM5 9.5C5.82996 9.5 6.5 8.82999 6.5 8C6.5 7.17001 5.82996 6.5 5 6.5C4.17004 6.5 3.5 7.17001 3.5 8C3.5 8.82999 4.17004 9.5 5 9.5ZM9 9C9.55005 9 10 8.54999 10 8C10 7.45001 9.55005 7 9 7C8.44995 7 8 7.45001 8 8C8 8.54999 8.44995 9 9 9ZM10 12C10 12.55 9.55005 13 9 13C8.44995 13 8 12.55 8 12C8 11.45 8.44995 11 9 11C9.55005 11 10 11.45 10 12ZM6.5 16C6.5 16.83 5.82996 17.5 5 17.5C4.17004 17.5 3.5 16.83 3.5 16C3.5 15.17 4.17004 14.5 5 14.5C5.82996 14.5 6.5 15.17 6.5 16ZM21 19V21H3V19H21ZM6.5 12C6.5 12.83 5.82996 13.5 5 13.5C4.17004 13.5 3.5 12.83 3.5 12C3.5 11.17 4.17004 10.5 5 10.5C5.82996 10.5 6.5 11.17 6.5 12ZM9 17C9.55005 17 10 16.55 10 16C10 15.45 9.55005 15 9 15C8.44995 15 8 15.45 8 16C8 16.55 8.44995 17 9 17ZM17.5 16C17.5 16.28 17.28 16.5 17 16.5C16.72 16.5 16.5 16.28 16.5 16C16.5 15.72 16.72 15.5 17 15.5C17.28 15.5 17.5 15.72 17.5 16ZM17.5 8C17.5 8.28 17.28 8.5 17 8.5C16.72 8.5 16.5 8.28 16.5 8C16.5 7.72 16.72 7.5 17 7.5C17.28 7.5 17.5 7.72 17.5 8ZM17 12.5C17.28 12.5 17.5 12.28 17.5 12C17.5 11.72 17.28 11.5 17 11.5C16.72 11.5 16.5 11.72 16.5 12C16.5 12.28 16.72 12.5 17 12.5ZM14 8C14 8.54999 13.55 9 13 9C12.45 9 12 8.54999 12 8C12 7.45001 12.45 7 13 7C13.55 7 14 7.45001 14 8ZM13 13C13.55 13 14 12.55 14 12C14 11.45 13.55 11 13 11C12.45 11 12 11.45 12 12C12 12.55 12.45 13 13 13ZM14 16C14 16.55 13.55 17 13 17C12.45 17 12 16.55 12 16C12 15.45 12.45 15 13 15C13.55 15 14 15.45 14 16Z'
    />
  </svg>
)

ScanVolumesIcon.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
}

export default ScanVolumesIcon
