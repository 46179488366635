import { get } from 'lodash/fp'
import { highlight, languages } from 'prismjs/components/prism-core'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import Editor from 'react-simple-code-editor'
import 'prismjs/components/prism-json'

import desktop from '~/api/desktop'
import Dialog from '~/components/Dialog'

import LoadingMessage from '~/components/LoadingMessage'
import TinyNotification from '~/components/notifications/TinyNotification'
import cs from './routine_definition_dialog.scss'

const RoutineDefinitionDialog = ({ routine, isOpen, onClose }) => {
  const [code, setCode] = useState<string | null>(null)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState('')
  const fetchRoutineDefinition = async () => {
    setCode(null)
    setError('')
    if (!routine) return
    setLoading(true)
    try {
      const response = await desktop.getRoutineDefinitionTemplateJson(routine.id)
      setCode(JSON.stringify(get('templateJson', response), undefined, 4))
    } catch (e) {
      setError('Could not load routine definition JSON')
    }
    setLoading(false)
  }

  useEffect(() => {
    fetchRoutineDefinition()
  }, [routine])

  if (!routine) {
    return null
  }

  const renderContainer = () => {
    if (loading) {
      return (
        <div className={cs.container}>
          <LoadingMessage
            label='Loading Routine Definition...'
            className={cs.loadingMessageBox}
          />
        </div>
      )
    }
    if (error) {
      return (
        <div className={cs.container}>
          <TinyNotification className={cs.error} message={error} type='bareError' />
        </div>
      )
    }
    if (code) {
      return (
        <div className={cs.editorContainer}>
          <Editor
            value={code}
            onValueChange={() => {}}
            className={cs.editor}
            highlight={_code => highlight(_code, languages.json)}
          />
        </div>
      )
    }
    return null
  }

  return (
    <Dialog isOpen={isOpen} onClose={onClose} className={cs.routineDefinitionDialog}>
      <div className={cs.title}>{routine.name}</div>
      <div className={cs.fileName}>{routine.templateFile}</div>
      {renderContainer()}
    </Dialog>
  )
}

RoutineDefinitionDialog.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  routine: PropTypes.shape({
    name: PropTypes.string,
    templateFile: PropTypes.string,
    id: PropTypes.number,
  }),
}

export default RoutineDefinitionDialog
