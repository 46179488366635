import pluralize from 'pluralize'
import { components } from '~/api/desktop/generated-schema'
import { BulkLoadParsePlateDataResponseErrors } from '~/api/operatorActions/bulkLoadCulturePlates'
import { displayCount } from '~/utils/string'
import { ErrorGroup } from '../GroupedErrorTable'
import { generateErrorGroups } from '../generateErrorGroups'
import {
  ParseSpreadsheetDataError,
  getGroupHeaderForSpreadsheetErrorType,
  getSpreadsheetErrorDisplayElement,
} from '../spreadsheetDataError'

export type BulkLoadParsePlateDataError =
  | ParseSpreadsheetDataError
  | components['schemas']['ParseCultureDataMismatchedLabware']
  | components['schemas']['ParseCultureDataPlateNotCheckedOut']
  | components['schemas']['ParseCultureDataInvalidLabware']
  | components['schemas']['ParseCultureDataInsufficientRoomForPlate']
  | components['schemas']['ParseCultureDataDuplicatePlate']
  | components['schemas']['ParseCultureDataLabwareNotSpecified']

const _getErrorTypeForError = (
  error: BulkLoadParsePlateDataError,
): BulkLoadParsePlateDataError['type'] => {
  return error.type
}

const _getGroupHeaderForErrorType = (
  errorType: BulkLoadParsePlateDataError['type'],
  numErrors: number,
): string => {
  if (errorType == 'mismatched_labware') {
    return `${displayCount('plate', numErrors)} ${numErrors == 1 ? 'does' : 'do'} not match previous labware.`
  } else if (errorType == 'plate_not_checked_out') {
    return `${displayCount('plate', numErrors)} ${pluralize('has', numErrors)} is already checked in.`
  } else if (errorType == 'invalid_labware') {
    return `${displayCount('plate', numErrors)} ${pluralize('has', numErrors)} invalid labware.`
  } else if (errorType == 'insufficient_room_for_plate') {
    return `Instrument shelf has insufficient room for ${displayCount('labware type', numErrors)}.`
  } else if (errorType == 'duplicate_plate') {
    return `${displayCount('plate', numErrors)} ${pluralize('is', numErrors)} entered multiple times.`
  } else if (errorType == 'labware_not_specified') {
    return `${displayCount('new plate', numErrors)} ${pluralize('has', numErrors)} no labware specified.`
  }

  return getGroupHeaderForSpreadsheetErrorType(errorType, numErrors)
}
const _getErrorDisplayElement = (
  error: BulkLoadParsePlateDataError,
): React.ReactNode => {
  if (error.type == 'mismatched_labware') {
    return (
      <span>
        <b>{error.plate_barcode}</b> already exists and has labware{' '}
        <b>{error.actual_labware}</b> instead of <b>{error.expected_labware}</b>.{' '}
        <i>(Row {error.line_number})</i>
      </span>
    )
  } else if (error.type == 'plate_not_checked_out') {
    return (
      <span>
        Plate <b>{error.plate_barcode}</b> is already checked in.{' '}
        <i>(Row {error.line_number})</i>
      </span>
    )
  } else if (error.type == 'invalid_labware') {
    return (
      <span>
        Labware <b>{error.labware_name}</b> for plate <b>{error.plate_barcode}</b> is
        not valid. <i>(Row {error.line_number})</i>
      </span>
    )
  } else if (error.type == 'insufficient_room_for_plate') {
    return (
      <span>
        Instrument shelf on <b>{error.instrument}</b> does not have enough room for
        plates with labware <b>{error.labware}</b>.{' '}
        {displayCount('additional location', error.num_missing_locations)}{' '}
        {pluralize('is', error.num_missing_locations)} required.
      </span>
    )
  } else if (error.type == 'duplicate_plate') {
    return (
      <span>
        Plate <b>{error.plate_barcode}</b> was already entered earlier.{' '}
        <i>(Row {error.line_number})</i>
      </span>
    )
  } else if (error.type == 'labware_not_specified') {
    return (
      <span>
        Labware for new plate <b>{error.plate_barcode}</b> is required but not
        specified. <i>(Row {error.line_number})</i>
      </span>
    )
  }
  return getSpreadsheetErrorDisplayElement(error)
}

export const getBulkLoadParsePlateDataErrorGroups = (
  errors: BulkLoadParsePlateDataResponseErrors,
): ErrorGroup[] => {
  return generateErrorGroups<
    BulkLoadParsePlateDataError,
    BulkLoadParsePlateDataError['type']
  >(
    errors.errors,
    _getErrorTypeForError,
    _getGroupHeaderForErrorType,
    _getErrorDisplayElement,
  )
}
