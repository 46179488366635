import { useEffect, useState } from 'react'
import { LinkLotsParseDataSchemasResponse } from '~/api/operatorActions/linkCellLineLotsToCultures'
import linkCellLineLotsToCulturesAPI, {} from '~/api/operatorActions/linkCellLineLotsToCultures'
import Dialog from '~/components/Dialog'
import TabularDataSchemaDisplay from '../../TabularDataSchemaDisplay'
import cs from './link_lots_data_schemas_dialog.scss'

export type LinkLotsDataSchemasDialogProps = {
  isOpen: boolean
  onClose: () => void
}

const LinkLotsDataSchemasDialog = ({
  isOpen,
  onClose,
}: LinkLotsDataSchemasDialogProps) => {
  const [dataSchemas, setDataSchemas] =
    useState<LinkLotsParseDataSchemasResponse | null>(null)

  const fetchDataSchemas = async () => {
    const response = await linkCellLineLotsToCulturesAPI.getParseDataSchemas()
    setDataSchemas(response)
  }

  useEffect(() => {
    fetchDataSchemas()
  }, [])

  if (dataSchemas === null) {
    return null
  }

  return (
    <Dialog isOpen={isOpen} onClose={onClose} className={cs.linkLotsDataSchemasDialog}>
      <div className={cs.title}>Accepted Data Formats</div>
      <div className={cs.subtitle}>
        .csv and .tsv formats are supported. Please include the column names. Two
        schemas are supported for linking cell line lots and cell lines.
      </div>
      <div className={cs.options}>
        <div className={cs.column}>
          <div className={cs.optionTitle}>Schema 1 - Cell Line Lots</div>
          <TabularDataSchemaDisplay
            className={cs.optionSchema}
            schema={dataSchemas.cell_line_lot_schema}
          />
        </div>
        <div className={cs.column}>
          <div className={cs.optionTitle}>Schema 2 - Cell Lines</div>
          <TabularDataSchemaDisplay
            className={cs.optionSchema}
            schema={dataSchemas.cell_line_schema}
          />
        </div>
      </div>
    </Dialog>
  )
}

export default LinkLotsDataSchemasDialog
