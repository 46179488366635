import cs from './routine_instructions.scss'

export interface RoutineInstructionsProps {
  instructions: string
}

const RoutineInstructions = ({ instructions }: RoutineInstructionsProps) => {
  return (
    <div className={cs.routineInstructions}>
      <div className={cs.instructions}>{instructions}</div>
    </div>
  )
}

export default RoutineInstructions
