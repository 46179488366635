import PropTypes from 'prop-types'
import { IconProps } from './types/IconProps'
const MenuIcon = ({ className, onClick }: IconProps) => (
  <svg
    width='24'
    height='24'
    viewBox='0 0 24 24'
    xmlns='http://www.w3.org/2000/svg'
    className={className}
    onClick={onClick}
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M3 8V6H21V8H3ZM3 13H21V11H3V13ZM3 18H21V16H3V18Z'
    />
  </svg>
)

MenuIcon.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
}

export default MenuIcon
