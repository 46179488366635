import PropTypes from 'prop-types'
import { IconProps } from './types/IconProps'
const RightPointerIcon = ({ className, onClick }: IconProps) => (
  <svg
    width='23'
    height='24'
    viewBox='0 0 23 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    className={className}
    onClick={onClick}
  >
    <path d='M20.6042 12L16.7708 8V11H2.39584V13H16.7708V16L20.6042 12Z' />
  </svg>
)

RightPointerIcon.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
}

export default RightPointerIcon
