import PropTypes from 'prop-types'
import { IconProps } from './types/IconProps'
const LineChartIcon = ({ className, onClick }: IconProps) => (
  <svg
    width='32'
    height='32'
    viewBox='0 0 32 32'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    className={className}
    onClick={onClick}
  >
    <path d='M28 26H4V6' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
    <path
      d='M28.0002 12L20.0002 19L12.0002 13L4.00024 20'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)

LineChartIcon.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
}

export default LineChartIcon
