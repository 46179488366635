import { CultureImaged } from '../../DemoEvent'
import { CultureViewModel } from '../../ViewModels/CultureViewModel'
import { generateEventTime } from '../eventTimes'
import { DAYS_TO_IMAGE } from '../generate/generateDataset'

export function imageCultures(
  cultures: CultureViewModel[],
  onDay: number,
): CultureImaged[] {
  return cultures
    .filter(culture => !culture.isArchived && culture.datasets.length < DAYS_TO_IMAGE)
    .map(culture => ({
      kind: 'CultureImaged',
      at: generateEventTime({ eventDay: onDay, eventKind: 'CultureImaged' }),
      cultureID: culture.id,
      day: onDay,
    }))
}
