import PropTypes from 'prop-types'
import { IconProps } from './types/IconProps'

const ArchiveIcon = ({ className, onClick }: IconProps) => (
  <svg
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    className={className}
    onClick={onClick}
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M19.15 3.54999L20.54 5.23001C20.83 5.57001 21 6.01999 21 6.5V19C21 20.1 20.1 21 19 21H5C3.89999 21 3 20.1 3 19V6.5C3 6.01999 3.17 5.57001 3.46001 5.23001L4.84 3.54999C5.12 3.20999 5.53 3 6 3H18C18.47 3 18.88 3.20999 19.15 3.54999ZM17.76 5H6.24001L5.44 5.97H18.57L17.76 5ZM5 19V8H19V19H5ZM10.55 10H13.45V13H16L12 17L8 13H10.55V10Z'
    />
  </svg>
)

ArchiveIcon.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
}

export default ArchiveIcon
