import cx from 'classnames'
import { map } from 'lodash/fp'
import PropTypes from 'prop-types'

import cs from './icon_playground.scss'

// See Webpack require contexts:
// https://webpack.js.org/guides/dependency-management/#require-with-expression
const iconModuleContext = require.context(
  '~/components/icons',
  false,
  /^\.\/.*(.js|.tsx)$/,
)

interface IconPlaygroundProps {
  className?: string
}
const IconPlayground = ({ className }: IconPlaygroundProps) => {
  const renderIconModules = iconModulePath => {
    if (iconModulePath.includes('interface')) return null
    const Icon = iconModuleContext(iconModulePath).default

    // Convert from ./TeamIcon.js to TeamIcon
    const iconName = iconModulePath.slice(2, iconModulePath.length - 3)
    return (
      <div className={cs.iconContainer}>
        <Icon className={cs.iconWithFill} />
        <Icon className={cs.iconWithStroke} />
        <div className={cs.name}>{iconName}</div>
      </div>
    )
  }

  return (
    <div className={cx(className, cs.iconPlayground)}>
      <div className={cs.subtext}>
        Some icons use fill, others use stroke. Fill on left, stroke on right.
      </div>
      <div className={cs.icons}>{map(renderIconModules, iconModuleContext.keys())}</div>
    </div>
  )
}

IconPlayground.propTypes = {
  className: PropTypes.string,
}

export default IconPlayground
