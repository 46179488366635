import cx from 'classnames'
import { find, reject, sortBy } from 'lodash/fp'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'

import instrumentDriverAPI, { InstrumentDriver } from '~/api/desktop/instrumentDrivers'
import Button from '~/components/buttons/Button'
import AddIcon from '~/components/icons/AddIcon'
import RefreshIcon from '~/components/icons/RefreshIcon'
import { displayCount } from '~/utils/string'

import EditInstrumentDriverDialog from './EditInstrumentDriverDialog'
import InstrumentDriverTable from './InstrumentDriverTable'
import cs from './local_instruments_dashboard.scss'

const LocalInstrumentsDashboard = ({ className }: { className?: string }) => {
  const [loading, setLoading] = useState(false)
  const [instrumentDrivers, setInstrumentDrivers] = useState<InstrumentDriver[]>([])
  const [editInstrumentDriverDialogOpen, setEditInstrumentDriverDialogOpen] =
    useState(false)
  const [selectedDriverId, setSelectedDriverId] = useState<number | null>(null)
  const [create, setCreate] = useState(false)

  const fetchInstrumentDrivers = async () => {
    const drivers = await instrumentDriverAPI.get()
    setInstrumentDrivers(drivers)
    setLoading(false)
  }

  const refreshInstrumentDrivers = () => {
    setInstrumentDrivers([])
    setLoading(true)
    fetchInstrumentDrivers()
  }

  useEffect(() => {
    refreshInstrumentDrivers()
  }, [])

  const handleInstrumentDelete = async (id: number) => {
    const driver = find(['id', id], instrumentDrivers)
    setInstrumentDrivers(reject(['id', id], instrumentDrivers))
    if (!driver) return
    await instrumentDriverAPI.deleteAndStop({
      instrumentName: driver.instrumentName,
    })
    refreshInstrumentDrivers()
  }

  const handleInstrumentEdit = async (
    id: number,
    newParams: Partial<InstrumentDriver>,
  ) => {
    await instrumentDriverAPI.partialUpdate(id, newParams)
    refreshInstrumentDrivers()
  }

  const handleInstrumentEditIconClick = async (id: number) => {
    setSelectedDriverId(id)
    setCreate(false)
    setEditInstrumentDriverDialogOpen(true)
  }

  const getSelectedDriver = () => {
    if (!selectedDriverId) return null
    return find(['id', selectedDriverId], instrumentDrivers) || null
  }

  const renderTable = () => {
    if (loading) {
      return <div className={cs.bigMessage}>Loading instruments...</div>
    }
    if (!loading && (!instrumentDrivers || instrumentDrivers.length === 0)) {
      return <div className={cs.bigMessage}>No instruments found.</div>
    }

    const sortedInstrumentDrivers = sortBy('instrumentName', instrumentDrivers)

    return (
      <InstrumentDriverTable
        className={cs.table}
        instrumentDrivers={sortedInstrumentDrivers}
        onInstrumentEditIconClick={handleInstrumentEditIconClick}
        onInstrumentEdit={handleInstrumentEdit}
        onInstrumentDelete={handleInstrumentDelete}
      />
    )
  }

  return (
    <div className={cx(className, cs.localInstrumentsDashboard)}>
      <div className={cs.header}>
        <div className={cs.title}>
          {displayCount('instrument', instrumentDrivers.length)}
        </div>
        <div className={cs.fill} />
        <Button
          className={cs.button}
          label='Refresh Table'
          IconComponent={RefreshIcon}
          onClick={refreshInstrumentDrivers}
        />
        <Button
          className={cs.button}
          type='primary'
          label='Add Instrument Driver'
          IconComponent={AddIcon}
          onClick={() => {
            setCreate(true)
            setEditInstrumentDriverDialogOpen(true)
          }}
        />
      </div>
      {renderTable()}
      <EditInstrumentDriverDialog
        isOpen={editInstrumentDriverDialogOpen}
        onClose={() => setEditInstrumentDriverDialogOpen(false)}
        onEditSuccess={refreshInstrumentDrivers}
        create={create}
        driver={getSelectedDriver()}
      />
    </div>
  )
}

LocalInstrumentsDashboard.propTypes = {
  className: PropTypes.string,
}

export default LocalInstrumentsDashboard
