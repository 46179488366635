import { supportedPlateFormats } from '~/components/TinyMicroplate.interface'
import {
  fittedGrowthCurveConstants,
  gaussianRandom,
  logisticFun,
} from '~/pages/slasDemo/events/data/generate/generateDataset'

export type ClePhase =
  | 'enrichment'
  | 'clonal_isolation'
  | 'hitpicking'
  | 'clonal_expansion'
export const CLE_PHASES: ClePhase[] = [
  'enrichment',
  'clonal_isolation',
  'hitpicking',
  'clonal_expansion',
]

export interface CellLine {
  cellLineIndex: number
  parentCellLine: string
  desiredEdit: string
  name: string
}

export interface Well {
  confluency: number
}

export interface ClePlate {
  id: string
  plateFormat: supportedPlateFormats
  hasAlert: boolean
  cellLine: CellLine
  phase: ClePhase
  plateNumber: number
  wells: Record<string, Well>
  owner: string
}

export const getCellLineName = (cellLine: CellLine): string => {
  return `${cellLine.parentCellLine}-${cellLine.desiredEdit}`
}

export const plateNumberToChar = (plateNumber: number): string => {
  return String.fromCharCode(plateNumber + 64)
}

export const getClePlateType = (plate: ClePlate): string => {
  if (plate.phase === 'enrichment') {
    return 'Enrichment'
  }
  if (plate.phase === 'clonal_isolation') {
    return `Clonal Isolation ${plateNumberToChar(plate.plateNumber)}`
  }
  if (plate.phase === 'hitpicking') {
    return `Hitpicking ${plateNumberToChar(plate.plateNumber)}`
  }
  return `Clonal Expansion ${plateNumberToChar(plate.plateNumber)}`
}

export function getConfluencyForDataset(day: number): number {
  // Try a standard deviation of 300 minutes for lots of noise.
  try {
    const stdDevInDays = 300 / 60 / 24
    const imagingTime = day + gaussianRandom(0, stdDevInDays)
    return Number(logisticFun(fittedGrowthCurveConstants[0])(imagingTime).toFixed(1))
  } catch (e) {
    throw new Error(`Failed to get confluence for ${day}`)
  }
}

export const getConfluencyForPlate = (phase: string, cellLineIndex: number): number => {
  if (phase === 'clonal_isolation') {
    if (cellLineIndex % 2 === 0) {
      return getConfluencyForDataset(1)
    }
    return getConfluencyForDataset(2)
  }
  return getConfluencyForDataset(2)
}

export const getDayToSimulateForPlate = (
  phase: string,
  cellLineIndex: number,
): number => {
  if (phase === 'clonal_isolation') {
    if (cellLineIndex % 2 === 0) {
      return 2
    }
    return 3
  }
  return 3
}
