import { useState } from 'react'
import roboticArmAPI, { SuccessResponse } from '~/api/desktop/drivers/roboticArm'
import cs from './jogger.scss'

interface JoggerProps {
  instrumentName: string
  isTeachToolBusy: boolean
  onLocationUpdated: () => void
  teachToolBusyWrapper: (func: () => Promise<SuccessResponse | void>) => void
}

const Jogger = ({
  instrumentName,
  isTeachToolBusy,
  onLocationUpdated,
  teachToolBusyWrapper,
}: JoggerProps) => {
  const [amount, setAmount] = useState<number>(0.1)
  const jog = async (axis: string, negative = false) => {
    await teachToolBusyWrapper(() =>
      roboticArmAPI.jogc(instrumentName, axis, negative ? -amount : amount).then(() => {
        onLocationUpdated()
      }),
    )
  }

  const styledArrow = (): string => {
    return isTeachToolBusy ? cs.disabledArrow : cs.arrow
  }

  const zPanel = () => {
    return (
      <div className={cs.zPanel}>
        <div className={`${styledArrow()} ${cs.upward}`} onClick={() => jog('z')}></div>
        <div className={cs.zLabel}>Z Height</div>
        <div
          className={`${styledArrow()} ${cs.downward}`}
          onClick={() => jog('z', true)}
        ></div>
      </div>
    )
  }

  const xyPanel = () => {
    return (
      <div className={cs.xyPanel}>
        <div className={cs.innerPanel}>
          <div className={cs.innerLabel}>Gripper Head</div>
        </div>
        <div className={`${styledArrow()} ${cs.left}`} onClick={() => jog('l')}></div>
        <div
          className={`${styledArrow()} ${cs.turnRight}`}
          onClick={() => jog('tr')}
        ></div>
        <div
          className={`${styledArrow()} ${cs.forward}`}
          onClick={() => jog('f')}
        ></div>
        <div className={`${styledArrow()} ${cs.back}`} onClick={() => jog('b')}></div>
        <div
          className={`${styledArrow()} ${cs.turnLeft}`}
          onClick={() => jog('tl')}
        ></div>
        <div className={`${styledArrow()} ${cs.right}`} onClick={() => jog('r')}></div>
      </div>
    )
  }

  return (
    <div className={cs.jogger}>
      <div className={cs.axisPanels}>
        {zPanel()}
        {xyPanel()}
      </div>
      <div className={cs.amountSection}>
        <div className={cs.amountLabel}>Amount</div>
        <div className={cs.amountInputs}>
          {[0.1, 0.5, 1, 5, 10].map(amt => {
            return (
              <div
                className={amt == amount ? cs.amountSelected : cs.amountDefault}
                onClick={() => setAmount(amt)}
              >
                {amt}
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default Jogger
