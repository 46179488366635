import cx from 'classnames'
import { keyBy, map, uniq } from 'lodash/fp'
import memoize from 'memoize-one'
import {
  RecordManualPassageCulturePlateResponse,
  RecordManualPassageParsedWorklist,
} from '~/api/operatorActions/recordManualPassage'
import Table, { TableColumn } from '~/components/Table'
import TinyMicroplate from '~/components/TinyMicroplate'
import RightPointerIcon from '~/components/icons/RightPointerIcon'
import { convertWellCoordsToWellName } from '~/utils/microplate'
import cs from './record_manual_passage_worklist_preview.scss'

export interface RecordManualPassageWorklistPreviewProps {
  className?: string
  worklists: RecordManualPassageParsedWorklist[]
  plates: RecordManualPassageCulturePlateResponse[]
}

interface RecordManualPassagePreviewWorklist {
  parentPlateBarcode: string
  plateBarcode: string
  parentWells: string[]
  wells: string[]
}

export const getPreviewWorklists = (
  worklists: RecordManualPassageParsedWorklist[],
): RecordManualPassagePreviewWorklist[] => {
  return map((worklist: RecordManualPassageParsedWorklist) => {
    return {
      parentPlateBarcode: worklist.parent_plate_barcode,
      plateBarcode: worklist.plate_barcode,
      parentWells: uniq(worklist.well_transfers.map(transfer => transfer.parent_well)),
      wells: uniq(worklist.well_transfers.map(transfer => transfer.well)),
    }
  }, worklists)
}

const _getPreviewWorklists = memoize(getPreviewWorklists)

const RecordManualPassageWorklistPreview = ({
  className,
  worklists,
  plates,
}: RecordManualPassageWorklistPreviewProps) => {
  const platesByBarcode = keyBy('user_facing_id', plates)
  const previewWorklists = _getPreviewWorklists(worklists)

  const tableColumns: TableColumn<RecordManualPassagePreviewWorklist>[] = [
    {
      name: 'Parent Plate',
      width: 120,
      render: (plate: RecordManualPassagePreviewWorklist) => (
        <div className={cs.plate}>
          <TinyMicroplate
            plateFormat={platesByBarcode[plate.parentPlateBarcode].plate_format}
            size='medium'
            highlights={[
              {
                color: 'accent',
                fn: (row, col) => {
                  const wellName = convertWellCoordsToWellName(row, col)
                  return plate.parentWells.includes(wellName)
                },
              },
            ]}
          />
          <div className={cs.plateName}>{plate.parentPlateBarcode}</div>
        </div>
      ),
    },
    {
      name: '',
      width: 120,
      render: () => (
        <div className={cs.pointerContainer}>
          <RightPointerIcon className={cs.pointer} />
        </div>
      ),
    },
    {
      name: 'Plate',
      width: 120,
      render: (plate: RecordManualPassagePreviewWorklist) => (
        <div>
          <TinyMicroplate
            plateFormat={platesByBarcode[plate.plateBarcode].plate_format}
            size='medium'
            highlights={[
              {
                color: 'accent',
                fn: (row, col) => {
                  const wellName = convertWellCoordsToWellName(row, col)
                  return plate.wells.includes(wellName)
                },
              },
            ]}
          />
          <div className={cs.plateName}>{plate.plateBarcode}</div>
        </div>
      ),
    },
  ]
  return (
    <Table
      className={cx(className, cs.recordManualPassageWorklistPreview)}
      columns={tableColumns}
      data={previewWorklists}
      rowKey='id'
      rowPaddingVariant='rowPaddingLow'
      heightSizing='flexAuto'
    />
  )
}

export default RecordManualPassageWorklistPreview
