import { highlight, languages } from 'prismjs/components/prism-core'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import Editor from 'react-simple-code-editor'
import 'prismjs/components/prism-clike'
import 'prismjs/components/prism-python'
import 'prismjs/themes/prism.css'

import HelpPopover from '~/components/HelpPopover'
import Toaster from '~/components/Toaster'
import Button from '~/components/buttons/Button'

import { noop } from 'lodash/fp'
import cs from './structure_raw_data_script_tab.scss'
import { DataUploaderScriptApi } from './types/DataUploaderScriptAPI.interface'

interface StructureRawDataScriptTabProps {
  onClose: () => void
  example: string
  api: DataUploaderScriptApi
  metadata: {
    watchType: 'dir' | 'file'
  }
}

const StructureRawDataScriptTab = ({
  api,
  onClose,
  example,
  metadata,
}: StructureRawDataScriptTabProps) => {
  const [script, setScript] = useState('')
  const [scriptEdits, setScriptEdits] = useState('')

  const initialize = async () => {
    const _script = await api.getScript()
    setScript(_script)
    setScriptEdits(_script)
  }

  useEffect(() => {
    initialize()
  }, [])

  const handleScriptSave = async () => {
    const oldScript = script
    const newScript = scriptEdits

    setScript(newScript)
    onClose()
    try {
      await api.setScript(newScript)
    } catch (_error) {
      // No need to handle disconnect error here, since it is handled with regular updateStatus.
      Toaster.show({ message: 'New script failed to saved.', intent: 'danger' })
      setScript(oldScript)
      setScriptEdits(oldScript)
      return
    }
    Toaster.show({ message: 'New script saved.', intent: 'success' })
  }

  const renderInputs = () => {
    if (metadata.watchType === 'dir') {
      return (
        <>
          <div>
            <b>dir_path</b> - The path of the dataset directory on the current machine.
          </div>
          <div>
            <b>metadata</b> - The metadata attached to the dataset. A dictionary.
          </div>
          <div>
            <b>update_metadata</b> - Update the metadata attached to the dataset.
          </div>
        </>
      )
    }
    if (metadata.watchType === 'file') {
      return (
        <>
          <div>
            <b>file_path</b> - The path of the dataset file on the current machine.
          </div>
          <div>
            <b>metadata</b> - The metadata attached to the dataset. A dictionary.
          </div>
          <div>
            <b>update_metadata</b> - Update the metadata attached to the dataset.
          </div>
        </>
      )
    }
    return null
  }
  return (
    <div className={cs.structureRawDataScriptTab}>
      <div className={cs.popoverContainer}>
        <HelpPopover
          text='More Info'
          helpContent={
            <div className={cs.help}>
              <div>
                This script allows users to customize the structured output that should
                be captured from a data file.
              </div>
              <div className={cs.caption}>Inputs</div>
              {renderInputs()}
              <div className={cs.caption}>Outputs</div>
              <div>
                <b>data</b> - The structured output, as a Python dictionary.
              </div>
              {example && (
                <>
                  <div className={cs.caption}>Example</div>
                  <div className={cs.codeExample}>
                    <Editor
                      value={example}
                      className={cs.editor}
                      highlight={_code => highlight(_code, languages.python)}
                      onValueChange={noop}
                    />
                  </div>
                </>
              )}
            </div>
          }
          className={cs.text}
          placement='top-start'
        />
      </div>
      <div className={cs.editorContainer}>
        <Editor
          value={scriptEdits}
          onValueChange={setScriptEdits}
          className={cs.editor}
          highlight={_code => highlight(_code, languages.python)}
        />
      </div>
      <div className={cs.controls}>
        <Button label='Cancel' onClick={onClose} className={cs.button} />
        <Button
          type='primary'
          label='Save Changes'
          onClick={handleScriptSave}
          className={cs.button}
        />
      </div>
    </div>
  )
}

StructureRawDataScriptTab.propTypes = {
  onClose: PropTypes.func,
  example: PropTypes.string,
  metadata: PropTypes.shape({
    watchType: PropTypes.oneOf(['dir', 'file']),
  }),
  api: PropTypes.shape({
    getScript: PropTypes.func,
    setScript: PropTypes.func,
  }),
}

export default StructureRawDataScriptTab
