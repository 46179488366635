import cx from 'classnames'
import { uniq } from 'lodash/fp'
import { snakeCaseToTitleCase } from '~/utils/string'
import { ClePhase, ClePlate } from '../clePlates/ClePlate.interface'
import cs from './cle_pipeline_viz.scss'

const getNumCellLinesForPhase = (plates: ClePlate[], phase: ClePhase) => {
  const matchingPlates = plates.filter(plate => plate.phase === phase)
  return uniq(matchingPlates.map(plate => plate.cellLine.cellLineIndex)).length
}

const getNumCellLinesRequiringReviewForPhase = (
  plates: ClePlate[],
  phase: ClePhase,
) => {
  const platesWithAlerts = plates.filter(
    plate => plate.hasAlert && plate.phase === phase,
  )
  return uniq(platesWithAlerts.map(plate => plate.cellLine.cellLineIndex)).length
}

const ClePipelinePhase = ({
  plates,
  phase,
  selectedPhase,
  setSelectedPhase,
}: {
  phase: ClePhase
  plates: ClePlate[]
  selectedPhase: ClePhase | null
  setSelectedPhase: (phase: ClePhase | null) => void
}) => {
  const cellLineCount = getNumCellLinesForPhase(plates, phase)
  const alertCount = getNumCellLinesRequiringReviewForPhase(plates, phase)

  const handleClick = () => {
    if (selectedPhase === phase) {
      setSelectedPhase(null)
    } else {
      setSelectedPhase(phase)
    }
  }

  return (
    <div
      className={cx(
        cs.clePipelinePhase,
        selectedPhase !== null && selectedPhase !== phase && cs.disabled,
      )}
    >
      <div className={cs.cellLineCountContainer}>
        <div className={cs.cellLineCount} onClick={handleClick}>
          {cellLineCount}
        </div>
        <div className={cs.dottedLine} />
        {alertCount > 0 && (
          <div className={cs.alertCount} onClick={handleClick}>
            {alertCount}
          </div>
        )}
      </div>
      <div className={cs.phase} onClick={handleClick}>
        {snakeCaseToTitleCase(phase)}
      </div>
    </div>
  )
}

const ClePipelineViz = ({
  plates,
  selectedPhase,
  setSelectedPhase,
}: {
  plates: ClePlate[]
  selectedPhase: ClePhase | null
  setSelectedPhase: (phase: ClePhase | null) => void
}) => {
  return (
    <div className={cs.clePipelineViz}>
      <ClePipelinePhase
        phase='enrichment'
        plates={plates}
        selectedPhase={selectedPhase}
        setSelectedPhase={setSelectedPhase}
      />
      <ClePipelinePhase
        phase='clonal_isolation'
        plates={plates}
        selectedPhase={selectedPhase}
        setSelectedPhase={setSelectedPhase}
      />
      <ClePipelinePhase
        phase='hitpicking'
        plates={plates}
        selectedPhase={selectedPhase}
        setSelectedPhase={setSelectedPhase}
      />
      <ClePipelinePhase
        phase='clonal_expansion'
        plates={plates}
        selectedPhase={selectedPhase}
        setSelectedPhase={setSelectedPhase}
      />
    </div>
  )
}

export default ClePipelineViz
