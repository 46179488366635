import { get } from 'lodash/fp'
import { highlight, languages } from 'prismjs/components/prism-core'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import Editor from 'react-simple-code-editor'

import desktop from '~/api/desktop'
import Dialog from '~/components/Dialog'

import cs from './custom_scripts_dialog.scss'

const CustomScriptsDialog = ({ scriptName, isOpen, onClose }) => {
  const [code, setCode] = useState(null)
  useEffect(async () => {
    if (!scriptName) return
    const response = await desktop.getCustomScriptCode(scriptName)
    setCode(get('code', response))
  }, [scriptName])

  if (!scriptName) {
    return null
  }

  return (
    <Dialog isOpen={isOpen} onClose={onClose} className={cs.customScriptsDialog}>
      <div className={cs.title}>{scriptName}.py</div>
      <div className={cs.editorContainer}>
        {code && (
          <Editor
            value={code}
            className={cs.editor}
            highlight={_code => highlight(_code, languages.python)}
          />
        )}
      </div>
    </Dialog>
  )
}

CustomScriptsDialog.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  scriptName: PropTypes.string,
}

export default CustomScriptsDialog
