import dayjs from 'dayjs'
import { filter } from 'lodash/fp'
import { useEffect, useState } from 'react'

import processStepsAPI from '~/api/desktop/processSteps'
import PropTypes from '~/propTypes'

import { Instrument, ProcessSteps } from '~/common.interface'
import { useIsMounted } from '~/utils/hooks/useIsMounted'
import cs from './instrument_status_callout.scss'

interface StepsExecutedCalloutProps {
  instrument: Instrument
  reloadKey: string
  label?: string
  commandName?: string
  stepFilter?: (...args: unknown[]) => unknown
}

const StepsExecutedCallout = ({
  instrument,
  reloadKey,
  label,
  commandName,
  stepFilter,
}: StepsExecutedCalloutProps) => {
  const isMounted = useIsMounted()
  const [steps, setSteps] = useState<ProcessSteps | null>(null)
  const date = new Date()

  const fetchData = () => {
    processStepsAPI
      .get({
        startTimeAfter: dayjs(date).startOf('day').toISOString(),
        startTimeBefore: dayjs(date).endOf('day').toISOString(),
        instrumentName: instrument.instrumentName,
        status: 'completed',
        commandName,
      })
      .then(_steps => {
        if (!isMounted()) return
        if (stepFilter) {
          _steps = filter(stepFilter, _steps)
        }
        setSteps(_steps)
      })
  }

  useEffect(() => {
    fetchData()
  }, [instrument, reloadKey])

  if (!steps) return null

  return (
    <div className={cs.instrumentStatusCallout}>
      <div className={cs.label}>{label}</div>
      <div className={cs.callout}>{steps.length}</div>
    </div>
  )
}

StepsExecutedCallout.propTypes = {
  reloadKey: PropTypes.string,
  instrument: PropTypes.Instrument,
  label: PropTypes.string,
  commandName: PropTypes.string,
  stepFilter: PropTypes.func,
}

export default StepsExecutedCallout
