import cx from 'classnames'
import dayjs from 'dayjs'
import { get } from 'lodash/fp'
import PropTypes from 'prop-types'

import desktopAPI from '~/api/desktop'
import Table from '~/components/Table'
import Button from '~/components/buttons/Button'
import RefreshIcon from '~/components/icons/RefreshIcon'
import usePaginatedResults from '~/utils/hooks/usePaginatedResults'
import { renderStatusPill } from '~/utils/statusPill'

import cs from './job_log.scss'

const STATUS_PILL_CONFIG = {
  Scheduled: { type: 'default' },
  Running: { type: 'accent' },
  Success: { type: 'success' },
  Failure: { type: 'error' },
}

const JobLog = ({ className }) => {
  const {
    loadingInitialResults,
    results: tasks,
    refetchResults: refetchJobs,
    fetchMoreResults,
    hasMoreResults,
  } = usePaginatedResults(desktopAPI.getTasks, {
    initialQueryParams: {
      types: ['execute_job'],
    },
  })

  const renderStatus = row => {
    return renderStatusPill(get('status', row), STATUS_PILL_CONFIG, {
      small: true,
      popoverContent: get('error', row),
    })
  }

  const tableColumns = [
    {
      name: 'Timestamp',
      width: 200,
      render: row => dayjs(get(['createdAt'], row)).fromNow(),
      smallText: true,
    },
    {
      name: 'Status',
      width: 200,
      render: row => renderStatus(row),
      verticalCenter: true,
    },
    {
      name: 'Job Name',
      width: 'flex',
      render: get(['metadata', 'jobName']),
    },
  ]

  const renderTable = () => {
    if (loadingInitialResults) {
      return <div className={cs.bigMessage}>Loading jobs...</div>
    }
    if (!loadingInitialResults && (!tasks || tasks.length === 0)) {
      return <div className={cs.bigMessage}>No jobs found.</div>
    }

    return (
      <Table
        columns={tableColumns}
        data={tasks}
        className={cs.table}
        rowPaddingVariant='rowPaddingLow'
        rowKey='id'
        infiniteScrollProps={{
          hasMoreData: hasMoreResults,
          fetchMoreData: fetchMoreResults,
        }}
      />
    )
  }

  return (
    <div className={cx(className, cs.jobLog)}>
      <div className={cs.controls}>
        <div className={cs.fill} />
        <Button
          className={cs.button}
          label='Refresh Table'
          IconComponent={RefreshIcon}
          onClick={refetchJobs}
        />
      </div>
      {renderTable()}
    </div>
  )
}

JobLog.propTypes = {
  className: PropTypes.string,
}

export default JobLog
