import cx from 'classnames'
import { includes, map } from 'lodash/fp'
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom'

import { Instrument } from '~/common.interface'
import Tabs from '~/components/Tabs'
import StorageViz from '~/pages/Workcell/components/StorageViz'
import TransferStationViz from '~/pages/Workcell/components/TransferStationViz'
import InstrumentProcessItemList from '~/pages/Workcell/components/processItem/InstrumentProcessItemList'
import useTabsWithUrl from '~/utils/hooks/useTabsWithUrlPart'
import cs from './generic_instrument_body.scss'

const getTabForView = view => {
  if (view === 'storage') {
    return {
      key: 'storage',
      title: 'Storage',
    }
  }

  if (view === 'list') {
    return {
      key: 'list',
      title: 'List View',
    }
  }

  if (view === 'transfer_stations') {
    return {
      key: 'transfer_stations',
      title: 'Transfer Stations',
    }
  }
  return null
}

const getTabs = views => {
  return map(getTabForView, views)
}

interface GenericInstrumentBodyProps {
  className?: string
  instrument: Instrument
  reloadKey?: string
  views: ('transfer_stations' | 'list' | 'storage')[]
  defaultView?: string
  transferStationNameOrder?: 'asc' | 'desc'
}

const GenericInstrumentBody = ({
  className,
  reloadKey,
  instrument,
  views,
  defaultView,
  transferStationNameOrder,
}: GenericInstrumentBodyProps) => {
  const match = useRouteMatch()

  const [tabs, selectedTabId, handleTabChange] = useTabsWithUrl(getTabs(views))

  const getDefaultUrl = () => {
    if (defaultView) {
      return `${match.url}/${defaultView}`
    }
    return `${match.url}/${views[0]}`
  }

  const getProcessItemListSources = () => {
    const sources: string[] = []

    if (includes('transfer_stations', views)) {
      sources.push('transferStations')
    }
    if (includes('storage', views)) {
      sources.push('storage')
    }

    return sources
  }

  const getRouteForView = view => {
    if (view === 'storage') {
      return (
        <Route
          path={`${match.path}/storage`}
          key='storage'
          render={() => (
            <div className={cs.vizContainer}>
              <StorageViz reloadKey={reloadKey} instrument={instrument} />
            </div>
          )}
        />
      )
    }
    if (view === 'list') {
      return (
        <Route
          path={`${match.path}/list`}
          key='list'
          render={() => (
            <InstrumentProcessItemList
              instrumentName={instrument.instrumentName}
              reloadKey={reloadKey}
              sources={getProcessItemListSources()}
            />
          )}
        />
      )
    }
    if (view === 'transfer_stations') {
      return (
        <Route
          path={`${match.path}/transfer_stations`}
          key='transfer_stations'
          render={() => (
            <div className={cs.vizContainer}>
              <TransferStationViz
                instrument={instrument}
                reloadKey={reloadKey}
                orderNames={transferStationNameOrder}
              />
            </div>
          )}
        />
      )
    }
    return null
  }

  return (
    <div className={cx(className, cs.genericInstrumentBody)}>
      <Tabs
        tabs={tabs}
        selectedTabId={selectedTabId}
        onlyRenderWhenViewed
        empty
        onTabChange={handleTabChange}
      />
      <div className={cs.view}>
        <Switch>
          <Route
            exact
            path={match.path}
            render={() => <Redirect to={getDefaultUrl()} />}
          />
          {views.map(getRouteForView)}
        </Switch>
      </div>
    </div>
  )
}

export default GenericInstrumentBody
