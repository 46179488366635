// If there is an existing plate with the inputed barcode,
// we look at the isEmpty flag, not at whether the contents themselves are empty.
// We may remove the isEmpty flag in the future, but this is how it's currently implemented.

import { useState } from 'react'

import PlateBarcodeInput from '~/pages/Workcell/components/processItem/PlateBarcodeInput'
import { EmptyCulturePlateLoadParamsRequest } from '../../reloadItems/convertChangeToReloadOperation'
import { LabwareSelect } from '../LabwareSelect'
import { LoadParamsRequestOrError } from '../LoadParamsRequestOrError.interface'
import { LoadUnloadItemEmptyCulturePlateParams } from '../LoadUnloadItemsProcessItemParams.interface'
import { LoadUnloadItemsProcessItemFormComponentProps } from './LoadUnloadItemsProcessItemFormComponentProps.interface'
import cs from './load_unload_items_empty_culture_plate_form.scss'

const LoadUnloadItemsEmptyCulturePlateForm = ({
  className,
  processItemParams,
  setProcessItemParams,
  disabled,
}: LoadUnloadItemsProcessItemFormComponentProps<'empty_culture_plate'>) => {
  const [lastSelectedLabwareName, setLastSelectedLabwareName] = useState<
    string | undefined
  >(undefined)

  return (
    <div className={className}>
      <PlateBarcodeInput
        inputClassName={cs.uuidInput}
        value={processItemParams.uuid}
        onChange={uuid => {
          setProcessItemParams(
            (
              _latestProcessItemParams: Partial<LoadUnloadItemEmptyCulturePlateParams>,
            ) => ({
              ..._latestProcessItemParams,
              uuid: uuid,
            }),
          )
        }}
        existingPlateOrError={{
          error: processItemParams.uuidError,
          existingPlate: processItemParams.existingCulturePlate,
        }}
        validationOptions={{
          processItemType: 'culture_plate',
          allowExistingPlate: true,
          mustHaveIsEmptyFlag: true,
        }}
        onExistingPlateOrErrorUpdate={({ error, existingPlate }) => {
          setProcessItemParams(
            (
              _latestProcessItemParams: Partial<LoadUnloadItemEmptyCulturePlateParams>,
            ) => ({
              ..._latestProcessItemParams,
              uuidError: error,
              existingCulturePlate: existingPlate,
            }),
          )
        }}
        disabled={disabled}
      />
      <LabwareSelect
        processItemType='culture_plate'
        className={cs.labwareSelect}
        defaultLabwareName={lastSelectedLabwareName}
        overrideLabwareName={processItemParams.existingCulturePlate?.labwareName}
        selectedLabware={processItemParams.selectedLabware}
        onLabwareSelect={labware => {
          setLastSelectedLabwareName(labware?.labware_name)
          setProcessItemParams(
            (
              _latestProcessItemParams: Partial<LoadUnloadItemEmptyCulturePlateParams>,
            ) => ({
              ..._latestProcessItemParams,
              selectedLabware: labware,
            }),
          )
        }}
        triggerClassName={cs.trigger}
        popoverClassName={cs.popover}
        disabled={disabled || !!processItemParams.existingCulturePlate}
      />
    </div>
  )
}

export const getEmptyCulturePlateLoadParamsRequestOrError = (
  processItemParams: Partial<LoadUnloadItemEmptyCulturePlateParams>,
): LoadParamsRequestOrError => {
  if (!processItemParams.uuid) {
    return {
      error: 'Please enter a barcode.',
    }
  }

  if (processItemParams.uuidError) {
    return {
      error: processItemParams.uuidError,
    }
  }

  if (!processItemParams.selectedLabware) {
    return {
      error: 'Please select a labware.',
    }
  }

  return {
    params: {
      labware_name: processItemParams.selectedLabware.labware_name,
      uuid: processItemParams.uuid,
    } as EmptyCulturePlateLoadParamsRequest,
  }
}

export default LoadUnloadItemsEmptyCulturePlateForm
