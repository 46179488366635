import PropTypes from 'prop-types'
import { IconProps } from './types/IconProps'

const UnfoldLessIcon = ({ className, onClick }: IconProps) => (
  <svg
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    className={className}
    onClick={onClick}
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M20 15.1699L18.59 16.5899L14 11.9999L18.59 7.40991L20 8.82991L16.83 11.9999L20 15.1699ZM4 8.82991L5.41 7.40991L10 11.9999L5.41 16.5799L4 15.1699L7.17 11.9999L4 8.82991Z'
    />
  </svg>
)

UnfoldLessIcon.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
}

export default UnfoldLessIcon
