import { useEffect, useRef } from 'react'

class Poller {
  callback: () => Promise<void>
  delay: number
  loopStart: number | null
  id: NodeJS.Timeout
  canceled: boolean

  constructor(callback: () => Promise<void>, delay: number) {
    this.callback = callback
    this.delay = delay
    this.loopStart = null
    this.id = setTimeout(this.poll, delay)
    this.canceled = false
  }

  poll = async () => {
    this.loopStart = Date.now()
    // Don't poll again until the previous request returns.
    await this.callback()
    // Don't poll again if the poller has been canceled.
    if (this.canceled) return
    this.id = setTimeout(this.poll, this.loopStart + this.delay - Date.now())
  }

  cancel = () => {
    this.canceled = true
    if (this.id) {
      clearTimeout(this.id)
    }
  }
}

// https://overreacted.io/making-setinterval-declarative-with-react-hooks/
export default function usePollAsync(callback: () => Promise<void>, delay: number) {
  const savedCallback = useRef<Poller | undefined>()

  useEffect(() => {
    savedCallback.current = new Poller(callback, delay)

    return () => {
      if (savedCallback.current) savedCallback.current.cancel()
    }
  }, [delay])
}
