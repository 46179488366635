import cx from 'classnames'
import dayjs, { Dayjs } from 'dayjs'
import { round } from 'lodash'
import { noop } from 'lodash/fp'
import { useMemo } from 'react'

import { FragmentType, gql, unmaskFragment } from '~/__generated__'
import LineGraph from '~/components/LineGraph'
import Link from '~/components/Link'
import ReadOnlyField from '~/components/ReadOnlyField'
import RelativeTimestamp from '~/components/RelativeTimestamp'
import Select, { SelectOption } from '~/components/Select'
import MinimalButton from '~/components/buttons/MinimalButton'
import BareWarningIcon from '~/components/icons/BareWarningIcon'
import { useFeatureFlag } from '~/core/featureFlags'

import { getConfluencyColor } from '../LiveCultures/LiveCultures'
import CultureImageGrid from './CultureImageGrid'
import cs from './culture_overview.scss'

export interface CultureOverviewProps {
  wellCulture: FragmentType<typeof cultureOverviewFragment>
}

const CHART_FIELD_OPTIONS: SelectOption[] = [
  {
    key: 'confluency',
    label: 'Confluency (%)',
  },
]

export const cultureOverviewFragment = gql(`
  fragment CultureOverviewFragment on WellCultureGraphQL {
    id
    name
    createdAt
    culturePlate {
      id
      checkedInAt
    }
    parentCulture {
      id
      well
      culturePlate {
        id
        barcode
      }
    }
    status
    cellLine
    cellLineLot
    passageNumber
    confluence {
      value
    }
    confluenceHistory {
      timestamp
      value
    }
    observationHistory {
      montage {
        id
        culture {
          id
        }
      }
    }
    ...CultureImageGridFragment
  }
`)

const CultureOverview = (props: CultureOverviewProps) => {
  const limsCultureData2024 = useFeatureFlag('limsCultureData2024')
  const wellCulture = unmaskFragment(cultureOverviewFragment, props.wellCulture)

  interface LineGraphDatapoint {
    /** Number of days since seeding or check-in. */
    day: number
    /** Confluence in percent. */
    value: number
  }
  const [confluenceHistoryForLineGraph, earliestDate, latestDate]: [
    {
      [datasetName: string]: LineGraphDatapoint[]
    },
    Dayjs,
    Dayjs,
  ] = useMemo(() => {
    const startDate = dayjs(wellCulture.culturePlate.checkedInAt)
    const data: LineGraphDatapoint[] = []
    let minDate = dayjs()
    let maxDate = startDate
    for (const datapoint of wellCulture.confluenceHistory) {
      const date = dayjs(datapoint.timestamp)
      data.push({
        day: date.diff(startDate, 'days', true),
        value: datapoint.value,
      })
      if (date.isBefore(minDate)) {
        minDate = date
      }
      if (date.isAfter(maxDate)) {
        maxDate = date
      }
    }
    return [{ [wellCulture.name]: data }, minDate, maxDate]
  }, [wellCulture.confluenceHistory])

  // TODO(SWE-816): Test or remove predicted confluency functionality.
  const targetConfluencey = 80
  const latestDay = Math.ceil(
    latestDate.diff(dayjs(wellCulture.culturePlate.checkedInAt), 'day', true),
  )
  const earliestDay = Math.min(
    0,
    Math.floor(
      earliestDate.diff(dayjs(wellCulture.culturePlate.checkedInAt), 'day', true),
    ),
  )
  const displayIntercept = {
    // for now, use an x-intercept of max days
    // x: predictedConfluenceDay,
    x: latestDay,
    y: targetConfluencey,
  }

  return (
    <div className={cs.cultureOverview}>
      <div className={cs.left}>
        <div className={cs.metadata}>
          <ReadOnlyField
            label='Parent Culture'
            condensed
            value={
              wellCulture.parentCulture ? (
                <Link
                  variant='primary'
                  to={`/monitor/culture/${wellCulture.parentCulture.id}`}
                >
                  {wellCulture.parentCulture.culturePlate.barcode}
                  {', '}
                  {wellCulture.parentCulture.well}
                </Link>
              ) : (
                'N/A'
              )
            }
            className={cs.field}
          />
          <ReadOnlyField
            label={wellCulture.parentCulture != null ? 'Seeded' : 'First Checked In'}
            value={<RelativeTimestamp timestamp={wellCulture.createdAt} />}
            className={cs.field}
            condensed
          />

          {limsCultureData2024 ? (
            <>
              <ReadOnlyField
                label='Cell Line'
                value={wellCulture.cellLine || 'N/A'}
                className={cs.field}
                condensed
              />
              <ReadOnlyField
                label='Cell Line Lot'
                value={wellCulture.cellLineLot || 'N/A'}
                className={cs.field}
                condensed
              />
              <ReadOnlyField
                label='Passage Number'
                value={wellCulture.passageNumber ?? 'N/A'}
                className={cs.field}
                condensed
              />
            </>
          ) : null}
        </div>
        <TruncatedCultureImages wellCulture={props.wellCulture} />
      </div>
      <div className={cs.right}>
        <div className={cs.latestData}>
          <div className={cs.latestDataField}>
            <div className={cs.label}>Confluency</div>
            <div
              className={cx(cs.latestDataFieldValue, cs.fieldValue)}
              style={
                wellCulture.status === 'ACTIVE' && wellCulture.confluence
                  ? {
                      color: getConfluencyColor(wellCulture.confluence?.value, true),
                    }
                  : undefined
              }
            >
              {wellCulture.confluence &&
                wellCulture.confluence.value > 80 &&
                wellCulture.status === 'ACTIVE' && (
                  <div
                    className={cs.iconContainer}
                    style={{
                      fill: getConfluencyColor(wellCulture.confluence?.value, true),
                    }}
                  >
                    <BareWarningIcon className={cs.icon} />
                  </div>
                )}
              {wellCulture.confluence
                ? `${round(wellCulture.confluence.value)}%`
                : null}
            </div>
          </div>
        </div>
        <Select<SelectOption>
          items={CHART_FIELD_OPTIONS}
          itemKey='key'
          itemLabelKey='label'
          label='Data Field'
          activeItem={CHART_FIELD_OPTIONS[0]}
          triggerClassName={cs.chartFieldTrigger}
          popoverClassName={cs.chartFieldPopover}
          className={cs.chartSelect}
          onChange={noop}
        />
        <div className={cs.chartContainer}>
          <LineGraph
            className={cs.lineGraph}
            layoutOptions={{
              marginLeft: 50,
            }}
            axisOptions={{
              xLabel: 'Time (days)',
              yLabel: 'Confluency (%)',
              yTicks: 4,
              xTicks: 4,
              yLabelBuffer: 40,
            }}
            options={{
              xKey: 'day',
              yKey: 'value',
              yDomain: [0, 100],
              xDomain: [earliestDay, latestDay],
              lineColor: '#2CB1BC',
              projectedErrorKey: 'error',
              isProjected: key => key === 'projectedConfluency',
              intercept: displayIntercept ? [displayIntercept] : [],
              interceptColor: '#999',
            }}
            // @ts-expect-error Prop type seems wrong.
            data={confluenceHistoryForLineGraph}
          />
        </div>
      </div>
    </div>
  )
}

function TruncatedCultureImages(props: {
  wellCulture: FragmentType<typeof cultureOverviewFragment>
}) {
  const MAX_IMAGES = 2

  const wellCulture = unmaskFragment(cultureOverviewFragment, props.wellCulture)

  return (
    <div className={cs.cultureImages}>
      <div className={cs.header}>
        <div className={cs.label}>Images</div>
        <div className={cs.fill} />
        {wellCulture.observationHistory.length > MAX_IMAGES ? (
          <MinimalButton
            label='View All Images'
            to={`/monitor/culture/${wellCulture.id}/images`}
            type='primary'
          />
        ) : null}
      </div>
      <CultureImageGrid
        wellCulture={wellCulture}
        maxMontagesToShow={MAX_IMAGES}
        className={cs.imageGrid}
      />
    </div>
  )
}

export default CultureOverview
