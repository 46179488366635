import { useEffect, useState } from 'react'
import roboticArmAPI, {
  SequenceValidationRunStatus,
  SuccessResponse,
} from '~/api/desktop/drivers/roboticArm'
import { ApiV2HttpError } from '~/api/desktopAPIv2'
import Toaster from '~/components/Toaster'
import ButtonGroup from '~/components/buttons/ButtonGroup'
import usePollAsync from '~/utils/hooks/usePollAsync'
import TeachPanel from './TeachPanel'
import ValidatePanel from './ValidatePanel'
import cs from './teach_tool.scss'

// It can take a moment for the teach tool status to transition between busy and ready.
// (plus there are possible race conditions)
// We ignore statuses from the poller within a delay, to avoid flickering.
const TEACH_TOOL_STATUS_UPDATE_DELAY = 2000

interface TeachToolProps {
  instrumentName: string
  setShowBodyGripperStatus: (show: boolean) => void
  refreshGripperStatus: () => void
  refreshBodyStatus: () => void
  reloadKey?: string
  bodyFree?: boolean
  isTeachToolBusy: boolean
  setIsTeachToolBusy: (isBusy: boolean) => void
}

const TEACH_TOOL_PANEL_OPTIONS = [
  {
    value: 'teach',
    label: 'Teach',
  },
  {
    value: 'validate',
    label: 'Validate',
  },
]

const TeachTool = ({
  instrumentName,
  refreshGripperStatus,
  refreshBodyStatus,
  reloadKey,
  bodyFree,
  isTeachToolBusy,
  setIsTeachToolBusy,
  setShowBodyGripperStatus,
}: TeachToolProps) => {
  const [fetchingTeachToolStatus, setFetchingTeachToolStatus] = useState<boolean>(false)
  const [lastTeachToolStatusUpdate, setLastTeachToolStatusUpdate] = useState<
    number | undefined
  >(undefined)
  const [teachToolPanel, setTeachToolPanel] = useState<'teach' | 'validate'>('teach')
  const [sequenceValidationRunStatus, setSequenceValidationRunStatus] =
    useState<SequenceValidationRunStatus | null>(null)

  // Update the instrument state
  const pollForTeachToolStatus = async () => {
    if (fetchingTeachToolStatus) return
    setFetchingTeachToolStatus(true)
    try {
      const response = await roboticArmAPI.getStatus(instrumentName)
      if (
        !lastTeachToolStatusUpdate ||
        new Date().getTime() - lastTeachToolStatusUpdate >
          TEACH_TOOL_STATUS_UPDATE_DELAY
      ) {
        setIsTeachToolBusy(response.is_teach_tool_busy)
        setSequenceValidationRunStatus(response.sequence_validation_run_status || null)
      }
    } catch (error) {
      console.error('Failed to fetch teach tool status') // eslint-disable-line no-console
      console.error(String(error)) // eslint-disable-line no-console
    }
    setFetchingTeachToolStatus(false)
  }

  // Do this once.
  useEffect(() => {
    pollForTeachToolStatus()
    setShowBodyGripperStatus(true)
  }, [])

  // Do this regularly.
  usePollAsync(pollForTeachToolStatus, 500)

  const updateTeachToolPanel = (panel: string) => {
    setTeachToolPanel(panel as 'teach' | 'validate')
    setShowBodyGripperStatus(panel === 'teach')

    if (panel === 'teach') {
      refreshGripperStatus()
      refreshBodyStatus()
    }
  }

  // Busy wrapper for robot API calls to prevent spamming
  async function teachPanelBusyWrapper(func: () => Promise<SuccessResponse | void>) {
    if (!isTeachToolBusy) {
      setIsTeachToolBusy(true)
      setLastTeachToolStatusUpdate(new Date().getTime())
      try {
        await func()
      } catch (e) {
        const error = e as ApiV2HttpError
        Toaster.show({
          message: `Error: ${error?.message}`,
          intent: 'danger',
        })
      }
      setIsTeachToolBusy(false)
      setLastTeachToolStatusUpdate(new Date().getTime())
    }
  }

  async function validatePanelBusyWrapper(func: () => Promise<SuccessResponse>) {
    if (!isTeachToolBusy) {
      setIsTeachToolBusy(true)
      setLastTeachToolStatusUpdate(new Date().getTime())
      try {
        await func()
      } catch (e) {
        const error = e as ApiV2HttpError
        Toaster.show({
          message: `Error: ${error?.message}`,
          intent: 'danger',
        })
      }
    }
  }

  return (
    <div className={cs.teachTool}>
      <div className={cs.teachToolOptions}>
        <ButtonGroup
          choices={TEACH_TOOL_PANEL_OPTIONS}
          onChange={updateTeachToolPanel}
          activeChoice={teachToolPanel}
          choiceClassName={cs.choice}
        />
      </div>
      {teachToolPanel === 'teach' && (
        <TeachPanel
          instrumentName={instrumentName}
          bodyFree={bodyFree}
          reloadKey={reloadKey}
          refreshGripperStatus={refreshGripperStatus}
          refreshBodyStatus={refreshBodyStatus}
          isTeachToolBusy={isTeachToolBusy}
          teachPanelBusyWrapper={teachPanelBusyWrapper}
        />
      )}
      {teachToolPanel === 'validate' && (
        <ValidatePanel
          instrumentName={instrumentName}
          validatePanelBusyWrapper={validatePanelBusyWrapper}
          isTeachToolBusy={isTeachToolBusy}
          sequenceValidationRunStatus={sequenceValidationRunStatus}
        />
      )}
    </div>
  )
}

export default TeachTool
