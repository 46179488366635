import cx from 'classnames'

import { get } from 'lodash/fp'
import { Instrument, InstrumentStatus } from '~/common.interface'
import GenericInstrumentBody from '~/pages/Workcell/InstrumentPages/GenericInstrumentBody'
import GenericInstrumentPage from '~/pages/Workcell/InstrumentPages/GenericInstrumentPage'
import useReloadKey from '~/utils/hooks/useReloadKey'
import cs from './tecan_evo_instrument_page.scss'

interface TecanEvoDriverProps {
  className?: string
  instrument: Instrument
  status: InstrumentStatus
  live?: boolean
}

const TecanEvoDriver = ({
  className,
  status,
  live,
  instrument,
}: TecanEvoDriverProps) => {
  const [reloadKey, refreshReloadKey] = useReloadKey()

  const handleControlCommandComplete = commandName => {
    if (
      commandName === 'execute_protocol' ||
      commandName === 'refresh_item_cache_command' ||
      // Note: The EVO tiprack interface is not stable at the moment. This may need to be updated
      commandName == 'sync_tiprack_state'
    ) {
      refreshReloadKey()
    }
  }

  const renderEVOwareHasError = () => {
    const evoware_has_error = get(
      ['real_time_state', 'has_recoverable_evoware_error'],
      status,
    )
    return evoware_has_error ? (
      <span className={cs.hasError}>Yes</span>
    ) : (
      <span className={cs.noError}>No</span>
    )
  }

  const additionalStatus = (
    <span>
      <span className={cs.evowareErrorStatus}>
        EVOware has error: {renderEVOwareHasError()}
      </span>
    </span>
  )

  const renderBodyContents = () => {
    return (
      <GenericInstrumentBody
        instrument={instrument}
        reloadKey={reloadKey}
        views={['transfer_stations', 'list']}
        defaultView='transfer_stations'
        className={cs.bodyContents}
      />
    )
  }

  return (
    <div className={cx(className, cs.tecanEvoInstrumentPage)}>
      <GenericInstrumentPage
        instrument={instrument}
        status={status}
        live={live}
        additionalStatusText={additionalStatus}
        bodyContents={renderBodyContents()}
        className={cs.genericInstrumentPage}
        onControlCommandComplete={handleControlCommandComplete}
      />
    </div>
  )
}

export default TecanEvoDriver
