import Dialog from '~/components/Dialog'

import { RoutineParameters } from '../types/RoutineDefinition.interface'
import { RoutineParametersForm } from './RoutineParametersForm'
import { RoutineDefinitionWithDSL } from './useRoutineDefinitionsWithDSLs'

import cs from './advanced_routine_parameters_dialog.scss'

interface AdvancedRoutineParametersDialogProps {
  isOpen: boolean
  onClose: () => void
  routineDefinitionWithDSL: RoutineDefinitionWithDSL
  routineParameters: RoutineParameters
  onRoutineParametersUpdate: (key: string, newValue: unknown) => void
  fieldsToIgnore: string[]
}

const AdvancedRoutineParametersDialog = ({
  isOpen,
  onClose,
  routineDefinitionWithDSL,
  routineParameters,
  onRoutineParametersUpdate,
  fieldsToIgnore,
}: AdvancedRoutineParametersDialogProps) => {
  return (
    <Dialog isOpen={isOpen} onClose={onClose}>
      <Dialog.Title>{routineDefinitionWithDSL.name}</Dialog.Title>
      <Dialog.Subtitle>Advanced Parameters</Dialog.Subtitle>
      <Dialog.Info>
        These parameters can usually be left at their default values.
      </Dialog.Info>
      <RoutineParametersForm
        routineDefinitionWithDSL={routineDefinitionWithDSL}
        fieldsToIgnore={fieldsToIgnore}
        parameters={routineParameters}
        onParametersUpdate={onRoutineParametersUpdate}
        className={cs.routineParametersForm}
      />
      <Dialog.Footer>
        <Dialog.FooterButton label='Done' type='primary' onClick={onClose} />
      </Dialog.Footer>
    </Dialog>
  )
}

export default AdvancedRoutineParametersDialog
