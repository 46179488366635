/**
 * Used to render process items within a Select
 */

import cx from 'classnames'
import { ProcessItem } from '~/common.interface'
import TextWithOverflow from '~/components/TextWithOverflow'
import TinyNotification from '~/components/notifications/TinyNotification'
import { getShortDisplayName } from '~/utils/processItems/common'
import ProcessItemViz from '../ProcessItemViz'
import {
  getProcessItemLastCheckInTimeDisplayString,
  getProcessItemLocationDisplayString,
} from '../processItemDisplayStrings'
import cs from './process_item_select_option.scss'

interface MicroProcessItemWithLocationProps {
  processItem: ProcessItem
  renderProcessItemViz: boolean
  displayAsSelected?: boolean
}

export function ProcessItemSelectOption(
  props: MicroProcessItemWithLocationProps,
): JSX.Element {
  return (
    <div className={cx(cs.item, props.renderProcessItemViz && cs.withViz)}>
      {props.renderProcessItemViz && (
        <ProcessItemViz
          className={cs.viz}
          processItem={props.processItem}
          size='micro'
        />
      )}
      <ProcessItemSelectOptionInfo
        processItem={props.processItem}
        displayAsSelected={props.displayAsSelected}
      />
    </div>
  )
}

interface ProcessItemSelectOptionInfoProps {
  processItem: ProcessItem
  displayAsSelected?: boolean
}

function ProcessItemSelectOptionInfo(
  props: ProcessItemSelectOptionInfoProps,
): JSX.Element {
  const name = getShortDisplayName(props.processItem)

  const lastCheckInTimeDisplayString = getProcessItemLastCheckInTimeDisplayString(
    props.processItem,
  )
  const locationDisplayString = getProcessItemLocationDisplayString(props.processItem)

  const infoString = lastCheckInTimeDisplayString
    ? `${lastCheckInTimeDisplayString} · ${locationDisplayString}`
    : locationDisplayString

  return (
    <div className={cs.processItemWithLocation}>
      <TextWithOverflow className={cs.name} popoverContent={name} text={name} />
      {props.displayAsSelected ? (
        <TinyNotification
          className={cs.selected}
          type='bareSuccess'
          message='Selected'
        />
      ) : (
        <TextWithOverflow
          className={cs.info}
          popoverContent={infoString}
          text={infoString}
        />
      )}
    </div>
  )
}
