import MicroplateIcon from '~/components/icons/MicroplateIcon'
import LoadUnloadItemsCulturePlateForm, {
  getCulturePlateLoadParamsRequestOrError,
} from '../LoadUnloadItemsProcessItemForm/LoadUnloadItemsCulturePlateForm'
import { ProcessItemTypeConfig } from './ProcessItemTypeConfig.interface'

export const LOAD_UNLOAD_ITEMS_CULTURE_PLATE_CONFIG: ProcessItemTypeConfig<'culture_plate'> =
  {
    getLoadParamsRequestOrError: getCulturePlateLoadParamsRequestOrError,
    formComponent: LoadUnloadItemsCulturePlateForm,
    iconComponent: MicroplateIcon,
  }
