import PropTypes from 'prop-types'

import serviceConfigAPI from '~/api/desktop/serviceConfig'
import Dialog from '~/components/Dialog'
import ConfigDialogTab from '~/components/Dialog/ConfigDialogTab'
import { snakeCaseToTitleCase } from '~/utils/string'

import { JsonSchemaResponse } from '~/types/JsonSchema.interface'
import cs from './service_config_dialog.scss'

const ServiceConfigDialog = ({
  isOpen,
  onClose,
  serviceName,
}: {
  isOpen: boolean
  onClose: () => void
  serviceName: string
}) => {
  const api = {
    getSchema: async () => {
      const response = await serviceConfigAPI.getConfigSchema(serviceName)
      return {
        schema: response.service_config_schema,
      } as unknown as JsonSchemaResponse
    },
    getConfig: async () => {
      const response = await serviceConfigAPI.getConfig(serviceName)
      return response.service_config
    },
    setConfig: newConfig => {
      return serviceConfigAPI.setConfig(serviceName, newConfig)
    },
  }

  return (
    <Dialog isOpen={isOpen} onClose={onClose} className={cs.serviceConfigDialog}>
      <div className={cs.title}>Configure {snakeCaseToTitleCase(serviceName)}</div>
      <ConfigDialogTab api={api} onClose={onClose} className={cs.configTab} />
    </Dialog>
  )
}

ServiceConfigDialog.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  operatorActionName: PropTypes.string,
  onConfigUpdate: PropTypes.func,
}

export default ServiceConfigDialog
