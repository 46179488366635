import PropTypes from '~/propTypes'
import { getMetadataDisplay } from '~/utils/processItems/common'

const ProcessItemMetadata = ({ processItem, className }) => {
  if (!processItem) return null

  const metadataDisplay = getMetadataDisplay(processItem)

  if (!metadataDisplay) return null
  return <div className={className}>{metadataDisplay}</div>
}

ProcessItemMetadata.propTypes = {
  className: PropTypes.string,
  processItem: PropTypes.ProcessItem,
}

export default ProcessItemMetadata
