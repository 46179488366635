import cx from 'classnames'

import Select from '~/components/Select'
import {
  LoadUnloadItemsProcessItemType,
  getDisplayNameForLoadUnloadItemsProcessItemType,
} from './LoadUnloadItemsProcessItemType.interface'
import cs from './load_unload_items_type_select.scss'
import { getProcessItemTypeConfig } from './processItemTypeConfig/getProcessItemTypeConfig'

export interface LoadUnloadItemsTypeSelectProps {
  selectedType: LoadUnloadItemsProcessItemType
  onTypeSelect: (type: LoadUnloadItemsProcessItemType) => void
  triggerClassName?: string
  popoverClassName?: string
  disabled?: boolean
  allowedProcessItemTypes: LoadUnloadItemsProcessItemType[]
  itemTypeDisplayAliases: Record<string, string>
}

interface Option {
  label: string
  value: LoadUnloadItemsProcessItemType
}

const itemRenderer = (option: Option) => {
  const processItemTypeConfig = getProcessItemTypeConfig(option.value)
  const IconComponent = processItemTypeConfig.iconComponent

  return (
    <div className={cs.option}>
      <IconComponent className={cs.icon} />
      {option.label}
    </div>
  )
}

const LoadUnloadItemsTypeSelect = ({
  selectedType,
  onTypeSelect,
  triggerClassName,
  popoverClassName,
  disabled,
  allowedProcessItemTypes,
  itemTypeDisplayAliases,
}: LoadUnloadItemsTypeSelectProps) => {
  const options = allowedProcessItemTypes.map(processItemType => ({
    label: getDisplayNameForLoadUnloadItemsProcessItemType(
      processItemType,
      itemTypeDisplayAliases,
    ),
    value: processItemType,
  }))

  const activeItem = options.find(option => option.value === selectedType)

  return (
    <Select<Option>
      items={options}
      itemKey='value'
      itemLabelKey='label'
      activeItem={activeItem || null}
      itemRenderer={itemRenderer}
      onChange={(option: Option) => onTypeSelect(option.value)}
      className={cx(cs.loadUnloadItemsTypeSelect, disabled && cs.disabled)}
      triggerClassName={cx(triggerClassName, cs.trigger)}
      popoverClassName={popoverClassName}
      triggerIconClassName={cs.triggerIcon}
      noBorder
      disabled={disabled}
    />
  )
}

export default LoadUnloadItemsTypeSelect
