import cx from 'classnames'
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom'

import Tabs from '~/components/Tabs'
import useTabsWithUrl from '~/utils/hooks/useTabsWithUrlPart'

import { useState } from 'react'
import CheckboxOption from '~/components/CheckboxOption'
import Input from '~/components/Input'
import Consumables from './Consumables'
import Samples from './Samples'
import cs from './process_items.scss'

const TABS = [
  {
    key: 'culture-plates',
    title: 'Culture Plates',
  },
  {
    key: 'consumables',
    title: 'Consumables',
  },
]

interface ProcessItemsProps {
  className?: string
}

const ProcessItems = ({ className }: ProcessItemsProps) => {
  const [tabs, selectedTabId, handleTabChange] = useTabsWithUrl(TABS)
  const [showItemsWithoutLocation, setShowItemsWithoutLocation] = useState(false)
  const [searchValue, setSearchValue] = useState('')

  const match = useRouteMatch()

  return (
    <div className={cx(className, cs.processItems)}>
      <Tabs
        tabs={tabs}
        selectedTabId={selectedTabId}
        className={cs.tabs}
        panelClassName={cs.tabPanel}
        onlyRenderWhenViewed
        empty
        nested
        onTabChange={handleTabChange}
      />
      <Input
        placeholder='Search...'
        inputClassName={cx(cs.processItemsSearchInput)}
        value={searchValue}
        onChange={setSearchValue}
      />
      <CheckboxOption
        label='Show items without location'
        checked={showItemsWithoutLocation}
        onClick={setShowItemsWithoutLocation}
        className={cx(cs.option, cs.processItemsCheckbox)}
        allCaps
      />
      <Switch>
        <Route
          exact
          path={match.path}
          render={() => <Redirect to={`${match.url}/culture-plates`} />}
        />
        <Route
          path={`${match.path}/consumables`}
          render={() => (
            <Consumables
              searchValue={searchValue}
              showItemsWithoutLocation={showItemsWithoutLocation}
            />
          )}
        />
        <Route
          path={`${match.path}/culture-plates`}
          render={() => (
            <Samples
              searchValue={searchValue}
              showItemsWithoutLocation={showItemsWithoutLocation}
            />
          )}
        />
      </Switch>
    </div>
  )
}

export default ProcessItems
