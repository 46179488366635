import { MontageLayoutGraphQl, SubImageSizeGraphQl } from '~/__generated__/graphql'

/**
 * Instructions for preparing an image set:
 * https://www.notion.so/monomer/Preparing-demo-image-sets-1568d59ea9ff8043a0d0c490f9a009b8
 */
export interface DemoImageSet {
  /** A sales-facing name for the cell types depicted in the image set. */
  name: string

  /** A sales-facing description of the image set. */
  description: string

  /** A canonical identifier for the image set's license. */
  license: string

  /** Attribution text that must be published along with the images. */
  citation: string

  /** A list of relative filepaths of original files. */
  originals?: string[]

  /** A list of prefixes where plate sprites can be found. */
  plateObservations?: DemoPlateObservation[]
}

export interface DemoPlateObservation {
  spriteDirectory: string
  wellSequence: string[]
  montageLayout: MontageLayoutGraphQl
}

/**
 * Returns the URL of a demo plate sprite. The sprite must already exist. See
 * the comment on ImageSet for instructions on how to generate sprites.
 */
export function getDemoPlateSprite(
  spriteDirectory: string,
  subImageSize: SubImageSizeGraphQl,
): { url: string; wellSequence: string[]; montageLayout: MontageLayoutGraphQl } {
  const { wellSequence, montageLayout } =
    spriteDirectoryToPlateObservation[spriteDirectory]

  const spriteFilename = {
    [SubImageSizeGraphQl.Size_200X200]: 'sprite-v1-200x200.jpg',
    [SubImageSizeGraphQl.Size_400X400]: 'sprite-v1-400x400.jpg',
    [SubImageSizeGraphQl.Size_600X600]: 'sprite-v1-600x600.jpg',
    [SubImageSizeGraphQl.Size_800X800]: 'sprite-v1-800x800.jpg',
  }[subImageSize]
  if (!spriteFilename) {
    throw new Error(`Invalid sub image size "${subImageSize}"`)
  }

  return {
    url: `https://monomer-external-assets-public.s3.us-west-2.amazonaws.com/image_fixtures/sprites/${spriteDirectory}/${spriteFilename}`,
    wellSequence,
    montageLayout,
  }
}

const imageSets: DemoImageSet[] = [
  {
    name: 'iPSC (Science Corp)',
    description: '7 timepoints of a 6 well plate of iPSC cells.',
    license: 'Unknown',
    citation: 'Unknown',
    plateObservations: [
      {
        spriteDirectory:
          'science/2022_11_01_cell_image_batch/220928_110653_600__20220928_105655',
        wellSequence: ['A1', 'A2', 'A3', 'B1', 'B2', 'B3'],
        montageLayout: MontageLayoutGraphQl.Square_4X4,
      },
      {
        spriteDirectory:
          'science/2022_11_01_cell_image_batch/220929_122926_600__20220929_121947',
        wellSequence: ['A1', 'A2', 'A3', 'B1', 'B2', 'B3'],
        montageLayout: MontageLayoutGraphQl.Square_4X4,
      },
      {
        spriteDirectory:
          'science/2022_11_01_cell_image_batch/220930_103235_600__20220930_102257',
        wellSequence: ['A1', 'A2', 'A3', 'B1', 'B2', 'B3'],
        montageLayout: MontageLayoutGraphQl.Square_4X4,
      },
      {
        spriteDirectory:
          'science/2022_11_01_cell_image_batch/221001_111836_600__20221001_110902',
        wellSequence: ['A1', 'A2', 'A3', 'B1', 'B2', 'B3'],
        montageLayout: MontageLayoutGraphQl.Square_4X4,
      },
      {
        spriteDirectory:
          'science/2022_11_01_cell_image_batch/221002_102626_600__20221002_101644',
        wellSequence: ['A1', 'A2', 'A3', 'B1', 'B2', 'B3'],
        montageLayout: MontageLayoutGraphQl.Square_4X4,
      },
      {
        spriteDirectory:
          'science/2022_11_01_cell_image_batch/221003_124926_600__20221003_123947',
        wellSequence: ['A1', 'A2', 'A3', 'B1', 'B2', 'B3'],
        montageLayout: MontageLayoutGraphQl.Square_4X4,
      },
      {
        spriteDirectory:
          'science/2022_11_01_cell_image_batch/221004_145431_600__20221004_144601',
        wellSequence: ['A1', 'A2', 'A3', 'B1', 'B2', 'B3'],
        montageLayout: MontageLayoutGraphQl.Square_4X4,
      },
    ],
  },
  {
    name: 'HEK293T',
    description:
      "12 DIC images (pixel size 2.5 x 2.5 µm) of confluent HEK293T cells were acquired using LSM-410 microscope (Zeiss, Germany) in non-confocal mode from a multi-well wound healing experiment in Ilan Tsarfay's laboratory at Tel Aviv University.",
    license: 'CC-BY 3.0 Unported',
    citation:
      'Image set BBBC019v2 from the Broad Bioimage Benchmark Collection [Ljosa et al., Nature Methods, 2012].',
    originals: [
      'bbbc/BBBC019v2/HEK293/Yaniv__dic_L1_Sum001.tif',
      'bbbc/BBBC019v2/HEK293/Yaniv__dic_L10_Sum055.tif',
      'bbbc/BBBC019v2/HEK293/Yaniv__dic_L12_Sum030.tif',
      'bbbc/BBBC019v2/HEK293/Yaniv__dic_L15_Sum030.tif',
      'bbbc/BBBC019v2/HEK293/Yaniv__dic_L18_Sum030.tif',
      'bbbc/BBBC019v2/HEK293/Yaniv__dic_L21_Sum001.tif',
      'bbbc/BBBC019v2/HEK293/Yaniv__dic_L23_Sum001.tif',
      'bbbc/BBBC019v2/HEK293/Yaniv__dic_L25_Sum055.tif',
      'bbbc/BBBC019v2/HEK293/Yaniv__dic_L29_Sum020.tif',
      'bbbc/BBBC019v2/HEK293/Yaniv__dic_L5_Sum010.tif',
      'bbbc/BBBC019v2/HEK293/Yaniv__dic_L7_Sum020.tif',
      'bbbc/BBBC019v2/HEK293/Yaniv__dic_L8_Sum055.tif',
    ],
  },
  {
    name: 'Brain metastatic melanoma',
    description:
      '20 images of cell populations of brain metastatic melanoma during a would healing experiment were acquired in the I. Witz lab using an inverted microscope (Eclipse TE 2000-S; Nikon, Enfield Enfield, CT, USA) fitted with a digital camera (DXM1200F; Nikon).',
    license: 'CC-BY 3.0 Unported',
    citation:
      'Image set BBBC019v2 from the Broad Bioimage Benchmark Collection [Ljosa et al., Nature Methods, 2012].',
    originals: [
      'bbbc/BBBC019v2/Melanoma/EXP1_1_0HRS_001.tif',
      'bbbc/BBBC019v2/Melanoma/EXP1_1_24HRS_001.tif',
      'bbbc/BBBC019v2/Melanoma/EXP1_2_27HRS_001.tif',
      'bbbc/BBBC019v2/Melanoma/EXP1_2_4HRS_001.tif',
      'bbbc/BBBC019v2/Melanoma/EXP2_1_28HRS_002.jpg',
      'bbbc/BBBC019v2/Melanoma/EXP2_1_4HRS_002.jpg',
      'bbbc/BBBC019v2/Melanoma/EXP2_2_0HRS_002.jpg',
      'bbbc/BBBC019v2/Melanoma/EXP2_2_20HRS_002.jpg',
      'bbbc/BBBC019v2/Melanoma/EXP3_1_0HRS_003.jpg',
      'bbbc/BBBC019v2/Melanoma/EXP3_1_24HRS_003.jpg',
      'bbbc/BBBC019v2/Melanoma/EXP3_2_29HRS_003.jpg',
      'bbbc/BBBC019v2/Melanoma/EXP3_2_4HRS_003.tif',
      'bbbc/BBBC019v2/Melanoma/EXP4_1_26HRS_001.jpg',
      'bbbc/BBBC019v2/Melanoma/EXP4_1_4HRS_001.jpg',
      'bbbc/BBBC019v2/Melanoma/EXP4_2_0HRS_001.tif',
      'bbbc/BBBC019v2/Melanoma/EXP4_2_24HRS_001.jpg',
      'bbbc/BBBC019v2/Melanoma/EXP5_1_0HRS_002.jpg',
      'bbbc/BBBC019v2/Melanoma/EXP5_1_24HRS_002.jpg',
      'bbbc/BBBC019v2/Melanoma/EXP5_2_26HRS_002.jpg',
      'bbbc/BBBC019v2/Melanoma/EXP5_2_4HRS_002.jpg',
    ],
  },
  {
    name: 'CHO',
    description:
      'The image set consists of 60 Differential Interference Contrast (DIC) images of Chinese Hamster Ovary (CHO) cells. The images are taken on an Olympus Cell-R microscope with a 20x lens at the time when the cell initiated their attachment to the bottom of the dish.',
    license: 'CC-BY 3.0 Unported',
    citation:
      'Image set BBBC030v1 [Koos, K., Molnár, J., Kelemen, L., Tamás, G., & Horvath, P. (2016). DIC image reconstruction using an energy minimization framework to visualize optical path length distribution. Scientific reports, 6.] from the Broad Bioimage Benchmark Collection',
    originals: [
      'bbbc/BBBC030/cho01.png',
      'bbbc/BBBC030/cho02.png',
      'bbbc/BBBC030/cho03.png',
      'bbbc/BBBC030/cho04.png',
      'bbbc/BBBC030/cho05.png',
      'bbbc/BBBC030/cho06.png',
      'bbbc/BBBC030/cho07.png',
      'bbbc/BBBC030/cho08.png',
      'bbbc/BBBC030/cho09.png',
      'bbbc/BBBC030/cho10.png',
      'bbbc/BBBC030/cho11.png',
      'bbbc/BBBC030/cho12.png',
      'bbbc/BBBC030/cho13.png',
      'bbbc/BBBC030/cho14.png',
      'bbbc/BBBC030/cho15.png',
      'bbbc/BBBC030/cho16.png',
      'bbbc/BBBC030/cho17.png',
      'bbbc/BBBC030/cho18.png',
      'bbbc/BBBC030/cho19.png',
      'bbbc/BBBC030/cho20.png',
      'bbbc/BBBC030/cho21.png',
      'bbbc/BBBC030/cho22.png',
      'bbbc/BBBC030/cho23.png',
      'bbbc/BBBC030/cho24.png',
      'bbbc/BBBC030/cho25.png',
      'bbbc/BBBC030/cho26.png',
      'bbbc/BBBC030/cho27.png',
      'bbbc/BBBC030/cho28.png',
      'bbbc/BBBC030/cho29.png',
      'bbbc/BBBC030/cho30.png',
      'bbbc/BBBC030/cho31.png',
      'bbbc/BBBC030/cho32.png',
      'bbbc/BBBC030/cho33.png',
      'bbbc/BBBC030/cho34.png',
      'bbbc/BBBC030/cho35.png',
      'bbbc/BBBC030/cho36.png',
      'bbbc/BBBC030/cho37.png',
      'bbbc/BBBC030/cho38.png',
      'bbbc/BBBC030/cho39.png',
      'bbbc/BBBC030/cho40.png',
      'bbbc/BBBC030/cho41.png',
      'bbbc/BBBC030/cho42.png',
      'bbbc/BBBC030/cho43.png',
      'bbbc/BBBC030/cho44.png',
      'bbbc/BBBC030/cho45.png',
      'bbbc/BBBC030/cho46.png',
      'bbbc/BBBC030/cho47.png',
      'bbbc/BBBC030/cho48.png',
      'bbbc/BBBC030/cho49.png',
      'bbbc/BBBC030/cho50.png',
      'bbbc/BBBC030/cho51.png',
      'bbbc/BBBC030/cho52.png',
      'bbbc/BBBC030/cho53.png',
      'bbbc/BBBC030/cho54.png',
      'bbbc/BBBC030/cho55.png',
      'bbbc/BBBC030/cho56.png',
      'bbbc/BBBC030/cho57.png',
      'bbbc/BBBC030/cho58.png',
      'bbbc/BBBC030/cho59.png',
      'bbbc/BBBC030/cho60.png',
    ],
  },
]

export default imageSets

const spriteDirectoryToPlateObservation = Object.fromEntries(
  imageSets
    .flatMap(is => is.plateObservations)
    .filter(obs => obs != null)
    .map(obs => [obs.spriteDirectory, obs]),
)
