/**
 * TODO(mark): Move to the back-end.
 * TODO(mark): Write function to validate plate maps.
 *
 * Right now, our users need to check in "experiment plates" containing multiple "experiments".
 * See LabelledWellMap.ts for more info on the experiments themselves.
 *
 * An ExperimentPlateMap specifies the set of experiments that are present on an experiment plate.
 * Users will select an ExperimentPlateMap when they check in an experiment plate.
 *
 * For now, we anticipate this will be used primarily for E11's use case.
 */

import { isEqual } from 'lodash/fp'
import { supportedPlateFormats } from '~/components/TinyMicroplate.interface'
import { WELL_MAPS_BY_LABEL, validateLabelledWellMap } from './LabelledWellMap'

export interface ExperimentPlateMap {
  name: string
  labelled_well_maps: string[]
  from_format: supportedPlateFormats
  to_format: supportedPlateFormats
}

export const _validateExperimentPlateMap = (experimentPlateMap: ExperimentPlateMap) => {
  for (const labelledWellMapLabel of experimentPlateMap.labelled_well_maps) {
    const labelledWellMap = WELL_MAPS_BY_LABEL[labelledWellMapLabel]

    if (labelledWellMap.fromFormat != experimentPlateMap.from_format) {
      throw new Error(
        `ExperimentPlateMap ${experimentPlateMap.name} has a labelledWellMap ${labelledWellMapLabel} that has a different fromFormat`,
      )
    }

    if (labelledWellMap.toFormat != experimentPlateMap.to_format) {
      throw new Error(
        `ExperimentPlateMap ${experimentPlateMap.name} has a labelledWellMap ${labelledWellMapLabel} that has a different toFormat`,
      )
    }

    validateLabelledWellMap(labelledWellMap)
  }
}

export const getExperimentPlateMaps = (): ExperimentPlateMap[] => {
  return [
    {
      name: 'Immunostain Antibodies 3ml',
      labelled_well_maps: ['primary_immunostain_3ml', 'secondary_immunostain_3ml'],
      from_format: 'wells_96',
      to_format: 'wells_6',
    },
    {
      name: 'Immunostain Antibodies',
      labelled_well_maps: ['primary_immunostain', 'secondary_immunostain'],
      from_format: 'wells_96',
      to_format: 'wells_6',
    },
    {
      name: '24WP Stamp',
      labelled_well_maps: ['24wp_stamp'],
      from_format: 'wells_24',
      to_format: 'wells_24',
    },
  ]
}

export const validateExperimentPlateMaps = (
  experimentPlateMaps: ExperimentPlateMap[],
) => {
  for (const experimentPlateMap of experimentPlateMaps) {
    _validateExperimentPlateMap(experimentPlateMap)
  }
}

export const getExperimentPlateMapNameForExperiments = (experiments: string[]) => {
  const experimentPlateMap = getExperimentPlateMaps().find(experimentPlateMap => {
    return isEqual(experimentPlateMap.labelled_well_maps.sort(), experiments.sort())
  })

  if (experimentPlateMap == null) {
    return 'Unknown Plate Map'
  }

  return experimentPlateMap.name
}
