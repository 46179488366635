import PropTypes from 'prop-types'
import { IconProps } from './types/IconProps'
const CellIcon = ({ className, onClick }: IconProps) => (
  <svg
    width='20'
    height='20'
    viewBox='0 0 20 20'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    className={className}
    onClick={onClick}
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M18 10C18 14.4183 14.4183 18 10 18C5.58172 18 2 14.4183 2 10C2 5.58172 5.58172 2 10 2C14.4183 2 18 5.58172 18 10ZM20 10C20 15.5228 15.5228 20 10 20C4.47715 20 0 15.5228 0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10ZM10.5 15C12.433 15 14 13.433 14 11.5C14 9.567 12.433 8 10.5 8C8.567 8 7 9.567 7 11.5C7 13.433 8.567 15 10.5 15ZM6.79903 6.25006C7.07518 6.72835 6.71744 7.45187 6 7.86608C5.28256 8.2803 4.4771 8.22835 4.20096 7.75006C3.92482 7.27177 4.28256 6.54825 5 6.13403C5.71744 5.71982 6.52289 5.77177 6.79903 6.25006ZM12.4881 6.20773C12.9023 6.92517 13.6259 7.28291 14.1042 7.00677C14.5825 6.73063 14.6344 5.92517 14.2202 5.20773C13.806 4.49029 13.0825 4.13255 12.6042 4.40869C12.1259 4.68483 12.0739 5.49029 12.4881 6.20773Z'
    />
  </svg>
)

CellIcon.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
}

export default CellIcon
