import { useEffect } from 'react'
import linkCellLineLotsToCulturesAPI, {
  LinkLotsAddDataRequest,
  LinkLotsAddDataResponse,
  LinkLotsLookupDataResponseCellLineLots,
  LinkLotsLookupDataResponseCellLines,
  LinkLotsParseDataResponseCellLineLots,
  LinkLotsParseDataResponseCellLines,
} from '~/api/operatorActions/linkCellLineLotsToCultures'
import LoadingMessageBox from '~/components/LoadingMessageBox'
import Toaster from '~/components/Toaster'
import BigNotification from '~/components/notifications/BigNotification'
import Notification from '~/components/notifications/Notification'
import { analytics } from '~/core/analytics'
import { useIsMounted } from '~/utils/hooks/useIsMounted'
import { displayCount } from '~/utils/string'
import {
  ConfirmUpdatesStepValidData,
  SubmitStepData,
  UploadDataStepValidData,
} from './LinkCellLineLotsToCulturesWizardData.interface'
import LinkLotsDataTable from './LinkLotsDataTable'
import LinkLotsPlatePreview from './LinkLotsPlatePreview'
import LinkLotsUnknownError from './LinkLotsUnknownError'
import {
  getNumDistinctCellLineLots,
  getNumDistinctCellLines,
} from './lookupDataResponseExtractors'
import { getLinkLotsPreviewPlates, getNumCultures } from './parseDataResponseExtractors'
import cs from './submit_step.scss'

interface SubmitStepProps {
  stepData: SubmitStepData
  setStepData: (stepData: Partial<SubmitStepData>) => void
  uploadDataStepData: UploadDataStepValidData
  confirmUpdatesStepData: ConfirmUpdatesStepValidData
}

export const assembleAddRequest = (
  confirmUpdatesStepData: ConfirmUpdatesStepValidData,
): LinkLotsAddDataRequest => {
  if (confirmUpdatesStepData.lookupDataResponse.type === 'cell_line_lots') {
    return {
      type: 'cell_line_lots',
      data: confirmUpdatesStepData.lookupDataResponse.data,
    }
  }

  return {
    type: 'cell_lines',
    data: confirmUpdatesStepData.lookupDataResponse.data,
  }
}

const SubmitStep = ({
  stepData,
  setStepData,
  uploadDataStepData,
  confirmUpdatesStepData,
}: SubmitStepProps) => {
  const isMounted = useIsMounted()

  const addData = async () => {
    const request = assembleAddRequest(confirmUpdatesStepData)

    setStepData({
      submitInProgress: true,
    })

    let addDataResponse: LinkLotsAddDataResponse | null = null
    try {
      addDataResponse = await linkCellLineLotsToCulturesAPI.addData(request)
    } catch (e) {
      Toaster.show({
        message: 'Error communicating with the server.',
        intent: 'danger',
      })
      if (!isMounted()) {
        return
      }
      setStepData({
        submitInProgress: false,
        addDataResponse: null,
      })
      return
    }
    if (!isMounted()) {
      return
    }
    setStepData({
      submitInProgress: false,
      addDataResponse,
    })
  }

  const renderSuccessNotification = (
    lookupDataResponse:
      | LinkLotsLookupDataResponseCellLineLots
      | LinkLotsLookupDataResponseCellLines,
    parseDataResponse:
      | LinkLotsParseDataResponseCellLineLots
      | LinkLotsParseDataResponseCellLines,
  ) => {
    const linkedValueCount =
      lookupDataResponse.type === 'cell_line_lots'
        ? displayCount('cell line lot', getNumDistinctCellLineLots(lookupDataResponse))
        : displayCount('cell line', getNumDistinctCellLines(lookupDataResponse))
    const message = `Successfully linked ${linkedValueCount} to ${displayCount('culture', getNumCultures(parseDataResponse))}`

    return (
      <BigNotification
        message={message}
        type='success'
        className={cs.successNotification}
      />
    )
  }

  useEffect(() => {
    addData()
  }, [])

  useEffect(() => {
    if (stepData.addDataResponse?.success) {
      if (uploadDataStepData.parseDataResponse.type === 'cell_line_lots') {
        analytics.track('Completed Link Cell Line Lots to Cultures', {
          numCultures: uploadDataStepData.parseDataResponse.data.length,
          numPlates: uploadDataStepData.parseDataResponse.culture_plates.length,
          lookedUpEntity: 'cell_line_lot',
        })
      } else if (uploadDataStepData.parseDataResponse.type === 'cell_lines') {
        analytics.track('Completed Link Cell Line Lots to Cultures', {
          numCultures: uploadDataStepData.parseDataResponse.data.length,
          numPlates: uploadDataStepData.parseDataResponse.culture_plates.length,
          lookedUpEntity: 'cell_line',
        })
      }
    }
  }, [stepData.addDataResponse])

  if (stepData.submitInProgress) {
    return (
      <LoadingMessageBox
        label='Saving Culture Metadata...'
        className={cs.loadingMessageBox}
      />
    )
  }

  // This means there was an HTTP error during addData.
  if (!stepData.addDataResponse) {
    return <LinkLotsUnknownError />
  }

  if (!stepData.addDataResponse.success) {
    return (
      <Notification
        className={cs.submitError}
        variant='mini'
        type='error'
        label={`Error saving culture metadata: ${stepData.addDataResponse.error}`}
      />
    )
  }

  return (
    <div className={cs.submitStep}>
      {renderSuccessNotification(
        confirmUpdatesStepData.lookupDataResponse,
        uploadDataStepData.parseDataResponse,
      )}
      <div className={cs.contents}>
        <div className={cs.dataTableColumn}>
          <LinkLotsDataTable
            className={cs.dataTable}
            lookupDataResponse={confirmUpdatesStepData.lookupDataResponse}
            parseDataResponse={uploadDataStepData.parseDataResponse}
          />
        </div>
        <div className={cs.platePreviewColumn}>
          <LinkLotsPlatePreview
            className={cs.platePreview}
            uploadType={uploadDataStepData.parseDataResponse.type}
            plates={getLinkLotsPreviewPlates(uploadDataStepData.parseDataResponse)}
          />
        </div>
      </div>
    </div>
  )
}

export default SubmitStep
