import cx from 'classnames'
import { displayCount } from '~/utils/string'
import GroupedErrorTable from '../GroupedErrorTable'
import {
  UploadLiveWellsStepData,
  UploadPlateDataStepValidData,
} from './BulkLoadCulturePlatesWizardData.interface'
import BulkLoadPlatePreview from './BulkLoadPlatePreview'
import { getBulkLoadParseWellDataErrorGroups } from './getBulkLoadParseWellDataErrorGroups'
import {
  getBulkLoadPreviewPlates,
  getNumPlates,
  getTotalLiveWells,
} from './getBulkLoadPreviewPlates'
import cs from './upload_live_wells_step_preview.scss'

export interface UploadLiveWellsStepPreviewProps {
  className?: string
  loading: boolean
  stepData: UploadLiveWellsStepData
  uploadPlateStepData: UploadPlateDataStepValidData
}

const UploadLiveWellsStepPreview = ({
  className,
  loading,
  stepData,
  uploadPlateStepData,
}: UploadLiveWellsStepPreviewProps) => {
  const renderContents = () => {
    if (loading) {
      return <div className={cs.info}>Loading preview...</div>
    }
    let { parseDataResponse } = stepData

    if (parseDataResponse !== null && parseDataResponse.type === 'errors') {
      return (
        <>
          <div className={cs.errorCount}>
            {displayCount('error', parseDataResponse.errors.length)} with pasted data
          </div>
          <GroupedErrorTable
            className={cs.parseDataErrorTable}
            errorGroups={getBulkLoadParseWellDataErrorGroups(parseDataResponse)}
          />
        </>
      )
    }
    const previewPlates = getBulkLoadPreviewPlates(uploadPlateStepData, stepData)
    return (
      <>
        <div className={cs.info}>
          Setting {displayCount('live well', getTotalLiveWells(previewPlates))} on{' '}
          {displayCount('plate', getNumPlates(previewPlates))}
        </div>
        <BulkLoadPlatePreview className={cs.platePreview} plates={previewPlates} />
      </>
    )
  }
  return (
    <div className={cx(className, cs.uploadLiveWellsStepPreview)}>
      <div className={cs.sectionTitle}>Preview</div>
      <div className={cs.contents}>{renderContents()}</div>
    </div>
  )
}

export default UploadLiveWellsStepPreview
