import PropTypes from 'prop-types'
import { IconProps } from './types/IconProps'
const CultureIcon = ({ className, onClick }: IconProps) => (
  <svg
    width='24'
    height='19'
    viewBox='-2 -2 28 23'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    className={className}
    onClick={onClick}
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M24 7.0586C23.995 3.15974 18.6243 0 12 0C5.37567 0 0.0050114 3.15974 0 7.0586H24ZM24 7.0586L24 11.2991C24 15.2005 18.6274 18.3632 12 18.3632C5.37258 18.3632 0 15.2005 0 11.2991V7.06406L24 7.0586ZM21.129 11.6492C18.928 13.1663 15.6548 14.1281 12 14.1281C8.34518 14.1281 5.072 13.1663 2.87102 11.6492C3.01739 12.2169 3.53994 13.0326 4.94709 13.861C6.61359 14.842 9.10072 15.5397 12 15.5397C14.8993 15.5397 17.3864 14.842 19.0529 13.861C20.4601 13.0326 20.9826 12.2168 21.129 11.6492ZM9.6629 4.01022C10.4038 4.01186 11.0032 4.61383 11.0015 5.35477C10.9999 6.09571 10.3979 6.69503 9.65698 6.6934L6.34005 6.68608C5.59911 6.68445 4.99979 6.08248 5.00143 5.34154C5.00306 4.6006 5.60503 4.00128 6.34597 4.00291L9.6629 4.01022ZM19.0031 9.35477C19.0047 8.61383 18.4054 8.01186 17.6644 8.01022L14.3475 8.00291C13.6066 8.00128 13.0046 8.6006 13.003 9.34154C13.0013 10.0825 13.6006 10.6844 14.3416 10.6861L17.6585 10.6934C18.3994 10.695 19.0014 10.0957 19.0031 9.35477Z'
    />
  </svg>
)

CultureIcon.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
}

export default CultureIcon
