import cx from 'classnames'
import { get } from 'lodash/fp'

import { Instrument, InstrumentStatus } from '~/common.interface'
import { isInstrumentInBadState } from '~/utils/instrument'
import GenericInstrumentPage from './GenericInstrumentPage'
import cs from './dataman_70_instrument_page.scss'

interface Dataman70InstrumentPageProps {
  className?: string
  instrument: Instrument
  status: InstrumentStatus
  live?: boolean
}

const Dataman70InstrumentPage = ({
  className,
  status,
  live,
  instrument,
}: Dataman70InstrumentPageProps) => {
  const renderRealTimeState = () => {
    if (isInstrumentInBadState(status)) {
      return null
    }

    return (
      <div className={cs.realTimeState}>
        <div className={cs.label}>Current Barcode</div>
        <div>{get(['real_time_state', 'current_barcode'], status) || 'None'}</div>
        <div className={cs.label}>Reader State</div>
        <div
          className={cx(
            cs.readerState,
            get(['real_time_state', 'is_on'], status) && cs.on,
          )}
        >
          {get(['real_time_state', 'is_on'], status) ? 'On' : 'Off'}
        </div>
      </div>
    )
  }

  return (
    <div className={cx(className, cs.dataman70InstrumentPage)}>
      <GenericInstrumentPage
        instrument={instrument}
        status={status}
        live={live}
        sidebarContents={renderRealTimeState()}
      />
    </div>
  )
}

export default Dataman70InstrumentPage
