// While this is called Empty Assay Plate, we currently allow users to load non-empty assay plates.
// This is an escape hatch, in case they need to for some reason.

import { useState } from 'react'

import TinyNotification from '~/components/notifications/TinyNotification'
import { LoadUnloadItemEmptyAssayPlateParams } from '~/pages/Workcell/OperatorActions/LoadUnloadItems/LoadUnloadItemsProcessItemParams.interface'
import { EmptyAssayPlateLoadParamsRequest } from '~/pages/Workcell/OperatorActions/reloadItems/convertChangeToReloadOperation'
import PlateBarcodeInput from '~/pages/Workcell/components/processItem/PlateBarcodeInput'
import { LabwareSelect } from '../LabwareSelect'
import { LoadParamsRequestOrError } from '../LoadParamsRequestOrError.interface'
import { LoadUnloadItemsProcessItemFormComponentProps } from './LoadUnloadItemsProcessItemFormComponentProps.interface'
import cs from './load_unload_items_empty_assay_plate_form.scss'

const LoadUnloadItemsEmptyAssayPlateForm = ({
  className,
  processItemParams,
  setProcessItemParams,
  disabled,
}: LoadUnloadItemsProcessItemFormComponentProps<'empty_assay_plate'>) => {
  const [lastSelectedLabwareName, setLastSelectedLabwareName] = useState<
    string | undefined
  >(undefined)

  return (
    <div className={className}>
      <TinyNotification
        type='bareInfo'
        className={cs.infoNotification}
        message='Assay Plates should be empty when checked in and will be filled by the workcell.'
      />
      <PlateBarcodeInput
        inputClassName={cs.uuidInput}
        value={processItemParams.uuid}
        onChange={uuid => {
          setProcessItemParams(
            (
              _latestProcessItemParams: Partial<LoadUnloadItemEmptyAssayPlateParams>,
            ) => ({
              ..._latestProcessItemParams,
              uuid: uuid,
            }),
          )
        }}
        existingPlateOrError={{
          error: processItemParams.uuidError,
          existingPlate: processItemParams.existingAssayPlate,
        }}
        onExistingPlateOrErrorUpdate={({ error, existingPlate }) => {
          setProcessItemParams(
            (
              _latestProcessItemParams: Partial<LoadUnloadItemEmptyAssayPlateParams>,
            ) => ({
              ..._latestProcessItemParams,
              uuidError: error,
              existingAssayPlate: existingPlate,
            }),
          )
        }}
        validationOptions={{
          processItemType: 'assay_plate',
          allowExistingPlate: true,
        }}
        disabled={disabled}
      />
      <LabwareSelect
        processItemType='assay_plate'
        className={cs.labwareSelect}
        defaultLabwareName={lastSelectedLabwareName}
        overrideLabwareName={processItemParams.existingAssayPlate?.labwareName}
        selectedLabware={processItemParams.selectedLabware}
        onLabwareSelect={labware => {
          setLastSelectedLabwareName(labware?.labware_name)
          setProcessItemParams(
            (
              _latestProcessItemParams: Partial<LoadUnloadItemEmptyAssayPlateParams>,
            ) => ({
              ..._latestProcessItemParams,
              selectedLabware: labware,
            }),
          )
        }}
        triggerClassName={cs.trigger}
        popoverClassName={cs.popover}
        disabled={disabled || !!processItemParams.existingAssayPlate}
      />
    </div>
  )
}

export const getEmptyAssayPlateLoadParamsRequestOrError = (
  processItemParams: Partial<LoadUnloadItemEmptyAssayPlateParams>,
): LoadParamsRequestOrError => {
  if (!processItemParams.selectedLabware) {
    return {
      error: 'Please select a labware.',
    }
  }
  if (!processItemParams.uuid) {
    return {
      error: 'Please enter a barcode.',
    }
  }

  if (processItemParams.uuidError) {
    return {
      error: processItemParams.uuidError,
    }
  }

  return {
    params: {
      labware_name: processItemParams.selectedLabware.labware_name,
      uuid: processItemParams.uuid,
    } as EmptyAssayPlateLoadParamsRequest,
  }
}

export default LoadUnloadItemsEmptyAssayPlateForm
