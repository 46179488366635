/*
  D3ChartWrapper wraps a D3Chart.
  Handles creating the chart and calling updateData.
  Handles tooltip logic.

  Example usage:

  <D3ChartWrapper
    D3ChartClass={D3LineGraph}
    className={className}
    data={data}
    options={options}
  />
*/

import cx from 'classnames'
import { set, size } from 'lodash/fp'
import PropTypes from 'prop-types'
import React, { useRef, useState, useLayoutEffect, useEffect } from 'react'

import ChartTooltip, { TooltipParams } from '~/components/ChartTooltip'

import { AxisDomain } from 'd3-axis'
import D3Chart, {
  D3ChartAxisOptions,
  D3ChartClassType,
  D3ChartLayoutOptions,
} from './D3Chart'
import cs from './d3_chart.scss'

interface D3ChartWrapperProps<
  DataType,
  XDomainType extends AxisDomain,
  YDomainType extends AxisDomain,
  ChartOptions,
> {
  className?: string
  data: DataType
  options?: Partial<ChartOptions>
  layoutOptions?: Partial<D3ChartLayoutOptions>
  axisOptions?: Partial<D3ChartAxisOptions<XDomainType, YDomainType>>
  D3ChartClass: D3ChartClassType<
    D3Chart<DataType, XDomainType, YDomainType>,
    XDomainType,
    YDomainType,
    ChartOptions
  >
  renderTooltipContents?: (tooltipParams: TooltipParams) => void
  chartTooltipTriggerFn?: string
  containerStyle?: React.CSSProperties
  showNoDataMessage?: boolean
  tooltipPosition?: 'right' | 'center'
  reloadKey?: string
}

const D3ChartWrapper = <
  DataType,
  XDomainType extends AxisDomain,
  YDomainType extends AxisDomain,
  ChartOptions,
>({
  data,
  options,
  axisOptions,
  layoutOptions,
  className,
  D3ChartClass,
  renderTooltipContents,
  chartTooltipTriggerFn,
  containerStyle,
  showNoDataMessage,
  tooltipPosition,
  reloadKey,
}: D3ChartWrapperProps<DataType, XDomainType, YDomainType, ChartOptions>) => {
  const d3Chart = useRef<D3Chart<DataType, XDomainType, YDomainType> | null>(null)
  const container = useRef<HTMLDivElement | null>(null)
  const [tooltipParams, setTooltipParams] = useState(null)

  useEffect(() => {
    return () => {
      if (d3Chart.current) {
        d3Chart.current.teardown()
      }
    }
  }, [])

  // Whenever the data changes, update it.
  useLayoutEffect(() => {
    if (data && d3Chart.current) {
      d3Chart.current._updateData(data)
    }
  }, [data])

  const onTooltipTrigger = _tooltipParams => {
    setTooltipParams(_tooltipParams)
  }

  // Whenever the options change, update it.
  useEffect(() => {
    if (data && d3Chart.current) {
      let chartOptions = options || {}
      if (chartTooltipTriggerFn) {
        chartOptions = set(chartTooltipTriggerFn, onTooltipTrigger)(chartOptions)
      }
      d3Chart.current.updateChartOptions(chartOptions)
    }
  }, [options])

  // Whenever the container div changes, recreate the line graph.
  // This should happen very rarely.
  // NOTE: There is currently a gotcha regarding placing D3ChartWrappers inside modals that have
  // an expand animation when opened.
  // We use this particular combination of useLayoutEffect and a container ref because
  // the code runs when the container is mounted, before the animation transform is applied.
  // ResizeObservers may be another option, but have more performance concerns.
  useLayoutEffect(() => {
    if (container.current) {
      let chartOptions = options || {}
      if (chartTooltipTriggerFn) {
        chartOptions = set(chartTooltipTriggerFn, onTooltipTrigger)(chartOptions)
      }
      d3Chart.current = new D3ChartClass(
        container.current,
        layoutOptions || {},
        axisOptions || {},
        chartOptions || {},
      )
    }
    if (data && d3Chart.current) {
      d3Chart.current._updateData(data)
    }
    return () => {
      if (d3Chart.current) {
        d3Chart.current.teardown()
      }
    }
  }, [reloadKey])

  const onTooltipClose = () => {
    setTooltipParams(null)
  }

  if (!data || (showNoDataMessage && size(data) === 0)) {
    return <div className={cx(className, cs.chart, cs.noData)}>No data</div>
  }

  return (
    <>
      <div className={cx(className, cs.chart)} style={containerStyle} ref={container} />
      {renderTooltipContents && tooltipParams && tooltipPosition && (
        <ChartTooltip
          tooltipParams={tooltipParams}
          renderTooltipContents={renderTooltipContents}
          onTooltipClose={onTooltipClose}
          position={tooltipPosition}
        />
      )}
    </>
  )
}

D3ChartWrapper.propTypes = {
  className: PropTypes.string,
  // Any dynamic styles for the chart container
  containerStyle: PropTypes.shape({}),
  // Should be a subclass of D3Chart. This is a CLASS, not an INSTANCE.
  D3ChartClass: PropTypes.any,
  data: PropTypes.any,
  options: PropTypes.objectOf(PropTypes.any),
  chartTooltipTriggerFn: PropTypes.string,
  renderTooltipContents: PropTypes.func,
  tooltipPosition: PropTypes.oneOf(['center', 'right']),
  showNoDataMessage: PropTypes.bool,
  // Modify when you want the d3 chart to completely reinitialize.
  reloadKey: PropTypes.string,
}

D3ChartWrapper.defaultProps = {
  showNoDataMessage: true,
  reloadKey: '',
}

export default D3ChartWrapper
