import desktopAPI from '~/api/desktop'
import { components } from '~/api/desktop/generated-schema'
import { sleep } from '~/utils/async'

// TODO: This should be automatically generated when we migrate the getTaskStatus
//  endpoint
// TODO: Flesh out these types - this would still be better as a union of
// {error: null; response: TResponse} | {error: string; response: null}
export interface TaskExecutionResult<TResponse> {
  success: boolean
  result?: TResponse
  error?: string
}

export type TaskExecutionStartResponse =
  components['schemas']['TaskExecutionStartResponse']

// Execute a custom script.
// Update setExecutingTask as the script runs.
// TODO: This does not utilize the return type of the taskEndpoint function,
//  resulting in the need to explicitly narrow the result type. We need to migrate
//  the getTaskStatus endpoint to v2 to properly generate types.
const executeTaskEndpoint = async <TResponse>(
  taskEndpoint: () => Promise<TaskExecutionStartResponse>,
  setExecutingTask: (boolean) => void,
  setTaskProgressMessage?: (string) => void,
): Promise<TaskExecutionResult<TResponse> | null> => {
  if (setTaskProgressMessage) {
    setTaskProgressMessage('')
  }
  setExecutingTask(true)

  let taskUuid: string
  // Catch errors from initial request, e.g. invalid schema or network error
  try {
    const response = await taskEndpoint()
    taskUuid = response.taskId
  } catch (e) {
    setExecutingTask(false)
    return {
      success: false,
      error: JSON.stringify(e),
    }
  }

  let done = false
  // TODO: We should avoid returning nulls
  let res: TaskExecutionResult<TResponse> | null = null

  while (!done) {
    // eslint-disable-next-line no-await-in-loop
    await sleep(1000)

    // TODO: What happens if there's a network error? we should probably handle that
    // eslint-disable-next-line no-await-in-loop

    let taskStatus

    try {
      taskStatus = await desktopAPI.getTaskStatus(taskUuid)
    } catch (e) {
      setExecutingTask(false)
      return {
        success: false,
        error: String(e),
      }
    }

    if (taskStatus.error === 'Not found' || taskStatus.status === 'Failure') {
      res = {
        success: false,
        error: taskStatus.error,
      }
      done = true
    } else if (taskStatus.status === 'Success') {
      setExecutingTask(true)
      res = {
        success: true,
        result: taskStatus.result,
      }
      done = true
    } else if (taskStatus.status === 'Running' && setTaskProgressMessage) {
      setTaskProgressMessage(taskStatus.progressMessage)
    }
    // TODO: else? we should probably have an explicit status for polling
  }
  setExecutingTask(false)
  return res
}

export default executeTaskEndpoint
