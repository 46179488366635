import cx from 'classnames'
import { noop } from 'lodash/fp'
import PropTypes from 'prop-types'

import Button from '~/components/buttons/Button'
import MinimalBoldButton from '~/components/buttons/MinimalBoldButton'
import MinimalButton from '~/components/buttons/MinimalButton'
import LaunchIcon from '~/components/icons/LaunchIcon'

import cs from './button_playground.scss'

interface ButtonPlaygroundProps {
  className?: string
}

const ButtonPlayground = ({ className }: ButtonPlaygroundProps) => {
  return (
    <div className={cx(className, cs.buttonPlayground)}>
      <div className={cs.contents}>
        <div className={cs.item}>
          <div className={cs.label}>Button</div>
          <Button label='Schedule Routines' className={cs.button} onClick={noop} />
          <Button
            label='Schedule Routines'
            type='primary'
            className={cs.button}
            onClick={noop}
          />
          <Button
            label='Schedule Routines'
            type='danger'
            className={cs.button}
            onClick={noop}
          />
          <Button
            label='Schedule Routines'
            className={cs.button}
            onClick={noop}
            disabled
          />
          <Button
            label='Schedule Routines'
            className={cs.button}
            onClick={noop}
            IconComponent={LaunchIcon}
          />
        </div>
        <div className={cs.item}>
          <div className={cs.label}>MinimalButton</div>
          <MinimalButton
            label='Schedule Routines'
            className={cs.button}
            onClick={noop}
          />
          <MinimalButton
            label='Schedule Routines'
            type='primary'
            className={cs.button}
            onClick={noop}
          />
          <MinimalButton
            label='Schedule Routines'
            type='danger'
            className={cs.button}
            onClick={noop}
          />
          <MinimalButton
            label='Schedule Routines'
            className={cs.button}
            onClick={noop}
            disabled
          />
          <MinimalButton
            label='Schedule Routines'
            className={cs.button}
            onClick={noop}
            IconComponent={LaunchIcon}
          />
        </div>
        <div className={cs.item}>
          <div className={cs.label}>MinimalBoldButton</div>
          <MinimalBoldButton
            label='Schedule Routines'
            className={cs.button}
            onClick={noop}
          />
          <MinimalBoldButton
            label='Schedule Routines'
            type='primary'
            className={cs.button}
            onClick={noop}
          />
          <MinimalBoldButton
            label='Schedule Routines'
            type='danger'
            className={cs.button}
            onClick={noop}
          />
          <MinimalBoldButton
            label='Schedule Routines'
            className={cs.button}
            onClick={noop}
            disabled
          />
          <MinimalBoldButton
            label='Schedule Routines'
            className={cs.button}
            onClick={noop}
            IconComponent={LaunchIcon}
          />
        </div>
      </div>
    </div>
  )
}

ButtonPlayground.propTypes = {
  className: PropTypes.string,
}

export default ButtonPlayground
