import cx from 'classnames'

import { LinkLotsLookupDataResponseErrors } from '~/api/operatorActions/linkCellLineLotsToCultures'
import CloseIcon from '~/components/icons/CloseIcon'
import { displayCount } from '~/utils/string'
import GroupedErrorTable from '../../GroupedErrorTable'
import { getLinkLotsLookupDataErrorGroups } from './getLinkLotsLookupDataErrorGroups'
import cs from './lookup_errors_inline_dialog.scss'

export interface LookupErrorsInlineDialogProps {
  className?: string
  onClose: () => void
  lookupErrorResponse: LinkLotsLookupDataResponseErrors
}

const LookupErrorsInlineDialog = ({
  className,
  onClose,
  lookupErrorResponse,
}: LookupErrorsInlineDialogProps) => {
  return (
    <div className={cx(className, cs.lookupErrorsInlineDialog)}>
      <div className={cs.header}>
        <div className={cs.headerInner}>
          {displayCount('error', lookupErrorResponse.errors.length)} looking up data via
          integration
        </div>
        <div className={cs.fill} />
        <CloseIcon className={cs.closeIcon} onClick={onClose} />
      </div>
      <div className={cs.content}>
        <GroupedErrorTable
          className={cs.parseDataErrorTable}
          errorGroups={getLinkLotsLookupDataErrorGroups(lookupErrorResponse)}
        />
      </div>
    </div>
  )
}

export default LookupErrorsInlineDialog
