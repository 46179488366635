import cx from 'classnames'
import { times } from 'lodash'
import { KeyboardEvent, ReactNode, useCallback, useEffect, useState } from 'react'

import { FragmentType, gql, unmaskFragment } from '~/__generated__'
import Dialog from '~/components/Dialog'
import Link from '~/components/Link'

import { useQuery } from '@apollo/client'
import { MontageImagesDialogFragmentFragment } from '~/__generated__/graphql'
import LoadingMessage from '~/components/LoadingMessage'
import { analytics } from '~/core/analytics'
import CultureRelativeTimestamp from '../../components/CultureRelativeTimestamp'
import { useMetrics } from '../../metrics'
import cs from './montage_images_dialog.scss'

export const montageImagesDialogFragment = gql(`
  fragment MontageImagesDialogFragment on MontageGraphQL {
    id
    largeImages: images(positions: ALL) {
      montageIndex
      imageUrl(size: SIZE_1500)
    }
    dimensions {
      rows
      cols
    }
    culture {
      id
      createdAt
      status
      name
      culturePlate {
        id
        barcode
      }
      well
    }
  }
`)

const montageImagesDialogQuery = gql(`
  query MontageImagesDialogQuery($id:UUID!) {
    wellCulture(id: $id) {
      montage {
        ...MontageImagesDialogFragment
      }
      confluence {
        value
        lastUpdatedAt
      }
    }
  }
`)

type ImageGraphQLFragment = MontageImagesDialogFragmentFragment['largeImages'][number]

interface MontageImagesDialogProps {
  montage: FragmentType<typeof montageImagesDialogFragment>
  confluence?: number
  timestamp?: string
  header?: ReactNode
  initialImageIndex?: number
  isOpen: boolean
  onClose: () => void

  /** @default true */
  linkToCulture?: boolean
}

interface MontageImagesDialogStandaloneProps {
  cultureId: string
  confluence?: number
  timestamp?: string
  header?: ReactNode
  isOpen: boolean
  onClose: () => void
}

export function MontageImagesDialogStandalone({
  cultureId,
  ...props
}: MontageImagesDialogStandaloneProps) {
  const { loading, error, data } = useQuery(montageImagesDialogQuery, {
    variables: { id: cultureId },
  })
  if (data?.wellCulture?.montage) {
    return (
      <MontageImagesDialog
        montage={data.wellCulture?.montage}
        confluence={data.wellCulture?.confluence?.value || undefined}
        timestamp={data.wellCulture.confluence?.lastUpdatedAt || undefined}
        isOpen={props.isOpen}
        onClose={props.onClose}
        header={props.header}
      />
    )
  } else if (loading) {
    return <LoadingMessage />
  } else if (error) {
    throw error
  } else {
    return <>No Montage Available</>
  }
}

export default function MontageImagesDialog({
  header,
  isOpen,
  onClose,
  linkToCulture,
  ...props
}: MontageImagesDialogProps) {
  const metrics = useMetrics()

  const [imageIndex, setImageIndex] = useState(props.initialImageIndex ?? 0)

  const montage = unmaskFragment(montageImagesDialogFragment, props.montage)
  const culture = montage.culture
  const numRows = montage.dimensions.rows
  const numCols = montage.dimensions.cols

  const images = new Array<ImageGraphQLFragment | undefined>(numCols * numRows)
  montage.largeImages.forEach(image => (images[image.montageIndex - 1] = image))

  const [imageLoaded, setImageLoaded] = useState<Array<boolean>>(
    new Array(numCols * numRows).fill(false),
  )

  useEffect(
    () => metrics.montageImages.open(culture.id, montage.id),
    [culture.id, montage.id, metrics],
  )
  useEffect(
    () => metrics.montageImages.select(culture.id, montage.id, imageIndex),
    [culture.id, montage.id, imageIndex, metrics],
  )
  useEffect(() => {
    analytics.track('Opened Image Montage', {
      culture: culture.id,
      montage: montage.id,
    })
  }, [culture.id, montage.id])
  useEffect(() => {
    analytics.track('Selected Montage Image', {
      culture: culture.id,
      montage: montage.id,
      index: imageIndex,
    })
  }, [culture.id, montage.id, imageIndex])

  return (
    <Dialog
      noPadding
      isOpen={isOpen}
      onClose={onClose}
      title={'Image Viewer'}
      className={cs.dialogBody}
    >
      {header ? <div className={cs.header}>{header}</div> : null}

      <div className={cs.imageContainer}>
        {imageLoaded[imageIndex] ? null : (
          <div className={cs.imagePlaceholder}>
            <LoadingMessage label={'Loading image...'} variant={'large'} />
          </div>
        )}
        <img
          className={imageLoaded[imageIndex] ? cs.image : cs.imageHidden}
          src={images[imageIndex]?.imageUrl ?? '#'}
          key={imageIndex}
          onLoad={() =>
            setImageLoaded(
              imageLoaded.map((_, i) => {
                if (i === imageIndex) return true
                return _
              }),
            )
          }
        />
      </div>

      <div className={cs.footer}>
        <div className={cs.leftSection}>
          <ImageGrid
            numRows={numRows}
            numCols={numCols}
            selectedIndex={imageIndex}
            onIndexSelected={index => {
              setImageIndex(index)
            }}
          />
          <div>
            <div className={cs.prominentInfo}>
              Image {imageIndex + 1} of {numRows * numCols}
            </div>
            {props.timestamp ? (
              <div className={cs.subInfo}>
                Captured{' '}
                <CultureRelativeTimestamp
                  timestamp={props.timestamp}
                  createdAt={culture.createdAt}
                  status={culture.status}
                  className={cs.relativeTimestamp}
                />
              </div>
            ) : null}
          </div>
        </div>

        <div className={cs.rightSection}>
          {props.confluence ? (
            <div className={cs.prominentInfo}>
              Confluence: {Math.round(props.confluence)}%
            </div>
          ) : null}
          <div className={cs.subInfo}>
            {culture.culturePlate.barcode} &middot;{' '}
            {linkToCulture == null || linkToCulture == true ? (
              <Link
                to={`/monitor/culture/${culture.id}`}
                className={cs.cultureLink}
                variant='primary'
              >
                Well {culture.well}
              </Link>
            ) : (
              <>Well {culture.well}</>
            )}
          </div>
        </div>
      </div>
    </Dialog>
  )
}

function ImageGrid({
  numRows,
  numCols,
  selectedIndex,
  onIndexSelected,
}: {
  numRows: number
  numCols: number
  selectedIndex: number
  onIndexSelected: (index: number) => void
}) {
  const onKeyHandler = useCallback(
    (event: KeyboardEvent) => {
      const rowPos = Math.floor(selectedIndex / numCols)
      const colPos = selectedIndex % numCols
      switch (event.key) {
        case 'ArrowDown':
          if (rowPos < numRows - 1) {
            onIndexSelected(selectedIndex + numCols)
          }
          break
        case 'ArrowUp':
          if (rowPos > 0) {
            onIndexSelected(selectedIndex - numCols)
          }
          break
        case 'ArrowRight':
          if (colPos < numCols - 1) {
            onIndexSelected(selectedIndex + 1)
          }
          break
        case 'ArrowLeft':
          if (colPos > 0) {
            onIndexSelected(selectedIndex - 1)
          }
          break
      }
    },
    [selectedIndex, onIndexSelected],
  )
  const autoFocus = useCallback(
    (element: HTMLDivElement | null) => element?.focus(),
    [],
  )
  return (
    <div
      className={cs.imageGridContainer}
      tabIndex={0}
      onKeyDown={onKeyHandler}
      ref={autoFocus}
    >
      <div className={cs.imageGrid}>
        {times(numRows, row => (
          <div key={row} className={cs.imageGridRow}>
            {times(numCols, col => {
              const index = row * numCols + col
              return (
                <div
                  key={index}
                  className={cx(
                    cs.imageGridImage,
                    selectedIndex === index
                      ? cs.imageGridImage__selected
                      : cs.imageGridImage__unselected,
                  )}
                  onClick={() => {
                    onIndexSelected(index)
                  }}
                />
              )
            })}
          </div>
        ))}
      </div>
    </div>
  )
}
