import { useState } from 'react'

import cx from 'classnames'
import { find, uniq, uniqBy } from 'lodash/fp'
import Link from '~/components/Link'
import PopupMenu from '~/components/PopupMenu'
import Select, { SelectOption } from '~/components/Select'
import BareWarningIcon from '~/components/icons/BareWarningIcon'
import VerticalMenuIcon from '~/components/icons/VerticalMenuIcon'
import { useDemoMutation } from '~/demoControls/DemoContext'
import { displayCount } from '~/utils/string'
import { ClePhase, ClePlate } from '../clePlates/ClePlate.interface'
import { CLE_PLATES, demoInitialData } from '../data/generateClePipelinePlates'
import ClePipelineViz from './ClePIpelineViz'
import ClePipelineGrid from './ClePipelineGrid'
import cs from './cle_pipeline_view.scss'

const getNumPlates = (plates: ClePlate[]) => {
  return plates.length
}

const getNumPlatesRequiringReview = (plates: ClePlate[]) => {
  const platesWithAlerts = plates.filter(plate => plate.hasAlert)
  return platesWithAlerts.length
}

const getOwnerSelectOptions = (plates: ClePlate[]) => {
  const owners = uniq(plates.map(plate => plate.owner))
  return owners.map(owner => ({
    key: owner,
    label: owner,
  }))
}

const getCellLineSelectOptions = (plates: ClePlate[]) => {
  const cellLines = uniqBy(
    'cellLineIndex',
    plates.map(plate => plate.cellLine),
  )
  return cellLines.map(cellLine => ({
    key: String(cellLine.cellLineIndex),
    label: cellLine.name,
  }))
}

const itemMatchesQuery = (item, queryLowerCase) =>
  item && item.label.toLowerCase().includes(queryLowerCase)

const ClePipelineView = ({
  useLocalAssets,
}: {
  useLocalAssets: boolean
}) => {
  // Just calculate the plates once per re-fresh.
  const clePipelinePlates = CLE_PLATES

  const [selectedOwner, setSelectedOwner] = useState<string | null>(null)
  const [selectedCellLineIndex, setSelectedCellLineIndex] = useState<number | null>(
    null,
  )
  const [selectedPhase, setSelectedPhase] = useState<ClePhase | null>(null)

  const [isDropdownOpen, setDropdownOpen] = useState(false)
  const resetDemoMutation = useDemoMutation('cle', () => demoInitialData)
  const pipelineActionsOptions = [
    {
      label: 'Reset Demo',
      action: resetDemoMutation,
    },
    {
      label: 'Fast-Forward 3 days...',
      action: () => {},
    },
  ]

  const handleSelectedOwnerChange = (value: SelectOption) => {
    setSelectedOwner(value.label)
  }

  const handleSelectedCellLineChange = (value: SelectOption) => {
    setSelectedCellLineIndex(value.key === null ? null : Number(value.key))
  }

  const getFilteredPlates = () => {
    return clePipelinePlates
      .filter(plate => selectedOwner === null || plate.owner === selectedOwner)
      .filter(
        plate =>
          selectedCellLineIndex === null ||
          plate.cellLine.cellLineIndex === selectedCellLineIndex,
      )
      .filter(plate => selectedPhase === null || plate.phase === selectedPhase)
  }

  const renderControls = () => {
    const ownerSelectOptions = getOwnerSelectOptions(clePipelinePlates)
    const cellLineSelectOptions = getCellLineSelectOptions(clePipelinePlates)
    return (
      <div className={cs.controls}>
        <Select<SelectOption>
          label='Owner'
          items={ownerSelectOptions}
          itemKey='key'
          itemLabelKey='label'
          filterable
          itemMatchesQuery={itemMatchesQuery}
          activeItem={find(['label', selectedOwner], ownerSelectOptions) || null}
          onChange={handleSelectedOwnerChange}
          className={cs.select}
          triggerClassName={cs.trigger}
          popoverClassName={cs.popover}
          allowNoneOption
          noneOptionText='All'
        />
        <Select<SelectOption>
          label='Cell Line'
          items={cellLineSelectOptions}
          itemKey='key'
          itemLabelKey='label'
          filterable
          itemMatchesQuery={itemMatchesQuery}
          activeItem={
            find(
              option => option.key === String(selectedCellLineIndex),
              cellLineSelectOptions,
            ) || null
          }
          onChange={handleSelectedCellLineChange}
          className={cs.select}
          triggerClassName={cs.trigger}
          popoverClassName={cs.popover}
          allowNoneOption
          noneOptionText='All'
        />
      </div>
    )
  }

  const filteredPlates = getFilteredPlates()
  const numPlatesRequiringReview = getNumPlatesRequiringReview(clePipelinePlates)

  return (
    <div className={cx(cs.clePipelineView)}>
      <div className={cs.header}>
        <div className={cs.left}>
          <div className={cs.title}>
            iPSC Engineering Workflow
            <PopupMenu
              options={pipelineActionsOptions}
              trigger={
                <a
                  className={cs.pipelineActions}
                  onClick={() => setDropdownOpen(!isDropdownOpen)}
                >
                  <VerticalMenuIcon className={cs.menuIcon} />
                </a>
              }
            />
          </div>
          <div className={cs.subtitle}>
            {displayCount('plate', getNumPlates(clePipelinePlates))} in progress
          </div>
        </div>
        <div className={cs.fill} />
        <Link
          className={cs.alertMessage}
          to={`/cle-2025/decisions/hitpicking/ACS-1026-LD4K-KO`}
        >
          <BareWarningIcon className={cs.alertIcon} />
          {displayCount('plate', numPlatesRequiringReview)}{' '}
          {numPlatesRequiringReview === 1 ? 'requires' : 'require'} review
        </Link>
      </div>
      <ClePipelineViz
        plates={clePipelinePlates}
        selectedPhase={selectedPhase}
        setSelectedPhase={setSelectedPhase}
      />
      {renderControls()}
      <ClePipelineGrid plates={filteredPlates} useLocalAssets={useLocalAssets} />
    </div>
  )
}

export default ClePipelineView
