import cx from 'classnames'
import PropTypes from 'prop-types'

import CheckmarkIcon from '~/components/icons/CheckmarkIcon'
import WarningIcon from '~/components/icons/WarningIcon'

import React from 'react'
import Link from '../Link'
import CloseIcon from '../icons/CloseIcon'
import cs from './notification.scss'

interface NotificationProps {
  className?: string
  type: 'warning' | 'error' | 'success' | 'accent'
  variant?: 'mini'
  label?: React.ReactNode
  showContactUs?: boolean
  onClose?: () => void
}

const Notification = ({
  className,
  label,
  type,
  showContactUs,
  variant,
  onClose,
}: NotificationProps) => (
  <div className={cx(className, cs.notification, cs[type], variant && cs[variant])}>
    <div className={cs.inner}>
      {type === 'success' || type === 'accent' ? (
        <CheckmarkIcon className={cs.icon} />
      ) : (
        <WarningIcon className={cs.icon} />
      )}
      <div className={cs.message}>{label}</div>
      {onClose && <CloseIcon className={cs.closeIcon} onClick={onClose} />}
    </div>
    {showContactUs && (
      <div className={cs.contactUs}>
        Need help?&nbsp;
        <Link external to='mailto:support@monomerbio.com' className={cs.link}>
          Contact Monomer Support
        </Link>
      </div>
    )}
  </div>
)

Notification.propTypes = {
  className: PropTypes.string,
  type: PropTypes.oneOf(['warning', 'error', 'success', 'accent']),
  variant: PropTypes.oneOf(['mini']),
  label: PropTypes.node,
  showContactUs: PropTypes.bool,
}

Notification.defaultProps = {
  type: 'success',
}

export default Notification
