import pluralize from 'pluralize'
import { components } from '~/api/desktop/generated-schema'
import { BulkLoadParseWellDataResponseErrors } from '~/api/operatorActions/bulkLoadCulturePlates'
import { displayCount } from '~/utils/string'
import { ErrorGroup } from '../GroupedErrorTable'
import { generateErrorGroups } from '../generateErrorGroups'
import {
  ParseSpreadsheetDataError,
  getGroupHeaderForSpreadsheetErrorType,
  getSpreadsheetErrorDisplayElement,
} from '../spreadsheetDataError'

export type BulkLoadParseWellDataError =
  | ParseSpreadsheetDataError
  | components['schemas']['ParseCultureDataInvalidWell']
  | components['schemas']['ParseCultureDataPlateNotInOriginalRequest']

const _getErrorTypeForError = (
  error: BulkLoadParseWellDataError,
): BulkLoadParseWellDataError['type'] => {
  return error.type
}

const _getGroupHeaderForErrorType = (
  errorType: BulkLoadParseWellDataError['type'],
  numErrors: number,
): string => {
  if (errorType === 'invalid_well') {
    return `${displayCount('well', numErrors)} ${pluralize('is', numErrors)} invalid based on the plate dimensions.`
  } else if (errorType == 'plate_not_in_original_request') {
    return `${displayCount('plate', numErrors)} ${pluralize('is', numErrors)} not in the original request.`
  }

  return getGroupHeaderForSpreadsheetErrorType(errorType, numErrors)
}
const _getErrorDisplayElement = (
  error: BulkLoadParseWellDataError,
): React.ReactNode => {
  if (error.type == 'invalid_well') {
    return (
      <span>
        <b>{error.well}</b> is not valid for plate <b>{error.plate_barcode}</b> with
        plate format {error.plate_format}. <i>(Row {error.line_number})</i>
      </span>
    )
  } else if (error.type == 'plate_not_in_original_request') {
    return (
      <span>
        Plate <b>{error.plate_barcode}</b> is not in the original request.{' '}
        <i>(Row {error.line_number})</i>
      </span>
    )
  }
  return getSpreadsheetErrorDisplayElement(error)
}

export const getBulkLoadParseWellDataErrorGroups = (
  errors: BulkLoadParseWellDataResponseErrors,
): ErrorGroup[] => {
  return generateErrorGroups<
    BulkLoadParseWellDataError,
    BulkLoadParseWellDataError['type']
  >(
    errors.errors,
    _getErrorTypeForError,
    _getGroupHeaderForErrorType,
    _getErrorDisplayElement,
  )
}
