import PropTypes from 'prop-types'
import { IconProps } from './types/IconProps'

const AddTeamIcon = ({ className, onClick }: IconProps) => (
  <svg
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    onClick={onClick}
    className={className}
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M12 12C13.9301 12 15.5 10.43 15.5 8.5C15.5 6.57001 13.9301 5 12 5C10.0699 5 8.5 6.57001 8.5 8.5C8.5 10.43 10.0699 12 12 12ZM5 15V12H8V10H5V7H3V10H0V12H3V15H5ZM12 13.75C9.66003 13.75 5 14.92 5 17.25V19H19V17.25C19 14.92 14.34 13.75 12 13.75ZM12 15.75C10.21 15.75 8.17993 16.42 7.33997 17H16.6599C15.8199 16.42 13.7899 15.75 12 15.75ZM13.5 8.5C13.5 7.67001 12.83 7 12 7C11.17 7 10.5 7.67001 10.5 8.5C10.5 9.32999 11.17 10 12 10C12.83 10 13.5 9.32999 13.5 8.5ZM17 12C18.9301 12 20.5 10.43 20.5 8.5C20.5 6.57001 18.9301 5 17 5C16.76 5 16.52 5.01999 16.29 5.07001C17.05 6.01001 17.5 7.20001 17.5 8.5C17.5 9.79999 17.03 10.98 16.27 11.92C16.51 11.97 16.75 12 17 12ZM21 17.25C21 15.89 20.3199 14.83 19.3199 14.02C21.5599 14.49 24 15.56 24 17.25V19H21V17.25Z'
    />
  </svg>
)

AddTeamIcon.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
}

export default AddTeamIcon
