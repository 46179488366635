import cx from 'classnames'
import PropTypes from 'prop-types'
import { useContext, useEffect, useState } from 'react'
import operatorActionAPI, { EnabledOperatorConfigs } from '~/api/operatorActions/shared'
import WorkcellStatusContext from '~/pages/Workcell/WorkcellStatusContext'
import { getComparatorString } from '~/utils/array'
import { useIsMounted } from '~/utils/hooks/useIsMounted'
import { getSortedActionNamesForMenu } from '~/utils/operatorAction'
import { getEnabledOperatorActions } from '../utils/workcellStatus'
import { OperatorActionOption } from './OperatorActionOption'
import cs from './operator_actions_menu.scss'
import { useEnabledAndUnusedOperatorActionNames } from './useEnabledAndUnusedOperatorActionNames'

interface OperatorActionsMenuProps {
  className?: string
}
const OperatorActionsMenu = ({ className }: OperatorActionsMenuProps) => {
  const isMounted = useIsMounted()
  const [operatorActionConfigs, setOperatorActionConfigs] =
    useState<EnabledOperatorConfigs | null>(null)

  const workcellStatus = useContext(WorkcellStatusContext)
  const { enabledOperatorActionNames } = useEnabledAndUnusedOperatorActionNames()

  const fetchOperatorActionConfigs = () => {
    operatorActionAPI.getAllEnabledConfigs().then(allEnabledConfigs => {
      if (isMounted()) {
        setOperatorActionConfigs(allEnabledConfigs.configs)
      }
    })
  }

  useEffect(() => {
    fetchOperatorActionConfigs()
  }, [getComparatorString(getEnabledOperatorActions(workcellStatus))])

  if (operatorActionConfigs === null) return null

  if (enabledOperatorActionNames.length === 0) {
    return <div className={cs.bigMessage}>No operator actions configured.</div>
  }

  const actions = [
    ...getSortedActionNamesForMenu(enabledOperatorActionNames),
    '_filler',
  ]

  return (
    <div className={cx(cs.actionContainer, className)}>
      {actions.map(action => (
        <OperatorActionOption
          actionName={action}
          operatorActionConfigs={operatorActionConfigs}
        />
      ))}
    </div>
  )
}

OperatorActionsMenu.propTypes = {
  className: PropTypes.string,
}

export default OperatorActionsMenu
