import cx from 'classnames'
import Notification from '~/components/notifications/Notification'
import cs from './bulk_load_culture_plates_unknown_error.scss'

export interface BulkLoadCulturePlatesUnknownErrorProps {
  className?: string
}

const BulkLoadCulturePlatesUnknownError = ({
  className,
}: BulkLoadCulturePlatesUnknownErrorProps) => {
  return (
    <Notification
      className={cx(cs.bulkLoadCulturePlatesUnknownError, className)}
      variant='mini'
      type='error'
      label='An unknown error has occurred'
    />
  )
}

export default BulkLoadCulturePlatesUnknownError
