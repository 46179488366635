import { highlight, languages } from 'prismjs/components/prism-core'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import Editor from 'react-simple-code-editor'
import 'prismjs/components/prism-clike'
import 'prismjs/components/prism-python'
import 'prismjs/themes/prism.css'

import HelpPopover from '~/components/HelpPopover'
import Toaster from '~/components/Toaster'
import Button from '~/components/buttons/Button'

import { noop } from 'lodash/fp'
import cs from './s3_upload_script_tab.scss'
import { DataUploaderScriptApi } from './types/DataUploaderScriptAPI.interface'

interface S3UploadScriptTabProps {
  onClose: () => void
  example: string
  api: DataUploaderScriptApi
}

const S3UploadScriptTab = ({ api, onClose, example }: S3UploadScriptTabProps) => {
  const [script, setScript] = useState('')
  const [scriptEdits, setScriptEdits] = useState('')

  const initialize = async () => {
    const _script = await api.getScript()
    setScript(_script)
    setScriptEdits(_script)
  }

  useEffect(() => {
    initialize()
  }, [])

  const handleScriptSave = async () => {
    const oldScript = script
    const newScript = scriptEdits

    setScript(newScript)
    onClose()
    try {
      await api.setScript(newScript)
    } catch (_error) {
      // No need to handle disconnect error here, since it is handled with regular updateStatus.
      Toaster.show({ message: 'New script failed to saved.', intent: 'danger' })
      setScript(oldScript)
      setScriptEdits(oldScript)
      return
    }
    Toaster.show({ message: 'New script saved.', intent: 'success' })
  }

  return (
    <div className={cs.s3UploadScriptTab}>
      <div className={cs.popoverContainer}>
        <HelpPopover
          text='More Info'
          helpContent={
            <div className={cs.help}>
              <div>
                This script allows users to customize which raw files are uploaded to
                S3. The user should provide one or more tuples describing what to
                upload.
              </div>
              <div className={cs.caption}>Inputs</div>
              <div>
                <b>dataset_name</b> - The name of the dataset.
              </div>
              <div>
                <b>metadata</b> - The metadata attached to the dataset. A dictionary.
              </div>
              <div>
                <b>update_metadata</b> - Update the metadata attached to the dataset.
              </div>
              <div className={cs.caption}>Outputs</div>
              <div>
                <b>s3_upload_tuples</b> - An array of tuples (local_dir, bucket, path)
                <ul>
                  <li>
                    <b>local_dir</b> - The local directory to upload.
                  </li>
                  <li>
                    <b>bucket</b> - The s3 bucket to upload to.
                  </li>
                  <li>
                    <b>path</b> - The path within the s3 bucket to upload to.
                  </li>
                </ul>
              </div>
              {example && (
                <>
                  <div className={cs.caption}>Example</div>
                  <div className={cs.codeExample}>
                    <Editor
                      value={example}
                      className={cs.editor}
                      highlight={_code => highlight(_code, languages.python)}
                      onValueChange={noop}
                    />
                  </div>
                </>
              )}
            </div>
          }
          className={cs.text}
          placement='bottom-start'
        />
      </div>
      <div className={cs.editorContainer}>
        <Editor
          value={scriptEdits}
          onValueChange={setScriptEdits}
          className={cs.editor}
          highlight={_code => highlight(_code, languages.python)}
        />
      </div>
      <div className={cs.controls}>
        <Button label='Cancel' onClick={onClose} className={cs.button} />
        <Button
          type='primary'
          label='Save Changes'
          onClick={handleScriptSave}
          className={cs.button}
        />
      </div>
    </div>
  )
}

S3UploadScriptTab.propTypes = {
  onClose: PropTypes.func,
  example: PropTypes.string,
  api: PropTypes.shape({
    getScript: PropTypes.func,
    setScript: PropTypes.func,
  }),
}

export default S3UploadScriptTab
