import { useState } from 'react'

export const WIZARD_FINAL_SUBMIT_STEP = '_finalSubmitStep'

export interface WizardData<TWizardStep extends string> {
  currentStep: TWizardStep | typeof WIZARD_FINAL_SUBMIT_STEP
  // By convention, data for each wizard step is placed in a separate field, to help
  // keep data organized for complex wizards.
  //
  // At the moment, we allow steps to read and write data from other steps.
  stepData: {
    [key in TWizardStep]: any
  }
  finalSubmitData: any
}

// This hook uses updater functions with setState, to help prevent
// race conditions if multiple updates are happening simultaneously in the wizard step
// (for example, an API call is happening while the user is also inputting data)
//
// Note that to maintain flexibility, we currently don't implement opinionated logic like:
//   - Deleting later step data when returning to a previous step.
//   - Preventing updates to step data while on a different step.
// When implementing wizards, be careful around situations where an async API call
// can unexpectedly update step data after the user has already progressed to the next step.
export default function useWizardData<
  TWizardStep extends string,
  TWizardData extends WizardData<TWizardStep>,
>(initialData: TWizardData) {
  const [wizardData, setWizardData] = useState<TWizardData>(initialData)

  const updateCurrentStep = (
    currentStep: TWizardStep | typeof WIZARD_FINAL_SUBMIT_STEP,
  ) => {
    setWizardData((_latestWizardData: TWizardData) => {
      return {
        ..._latestWizardData,
        currentStep,
      }
    })
  }

  const updateFinalSubmitData = (
    finalSubmitData: Partial<TWizardData['finalSubmitData']>,
  ) => {
    setWizardData((_latestWizardData: TWizardData) => {
      return {
        ..._latestWizardData,
        finalSubmitData: {
          ..._latestWizardData.finalSubmitData,
          ...finalSubmitData,
        },
      }
    })
  }

  const updateStepData = (
    step: TWizardStep,
    newStepData: Partial<TWizardData['stepData'][TWizardStep]>,
  ) => {
    setWizardData((_latestWizardData: TWizardData) => {
      return {
        ..._latestWizardData,
        stepData: {
          ..._latestWizardData.stepData,
          [step]: {
            ..._latestWizardData.stepData[step],
            ...newStepData,
          },
        },
      }
    })
  }

  return { wizardData, updateCurrentStep, updateFinalSubmitData, updateStepData }
}
