import cx from 'classnames'

import { Instrument, InstrumentStatus } from '~/common.interface'
import useReloadKey from '~/utils/hooks/useReloadKey'
import GenericInstrumentBody from './GenericInstrumentBody'
import GenericInstrumentPage from './GenericInstrumentPage'
import cs from './ot_2_instrument_page.scss'

interface Ot2InstrumentPageProps {
  className?: string
  instrument: Instrument
  status: InstrumentStatus
  live?: boolean
}

const Ot2InstrumentPage = ({
  className,
  status,
  live,
  instrument,
}: Ot2InstrumentPageProps) => {
  const [reloadKey, refreshReloadKey] = useReloadKey()

  const handleControlCommandComplete = commandName => {
    if (
      commandName === 'execute_protocol' ||
      commandName === 'refresh_item_cache_command'
    ) {
      refreshReloadKey()
    }
  }

  const renderBodyContents = () => {
    return (
      <GenericInstrumentBody
        instrument={instrument}
        reloadKey={reloadKey}
        views={['transfer_stations', 'list']}
        defaultView='transfer_stations'
        className={cs.bodyContents}
      />
    )
  }

  return (
    <div className={cx(className, cs.ot2InstrumentPage)}>
      <GenericInstrumentPage
        instrument={instrument}
        status={status}
        live={live}
        bodyContents={renderBodyContents()}
        className={cs.genericInstrumentPage}
        onControlCommandComplete={handleControlCommandComplete}
      />
    </div>
  )
}

export default Ot2InstrumentPage
