import cx from 'classnames'
import { size } from 'lodash/fp'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { useContext } from 'react'

import Dialog from '~/components/Dialog'
import WorkcellStatusContext from '~/pages/Workcell/WorkcellStatusContext'
import {
  getSimulatedInstrumentNames,
  getStringOnlyProcessItemsOnTransferStations,
  getWarnOnSimulatedInstruments,
} from '~/pages/Workcell/utils/workcellStatus'
import { displayCount } from '~/utils/string'

import { useHistory } from 'react-router-dom'
import HelpPopover from '~/components/HelpPopover'
import cs from './confirm_start_workcell_dialog.scss'

interface ConfirmStartWorkcellDialogProps {
  className?: string
  isOpen: boolean
  onClose(): void
  onConfirm(): void
}

const ConfirmStartWorkcellDialog = ({
  className,
  isOpen,
  onClose,
  onConfirm,
}: ConfirmStartWorkcellDialogProps) => {
  const workcellStatus = useContext(WorkcellStatusContext)
  const [delayedIsOpen, setDelayedIsOpen] = useState(false)
  const history = useHistory()

  const handleConfirm = () => {
    onConfirm()
    onClose()
  }

  useEffect(() => {
    if (isOpen) {
      const simulatedInstrumentNames = getSimulatedInstrumentNames(workcellStatus)
      const warnOnSimulatedInstruments = getWarnOnSimulatedInstruments(workcellStatus)
      const stringOnlyProcessItems =
        getStringOnlyProcessItemsOnTransferStations(workcellStatus)

      if (
        (!warnOnSimulatedInstruments || size(simulatedInstrumentNames) === 0) &&
        size(stringOnlyProcessItems) === 0
      ) {
        handleConfirm()
      } else {
        setDelayedIsOpen(true)
      }
    } else {
      setDelayedIsOpen(false)
    }
  }, [isOpen])

  const goToTransferStations = () => {
    onClose()
    history.push('/workcell/overview/transfer-stations')
  }

  const renderDialogBody = () => {
    const simulatedInstrumentNames = getSimulatedInstrumentNames(workcellStatus)
    const warnOnSimulatedInstruments = getWarnOnSimulatedInstruments(workcellStatus)
    if (warnOnSimulatedInstruments && size(simulatedInstrumentNames) > 0) {
      return (
        <div className={cx(className, cs.cancelProcessStepWarning)}>
          <div className={cs.text}>
            <span className={cs.warning}>
              <HelpPopover
                className={cs.helpPopover}
                interactionKind='hover'
                smallText={false}
                text={displayCount('instrument', size(simulatedInstrumentNames))}
                helpContent={simulatedInstrumentNames.join(', ')}
              />{' '}
              {size(simulatedInstrumentNames) > 1 ? 'are' : 'is'} currently in{' '}
              <span className={cs.simulation}>simulation mode</span>.{' '}
            </span>
            Are you sure you want to turn on the workcell?
          </div>
          <Dialog.Footer>
            <Dialog.FooterButton label='Cancel' onClick={onClose} />
            <Dialog.FooterButton
              label='Turn On Workcell'
              onClick={() => handleConfirm()}
              type='primary'
            />
          </Dialog.Footer>
        </div>
      )
    }

    const stringOnlyProcessItems =
      getStringOnlyProcessItemsOnTransferStations(workcellStatus)

    if (size(stringOnlyProcessItems) > 0) {
      return (
        <div className={cx(className, cs.cancelProcessStepWarning)}>
          <div className={cs.text}>
            <span className={cs.warning}>
              <HelpPopover
                className={cs.unknownPlates}
                interactionKind='hover'
                smallText={false}
                text={displayCount('unknown plate', size(stringOnlyProcessItems))}
                helpContent={
                  <div className={cs.unknownPlatesPopover}>
                    {stringOnlyProcessItems.map(item => (
                      <div className={cs.plate} key={item.uuid}>
                        <span className={cs.uuid}>{item.uuid}</span> (on{' '}
                        {item.transferStationName} of {item.instrumentName})
                      </div>
                    ))}
                  </div>
                }
              />{' '}
              {size(stringOnlyProcessItems) > 1 ? 'are' : 'is'} currently loaded on the
              workcell.{' '}
            </span>
            <br />
            <br />
            Please verify that these plates are expected and unload any unexpected
            plates via the{' '}
            <span className={cs.goToTransferStations} onClick={goToTransferStations}>
              Transfer Stations
            </span>{' '}
            tab.
            <br />
            <br />
            Are you sure you want to turn on the workcell?
          </div>
          <Dialog.Footer>
            <Dialog.FooterButton label='Cancel' onClick={onClose} />
            <Dialog.FooterButton
              label='Turn On Workcell'
              onClick={() => handleConfirm()}
              type='primary'
            />
          </Dialog.Footer>
        </div>
      )
    }

    return null
  }

  return (
    <Dialog
      isOpen={delayedIsOpen}
      onClose={onClose}
      className={cx(cs.confirmStartWorkcellDialog, className)}
    >
      {renderDialogBody()}
    </Dialog>
  )
}

ConfirmStartWorkcellDialog.propTypes = {
  className: PropTypes.string,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
}

export default ConfirmStartWorkcellDialog
