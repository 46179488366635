// For LoadUnloadItems,
// we currently store the item type and selected instrument in the URL Params.
// This hook handles that logic, as well as various behavior around defaults and validation.
// For example, if the URL params are invalid, the hook will try to update them to be valid.
// When the user selects an item type, the hook will select a default instrument for that item type.
// Note that we don't currently store the loadOrUnload param in the URL.
// The two params we do store were originally so that we can provide menu links from the
// InstrumentStatusTiles in LiveStatus.
import { History, Location } from 'history'
import { any, find, pickBy, size } from 'lodash/fp'
import { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { WorkcellAllowedInstrumentsForItemType } from '~/api/desktop/workcell'
import { Instrument } from '~/common.interface'
import { InstrumentDescriptorForItemType } from './InstrumentDescriptorForItemType.interface'
import { LoadUnloadItemsConfig } from './LoadUnloadItemsAction'
import { getPreferredInstrumentForProcessItemType } from './LoadUnloadItemsLocationSelection/getPreferredInstrumentForProcessItemType'
import { LoadUnloadItemsProcessItemType } from './LoadUnloadItemsProcessItemType.interface'
import { getAllowedInstruments } from './getAllowedInstruments'
import { getAllowedInstrumentsForItemType } from './getAllowedInstrumentsforItemType'
import { getAllowedProcessItemTypes } from './getAllowedProcessItemTypes'

const extractSelectedProcessItemTypeFromURLParams = (location: Location) => {
  const url = new URL(window.location.origin + location.pathname + location.search)
  return url.searchParams.get('itemType') as LoadUnloadItemsProcessItemType | null
}

const extractSelectedInstrumentNameFromURLParams = (location: Location) => {
  const url = new URL(window.location.origin + location.pathname + location.search)
  return url.searchParams.get('instrumentName')
}

const updateLocationSearch = (
  location: Location,
  params: { [key: string]: string | null | undefined },
) => {
  const url = new URL(window.location.origin + location.pathname + location.search)
  for (const key of Object.keys(params)) {
    const value = params[key]
    if (value === null || value === undefined) {
      url.searchParams.delete(key)
    } else {
      url.searchParams.set(key, value)
    }
  }

  return url.search
}

const updateHistory = (
  location: Location,
  history: History,
  params: { [key: string]: string | null | undefined },
  replace: boolean = false,
) => {
  const url = new URL(window.location.origin + location.pathname + location.search)

  // Don't update keys that haven't changed.
  const filteredParams = pickBy(
    (value, key) => url.searchParams.get(key) !== value,
    params,
  )

  if (size(filteredParams) === 0) {
    return
  }
  if (replace) {
    history.replace({
      search: updateLocationSearch(location, filteredParams),
    })
  } else {
    history.push({
      search: updateLocationSearch(location, filteredParams),
    })
  }
}

const getDefaultInstrumentForProcessItemType = (
  processItemType: LoadUnloadItemsProcessItemType,
  allowedInstruments: InstrumentDescriptorForItemType[],
  config: LoadUnloadItemsConfig,
) => {
  return (
    getPreferredInstrumentForProcessItemType(
      allowedInstruments,
      processItemType,
      config.itemTypePreferredInstrumentNames,
    ) || allowedInstruments[0].instrument
  )
}

export function useSelectedProcessItemTypeAndInstrument(
  config: LoadUnloadItemsConfig,
  allInstruments: Instrument[] | null,
  workcellAllowedInstrumentsForItemType:
    | WorkcellAllowedInstrumentsForItemType
    | undefined,
): {
  selectedProcessItemType: LoadUnloadItemsProcessItemType | null
  selectedInstrument: Instrument | null
  setSelectedProcessItemType: (itemType: LoadUnloadItemsProcessItemType) => void
  setSelectedInstrument: (instrument: Instrument | null) => void
} {
  let location = useLocation()
  let history = useHistory()
  const [selectedInstrument, _setSelectedInstrument] = useState<Instrument | null>(null)
  const [selectedProcessItemType, _setSelectedProcessItemType] =
    useState<LoadUnloadItemsProcessItemType | null>(null)

  const updateURLParams = (
    params: {
      itemType?: LoadUnloadItemsProcessItemType | null
      instrument?: Instrument | null
    },
    replace = false,
  ) => {
    let paramsToSet = {}
    if (params.itemType) {
      paramsToSet['itemType'] = params.itemType
    }
    if (params.instrument) {
      paramsToSet['instrumentName'] = params.instrument.instrumentName
    }
    updateHistory(location, history, paramsToSet, replace)
  }

  // This updates selectedProcessItemType whenever the URL changes.
  // updateURLParams is called when the itemType needs to
  // be updated further (via the URL).
  // _setSelectedProcessItemType is called when the itemType is valid
  // and can be exported from the hook via the selectedProcessItemType output parameter.
  useEffect(() => {
    const _itemType = extractSelectedProcessItemTypeFromURLParams(location)
    const allowedProcessItemTypes = getAllowedProcessItemTypes(config)

    // If the itemType is set, only adjust it if it is not valid.
    if (_itemType) {
      if (allowedProcessItemTypes.length === 0) {
        updateURLParams({ itemType: null }, true)
      } else if (!allowedProcessItemTypes.includes(_itemType)) {
        updateURLParams({ itemType: allowedProcessItemTypes[0] }, true)
      } else {
        _setSelectedProcessItemType(_itemType)
      }
    } else {
      // If the itemType is not set,  select a default if possible.
      if (allowedProcessItemTypes.length > 0) {
        updateURLParams({ itemType: allowedProcessItemTypes[0] }, true)
      } else {
        _setSelectedProcessItemType(null)
      }
    }
  }, [config, location])

  // This follows a similar pattern to selectedProcessItemType above.
  useEffect(() => {
    const _itemType = extractSelectedProcessItemTypeFromURLParams(location)
    if (!allInstruments || !workcellAllowedInstrumentsForItemType || !_itemType) return

    const _instrumentName = extractSelectedInstrumentNameFromURLParams(location)
    const _instrument =
      find(['instrumentName', _instrumentName], allInstruments) || null
    const allowedInstruments = getAllowedInstruments(
      allInstruments,
      getAllowedInstrumentsForItemType(
        workcellAllowedInstrumentsForItemType,
        _itemType,
      ),
    )
    const defaultInstrument = getDefaultInstrumentForProcessItemType(
      _itemType,
      allowedInstruments,
      config,
    )

    // If the instrument is set, only adjust it if it is not valid.
    if (_instrument) {
      if (allowedInstruments.length === 0) {
        updateURLParams({ instrument: null }, true)
      } else if (
        !any(
          ['instrument.instrumentName', _instrument.instrumentName],
          allowedInstruments,
        )
      ) {
        updateURLParams(
          {
            instrument: defaultInstrument,
          },
          true,
        )
      } else {
        _setSelectedInstrument(_instrument)
      }
    } else {
      // If the instrument is not set,  select a default if possible.
      if (allowedInstruments.length > 0) {
        updateURLParams(
          {
            instrument: defaultInstrument,
          },
          true,
        )
      } else {
        _setSelectedInstrument(null)
      }
    }
  }, [allInstruments, workcellAllowedInstrumentsForItemType, location])

  const setSelectedProcessItemType = (itemType: LoadUnloadItemsProcessItemType) => {
    // When the user selects a new process item type, also update the selected instrument
    // to the default for that process item type.
    if (allInstruments) {
      const allowedInstruments = getAllowedInstruments(
        allInstruments,
        getAllowedInstrumentsForItemType(
          workcellAllowedInstrumentsForItemType,
          itemType,
        ),
      )

      const defaultInstrument = getDefaultInstrumentForProcessItemType(
        itemType,
        allowedInstruments,
        config,
      )
      // On user selection, push to history.
      updateURLParams(
        {
          itemType,
          instrument: defaultInstrument,
        },
        false,
      )
    } else {
      // On user selection, push to history.
      updateURLParams(
        {
          itemType,
          instrument: null,
        },
        false,
      )
    }
  }

  const setSelectedInstrument = (instrument: Instrument | null) => {
    // On user selection, push to history.
    updateURLParams(
      {
        instrument,
      },
      false,
    )
  }

  return {
    selectedProcessItemType,
    selectedInstrument,
    setSelectedProcessItemType,
    setSelectedInstrument,
  }
}
