// Placeholder dialog. Under development, guarded behind enable_rotate_shelf_to_door and a debug flag.

import cx from 'classnames'

import HelpPopover from '~/components/HelpPopover'
import LoadingMessage from '~/components/LoadingMessage'
import Toaster from '~/components/Toaster'
import MinimalButton from '~/components/buttons/MinimalButton'
import RotateIcon from '~/components/icons/RotateIcon'
import useInstrumentControlCommandExecutor from '~/utils/hooks/useInstrumentControlCommandExecutor'
import cs from './liconic_rotate_shelf_to_door_button.scss'

export interface LiconicRotateShelfToDoorButtonProps {
  className?: string
  instrumentName: string
  shelf: number
}

const LiconicRotateShelfToDoorButton = ({
  className,
  shelf,
  instrumentName,
}: LiconicRotateShelfToDoorButtonProps) => {
  const onCommandSuccess = () => {
    Toaster.show({
      message: `Rotate shelf ${shelf} to door succeeded`,
      intent: 'success',
    })
  }

  const onCommandError = (error: string) => {
    Toaster.show({
      message: `Rotate shelf ${shelf} to door failed.${error ? ' ' + error : ''}`,
      intent: 'danger',
    })
  }

  const { instrumentIsReady, currentCommandExecuted, executeCommand } =
    useInstrumentControlCommandExecutor(
      instrumentName,
      onCommandSuccess,
      onCommandError,
    )

  const executeDrawerOpen = async () => {
    executeCommand('rotate_shelf_to_door', [shelf])
  }

  const button = (
    <MinimalButton
      label={`Rotate Shelf${shelf !== null ? ' ' + shelf : ''} to Door`}
      IconComponent={RotateIcon}
      onClick={executeDrawerOpen}
      className={cx(className, cs.rotateShelfButton)}
      type='primary'
      variant='tiny'
      disabled={!instrumentIsReady}
    />
  )

  if (currentCommandExecuted === 'rotate_shelf_to_door') {
    return (
      <LoadingMessage
        className={className}
        label={`Rotating shelf ${shelf} to door...`}
      />
    )
  } else if (!instrumentIsReady) {
    return (
      <HelpPopover
        interactionKind='hover'
        text={button}
        helpContent='Instrument is not ready'
      />
    )
  }

  return button
}

export default LiconicRotateShelfToDoorButton
