import {
  CulturePlateGraphQl,
  PlateImagingObservationGraphQl,
  SubImageSizeGraphQl,
} from '~/__generated__/graphql'

import { DemoObjectConverter } from '../demoData'
import { getDemoPlateSprite } from '../imageSets'
import { DemoSourceTimepoint, plateObservationKey } from './demoTimepoint'
import { DemoSourceWell, demoWellKey } from './demoWell'

export type DemoSourcePlate = {
  barcode: string
  format: string
}

type PartialPlate = Omit<
  CulturePlateGraphQl,
  'plateObservationHistory' | 'wellCultures'
> & {
  relevantTimepoints: DemoSourceTimepoint[]
  wellCultures: DemoSourceWell[]
}

export const plateConverter: DemoObjectConverter<
  DemoSourcePlate,
  PartialPlate,
  CulturePlateGraphQl
> = {
  tsvColumns: ['barcode', 'format'],

  validate(plates) {
    if (plates.length !== new Set(plates.map(p => p.barcode)).size) {
      throw new Error('There are duplicate plate barcodes.')
    }
    if (plates.some(p => !['96', '24', '12', '6'].includes(p.format))) {
      throw new Error('Unsupported plate format')
    }
  },

  convert(sourcePlate, others) {
    const wells =
      others.wells?.filter(w => w.plateBarcode === sourcePlate.barcode) ?? []

    const defaults = {
      id: encodeURIComponent(btoa(sourcePlate.barcode)),
      checkedInAt: '2024-11-05T17:05:00.000Z',
      firstCheckedInAt: '2024-11-05T17:05:00.000Z',
      checkedOutAt: '0001-01-01T00:00:00.000Z',
      isCheckedIn: true,
      plateDimensions: plateFormatToDims(sourcePlate.format),
      wellCultures: wells,
    }

    return {
      partialItem: {
        __typename: 'CulturePlateGraphQL',
        ...defaults,
        ...sourcePlate,
        relevantTimepoints:
          others.timepoints?.filter(t => t.plateBarcode === sourcePlate.barcode) ?? [],
      },
      lookupKey: sourcePlate.barcode,
    }
  },

  link(derived, partial, lookup) {
    derived.wellCultures = partial.wellCultures
      .map(w => lookup('wells', demoWellKey(w)))
      .filter(w => w != null)

    const activeWells = new Set(derived.wellCultures.map(w => w.well))
    const plateObservations: { [timestamp: string]: PlateImagingObservationGraphQl } =
      {}
    for (const timepoint of partial.relevantTimepoints) {
      const ts = timepoint.timestamp
      if (!plateObservations[ts]) {
        const { url, wellSequence, montageLayout } = getDemoPlateSprite(
          timepoint.plateImageSet,
          SubImageSizeGraphQl.Size_800X800,
        )
        plateObservations[ts] = {
          id: plateObservationKey(timepoint),
          timestamp: ts,
          confluences: [],
          sprite: {
            id: url,
            datasetId: plateObservationKey(timepoint),
            imageUrl: url,
            montageLayout,
            // Normally we'd show images for all available wells. But, to allow
            // people to customize the demo without re-generating plate sprites
            // every time, we mask the wellSequence entries for wells that are
            // not specified in the demo. This prevents image UIs from
            // displaying inactive wells, while preserving the correctness of
            // the active well positions in the sprite.
            wellSequence: wellSequence.map(w =>
              activeWells.has(w) ? w : `DEMO_INACTIVE_${w}`,
            ),
          },
        }
      }
      plateObservations[ts].confluences.push({
        well: timepoint.wellPosition,
        value: parseFloat(timepoint.confluence),
      })
    }
    derived.plateObservationHistory = Object.values(plateObservations)
  },
}

function plateFormatToDims(format?: string): { rows: number; columns: number } {
  switch (format) {
    case '':
    case undefined:
    case '96':
      return { rows: 8, columns: 12 }
    case '24':
      return { rows: 4, columns: 6 }
    case '12':
      return { rows: 3, columns: 4 }
    case '6':
      return { rows: 2, columns: 3 }
    default:
      throw new Error(`Unsupported plate format "${format}"`)
  }
}
