import cx from 'classnames'
import {} from 'lodash/fp'
import { LinkLotsParseDataResponse } from '~/api/operatorActions/linkCellLineLotsToCultures'
import { displayCount } from '~/utils/string'
import GroupedErrorTable from '../../GroupedErrorTable'
import LinkLotsPlatePreview, {} from './LinkLotsPlatePreview'
import { getLinkLotsParseDataErrorGroups } from './getLinkLotsParseDataErrorGroups'
import {
  getLinkLotsPreviewPlates,
  getNumCultures,
  getNumDistinctCellLineLots,
  getNumDistinctCellLines,
  getNumPlates,
} from './parseDataResponseExtractors'
import cs from './upload_metadata_step_preview.scss'

export interface UploadDataStepPreviewProps {
  className?: string
  loading: boolean
  parseDataResponse: LinkLotsParseDataResponse | null
}

const UploadDataStepPreview = ({
  className,
  loading,
  parseDataResponse,
}: UploadDataStepPreviewProps) => {
  if (!loading && parseDataResponse === null) {
    return null
  }

  const renderContents = () => {
    if (loading) {
      return <div className={cs.info}>Loading preview...</div>
    }

    if (parseDataResponse === null) {
      return null
    }

    if (parseDataResponse.type === 'errors') {
      return (
        <>
          <div className={cs.errorCount}>
            {displayCount('error', parseDataResponse.errors.length)} with pasted data
          </div>
          <GroupedErrorTable
            className={cs.parseDataErrorTable}
            errorGroups={getLinkLotsParseDataErrorGroups(parseDataResponse)}
          />
        </>
      )
    }

    if (
      parseDataResponse.type === 'cell_line_lots' ||
      parseDataResponse.type === 'cell_lines'
    ) {
      const linkedValueCount =
        parseDataResponse.type === 'cell_line_lots'
          ? displayCount('cell line lot', getNumDistinctCellLineLots(parseDataResponse))
          : displayCount('cell line', getNumDistinctCellLines(parseDataResponse))

      return (
        <>
          <div className={cs.info}>
            Linking {linkedValueCount} to{' '}
            {displayCount('culture', getNumCultures(parseDataResponse))} (
            {displayCount('plate', getNumPlates(parseDataResponse))})
          </div>
          <LinkLotsPlatePreview
            className={cs.platePreview}
            uploadType={parseDataResponse.type}
            plates={getLinkLotsPreviewPlates(parseDataResponse)}
          />
        </>
      )
    }

    return null
  }

  return (
    <div className={cx(className, cs.uploadMetadataStepPreview)}>
      <div className={cs.sectionTitle}>Preview</div>
      <div className={cs.contents}>{renderContents()}</div>
    </div>
  )
}

export default UploadDataStepPreview
