import TinyMicroplate from '~/components/TinyMicroplate'
import { getConfluencyColor } from '~/pages/slasDemo/CommandCenter/LiveCultures/LiveCultures'
import { convertWellCoordsToWellName } from '~/utils/microplate'
import { CleCulturePopover } from '../clePlates/CleCulturePopover'
import { ClePlate } from '../clePlates/ClePlate.interface'
import { getDayToSimulateForPlate } from '../data/hitpickingData'

const ClePlateViz = ({
  plate,
  onClickCell,
  useLocalAssets,
}: {
  plate: ClePlate
  onClickCell?: (() => void) | undefined
  useLocalAssets: boolean
}) => {
  return (
    <TinyMicroplate
      plateFormat={plate.plateFormat}
      size='medium'
      highlights={[
        {
          colorRgbFn: (row, col) => {
            const well = convertWellCoordsToWellName(row, col)
            const confluency = plate.wells[well].confluency
            return getConfluencyColor(confluency)
          },
        },
      ]}
      onClickCell={onClickCell}
      getPopoverContent={(row, col) => {
        const wellName = convertWellCoordsToWellName(row, col)
        const confluency = plate.wells[wellName].confluency
        return (
          <CleCulturePopover
            clePlate={plate}
            wellName={wellName}
            dayToSimulate={getDayToSimulateForPlate(
              plate.phase,
              plate.cellLine.cellLineIndex,
            )}
            actualConfluency={confluency}
            useLocalAssets={useLocalAssets}
          />
        )
      }}
    />
  )
}

export default ClePlateViz
