import PropTypes from 'prop-types'
import { IconProps } from './types/IconProps'
const UpArrowIcon = ({ className, onClick }: IconProps) => (
  <svg
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    onClick={onClick}
    className={className}
  >
    <path d='M12 8.29501L6 14.295L7.41 15.705L12 11.125L16.59 15.705L18 14.295L12 8.29501Z' />
  </svg>
)

UpArrowIcon.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
}

export default UpArrowIcon
