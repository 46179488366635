import cx from 'classnames'
import { useContext, useEffect, useState } from 'react'

import { get } from 'lodash/fp'
import commonDriverAPI from '~/api/desktop/commonDriver'
import Dialog from '~/components/Dialog'
import LoadingMessage from '~/components/LoadingMessage'
import Toaster from '~/components/Toaster'
import WorkcellStatusContext from '~/pages/Workcell/WorkcellStatusContext'
import {
  getInstrumentRealTimeState,
  getInstrumentStatus,
} from '~/pages/Workcell/utils/workcellStatus'
import { getInstrumentErrorMessage } from '~/utils/instrument'
import cs from './inheco_scila_open_drawer_dialog.scss'

export interface InhecoScilaOpenDrawerDialogProps {
  className?: string
  isOpen: boolean
  onCancel: () => void
  onConfirm: () => void
  selectedDrawer: number | null
  instrumentName: string
}

const InhecoScilaOpenDrawerDialog = ({
  className,
  isOpen,
  onCancel,
  onConfirm,
  selectedDrawer,
  instrumentName,
}: InhecoScilaOpenDrawerDialogProps) => {
  const workcellStatus = useContext(WorkcellStatusContext)
  const realTimeState = getInstrumentRealTimeState(workcellStatus, instrumentName)
  const [waitingForOpen, setWaitingForOpen] = useState(false)

  const isSelectedDrawerOpened = () => {
    if (selectedDrawer) {
      return get(['door_state', selectedDrawer - 1], realTimeState) === 'Opened'
    }
    return false
  }

  useEffect(() => {
    if (isSelectedDrawerOpened() && isOpen) {
      if (selectedDrawer) {
        onConfirm()
      } else {
        onCancel()
      }
    } else if (isOpen && waitingForOpen) {
      const instrumentStatus = getInstrumentStatus(workcellStatus, instrumentName)
      const executionState = get('execution_state', instrumentStatus)

      if (executionState === 'FAULTED') {
        onCancel()
        Toaster.show({
          message: `Could not open door. ${getInstrumentErrorMessage(
            instrumentStatus,
          )}`,
          intent: 'danger',
        })
      }
    }
  }, [realTimeState])

  useEffect(() => {
    if (isOpen) {
      setWaitingForOpen(false)

      if (isSelectedDrawerOpened()) {
        onConfirm()
      }
    }
  }, [isOpen])

  const executeDrawerOpen = async () => {
    setWaitingForOpen(true)
    await commonDriverAPI.executeControlCommand(instrumentName, 'open_door', [
      selectedDrawer,
    ])
  }

  return (
    <Dialog
      isOpen={isOpen}
      onClose={onCancel}
      className={cx(cs.inhecoScilaOpenDrawerDialog, className)}
    >
      <div className={cs.text}>
        Drawer {selectedDrawer} on {instrumentName} is currently closed and needs to be
        opened for check-in.
      </div>
      <Dialog.Footer>
        <Dialog.FooterButton label='Select Another Drawer' onClick={onCancel} />
        {waitingForOpen ? (
          <LoadingMessage
            className={cs.openDrawerMessage}
            label={`Opening drawer ${selectedDrawer}...`}
          />
        ) : (
          <Dialog.FooterButton
            label={`Open Drawer ${selectedDrawer}`}
            onClick={executeDrawerOpen}
            type='primary'
          />
        )}
      </Dialog.Footer>
    </Dialog>
  )
}

export default InhecoScilaOpenDrawerDialog
