import { client, unwrap } from '~/api/desktopAPIv2'
import { DefaultSuccessResponse } from './serviceConfig'

export default {
  updateJob: (
    id: string,
    fields: Record<string, never>,
  ): Promise<DefaultSuccessResponse> =>
    unwrap(
      client().POST('/api/v2/jobs/{id}/update', {
        params: {
          path: { id },
        },
        body: {
          new_fields: fields,
        },
      }),
    ),
}
