import MicroplateIcon from '~/components/icons/MicroplateIcon'
import LoadUnloadItemsEmptyAssayPlateForm, {
  getEmptyAssayPlateLoadParamsRequestOrError,
} from '~/pages/Workcell/OperatorActions/LoadUnloadItems/LoadUnloadItemsProcessItemForm/LoadUnloadItemsEmptyAssayPlateForm'
import { ProcessItemTypeConfig } from './ProcessItemTypeConfig.interface'

export const LOAD_UNLOAD_ITEMS_EMPTY_ASSAY_PLATE_CONFIG: ProcessItemTypeConfig<'empty_assay_plate'> =
  {
    getLoadParamsRequestOrError: getEmptyAssayPlateLoadParamsRequestOrError,
    formComponent: LoadUnloadItemsEmptyAssayPlateForm,
    iconComponent: MicroplateIcon,
  }
