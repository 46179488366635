import cx from 'classnames'
import React from 'react'

import PopupMenu from '~/components/PopupMenu'

import { compact, size } from 'lodash'
import { Link } from 'react-router-dom'
import Notification from '~/components/notifications/Notification'
import {
  generate100PlateSeededEvents,
  generatePlateSeededEvents,
} from '~/pages/slasDemo/events/data/generate/generatePlateSeededEvents'
import { getLiveCulturesWithIssues } from '../../events/ViewModels/CultureViewModel'
import { ViewModels } from '../../events/ViewModels/ViewModels'
import { generateEventTime } from '../../events/data/eventTimes'
import { fastForwardOneDay } from '../../events/data/fastForwardOneDay'
import { generatePlateCheckedInEvents } from '../../events/data/generate/generatePlateCheckedInEvents'
import { useCommandCenterEventContext } from '../CommandCenterEventsContext'
import SlasDemoConfigDialog from '../SlasDemoConfigDialog'
import { getProfileSettings } from '../SlasDemoConfigDialog/DemoConfig'
import cs from './live_cultures_header.scss'

export const LiveCulturesHeader = ({
  viewModels,
  useLocalAssets,
}: {
  viewModels: ViewModels
  useLocalAssets: boolean
}) => {
  const { eventState, publishEvents, resetEventsTo, config } =
    useCommandCenterEventContext()
  const [configDialogOpen, setConfigDialogOpen] = React.useState(false)

  const numCulturesWithIssues = getLiveCulturesWithIssues(
    viewModels.today,
    viewModels.cultures,
  ).length

  const handleCheckInCulturePlate = async () => {
    const checkInTime = generateEventTime({
      eventDay: viewModels.today,
      eventKind: 'PlateCheckedIn',
    })
    const numExistingPlates = size(viewModels.plates)
    await publishEvents(
      generatePlateCheckedInEvents(
        1,
        viewModels.today,
        checkInTime,
        numExistingPlates,
        config,
      ),
    )
  }

  const handleResetTo1CulturePlate6Well = async () => {
    const checkInTime = generateEventTime({
      eventDay: 0,
      eventKind: 'PlateSeeded',
    })
    await resetEventsTo(generatePlateSeededEvents(1, 0, checkInTime, config))
  }

  const handleResetTo10CulturePlates6Well = async () => {
    const checkInTime = generateEventTime({
      eventDay: 0,
      eventKind: 'PlateSeeded',
    })
    await resetEventsTo(generatePlateSeededEvents(10, 0, checkInTime, config))
  }

  const handleResetTo100CulturePlates = async () => {
    const checkInTime = generateEventTime({
      eventDay: 0,
      eventKind: 'PlateSeeded',
    })
    await resetEventsTo(generate100PlateSeededEvents(0, checkInTime, config))
  }

  const handleResetTo5CulturePlates96Well = async () => {
    const checkInTime = generateEventTime({
      eventDay: 0,
      eventKind: 'PlateSeeded',
    })
    await resetEventsTo(
      generatePlateSeededEvents(5, 0, checkInTime, config, 'wells_96'),
    )
  }

  return (
    <div className={cx(cs.header)}>
      <div className={cs.titleContainer}>
        <span className={cs.title}>Live Cultures</span>
        <PopupMenu
          options={compact([
            {
              label: 'Check In Culture Plate (6W)',
              action: () => {
                handleCheckInCulturePlate()
              },
            },
            {
              label: 'Fast-forward 1 Day',
              action: () => {
                publishEvents(
                  fastForwardOneDay(eventState, viewModels, config, useLocalAssets),
                )
              },
            },
            {
              label: 'Reset to 1 Culture Plate',
              action: () => {
                handleResetTo1CulturePlate6Well()
              },
            },
            {
              label: 'Reset to 10 Culture Plates',
              action: () => {
                handleResetTo10CulturePlates6Well()
              },
            },
            {
              label: 'Reset to 100 Culture Plates',
              action: () => {
                handleResetTo100CulturePlates()
              },
            },
            getProfileSettings(config).allow96WellPlates && {
              label: 'Reset to 5 Culture Plates (96W)',
              action: () => {
                handleResetTo5CulturePlates96Well()
              },
            },
            {
              label: 'Clear All',
              action: () => {
                resetEventsTo([])
              },
            },
            {
              label: 'Configure...',
              action: () => {
                setConfigDialogOpen(true)
              },
            },
          ])}
          className={cs.menu}
        />
      </div>
      <div className={cs.fill} />
      {numCulturesWithIssues > 0 ? (
        <CulturesNeedAttention numCultures={numCulturesWithIssues} />
      ) : null}
      <SlasDemoConfigDialog
        isOpen={configDialogOpen}
        onClose={() => setConfigDialogOpen(false)}
      />
    </div>
  )
}

function CulturesNeedAttention({ numCultures }: { numCultures: number }) {
  return (
    <Notification
      className={cs.culturesNeedAttention}
      label={
        <div className={cs.cultureNeedAttentionInner}>
          <div className={cs.culturesNeedAttentionTitle}>
            {numCultures} cultures need attention.
          </div>
          <div>
            <Link
              to='/command-center/needs-attention'
              className={cs.culturesNeedAttentionLink}
            >
              View Issues
            </Link>
          </div>
        </div>
      }
      type='error'
      variant='mini'
    />
  )
}
