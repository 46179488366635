import cx from 'classnames'

import ProcessItemList from '~/pages/Workcell/components/processItem/ProcessItemList'
import cs from './samples.scss'

interface SampleProps {
  className?: string
  searchValue: string
  showItemsWithoutLocation: boolean
}

const Samples = ({ className, searchValue, showItemsWithoutLocation }: SampleProps) => {
  return (
    <div className={cx(className, cs.samples)}>
      <ProcessItemList
        className={cs.table}
        consumablesOrSamples='samples'
        searchValue={searchValue}
        showItemsWithoutLocation={showItemsWithoutLocation}
        isCheckedIn
        canEdit
        showWorkflowsCompletedWarning
      />
    </div>
  )
}

export default Samples
