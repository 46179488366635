import cx from 'classnames'
import { find, includes, map, sortBy } from 'lodash/fp'
import PropTypes from 'prop-types'
import { useContext, useEffect, useState } from 'react'

import desktopAPI from '~/api/desktop'
import workcellAPI from '~/api/desktop/workcell'
import Dialog from '~/components/Dialog'
import Input from '~/components/Input'
import Select from '~/components/Select'
import Toaster from '~/components/Toaster'
import WorkcellStatusContext from '~/pages/Workcell/WorkcellStatusContext'
import { areStepsScheduled } from '~/pages/Workcell/utils/workcellStatus'

import cs from './workcell_profile_dialog_tab.scss'

interface WorkcellProfile {
  profileName: string
}

interface ProfileOption {
  label: string
  value: string
}

export const getProfileOptions = (profiles: WorkcellProfile[]) => [
  ...sortBy(
    'value',
    profiles.map(profile => ({
      label: profile.profileName,
      value: profile.profileName,
    })),
  ),
  { label: 'Create New...', value: 'create_new' },
]

const WorkcellProfileDialogTab = ({
  className,
  onClose,
}: {
  className?: string
  onClose(): void
}) => {
  const workcellStatus = useContext(WorkcellStatusContext)
  const [profileOptions, setProfileOptions] = useState<ProfileOption[]>([])
  const [newProfileName, setNewProfileName] = useState('')
  const [currentProfile, setCurrentProfile] = useState<ProfileOption | null>(null)

  const fetchProfiles = async () => {
    const [_profiles, profileName] = await Promise.all([
      desktopAPI.getWorkcellProfiles(),
      workcellAPI.getProfileName(),
    ])

    const _profileOptions = getProfileOptions(_profiles)

    setProfileOptions(_profileOptions)

    const _currentProfile = find(['value', profileName], _profileOptions) || null
    setCurrentProfile(_currentProfile)
  }

  const isCreatingNewProfile = () => {
    return currentProfile && currentProfile.value === 'create_new'
  }

  const handleProfileSave = async () => {
    onClose()
    const getSelectedProfileName = () => {
      if (isCreatingNewProfile()) {
        return newProfileName
      }
      if (!currentProfile) {
        return null
      }
      return currentProfile.value
    }

    const selectedProfileName = getSelectedProfileName()

    if (!selectedProfileName) {
      return
    }

    try {
      await workcellAPI.setProfileName(selectedProfileName)
      Toaster.show({
        message: `Updated profile to ${selectedProfileName}`,
        intent: 'success',
      })
    } catch {
      Toaster.show({
        message: `Could not update profile to ${selectedProfileName}`,
        intent: 'danger',
      })
    }
  }

  useEffect(() => {
    fetchProfiles()
  }, [])

  const getProfileNameWarning = () => {
    if (
      isCreatingNewProfile() &&
      includes(newProfileName, map('label', profileOptions))
    ) {
      return 'Profile name is already in use.'
    }
    return null
  }

  const getError = () => {
    if (areStepsScheduled(workcellStatus)) {
      // We use intentionally "routines" for the user error message instead of "process steps".
      return 'Cannot update profile while routines are scheduled.'
    }
    return null
  }
  return (
    <div className={cx(className, cs.workcellProfileTab)}>
      <Select<ProfileOption>
        itemKey='value'
        label='Profile'
        triggerClassName={cs.selectTrigger}
        popoverClassName={cs.selectPopover}
        className={cs.select}
        items={profileOptions}
        activeItem={currentProfile}
        onChange={setCurrentProfile}
        placeholder='Select profile name'
      />
      {isCreatingNewProfile() && (
        <Input
          label='New Profile Name'
          value={newProfileName}
          onChange={setNewProfileName}
          className={cs.inputContainer}
          inputClassName={cs.input}
          errorMessage={getProfileNameWarning() || undefined}
        />
      )}
      <Dialog.Footer error={getError()}>
        <Dialog.FooterButton label='Cancel' onClick={onClose} />
        <Dialog.FooterButton
          label='Update Profile'
          type='primary'
          onClick={handleProfileSave}
          disabled={!!getProfileNameWarning()}
        />
      </Dialog.Footer>
    </div>
  )
}

WorkcellProfileDialogTab.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
}

export default WorkcellProfileDialogTab
