import { PastObservationGraphQl } from '~/__generated__/graphql'
import { DemoObjectConverter, LinkingError } from '../demoData'
import imageSets from '../imageSets'
import { demoWellKey } from './demoWell'

export type DemoSourceTimepoint = {
  plateBarcode: string
  wellPosition: string
  timestamp: string
  plateImageSet: string
  confluence: string
}

type DemoPartialTimepoint = PastObservationGraphQl & {
  plateBarcode: string
  wellPosition: string
}

export const timepointConverter: DemoObjectConverter<
  DemoSourceTimepoint,
  DemoPartialTimepoint,
  PastObservationGraphQl
> = {
  tsvColumns: [
    'plateBarcode',
    'wellPosition',
    'timestamp',
    'plateImageSet',
    'confluence',
  ],

  validate(timepoints) {
    if (timepoints.length !== new Set(timepoints.map(t => demoTimepointKey(t))).size) {
      throw new Error(
        'There are multiple timepoints with the same plate, well, and timestamp.',
      )
    }
    if (timepoints.some(t => !t.plateBarcode || !t.wellPosition || !t.timestamp)) {
      throw new Error(
        'Some timepoints are missing a plate barcode, well position, or timestamp',
      )
    }
    if (timepoints.some(t => t.wellPosition !== t.wellPosition.toUpperCase())) {
      throw new Error('Well positions must be uppercase')
    }
    if (timepoints.some(t => Number.isNaN(parseFloat(t.confluence)))) {
      throw new Error('Invalid confluence number')
    }
    const availablePlateImageSets = new Set(
      imageSets.flatMap(is => is.plateObservations?.map(obs => obs.spriteDirectory)),
    )
    if (
      timepoints.some(
        t => t.plateImageSet && !availablePlateImageSets.has(t.plateImageSet),
      )
    ) {
      throw new Error('Some timepoints have an invalid plate image set ID.')
    }
  },

  convert(sourceTimepoint, others) {
    return {
      partialItem: {
        ...sourceTimepoint,
        __typename: 'PastObservationGraphQL',
        timestamp: sourceTimepoint.timestamp,
        confluence: parseFloat(sourceTimepoint.confluence),
        source: {
          __typename: 'DatasetSourceGraphQL',
          datasetId: plateObservationKey(sourceTimepoint),
        },
      },
      lookupKey: demoTimepointKey(sourceTimepoint),
    }
  },

  link(derived, partial, lookup) {
    const wellKey = demoWellKey({
      plateBarcode: partial.plateBarcode,
      position: partial.wellPosition,
    })
    if (lookup('wells', wellKey) == null) {
      throw new LinkingError(
        `There is a timepoint for "${partial.plateBarcode}" well ${partial.wellPosition}, but there is no corresponding well in the list of wells.`,
      )
    }
  },
}

export function demoTimepointKey({
  plateBarcode,
  wellPosition,
  timestamp,
}: { plateBarcode: string; wellPosition: string; timestamp: string }): string {
  return plateBarcode + '|' + wellPosition + '|' + timestamp
}

export function plateObservationKey({
  plateBarcode,
  timestamp,
}: { plateBarcode: string; timestamp: string }): string {
  return plateBarcode + '|' + timestamp
}
