// For now, just allow user to edit the daily time at which the job runs.
// This is by far the most common use case.

import { get, set } from 'lodash/fp'
import memoize from 'memoize-one'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'

import desktopAPI from '~/api/desktop'
import jobsAPI from '~/api/desktop/jobs'
import { getDesktopDomain } from '~/api/desktop/utils'
import Dialog from '~/components/Dialog'
import InputDatetime from '~/components/InputDatetime'
import Link from '~/components/Link'
import Toaster from '~/components/Toaster'
import Button from '~/components/buttons/Button'
import EditIcon from '~/components/icons/EditIcon'

import cs from './edit_job_dialog.scss'
import { parseCronString, serializeCronString } from './utils'

const getEditJobLink = id => `${getDesktopDomain()}/api/jobs/${id}`

// We only want to change the isostring when the cronString changes, which is why
// we memoize.
// The isoformat datetime is just a vehicle to carry the hours and minutes
// into InputDatetime here.
const _parseCronString = memoize(parseCronString)

const EditJobDialog = ({ isOpen, onClose, jobId, onEditSuccess }) => {
  const [job, setJob] = useState(null)

  const fetchJob = async () => {
    setJob(null)
    const _job = await desktopAPI.getJob(jobId)
    setJob(_job)
  }

  const handleScheduleChange = value => {
    const cronString = serializeCronString(value)
    setJob(set('cronString', cronString, job))
  }

  useEffect(async () => {
    if (jobId) {
      fetchJob()
    }
  }, [jobId])

  const submit = async () => {
    onClose()
    try {
      await jobsAPI.updateJob(job.id, {
        cron_string: job.cronString,
      })
      Toaster.show({
        message: 'Successfully modified job',
        intent: 'success',
      })
    } catch {
      Toaster.show({
        message: 'Failed to modify job',
        intent: 'success',
      })
    }
    onEditSuccess()
  }

  const renderContents = () => {
    if (!job) {
      return null
    }

    return (
      <>
        <div className={cs.title}>{job.name}</div>
        <div className={cs.container}>
          <InputDatetime
            label='Edit Daily Run Time'
            value={_parseCronString(get('cronString', job))}
            onChange={handleScheduleChange}
            inputClassName={cs.input}
            parseFormats={['hA', 'ha', 'h:mmA', 'h:mma', 'h:mm A', 'h:mm a']}
            parseStrict
            displayFormat='h:mm A'
          />
          <Link to={getEditJobLink(job.id)} external className={cs.advancedOptionsLink}>
            Advanced options...
          </Link>
        </div>
        <div className={cs.footer}>
          <Button label='Cancel' onClick={onClose} className={cs.button} />
          <Button
            className={cs.button}
            label='Save Edits'
            IconComponent={EditIcon}
            type='primary'
            onClick={submit}
          />
        </div>
      </>
    )
  }

  return (
    <Dialog isOpen={isOpen} onClose={onClose} className={cs.editJobDialog}>
      {renderContents()}
    </Dialog>
  )
}

EditJobDialog.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  jobId: PropTypes.number,
  onEditSuccess: PropTypes.func,
}

export default EditJobDialog
