/**
* Returns a debounced API endpoint function,
*
* The debounced function will only call the actual API after
* the debounce time has elapsed since the last debounced function call.
*
* Stale responses are ignored.
* Responses returned after the component using this hook
* has been unmounted are also ignored.

* NOTE: We currently don't support updating the values of apiEndpoint or debounceTime
* once the hook has been created.
* NOTE: In the future, we could use AbortController to cancel previous stale API calls.
* We didn't do this initially because it would have required refactoring the API module.
 */

import { debounce, uniqueId } from 'lodash'
import { useCallback, useRef, useState } from 'react'
import { ApiV2HttpError } from '~/api/desktopAPIv2'
import { useIsMounted } from './useIsMounted'

// This is returned on a 4XX or 5XX error.
export interface DebouncedAPIHttpError {
  type: 'error'
  message: string
}

// This is the API-specific response, which can still contain errors within it.
export interface DebouncedAPIResponse<T> {
  type: 'response'
  response: T
}

export interface UseDebouncedAPIEndpointResponse<TRequestData, TResponse> {
  // A null request will always return a null response.
  // If a null request/response is possible (for example, if some input data is empty)
  // it should be passed through the debounced API, so
  // the null response doesn't get overwritten by stale responses.
  debouncedAPIEndpoint: (data: TRequestData | null) => Promise<void>
  response: DebouncedAPIHttpError | DebouncedAPIResponse<TResponse> | null
  // Set to true immediately after debouncedAPIEndpoint is called.
  // Set to false after all responses are received.
  loading: boolean
}

export default function useDebouncedAPIEndpoint<TRequestData, TResponse>(
  apiEndpoint: (data: TRequestData) => Promise<TResponse | null>,
  debounceTimeInMs: number,
  // This can be helpful to prevent a flicker in the UI in certain situations.
  initialLoadingValueOverride: boolean = false,
): UseDebouncedAPIEndpointResponse<TRequestData, TResponse> {
  const isMounted = useIsMounted()
  const [loading, setLoading] = useState(initialLoadingValueOverride)
  const [response, setResponse] = useState<
    DebouncedAPIHttpError | DebouncedAPIResponse<TResponse> | null
  >(null)
  const isFirstRender = useRef<boolean>(true)
  const lastCallUniqueId = useRef<string | null>()

  const _debouncedAPIEndpoint = useCallback(
    debounce(async (data: TRequestData | null) => {
      let response: TResponse | null = null
      const callUniqueId = uniqueId()
      lastCallUniqueId.current = callUniqueId
      try {
        if (data === null) {
          response = null
        } else {
          response = await apiEndpoint(data)
        }
      } catch (e) {
        const error = e as ApiV2HttpError
        setResponse({
          type: 'error',
          message: error.message,
        })
        setLoading(false)
        return
      }

      if (!isMounted()) {
        return
      }

      // Ignore stale responses.
      if (lastCallUniqueId.current !== callUniqueId) {
        return
      }

      if (response === null) {
        setResponse(null)
      } else {
        setResponse({
          type: 'response',
          response: response,
        })
      }

      setLoading(false)
    }, debounceTimeInMs),
    [],
    // Apparently DebouncedFunc can be undefined, but it's not clear why.
    // Type cast it here.
  ) as (data: TRequestData | null) => Promise<void>

  const debouncedAPIEndpointWrapper = useCallback((data: TRequestData | null) => {
    // Skip the very first call to this function, if it is null.
    // This is a common occurence if the API endpoint is called from within useEffect.
    if (isFirstRender.current) {
      isFirstRender.current = false
      if (data === null) {
        return
      }
    }
    // Immediately set loading to true, without waiting for the debounced function to fire.
    setLoading(true)
    setResponse(null)
    _debouncedAPIEndpoint(data)
  }, []) as (data: TRequestData | null) => Promise<void>

  return { debouncedAPIEndpoint: debouncedAPIEndpointWrapper, response, loading }
}
