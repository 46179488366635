import { useEffect } from 'react'
import { useRouteMatch } from 'react-router'

import { analytics } from '~/core/analytics'

import Link from './Link'
import cs from './page_not_found.scss'

export default function PageNotFound({ message }: { message?: React.ReactNode }) {
  const routingInfo = useRouteMatch()
  useEffect(() => {
    analytics.page('General', 'Page Not Found', { routingInfo })
    console.log('Page not found. Routing info:', routingInfo) // eslint-disable-line no-console
  }, [])

  return (
    <div className={cs.container}>
      <div className={cs.title}>Page not found.</div>
      <div className={cs.message}>{message ?? <DefaultMessage />}</div>
    </div>
  )
}

function DefaultMessage() {
  const link = window.appContext ? (
    <Link to='/workcell/overview/live-status'>workcell overview</Link>
  ) : (
    <Link to='/monitor/live-cultures'>all plates</Link>
  )

  return (
    <>
      There's nothing at this address. If you followed a link to this page, try checking
      it for typos, or return to {link}.
    </>
  )
}
