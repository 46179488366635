import { Placement, Popover2 } from '@blueprintjs/popover2'
import cx from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'

import { omit } from 'lodash/fp'
import cs from './help_popover.scss'

interface HelpPopoverProps {
  text: React.ReactNode
  helpContent: JSX.Element | string
  className?: string
  interactionKind?: 'hover' | 'click'
  popoverStyle?: 'default' | 'minimal'
  type?: 'warning' | 'error' | 'default'
  placement?: Placement
  offset?: [number, number]
  smallText?: boolean
  elementType?: string
}

const HelpPopover = ({
  text,
  helpContent,
  className,
  interactionKind,
  popoverStyle,
  placement,
  offset,
  smallText,
  elementType = 'span',
  type = 'default',
}: HelpPopoverProps) => {
  const renderTarget = ({ ...targetProps }) => {
    // We omit isOpen. It is passed from Popover2, but not required.
    // It also causes react to throw a warning.
    return React.createElement(
      elementType,
      {
        ...omit(['isOpen'], targetProps),
        className: cx(
          cs.text,
          className,
          interactionKind && cs[interactionKind],
          smallText && cs.smallText,
          type && cs[type],
        ),
      },
      text,
    )
  }

  const renderContent = () => {
    if (typeof helpContent === 'string') {
      return (
        <div className={cx(cs.defaultPopover, popoverStyle && cs[popoverStyle])}>
          {helpContent}
        </div>
      )
    }
    return helpContent
  }

  const modifiers = { offset: {} }

  if (offset) {
    modifiers.offset = {
      enabled: true,
      options: {
        offset,
      },
    }
  }

  return (
    <Popover2
      content={renderContent()}
      interactionKind={interactionKind}
      placement={placement}
      renderTarget={renderTarget}
      modifiers={modifiers}
      openOnTargetFocus={false}
    />
  )
}

HelpPopover.propTypes = {
  text: PropTypes.node,
  helpContent: PropTypes.node,
  className: PropTypes.string,
  interactionKind: PropTypes.oneOf(['click', 'hover']),
  // minimal style has less padding.
  popoverStyle: PropTypes.oneOf(['default', 'minimal']),
  // Can be top, top-start, top-end, bottom, left, right, etc.
  placement: PropTypes.string,
  // [skidding, distance]
  offset: PropTypes.arrayOf(PropTypes.number),
  smallText: PropTypes.bool,
  elementType: PropTypes.string,
}

HelpPopover.defaultProps = {
  interactionKind: 'click',
  popoverStyle: 'default',
  placement: 'top',
  smallText: true,
  elementType: 'span',
}

export default HelpPopover
