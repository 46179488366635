import { client, unwrap } from '~/api/desktopAPIv2'
import { DefaultSuccessResponse } from '.'
import {
  DefaultGetJsonResponse,
  DefaultUpdateJsonRequest,
} from './workcellRecovery.interface'

const apiPrefix = '/api/v2/workcell-recovery'

export default {
  getRunnerConfig: (): Promise<DefaultGetJsonResponse> =>
    unwrap(client().GET(`${apiPrefix}/runner-config`, {})),
  setRunnerConfig: (
    request: DefaultUpdateJsonRequest,
  ): Promise<DefaultSuccessResponse> =>
    unwrap(
      client().POST(`${apiPrefix}/runner-config/set`, {
        body: request,
      }),
    ),
  getScheduleState: (): Promise<DefaultGetJsonResponse> =>
    unwrap(client().GET(`${apiPrefix}/schedule-state`, {})),
  setScheduleState: (
    request: DefaultUpdateJsonRequest,
  ): Promise<DefaultSuccessResponse> =>
    unwrap(
      client().POST(`${apiPrefix}/schedule-state/set`, {
        body: request,
      }),
    ),
}
