import { useEffect } from 'react'

import { FragmentType, unmaskFragment } from '~/__generated__'
import { gql } from '~/__generated__/gql'
import Link from '~/components/Link'
import RelativeTimestamp from '~/components/RelativeTimestamp'
import { analytics } from '~/core/analytics'

import { round } from 'lodash'
import CultureRelativeTimestamp from '../../components/CultureRelativeTimestamp'
import { useMetrics } from '../../metrics'
import CultureImage from '../CulturePage/CultureImage'
import { MiniCultureGrowthChart } from '../MiniCultureGrowthChart'
import cs from './culture_popover.scss'

const plateCulturePopoverFragment = gql(`
  fragment PlateCulturePopoverFragment on WellCultureGraphQL {
    id
    well
    culturePlate {
      id
      barcode
      checkedInAt
    }
    montage {
      id
      culture {
        id
      }
      centerImage: images(positions: CENTER) {
        id
        imageUrl(size: SIZE_600)
      }
    }
    ...MiniCultureGrowthChartFragment
    ...PlateCulturePopoverConfluenceInfoFragment
  }
`)

export function PlateCulturePopover(props: {
  wellCulture: FragmentType<typeof plateCulturePopoverFragment>
  onImageClick?(...args: unknown[]): unknown
}) {
  // TODO: Check for uses of isArchived, consider whether status === "ACTIVE"
  // is more appropriate

  const wellCulture = unmaskFragment(plateCulturePopoverFragment, props.wellCulture)
  const thumbnailURL = wellCulture.montage?.centerImage[0]?.imageUrl

  const metrics = useMetrics()
  useEffect(
    () => metrics.liveCultures.cultureHover(wellCulture.id),
    [metrics, wellCulture.id],
  )
  useEffect(() => {
    analytics.track('Opened Culture Popover', {
      culture: wellCulture.id,
      plate: wellCulture.culturePlate.id,
      well: wellCulture.well,
      hasImage: !!thumbnailURL,
    })
  }, [wellCulture])

  return (
    <div className={cs.popover}>
      <div className={cs.popoverHeader}>
        <div className={cs.popoverHeaderLeft}>
          <div className={cs.popoverHeaderLabel}>
            {wellCulture.culturePlate.barcode}
          </div>
          <Link
            to={`/monitor/culture/${wellCulture.id}`}
            className={cs.popoverHeaderCulture}
            variant='primary'
          >
            Well {wellCulture.well}
          </Link>
          <div className={cs.popoverHeaderMetadata}>
            Checked in{' '}
            <RelativeTimestamp timestamp={wellCulture.culturePlate.checkedInAt} />
          </div>
        </div>
        <div className={cs.popoverHeaderRight}>
          <MiniCultureGrowthChart
            wellCulture={wellCulture}
            className={cs.popoverChart}
          />
        </div>
      </div>
      <div className={cs.popoverData}>
        <div className={cs.popoverDataTitle}>
          <ConfluenceInfo wellCulture={wellCulture} />
        </div>
        {thumbnailURL ? (
          <div className={cs.thumbnailContainer} onClick={props.onImageClick}>
            <CultureImage className={cs.popoverImage} thumbnail={thumbnailURL} />
          </div>
        ) : (
          <div className={cs.thumbnailContainer}>No images available</div>
        )}
      </div>
    </div>
  )
}

const plateCulturePopoverConfluenceInfoFragment = gql(`
  fragment PlateCulturePopoverConfluenceInfoFragment on WellCultureGraphQL {
    id
    createdAt
    status
    confluence {
      value
      lastUpdatedAt
    }
  }
`)

function ConfluenceInfo(props: {
  wellCulture: FragmentType<typeof plateCulturePopoverConfluenceInfoFragment>
}) {
  const { confluence, status, createdAt } = unmaskFragment(
    plateCulturePopoverConfluenceInfoFragment,
    props.wellCulture,
  )

  return (
    <>
      <span className={cs.confluency}>
        {confluence ? `${round(confluence.value)}% confluent` : 'Unknown confluence'}
      </span>{' '}
      {confluence ? (
        <CultureRelativeTimestamp
          status={status}
          createdAt={createdAt}
          timestamp={confluence.lastUpdatedAt}
          className={cs.capturedAt}
        />
      ) : null}
    </>
  )
}
