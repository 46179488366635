import { PropsWithChildren, ReactNode } from 'react'

import cs from './metadata_sidebar.scss'

/**
 * A sidebar (or section of a sidebar) that displays generic key-value metadata.
 *
 * @example
 *   <MetadataSidebar>
 *     <MetadataItem label="Created by">
 *       Jon
 *     </MetadataItem>
 *
 *     <MetadataItem label={
 *       <span className={cs.status}>Status</span>
 *     }>
 *       Unread
 *     </MetadataItem>
 *   </MetadataSidebar>
 */
export default function MetadataSidebar({ children }: PropsWithChildren<unknown>) {
  return (
    <aside>
      <dl className={cs.metadata}>{children}</dl>
    </aside>
  )
}

export function MetadataItem({
  label,
  children,
}: PropsWithChildren<{ label: ReactNode }>) {
  return (
    <>
      <dt>{label}</dt>
      <dd>{children}</dd>
    </>
  )
}
