import cx from 'classnames'
import { compact, filter, get, includes, keyBy, sortBy } from 'lodash/fp'
import { useContext, useEffect, useState } from 'react'

import processItemsAPI from '~/api/desktop/processItems'
import processItemsAPIV2 from '~/api/desktop/processItemsV2'
import workflowInstancesAPI, { WorkflowInstance } from '~/api/desktop/workflowInstances'
import Table from '~/components/Table'
import ArchiveIcon from '~/components/icons/ArchiveIcon'
import UnarchiveIcon from '~/components/icons/UnarchiveIcon'
import Notification from '~/components/notifications/Notification'
import ProcessItemLastCheckInTime from '~/pages/Workcell/components/processItem/ProcessItemLastCheckInTime'
import ProcessItemLocation from '~/pages/Workcell/components/processItem/ProcessItemLocation'
import ProcessItemName from '~/pages/Workcell/components/processItem/ProcessItemName'
import {
  TYPE_STATUS_PILL_CONFIG,
  canCheckIn,
  canCheckOut,
  getMetadataColumnDisplay,
  getShortDisplayName,
  getTypeStatusPillLabel,
  isEditable,
  processItemMatchesInstrumentNames,
} from '~/utils/processItems/common'
import { renderStatusPill } from '~/utils/statusPill'

import WorkcellStatusContext from '../../WorkcellStatusContext'
import {
  experimentalEnableWorkflows,
  getInstrumentNames,
} from '../../utils/workcellStatus'

import { ProcessItem } from '~/common.interface'
import { useIsMounted } from '~/utils/hooks/useIsMounted'
import { underscoreize } from '~/utils/object'
import { displayCount } from '~/utils/string'
import CulturePlateWorkflow from './CulturePlateWorkflow'
import EditableProcessItemViz from './EditableProcessItemViz'
import { processItemMatchesQuery } from './ProcessItemSelect/processItemMatchesQuery'
import ProcessItemViz from './ProcessItemViz'
import cs from './process_item_table.scss'

const renderType = item =>
  renderStatusPill(getTypeStatusPillLabel(item), TYPE_STATUS_PILL_CONFIG, {
    className: cs.status,
  })

const ProcessItemTable = ({
  className,
  processItems,
  loading,
  showLastCheckInTime,
  consumablesOrSamples,
  canEdit,
  onProcessItemUpdate,
  searchValue,
  showWorkflowsCompletedWarning,
  showItemsWithoutLocation,
}: {
  className?: string
  processItems: ProcessItem[] | null
  loading: boolean
  canEdit: boolean
  showLastCheckInTime?: boolean
  consumablesOrSamples: 'consumables' | 'samples' | 'both'
  onProcessItemUpdate: () => void
  searchValue?: string
  showWorkflowsCompletedWarning?: boolean
  showItemsWithoutLocation: boolean
}) => {
  const isMounted = useIsMounted()
  const workcellStatus = useContext(WorkcellStatusContext)
  const workflowsEnabled = experimentalEnableWorkflows(workcellStatus)
  const instrumentNames = getInstrumentNames(workcellStatus)
  const [culturePlateWorkflowInstances, setCulturePlateWorkflowInstances] =
    useState<Record<string, WorkflowInstance> | null>(null)

  const fetchCulturePlateWorkflowInstances = () => {
    const workflowInstanceUuids = compact(
      processItems?.map(processItem => processItem.workflowInstanceUuid),
    )

    workflowInstancesAPI.bulkRetrieve(workflowInstanceUuids).then(response => {
      if (isMounted()) {
        setCulturePlateWorkflowInstances(keyBy('uuid', response.workflow_instances))
      }
    })
  }

  useEffect(() => {
    if (consumablesOrSamples === 'samples') {
      fetchCulturePlateWorkflowInstances()
    }
  }, [processItems])

  const onProcessItemCheckOut = async (uuid: string) => {
    await processItemsAPI.checkOutProcessItem(uuid)
    onProcessItemUpdate()
  }

  const onProcessItemCheckIn = async (uuid: string) => {
    await processItemsAPI.checkInProcessItem(uuid)
    onProcessItemUpdate()
  }

  const onProcessItemEdit = async (uuid: string, newValues: ProcessItem) => {
    // We need to manually underscoreize the state before passing it to the API endpoint,
    // because we are still using the v1 process api endpoint to fetch, which
    // automatically camelizes. We need to improve the typing here as well.
    await processItemsAPIV2.updateProcessItem(uuid, {
      state: underscoreize(newValues.state) as Record<string, never>,
    })
    onProcessItemUpdate()
  }

  const handleEdit = (newProcessItem: ProcessItem) => {
    onProcessItemEdit(newProcessItem.uuid, newProcessItem)
  }

  const getProcessItemViz = (processItem: ProcessItem, hovered: boolean) => {
    if (canEdit && isEditable(processItem)) {
      return (
        <EditableProcessItemViz
          processItem={processItem}
          handleEdit={handleEdit}
          showOverlay={hovered}
        />
      )
    } else {
      return <ProcessItemViz processItem={processItem} />
    }
  }

  const getNumWorkflowsCompleted = () => {
    const workflowsCompleted = compact(
      _processItems.map(processItem =>
        processItem.workflowInstanceUuid
          ? get(processItem.workflowInstanceUuid, culturePlateWorkflowInstances)
          : undefined,
      ),
    )

    return filter(workflow => workflow.status === 'completed', workflowsCompleted)
      .length
  }

  const tableColumns = compact([
    {
      name: 'Process Item',
      width: 150,
      render: (row: ProcessItem) => <ProcessItemName processItem={row} />,
    },
    {
      name: 'Contents',
      width: 125,
      render: (row: ProcessItem, hovered: boolean) => getProcessItemViz(row, hovered),
      receiveHoverParamInRender: true,
    },
    (!workflowsEnabled || includes(consumablesOrSamples, ['consumables', 'both'])) && {
      name: 'Type',
      width: 200,
      render: (row: ProcessItem) => renderType(row),
    },
    workflowsEnabled &&
      includes(consumablesOrSamples, ['samples']) && {
        name: 'Workflow',
        width: 200,
        render: (row: ProcessItem) => (
          <CulturePlateWorkflow
            culturePlate={row}
            workflowInstance={
              row.workflowInstanceUuid
                ? get(row.workflowInstanceUuid, culturePlateWorkflowInstances)
                : undefined
            }
            onEditSuccess={onProcessItemUpdate}
          />
        ),
      },
    {
      name: 'Location',
      width: 200,
      render: (row: ProcessItem) => <ProcessItemLocation processItem={row} />,
      smallText: true,
    },
    showLastCheckInTime && {
      name: 'Checked in',
      width: 200,
      render: (row: ProcessItem) => <ProcessItemLastCheckInTime processItem={row} />,
      smallText: true,
    },
    {
      name: 'Metadata',
      width: 'flex',
      render: (row: ProcessItem) => getMetadataColumnDisplay(row),
      smallText: true,
    },
    {
      name: '',
      width: 60,
      render: (row: ProcessItem) => {
        const controls: JSX.Element[] = []

        if (canCheckOut(row)) {
          controls.push(
            <ArchiveIcon
              className={cs.icon}
              onClick={() => onProcessItemCheckOut(row.uuid)}
              key='archiveIcon'
            />,
          )
        }

        if (canCheckIn(row)) {
          controls.push(
            <UnarchiveIcon
              className={cs.icon}
              onClick={() => onProcessItemCheckIn(row.uuid)}
              key='unarchiveIcon'
            />,
          )
        }

        return <>{controls}</>
      },
      showOnHover: true,
      omitCellPadding: true,
    },
  ])

  if (loading) {
    return <div className={cx(className, cs.bigMessage)}>Loading consumables...</div>
  }
  if (!loading && (!processItems || processItems.length === 0)) {
    return <div className={cx(className, cs.bigMessage)}>No consumables found.</div>
  }

  let _processItems = sortBy(getShortDisplayName, processItems)

  _processItems = filter(
    processItem => processItemMatchesInstrumentNames(processItem, instrumentNames),
    _processItems,
  )

  if (!showItemsWithoutLocation) {
    _processItems = filter(
      processItem => processItem.state?.location != null,
      _processItems,
    )
  }

  if (searchValue) {
    _processItems = filter(
      processItem => processItemMatchesQuery(processItem, searchValue.toLowerCase()),
      _processItems,
    )
  }

  const numWorkflowsCompleted = getNumWorkflowsCompleted()

  return (
    <div className={cx(className, cs.processItemTable)}>
      {showWorkflowsCompletedWarning && numWorkflowsCompleted > 0 && (
        <Notification
          type='warning'
          variant='mini'
          label={`Workflows have completed for ${displayCount(
            'culture plate',
            numWorkflowsCompleted,
          )}`}
          className={cs.workflowsCompletedNotification}
        />
      )}
      <Table
        columns={tableColumns}
        data={_processItems}
        className={cs.table}
        rowPaddingVariant='rowPaddingLow'
        rowKey='id'
      />
    </div>
  )
}

export default ProcessItemTable
