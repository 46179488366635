import { max, min } from 'lodash/fp'

export const getRectWithinContainer = (rect, containerRect) => {
  const x1 = max([rect.x, containerRect.x])
  const y1 = max([rect.y, containerRect.y])
  const x2 = min([rect.x + rect.width, containerRect.x + containerRect.width])
  const y2 = min([rect.y + rect.height, containerRect.y + containerRect.height])

  return {
    left: x1,
    top: y1,
    width: x2 - x1,
    height: y2 - y1,
  }
}
