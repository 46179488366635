import cx from 'classnames'
import { useState } from 'react'
import BulkLoadCulturePlatesIcon from '~/assets/images/operator_bulk_load_culture_plates.png'
import AppHeader from '~/components/AppHeader'
import OperatorActionConfigDialog from '../OperatorActionConfigDialog'
import {
  BULK_LOAD_CULTURE_PLATES,
  BulkLoadCulturePlatesConfig,
} from './BulkLoadCulturePlatesAction'

export interface BulkLoadCulturePlatesHeaderProps {
  className?: string
  handleConfigUpdate: (config: BulkLoadCulturePlatesConfig) => void
}

const BulkLoadCulturePlatesHeader = ({
  className,
  handleConfigUpdate,
}: BulkLoadCulturePlatesHeaderProps) => {
  const [operatorActionConfigDialogOpen, setOperatorActionConfigDialogOpen] =
    useState(false)
  return (
    <div className={cx(className)}>
      <AppHeader
        appName='Bulk Load Culture Plates'
        iconSrc={BulkLoadCulturePlatesIcon}
        variant='inline'
        menuOptions={[
          {
            label: 'Configure...',
            action: () => setOperatorActionConfigDialogOpen(true),
          },
        ]}
      />
      <OperatorActionConfigDialog
        isOpen={operatorActionConfigDialogOpen}
        onClose={() => setOperatorActionConfigDialogOpen(false)}
        operatorActionName={BULK_LOAD_CULTURE_PLATES}
        onConfigUpdate={handleConfigUpdate}
      />
    </div>
  )
}

export default BulkLoadCulturePlatesHeader
