import PropTypes from 'prop-types'
import { IconProps } from './types/IconProps'
const RightArrowIcon = ({ className, onClick }: IconProps) => (
  <svg
    width='8'
    height='12'
    viewBox='0 0 8 12'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    className={className}
    onClick={onClick}
  >
    <path d='M0.295 10.59L4.875 6L0.295 1.41L1.705 0L7.705 6L1.705 12L0.295 10.59Z' />
  </svg>
)

RightArrowIcon.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
}

export default RightArrowIcon
