import TiprackIcon from '~/components/icons/TiprackIcon'
import LoadUnloadItemsTiprackForm, {
  getTiprackLoadParamsRequestOrError,
} from '../LoadUnloadItemsProcessItemForm/LoadUnloadItemsTiprackForm'
import { ProcessItemTypeConfig } from './ProcessItemTypeConfig.interface'

export const LOAD_UNLOAD_ITEMS_TIPRACK_CONFIG: ProcessItemTypeConfig<'tiprack'> = {
  getLoadParamsRequestOrError: getTiprackLoadParamsRequestOrError,
  formComponent: LoadUnloadItemsTiprackForm,
  iconComponent: TiprackIcon,
}
