import cx from 'classnames'
import PropTypes from 'prop-types'

import BigNotification from '~/components/notifications/BigNotification'
import Notification from '~/components/notifications/Notification'
import TinyNotification from '~/components/notifications/TinyNotification'

import cs from './notification_playground.scss'

interface NotificationPlaygroundProps {
  className?: string
}

const NotificationPlayground = ({ className }: NotificationPlaygroundProps) => {
  return (
    <div className={cx(className, cs.notificationPlayground)}>
      <div className={cs.contents}>
        <div className={cs.item}>
          <div className={cs.label}>Notification</div>
          <Notification label='This is a notification' />
        </div>
        <div className={cs.item}>
          <div className={cs.label}>Notification (Mini)</div>
          <Notification label='This is a notification' variant='mini' />
        </div>
        <div className={cs.item}>
          <div className={cs.label}>TinyNotification (Default)</div>
          <TinyNotification message='Schedule Routines' />
        </div>
        <div className={cs.item}>
          <div className={cs.label}>TinyNotification (Bare)</div>
          <TinyNotification message='Schedule Routines' type='bareSuccess' />
        </div>
        <div className={cs.item}>
          <div className={cs.label}>BigNotification (Default)</div>
          <BigNotification message='Schedule Routines' />
        </div>
      </div>
    </div>
  )
}

NotificationPlayground.propTypes = {
  className: PropTypes.string,
}

export default NotificationPlayground
