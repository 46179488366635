import { compact, filter, get, keyBy, map, merge } from 'lodash/fp'
import { useEffect, useState } from 'react'

import commonDriverAPI from '~/api/desktop/commonDriver'
import processItemsAPIV2 from '~/api/desktop/processItemsV2'
import PropTypes from '~/propTypes'

import {
  Instrument,
  ProcessItem,
  ProcessItemType,
  StringOnlyProcessItem,
} from '~/common.interface'
import { useIsMounted } from '~/utils/hooks/useIsMounted'
import { underscoreize, updateValuesByProperty } from '~/utils/object'
import { isProcessItem } from '~/utils/processItems/common'
import { ReloadChange } from '../../OperatorActions/reloadItems/ReloadOperation.interface'
import { InstrumentVizDisplaySize } from '../InstrumentVizDisplaySize.interface'
import {
  StorageThreshold,
  getDisplaySizeUsingDimensionThresholds,
} from '../getDisplaySizeUsingLevelThresholds'
import {
  StorageDimensions,
  StorageInstrumentConfig,
} from './StorageInstrumentConfig.interface'
import { StorageInstrumentState } from './StorageInstrumentState.interface'
import StorageVizView, { SelectedStorageLocation } from './StorageVizView'

interface StorageVizProps {
  className?: string
  instrument: Instrument
  highlightLocations?: string[]
  onSlotClick?: (
    processItemUuid: string | null,
    shelfIndex: number,
    levelIndex: number,
    isChanged: boolean,
  ) => void
  displayAddIconOnHover?: boolean
  displayRemoveIconOnHover?: boolean
  changes?: ReloadChange[]
  reloadKey?: string
  clickablePartitions?: string[]
  hideShelfLabels?: boolean
  hideLevelLabels?: boolean
  displaySize?: InstrumentVizDisplaySize
  selectedLocations?: SelectedStorageLocation[]
  clickableOnItemOnly?: boolean
  clickableOnNoItemOnly?: boolean
  clickableItemType?: ProcessItemType
  displaySizeDimensionThresholds?: Partial<
    Record<InstrumentVizDisplaySize, StorageThreshold>
  >
  disabled?: boolean
  showOnlyShelfIndex?: number // 1-indexed
}

const StorageViz = ({
  className,
  instrument,
  reloadKey,
  highlightLocations,
  onSlotClick,
  displayAddIconOnHover,
  displayRemoveIconOnHover,
  changes,
  clickablePartitions,
  displaySize,
  displaySizeDimensionThresholds,
  hideShelfLabels,
  hideLevelLabels,
  selectedLocations,
  clickableOnItemOnly,
  clickableOnNoItemOnly,
  clickableItemType,
  disabled,
  showOnlyShelfIndex,
}: StorageVizProps) => {
  const isMounted = useIsMounted()
  const [config, setConfig] = useState<StorageInstrumentConfig | null>(null)
  const [instrumentState, setInstrumentState] = useState<StorageInstrumentState | null>(
    null,
  )
  const [processItems, setProcessItems] = useState<Record<
    string,
    ProcessItem | StringOnlyProcessItem
  > | null>(null)
  const [processItemToEdit, setProcessItemToEdit] = useState<ProcessItem | null>(null)

  const fetchData = async () => {
    const instrumentName = get('instrumentName', instrument)
    const [_config, _instrumentState, _processItems] = await Promise.all([
      commonDriverAPI.getConfigV2(instrumentName),
      commonDriverAPI.getState(instrumentName),
      commonDriverAPI.getProcessItems(instrumentName),
    ])

    if (!isMounted()) return
    setConfig(_config.config as unknown as StorageInstrumentConfig)
    setInstrumentState(_instrumentState)
    setProcessItems(keyBy('uuid', compact(map('processItem', _processItems))))
  }

  const getDimensions = (): StorageDimensions | undefined => {
    if (get('instrumentType', instrument) === 'inheco_scila') {
      return {
        numShelves: 1,
        numLevels: 4,
      }
    }
    if (!config?.dimensions) {
      // If the instrument is not set up yet, return a default 1x1 to avoid crashing the page.
      return { numShelves: 1, numLevels: 1 }
    }
    if (config) {
      return {
        numShelves: config.dimensions.num_shelves,
        numLevels: config.dimensions.num_levels,
      }
    }
    return undefined
  }

  const getDisplaySize = () => {
    if (displaySize) {
      return displaySize
    }
    const dimensions = getDimensions()
    if (dimensions) {
      return getDisplaySizeUsingDimensionThresholds(
        dimensions,
        displaySizeDimensionThresholds || {
          micro: { numLevels: 40, numShelves: 20 },
          mini: { numLevels: 30, numShelves: 20 },
          normal: { numLevels: 15, numShelves: 20 },
        },
      )
    }
    return 'large'
  }

  const updateProcessItem = async (uuid: string, newValues: ProcessItem) => {
    setProcessItems(
      updateValuesByProperty(
        ['uuid', uuid],
        (oldValues: ProcessItem | StringOnlyProcessItem) => {
          if (isProcessItem(oldValues)) {
            return merge(oldValues, newValues)
          }
          return oldValues
        },
        processItems,
      ),
    )
    // We need to manually underscoreize the state before passing it to the API endpoint,
    // because we are still using the v1 process api endpoint to fetch, which
    // automatically camelizes. We need to improve the typing here as well.
    await processItemsAPIV2.updateProcessItem(uuid, {
      state: underscoreize(newValues.state) as Record<string, never>,
    })
    fetchData()
  }

  const handleProcessItemEdit = (newProcessItem: ProcessItem) => {
    if (processItemToEdit) {
      updateProcessItem(processItemToEdit.uuid, newProcessItem)
    }
    setProcessItemToEdit(null)
  }

  useEffect(() => {
    if (instrument) {
      fetchData()
    }
  }, [instrument, reloadKey])

  const dimensions = getDimensions()

  if (!instrumentState || !dimensions) {
    return null
  }

  // If an instrument is specified, and it is not this instrument, ignore it.
  const changesOnThisInstrument = filter(
    change =>
      !change.instrumentName ||
      change.instrumentName === get('instrumentName', instrument),
    changes,
  )

  return (
    <StorageVizView
      className={className}
      instrumentName={instrument.instrumentName}
      items={get('storedProcessItems', instrumentState)}
      itemObjects={processItems || undefined}
      dimensions={dimensions}
      regions={get('partitions', config)}
      defaultRegion={config?.default_partition}
      highlightLocations={highlightLocations}
      onSlotClick={onSlotClick}
      displayAddIconOnHover={displayAddIconOnHover}
      displayRemoveIconOnHover={displayRemoveIconOnHover}
      changes={changesOnThisInstrument}
      selectedLocations={selectedLocations}
      clickablePartitions={clickablePartitions}
      clickableOnItemOnly={clickableOnItemOnly}
      clickableOnNoItemOnly={clickableOnNoItemOnly}
      clickableItemType={clickableItemType}
      processItemToEdit={processItemToEdit || null}
      setProcessItemToEdit={setProcessItemToEdit}
      handleProcessItemEdit={handleProcessItemEdit}
      size={getDisplaySize()}
      hideShelfLabels={hideShelfLabels}
      hideLevelLabels={hideLevelLabels}
      disabled={disabled}
      showOnlyShelfIndex={showOnlyShelfIndex}
    />
  )
}

StorageViz.propTypes = {
  className: PropTypes.string,
  instrument: PropTypes.Instrument,
  // "s1:l1". Should be 1-indexed.
  highlightLocations: PropTypes.arrayOf(PropTypes.string),
  // Change this to have the viz reload its data.
  reloadKey: PropTypes.string,
  onSlotClick: PropTypes.func,
  displayAddIconOnHover: PropTypes.bool,
  displayRemoveIconOnHover: PropTypes.bool,
  // Changes will display in highlighted color.
  changes: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      shelfIndex: PropTypes.number,
      levelIndex: PropTypes.number,
      locationString: PropTypes.string,
    }),
  ),
  clickablePartitions: PropTypes.arrayOf(PropTypes.string),
  displaySize: PropTypes.oneOf(['normal', 'large', 'micro', 'mini']),
  hideShelfLabels: PropTypes.bool,
  hideLevelLabels: PropTypes.bool,
}

export default StorageViz
