import cx from 'classnames'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'

import { updateUser } from '~/api/team'
import Dialog from '~/components/Dialog'
import Input from '~/components/Input'
import Pill from '~/components/Pill'
import Button from '~/components/buttons/Button'

import { UserProfile } from '~/types/UserProfile.interface'
import cs from './modify_user_dialog.scss'

const ERROR_MESSAGES = {
  ADMIN_REQUIRED_TO_MODIFY_ADMIN:
    'Modifying admin privileges requires admin privileges.',
  ADMIN_REQUIRED_TO_MODIFY_USER: 'Modifying other users requires admin privileges.',
  USER_IN_DIFFERENT_ORG: 'Cannot modify user in different organization.',
  UNKNOWN_ERROR: 'An unknown error occurred. Contact support@monomerbio.com.',
}

interface ModifyUserDialogProps {
  open: boolean
  onClose: () => void
  onUserModified: (
    id: number,
    firstName: string,
    lastName: string,
    email: string,
    isAdmin: boolean | null,
  ) => void
  user: UserProfile | null
  userIsAdmin: boolean
}

const ModifyUserDialog = ({
  user,
  open,
  onClose,
  onUserModified,
  userIsAdmin,
}: ModifyUserDialogProps) => {
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [isAdmin, setIsAdmin] = useState(false)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')

  const onModifyUser = async () => {
    if (!user) return
    setLoading(true)
    setError('')
    let response
    try {
      response = await updateUser(
        user.id,
        firstName,
        lastName,
        userIsAdmin ? isAdmin : null,
      )
    } catch (inviteUserError) {
      setLoading(false)
      setError('UNKNOWN_ERROR')
    }

    if (response) {
      setLoading(false)
      if (response.error) {
        setError(response.error)
      } else {
        onUserModified(
          user.id,
          firstName,
          lastName,
          user.email,
          userIsAdmin ? isAdmin : null,
        )
      }
    }
  }

  useEffect(() => {
    if (user) {
      setFirstName(user.first_name)
      setLastName(user.last_name)
      setIsAdmin(user.is_admin)
      setError('')
    }
  }, [user])

  const renderContents = () => {
    return (
      <>
        <div className={cs.title}>Update User Information</div>
        <Input
          label='First Name'
          value={firstName}
          onChange={setFirstName}
          className={cs.inputContainer}
          inputClassName={cs.input}
        />
        <Input
          label='Last Name'
          value={lastName}
          onChange={setLastName}
          className={cs.inputContainer}
          inputClassName={cs.input}
        />
        {userIsAdmin && (
          <>
            <div className={cs.label}>Role</div>
            <div className={cs.roleOptions}>
              <Pill
                className={cx(cs.roleOption, isAdmin && cs.selected)}
                onClick={() => setIsAdmin(true)}
                type={isAdmin ? 'accent' : 'default'}
                label='Admin'
              />
              <Pill
                className={cx(cs.roleOption, !isAdmin && cs.selected)}
                onClick={() => setIsAdmin(false)}
                type={!isAdmin ? 'info' : 'default'}
                label='User'
              />
            </div>
            <div className={cs.roleDescription}>
              {!isAdmin && 'A user can use apps  but cannot manage the team.'}
              {isAdmin && 'An admin can use apps and manage the team.'}
            </div>
          </>
        )}
        <div className={cs.modifyUserButtonContainer}>
          <div className={cs.error}>{ERROR_MESSAGES[error]}</div>
          <Button
            type='primary'
            label={loading ? 'Loading' : 'Update User'}
            disabled={!firstName || !lastName || loading}
            className={cs.modifyUserButton}
            onClick={onModifyUser}
          />
        </div>
      </>
    )
  }

  return (
    <Dialog isOpen={open} onClose={onClose} className={cs.modifyUserDialog}>
      {renderContents()}
    </Dialog>
  )
}

ModifyUserDialog.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.number,
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    email: PropTypes.string,
    is_admin: PropTypes.bool,
  }),
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onUserModified: PropTypes.func,
}

export default ModifyUserDialog
