import cx from 'classnames'
import PropTypes from 'prop-types'
import { ReactNode } from 'react'

import LoadingIcon from '~/components/icons/LoadingIcon'

import cs from './loading_message.scss'

export interface LoadingMessageProps {
  className?: string
  label: ReactNode
  type?: 'black' | 'accent'
  variant?: 'default' | 'large'
}

const LoadingMessage = ({ className, label, type, variant }) => (
  <div className={cx(className, cs.loadingMessage, cs[type], cs[variant])}>
    <LoadingIcon className={cs.icon} />
    <div className={cs.message}>{label}</div>
  </div>
)

LoadingMessage.propTypes = {
  className: PropTypes.string,
  label: PropTypes.node,
  type: PropTypes.oneOf(['black', 'accent']),
  variant: PropTypes.oneOf(['default', 'large']),
}

LoadingMessage.defaultProps = {
  variant: 'default',
}

export default LoadingMessage
