import dayjs from 'dayjs'

import { FragmentType, gql, unmaskFragment } from '~/__generated__'
import MetadataSidebar, { MetadataItem } from '~/components/MetadataSidebar'

import cs from './plate_metadata_sidebar.scss'

const fragment = gql(`
  fragment PlateMetadataSidebarFragment on CulturePlateGraphQL {
    id
    barcode
    firstCheckedInAt
  }
`)

export default function PlateMetadataSidebar(props: {
  plate: FragmentType<typeof fragment>
}) {
  const plate = unmaskFragment(fragment, props.plate)

  const firstCheckedInAt = dayjs(plate.firstCheckedInAt)
  const firstCheckedInThisYear = firstCheckedInAt.year() === dayjs().year()

  return (
    <MetadataSidebar>
      <MetadataItem label='First Checked In'>
        {firstCheckedInAt.format(firstCheckedInThisYear ? 'MMM D' : 'MMM D, YYYY')}
        &ensp;
        {firstCheckedInAt.format('h:mm A')}&ensp;
        <span className={cs.secondary}>({firstCheckedInAt.fromNow()})</span>
      </MetadataItem>

      <MetadataItem label='Barcode'>{plate.barcode}</MetadataItem>
    </MetadataSidebar>
  )
}
