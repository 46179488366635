import { useEffect, useState } from 'react'
import recordManualPassageAPI, {
  RecordManualPassageParseDataResponse,
} from '~/api/operatorActions/recordManualPassage'
import Textarea from '~/components/Textarea'
import Toaster from '~/components/Toaster'
import MinimalButton from '~/components/buttons/MinimalButton'
import useDebouncedAPIEndpoint from '~/utils/hooks/useDebouncedAPIEndpoint'
import RecordManualPassageDataSchemasDialog from './RecordManualPassageDataSchemasDialog'
import UploadDataStepPreview from './UploadDataStepPreview'
import cs from './upload_data_step.scss'

type UploadDataStepProps = {
  rawData: string
  setRawData: (rawData: string) => void
  parseDataResponse: RecordManualPassageParseDataResponse | null
  setParseDataResponse: (response: RecordManualPassageParseDataResponse | null) => void
}

interface ParseDataRequest {
  rawData: string
}

const UploadDataStep = ({
  rawData,
  setRawData,
  parseDataResponse,
  setParseDataResponse,
}: UploadDataStepProps) => {
  const [dataSchemasDialogOpen, setDataSchemasDialogOpen] = useState(false)
  const {
    response: parseDataDebouncedResponse,
    debouncedAPIEndpoint: debouncedParseData,
    loading: isParsingData,
  } = useDebouncedAPIEndpoint<ParseDataRequest, RecordManualPassageParseDataResponse>(
    async (parseDataRequest: ParseDataRequest) => {
      return recordManualPassageAPI.parseData({
        data: parseDataRequest.rawData,
      })
    },
    250,
  )

  useEffect(() => {
    if (parseDataDebouncedResponse === null) {
      setParseDataResponse(null)
    } else if (parseDataDebouncedResponse.type === 'response') {
      setParseDataResponse(parseDataDebouncedResponse.response)
    } else if (parseDataDebouncedResponse.type === 'error') {
      Toaster.show({
        message: 'Error communicating with the server.',
        intent: 'danger',
      })
      setParseDataResponse(null)
    }
  }, [parseDataDebouncedResponse])

  useEffect(() => {
    // Null responses must be passed through the debounced API call, so
    // they don't get overwritten by stale responses.
    debouncedParseData(rawData ? { rawData } : null)
  }, [rawData])

  return (
    <div className={cs.uploadDataStep}>
      <div className={cs.inputColumn}>
        <div className={cs.sectionTitle}>Paste Manual Passage Data</div>
        <Textarea
          className={cs.textarea}
          rows={8}
          onChange={setRawData}
          value={rawData}
        />
        <MinimalButton
          className={cs.viewAcceptedDataSchemas}
          type='primary'
          onClick={() => setDataSchemasDialogOpen(true)}
          label='View Accepted Data Formats'
          variant='normal'
        />
      </div>
      <div className={cs.previewColumn}>
        <UploadDataStepPreview
          className={cs.preview}
          loading={isParsingData}
          parseDataResponse={parseDataResponse}
        />
      </div>
      <RecordManualPassageDataSchemasDialog
        isOpen={dataSchemasDialogOpen}
        onClose={() => setDataSchemasDialogOpen(false)}
      />
    </div>
  )
}

export default UploadDataStep
