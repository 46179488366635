import { includes } from 'lodash/fp'
import { LoadUnloadItemsConfig } from './LoadUnloadItemsAction'
import { LOAD_UNLOAD_ITEMS_PROCESS_ITEM_TYPES } from './LoadUnloadItemsProcessItemType.interface'

export const getAllowedProcessItemTypes = (config: LoadUnloadItemsConfig) => {
  const { enabledItemTypes } = config

  return enabledItemTypes.filter(processItemType => {
    return includes(processItemType, LOAD_UNLOAD_ITEMS_PROCESS_ITEM_TYPES)
  })
}
