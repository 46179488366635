import Link from '~/components/Link'

import cs from './culture_link.scss'

export function CultureLink({
  id,
  name,
  cellLine,
  className,
}: {
  id: string
  name: string
  cellLine: string
  className?: string
}) {
  return (
    <div className={className}>
      <div>
        <Link
          to={`/command-center/culture/${id}`}
          className={cs.cultureLink}
          variant='primary'
        >
          {name}
        </Link>
      </div>
      <div className={cs.cultureCellLine}>{cellLine}</div>
    </div>
  )
}
