import dayjs from 'dayjs'
import React, { useMemo } from 'react'
import { WellCultureStatusGraphQl } from '~/__generated__/graphql'
import RelativeTimestamp from '~/components/RelativeTimestamp'

/**
 * Returns a `<span>` that displays the relative time since the culture was created
 * expressed as "Day 1, Hour 2".
 * Also provides the absolute timestamp in a tooltip.
 */
function RelativeFromCultureCreatedTimeDisplay({
  timestamp,
  cultureCreatedAt,
  className,
}: {
  timestamp: any
  cultureCreatedAt: any
  className: string
} & React.ComponentProps<'span'>) {
  const [absoluteTime, relativeTime] = useMemo(() => {
    const days = dayjs(timestamp).diff(dayjs(cultureCreatedAt), 'day')
    const hours = dayjs(timestamp).diff(dayjs(cultureCreatedAt), 'hour') % 24

    const date = dayjs(timestamp)
    return [date.format('MMM D, YYYY h:mm A (Z UTC)'), `Day ${days}, Hour ${hours}`]
  }, [timestamp])

  return (
    <span title={absoluteTime} className={className}>
      {relativeTime}
    </span>
  )
}

/**
 * Returns a relative timestamp based on the status of the culture.
 * If the culture is active, it will return a relative timestamp like "5 days ago."
 * If the culture is inactive, it will return a relative timestamp based on the culture's creation time,
 * expressed as "Day 1, Hour 2".
 * Also provides the absolute timestamp in a tooltip for both formats.
 */
export default function CultureRelativeTimestamp({
  status,
  createdAt,
  timestamp,
  className,
}: {
  status: WellCultureStatusGraphQl
  createdAt: any
  timestamp: any
  className: string
}) {
  if (status === 'ACTIVE') {
    return <RelativeTimestamp className={className} timestamp={timestamp} />
  }
  return (
    <RelativeFromCultureCreatedTimeDisplay
      className={className}
      cultureCreatedAt={createdAt}
      timestamp={timestamp}
    />
  )
}
