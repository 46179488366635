import MicroplateIcon from '~/components/icons/MicroplateIcon'
import LoadUnloadItemsReagentPlateForm, {
  getReagentPlateLoadParamsRequestOrError,
} from '../LoadUnloadItemsProcessItemForm/LoadUnloadItemsReagentPlateForm'
import { ProcessItemTypeConfig } from './ProcessItemTypeConfig.interface'

export const LOAD_UNLOAD_ITEMS_REAGENT_PLATE_CONFIG: ProcessItemTypeConfig<'reagent_plate'> =
  {
    getLoadParamsRequestOrError: getReagentPlateLoadParamsRequestOrError,
    formComponent: LoadUnloadItemsReagentPlateForm,
    iconComponent: MicroplateIcon,
  }
