import cx from 'classnames'
import { filter, get, isNil, map, reject, set, size } from 'lodash/fp'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'

import workcellAPI from '~/api/desktop/workcell'
import Input from '~/components/Input'
import PopupMenu from '~/components/PopupMenu'
import Button from '~/components/buttons/Button'
import DownArrowIcon from '~/components/icons/DownArrowIcon'
import DownloadIcon from '~/components/icons/DownloadIcon'
import RefreshIcon from '~/components/icons/RefreshIcon'
import usePaginatedResults from '~/utils/hooks/usePaginatedResults'
import { INSTRUMENT_DISPLAY_NAMES } from '~/utils/instrument'

import datasetsAPI from '~/api/desktop/datasets'
import { Instrument } from '~/common.interface'
import { useIsMounted } from '~/utils/hooks/useIsMounted'
import DriverConfigDialog from '../components/DriverConfigDialog'
import DatasetDownloadDialog from './DatasetDownloadDialog'
import { DatasetQueryParams } from './DatasetQueryParams.interface'
import DatasetTable from './DatasetTable'
import Dataset from './DatasetTable/Dataset.interface'
import cs from './datasets.scss'

interface DatasetsProps {
  className?: string
}

const Datasets = ({ className }: DatasetsProps) => {
  const {
    queryParams,
    setQueryParams,
    loadingInitialResults,
    results: datasets,
    fetchMoreResults,
    hasMoreResults,
    totalCount,
    refetchResults: refetchDatasets,
  } = usePaginatedResults<Dataset, DatasetQueryParams>(datasetsAPI.getDatasets, {
    initialQueryParams: {
      searchQuery: '',
    },
    isQueryParamsValid: params => params && !isNil(params.instrumentNames),
    isResultsEmptyFromQueryParams: params =>
      params && !isNil(params.instrumentNames) && size(params.instrumentNames) === 0,
  })

  const isMounted = useIsMounted()
  const [datasetDownloadDialogOpen, setDatasetDownloadDialogOpen] = useState(false)
  const [instruments, setInstruments] = useState<Instrument[] | null>(null)
  const [configDialogOpen, setConfigDialogOpen] = useState(false)

  let instrumentTypeDisplay = null
  let instrument: Instrument | null = null

  if (instruments) {
    const dataSourceInstruments = filter(['isDataSource', true], instruments)
    // Just use the first instrument for now, for the config dialog.
    instrument = get(0, dataSourceInstruments)
    instrumentTypeDisplay = INSTRUMENT_DISPLAY_NAMES[get('instrumentType', instrument)]
  }

  const fetchInstruments = () => {
    workcellAPI.getInstruments().then(_instruments => {
      if (!isMounted()) return
      setInstruments(_instruments)
      const dataSourceInstruments = filter(['isDataSource', true], _instruments)
      setQueryParams(
        set(
          'instrumentNames',
          map('instrumentName', dataSourceInstruments),
          queryParams,
        ),
      )
    })
  }

  useEffect(() => {
    fetchInstruments()
  }, [])

  const renderTable = () => {
    if (loadingInitialResults) {
      return <div className={cs.bigMessage}>Loading entries...</div>
    }
    if (!loadingInitialResults && datasets.length === 0) {
      return <div className={cs.bigMessage}>No entries found.</div>
    }

    return (
      <DatasetTable
        datasets={datasets}
        className={cs.table}
        hasMoreData={hasMoreResults}
        fetchMoreData={fetchMoreResults}
      />
    )
  }

  const renderSubheader = () => {
    if (loadingInitialResults) {
      return (
        <div className={cs.subheader}>
          <div className={cs.subtitle}>Loading datasets...</div>
        </div>
      )
    }

    return (
      <div className={cs.subheader}>
        <div className={cs.subtitle}>
          {totalCount} matching datasets on this workcell
        </div>
      </div>
    )
  }

  const validDatasets = reject(['status', 'invalid'], datasets)

  return (
    <div className={cx(className, cs.datasets)}>
      <div className={cs.controls}>
        <Input
          className={cs.searchInput}
          value={queryParams.searchQuery}
          label='Search'
          onChange={value => setQueryParams(set('searchQuery', value, queryParams))}
          placeholder='Search...'
        />
        <div className={cs.fill} />
        <Button
          className={cs.button}
          label='Download as CSV'
          IconComponent={DownloadIcon}
          type='primary'
          onClick={() => setDatasetDownloadDialogOpen(true)}
        />
        <Button
          className={cs.button}
          label='Refresh Table'
          IconComponent={RefreshIcon}
          onClick={refetchDatasets}
        />
        {instrumentTypeDisplay && (
          <PopupMenu
            trigger={
              <div className={cs.actionsTrigger}>
                Settings
                <DownArrowIcon className={cs.icon} />
              </div>
            }
            placement='bottom-end'
            options={[
              {
                label: `Edit ${instrumentTypeDisplay} Settings...`,
                action: () => setConfigDialogOpen(true),
              },
            ]}
          />
        )}
      </div>
      {renderSubheader()}
      {renderTable()}
      {instrument && (
        <DriverConfigDialog
          instrumentName={instrument.instrumentName}
          instrumentType={instrument.instrumentType}
          isOpen={configDialogOpen}
          onClose={() => setConfigDialogOpen(false)}
        />
      )}
      <DatasetDownloadDialog
        isOpen={datasetDownloadDialogOpen}
        onClose={() => setDatasetDownloadDialogOpen(false)}
        queryParams={queryParams}
        validDatasetCount={validDatasets.length}
      />
    </div>
  )
}

Datasets.propTypes = {
  className: PropTypes.string,
}

export default Datasets
