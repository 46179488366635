// TODO: A better name for this might be "pooled consumable".
// At the moment, the back-end calls these "plates".
// Tipracks, reagent plates, and culture plates are all "plates".
// For now, we mirror the naming from the back-end.
// This domain is expected to change.

import { compact, flatten, flow, get, range, set } from 'lodash/fp'
import { ProcessItem } from '~/common.interface'
import { convertWellNameToWellCoord } from '~/utils/microplate'

export const generateProcessItemContentsFromDim = (
  numRows: number,
  numCols: number,
  numWellsUsed: number,
): Array<Array<string | null>> => {
  return range(0, numRows).map(rowIndex =>
    range(0, numCols).map(colIndex => {
      const _index = rowIndex + numRows * colIndex
      return _index >= numWellsUsed ? 'X' : null
    }),
  )
}

export const generateProcessItemContents = (
  processItem: ProcessItem,
  currentIndex,
): Array<Array<string | null>> => {
  const numRows = get(['metadata', 'num_rows'], processItem)
  const numCols = get(['metadata', 'num_cols'], processItem)

  return generateProcessItemContentsFromDim(numRows, numCols, currentIndex)
}

const generateProcessItemContentsFromWellArray = (
  processItem: ProcessItem,
  wellArray: string[],
): Array<Array<string | null>> => {
  const numRows = get(['metadata', 'num_rows'], processItem)
  const numCols = get(['metadata', 'num_cols'], processItem)

  const contentCoords = wellArray.map(wellStr => convertWellNameToWellCoord(wellStr))

  return range(0, numRows).map(rowIndex =>
    range(0, numCols).map(colIndex => {
      return contentCoords.some(e => e.rowIndex == rowIndex && e.colIndex == colIndex)
        ? 'X'
        : null
    }),
  )
}

export const updateProcessItemCurrentIndex = (
  processItem: ProcessItem,
  currentIndex,
) => {
  return flow(
    set(['state', 'currentIndex'], currentIndex),
    set(['state', 'contents'], generateProcessItemContents(processItem, currentIndex)),
  )(processItem)
}

export const updateProcessItemWellArray = (
  processItem: ProcessItem,
  wellArray: string[],
) => {
  return flow(
    set(
      ['state', 'contents'],
      generateProcessItemContentsFromWellArray(processItem, wellArray),
    ),
  )(processItem)
}

export const getPooledConsumablePercentFilled = (processItem: ProcessItem) => {
  const contents = get(['state', 'contents'], processItem)
  const flattened = flatten(contents)
  return (compact(flattened).length / flattened.length) * 100
}
