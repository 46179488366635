import PropTypes from 'prop-types'
import { useCommandCenterEventContext } from '../CommandCenterEventsContext'

import Dialog from '~/components/Dialog'
import ConfigDialogTab from '~/components/Dialog/ConfigDialogTab'
import demoConfigAPI from '~/pages/slasDemo/api/demoConfig'

import { JsonSchemaResponse } from '~/types/JsonSchema.interface'
import cs from './slas_demo_config_dialog.scss'

const SlasDemoConfigDialog = ({
  isOpen,
  onClose,
}: {
  isOpen: boolean
  onClose: () => void
}) => {
  const { onConfigUpdate } = useCommandCenterEventContext()

  // Note: These calls are technically all synchronous, but we're keeping the async signature
  // here to minimize changes to the ConfigDialogTab component.
  const api = {
    getSchema: async () => {
      const response = demoConfigAPI.getDemoConfigSchema()
      return response as JsonSchemaResponse
    },
    getConfig: async () => {
      return demoConfigAPI.getDemoConfig()
    },
    setConfig: async newConfig => {
      const response = demoConfigAPI.setDemoConfig(newConfig)
      onConfigUpdate(newConfig)
      return response
    },
  }

  return (
    <Dialog isOpen={isOpen} onClose={onClose} className={cs.slasDemoConfigDialog}>
      <div className={cs.title}>Configure</div>
      <ConfigDialogTab api={api} onClose={onClose} className={cs.configTab} />
    </Dialog>
  )
}

SlasDemoConfigDialog.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  operatorActionName: PropTypes.string,
  onConfigUpdate: PropTypes.func,
}

export default SlasDemoConfigDialog
