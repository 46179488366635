import cx from 'classnames'
import BareWarningIcon from '~/components/icons/BareWarningIcon'
import cs from './issue_label.scss'

export function IssueLabel({
  label,
  messageKind,
  flushLeft,
  className,
}: {
  label: string
  className: string
  messageKind: 'warning' | 'error'
  // Set a negative margin-left so the label is flush with the left.
  flushLeft?: boolean
}) {
  return (
    <div
      className={cx(className, cs.issue, cs[messageKind], flushLeft && cs.flushLeft)}
    >
      <BareWarningIcon className={cx(cs.issueIcon, cs[`issueIcon__${messageKind}`])} />
      <span>{label}</span>
    </div>
  )
}
