import cx from 'classnames'
import { compact, flatten, get } from 'lodash/fp'
import { useEffect, useState } from 'react'

import commonDriverAPI from '~/api/desktop/commonDriver'
import useReloadKey from '~/utils/hooks/useReloadKey'

import { Instrument, InstrumentStatus } from '~/common.interface'
import GenericInstrumentBody from './GenericInstrumentBody'
import GenericInstrumentPage from './GenericInstrumentPage'
import cs from './static_storage_instrument_page.scss'

interface StaticStorageInstrumentPageProps {
  className?: string
  instrument: Instrument
  status: InstrumentStatus
  live?: boolean
}

const StaticStorageInstrumentPage = ({
  className,
  status,
  live,
  instrument,
}: StaticStorageInstrumentPageProps) => {
  const [instrumentState, setInstrumentState] = useState(null)
  const [reloadKey, refreshReloadKey] = useReloadKey()

  const fetchStaticStorageInfo = async () => {
    const _instrumentState = await commonDriverAPI.getState(instrument.instrumentName)
    setInstrumentState(_instrumentState)
  }

  const handleControlCommandComplete = commandName => {
    if (
      commandName === 'load_process_item_command' ||
      commandName === 'unload_process_item_command' ||
      commandName === 'refresh_item_cache_command'
    ) {
      fetchStaticStorageInfo()
      refreshReloadKey()
    }
  }

  useEffect(() => {
    fetchStaticStorageInfo()
  }, [])

  const renderBodyContents = () => {
    return (
      <GenericInstrumentBody
        instrument={instrument}
        reloadKey={reloadKey}
        views={['storage', 'list']}
        defaultView='storage'
        className={cs.bodyContents}
      />
    )
  }

  const items = get('storedProcessItems', instrumentState)

  const totalSlots = flatten(items).length
  const filledSlots = compact(flatten(items)).length

  const additionalStatusText = `${filledSlots} / ${totalSlots} items`
  return (
    <div className={cx(className, cs.staticStorageInstrumentPage)}>
      <GenericInstrumentPage
        instrument={instrument}
        bodyContents={renderBodyContents()}
        status={status}
        live={live}
        additionalStatusText={additionalStatusText}
        onControlCommandComplete={handleControlCommandComplete}
        className={cs.genericInstrumentPage}
      />
    </div>
  )
}

export default StaticStorageInstrumentPage
