import cx from 'classnames'
import { get, includes } from 'lodash/fp'
import { useEffect, useState } from 'react'

import processItemsAPI from '~/api/desktop/processItems'
import Dialog from '~/components/Dialog'
import { getProcessStepDisplay } from '~/pages/Workcell/utils/processStepDisplay'
import PropTypes from '~/propTypes'
import { isRoboticArm } from '~/utils/instrument'
import {
  getLocation,
  getProcessItemLocationDisplayString,
} from '~/utils/processItems/common'
import { getProcessItemForStep } from '~/utils/processStep'

import { ProcessItem } from '~/common.interface'
import { ProcessStep } from '../../types/ProcessStep.interface'
import cs from './retry_process_step_warning.scss'

export const showRetryProcessStepDialog = step => {
  return includes(step.instrumentType, ['pf_400', 'kx_2', 'ot_2'])
}

const RetryProcessStepWarning = ({
  className,
  step,
  onCancel,
  onConfirm,
}: {
  className?: string
  step: ProcessStep
  onCancel: () => void
  onConfirm: (step: ProcessStep) => void
}) => {
  const [processItem, setProcessItem] = useState<ProcessItem | null>(null)

  const fetchAdditionalData = async () => {
    if (step.instrumentType === 'pf_400' || step.instrumentType === 'kx_2') {
      const processItemUuid = getProcessItemForStep(step)
      const response =
        await processItemsAPI.getProcessItemByUserFacingId(processItemUuid)
      setProcessItem(response.processItem)
    }
  }

  useEffect(() => {
    fetchAdditionalData()
  }, [step])

  const renderAdditionalRetryInstructions = (_step: ProcessStep) => {
    if (!_step || !processItem) return null
    const { instrumentType } = _step

    if (isRoboticArm(instrumentType)) {
      let instrumentName = 'PF-400'

      if (instrumentType === 'kx_2') {
        instrumentName = 'KX-2'
      }

      const processItemUuid = get(['uuid'], processItem)
      const location = getLocation(processItem)

      if (processItemUuid) {
        if (location) {
          const displayString = getProcessItemLocationDisplayString(location)
          return (
            <ul className={cs.instructionGroup}>
              <li>
                Please ensure <b>{processItemUuid}</b>
                &nbsp;has been ungripped by the {instrumentName} robotic arm and
                returned to&nbsp;
                <b>
                  {location.instrumentName}, {displayString}
                </b>
                &nbsp;before retrying the step.
              </li>
            </ul>
          )
        }

        return (
          <ul className={cs.instructionGroup}>
            <li>
              Please ensure <b>{processItemUuid}</b>
              &nbsp;has been ungripped by the {instrumentName} robotic arm and returned
              to the previous position before retrying the step.
            </li>
          </ul>
        )
      }

      return (
        <ul className={cs.instructionGroup}>
          <li>
            If there is a plate being gripped by the {instrumentName} robotic arm,
            please remove it.
          </li>
          <li>
            Please return the plate to the previous position before retrying the step.
          </li>
        </ul>
      )
    }

    if (instrumentType === 'ot_2') {
      // TODO(mark): Dynamically populate these instructions from the ot-2 step.
      return (
        <ul className={cs.instructionGroup}>
          <li>
            If the OT-2 method partially ran and consumed any wells or tipracks, please
            update the consumables in software or refill the real-world consumables on
            the OT-2 deck before continuing.
          </li>
        </ul>
      )
    }

    return null
  }

  return (
    <div className={cx(className, cs.retryProcessStepWarning)}>
      <Dialog.Title>Confirm Retry Step</Dialog.Title>
      <div className={cs.text}>
        Are you sure you want to retry <b>{getProcessStepDisplay(step)}</b>
        &nbsp;on <b>{step.instrumentName}</b>?
      </div>
      {renderAdditionalRetryInstructions(step)}
      <Dialog.Footer>
        <Dialog.FooterButton label='Go Back' onClick={onCancel} />
        <Dialog.FooterButton
          label='Retry'
          onClick={() => onConfirm(step)}
          type='primary'
        />
      </Dialog.Footer>
    </div>
  )
}

RetryProcessStepWarning.propTypes = {
  className: PropTypes.string,
  step: PropTypes.ProcessStep,
  onCancel: PropTypes.func,
  onConfirm: PropTypes.func,
}

export default RetryProcessStepWarning
