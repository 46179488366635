import { FutureRoutine } from '~/api/desktop/future_routine_schemas.interface'
import { client, unwrap } from '~/api/desktopAPIv2'
import { components } from './generated-schema'

export type GetConsumablesRequiredForFutureRoutinesUntilResponse =
  components['schemas']['GetConsumablesRequiredForFutureRoutinesUntilResponse']
export type ConsumableRequirements =
  | components['schemas']['ReagentPlateRequirement']
  | components['schemas']['TiprackRequirement']
  | components['schemas']['EmptyCulturePlateRequirement']
  | components['schemas']['EmptyAssayPlateRequirement']
  | components['schemas']['ExperimentRequirement']
export default {
  listFutureRoutines: (): Promise<FutureRoutine[]> =>
    unwrap(client().GET('/api/v2/future-routines/', {})),
  clearFutureRoutinesByUuids: futureRoutineUuids =>
    unwrap(
      client().POST('/api/v2/future-routines/clear-by-uuids', {
        body: {
          future_routine_uuids: futureRoutineUuids,
        },
      }),
    ),
  queueFutureRoutines: futureRoutineUuids =>
    unwrap(
      client().POST('/api/v2/future-routines/queue', {
        body: {
          future_routine_uuids: futureRoutineUuids,
        },
      }),
    ),
  manuallyUpdateScheduleForFutureRoutines: (
    futureRoutineUuids,
    newScheduledFor,
    newShouldQueueAutomatically,
  ) =>
    unwrap(
      client().POST('/api/v2/future-routines/manually-update-schedule', {
        body: {
          future_routine_uuids: futureRoutineUuids,
          new_scheduled_for: newScheduledFor,
          new_should_queue_automatically: newShouldQueueAutomatically,
        },
      }),
    ),
  getConsumablesRequiredForFutureRoutinesUntil: (untilTime: string) =>
    unwrap(
      client().POST('/api/v2/future-routines/get-consumables-required-until', {
        body: {
          until_time: untilTime,
        },
      }),
    ),
}
