import dayjs from 'dayjs'
import React, { useMemo } from 'react'

/**
 * Returns a `<span>` that displays the relative version of `timestamp`, and
 * provides the absolute timestamp in a tooltip.
 */
export default function RelativeTimestamp({
  timestamp,
  ...props
}: { timestamp: string } & React.ComponentProps<'span'>) {
  const [absoluteTime, relativeTime] = useMemo(() => {
    const date = dayjs(timestamp)
    return [date.format('MMM D, YYYY h:mm A (Z UTC)'), date.fromNow()]
  }, [timestamp])

  return (
    <span title={absoluteTime} {...props}>
      {relativeTime}
    </span>
  )
}
