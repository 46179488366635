// TODO: We can make this fancier by generating a time range, and staggering when many
// of the same operation occur (e.g. multiple imaging steps)

// TODO: We currently don't "freeze" time to noon, so e.g. images are rendered with
// various versions of "2 hours ago", "6 hours ago", etc. This makes the "Next Action"
// view look silly sometimes.

import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'

dayjs.extend(relativeTime)

export type EventKind =
  | 'CulturePassaged'
  | 'CultureImaged'
  | 'PlateCheckedIn'
  | 'PlateSeeded'
  | 'CultureMediaExchanged'
  | 'MediaLotWarmed'
  | 'UserScheduledPassages'
  | 'UserTerminatedCultures'
  | 'UserMarkedCulturesOkay'

const EVENT_KIND_TO_HOUR: { [ev in EventKind]: number } = {
  PlateCheckedIn: 8,
  PlateSeeded: 8,
  CultureImaged: 10,
  UserScheduledPassages: 12,
  UserTerminatedCultures: 12,
  UserMarkedCulturesOkay: 12,
  MediaLotWarmed: 13,
  CultureMediaExchanged: 14,
  CulturePassaged: 14,
}

// If we want to make fastForwardOneDay feel more responsive, this is one of the
// hot spots. We could probably just store the event time as a string rather than
// using dayjs here, and then only use dayjs() for rendering.
export function generateEventTime({
  eventDay,
  eventKind,
}: {
  eventDay: number
  eventKind: EventKind
}): string {
  return dayjs()
    .tz(dayjs.tz.guess())
    .add(eventDay, 'day')
    .hour(EVENT_KIND_TO_HOUR[eventKind])
    .minute(eventKind === 'MediaLotWarmed' ? 30 : 0)
    .second(0)
    .millisecond(0)
    .toISOString()
}

// TODO: This is a bit confusing. The idea is that `today` is at the end of the event log,
// and `time` was calculated via `generateEventTime` using a potentially earlier `eventDay`
export function simulatedEventTime({
  today,
  eventTime,
}: {
  today: number
  eventTime: string
}): string {
  return dayjs(eventTime).subtract(today, 'day').toISOString()
}

export function getRelativeTime(
  datetime: string,
  withoutSuffix: boolean = false,
): string {
  return dayjs(datetime).from(
    dayjs().hour(12).minute(0).second(0).millisecond(0),
    withoutSuffix,
  )
}

export function normalizeDayToTime(day: number): string {
  const remainingHours = (day * 24) % 24
  return dayjs()
    .tz('US/Pacific')
    .add(Math.floor(day), 'day')
    .hour(12 + remainingHours)
    .toISOString()
}
