import dayjs from 'dayjs'
import { get } from 'lodash/fp'
import PropTypes from 'prop-types'
import { useState } from 'react'

import Table from '~/components/Table'
import { renderStatusPill } from '~/utils/statusPill'

import Dataset from './Dataset.interface'
import DatasetViewerDialog from './DatasetViewerDialog'

const STATUS_PILL_CONFIG = {
  invalid: { type: 'error' },
  ready: { type: 'warning' },
  waiting_for_plate_metadata: { type: 'default', label: 'waiting for plate' },
  in_progress: { type: 'default', label: 'processing' },
  uploaded: { type: 'success' },
  uploading: { type: 'accent' },
  upload_failed: { type: 'error' },
}

interface DatasetTableProps {
  className?: string
  datasets: Dataset[]
  hasMoreData: boolean
  fetchMoreData: () => void
}

const DatasetTable = ({
  className,
  datasets,
  hasMoreData,
  fetchMoreData,
}: DatasetTableProps) => {
  const [selectedDataset, setSelectedDataset] = useState<Dataset | null>(null)
  const [datasetViewerDialogOpen, setDatasetViewerDialogOpen] = useState(false)

  const handleRowClick = row => {
    setSelectedDataset(row)
    setDatasetViewerDialogOpen(true)
  }

  const renderStatus = status => {
    return renderStatusPill(status, STATUS_PILL_CONFIG, {
      small: true,
    })
  }

  const renderBarcode = row => {
    if (!row.metadata) return null
    if (row.instrumentType === 'cytation') {
      if (row.metadata.usesNewDatasetProcessing) {
        return row.metadata?.plateMetadata?.uuid
      }
      return row.metadata.scannedBarcode
    }
    if (row.instrumentType === 'flex_2') {
      return row.metadata.entityName
    }
    if (row.instrumentType === 'azenta_perception_hd') {
      return row.metadata.rackBarcode
    }
    return null
  }

  const renderFilePath = row => {
    if (!row.metadata) return null
    if (row.metadata.usesNewDatasetProcessing) {
      return row.metadata?.initialDetectedFileMetadata?.filePath
    }
    return row.metadata.filePath
  }

  const tableColumns = [
    {
      name: 'Name',
      width: 200,
      render: get('name'),
      oneLine: true,
      smallText: true,
    },
    {
      name: 'Status',
      width: 150,
      render: row => renderStatus(get(['status'], row)),
    },
    {
      name: 'Timestamp',
      width: 125,
      render: row => dayjs(get(['createdAt'], row)).fromNow(),
      smallText: true,
    },
    {
      name: 'Barcode',
      width: 125,
      render: renderBarcode,
      smallText: true,
    },
    {
      name: 'File Path',
      width: 150,
      render: row => renderFilePath(row),
      smallText: true,
      oneLine: true,
    },
  ]

  return (
    <>
      <Table
        columns={tableColumns}
        data={datasets}
        className={className}
        rowKey='id'
        rowPaddingVariant='rowPaddingLow'
        onRowClick={handleRowClick}
        infiniteScrollProps={{
          hasMoreData,
          fetchMoreData,
        }}
      />
      <DatasetViewerDialog
        isOpen={datasetViewerDialogOpen}
        onClose={() => setDatasetViewerDialogOpen(false)}
        datasetId={get('id', selectedDataset)}
      />
    </>
  )
}

DatasetTable.propTypes = {
  className: PropTypes.string,
  datasets: PropTypes.arrayOf(PropTypes.shape({})),
  hasMoreData: PropTypes.bool,
  fetchMoreData: PropTypes.func,
}

export default DatasetTable
