import { useEffect, useState } from 'react'

import roboticArmAPI, {
  SequenceValidationRunParams,
  SequenceValidationRunStatus,
  SuccessResponse,
  TeachToolSeqFile,
  ValidateSequenceValidationRunParamsResponse,
} from '~/api/desktop/drivers/roboticArm'
import SequenceFileInlineDialog from './SequenceFileInlineDialog'
import SequenceValidationForm, {
  SequenceValidationFormValues,
  shouldAcceptRepeatParam,
} from './SequenceValidationForm'
import { SequenceValidationRunParamsOrError } from './SequenceValidationRunParamsOrError.interface'
import SequenceValidationRunStatusView from './SequenceValidationRunStatusView'
import ValidatePanelFooter from './ValidatePanelFooter'
import { sequenceRequiresStorageLocationParam } from './storageLocationParam'
import cs from './validate_panel.scss'

interface ValidatePanelProps {
  instrumentName: string
  isTeachToolBusy: boolean
  validatePanelBusyWrapper: (func: () => Promise<SuccessResponse>) => void
  sequenceValidationRunStatus: SequenceValidationRunStatus | null
}

const ValidatePanel = ({
  instrumentName,
  isTeachToolBusy,
  validatePanelBusyWrapper,
  sequenceValidationRunStatus,
}: ValidatePanelProps) => {
  const [formParams, setFormParams] = useState<SequenceValidationFormValues>({
    run_params: {
      start_on_step: 1,
      repeat: 1,
    },
  })
  const [seqFileToDisplay, setSeqFileToDisplay] = useState<null | TeachToolSeqFile>(
    null,
  )
  const [sequenceValidationRunParamsOrError, setSequenceValidationRunParamsOrError] =
    useState<SequenceValidationRunParamsOrError | null>(null)

  const prevalidateAndReturnError = () => {
    if (!formParams.run_params.forward_sequence_file_name) {
      return 'Please select forward sequence.'
    }
    if (!formParams.run_params.start_on_step) {
      return 'Please enter start on step.'
    }
    if (
      formParams.forward_seq_file &&
      sequenceRequiresStorageLocationParam(formParams.forward_seq_file) &&
      !formParams.run_params.storage_location
    ) {
      return 'Please enter storage location.'
    }
    if (
      shouldAcceptRepeatParam(formParams) &&
      formParams.run_params.repeat === undefined
    ) {
      return 'Please enter number of repeats'
    }
    return null
  }

  const validateParams = async (params: Partial<SequenceValidationRunParams>) => {
    const error = prevalidateAndReturnError()

    if (error) {
      setSequenceValidationRunParamsOrError({ error })
      return
    }

    let response: null | ValidateSequenceValidationRunParamsResponse = null

    try {
      response = await roboticArmAPI.validateSequenceValidationRunParams(
        instrumentName,
        params as SequenceValidationRunParams,
      )
    } catch (e) {
      setSequenceValidationRunParamsOrError({ error: 'Parameter validation failed.' })
      return
    }

    if (response.result.error) {
      setSequenceValidationRunParamsOrError({ error: response.result.error })
      return
    }

    setSequenceValidationRunParamsOrError({
      sequenceValidationRunParams: params as SequenceValidationRunParams,
    })
  }

  const renderRightPane = () => {
    if (seqFileToDisplay) {
      return (
        <SequenceFileInlineDialog
          seqFile={seqFileToDisplay}
          onClose={() => setSeqFileToDisplay(null)}
          className={cs.inlineDialog}
        />
      )
    }

    if (sequenceValidationRunStatus) {
      return (
        <SequenceValidationRunStatusView
          sequenceValidationRunStatus={sequenceValidationRunStatus}
        />
      )
    }
  }

  // Assumes that validateParams has passed.
  const handleSubmit = async () => {
    validatePanelBusyWrapper(() =>
      roboticArmAPI.runSequenceValidation(
        instrumentName,
        formParams.run_params as SequenceValidationRunParams,
      ),
    )
  }

  useEffect(() => {
    validateParams(formParams.run_params)
  }, [formParams])

  return (
    <div className={cs.validatePanel}>
      <div className={cs.body}>
        <SequenceValidationForm
          className={cs.form}
          instrumentName={instrumentName}
          formParams={formParams}
          setFormParams={setFormParams}
          onViewSeqFile={setSeqFileToDisplay}
          isTeachToolBusy={isTeachToolBusy}
        />
        <div className={cs.rightPane}>{renderRightPane()}</div>
      </div>

      <ValidatePanelFooter
        sequenceValidationParamsOrError={sequenceValidationRunParamsOrError}
        onSubmit={handleSubmit}
        isTeachToolBusy={isTeachToolBusy}
      />
    </div>
  )
}

export default ValidatePanel
