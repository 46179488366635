import cx from 'classnames'

import ProcessItemList from '~/pages/Workcell/components/processItem/ProcessItemList'
import cs from './consumables.scss'

interface ConsumablesProps {
  className?: string
  searchValue: string
  showItemsWithoutLocation: boolean
}

const Consumables = ({
  className,
  searchValue,
  showItemsWithoutLocation,
}: ConsumablesProps) => {
  return (
    <div className={cx(className, cs.consumables)}>
      <ProcessItemList
        className={cs.table}
        searchValue={searchValue}
        showItemsWithoutLocation={showItemsWithoutLocation}
        isCheckedIn
        consumablesOrSamples='consumables'
        canEdit
      />
    </div>
  )
}

export default Consumables
