import cx from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'

import cs from './status_message.scss'

interface StatusMessageProps {
  className?: string
  message: React.ReactNode
}

const StatusMessage = ({ className, message }: StatusMessageProps) => {
  return <div className={cx(className, cs.statusMessage)}>{message}</div>
}

StatusMessage.propTypes = {
  className: PropTypes.string,
  message: PropTypes.node,
}

export default StatusMessage
