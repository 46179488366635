import { useEffect, useState } from 'react'

import { includes } from 'lodash/fp'
import { LoadUnloadItemTiprackParams } from '~/pages/Workcell/OperatorActions/LoadUnloadItems/LoadUnloadItemsProcessItemParams.interface'
import { getNumWells } from '~/utils/microplate'
import { TiprackLoadParamsRequest } from '../../reloadItems/convertChangeToReloadOperation'
import { LabwareSelect } from '../LabwareSelect'
import { LoadParamsRequestOrError } from '../LoadParamsRequestOrError.interface'
import { LoadUnloadItemsProcessItemFormComponentProps } from './LoadUnloadItemsProcessItemFormComponentProps.interface'
import RightPackedConsumableWellSelect from './RightPackedConsumableWellSelect'
import cs from './load_unload_items_tiprack_form.scss'

const LoadUnloadItemsTiprackForm = ({
  className,
  config,
  processItemParams,
  setProcessItemParams,
  disabled,
}: LoadUnloadItemsProcessItemFormComponentProps<'tiprack'>) => {
  const [lastSelectedLabwareName, setLastSelectedLabwareName] = useState<
    string | undefined
  >(undefined)

  useEffect(() => {
    if (processItemParams.selectedLabware) {
      setProcessItemParams(
        (_latestProcessItemParams: Partial<LoadUnloadItemTiprackParams>) => ({
          ..._latestProcessItemParams,
          ...(_latestProcessItemParams.selectedLabware?.plate_format
            ? {
                initialNumWells: getNumWells(
                  _latestProcessItemParams.selectedLabware.plate_format,
                ),
              }
            : {}),
        }),
      )
    }
  }, [processItemParams.selectedLabware])

  return (
    <div className={className}>
      <LabwareSelect
        processItemType='tiprack'
        className={cs.labwareSelect}
        defaultLabwareName={lastSelectedLabwareName}
        selectedLabware={processItemParams.selectedLabware}
        onLabwareSelect={labware => {
          setLastSelectedLabwareName(labware?.labware_name)
          setProcessItemParams(
            (_latestProcessItemParams: Partial<LoadUnloadItemTiprackParams>) => ({
              ..._latestProcessItemParams,
              selectedLabware: labware,
            }),
          )
        }}
        triggerClassName={cs.trigger}
        popoverClassName={cs.popover}
        disabled={disabled}
      />
      {includes('tiprack', config.itemTypesWithSelectableWells) && (
        <div className={cs.selectWellsContainer}>
          <RightPackedConsumableWellSelect
            className={cs.wellSelect}
            processItemParams={processItemParams}
            setProcessItemParams={newParams => {
              setProcessItemParams(
                (_latestProcessItemParams: Partial<LoadUnloadItemTiprackParams>) => ({
                  ..._latestProcessItemParams,
                  ...newParams,
                }),
              )
            }}
          />
        </div>
      )}
    </div>
  )
}

export const getTiprackLoadParamsRequestOrError = (
  processItemParams: Partial<LoadUnloadItemTiprackParams>,
): LoadParamsRequestOrError => {
  if (!processItemParams.selectedLabware) {
    return {
      error: 'Please select a labware.',
    }
  }
  return {
    params: {
      labware_name: processItemParams.selectedLabware.labware_name,
      initial_num_wells: processItemParams.initialNumWells,
    } as TiprackLoadParamsRequest,
  }
}

export default LoadUnloadItemsTiprackForm
