import cx from 'classnames'
import Notification from '~/components/notifications/Notification'
import cs from './link_lots_unknown_error.scss'

export interface LinkLotsUnknownErrorProps {
  className?: string
}

const LinkLotsUnknownError = ({ className }: LinkLotsUnknownErrorProps) => {
  return (
    <Notification
      className={cx(cs.linkLotsUnknownError, className)}
      variant='mini'
      type='error'
      label='An unknown error has occurred'
    />
  )
}

export default LinkLotsUnknownError
