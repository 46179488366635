import { useEffect, useState } from 'react'

// Note that some data fetching libraries may return shapes like {isLoading: true; data: TData}
// when re-fetching, but still wanting to show users the last known data.
export type UseFetchDataResult<TData> =
  | { isLoading: true; data: TData | null; error: null }
  | { isLoading: false; data: TData; error: null }
  | { isLoading: false; data: null; error: string }

export function useFetchData<TData>(
  fetch: () => Promise<TData>,
  // NOTE(damon): `deps` must be stable. I'm not sure why useMemo is required in the current usage
  // This can be dangerous, because if React thinks this is changing with every render (e.g.
  // because we ping workcell status, and we recompute this dependency list), this will lead to
  // infinitely re-fetching
  deps?: React.DependencyList,
): UseFetchDataResult<TData> {
  const [isLoading, setIsLoading] = useState(true)
  const [data, setData] = useState<TData | null>(null)
  const [error, setError] = useState<string | null>(null)

  useEffect(() => {
    async function doFetch() {
      setIsLoading(true)
      setError(null)
      try {
        const result = await fetch()
        setData(result)
        setIsLoading(false)
      } catch (e) {
        setError(String(e))
        setIsLoading(false)
      }
    }

    doFetch()
  }, deps)

  if (isLoading) {
    return {
      isLoading: true,
      data,
      error: null,
    }
  }

  if (error) {
    return {
      isLoading: false,
      data: null,
      error,
    }
  }

  return { isLoading: false, data: data as TData, error: null }
}
