import cx from 'classnames'
import PropTypes from 'prop-types'
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom'

import Tabs from '~/components/Tabs'
import useTabsWithUrl from '~/utils/hooks/useTabsWithUrlPart'

import EventHandlers from './EventHandlers'
import EventLog from './EventLog'
import JobLog from './JobLog'
import Jobs from './Jobs'
import Slack from './Slack'
import cs from './integrations.scss'

const TABS = [
  {
    key: 'event-log',
    title: 'Event Log',
  },
  {
    key: 'event-handlers',
    title: 'Event Handlers',
  },
  {
    key: 'job-log',
    title: 'Job Log',
  },
  {
    key: 'jobs',
    title: 'Jobs',
  },
  {
    key: 'slack',
    title: 'Slack',
  },
]

const Integrations = ({ className }) => {
  const match = useRouteMatch()

  const [tabs, selectedTabId, handleTabChange] = useTabsWithUrl(TABS)

  return (
    <div className={cx(className, cs.integrations)}>
      <Tabs
        tabs={tabs}
        selectedTabId={selectedTabId}
        className={cs.tabs}
        panelClassName={cs.tabPanel}
        onlyRenderWhenViewed
        empty
        nested
        onTabChange={handleTabChange}
      />
      <Switch>
        <Route
          exact
          path={match.path}
          render={() => <Redirect to={`${match.path}/event-log`} />}
        />
        <Route path={`${match.path}/event-handlers`} render={() => <EventHandlers />} />
        <Route path={`${match.path}/event-log`} render={() => <EventLog />} />
        <Route path={`${match.path}/jobs`} render={() => <Jobs />} />
        <Route path={`${match.path}/job-log`} render={() => <JobLog />} />
        <Route path={`${match.path}/slack`} render={() => <Slack />} />
      </Switch>
    </div>
  )
}

Integrations.propTypes = {
  className: PropTypes.string,
}

export default Integrations
