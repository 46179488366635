import PropTypes from 'prop-types'
import { IconProps } from './types/IconProps'
const MicroplateIcon = ({ className, onClick }: IconProps) => (
  <svg
    width='22'
    height='17'
    viewBox='0 0 22 17'
    xmlns='http://www.w3.org/2000/svg'
    className={className}
    onClick={onClick}
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M2.7228 0C1.21904 0 0 1.21904 0 2.7228V14.2772C0 15.781 1.21904 17 2.7228 17H19.0256C20.5293 17 21.7484 15.781 21.7484 14.2772V2.7228C21.7484 1.21904 20.5293 0 19.0256 0H2.7228ZM3.31913 1.68536C2.41687 1.68536 1.68545 2.41678 1.68545 3.31904V13.5796C1.68545 14.4818 2.41688 15.2133 3.31913 15.2133H18.4293C19.3315 15.2133 20.063 14.4818 20.063 13.5796V3.31904C20.063 2.41678 19.3315 1.68536 18.4293 1.68536H3.31913ZM3.72738 3.72733H7.27936V7.27932H3.72738V3.72733ZM12.7591 3.72733H9.20708V7.27932H12.7591V3.72733ZM14.6868 3.72733H18.2388V7.27932H14.6868V3.72733ZM7.27936 9.41494H3.72738V12.9669H7.27936V9.41494ZM9.20708 9.41494H12.7591V12.9669H9.20708V9.41494ZM18.2388 9.41494H14.6868V12.9669H18.2388V9.41494Z'
    />
  </svg>
)

MicroplateIcon.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
}

export default MicroplateIcon
