import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom'

import PlatePage from '../plate/PlatePage'
import WellPage from '../plate/well/WellPage'
import ClePipelineView from './ClePipelineView'
import HitpickingWorkflow from './HitpickingWorkflow'
import cs from './cle_demo.scss'

const Cle2025Demo = ({ useLocalAssets }: { useLocalAssets: boolean }) => {
  const match = useRouteMatch()

  return (
    <div className={cs.cleDemo}>
      <Switch>
        <Route
          exact
          path={match.path}
          render={() => <Redirect to={`${match.path}/pipeline`} />}
        />
        <Route
          exact
          path={`${match.path}/pipeline`}
          render={() => <ClePipelineView useLocalAssets={useLocalAssets} />}
        />
        <Route
          exact
          path={`${match.path}/decisions/hitpicking/:cellLineName`}
          render={({
            match: {
              params: { cellLineName },
            },
          }) => (
            <HitpickingWorkflow
              cellLineName={cellLineName}
              useLocalAssets={useLocalAssets}
            />
          )}
        />
        <Route
          exact
          path={`${match.path}/plate/:id`}
          render={({
            match: {
              params: { id },
            },
          }) => <PlatePage id={id} enableSelection={true} routePrefix={match.path} />}
        />

        <Route
          exact
          path={`${match.path}/plate/:id/well/:pos`}
          render={({
            match: {
              params: { id, pos },
            },
          }) => (
            <WellPage
              plateID={id}
              pos={pos}
              enableLineage={true}
              routePrefix={match.path}
            />
          )}
        />
      </Switch>
    </div>
  )
}

export default Cle2025Demo
