import { compact, find, last, sortBy } from 'lodash/fp'
import { useState } from 'react'
import { useHistory, useRouteMatch } from 'react-router-dom'

import workcellAPI from '~/api/desktop/workcell'
import { Instrument } from '~/common.interface'
import { getUrlAfter } from '~/utils/url'

export function useOperatorActionInstrumentLegacy(onInstrumentSelect) {
  const history = useHistory()
  const match = useRouteMatch()
  const [instruments, setInstruments] = useState<Instrument[] | null>(null)
  const [selectedInstrument, setSelectedInstrument] = useState<Instrument | null>(null)

  const handleSelectInstrument = instrument => {
    if (onInstrumentSelect) {
      onInstrumentSelect()
    }
    history.push(`${match.path}/${instrument.instrumentName}`)
    setSelectedInstrument(instrument)
  }

  const fetchInstruments = async config => {
    if (config && config.instrumentNames) {
      let _instruments = await workcellAPI.getInstruments({
        instrumentNames: config.instrumentNames,
      })

      _instruments = sortBy(_instrument => _instrument.instrumentName, _instruments)
      setInstruments(_instruments)

      if (_instruments && _instruments.length > 0) {
        const lastPart = getUrlAfter(match.path)
        if (lastPart) {
          const instrumentName = last(compact(lastPart.split('/')))
          const instrument = find(['instrumentName', instrumentName], _instruments)
          if (instrument) {
            handleSelectInstrument(instrument)
            return
          }
        }
        handleSelectInstrument(_instruments[0])
      }
    }
  }

  return [instruments, selectedInstrument, fetchInstruments, handleSelectInstrument]
}

export default function useOperatorActionInstrument(onInstrumentSelect) {
  const history = useHistory()
  const match = useRouteMatch()
  const [instruments, setInstruments] = useState<Instrument[] | null>(null)
  const [selectedInstrument, setSelectedInstrument] = useState<Instrument | null>(null)

  const handleSelectInstrument = instrument => {
    if (onInstrumentSelect) {
      onInstrumentSelect()
    }
    history.push(`${match.path}/${instrument.instrumentName}`)
    setSelectedInstrument(instrument)
  }

  const fetchInstruments = async config => {
    if (config && config.instrumentNames) {
      let _instruments = await workcellAPI.getInstruments({
        instrumentNames: config.instrumentNames,
      })

      _instruments = sortBy(_instrument => _instrument.instrumentName, _instruments)
      setInstruments(_instruments)

      if (_instruments && _instruments.length > 0) {
        const lastPart = getUrlAfter(match.path)
        if (lastPart) {
          const instrumentName = last(compact(lastPart.split('/')))
          const instrument = find(['instrumentName', instrumentName], _instruments)
          if (instrument) {
            handleSelectInstrument(instrument)
            return
          }
        }
        handleSelectInstrument(_instruments[0])
      }
    }
  }

  return {
    instruments,
    selectedInstrument,
    fetchInstruments,
    handleSelectInstrument,
  }
}
