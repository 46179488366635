// Get the default plate map if it exists and matches the plate formats.

import { find } from 'lodash/fp'
import { supportedPlateFormats } from '~/components/TinyMicroplate.interface'
import { ExperimentPlateMap } from '~/pages/Workcell/ProcessItems/PlateMap'

// Otherwise get the first plate map matching the plate formats.
export const getMatchingPlateMapWithDefault = (
  plateMaps: ExperimentPlateMap[],
  fromFormat: supportedPlateFormats,
  toFormat: supportedPlateFormats,
  defaultName: string,
): ExperimentPlateMap | undefined => {
  const defaultPlateMap = find(['name', defaultName], plateMaps)

  if (
    defaultPlateMap &&
    defaultPlateMap.from_format === fromFormat &&
    defaultPlateMap.to_format === toFormat
  ) {
    return defaultPlateMap
  }

  const matchingPlateMap = find(
    plateMap => plateMap.from_format === fromFormat && plateMap.to_format === toFormat,
    plateMaps,
  )

  if (matchingPlateMap) {
    return matchingPlateMap
  }

  return undefined
}
