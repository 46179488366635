import cx from 'classnames'
import { useContext } from 'react'
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom'

import Tabs from '~/components/Tabs'
import useTabsWithUrl from '~/utils/hooks/useTabsWithUrlPart'

import WorkcellStatusContext from '../WorkcellStatusContext'
import { experimentalEnableWorkflows } from '../utils/workcellStatus'
import CustomScripts from './CustomScripts'
import RoutineDefinitions from './RoutineDefinitions'
import WorkflowDefinitions from './WorkflowDefinitions'
import cs from './protocols.scss'

const Protocols = ({ className }: { className?: string }) => {
  const match = useRouteMatch()
  const workcellStatus = useContext(WorkcellStatusContext)
  const workflowsEnabled = experimentalEnableWorkflows(workcellStatus)

  const TABS = [
    {
      key: 'routine-definitions',
      title: 'Routine Definitions',
    },
    {
      key: 'custom-scripts',
      title: 'Custom Scripts',
    },
    ...(workflowsEnabled
      ? [{ key: 'workflow-definitions', title: 'Workflow Definitions' }]
      : []),
  ]

  const [tabs, selectedTabId, handleTabChange] = useTabsWithUrl(TABS)

  return (
    <div className={cx(className, cs.protocols)}>
      <Tabs
        tabs={tabs}
        selectedTabId={selectedTabId}
        className={cs.tabs}
        panelClassName={cs.tabPanel}
        onlyRenderWhenViewed
        empty
        nested
        onTabChange={handleTabChange}
      />
      <Switch>
        <Route
          exact
          path={match.path}
          render={() => <Redirect to={`${match.path}/routine-definitions`} />}
        />
        <Route
          path={`${match.path}/routine-definitions`}
          render={() => <RoutineDefinitions />}
        />
        <Route
          path={`${match.path}/workflow-definitions`}
          render={() => <WorkflowDefinitions />}
        />
        <Route path={`${match.path}/custom-scripts`} render={() => <CustomScripts />} />
      </Switch>
    </div>
  )
}

export default Protocols
