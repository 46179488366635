import { find, findIndex, includes, reject } from 'lodash/fp'
import { useEffect, useState } from 'react'

import integrationsAPIv2, { SlackIntegration } from '~/api/desktop/integrations'
import workcellAPI from '~/api/desktop/workcell'
import { WorkcellStatus } from '~/api/desktop/workcell.interface'
import slackLogo from '~/assets/images/slack_logo.svg'
import Pill, { PillType } from '~/components/Pill'
import Button from '~/components/buttons/Button'
import { UserMetadata, getUserIsAdmin } from '~/core/MultiFrontendContext'
import ConnectSlackDialog from '~/pages/IntegrationsDashboard/ConnectSlackDialog'
import DeprecatedIntegrationsDashboard from '~/pages/IntegrationsDashboard/DeprecatedIntegrationsDashboard'
import { useNativeSlackIntegration } from '~/pages/Workcell/utils/workcellStatus'
import ConfigureSlackDialog from './ConfigureSlackDialog'
import cs from './integrations_dashboard.scss'

const IntegrationsDashboard = ({
  enabledIntegrations,
  userMetadata,
}: { enabledIntegrations: string[]; userMetadata: UserMetadata }) => {
  // Note: This will be removed once we've fully migrated to using the native slack integration
  const [workcellStatus, setWorkcellStatus] = useState<WorkcellStatus>()
  const updateWorkcellStatus = async () => {
    const status = await workcellAPI.status()
    if ('workcell_services_failed_to_initialize' in status) {
      setWorkcellStatus(undefined)
    } else {
      setWorkcellStatus(status as WorkcellStatus)
    }
  }

  // Note: This is designed to support additional integration types. For now, SlackIntegration
  // is the only type which exists.
  type Integration = SlackIntegration

  const [integrations, setIntegrations] = useState<Integration[]>([])
  const [connectSlackOpen, setConnectSlackOpen] = useState(false)
  const [configureSlackOpen, setConfigureSlackOpen] = useState(false)
  const [loading, setLoading] = useState(true)
  const userIsOrgAdmin = getUserIsAdmin(userMetadata)

  const refreshIntegrations = async () => {
    const integrations = await integrationsAPIv2.getIntegrations()
    setIntegrations(integrations)
  }

  const updateIntegration = (integrationToUpdate: Integration) => {
    const targetIndex = findIndex(['uuid', integrationToUpdate.uuid], integrations)
    if (targetIndex === -1) {
      setIntegrations([...integrations, integrationToUpdate])
    } else {
      const newIntegrations = integrations.map((integration, idx) => {
        if (idx == targetIndex) {
          return integrationToUpdate
        } else {
          return integration
        }
      })
      setIntegrations(newIntegrations)
    }
  }

  const updateSlackIntegration = (newSlackIntegration: SlackIntegration) => {
    updateIntegration(newSlackIntegration)
  }

  const deleteSlackIntegration = (integrationId: string) => {
    setIntegrations(reject(['uuid', integrationId], integrations))
  }

  const initialize = async () => {
    setLoading(true)
    updateWorkcellStatus()
    refreshIntegrations()
    setLoading(false)
  }

  useEffect(() => {
    initialize()
  }, [])

  const renderSlackPill = () => {
    const slackIntegration = find(['type', 'slack'], integrations)
    let label = 'Not Connected'
    let type: PillType = 'inactive'

    if (slackIntegration) {
      const slackSettings = slackIntegration.settings
      if (slackSettings && !slackSettings.channel) {
        label = 'Configuration Required'
        type = 'warning'
      } else {
        label = `Connected: ${slackSettings?.team_name} ${slackSettings?.channel}`
        type = 'success'
      }
    }

    return <Pill label={label} type={type} small className={cs.pill} />
  }

  const hasIntegration = integration => {
    return includes(integration, enabledIntegrations)
  }

  // Handle case where integration does not exist.
  const slackIntegration = find(['type', 'slack'], integrations) as SlackIntegration

  const renderSlackIntegration = () => {
    return (
      <div className={cs.integration}>
        <img src={slackLogo} className={cs.logo} alt='Slack Logo' />
        <div className={cs.text}>
          <div className={cs.name}>
            Slack
            {renderSlackPill()}
          </div>
          <div className={cs.description}>
            Receive updates from your lab instruments in Slack.
          </div>
          {userIsOrgAdmin && (
            <div className={cs.controls}>
              {slackIntegration ? (
                <Button
                  type='normal'
                  label='Configure'
                  onClick={() => setConfigureSlackOpen(true)}
                  className={cs.button}
                />
              ) : (
                <Button
                  type='normal'
                  label='Link Slack Team'
                  // Note: Eventually, this should go through the Slack OAuth flow. In practice
                  // today, we end up sharing the same dev settings for all on-premise deploys.
                  // So a simple dialog box will suffice.
                  onClick={() => {
                    setConnectSlackOpen(true)
                  }}
                />
              )}
            </div>
          )}
        </div>
      </div>
    )
  }

  const renderList = () => {
    if (loading) {
      return <div className={cs.bigMessage}>Loading integrations...</div>
    }
    if (!useNativeSlackIntegration(workcellStatus))
      return (
        <DeprecatedIntegrationsDashboard
          enabledIntegrations={enabledIntegrations}
          userMetadata={userMetadata}
        />
      )
    return (
      <div className={cs.integrations}>
        {hasIntegration('slack') && renderSlackIntegration()}
        <ConfigureSlackDialog
          open={configureSlackOpen}
          onClose={() => setConfigureSlackOpen(false)}
          integration={slackIntegration}
          onIntegrationUpdate={updateSlackIntegration}
          onIntegrationDelete={deleteSlackIntegration}
        />
        <ConnectSlackDialog
          open={connectSlackOpen}
          onClose={() => setConnectSlackOpen(false)}
          onIntegrationUpdate={updateSlackIntegration}
        />
      </div>
    )
  }

  return (
    <div className={cs.integrationsDashboard}>
      <div className={cs.title}>Integrations</div>
      {renderList()}
    </div>
  )
}

IntegrationsDashboard.propTypes = {}

export default IntegrationsDashboard
