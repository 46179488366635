import cx from 'classnames'
import { useState } from 'react'
import { ProcessItem } from '~/common.interface'
import { supportedSize } from '~/components/TinyMicroplate.interface'
import EditIcon from '~/components/icons/EditIcon'
import EditProcessItemDialog from './EditProcessItemDialog'
import ProcessItemViz from './ProcessItemViz'
import cs from './editable_process_item_viz.scss'

interface EditableProcessItemVizProps {
  className?: string
  processItem?: ProcessItem
  // Additional well to highlight
  numWellsToHighlight?: number
  size?: supportedSize
  handleEdit: (processItem: ProcessItem) => void
  showOverlay?: boolean
}

const EditableProcessItemViz = ({
  className,
  processItem,
  numWellsToHighlight,
  size,
  handleEdit,
  showOverlay,
}: EditableProcessItemVizProps) => {
  const [editDialogOpen, setEditDialogOpen] = useState(false)
  return (
    <>
      <div className={cx(cs.editableProcessItemViz, className)}>
        <div className={cs.inner} onClick={() => setEditDialogOpen(true)}>
          <div className={cx(showOverlay && cs.show, cs.overlay)}>
            <div className={cs.overlayMessage}>
              <EditIcon className={cs.icon} />
            </div>
          </div>
          <ProcessItemViz
            className={className}
            processItem={processItem}
            numWellsToHighlight={numWellsToHighlight}
            size={size}
          />
        </div>
      </div>

      <EditProcessItemDialog
        isOpen={editDialogOpen}
        onClose={() => setEditDialogOpen(false)}
        onEdit={handleEdit}
        processItem={processItem}
      />
    </>
  )
}

export default EditableProcessItemViz
