import cx from 'classnames'
import { get, includes } from 'lodash/fp'
import { useEffect, useState } from 'react'

import processItemsAPI from '~/api/desktop/processItems'
import Dialog from '~/components/Dialog'
import { getProcessStepDisplay } from '~/pages/Workcell/utils/processStepDisplay'
import PropTypes from '~/propTypes'
import { isRoboticArm } from '~/utils/instrument'
import { getProcessItemForStep } from '~/utils/processStep'

import { ProcessStep } from '../../types/ProcessStep.interface'
import cs from './cancel_process_step_warning.scss'

export const showCancelProcessStepDialog = step => {
  return includes(step.instrumentType, ['pf_400', 'kx_2', 'ot_2'])
}

const CancelProcessStepWarning = ({
  className,
  step,
  onCancel,
  onConfirm,
}: {
  className?: string
  step: ProcessStep
  onCancel: () => void
  onConfirm: (step: ProcessStep) => void
}) => {
  const [processItem, setProcessItem] = useState(null)

  const fetchAdditionalData = async () => {
    if (isRoboticArm(step.instrumentType)) {
      const processItemUuid = getProcessItemForStep(step)
      const response =
        await processItemsAPI.getProcessItemByUserFacingId(processItemUuid)
      setProcessItem(response.processItem)
    }
  }

  useEffect(() => {
    fetchAdditionalData()
  }, [step])

  const renderAdditionalCancelInstructions = (_step: ProcessStep) => {
    if (!_step) return null
    const { instrumentType } = _step

    if (isRoboticArm(instrumentType)) {
      let instrumentName = 'PF-400'

      if (instrumentType === 'kx_2') {
        instrumentName = 'KX-2'
      }

      const processItemUuid = get(['uuid'], processItem)

      if (processItemUuid) {
        return (
          <ul className={cs.instructionGroup}>
            <li>
              Please ensure <b>{processItemUuid}</b>
              &nbsp;has been ungripped by the {instrumentName} robotic arm.
            </li>
            <li>
              Please ensure <b>{processItemUuid}</b> has been manually moved to the next
              position in the real-world and updated in the software before continuing.
            </li>
          </ul>
        )
      }

      return (
        <ul className={cs.instructionGroup}>
          <li>
            If there is a plate being gripped by the {instrumentName} robotic arm,
            please remove it.
          </li>
          <li>
            Please ensure the plate has been manually moved to the next position in the
            real-world and updated in the software before continuing.
          </li>
        </ul>
      )
    }

    if (instrumentType === 'ot_2') {
      // TODO(mark): Dynamically populate these instructions from the ot-2 step.
      return (
        <ul className={cs.instructionGroup}>
          <li>
            If the OT-2 method partially ran and consumed any wells or tipracks, please
            update the consumables in software or refill the real-world consumables on
            the OT-2 deck before continuing.
          </li>
        </ul>
      )
    }

    return null
  }

  return (
    <div className={cx(className, cs.cancelProcessStepWarning)}>
      <Dialog.Title>Confirm Cancel Step</Dialog.Title>
      <div className={cs.text}>
        Are you sure you want to cancel <b>{getProcessStepDisplay(step)}</b>
        &nbsp;on <b>{step.instrumentName}</b>?
      </div>
      {renderAdditionalCancelInstructions(step)}
      <Dialog.Footer>
        <Dialog.FooterButton label='Go Back' onClick={onCancel} />
        <Dialog.FooterButton
          label='Confirm'
          onClick={() => onConfirm(step)}
          type='danger'
        />
      </Dialog.Footer>
    </div>
  )
}

CancelProcessStepWarning.propTypes = {
  className: PropTypes.string,
  step: PropTypes.ProcessStep,
  onCancel: PropTypes.func,
  onConfirm: PropTypes.func,
}

export default CancelProcessStepWarning
