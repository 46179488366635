import PropTypes from 'prop-types'
import { IconProps } from './types/IconProps'
const UserIcon = ({ className, onClick }: IconProps) => (
  <svg
    width='16'
    height='16'
    viewBox='0 0 16 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    onClick={onClick}
    className={className}
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M8 0C3.584 0 0 3.584 0 8C0 3.59 3.59 0 8 0ZM16 8C16 3.584 12.416 0 8 0C12.41 0 16 3.59 16 8ZM16 8C16 9.82 15.38 11.49 14.36 12.83C12.93 11.09 9.46 10.5 8 10.5C6.54 10.5 3.07 11.09 1.64 12.83C0.62 11.49 0 9.82 0 8C0 12.3729 3.51438 15.93 7.87093 15.999C6.06093 15.9701 4.39848 15.335 3.07 14.28C3.5 13.38 6.12 12.5 8 12.5C9.88 12.5 12.51 13.38 12.93 14.28C11.5952 15.34 9.92332 15.9761 8.10335 15.9993C12.4718 15.9439 16 12.3815 16 8ZM8 2C6.06 2 4.5 3.56 4.5 5.5C4.5 7.44 6.06 9 8 9C9.94 9 11.5 7.44 11.5 5.5C11.5 3.56 9.94 2 8 2ZM6.5 5.5C6.5 6.33 7.17 7 8 7C8.83 7 9.5 6.33 9.5 5.5C9.5 4.67 8.83 4 8 4C7.17 4 6.5 4.67 6.5 5.5Z'
    />
  </svg>
)

UserIcon.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
}

export default UserIcon
