export const getDesktopDomainDefault = () => {
  return process.env.MONOMER_DESKTOP_DEFAULT_ORIGIN
}

export const getDesktopDomain = () => {
  const config = JSON.parse(
    window.localStorage.getItem('localWorkcellConfig') ?? 'null',
  )
  const networkAddress = config?.networkAddress || getDesktopDomainDefault()

  // Assume http if none is provided.
  if (!networkAddress.startsWith('http://') && !networkAddress.startsWith('https://')) {
    return `http://${networkAddress}`
  }
  return networkAddress
}
