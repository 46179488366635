import cx from 'classnames'

import { SequenceValidationRunStatus } from '~/api/desktop/drivers/roboticArm'
import TextWithOverflow from '~/components/TextWithOverflow'
import cs from './sequence_validation_run_status_view.scss'

export interface RunStatusBarProps {
  className?: string
  sequenceValidationRunStatus: SequenceValidationRunStatus
}

const RunStatusBar = ({ sequenceValidationRunStatus }: RunStatusBarProps) => {
  const getStatusBarClass = () => {
    if (sequenceValidationRunStatus.status === 'BUSY') {
      return cs.busy
    } else if (sequenceValidationRunStatus.status === 'FAILED') {
      return cs.failed
    } else if (sequenceValidationRunStatus.status === 'COMPLETED') {
      return cs.completed
    } else {
      return cs.notStarted
    }
  }
  const getCurrentRunNumber = () => {
    if (!sequenceValidationRunStatus.runtime_params) {
      return 0
    }
    return sequenceValidationRunStatus.runtime_params.current_run_number
  }
  const getMessage = () => {
    if (sequenceValidationRunStatus.status === 'BUSY') {
      if (!sequenceValidationRunStatus.runtime_params) {
        return null
      }
      return (
        `Executing ${sequenceValidationRunStatus.runtime_params.forward_or_reverse} sequence ` +
        `(Step ${sequenceValidationRunStatus.runtime_params.current_seq_step_number} ` +
        `of ${sequenceValidationRunStatus.runtime_params.total_seq_steps})...`
      )
    } else if (sequenceValidationRunStatus.status === 'FAILED') {
      return `Error: ${sequenceValidationRunStatus.error_message}`
    } else if (sequenceValidationRunStatus.status === 'COMPLETED') {
      return 'Success!'
    } else {
      return null
    }
  }
  const getPercentComplete = () => {
    if (
      !sequenceValidationRunStatus.runtime_params ||
      !sequenceValidationRunStatus.run_params
    ) {
      return 0
    }

    if (sequenceValidationRunStatus.status === 'COMPLETED') {
      return 100
    }

    // Add the number of repeats completed
    let percentComplete =
      (getCurrentRunNumber() - 1) / sequenceValidationRunStatus.run_params.repeat

    // Add the percentage of the current sequence completed
    const sequenceCompleted =
      sequenceValidationRunStatus.runtime_params.current_seq_step_number /
      sequenceValidationRunStatus.runtime_params.total_seq_steps
    if (sequenceValidationRunStatus.run_params.reverse_sequence_file_name) {
      if (sequenceValidationRunStatus.runtime_params.forward_or_reverse === 'reverse') {
        percentComplete += 1 / (2 * sequenceValidationRunStatus.run_params.repeat)
      }
      percentComplete +=
        sequenceCompleted / (2 * sequenceValidationRunStatus.run_params.repeat)
    } else {
      percentComplete +=
        sequenceCompleted / sequenceValidationRunStatus.run_params.repeat
    }

    return Math.round(percentComplete * 100)
  }
  return (
    <div className={cx(cs.runStatusBarContainer, getStatusBarClass())}>
      <div className={cs.runStatusBarText}>
        Run {getCurrentRunNumber()} of {sequenceValidationRunStatus.run_params.repeat}
      </div>
      <div className={cs.runStatusBar}>
        <div
          className={cs.runStatusBarInner}
          style={{ width: `${getPercentComplete()}%` }}
        />
      </div>
      <TextWithOverflow
        className={cs.message}
        popoverContent={getMessage()}
        text={getMessage()}
      />
    </div>
  )
}

export interface SequenceStepDisplayProps {
  sequenceValidationRunStatus: SequenceValidationRunStatus
}

const SequenceStepDisplay = ({
  sequenceValidationRunStatus,
}: SequenceStepDisplayProps) => {
  if (
    !sequenceValidationRunStatus.runtime_params ||
    !sequenceValidationRunStatus.run_params
  ) {
    return null
  }
  const getCurrentStepJson = () => {
    if (!sequenceValidationRunStatus.runtime_params) {
      return null
    }
    return sequenceValidationRunStatus.runtime_params.current_seq_step_json
  }

  return (
    <div className={cs.sequenceStepDisplay}>
      <div className={cs.header}>
        <div className={cs.label}>Current Step</div>
        <div className={cs.name}>
          {sequenceValidationRunStatus.runtime_params.sequence_name}
        </div>
        <div className={cs.stepProgress}>
          Step {sequenceValidationRunStatus.runtime_params.current_seq_step_number} of{' '}
          {sequenceValidationRunStatus.runtime_params.total_seq_steps}
        </div>
      </div>
      <div className={cs.currentStepJson}>
        <pre>{JSON.stringify(getCurrentStepJson(), undefined, 4)}</pre>
      </div>
    </div>
  )
}

export interface SequenceValidationRunStatusViewProps {
  className?: string
  sequenceValidationRunStatus: SequenceValidationRunStatus | null
}

const SequenceValidationRunStatusView = ({
  className,
  sequenceValidationRunStatus,
}: SequenceValidationRunStatusViewProps) => {
  if (!sequenceValidationRunStatus) {
    return null
  }
  return (
    <div className={cx(className, cs.sequenceValidationRunStatusView)}>
      <RunStatusBar sequenceValidationRunStatus={sequenceValidationRunStatus} />
      <SequenceStepDisplay sequenceValidationRunStatus={sequenceValidationRunStatus} />
    </div>
  )
}

export default SequenceValidationRunStatusView
