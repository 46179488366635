import { filter, includes, reverse, sortBy } from 'lodash/fp'
import React, { useContext } from 'react'

import Select from '~/components/Select'
import PropTypes from '~/propTypes'
import { processItemMatchesInstrumentNames } from '~/utils/processItems/common'

import { ProcessItem } from '~/common.interface'
import WorkcellStatusContext from '~/pages/Workcell/WorkcellStatusContext'
import { getInstrumentNames } from '~/pages/Workcell/utils/workcellStatus'
import { ProcessItemSelectOption } from './ProcessItemSelectOption'
import { processItemMatchesQuery } from './processItemMatchesQuery'

interface ProcessItemSelectProps {
  className?: string
  // TODO: Is this prop actually nullable?
  processItems?: ProcessItem[] | null
  selectedProcessItem?: ProcessItem | null
  onProcessItemSelect: (processItem: ProcessItem) => void
  processItemUuidsToDisplayAsSelected?: string[]
  disabled?: boolean
  label?: string
  hideLabel?: boolean
  triggerClassName?: string
  popoverClassName?: string
  renderProcessItemViz?: boolean
  placeholder?: React.ReactNode
  hideArrow?: boolean
  noBorder?: boolean
  stayOpenOnSelect?: boolean
}

const ProcessItemSelect = ({
  className,
  processItems,
  selectedProcessItem,
  onProcessItemSelect,
  processItemUuidsToDisplayAsSelected,
  disabled,
  label,
  hideLabel = false,
  triggerClassName,
  popoverClassName,
  renderProcessItemViz = false,
  stayOpenOnSelect = false,
  placeholder,
  hideArrow,
  noBorder,
}: ProcessItemSelectProps) => {
  const workcellStatus = useContext(WorkcellStatusContext)

  const instrumentNames = getInstrumentNames(workcellStatus)

  let _processItems = reverse(sortBy(item => item.lastCheckInTime || '', processItems))

  _processItems = filter(
    processItem => processItemMatchesInstrumentNames(processItem, instrumentNames),
    _processItems,
  )

  const handleClick = (e: React.MouseEvent, processItem: ProcessItem) => {
    // This is a hacky-ish way to implement stayOpenOnSelect.
    // Blueprint JS doesn't give an option for this, so we have to manually
    // cancel the click event and call the select ourselves.
    // We want this for situations where the Select is being used for a specialized
    // purpose, such as in LoadUnloadItemsLocationSelection.
    if (stayOpenOnSelect) {
      e.stopPropagation()
      onProcessItemSelect(processItem)
    }
  }

  return (
    <Select<ProcessItem>
      placeholder={placeholder || 'Select item'}
      label={hideLabel ? undefined : label || 'Select Process Item'}
      items={_processItems}
      itemKey='uuid'
      itemLabelKey='uuid'
      activeItem={selectedProcessItem || null}
      onChange={onProcessItemSelect}
      triggerClassName={triggerClassName}
      popoverClassName={popoverClassName}
      className={className}
      itemRenderer={item => (
        <div onClick={(e: React.MouseEvent) => handleClick(e, item)}>
          <ProcessItemSelectOption
            processItem={item}
            renderProcessItemViz={renderProcessItemViz}
            displayAsSelected={
              processItemUuidsToDisplayAsSelected &&
              includes(item.uuid, processItemUuidsToDisplayAsSelected)
            }
          />
        </div>
      )}
      disabled={disabled}
      filterable
      itemMatchesQuery={(item, queryLowerCase) =>
        processItemMatchesQuery(item, queryLowerCase)
      }
      noBorder={noBorder}
      hideArrow={hideArrow}
    />
  )
}

ProcessItemSelect.propTypes = {
  className: PropTypes.string,
  processItems: PropTypes.arrayOf(PropTypes.ProcessItem),
  selectedProcessItem: PropTypes.shape({
    uuid: PropTypes.string,
  }),
  onProcessItemSelect: PropTypes.func,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  triggerClassName: PropTypes.string,
  popoverClassName: PropTypes.string,
  renderPlateViz: PropTypes.bool,
}

export default ProcessItemSelect
