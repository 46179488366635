import Dialog from '~/components/Dialog'

import desktopAPI from '~/api/desktop'
import ConfigDialogTab from '~/components/Dialog/ConfigDialogTab'
import cs from './global_config_edit_dialog.scss'

interface GlobalConfigEditDialogProps {
  isOpen: boolean
  onClose(): void
}

const GlobalConfigEditDialog = ({ isOpen, onClose }: GlobalConfigEditDialogProps) => {
  const api = {
    getSchema: desktopAPI.getGlobalConfigSchema,
    setConfig: desktopAPI.setGlobalConfig,
    getConfig: desktopAPI.getGlobalConfig,
  }

  return (
    <Dialog isOpen={isOpen} onClose={onClose} className={cs.globalConfigEditDialog}>
      <Dialog.Title>Edit Global Config</Dialog.Title>
      <ConfigDialogTab api={api} onClose={onClose} />
    </Dialog>
  )
}

export default GlobalConfigEditDialog
