import { sortBy } from 'lodash/fp'
import Table, { TableColumn } from '~/components/Table'
import { renderStatusPill } from '~/utils/statusPill'
import { HitpickingValues, KnockoutType, getKnockoutType } from '../data/hitpickingData'

export interface HitpickingDataTableProps {
  className?: string
  plateAndWellToValues: Record<string, HitpickingValues>
  selectedRowIds: Record<string, boolean>
  setSelectedRowIds: (rowIds: Record<string, boolean>) => void
}

interface HitpickingRow {
  plate: number
  well: string
  koScore: number
  doublingRate: number
  uuid: string
}

const STATUS_PILL_CONFIG: { [key in KnockoutType]: { type: string } } = {
  Heterozygous: { type: 'cleDemoBlue' },
  Homozygous: { type: 'cleDemoPurple' },
}

const getHitpickingRowsFromPlateAndWellToValues = (
  plateAndWellToValues: Record<string, HitpickingValues>,
) => {
  const rows: HitpickingRow[] = []
  Object.entries(plateAndWellToValues).forEach(([plateAndWell, values]) => {
    const [plate, well] = plateAndWell.split('-')
    rows.push({
      plate: parseInt(plate),
      well,
      koScore: values.koScore,
      doublingRate: values.doublingRate,
      uuid: plateAndWell,
    })
  })
  return rows
}

const HitpickingDataTable = ({
  className,
  plateAndWellToValues,
  selectedRowIds,
  setSelectedRowIds,
}: HitpickingDataTableProps) => {
  const hitpickingRows = getHitpickingRowsFromPlateAndWellToValues(plateAndWellToValues)

  const tableColumns: TableColumn<HitpickingRow>[] = [
    {
      name: 'Well',
      width: 250,
      render: (row: HitpickingRow) => `Plate ${row.plate}, Well  ${row.well}`,
    },
    {
      name: 'KO Type',
      width: 250,
      render: (row: HitpickingRow) => {
        const label = getKnockoutType(row.koScore)

        if (label) {
          return renderStatusPill(label, STATUS_PILL_CONFIG, {
            small: true,
          })
        }

        return null
      },
    },
    {
      name: 'KO Score',
      width: 200,
      render: (row: HitpickingRow) => row.koScore.toFixed(1),
    },
    {
      name: 'Doubling Rate (hrs)',
      width: 200,
      render: (row: HitpickingRow) => row.doublingRate.toFixed(1),
    },
  ]

  const sortedRows = sortBy(
    [
      (row: HitpickingRow) => {
        return selectedRowIds[row.uuid] ? 0 : 1
      },
      (row: HitpickingRow) => {
        const koType = getKnockoutType(row.koScore)
        if (koType === 'Heterozygous') {
          return 2
        } else if (koType === 'Homozygous') {
          return 1
        } else {
          return 3
        }
      },
      (row: HitpickingRow) => {
        return -row.koScore
      },
    ],
    hitpickingRows,
  )

  return (
    <Table
      columns={tableColumns}
      data={sortedRows}
      rowKey='uuid'
      className={className}
      rowPaddingVariant='rowPaddingLow'
      heightSizing='flexAuto'
      noDataMessage='No wells to display'
      selectableProps={{
        onRowsSelected: setSelectedRowIds,
        selectedRowIds: selectedRowIds,
      }}
    />
  )
}

export default HitpickingDataTable
