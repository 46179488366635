import { DesktopAPI } from '~/api/core'
import {
  AllowedInstrumentForItemTypeResponse,
  GetLabwareOptionsByProcessItemType,
  RebuildAllLayoutResponse,
  WorkcellAllowedInstrumentsForItemTypeResponse,
  WorkcellGetRoutineIdsWithStepsInProgress,
  WorkcellServicesFailedToInitialize,
  WorkcellStatus,
} from '~/api/desktop/workcell.interface'
import { client, unwrap } from '~/api/desktopAPIv2'
import { Instrument, ProcessItemType } from '~/common.interface'
import { ProcessStep } from '~/pages/Workcell/types/ProcessStep.interface'

const workcellAPI = new DesktopAPI('/api/workcell')

const apiPrefix = '/api/v2/workcell'

export type WorkcellAllowedInstrumentsForItemType = Record<
  ProcessItemType,
  AllowedInstrumentForItemTypeResponse
>

export interface GetLiveStepsResponse {
  workcellStatus: WorkcellStatus
  steps: ProcessStep[]
}

export type ProcessStepStatus =
  | 'initialized'
  | 'scheduled'
  | 'in_progress'
  | 'completed'
  | 'canceled'
  | 'failed'

export default {
  status: (): Promise<WorkcellStatus | WorkcellServicesFailedToInitialize> =>
    unwrap(client().GET(`${apiPrefix}/`, {})),
  getWorkcellAllowedInstrumentsForItemType:
    (): Promise<WorkcellAllowedInstrumentsForItemTypeResponse> =>
      unwrap(client().GET(`${apiPrefix}/allowed-instruments-for-item-type`, {})),
  getRoutineIdsWithStepsInProgress:
    (): Promise<WorkcellGetRoutineIdsWithStepsInProgress> =>
      unwrap(client().GET(`${apiPrefix}/routine-ids-with-steps-in-progress`, {})),

  rebuildAllLayouts: (): Promise<RebuildAllLayoutResponse> =>
    unwrap(client().POST(`${apiPrefix}/rebuild-all-layouts`, {})),
  start: () => workcellAPI.post('start/'),
  stop: () => workcellAPI.post('stop/'),
  getInstruments: (params = {}): Promise<Instrument[]> =>
    workcellAPI.get('instruments/', params),
  getLiveSteps: () => workcellAPI.get('live-steps/') as Promise<GetLiveStepsResponse>,
  clearMessage: () => workcellAPI.post('clear-message/'),
  cancelAllScheduledSteps: () => workcellAPI.post('cancel-all-scheduled-steps/'),
  cancelSteps: stepUuids =>
    workcellAPI.post('cancel-steps/', {
      stepUuids,
    }),
  performInstrumentRecovery: () => workcellAPI.post('perform-instrument-recovery/'),
  getConfig: () => workcellAPI.get('config/'),
  setConfig: (newConfig, overwrite) =>
    workcellAPI.post('config/', {
      config: newConfig,
      overwrite,
    }),
  getConfigSchema: () => workcellAPI.get('config-schema/'),
  rescheduleFailedStep: stepUuid =>
    workcellAPI.post('reschedule-failed-step/', {
      stepUuid,
    }),
  rescheduleCompletedStep: stepUuid =>
    workcellAPI.post('reschedule-completed-step/', {
      stepUuid,
    }),
  bulkScheduleRoutines: (routines, automated = false) =>
    workcellAPI.post('bulk-schedule-routines/', {
      routines,
      automated,
    }),
  loadTransferStation: (instrumentName, transferStationId, processItemId) =>
    workcellAPI.post('load-transfer-station/', {
      instrumentName,
      transferStationId,
      processItemId,
    }),
  unloadTransferStation: (instrumentName, transferStationId, processItemId) =>
    workcellAPI.post('unload-transfer-station/', {
      instrumentName,
      transferStationId,
      processItemId,
    }),
  cancelRoutine: routineId =>
    workcellAPI.post('cancel-routine/', {
      routineId,
    }),
  bulkCancelRoutines: routineIds =>
    workcellAPI.post('bulk-cancel-routines/', {
      routineIds,
    }),
  kickoffNextSingleStep: () => workcellAPI.post('kickoff-next-single-step/'),
  addInstrument: (instrumentName, instrumentType) =>
    workcellAPI.post('add-instrument/', {
      instrumentName,
      instrumentType,
    }),
  removeInstrument: instrumentName =>
    workcellAPI.post('remove-instrument/', {
      instrumentName,
    }),
  getProfileName: async () => {
    const response = await workcellAPI.get('profile-name/')
    return response.profileName
  },
  setProfileName: profileName =>
    workcellAPI.post('profile-name/', {
      profileName,
    }),
  getLabwareOptionsByProcessItemType: (): Promise<GetLabwareOptionsByProcessItemType> =>
    unwrap(client().GET(`${apiPrefix}/get-labware-options-by-process-item-type`, {})),
}
