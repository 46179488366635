import fp, {
  flow,
  map,
  unset,
  forEach,
  set,
  isArray,
  camelCase,
  snakeCase,
  isObject,
  includes,
  mapKeys,
  identity,
  matchesProperty,
} from 'lodash/fp'

const mapValues = fp.mapValues.convert({ cap: false })

export const setAll = (keys, value, obj) => {
  let newObject = obj
  forEach(key => {
    newObject = set(key, value, newObject)
  }, keys)
  return newObject
}

export const unsetAll = (keys, obj) => {
  let newObject = obj
  forEach(key => {
    newObject = unset(key, newObject)
  }, keys)
  return newObject
}

export const camelize = (obj, ignoreKeysOf, ignoreFields) => {
  const camelizeInner = (_obj, parentKey) => {
    if (includes(ignoreFields, parentKey)) {
      return _obj
    }
    if (isArray(_obj)) {
      return map(val => camelizeInner(val, null), _obj)
    }
    if (isObject(_obj)) {
      return flow(
        mapValues((value, key) => camelizeInner(value, key)),
        mapKeys(includes(ignoreKeysOf, parentKey) ? identity : camelCase),
      )(_obj)
    }
    return _obj
  }

  return camelizeInner(obj, null)
}

export const underscoreize = (obj, ignoreKeysOf, ignoreFields) => {
  const underscoreizeInner = (_obj, parentKey) => {
    if (includes(ignoreFields, parentKey)) {
      return _obj
    }
    if (isArray(_obj)) {
      return map(val => underscoreizeInner(val, null), _obj)
    }
    if (isObject(_obj)) {
      return flow(
        mapValues((value, key) => underscoreizeInner(value, key)),
        mapKeys(includes(ignoreKeysOf, parentKey) ? identity : snakeCase),
      )(_obj)
    }
    return _obj
  }

  return underscoreizeInner(obj, null)
}

export const updateValuesByProperty = ([key, value], updateFn, obj) => {
  return mapValues(_value => {
    if (matchesProperty(key, value)(_value)) {
      return updateFn(_value)
    }
    return _value
  }, obj)
}
