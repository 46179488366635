import EditIcon from '~/components/icons/EditIcon'
import {
  CultureViewModel,
  NEXT_ACTION_USER_STRING,
  NextScheduledAction,
} from '../../events/ViewModels/CultureViewModel'
import {
  EventKind,
  generateEventTime,
  getRelativeTime,
  simulatedEventTime,
} from '../../events/data/eventTimes'
import cs from './editable_next_action.scss'

const NEXT_ACTION_TO_EVENT_KIND: { [action in NextScheduledAction]: EventKind } = {
  MEDIA_EXCHANGE: 'CultureMediaExchanged',
  PASSAGE: 'CulturePassaged',
}

export function EditableNextAction({
  today,
  culture,
}: {
  today: number
  culture: CultureViewModel
}) {
  if (culture.nextScheduledAction == null) {
    return <div>None</div>
  }

  const eventTime = generateEventTime({
    eventDay: today,
    eventKind: NEXT_ACTION_TO_EVENT_KIND[culture.nextScheduledAction],
  })
  const relativeEventTime = getRelativeTime(simulatedEventTime({ today, eventTime }))

  return (
    <div>
      <div>
        <span>{NEXT_ACTION_USER_STRING[culture.nextScheduledAction]}</span>
        <span className={cs.nextActionWhen}>{relativeEventTime}</span>
      </div>
      <div
        className={cs.modifyNextAction}
        onClick={() => {
          console.log('test')
        }}
      >
        <EditIcon className={cs.modifyNextActionIcon} /> Modify
      </div>
    </div>
  )
}
