import { RoutineParameters } from '../types/RoutineDefinition.interface'
import { RoutineDefinitionWithDSL } from './useRoutineDefinitionsWithDSLs'

export function areAllParametersSpecified(
  routineDefinitionWithDSL: RoutineDefinitionWithDSL,
  parameters: RoutineParameters,
  ignoreParameters: string[] = [],
): boolean {
  return Object.entries(routineDefinitionWithDSL.dsl.inputs).every(
    // Assume that all inputs without defaults are required for now
    // Note that this state is a little wacky - RoutineForm will render a default value, but
    // in the state here, it will be null/missing. We'll pass that up to the backend, which
    // will then fill in default values.
    ([input, inputDesc]) =>
      ignoreParameters.includes(input) ||
      inputDesc.default != null ||
      !(['', null, undefined] as unknown[]).includes(parameters[input]),
  )
}
