import { Instrument } from '~/common.interface'
import { SelectedTransferStation } from '~/pages/Workcell/components/TransferStationViz/TransferStationVizView'
import { TransferStationParamsRequest } from '../../reloadItems/convertChangeToReloadOperation'
import { LoadParamsRequest } from '../LoadParamsRequestOrError.interface'
import { LoadUnloadItemsProcessItemType } from '../LoadUnloadItemsProcessItemType.interface'

export const getLoadTransferStationOperation = (
  instrument: Instrument,
  storageLocation: SelectedTransferStation,
  processItemType: LoadUnloadItemsProcessItemType,
  processItemParams: LoadParamsRequest,
  useArmAssist: boolean,
): {
  // TODO: LoadOperationRequest should work here but requires a deeper investigation
  instrument_name: string
  process_item_params: LoadParamsRequest
  type: string
  command: string
  command_params: TransferStationParamsRequest
  use_arm_assist: boolean
} => {
  return {
    instrument_name: instrument.instrumentName,
    command: 'load_transfer_station',
    command_params: {
      transfer_station_id: storageLocation.transferStationId,
    } as TransferStationParamsRequest,
    type: processItemType,
    process_item_params: processItemParams,
    use_arm_assist: useArmAssist,
  }
}
