import cx from 'classnames'

import { size } from 'lodash/fp'
import Histogram from '~/components/Histogram'
import cs from './cle_demo_histogram.scss'

export interface CleDemoHistogramProps {
  className?: string
  values: number[]
  xMin: number
  xMax: number
  getBarColor?: (x0: number, x1: number) => string
}

const CleDemoHistogram = ({
  className,
  values,
  xMin,
  xMax,
  getBarColor,
}: CleDemoHistogramProps) => {
  // Hard-code these y-max values for now to look nice.
  let yMax = 2
  if (size(values) > 5) {
    yMax = 4
  }

  if (size(values) > 25) {
    yMax = 15
  }

  if (size(values) > 150) {
    yMax = 40
  }

  return (
    <Histogram
      className={cx(cs.cleDemoHistogram, className)}
      data={values}
      layoutOptions={{
        marginLeft: -1,
        marginRight: 1,
        marginBottom: 0,
        marginTop: 0,
      }}
      axisOptions={{
        showYAxis: false,
        showXAxis: false,
      }}
      options={{
        xDomain: [xMin, xMax],
        yDomain: [0, yMax],
        bins: 50,
        hoverBins: 50,
        getBarColor: getBarColor,
      }}
    />
  )
}

export default CleDemoHistogram
