import { FutureRoutine } from '~/api/desktop/future_routine_schemas.interface'
import HelpPopover from '~/components/HelpPopover'
import UserIcon from '~/components/icons/UserIcon'
import { getProcessItemsDisplayForRoutine } from '~/pages/Workcell/utils/routine'
import {
  dateToDisplayString,
  datetimeToDisplayString,
  renderDateTimeOnly,
} from '~/utils/date'
import cs from './future_routine_table.scss'

const _renderScheduledFor = (
  row: FutureRoutine,
  displayScheduledForDate: boolean | undefined,
) => {
  if (!displayScheduledForDate) {
    if (row.shouldQueueAutomatically)
      return <span>{renderDateTimeOnly(row.scheduledFor)}</span>
    return <span className={cs.operatorToSchedule}>Operator to Schedule</span>
  }
  if (row.shouldQueueAutomatically) {
    return <span>{datetimeToDisplayString(row.scheduledFor)}</span>
  }
  return (
    <>
      <span className={cs.scheduledForWithRightPadding}>
        {dateToDisplayString(row.scheduledFor)},
      </span>
      <span className={cs.operatorToSchedule}> Operator to Schedule</span>
    </>
  )
}

export const renderFutureRoutinesScheduledFor = (
  row: FutureRoutine,
  displayScheduledForDate: boolean | undefined,
): JSX.Element => {
  return (
    <>
      {_renderScheduledFor(row, displayScheduledForDate)}
      {row.didUserOverrideScheduledFor && (
        <HelpPopover
          text={<UserIcon className={cs.userOverrideIcon} />}
          helpContent="User has edited the scheduled time. This routine's scheduled time will no longer be automatically adjusted."
          interactionKind='hover'
          smallText={false}
          className={cs.userOverrideIconContainer}
        />
      )}
    </>
  )
}

export const renderFutureRoutinesProcessItem = (row: FutureRoutine) => {
  const processItem = getProcessItemsDisplayForRoutine(row)
  if (!processItem) return null
  return <b>{processItem}</b>
}

export const renderRequiresUserAttentionErrorMessage = (row: FutureRoutine) => {
  let message = ''
  if (row.requiresUserAttentionReason === 'FAILED_TO_QUEUE_AT_SCHEDULED_TIME') {
    message = `Failed to execute at the scheduled time. ${row.queueFailMessage}`
  }
  if (row.requiresUserAttentionReason === 'WORKFLOW_ROUTINE_CANCELED_BY_USER') {
    message =
      'Routine canceled by user during execution, but required as part of the workflow. To skip this routine in the workflow, cancel this future routine.'
  }

  return <div className={cs.reviewReason}>{message}</div>
}
