import cx from 'classnames'
import { keyBy } from 'lodash/fp'
import {} from '~/api/operatorActions/linkCellLineLotsToCultures'
import Table from '~/components/Table'
import TinyMicroplate, { supportedWellColor } from '~/components/TinyMicroplate'
import { convertWellCoordsToWellName } from '~/utils/microplate'
import { UploadPlateDataStepValidData } from './BulkLoadCulturePlatesWizardData.interface'
import { BulkLoadPreviewPlate } from './BulkLoadPlatePreview'
import cs from './bulk_load_data_table.scss'

export interface BulkLoadDataTableProps {
  className?: string
  uploadPlateDataStepData: UploadPlateDataStepValidData
  previewPlates: BulkLoadPreviewPlate[]
}

interface BulkLoadDataTableRow {
  level: number
  previewPlate: BulkLoadPreviewPlate
}

const TABLE_COLUMNS = [
  {
    name: 'Level',
    width: 100,
    render: (row: BulkLoadDataTableRow) => row.level,
  },
  {
    name: 'Process Item',
    width: 200,
    oneLine: true,
    render: (row: BulkLoadDataTableRow) => <b>{row.previewPlate.plateBarcode}</b>,
  },
  {
    name: 'Live Wells',
    width: 80,
    render: (row: BulkLoadDataTableRow) => {
      const getHighlightFns = () => {
        // Putting these functions in the closure to make rendering more efficient.
        // fn will be called many times.
        const liveWellMap = keyBy(well => well, row.previewPlate.liveWells)

        return [
          {
            color: 'accent' as supportedWellColor,
            fn: (row: number, col: number): boolean => {
              const wellName = convertWellCoordsToWellName(row, col)
              return !!liveWellMap[wellName]
            },
          },
        ]
      }
      return (
        <TinyMicroplate
          plateFormat={row.previewPlate.plateFormat}
          size='micro'
          highlights={getHighlightFns()}
        />
      )
    },
    rightAlign: true,
  },
]

const BulkLoadDataTable = ({
  className,
  uploadPlateDataStepData,
  previewPlates,
}: BulkLoadDataTableProps) => {
  const renderTable = () => {
    const previewPlateByBarcode = keyBy('plateBarcode', previewPlates)

    const data = uploadPlateDataStepData.parseDataResponse.data.map(
      plateWithStorageLoadLocation => ({
        level: plateWithStorageLoadLocation.level,
        previewPlate: previewPlateByBarcode[plateWithStorageLoadLocation.plate_barcode],
      }),
    )

    return (
      <Table<BulkLoadDataTableRow>
        columns={TABLE_COLUMNS}
        data={data}
        className={cx(cs.table)}
        rowKey='id'
        rowPaddingVariant='rowPaddingLow'
        heightSizing='flexAuto'
      />
    )
  }

  return <div className={cx(className, cs.linkLotsDataTable)}>{renderTable()}</div>
}

export default BulkLoadDataTable
