import { omit } from 'lodash'
import { PlateCreatedKinds } from '~/pages/slasDemo/events/DemoEvent'
import { simulatedEventTime } from '../data/eventTimes'

export const OVER_CONFLUENT_THRESHOLD = 80

export interface CultureDatasetViewModel {
  id: string

  confluency: number
  doublingTime: number | null // null on first dataset

  thumbnails: string[][]
  fullImages: string[][]

  cellDeathDetected: boolean

  _datasetDay: number
  _globalDayCaptured: number
  _eventTimeCaptured: string
}

export type CultureStatus = 'ACTIVE' | 'CONSUMED' | 'TERMINATED'
export type NextScheduledAction = 'MEDIA_EXCHANGE' | 'PASSAGE'

export const NEXT_ACTION_USER_STRING: {
  [action in NextScheduledAction]: string
} = {
  MEDIA_EXCHANGE: 'Media Exchange',
  PASSAGE: 'Passage',
}

// TODO: Move consider figuring out which of these variables we still need
export interface CultureViewModel {
  id: string
  name: string
  status: CultureStatus

  plateID: string
  plateName: string
  wellName: string

  cellLine: string
  experimentLabel: string

  parentCultureID: string | null
  passageNumber: number

  isArchived: boolean
  associatedMediaLotIDs: string[]

  datasets: CultureDatasetViewModel[]

  latestDayUserMarkedOkay: number
  nextScheduledAction: NextScheduledAction | null

  creationKind: PlateCreatedKinds

  _dayCreated: number
  _datasetIndex: number
  _createdEventTime: string
  _archivedEventTime?: string
  _cultureIsContaminated: boolean
}

export type CultureViewModelMap = {
  [cultureID: string]: CultureViewModel
}

// Not calculated on the view model for performance
export function getDatasetCreatedAt(
  today: number,
  dataset: CultureDatasetViewModel,
): string {
  return simulatedEventTime({ today, eventTime: dataset._eventTimeCaptured })
}

export function getCultureCreatedAt(today: number, culture: CultureViewModel): string {
  return simulatedEventTime({ today, eventTime: culture._createdEventTime })
}
export function getCultureArchivedAt(today: number, culture: CultureViewModel): string {
  if (culture._archivedEventTime) {
    return simulatedEventTime({ today, eventTime: culture._archivedEventTime })
  } else {
    throw new Error('Archived time is not defined')
  }
}

export function getLatestConfluency(culture: CultureViewModel): number {
  const latestDataset = culture.datasets[culture.datasets.length - 1]
  return latestDataset.confluency
}

export type IssueType = 'OVER_CONFLUENT' | 'CELL_DEATH'

export function getCultureIssue(
  today: number,
  culture: CultureViewModel,
): IssueType | null {
  const latestDataset = culture.datasets[culture.datasets.length - 1]
  if (culture.latestDayUserMarkedOkay >= today) {
    return null
  }
  if (latestDataset.cellDeathDetected) {
    return 'CELL_DEATH'
  } else if (latestDataset.confluency >= OVER_CONFLUENT_THRESHOLD) {
    return 'OVER_CONFLUENT'
  } else {
    return null
  }
}

export function getLiveCulturesWithIssues(
  today: number,
  cultures: CultureViewModelMap,
  issueTypeFilter: IssueType | null = null,
): CultureViewModel[] {
  return Object.values(cultures).filter(culture => {
    const cultureIssue = getCultureIssue(today, culture)
    return (
      !culture.isArchived &&
      culture.latestDayUserMarkedOkay < today &&
      cultureIssue != null &&
      (issueTypeFilter == null || issueTypeFilter === cultureIssue) &&
      (cultureIssue !== 'OVER_CONFLUENT' || culture.nextScheduledAction !== 'PASSAGE')
    )
  })
}

export function omitArchivedCultures(
  cultureVMMap: CultureViewModelMap,
): CultureViewModelMap {
  return omit(
    cultureVMMap,
    Object.keys(cultureVMMap).filter(id => cultureVMMap[id].isArchived),
  )
}
