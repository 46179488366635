import { values } from 'lodash/fp'
import { supportedPlateFormats } from '~/components/TinyMicroplate.interface'
import { CultureViewModel } from './CultureViewModel'

export interface PlateViewModel {
  id: string
  type: supportedPlateFormats
  name: string
  experimentLabel: string

  wellNameToCulture: { [well: string]: CultureViewModel }

  // TODO: This is a bit janky right now - we assume that if any cultures on the plate are
  // archived, then the whole plate should be archived
  isArchived: boolean
}

// Not calculated on the view model for performance
export function getAllCultures(plate: PlateViewModel): CultureViewModel[] {
  return values(plate.wellNameToCulture)
}

export type PlateViewModelMap = { [plateID: string]: PlateViewModel }
