import cx from 'classnames'
import PropTypes from 'prop-types'

import TinyMicroplate from '~/components/TinyMicroplate'

import cs from './microplate_playground.scss'

const MicroplatePlayground = ({ className }) => {
  return (
    <div className={cx(className, cs.microplatePlayground)}>
      <div className={cs.contents}>
        <div className={cs.item}>
          <div className={cs.label}>6 Well</div>
          <TinyMicroplate plateFormat='wells_6' size='micro' className={cs.plate} />
          <TinyMicroplate plateFormat='wells_6' className={cs.plate} />
          <TinyMicroplate plateFormat='wells_6' size='medium' className={cs.plate} />
        </div>
        <div className={cs.item}>
          <div className={cs.label}>12 Well</div>
          <TinyMicroplate plateFormat='wells_12' size='micro' className={cs.plate} />
          <TinyMicroplate plateFormat='wells_12' className={cs.plate} />
          <TinyMicroplate plateFormat='wells_12' size='medium' className={cs.plate} />
        </div>
        <div className={cs.item}>
          <div className={cs.label}>24 Well</div>
          <TinyMicroplate plateFormat='wells_24' size='micro' className={cs.plate} />
          <TinyMicroplate plateFormat='wells_24' className={cs.plate} />
          <TinyMicroplate plateFormat='wells_24' size='medium' className={cs.plate} />
        </div>
        <div className={cs.item}>
          <div className={cs.label}>96 Well</div>
          <TinyMicroplate plateFormat='wells_96' size='micro' className={cs.plate} />
          <TinyMicroplate plateFormat='wells_96' className={cs.plate} />
          <TinyMicroplate plateFormat='wells_96' size='medium' className={cs.plate} />
        </div>
      </div>
    </div>
  )
}

MicroplatePlayground.propTypes = {
  className: PropTypes.string,
}

export default MicroplatePlayground
