import cx from 'classnames'
import PropTypes from 'prop-types'
import { useState } from 'react'
import Dialog from '~/components/Dialog'
import Toaster from '~/components/Toaster'

import workcellAPI from '~/api/desktop/workcell'

import cs from './workcell_layout_dialog_tab.scss'

const WorkcellLayoutDialogTab = ({ className }) => {
  const [isRebuilding, setIsRebuilding] = useState(false)

  const handleRebuild = async () => {
    await workcellAPI.rebuildAllLayouts()
    setIsRebuilding(false)
    Toaster.show({
      message: 'Rebuild complete',
      intent: 'success',
    })
  }

  return (
    <div className={cx(className, cs.workcellLayoutTab)}>
      <Dialog.Footer>
        <Dialog.FooterButton
          label='Rebuild Workcell Layouts'
          type='primary'
          onClick={() => {
            setIsRebuilding(true)
            handleRebuild()
          }}
          disabled={isRebuilding}
        />
      </Dialog.Footer>
    </div>
  )
}

WorkcellLayoutDialogTab.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
}

export default WorkcellLayoutDialogTab
