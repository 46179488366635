import { getUnitFloatDisplay } from '~/utils/entity'
import { datetimeToDisplayString } from './date'
import { getSmartDefaultDisplay, isSmartDefault, isTemplateString } from './jsonSchema'

export const getDisplayStringForField = (value, type: string) => {
  // Smart default template strings should be displayed in a more human-readable form.
  if (isSmartDefault(value)) {
    return getSmartDefaultDisplay(value)
  }

  return getStringValueForField(value, type)
}

// Convert an object to a string value based on type.
// NOTE: This function can use some cleanup.
export const getStringValueForField = (value, type: string) => {
  const isEmpty = value === null || value === undefined
  if (isTemplateString(value)) {
    console.log("Can't convert template string to string value", value, type)
    return null
  }
  switch (type) {
    case 'unit_float':
      if (isEmpty) return ''
      return getUnitFloatDisplay(value)
    case 'string_datetime':
      if (isEmpty) return ''
      return datetimeToDisplayString(value)
    case 'number':
    case 'integer':
    case 'boolean':
      if (isEmpty) return ''
      return String(value)
    case 'int_array':
    case 'string_array':
      if (isEmpty) return ''
      return JSON.stringify(value)
    case 'sample_plate_array':
      if (isEmpty) return '[]'
      return JSON.stringify(value)
    case 'string':
    case 'reagent':
    case 'sample_plate':
    case 'assay_plate':
    // select_from_string_choices is a data type in our routine DSL, whereas enum is from pydantic's JSONSchema.
    // enum from JSONSchema has a much more convoluted spec, which we didn't want to support in our routine DSL syntax.
    // e.g. allOf: [{$ref: "#/definitions/MyEnum"} }]
    case 'select_from_string_choices':
    // For now, we assume that enums are all string enums.
    case 'enum': // eslint-disable-line no-fallthrough
      if (isEmpty) return ''
      return value
    default:
      if (isEmpty) return ''
      return JSON.stringify(value)
  }
}
