import cx from 'classnames'
import PropTypes from 'prop-types'
/* eslint-disable arrow-body-style */
import React, { useEffect, useState, useRef } from 'react'

import HelpPopover from './HelpPopover'
import cs from './text_with_overflow.scss'

interface TextWithOverflowProps {
  className?: string
  text?: React.ReactNode
  popoverContent?: React.ReactNode
  onClick?: () => void
}

// Note: This does not currently support updates on the text prop.
// If you pass in a short 'text' value and then change it to a long text value that overflows,
// the help popover will not correctly display.
const TextWithOverflow = ({
  className,
  text,
  popoverContent,
  onClick,
}: TextWithOverflowProps) => {
  const outerRef = useRef<HTMLDivElement>(null)
  const innerRef = useRef<HTMLSpanElement>(null)
  const [overflow, setOverflow] = useState(false)

  useEffect(() => {
    if (outerRef.current && innerRef.current) {
      const computedStyle = getComputedStyle(outerRef.current)
      const availableWidth =
        outerRef.current.clientWidth -
        (parseFloat(computedStyle.paddingLeft) + parseFloat(computedStyle.paddingRight))
      if (availableWidth < innerRef.current.offsetWidth) {
        setOverflow(true)
      }
    }
    // We use outerRef.current here instead of outerRef because we've noticed that
    // outerRef.current (which is a raw DOM node) is sometimes created twice,
    // and outerRef.current.clientWidth is not accurate until the second creation.
    // In one specific instance, TextWithOverflow was in a Popover.
    // outerRef.current.clientWidth was 260px on creation 1 and 251px (the correct value)
    // on creation 2.
    // Due to React's conservative diffing algorithm on the DOM tree, we expect that DOM
    // nodes will rarely be recreated. Empirically, we've seen outerRef.current
    // update at most 2 times when TextWithOverflow is rendered.
    //
    // TODO: We should also depend on the text content, since it could change
    // independent of the refs. But we should make sure it wouldn't trigger
    // excessive re-renders in components that pass JSX to the text prop.
  }, [outerRef.current, innerRef.current])

  const helpContent = <div className={cs.helpContent}>{popoverContent || text}</div>

  if (!overflow) {
    return (
      <div
        ref={outerRef}
        className={cx(className, cs.textWithOverflow)}
        onClick={onClick}
      >
        <span className={cs.inner} ref={innerRef}>
          {text}
        </span>
      </div>
    )
  }

  return (
    <div className={cx(className)} onClick={onClick}>
      <HelpPopover
        className={cs.textWithOverflow}
        text={text}
        elementType='div'
        helpContent={helpContent}
        smallText={false}
        interactionKind='hover'
      />
    </div>
  )
}

TextWithOverflow.propTypes = {
  className: PropTypes.string,
  text: PropTypes.node,
  popoverContent: PropTypes.node,
  onClick: PropTypes.func,
}

export default TextWithOverflow
