import { _plural, plateBarcodeInputParameterNames } from '../History/utils/routines'

import { get, isArray } from 'lodash/fp'
import { RoutineDescriptor } from '../types/RoutineDescriptor.interface'

// This returns a display string for the process items in the routine.
// This can be one or more process items. For example, Count Assay can have multiple.

export const getProcessItemsDisplayForRoutine = (
  routine: RoutineDescriptor,
): string | null => {
  // We are currently converting our APIs and future routine parameters use snake case.
  for (const parameterName of plateBarcodeInputParameterNames) {
    const processItem = get(['parameters', parameterName], routine)
    if (processItem) {
      return processItem
    }
  }

  for (const parameterName of plateBarcodeInputParameterNames) {
    const processItems = get(['parameters', _plural(parameterName)], routine)
    if (isArray(processItems)) {
      return processItems.join(', ')
    }
  }

  return null
}
