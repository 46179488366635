import cx from 'classnames'
import { times } from 'lodash'
import { ReactNode, useState } from 'react'
import Dialog from '~/components/Dialog'
import Link from '~/components/Link'
import {
  CultureDatasetViewModel,
  CultureViewModel,
  getDatasetCreatedAt,
} from '../../events/ViewModels/CultureViewModel'
import { getRelativeTime } from '../../events/data/eventTimes'
import {
  NUM_WELL_IMAGES_HORIZONTAL,
  NUM_WELL_IMAGES_VERTICAL,
} from '../../events/data/generate/generateDataset'
import cs from './culture_dataset_images_dialog.scss'

// On a 3-down 4-wide grid, 0-indexed 5 is the center-left image
const INITIAL_IMAGE_INDEX = 0

export function CultureDatasetImagesDialog({
  header,
  today,
  culture,
  dataset,
  isOpen,
  onClose,
}: {
  header?: ReactNode
  today: number
  culture: CultureViewModel | null
  dataset: CultureDatasetViewModel | null
  isOpen: boolean
  onClose: () => void
}) {
  if (!culture || !dataset) {
    return null
  }

  const [imageIndex, setImageIndex] = useState(INITIAL_IMAGE_INDEX)

  const row = Math.floor(imageIndex / NUM_WELL_IMAGES_HORIZONTAL)
  const col = imageIndex % NUM_WELL_IMAGES_HORIZONTAL

  const datasetCreatedAt = getRelativeTime(getDatasetCreatedAt(today, dataset))

  return (
    <Dialog noPadding isOpen={isOpen} onClose={onClose} className={cs.dialogBody}>
      {header ? <div className={cs.header}>{header}</div> : null}
      <img
        className={cs.image}
        // We have missing images and need some backup. Let's use index 1 as
        // that's available for all datasets
        src={dataset.fullImages[row][col] || dataset.fullImages[0][0]}
        key={dataset.fullImages[row][col]}
      />
      <div className={cs.footer}>
        <div className={cs.imageMetadata}>
          <ImageGrid
            selectedIndex={imageIndex}
            onIndexSelected={index => {
              setImageIndex(index)
            }}
          />
          <div>
            <div className={cs.imageCounter}>
              Image {imageIndex + 1} of{' '}
              {NUM_WELL_IMAGES_HORIZONTAL * NUM_WELL_IMAGES_VERTICAL}
            </div>
            <div className={cs.imageMetadataSubtext}>
              Captured {datasetCreatedAt} &middot; CYTATION_001
            </div>
          </div>
        </div>
        <div>
          <div className={cs.cultureImportant}>
            <Link
              to={`/command-center/culture/${culture.id}`}
              className={cs.cultureLink}
              variant='primary'
            >
              {culture.name}
            </Link>{' '}
            &middot; Confluency: {dataset.confluency}%
          </div>
          <div className={cs.cultureSubtext}>
            Plate {culture.plateName}, Well {culture.wellName} &middot;{' '}
            {culture.cellLine}
          </div>
        </div>
      </div>
    </Dialog>
  )
}

function ImageGrid({
  selectedIndex,
  onIndexSelected,
}: {
  selectedIndex: number
  onIndexSelected: (index: number) => void
}) {
  return (
    <div className={cs.imageGridContainer}>
      <div className={cs.imageGrid}>
        {times(NUM_WELL_IMAGES_VERTICAL, row => (
          <div key={row} className={cs.imageGridRow}>
            {times(NUM_WELL_IMAGES_HORIZONTAL, col => {
              const index = row * NUM_WELL_IMAGES_HORIZONTAL + col
              return (
                <div
                  key={index}
                  className={cx(
                    cs.imageGridImage,
                    selectedIndex === index
                      ? cs.imageGridImage__selected
                      : cs.imageGridImage__unselected,
                  )}
                  onClick={() => {
                    onIndexSelected(index)
                  }}
                />
              )
            })}
          </div>
        ))}
      </div>
    </div>
  )
}
