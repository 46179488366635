import formatRelative from 'date-fns/formatRelative'
import { enUS } from 'date-fns/locale'
import dayjs, { Dayjs } from 'dayjs'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import duration from 'dayjs/plugin/duration'
import relativeTime from 'dayjs/plugin/relativeTime'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'

/**
 * Initialize plugins for DayJS.
 */
export function initializeDayJS() {
  dayjs.extend(relativeTime)
  dayjs.extend(duration)
  dayjs.extend(utc)
  dayjs.extend(timezone)
  dayjs.extend(advancedFormat)
  dayjs.extend(customParseFormat)
}

export const renderDatetime = date => {
  return dayjs(date).format('h:mm A, MMM D')
}

export const renderTime = date => {
  return dayjs(date).format('h:mm A')
}

const z = (num, places) => String(num).padStart(places, '0')

export const displayDateDiff = (date, dateTwo) => {
  // get total seconds between the times
  let delta = Math.floor(Math.abs(dateTwo - date) / 1000)

  // calculate (and subtract) whole days
  const days = Math.floor(delta / 86400)
  delta -= days * 86400

  // calculate (and subtract) whole hours
  const hours = Math.floor(delta / 3600) % 24
  delta -= hours * 3600

  // calculate (and subtract) whole minutes
  const minutes = Math.floor(delta / 60) % 60
  delta -= minutes * 60

  // what's left is seconds
  const seconds = delta % 60 // in theory the modulus is not required

  if (days > 0) {
    return `${days}:${z(hours, 2)}:${z(minutes, 2)}:${z(seconds, 2)}`
  }
  return `${hours}:${z(minutes, 2)}:${z(seconds, 2)}`
}
const formatRelativeLocale = {
  lastWeek: "'Last' eeee",
  yesterday: "'Yesterday'",
  today: "'Today'",
  tomorrow: "'Tomorrow'",
  nextWeek: "'Next' eeee",
  other: 'MM/dd/yyyy',
}

// This could be a constant somewhere
const defaultLocale = enUS

const locale = {
  ...defaultLocale,
  formatRelative: token => formatRelativeLocale[token],
}

// Returns values like "Next Friday", "Today", "Tomorrow"
// To render values like "one hour ago", use dayjs().fromNow()
export const renderRelativeDate = (date): string => {
  return formatRelative(date, new Date(), { locale })
}

export const renderDateTimeOnly = (date): string => {
  return dayjs(date).format('h:mm A')
}

export const dateToDisplayString = (date): string => {
  return dayjs(date).format('YYYY-MM-DD')
}

export const roundDatetimeToMinute = (date): Date => {
  return dayjs(date).startOf('minute').toDate()
}

export const datetimeToUTCIsoStringTruncateSeconds = (date): string => {
  return dayjs(date).startOf('minute').toISOString()
}

export const datetimeToUTCIsoString = (date): string => {
  return dayjs(date).toISOString()
}

export const datetimeToDisplayString = (date): string => {
  return dayjs(date).format('YYYY-MM-DD, hh:mm A')
}

/**
 * Encode a timestamp for use in a URI component, returning a format that can be
 * decoded by decodeURITimestamp, or an empty string if `time` is invalid.
 */
export function encodeURITimestamp(time: Dayjs): string {
  if (!time.isValid()) {
    return ''
  }
  // Intentionally allow the '+' character in the time zone suffix for clarity.
  return time.format('YYYY-MM-DD_HH.mm.ss.SSSZZ')
}

/**
 * Decode a timestamp from a URI component and return a valid Dayjs, or null if
 * the component is missing or invalid. Supported formats include the format
 * produced by encodeURITimestamp, as well as similar formats with the time
 * truncated.
 *
 * If the URI doesn't specify a time zone, we assume it's in the user's local
 * time zone.
 */
export function decodeURITimestamp(rawURIComponent?: string | null): Dayjs | null {
  if (!rawURIComponent) {
    return null
  }

  const decoded = decodeURIComponent(rawURIComponent).replace(' ', '+')

  // Use the specified timezone if there is one. Otherwise, assume the user's
  // local timezone.
  let d = dayjs(decoded, ['YYYY-MM-DD_HH.mm.ss.SSSZZ', 'YYYY-MM-DD_HH.mm.ss.SSSZ'])
  if (!d.isValid()) {
    d = dayjs(decoded, [
      'YYYY-MM-DD_HH.mm.ss.SSS',
      'YYYY-MM-DD_HH.mm.ss',
      'YYYY-MM-DD_HH.mm',
      'YYYY-MM-DD_HH',
      'YYYY-MM-DD',
      'YYYY-MM',
      'YYYY',
    ]).tz(dayjs.tz.guess(), true)
  }
  if (!d.isValid()) {
    return null
  }
  return d
}
