import cx from 'classnames'
import { snakeCaseToTitleCase } from '~/utils/string'
import { ClePhase, ClePlate } from '../clePlates/ClePlate.interface'
import cs from './cle_pipeline_viz.scss'

const getNumPlatesForPhase = (plates: ClePlate[], phase: ClePhase) => {
  return plates.filter(plate => plate.phase === phase).length
}

const getNumPlatesRequiringReviewForPhase = (plates: ClePlate[], phase: ClePhase) => {
  const platesWithAlerts = plates.filter(
    plate => plate.hasAlert && plate.phase === phase,
  )
  return platesWithAlerts.length
}

const ClePipelinePhase = ({
  plates,
  phase,
  selectedPhase,
  setSelectedPhase,
}: {
  phase: ClePhase
  plates: ClePlate[]
  selectedPhase: ClePhase | null
  setSelectedPhase: (phase: ClePhase | null) => void
}) => {
  const plateCount = getNumPlatesForPhase(plates, phase)
  const alertCount = getNumPlatesRequiringReviewForPhase(plates, phase)

  const handleClick = () => {
    if (selectedPhase === phase) {
      setSelectedPhase(null)
    } else {
      setSelectedPhase(phase)
    }
  }

  return (
    <div
      className={cx(
        cs.clePipelinePhase,
        selectedPhase !== null && selectedPhase !== phase && cs.disabled,
      )}
    >
      <div className={cs.plateCountContainer}>
        <div className={cs.plateCount} onClick={handleClick}>
          {plateCount}
        </div>
        <div className={cs.dottedLine} />
        {alertCount > 0 && (
          <div className={cs.alertCount} onClick={handleClick}>
            {alertCount}
          </div>
        )}
      </div>
      <div className={cs.phase} onClick={handleClick}>
        {snakeCaseToTitleCase(phase)}
      </div>
    </div>
  )
}

const ClePipelineViz = ({
  plates,
  selectedPhase,
  setSelectedPhase,
}: {
  plates: ClePlate[]
  selectedPhase: ClePhase | null
  setSelectedPhase: (phase: ClePhase | null) => void
}) => {
  return (
    <div className={cs.clePipelineViz}>
      <ClePipelinePhase
        phase='seeding'
        plates={plates}
        selectedPhase={selectedPhase}
        setSelectedPhase={setSelectedPhase}
      />
      <ClePipelinePhase
        phase='24wp_expansion'
        plates={plates}
        selectedPhase={selectedPhase}
        setSelectedPhase={setSelectedPhase}
      />
      <ClePipelinePhase
        phase='6wp_expansion'
        plates={plates}
        selectedPhase={selectedPhase}
        setSelectedPhase={setSelectedPhase}
      />
    </div>
  )
}

export default ClePipelineViz
