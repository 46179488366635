import cx from 'classnames'
import PropTypes from 'prop-types'

import Select from '~/components/Select'
import InstrumentTile from '~/pages/Workcell/components/instrument/InstrumentTile'

import cs from './instrument_select.scss'

const InstrumentSelect = ({
  className,
  instruments,
  selectedInstrument,
  onInstrumentSelect,
  disabled,
  noBorder,
  triggerClassName,
  triggerIconClassName,
  popoverClassName,
}) => {
  const renderInstrument = instrument => (
    <InstrumentTile instrument={instrument} variant='leftAligned' mini />
  )

  const itemMatchesQuery = (instrument, queryLowerCase) =>
    instrument.instrumentName.toLowerCase().includes(queryLowerCase)

  return (
    <Select
      label={noBorder ? undefined : 'Select Instrument'}
      items={instruments}
      itemKey='instrumentName'
      itemLabelKey='instrumentName'
      activeItem={selectedInstrument}
      onChange={onInstrumentSelect}
      triggerClassName={cx(cs.trigger, triggerClassName)}
      popoverClassName={cx(cs.popover, popoverClassName)}
      triggerIconClassName={triggerIconClassName}
      className={cx(cs.instrumentSelect, className)}
      itemRenderer={renderInstrument}
      disabled={disabled}
      noBorder={noBorder}
      filterable
      itemMatchesQuery={itemMatchesQuery}
    />
  )
}

InstrumentSelect.propTypes = {
  className: PropTypes.string,
  instruments: PropTypes.arrayOf(
    PropTypes.shape({
      instrumentName: PropTypes.string,
      instrumentType: PropTypes.string,
    }),
  ),
  selectedInstrument: PropTypes.shape({
    instrumentName: PropTypes.string,
    instrumentType: PropTypes.string,
  }),
  onInstrumentSelect: PropTypes.func,
  disabled: PropTypes.bool,
  noBorder: PropTypes.bool,
  triggerClassName: PropTypes.string,
  popoverClassName: PropTypes.string,
  triggerIconClassName: PropTypes.string,
}

export default InstrumentSelect
