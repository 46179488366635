import { compact, forEach, keyBy, map, mapValues, size, uniq, values } from 'lodash/fp'
import {
  LinkLotsCulturePlateResponse,
  LinkLotsParseDataResponseCellLineLots,
  LinkLotsParseDataResponseCellLines,
  LinkLotsParsedCellLine,
  LinkLotsParsedCellLineLot,
} from '~/api/operatorActions/linkCellLineLotsToCultures'
import { LinkLotsPreviewPlate, LinkLotsPreviewWell } from './LinkLotsPlatePreview'

export const getNumPlates = (
  parseDataResponse:
    | LinkLotsParseDataResponseCellLineLots
    | LinkLotsParseDataResponseCellLines,
): number => {
  return parseDataResponse.culture_plates.length
}
export const getNumCultures = (
  parseDataResponse:
    | LinkLotsParseDataResponseCellLineLots
    | LinkLotsParseDataResponseCellLines,
): number => {
  return parseDataResponse.data.length
}

export const getNumDistinctCellLines = (
  parseDataResponse:
    | LinkLotsParseDataResponseCellLineLots
    | LinkLotsParseDataResponseCellLines,
): number => {
  if (parseDataResponse.type !== 'cell_lines') return 0
  return size(uniq(compact(map('cell_line', parseDataResponse.data))))
}

export const getNumDistinctCellLineLots = (
  parseDataResponse:
    | LinkLotsParseDataResponseCellLineLots
    | LinkLotsParseDataResponseCellLines,
): number => {
  if (parseDataResponse.type !== 'cell_line_lots') return 0
  return size(uniq(compact(map('cell_line_lot', parseDataResponse.data))))
}

export const getLinkLotsPreviewPlates = (
  parseDataResponse:
    | LinkLotsParseDataResponseCellLineLots
    | LinkLotsParseDataResponseCellLines,
): LinkLotsPreviewPlate[] => {
  const _plateWells: Record<string, Record<string, LinkLotsPreviewWell>> = mapValues(
    (culture_plate: LinkLotsCulturePlateResponse) =>
      keyBy(
        'well',
        map(wellName => ({ well: wellName, isLive: true }), culture_plate.live_wells),
      ),
    keyBy('user_facing_id', parseDataResponse.culture_plates),
  )

  if (parseDataResponse.type === 'cell_line_lots') {
    forEach((cellLineLot: LinkLotsParsedCellLineLot) => {
      // We assume the back-end response guarantees that the plate exists.
      if (_plateWells?.[cellLineLot.plate_barcode]) {
        if (_plateWells[cellLineLot.plate_barcode]?.[cellLineLot.well]) {
          _plateWells[cellLineLot.plate_barcode][cellLineLot.well] = {
            ..._plateWells[cellLineLot.plate_barcode][cellLineLot.well],
            cellLineLot: cellLineLot.cell_line_lot,
          }
        } else {
          _plateWells[cellLineLot.plate_barcode][cellLineLot.well] = {
            well: cellLineLot.well,
            isLive: false,
            cellLineLot: cellLineLot.cell_line_lot,
          }
        }
      }
    }, parseDataResponse.data)
  } else if (parseDataResponse.type === 'cell_lines') {
    forEach((cellLine: LinkLotsParsedCellLine) => {
      // We assume the back-end response guarantees that the plate exists.
      if (_plateWells?.[cellLine.plate_barcode]) {
        if (_plateWells[cellLine.plate_barcode]?.[cellLine.well]) {
          _plateWells[cellLine.plate_barcode][cellLine.well] = {
            ..._plateWells[cellLine.plate_barcode][cellLine.well],
            cellLine: cellLine.cell_line,
          }
        } else {
          _plateWells[cellLine.plate_barcode][cellLine.well] = {
            well: cellLine.well,
            isLive: false,
            cellLine: cellLine.cell_line,
          }
        }
      }
    }, parseDataResponse.data)
  }

  return map(
    culture_plate => ({
      plateFormat: culture_plate.plate_format,
      plateBarcode: culture_plate.user_facing_id,
      wells: values(_plateWells[culture_plate.user_facing_id]),
    }),
    parseDataResponse.culture_plates,
  )
}
