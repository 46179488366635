/*
  Component to edit key value pairs.
  Understands types and handles validation.
  A dataSchema is highly recommended. Without it, everything is presumed to be a string.
  dataSchema can be produced from pydantic model classes with Model.schema().
*/

import cx from 'classnames'
import { find, get, keys, map, set } from 'lodash/fp'
import { useState } from 'react'

import Checkbox from '~/components/Checkbox'
import DateInput from '~/components/DateInput'
import HelpPopover from '~/components/HelpPopover'
import Input from '~/components/Input'
import InputWithUnits from '~/components/InputWithUnits'
import Pill from '~/components/Pill'
import Select from '~/components/Select'
import HelpIcon from '~/components/icons/HelpIcon'
import { sort } from '~/utils/array'
import {
  getFieldDefault,
  getFieldDescription,
  getFieldType,
  getFieldTypeObjByRef,
} from '~/utils/jsonSchema'
import { camelCaseToTitleCase } from '~/utils/string'
import { getDisplayStringForField, getStringValueForField } from '~/utils/types'

import { ManagedProcessItemIDSelect } from '~/pages/Workcell/components/processItem/ProcessItemSelect/ManagedProcessItemIDSelect'
import { ManagedProcessItemMultiSelect } from '~/pages/Workcell/components/processItem/ProcessItemSelect/ProcessItemMultiSelect'
import { getProcessItemFiltersFromStructuredDataField } from '~/pages/Workcell/components/processItem/ProcessItemSelect/getSelectFiltersFromStructuredDataField'
import { ReagentSelect } from '~/pages/Workcell/components/processItem/ReagentSelect'
import { JsonSchema } from '~/types/JsonSchema.interface'
import cs from './structured_data_form.scss'
import validateNewValue from './validateNewValue'

export type StructuredDataFormData = { [key: string]: unknown }

export interface StructuredDataFormProps {
  className?: string
  // Key value pairs
  data: StructuredDataFormData | null
  // The actual fields to display.
  // If not specified, ALL fields will be displayed in alphabetical order.
  keysToDisplay?: string[]
  // Whenever a value is edited (and passes validation), this function is called with
  // (key, newValue)
  // If an empty string is passed, null is sent instead.
  onEdit: (key: string, newValue: unknown) => void
  // If dataSchema is absent, everything is assumed to be a string.
  // Some value types may not display properly.
  dataSchema?: JsonSchema
  convertKeysToTitleCase?: boolean
  variant: 'wideValues' | 'vertical'
  // Allows caller to style the inputs, in particular the width.
  // Includes multiple elements such as inputs, selects, popovers, etc.
  inputsClassName?: string
  // Allows caller to style the display text, when editing is False.
  fieldClassName?: string
}

const StructuredDataForm = ({
  className,
  data,
  keysToDisplay,
  onEdit,
  dataSchema,
  convertKeysToTitleCase,
  variant = 'wideValues',
  inputsClassName,
  fieldClassName: fieldClassName,
}: StructuredDataFormProps): JSX.Element => {
  const [errors, setErrors] = useState({})

  let _keys = keysToDisplay
  const configProperties = dataSchema?.properties

  if (!_keys) {
    if (dataSchema) {
      _keys = keys(dataSchema.properties)
    } else {
      _keys = sort(keys(data)) || []
    }
  }

  const getFieldSchemaProperty = (key: string, property: string) =>
    get([key, property], configProperties)

  const handleFieldChange = (key: string, newValue: string) => {
    try {
      const value = validateNewValue(key, newValue, dataSchema)
      // onEdit shouldn't be null when handleConfigChange is called. In theory, this could happen
      // if a component specifies `editing=true` without an `onEdit` handler. We could refactor
      // this component to make the types more comprehensive, or at least change it to fail faster.
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      onEdit(key, value)
      setErrors(set(key, null, errors))
      return
    } catch (error) {
      setErrors(set(key, (error as { message?: string }).message, errors))
    }
  }

  const renderEditableInputComponent = (key: string) => {
    const currentValue = getStringValueForField(
      get(key, data),
      getFieldType(key, dataSchema),
    )
    const onChange = value => handleFieldChange(key, value)

    const fieldType = getFieldType(key, dataSchema)
    switch (fieldType) {
      case 'sample_plate_array': {
        let plateIds: string[] = []
        try {
          plateIds = JSON.parse(currentValue)
        } catch (error) {
          console.error('Error parsing sample_plate_array', error)
        }
        return (
          <ManagedProcessItemMultiSelect
            renderProcessItemViz={true}
            onSelectedItemUuidsUpdate={(plateIds: string[]) => {
              onChange(JSON.stringify(plateIds))
            }}
            processItemFilters={{
              is_empty: false,
              types: ['culture_plate'],
            }}
            selectedItemUuids={plateIds}
          />
        )
      }
      case 'assay_plate':
      case 'sample_plate': {
        const selectFilters = getProcessItemFiltersFromStructuredDataField(
          key,
          fieldType,
          dataSchema,
        )

        const defaultValue = getFieldDefault(key, dataSchema!) as
          | unknown
          | null
          | undefined

        return (
          <ManagedProcessItemIDSelect
            selectedPlateId={currentValue == '' ? defaultValue : currentValue}
            onSelectedPlateIdChanged={(plateId: string | null) => {
              onChange(plateId)
            }}
            hideLabel={true}
            processItemFilters={selectFilters}
          />
        )
      }
      case 'reagent': {
        return (
          <ReagentSelect
            onSelectReagent={(reagent: string) => {
              onChange(reagent)
            }}
            selectedReagent={currentValue}
            hideLabel={true}
            // HACK: Currently, this is styled to share the same width as ManagedProcessItemIDSelect.
            // This means that both will be mismatched with the inputs below - these are 350px (to
            // accommodate extra info in ManagedProcessItemIDSelect), whereas the inputs are 300px.
            wide={true}
          />
        )
      }
      case 'enum': {
        // TODO: The types are a little funny here becaus TS can't determine that
        // `getFieldType() != string` implies `dataSchema != null`.
        // The way to fix this would be to collect all of these `getFieldTypeObjByRef`, etc
        // methods onto a single `getField(key, dataSchema)` method.
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const typeObj = getFieldTypeObjByRef(key, dataSchema!)
        const validValues = get('enum', typeObj)

        const viewOptions = map(
          value => ({
            key: value,
            label: value,
          }),
          validValues,
        )
        const defaultValue = getFieldDefault(key, dataSchema!) as
          | unknown
          | null
          | undefined

        const activeItem = find(['key', currentValue || defaultValue], viewOptions)
        return (
          <Select
            items={viewOptions}
            itemKey='key'
            itemLabelKey='label'
            activeItem={activeItem}
            onChange={value => onChange(value.key)}
            triggerClassName={cx(cs.trigger, inputsClassName)}
            popoverClassName={cx(cs.popover, inputsClassName, cs[variant])}
          />
        )
      }

      case 'select_from_string_choices': {
        const choices = getFieldSchemaProperty(key, 'choices')

        const viewOptions = map(
          value => ({
            key: value,
            label: value,
          }),
          choices,
        )

        const itemMatchesQuery = (value, queryLowerCase) =>
          value.label && value.label.toLowerCase().includes(queryLowerCase)

        const activeItem = find(['key', currentValue], viewOptions)
        return (
          <Select
            items={viewOptions}
            itemKey='key'
            itemLabelKey='label'
            activeItem={activeItem}
            onChange={value => onChange(value.key)}
            triggerClassName={cx(cs.trigger, inputsClassName)}
            popoverClassName={cx(cs.popover, inputsClassName, cs[variant])}
            filterable
            itemMatchesQuery={itemMatchesQuery}
          />
        )
      }
      case 'boolean': {
        let booleanValue = get(key, data)
        // TODO: See above
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const defaultValue = getFieldDefault(key, dataSchema!) as
          | boolean
          | null
          | undefined

        if (booleanValue === undefined && defaultValue) {
          booleanValue = true
        }
        return (
          <div onClick={() => onChange(!booleanValue)}>
            <Checkbox
              checked={(booleanValue as boolean | null | undefined) ?? false}
              className={cx(cs.checkboxContainer, inputsClassName)}
            />
          </div>
        )
      }
      case 'number':
      case 'integer':
      case 'unit_float': {
        let tokens: string[] = []

        const unit = getFieldSchemaProperty(key, 'unit')

        // TODO(damon): This is *very* janky
        if (
          fieldType === 'unit_float' ||
          (fieldType === 'number' && unit != null) ||
          (fieldType === 'integer' && unit != null)
        ) {
          if (
            currentValue === null ||
            currentValue === '' ||
            currentValue === undefined
          ) {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            const defaultValue = getFieldDefault(key, dataSchema!) as
              | number
              | null
              | undefined

            tokens = [defaultValue != null ? String(defaultValue) : '', unit]
          } else {
            tokens = currentValue.split(' ')
          }

          return (
            <InputWithUnits
              className={inputsClassName}
              value={tokens[0]}
              unit={unit}
              changeOnBlur
              onChange={(newValue: string) => {
                if (fieldType === 'number') {
                  onChange(newValue.split(' ')[0])
                } else {
                  onChange(newValue)
                }
              }}
            />
          )
        } else {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          const defaultValue = getFieldDefault(key, dataSchema!) as
            | number
            | null
            | undefined

          return (
            <Input
              inputClassName={cx(cs.input, inputsClassName)}
              // Note that because changeOnBlur is true, when the user deletes all of the text,
              // this does NOT reset the textbox to the default value. That's an important
              // regression to avoid.
              // It would reset the text to the default value after the user "blurs" (changes
              // input focus), but that's a reasonable behavior.
              value={currentValue == '' ? defaultValue : currentValue}
              onChange={onChange}
              changeOnBlur
            />
          )
        }
      }
      case 'string_datetime': {
        const defaultValue = getFieldDefault(key, dataSchema!) as
          | unknown
          | null
          | undefined

        return (
          <DateInput
            className={cs.dateInput}
            value={currentValue || defaultValue}
            onChange={onChange}
            placeholder='Select date'
            showTime
            showActionsBar
          />
        )
      }
      case 'int_array':
      case 'json':
      case 'string_array': {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        let defaultValue = getFieldDefault(key, dataSchema!) as
          | unknown
          | null
          | undefined

        if (defaultValue) {
          defaultValue = JSON.stringify(defaultValue)
        }

        return (
          <Input
            inputClassName={cx(cs.input, inputsClassName)}
            value={currentValue == '' ? defaultValue : currentValue}
            onChange={onChange}
            changeOnBlur
          />
        )
      }
      default: {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const defaultValue = getFieldDefault(key, dataSchema!) as
          | unknown
          | null
          | undefined

        return (
          <Input
            inputClassName={cx(cs.input, inputsClassName)}
            value={currentValue == '' ? defaultValue : currentValue}
            onChange={onChange}
            changeOnBlur
          />
        )
      }
    }
  }

  const renderFieldHelpContent = (key: string) => (
    <div className={cs.helpContent}>
      <div className={cs.fieldName}>{key}</div>
      <Pill
        small
        type='accent'
        label={getFieldType(key, dataSchema)}
        className={cs.type}
      />
      <div className={cs.description}>{getFieldDescription(key, dataSchema)}</div>
      <div className={cs.default}>
        Default:{' '}
        {getDisplayStringForField(
          getFieldDefault(key, dataSchema, true),
          getFieldType(key, dataSchema),
        )}
      </div>
    </div>
  )

  const renderEditableValue = (key: string) => {
    const error = errors[key]
    return (
      <div className={cs.editableValue}>
        {renderEditableInputComponent(key)}
        {error && <div className={cx(cs.error, cs.inputs)}>{error}</div>}
      </div>
    )
  }

  const renderEditableField = (key: string) => {
    const displayKey = convertKeysToTitleCase ? camelCaseToTitleCase(key) : key

    if (variant === 'vertical') {
      return (
        <div className={cx(cs.field, fieldClassName)} key={key}>
          <div className={cs.keyLabel}>
            {displayKey}
            <HelpPopover
              text={<HelpIcon className={cs.icon} />}
              helpContent={renderFieldHelpContent(key)}
              interactionKind='hover'
              smallText={false}
              className={cs.iconContainer}
            />
          </div>
          {renderEditableValue(key)}
        </div>
      )
    }

    return (
      <div className={cx(cs.field, fieldClassName)} key={key}>
        <div className={cs.key}>
          <HelpPopover
            text={displayKey}
            helpContent={renderFieldHelpContent(key)}
            interactionKind='hover'
            smallText={false}
          />
        </div>
        {renderEditableValue(key)}
      </div>
    )
  }

  return (
    <div className={cx(cs.structuredDataForm, className, cs[variant])}>
      {_keys.map(renderEditableField)}
    </div>
  )
}

StructuredDataForm.defaultProps = {
  variant: 'wideValues',
}

export default StructuredDataForm
