import cx from 'classnames'
import { get, isString } from 'lodash/fp'
import { Link } from 'react-router-dom'
import { EnabledOperatorConfigs } from '~/api/operatorActions/shared'
import { OPERATOR_ACTION_ICONS, getOperatorAction } from '~/utils/operatorAction'
import cs from './operator_action_option.scss'

export const OperatorActionOption = ({
  actionName,
  operatorActionConfigs,
  variant = 'default',
}: {
  actionName: string
  operatorActionConfigs: EnabledOperatorConfigs
  variant?: 'default' | 'condensed'
}) => {
  const action = getOperatorAction(actionName)

  if (!action) {
    return null
  }

  if ('filler' in action) {
    return <div className={cs.operatorActionOption} key={action.id} />
  }

  let iconName = action.icon

  const customIconName = get([action.key, 'displayIcon'], operatorActionConfigs)
  if (isString(customIconName) && customIconName !== '') {
    iconName = get([action.key, 'displayIcon'], operatorActionConfigs) as string
  }

  const actionIcon = OPERATOR_ACTION_ICONS[iconName]

  return (
    <Link
      to={action.link}
      className={cx(cs.operatorActionOption, cs[variant])}
      key={action.name}
    >
      <div className={cs.actionInner}>
        <img className={cs.icon} src={actionIcon} alt={action.name} />
        <div className={cs.text}>
          <div className={cs.actionName}>
            {get([action.key, 'displayName'], operatorActionConfigs) || action.name}
          </div>
          <div className={cs.actionDescription}>
            {get([action.key, 'displayDescription'], operatorActionConfigs) ||
              action.shortDescription}
          </div>
        </div>
      </div>
    </Link>
  )
}
