import cx from 'classnames'
import { identity, keys, pickBy, size } from 'lodash/fp'
import { useState } from 'react'
import { Link } from 'react-router-dom'
import PopupMenu from '~/components/PopupMenu'
import Table from '~/components/Table'
import Toaster from '~/components/Toaster'
import Button from '~/components/buttons/Button'
import LeftArrowIcon from '~/components/icons/LeftArrowIcon'
import RightArrowIcon from '~/components/icons/RightArrowIcon'
import { displayCount } from '~/utils/string'
import {
  CultureViewModel,
  getLiveCulturesWithIssues,
} from '../../events/ViewModels/CultureViewModel'
import { IssueType } from '../../events/ViewModels/CultureViewModel'
import { ViewModels } from '../../events/ViewModels/ViewModels'
import { generateEventTime } from '../../events/data/eventTimes'
import { useCommandCenterEventContext } from '../CommandCenterEventsContext'
import { CultureDatasetImagesDialog } from '../CultureDatasetImagesDialog/CultureDatasetImagesDialog'
import { CultureGrowthChart } from '../CultureGrowthChart/CultureGrowthChart'
import { CultureLink } from '../CultureLink/CultureLink'
import { IssueLabel } from '../IssueLabel/IssueLabel'
import { SimpleSelector } from '../SimpleSelector'
import cs from './needs_attention.scss'

type SelectedIssueType = 'ALL' | IssueType

const ISSUE_TYPE_TO_MESSAGE: { [issueType in SelectedIssueType]: string } = {
  ALL: 'Need Attention',
  CELL_DEATH: 'with Cell Death Detected',
  OVER_CONFLUENT: 'Over-Confluent',
}

export function NeedsAttention({ viewModels }: { viewModels: ViewModels }) {
  const [selectedIssueType, setSelectedIssueType] = useState<SelectedIssueType>('ALL')
  const [selectedRowIds, setSelectedRowIds] = useState({})

  const culturesWithIssues = getLiveCulturesWithIssues(
    viewModels.today,
    viewModels.cultures,
    selectedIssueType === 'ALL' ? null : selectedIssueType,
  )

  return (
    <div className={cs.needsAttention}>
      <NeedsAttentionHeader
        selectedCultureIDs={culturesWithIssues.map(culture => culture.id)}
        selectedIssueType={selectedIssueType}
      />
      <NeedsAttentionFilters
        selectedType={selectedIssueType}
        onSelectedTypeChanged={issueType => {
          setSelectedIssueType(issueType)
        }}
        selectedRowIds={selectedRowIds}
        setSelectedRowIds={setSelectedRowIds}
        today={viewModels.today}
      />
      <NeedsAttentionTable
        today={viewModels.today}
        culturesWithIssues={culturesWithIssues}
        selectedRowIds={selectedRowIds}
        setSelectedRowIds={setSelectedRowIds}
      />
    </div>
  )
}

function NeedsAttentionFilters({
  selectedType,
  onSelectedTypeChanged,
  selectedRowIds,
  setSelectedRowIds,
  today,
}: {
  selectedType: SelectedIssueType
  selectedRowIds: { [key: string]: boolean }
  setSelectedRowIds(...args: unknown[]): unknown
  onSelectedTypeChanged: (selectedType: SelectedIssueType) => void
  today: number
}) {
  const { publishEvents } = useCommandCenterEventContext()
  const options = [
    { id: 'ALL', value: 'All Issues' },
    { id: 'CELL_DEATH', value: 'Cell Death' },
    { id: 'OVER_CONFLUENT', value: 'Over-Confluent' },
  ]

  // TODO: This non-null assertion is unsafe
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const selectedOption = options.find(option => option.id === selectedType)!

  const selectedRowIdsArr = keys(pickBy(identity, selectedRowIds))

  return (
    <div className={cs.needsAttentionFilters}>
      <SimpleSelector
        label={'Issue Type'}
        options={options}
        selectedOption={selectedOption}
        onOptionSelected={option => {
          // TODO: This type cast is somewhat unsafe but fine for now
          // Ideally we'd use generics
          onSelectedTypeChanged(option.id as SelectedIssueType)
        }}
      />
      <div className={cs.spacer} />
      <PopupMenu
        options={[
          {
            label: 'Passage Cultures...',
            action: () => {
              Toaster.show({
                message: `Scheduled passage for ${displayCount(
                  'culture',
                  size(selectedRowIdsArr),
                )}`,
                intent: 'success',
              })
              publishEvents([
                {
                  kind: 'UserScheduledPassages',
                  cultureIDs: selectedRowIdsArr,
                  at: generateEventTime({
                    eventDay: today,
                    eventKind: 'UserScheduledPassages',
                  }),
                  day: today,
                },
              ])
              setSelectedRowIds({})
            },
          },
          {
            label: 'Terminate Cultures...',
            action: () => {
              Toaster.show({
                message: `Terminated ${displayCount(
                  'culture',
                  size(selectedRowIdsArr),
                )}`,
                intent: 'success',
              })
              publishEvents([
                {
                  kind: 'UserTerminatedCultures',
                  cultureIDs: selectedRowIdsArr,
                  at: generateEventTime({
                    eventDay: today,
                    eventKind: 'UserTerminatedCultures',
                  }),
                  day: today,
                },
              ])
              setSelectedRowIds({})
            },
          },
          {
            label: 'Continue Cultures as Planned...',
            action: () => {
              Toaster.show({
                message: `Continue ${displayCount(
                  'culture',
                  size(selectedRowIdsArr),
                )} as planned`,
                intent: 'success',
              })
              publishEvents([
                {
                  kind: 'UserMarkedCulturesOkay',
                  cultureIDs: selectedRowIdsArr,
                  at: generateEventTime({
                    eventDay: today,
                    eventKind: 'UserMarkedCulturesOkay',
                  }),
                  day: today,
                },
              ])
              setSelectedRowIds({})
            },
          },
        ]}
        trigger={
          <div
            className={cx(
              cs.bulkUpdateTrigger,
              !size(selectedRowIdsArr) && cs.disabled,
            )}
          >
            Update {displayCount('Culture', size(selectedRowIdsArr))}...
          </div>
        }
        popoverClassName={cs.bulkUpdateMenu}
        minimal
      />
    </div>
  )
}

const NeedsAttentionHeader = ({
  selectedCultureIDs,
  selectedIssueType,
}: {
  selectedCultureIDs: string[]
  selectedIssueType: SelectedIssueType
}) => {
  return (
    <div className={cs.header}>
      <Link to={'/command-center/live-cultures/'} className={cs.goBackLabel}>
        Back to Live Cultures
      </Link>
      <div className={cs.title}>
        <div className={cs.titleContainer}>
          <span className={cs.titleInner}>
            {displayCount('Culture', selectedCultureIDs.length)}{' '}
            {ISSUE_TYPE_TO_MESSAGE[selectedIssueType]}
          </span>
        </div>
      </div>
    </div>
  )
}

const TABLE_COLUMNS = [
  {
    name: 'Cell Culture',
    width: 150,
    render: (culture: CultureViewModel) => (
      <CultureLink
        id={culture.id}
        name={culture.name}
        cellLine={culture.cellLine}
        className={cs.cultureLink}
      />
    ),
  },
  // TODO: The mocks don't include titles for these columns, but the <Table> component
  // relies on a name for a key. This came up in ReagentPerformance as well - it would
  // be nice to decouple the title's rendering from its use as a key
  {
    name: 'Issues',
    width: 200,
    render: (culture: CultureViewModel) =>
      culture.datasets[culture.datasets.length - 1].cellDeathDetected ? (
        <div className={cs.issues}>
          <IssueLabel
            label='Cell Death'
            messageKind='error'
            flushLeft
            className={cs.issueLabel}
          />
          <IssueLabel
            label='Contamination'
            messageKind='error'
            flushLeft
            className={cs.issueLabel}
          />
        </div>
      ) : (
        <div className={cs.issues}>
          <IssueLabel
            label='Over-confluent'
            messageKind='warning'
            flushLeft
            className={cs.issueLabel}
          />
        </div>
      ),
  },
  {
    name: 'Growth',
    width: 150,
    render: (culture: CultureViewModel) => (
      <CultureGrowthChart culture={culture} className={cs.lineGraph} />
    ),
  },
  {
    name: 'Confluency',
    width: 150,
    render: (culture: CultureViewModel) => (
      <div className={cs.confluencyText}>
        {culture.datasets[culture.datasets.length - 1].confluency}%
      </div>
    ),
  },
  {
    name: 'Most Recent Image',
    width: 150,
    render: (culture: CultureViewModel) => (
      <img
        className={cs.thumbnail}
        src={culture.datasets[culture.datasets.length - 1].thumbnails[0][0]}
      />
    ),
  },
]

// TODO: Inline this - this was refactored down to a simple state
interface IssueDialogState {
  index: number
}

function NeedsAttentionTable({
  today,
  culturesWithIssues,
  selectedRowIds,
  setSelectedRowIds,
}: {
  today: number
  culturesWithIssues: CultureViewModel[]
  selectedRowIds: { [key: string]: boolean }
  setSelectedRowIds(...args: unknown[]): unknown
}) {
  const [dialogState, setDialogState] = useState<IssueDialogState | null>(null)
  const dialogCulture = dialogState ? culturesWithIssues[dialogState.index] : null

  return (
    <>
      <CultureDatasetImagesDialog
        header={
          <DialogHeader
            dialogState={dialogState}
            cultures={culturesWithIssues}
            onUpdateIndex={(index: number) => {
              setDialogState({ index })
            }}
            today={today}
          />
        }
        today={today}
        culture={dialogState ? culturesWithIssues[dialogState.index] : null}
        dataset={
          dialogCulture
            ? dialogCulture.datasets[dialogCulture.datasets.length - 1]
            : null
        }
        isOpen={dialogState != null}
        onClose={() => {
          setDialogState(null)
        }}
      />
      <div className={cs.tableContainer}>
        <Table
          flushLeft
          columns={TABLE_COLUMNS}
          data={culturesWithIssues}
          rowPaddingVariant='rowPaddingLow'
          rowKey='id'
          hideRowBorders
          noDataMessage='No plates need attention'
          onRowClick={(_: CultureViewModel, index: number) => {
            setDialogState({ index })
          }}
          selectableProps={{
            onRowsSelected: setSelectedRowIds,
            selectedRowIds: selectedRowIds,
          }}
          className={cs.table}
        />
      </div>
    </>
  )
}

function DialogHeader({
  dialogState,
  cultures,
  onUpdateIndex,
  today,
}: {
  dialogState: IssueDialogState | null
  cultures: CultureViewModel[]
  onUpdateIndex: (index: number) => void
  today: number
}) {
  const { publishEvents } = useCommandCenterEventContext()

  if (!dialogState) {
    return null
  }

  const culture = cultures[dialogState.index]
  const dataset = culture.datasets[culture.datasets.length - 1]
  const issueMessage = dataset.cellDeathDetected
    ? 'Cell death and contamination detected'
    : 'Over-confluent culture detected'

  return (
    <div>
      <div className={cs.dialogHeader}>
        <IssueLabel
          label={issueMessage}
          messageKind={dataset.cellDeathDetected ? 'error' : 'warning'}
          flushLeft
          className={cs.issueLabel}
        />
        <div className={cs.dialogNav}>
          <LeftArrowIcon
            // TODO: Add a disabled prop
            className={cs.dialogIcon}
            onClick={() => {
              if (dialogState.index > 0) {
                onUpdateIndex(dialogState.index - 1)
              }
            }}
          />
          <span className={cs.navText}>{cultures.length} Issues Left</span>
          <RightArrowIcon
            // TODO: Add a disabled prop
            className={cs.dialogIcon}
            onClick={() => {
              if (dialogState.index < cultures.length - 1) {
                onUpdateIndex(dialogState.index + 1)
              }
            }}
          />
        </div>
      </div>
      <div className={cs.dialogActions}>
        <Button
          label='Continue as Planned...'
          onClick={() => {
            publishEvents([
              {
                kind: 'UserMarkedCulturesOkay',
                day: today,
                at: generateEventTime({
                  eventDay: today,
                  eventKind: 'UserMarkedCulturesOkay',
                }),
                cultureIDs: [culture.id],
              },
            ])
          }}
          className={cs.dialogButton}
        />
        {dataset.cellDeathDetected ? (
          <Button
            type='primary'
            label='Terminate Culture'
            onClick={() => {
              publishEvents([
                {
                  kind: 'UserTerminatedCultures',
                  day: today,
                  at: generateEventTime({
                    eventDay: today,
                    eventKind: 'UserTerminatedCultures',
                  }),
                  cultureIDs: [culture.id],
                },
              ])
            }}
            className={cs.dialogButton}
          />
        ) : (
          <Button
            type='primary'
            label='Schedule Passage'
            onClick={() => {
              publishEvents([
                {
                  kind: 'UserScheduledPassages',
                  day: today,
                  at: generateEventTime({
                    eventDay: today,
                    eventKind: 'UserScheduledPassages',
                  }),
                  cultureIDs: [culture.id],
                },
              ])
            }}
            className={cs.dialogButton}
          />
        )}
      </div>
    </div>
  )
}
