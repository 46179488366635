// See /ui/buttons for Button playground

// A minimal button with no border or background color.

import PropTypes from 'prop-types'
import React, { MouseEventHandler } from 'react'
import Button from './Button'

interface MinimalButtonProps {
  className?: string
  label?: string
  to?: string
  IconComponent?: React.ElementType
  onClick?: MouseEventHandler<HTMLButtonElement> | ((...args: unknown[]) => unknown)
  type?: 'primary' | 'normal' | 'danger'
  variant?: 'tiny' | 'normal'
  disabled?: boolean
  external?: boolean
  appearNormalIfNotHovered?: boolean
  popoverMessageIfDisabled?: string
}

const MinimalButton = ({
  label,
  onClick,
  type,
  className,
  IconComponent,
  disabled,
  to,
  external,
  // This is tiny by default for legacy reasons.
  // TODO: make normal the default after making sure all current callers use "tiny".
  variant = 'tiny',
  appearNormalIfNotHovered,
  popoverMessageIfDisabled,
}: MinimalButtonProps) => {
  return (
    <Button
      className={className}
      label={label}
      to={to}
      IconComponent={IconComponent}
      onClick={onClick}
      type={type}
      disabled={disabled}
      external={external}
      variant={variant === 'tiny' ? 'minimalTiny' : 'minimal'}
      appearNormalIfNotHovered={appearNormalIfNotHovered}
      popoverMessageIfDisabled={popoverMessageIfDisabled}
    />
  )
}

MinimalButton.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  // If to is provided, wrap button in a Link.
  to: PropTypes.string,
  IconComponent: PropTypes.oneOfType([
    PropTypes.instanceOf(React.Component),
    PropTypes.func,
  ]),
  onClick: PropTypes.func,
  type: PropTypes.oneOf(['primary', 'normal', 'danger']),
  variant: PropTypes.oneOf(['tiny', 'normal']),
  appearNormalIfNotHovered: PropTypes.bool,
  disabled: PropTypes.bool,
  external: PropTypes.bool,
}

export default MinimalButton
