import cx from 'classnames'
import { compact, keys } from 'lodash/fp'
import PropTypes from 'prop-types'
import { useContext, useEffect, useState } from 'react'
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom'

import workcellAPI from '~/api/desktop/workcell'
import { Instrument } from '~/common.interface'
import Tabs from '~/components/Tabs'
import WorkcellStatusContext from '~/pages/Workcell/WorkcellStatusContext'
import {
  experimentalEnableWorkflows,
  getAllInstrumentStatus,
  getNumDefaultQueuedRoutines,
  getNumFutureRoutines,
} from '~/pages/Workcell/utils/workcellStatus'
import { getComparatorString } from '~/utils/array'
import { useIsMounted } from '~/utils/hooks/useIsMounted'
import useTabsWithUrl from '~/utils/hooks/useTabsWithUrlPart'
import FutureRoutines from './FutureRoutines'
import LiveTimeline from './LiveTimeline'
import QueuedRoutines from './QueuedRoutines'
import WorkflowInstances from './Workflows'
import cs from './schedule.scss'

interface ScheduleProps {
  className?: string
  instrumentStatus?: object
  routineState?: {
    routineIdsScheduled?: number[]
    routineIdsInProgress?: number[]
  }
}

const Schedule = ({ className }: ScheduleProps) => {
  const workcellStatus = useContext(WorkcellStatusContext)
  const workflowsEnabled = experimentalEnableWorkflows(workcellStatus)
  const allInstrumentStatus = getAllInstrumentStatus(workcellStatus)
  const [instruments, setInstruments] = useState<Instrument[] | null>(null)
  const match = useRouteMatch()
  const isMounted = useIsMounted()

  const fetchInstruments = () => {
    workcellAPI.getInstruments().then(_instruments => {
      if (isMounted()) setInstruments(_instruments)
    })
  }

  const getTabs = () => {
    const numFutureRoutines = getNumFutureRoutines(workcellStatus)
    const numDefaultQueuedRoutines = getNumDefaultQueuedRoutines(workcellStatus)

    return compact([
      {
        key: 'live-timeline',
        title: 'Live Timeline',
      },
      {
        key: 'queued-routines',
        title: `Queued Routines (${numDefaultQueuedRoutines})`,
      },
      {
        key: 'future-routines',
        title: `Future Routines (${numFutureRoutines})`,
      },
      workflowsEnabled && {
        key: 'workflows',
        title: 'Workflows',
      },
    ])
  }

  const [tabs, selectedTabId, handleTabChange] = useTabsWithUrl(getTabs())

  useEffect(() => {
    fetchInstruments()
  }, [getComparatorString(keys(allInstrumentStatus))])

  return (
    <div className={cx(className, cs.schedule)}>
      <Tabs
        tabs={tabs}
        selectedTabId={selectedTabId}
        className={cs.tabs}
        panelClassName={cs.tabPanel}
        onlyRenderWhenViewed
        empty
        nested
        onTabChange={handleTabChange}
      />
      <Switch>
        <Route
          exact
          path={match.path}
          render={() => <Redirect to={`${match.path}/live-timeline`} />}
        />
        <Route
          path={`${match.path}/live-timeline`}
          render={() => <LiveTimeline instruments={instruments} />}
        />
        <Route
          path={`${match.path}/queued-routines`}
          render={() => <QueuedRoutines />}
        />
        <Route
          path={`${match.path}/future-routines`}
          render={() => <FutureRoutines />}
        />
        <Route path={`${match.path}/workflows`} render={() => <WorkflowInstances />} />
      </Switch>
    </div>
  )
}

Schedule.propTypes = {
  className: PropTypes.string,
  instrumentStatus: PropTypes.shape({}),
  routineState: PropTypes.shape({
    routineIdsScheduled: PropTypes.arrayOf(PropTypes.number),
    routineIdsInProgress: PropTypes.arrayOf(PropTypes.number),
  }),
}

export default Schedule
