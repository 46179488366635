import cx from 'classnames'
import PropTypes from 'prop-types'

import cs from './no_org.scss'

const NoOrg = ({ className }) => {
  return (
    <div className={cx(className, cs.noOrg)}>
      <div className={cs.text}>
        <div className={cs.title}>Thanks for your interest in Monomer.</div>
        <div className={cs.subtitle}>
          The Monomer platform is currently in closed beta. Please&nbsp;
          <a href='mailto:hello@monomerbio.com' className={cs.link}>
            reach out
          </a>
          &nbsp;to get access.
        </div>
      </div>
    </div>
  )
}

NoOrg.propTypes = {
  className: PropTypes.string,
}

export default NoOrg
