import { AllowedInstrumentForItemTypeResponse } from '~/api/desktop/workcell.interface'
import {
  LoadUnloadItemsProcessItemType,
  convertLoadUnloadItemsProcessItemTypeToProcessItemType,
} from './LoadUnloadItemsProcessItemType.interface'

export const getAllowedInstrumentsForItemType = (
  workcellAllowedInstrumentsForItemType,
  itemType: LoadUnloadItemsProcessItemType,
): AllowedInstrumentForItemTypeResponse[] | undefined => {
  if (workcellAllowedInstrumentsForItemType === undefined) {
    return undefined
  }
  const processItemType =
    convertLoadUnloadItemsProcessItemTypeToProcessItemType(itemType)

  return workcellAllowedInstrumentsForItemType[processItemType]
}
