import { ProcessItem } from '~/common.interface'
import { getShortDisplayName } from '~/utils/processItems/common'

export function processItemMatchesQuery(
  item: ProcessItem,
  queryLowerCase: string,
): boolean {
  return (
    getShortDisplayName(item).toLowerCase().includes(queryLowerCase) ||
    item.uuid.toLowerCase().includes(queryLowerCase)
  )
}
