import cx from 'classnames'

import { components } from '~/api/desktop/generated-schema'
import cs from './tabular_data_schema_display.scss'

export type TabularDataSchema = components['schemas']['TabularDataSchema']

export interface TabularDataSchemaDisplayProps {
  className?: string
  schema: TabularDataSchema
}

const TabularDataSchemaDisplay = ({
  className,
  schema,
}: TabularDataSchemaDisplayProps) => {
  return (
    <div className={cx(className, cs.tabularDataSchemaDisplay)}>
      <div className={cs.properties}>
        {schema.properties.map((property, index) => {
          return (
            <div key={index} className={cs.property}>
              <span className={cs.propertyName}>
                {property.name}
                {property.is_optional && ` (optional)`}
              </span>{' '}
              - {property.description}
            </div>
          )
        })}
        <div className={cs.example}>
          <div className={cs.exampleTitle}>Example:</div>
          <div className={cs.exampleValue}>{schema.example}</div>
        </div>
      </div>
    </div>
  )
}

export default TabularDataSchemaDisplay
