import {} from 'lodash/fp'
import pluralize from 'pluralize'
import { components } from '~/api/desktop/generated-schema'
import { LinkLotsLookupDataResponseErrors } from '~/api/operatorActions/linkCellLineLotsToCultures'
import { displayCount } from '~/utils/string'
import { ErrorGroup } from '../../GroupedErrorTable'
import { generateErrorGroups } from '../../generateErrorGroups'
import {} from './LinkLotsPlatePreview'

type LinkLotsLookupCellLineError = components['schemas']['LinkLotsLookupCellLineError']
type LinkLotsLookupCellLineLotError =
  components['schemas']['LinkLotsLookupCellLineLotError']

type LinkLotsLookupBenchlingEntityNotRegistered =
  components['schemas']['LinkLotsLookupBenchlingEntityNotRegistered']
type LinkLotsLookupBenchlingFieldEmpty =
  components['schemas']['LinkLotsLookupBenchlingFieldEmpty']
type LinkLotsLookupBenchlingInvalidPassageNumber =
  components['schemas']['LinkLotsLookupBenchlingInvalidPassageNumber']
type LinkLotsLookupEntityNotFound =
  components['schemas']['LinkLotsLookupEntityNotFound']

type LookupDataErrorType =
  | 'cellLineEntityNotRegistered'
  | 'cellLineFieldEmpty'
  | 'cellLineInvalidPassageNumber'
  | 'cellLineEntityNotFound'
  | 'cellLineLotEntityNotRegistered'
  | 'cellLineLotFieldEmpty'
  | 'cellLineLotInvalidPassageNumber'
  | 'cellLineLotEntityNotFound'

const _getGroupHeaderForErrorType = (
  errorType: LookupDataErrorType,
  numErrors: number,
): string => {
  if (errorType === 'cellLineEntityNotRegistered') {
    return `${displayCount('cell line', numErrors)}  ${pluralize('is', numErrors)} not properly registered in Benchling.`
  } else if (errorType === 'cellLineFieldEmpty') {
    return `${displayCount('cell line', numErrors)}  ${pluralize('is', numErrors)} missing required fields in Benchling.`
  } else if (errorType === 'cellLineInvalidPassageNumber') {
    return `${displayCount('cell line', numErrors)} ${pluralize('has', numErrors)} invalid passage numbers in Benchling.`
  } else if (errorType === 'cellLineEntityNotFound') {
    return `${displayCount('cell line', numErrors)} could not be found.`
  } else if (errorType === 'cellLineLotEntityNotRegistered') {
    return `${displayCount('cell line lot', numErrors)}  ${pluralize('is', numErrors)} not properly registered in Benchling.`
  } else if (errorType === 'cellLineLotFieldEmpty') {
    return `${displayCount('cell line lot', numErrors)}  ${pluralize('is', numErrors)} missing required fields in Benchling.`
  } else if (errorType === 'cellLineLotInvalidPassageNumber') {
    return `${displayCount('cell line lot', numErrors)} ${pluralize('has', numErrors)} invalid passage numbers in Benchling.`
  }
  return `${displayCount('cell line lot', numErrors)} could not be found.`
}
// We currently render the same error message for cell line and cell line lot errors.
const _getErrorDisplayElement = (
  error: LinkLotsLookupCellLineError | LinkLotsLookupCellLineLotError,
): React.ReactNode => {
  const lookupValue = 'cellLine' in error ? error.cellLine : error.cellLineLot
  if (error.error.type === 'benchling') {
    if (error.error.error.type === 'entityNotRegistered') {
      const _error = error.error.error as LinkLotsLookupBenchlingEntityNotRegistered
      return (
        <span>
          Entity <b>{lookupValue}</b> of type {_error.entityType} is not registered.
        </span>
      )
    } else if (error.error.error.type === 'fieldEmpty') {
      const _error = error.error.error as LinkLotsLookupBenchlingFieldEmpty
      return (
        <span>
          Entity <b>{lookupValue}</b> is missing required field {_error.fieldName}.
        </span>
      )
    } else {
      const _error = error.error.error as LinkLotsLookupBenchlingInvalidPassageNumber
      return (
        <span>
          Entity <b>{lookupValue}</b> has invalid passage number{' '}
          <b>{_error.fieldValue}</b>.
        </span>
      )
    }
  }

  const _error = error.error as LinkLotsLookupEntityNotFound
  return (
    <span>
      Entity <b>{_error.entityName}</b> of type {_error.entityType} could not be found.
    </span>
  )
}

const _getErrorTypeForError = (
  error: LinkLotsLookupCellLineError | LinkLotsLookupCellLineLotError,
): LookupDataErrorType => {
  if ('cellLine' in error) {
    const cellLineError = error as LinkLotsLookupCellLineError
    if (cellLineError.error.type === 'benchling') {
      if (cellLineError.error.error.type === 'entityNotRegistered') {
        return 'cellLineEntityNotRegistered'
      }
      if (cellLineError.error.error.type === 'fieldEmpty') {
        return 'cellLineFieldEmpty'
      }
      if (cellLineError.error.error.type === 'invalidPassageNumber') {
        return 'cellLineInvalidPassageNumber'
      }
    } else {
      return 'cellLineEntityNotFound'
    }
  }
  // cellLineLot
  const cellLineLotError = error as LinkLotsLookupCellLineLotError
  if (cellLineLotError.error.type === 'benchling') {
    if (cellLineLotError.error.error.type === 'entityNotRegistered') {
      return 'cellLineLotEntityNotRegistered'
    }
    if (cellLineLotError.error.error.type === 'fieldEmpty') {
      return 'cellLineLotFieldEmpty'
    }
    if (cellLineLotError.error.error.type === 'invalidPassageNumber') {
      return 'cellLineLotInvalidPassageNumber'
    }
  }
  return 'cellLineLotEntityNotFound'
}

export const getLinkLotsLookupDataErrorGroups = (
  errors: LinkLotsLookupDataResponseErrors,
): ErrorGroup[] => {
  return generateErrorGroups<
    LinkLotsLookupCellLineError | LinkLotsLookupCellLineLotError,
    LookupDataErrorType
  >(
    errors.errors,
    _getErrorTypeForError,
    _getGroupHeaderForErrorType,
    _getErrorDisplayElement,
  )
}
