import cx from 'classnames'

import cs from './doubling_rate_histogram.scss'
import { getLegendBarStyle } from './getLegendBarStyle'
import {
  DOUBLING_RATE_BEST_BOUNDS,
  DOUBLING_RATE_GOOD_BOUNDS,
  DOUBLING_RATE_MAX,
  DOUBLING_RATE_MIN,
} from './hitpickingData'

import CleDemoHistogram from './CleDemoHistogram'

export interface DoublingRateHistogramProps {
  className?: string
  doublingRateValues: number[]
}

const BEST = '#333'
const GOOD = '#999'
const DEFAULT_COLOR = '#EAEAEA'

const DoublingRateHistogram = ({ doublingRateValues }: DoublingRateHistogramProps) => {
  return (
    <div>
      <DoublingRateHistogramChart
        className={cs.histogram}
        doublingRateValues={doublingRateValues}
      />
      <DoublingRateHistogramLegend className={cs.histogramLegend} />
    </div>
  )
}

const DoublingRateHistogramChart = ({
  className,
  doublingRateValues,
}: DoublingRateHistogramProps) => {
  return (
    <CleDemoHistogram
      xMin={DOUBLING_RATE_MIN}
      xMax={DOUBLING_RATE_MAX}
      values={doublingRateValues}
      className={className}
      getBarColor={(x0: number, x1: number) => {
        if (x0 >= DOUBLING_RATE_BEST_BOUNDS[0] && x1 <= DOUBLING_RATE_BEST_BOUNDS[1]) {
          return BEST
        }
        if (x0 >= DOUBLING_RATE_GOOD_BOUNDS[0] && x1 <= DOUBLING_RATE_GOOD_BOUNDS[1]) {
          return GOOD
        }
        return DEFAULT_COLOR
      }}
    />
  )
}

interface DoublingRateHistogramLegendProps {
  className?: string
}

const DoublingRateHistogramLegend = ({
  className,
}: DoublingRateHistogramLegendProps) => {
  return (
    <div className={cx(className, cs.doublingRateHistogramLegend)}>
      <div className={cs.doublingRateHistogramLegendBlock}>
        <div
          className={cs.good}
          style={getLegendBarStyle(DOUBLING_RATE_GOOD_BOUNDS, [
            DOUBLING_RATE_MIN,
            DOUBLING_RATE_MAX,
          ])}
        />
        <div
          className={cs.best}
          style={getLegendBarStyle(DOUBLING_RATE_BEST_BOUNDS, [
            DOUBLING_RATE_MIN,
            DOUBLING_RATE_MAX,
          ])}
        />
      </div>
      <div className={cs.labels}>
        <div className={cx(cs.label, cs.leftLabel)}>14</div>
        <div className={cx(cs.label, cs.midLabel)}>19</div>
        <div className={cx(cs.label, cs.rightLabel)}>24</div>
      </div>
    </div>
  )
}

export default DoublingRateHistogram
