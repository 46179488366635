import PropTypes from 'prop-types'
import { IconProps } from './types/IconProps'
const CalendarIcon = ({ className, onClick }: IconProps) => (
  <svg
    width='24'
    height='24'
    viewBox='0 0 24 24'
    xmlns='http://www.w3.org/2000/svg'
    className={className}
    onClick={onClick}
  >
    <path d='M20 3H19V1H17V3H7V1H5V3H4C2.90002 3 2 3.89999 2 5V21C2 22.1 2.90002 23 4 23H20C21.1 23 22 22.1 22 21V5C22 3.89999 21.1 3 20 3ZM20 21H4V10H20V21ZM4 8H20V5H4V8Z' />
  </svg>
)

CalendarIcon.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
}

export default CalendarIcon
