import { DesktopAPI } from '~/api/core'
import Toaster from '~/components/Toaster'

const processItemsAPI = new DesktopAPI('/api/process-items')

export default {
  getProcessItems: params => processItemsAPI.get('', params),
  checkOutProcessItem: async uuid => {
    try {
      const response = await processItemsAPI.post(`${uuid}/check-out/`)
      Toaster.show({
        message: `Successfully checked out ${uuid}`,
        intent: 'success',
      })
      return response
    } catch (e) {
      Toaster.show({
        message: `Failed to check out ${uuid}`,
        intent: 'danger',
      })
    }
    return null
  },
  checkInProcessItem: async uuid => {
    try {
      const response = await processItemsAPI.post(`${uuid}/check-in/`)
      Toaster.show({
        message: `Successfully checked in ${uuid}`,
        intent: 'success',
      })
      return response
    } catch (e) {
      Toaster.show({
        message: `Failed to check in ${uuid}`,
        intent: 'danger',
      })
    }
    return null
  },
  getProcessItemByUserFacingId: userFacingId =>
    processItemsAPI.get(`by-user-facing-id/${userFacingId}/`),
}
