import { components } from '~/api/desktop/generated-schema'
import { ProcessItem } from '~/common.interface'
import { WELL_MAPS_BY_LABEL } from '../../../ProcessItems/LabelledWellMap'
import { ExperimentPlateMap } from '../../../ProcessItems/PlateMap'

type ExperimentPlateExperimentRequest =
  components['schemas']['ExperimentPlateExperiment']

export interface ExperimentPlateExperiment {
  experimentType: string
  linkedSamplePlate: string
  // wellMap maps wells from `linkedSamplePlate` to a list of wells on this plate.
  // See experiment_plate_experiment.py for more.
  wellMap: { [linkedSampleWell: string]: string[] }
}

function makeExperiment(
  samplePlate: ProcessItem,
  experimentType: string,
): ExperimentPlateExperiment {
  return {
    experimentType,
    linkedSamplePlate: samplePlate.uuid,
    wellMap: WELL_MAPS_BY_LABEL[experimentType].wellMap,
  }
}

export const makeExperimentsForPlateMap = (
  samplePlate: ProcessItem,
  plateMap: ExperimentPlateMap,
): ExperimentPlateExperiment[] => {
  const experiments = plateMap.labelled_well_maps.map(labelledWellMap =>
    makeExperiment(samplePlate, labelledWellMap),
  )

  return experiments
}

export const getExperimentPlateExperimentRequest = (
  samplePlate: ProcessItem,
  plateMap: ExperimentPlateMap,
): ExperimentPlateExperimentRequest[] => {
  const experiments = makeExperimentsForPlateMap(samplePlate, plateMap)

  return experiments.map(experiment => ({
    experiment_type: experiment.experimentType,
    linked_sample_plate: experiment.linkedSamplePlate,
    well_map: experiment.wellMap,
  }))
}
