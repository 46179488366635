import classNames from 'classnames'
import { useCallback, useEffect, useMemo, useState } from 'react'

import Select, { SelectOption } from '~/components/Select'
import TinyMicroplate from '~/components/TinyMicroplate'

import { assets } from './assets'
import cs from './organoid_demo.scss'

export default function Images({ useLocalAssets }: { useLocalAssets: boolean }) {
  return (
    <div className={cs.page}>
      <Plate useLocalAssets={useLocalAssets} />
    </div>
  )
}

function Plate({ useLocalAssets }: { useLocalAssets: boolean }) {
  const LETTERS = 'ABCDEFGH'

  const [mediaType, setMediaType] = useState<'image' | 'video'>('image')

  const plates = Array.from(
    new Set(assets.filter(a => a.mediaType === mediaType).map(a => a.plate)),
  )
  const [plate, setPlate] = useState<string>(plates[0])

  const dates = useMemo(
    () =>
      assets
        .filter(a => a.plate === plate && a.mediaType === mediaType)
        .map(a => a.date)
        .toSorted(),
    [plate],
  )
  const maxDaysSinceStart = Math.max(...assets.map(a => a.daysSinceStart))
  const [date, setDate] = useState<string>(dates[0])
  const setDaysSinceStart = useCallback(
    (newDaysSinceStart: number) => {
      const asset = assets
        .filter(a => a.plate === plate && a.mediaType === mediaType)
        .find(a => a.daysSinceStart >= newDaysSinceStart)

      setDate(asset?.date ?? date)
    },
    [setDate],
  )

  // If we switch to a plate that doesn't have the current date, reset the date.
  useEffect(() => {
    const asset = assets.filter(
      a => a.plate === plate && a.date === date && a.mediaType === mediaType,
    )[0]
    if (!asset) {
      setPlate(plates[0])
      setDate(dates[0])
    }
  }, [mediaType, plate, date, setDate])

  const { path, daysSinceStart } = useMemo(() => {
    const asset = assets.filter(
      a => a.plate === plate && a.date === date && a.mediaType === mediaType,
    )[0]
    if (!asset) {
      console.error('no matching asset', { plate, date, mediaType })
      return { path: '', daysSinceStart: 0 }
    }
    return asset
  }, [plate, date])

  const [selectedWell, setSelectedWell] = useState('A1')
  const selectedRow = selectedWell[0]
  const selectedCol = parseInt(selectedWell.slice(1))

  // TODO: Preload adjacent detail images on hover

  return (
    <div>
      <div className={cs.pageTitle}>Live Organoids</div>

      <PlateChooser plates={plates} value={plate} onChange={setPlate} />

      <div className={cs.controls}>
        <Select<{ key: 'image' | 'video'; label: string }>
          label='Media'
          items={[
            { key: 'image', label: 'Images' },
            { key: 'video', label: 'Videos' },
          ]}
          itemKey='key'
          itemLabelKey='label'
          filterable
          activeItem={{
            key: mediaType,
            label: mediaType === 'video' ? 'Videos' : 'Images',
          }}
          onChange={({ key }) => setMediaType(key)}
          className={cs.select}
          triggerClassName={cs.trigger}
          popoverClassName={cs.popover}
        />

        <Select<SelectOption>
          label='Date'
          items={dates.map(d => ({ key: d, label: d }))}
          itemKey='key'
          itemLabelKey='label'
          filterable
          activeItem={{ key: date, label: date }}
          onChange={({ key }) => setDate(key)}
          className={cs.select}
          triggerClassName={cs.trigger}
          popoverClassName={cs.popover}
        />

        {mediaType === 'image' ? (
          <div className={cs.sliderGroup}>
            <p className={cs.label}>Day {daysSinceStart + 1}</p>
            <RelativeDateRangeInput
              value={daysSinceStart}
              onChange={setDaysSinceStart}
              max={maxDaysSinceStart}
              className={cs.input}
            />
          </div>
        ) : null}
      </div>

      <div className={cs.viewer}>
        <div className={cs.plate}>
          <div>{/* Corner cell */}</div>

          {new Array(12).fill(null).map((_, i) => (
            <div
              className={classNames(cs.columnHeader, {
                [cs.active]: i + 1 === selectedCol,
              })}
              key={`column_header_${i}`}
            >
              {i + 1}
            </div>
          ))}

          {new Array(8).fill(null).map((_, i) => (
            <div
              className={classNames(cs.rowHeader, {
                [cs.active]: LETTERS[i] === selectedRow,
              })}
              style={{ gridRowStart: i + 2, gridRowEnd: i + 3 }}
              key={`row_header_${i}`}
            >
              {LETTERS[i]}
            </div>
          ))}

          {new Array(96).fill(null).map((_, i) => {
            const row = LETTERS[Math.floor(i / 12)]
            const col = (i % 12) + 1
            const isSelected = selectedWell === `${row}${col}`
            return (
              <div className={cs.well} key={`well_${row}${col}`}>
                {mediaType === 'image' ? (
                  <img
                    src={getAssetURL(
                      useLocalAssets,
                      `300px/${path}/${row}${col}_03_1_1_Bright Field_001.jpg`,
                    )}
                    onMouseDown={() => {
                      setSelectedWell(`${row}${col}`)
                    }}
                    style={{
                      outline: `3px solid ${isSelected ? '#2cb1bc' : 'transparent'}`,
                    }}
                    draggable={false}
                  />
                ) : (
                  <video
                    autoPlay
                    loop
                    src={getAssetURL(
                      useLocalAssets,
                      `300px_video/${path}/${row}${col}.mp4`,
                    )}
                    onMouseDown={() => {
                      setSelectedWell(`${row}${col}`)
                    }}
                    style={{
                      outline: `3px solid ${isSelected ? '#2cb1bc' : 'transparent'}`,
                    }}
                    draggable={false}
                  />
                )}
              </div>
            )
          })}
        </div>

        <div className={cs.detail}>
          <div className={cs.header}>
            <p className={cs.wellCoordinates}>{selectedWell}</p>
            <p className={cs.day}>Day {daysSinceStart + 1}</p>
          </div>

          <div>
            {mediaType === 'image' ? (
              <img
                src={getAssetURL(
                  useLocalAssets,
                  `1000px/${path}/${selectedWell}_03_1_1_Bright Field_001.jpg`,
                )}
              />
            ) : (
              // TODO: Encode 1000px videos
              <video
                autoPlay
                loop
                controls
                src={getAssetURL(
                  useLocalAssets,
                  `300px_video/${path}/${selectedWell}.mp4`,
                )}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

function RelativeDateRangeInput({
  value,
  onChange,
  max,
  className,
}: {
  value: number
  onChange: (newValue: number) => void
  max: number
  className?: string
}) {
  const [isDragging, setIsDragging] = useState(false)
  const [draggingValue, setDraggingValue] = useState(value)

  // Emit changes when dragging
  useEffect(() => {
    if (isDragging) {
      onChange(Math.floor(draggingValue))
    }
  }, [draggingValue])

  // Accept changes when not dragging (ser picked a different date from menu)
  useEffect(() => {
    if (!isDragging) {
      setDraggingValue(value)
    }
  }, [value])

  return (
    <input
      type='range'
      value={draggingValue}
      onChange={e => {
        setDraggingValue(parseFloat(e.target.value))
      }}
      onMouseDown={() => {
        setIsDragging(true)
      }}
      onMouseUp={() => {
        setIsDragging(false)
      }}
      min={0}
      max={max}
      step='any'
      className={className}
    />
  )
}

function PlateChooser({
  plates,
  value,
  onChange,
}: {
  plates: string[]
  value: string
  onChange: (newPlate: string) => void
}) {
  const colorRgbFn = useCallback(() => '#3994c1', [])

  return (
    <div className={cs.plateChooser}>
      {plates.map(option => (
        <div
          className={classNames(cs.item, { [cs.active]: option === value })}
          onClick={() => {
            onChange(option)
          }}
          key={option}
        >
          <div className={cs.label}>{option}</div>
          <TinyMicroplate
            plateFormat='wells_96'
            size='medium'
            highlights={[{ colorRgbFn }]}
          />
        </div>
      ))}
    </div>
  )
}

function getAssetURL(useLocal: boolean, path: string) {
  const hostPrefix = useLocal
    ? '/static/organoid_demo_2024_v2'
    : 'https://monomer-slas-demo-cell-images.s3.us-west-2.amazonaws.com/organoid_demo_2024_v2'
  return `${hostPrefix}/${path}`
}
