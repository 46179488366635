import { renderStatusPill } from '~/utils/statusPill'
import {
  CultureStatus,
  CultureViewModel,
  IssueType,
  getCultureIssue,
} from '../../events/ViewModels/CultureViewModel'

const ISSUE_TO_LABEL: { [filter in IssueType]: string } = {
  OVER_CONFLUENT: 'Over-confluent',
  CELL_DEATH: 'Cell Death Detected',
}

const CULTURE_STATUS_TO_LABEL: { [status in CultureStatus]: string } = {
  ACTIVE: 'Active',
  CONSUMED: 'Consumed',
  TERMINATED: 'Terminated',
}

const STATUS_PILL_CONFIG = {
  Active: { type: 'success' },
  Consumed: { type: 'inactive' },
  Terminated: { type: 'inactive' },
  'Over-confluent': { type: 'warning' },
  'Cell Death Detected': { type: 'error' },
}

export function CultureIssue({
  culture,
  day,
  className,
}: {
  culture: CultureViewModel
  day: number
  className?: string
}) {
  const maybeIssue = getCultureIssue(day, culture)
  const shouldRenderIssue = maybeIssue && culture.status === 'ACTIVE'

  const renderIssue = (issue: IssueType) => {
    return renderStatusPill(ISSUE_TO_LABEL[issue], STATUS_PILL_CONFIG, {
      small: true,
      className,
    })
  }

  const renderStatus = (status: CultureStatus) => {
    return renderStatusPill(CULTURE_STATUS_TO_LABEL[status], STATUS_PILL_CONFIG, {
      small: true,
      className,
    })
  }

  // Prioritize rendering issue. If no issues, render status. For now, do not render status if status is active.
  if (culture.status == 'ACTIVE') {
    return shouldRenderIssue ? renderIssue(maybeIssue) : <></>
  } else {
    return renderStatus(culture.status)
  }
}
