export interface DemoConfig {
  profile: 'slas_demo' | 'ipsc_monitor' | 'slas_demo_no_contam'
}

export interface DemoConfigSettings {
  allow96WellPlates: boolean
  cellLineDisplayLabel: string
  cellLineExperimentLabelsStrategy: 'BANKGEN' | 'BATCH_AND_STOCK'
  experimentLabelDisplayLabel: string
  platePrefix: string
  showCellBankMetadataField: boolean
  showContamination: boolean
  showDoublingTime: boolean
  showMediaLots: boolean
  showNextScheduledAction: boolean
  showProjectedGrowth: boolean
  showTimeProjectedToBeConfluent: boolean
}

export const DEMO_CONFIG_SETTINGS: {
  [key in DemoConfig['profile']]: DemoConfigSettings
} = {
  slas_demo: {
    allow96WellPlates: false,
    cellLineDisplayLabel: 'Cell Line',
    cellLineExperimentLabelsStrategy: 'BATCH_AND_STOCK',
    experimentLabelDisplayLabel: 'Experiment Label',
    platePrefix: 'NSC',
    showCellBankMetadataField: false,
    showContamination: true,
    showDoublingTime: true,
    showMediaLots: true,
    showNextScheduledAction: true,
    showProjectedGrowth: true,
    showTimeProjectedToBeConfluent: true,
  },
  ipsc_monitor: {
    allow96WellPlates: true,
    cellLineDisplayLabel: 'Source Cell Line',
    cellLineExperimentLabelsStrategy: 'BANKGEN',
    experimentLabelDisplayLabel: 'Experiment Name',
    platePrefix: 'iPSC',
    showCellBankMetadataField: true,
    showContamination: false,
    showDoublingTime: false,
    showMediaLots: false,
    showNextScheduledAction: false,
    showProjectedGrowth: false,
    showTimeProjectedToBeConfluent: false,
  },
  slas_demo_no_contam: {
    allow96WellPlates: false,
    cellLineDisplayLabel: 'Cell Line',
    cellLineExperimentLabelsStrategy: 'BATCH_AND_STOCK',
    experimentLabelDisplayLabel: 'Experiment Label',
    platePrefix: 'NSC',
    showCellBankMetadataField: false,
    showContamination: false,
    showDoublingTime: true,
    showMediaLots: true,
    showNextScheduledAction: true,
    showProjectedGrowth: true,
    showTimeProjectedToBeConfluent: true,
  },
}

export function getProfileSettings(config: DemoConfig): DemoConfigSettings {
  return DEMO_CONFIG_SETTINGS[config.profile]
}
