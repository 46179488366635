import cx from 'classnames'
import { size } from 'lodash'
import { useState } from 'react'

import workcellAPI from '~/api/desktop/workcell'
import { WorkcellStatus } from '~/api/desktop/workcell.interface'
import { MonomerErrorBoundary } from '~/components/MonomerErrorBoundary'
import PopupMenu from '~/components/PopupMenu'
import Toaster from '~/components/Toaster'
import ToggleSwitch from '~/components/ToggleSwitch'
import WarningIcon from '~/components/icons/WarningIcon'
import ConfirmCancelAllRoutinesDialog from '~/pages/Workcell/WorkcellHeader/ConfirmCancelAllRoutinesDialog'
import FailedProcessStepNotification from '~/pages/Workcell/components/FailedProcessStepNotification'
import {
  getProcessStepsIdsFailed,
  getProcessStepsIdsScheduled,
  getRoutineIds,
} from '~/pages/Workcell/utils/workcellStatus'
import ConfirmStartWorkcellDialog from './ConfirmStartWorkcellDialog'
import ExecutionStatusBox from './ExecutionStatusBox'
import GlobalConfigEditDialog from './GlobalConfigEditDialog'
import InstrumentStatusPopover from './InstrumentStatusPopover'
import WorkcellConfigDialog from './WorkcellConfigDialog'
import WorkcellRawStateEditDialog from './WorkcellRawStateEditDialog'
import cs from './workcell_header.scss'

interface WorkcellHeaderProps {
  className: string
  workcellStatus: WorkcellStatus | undefined
  setWorkcellStatus(workcellStatus: WorkcellStatus): void
  workcellServicesFailedToInitialize: boolean
}

const WorkcellHeader = ({
  className,
  workcellStatus,
  setWorkcellStatus,
  workcellServicesFailedToInitialize,
}: WorkcellHeaderProps) => {
  const [showWorkcellConfigDialog, setShowWorkcellConfigDialog] = useState(false)
  const [showWorkcellRawStateEditDialog, setShowWorkcellRawStateEditDialog] =
    useState(false)
  const [showConfirmCancelAllRoutinesDialog, setShowConfirmCancelAllRoutinesDialog] =
    useState(false)
  const [showConfirmStartWorkcellDialog, setShowConfirmStartWorkcellDialog] =
    useState(false)
  const [showGlobalConfigEditDialog, setShowGlobalConfigEditDialog] = useState(false)

  const handleCancelAll = () => {
    setShowConfirmCancelAllRoutinesDialog(true)
  }

  const hasFailedStep = () => {
    return getProcessStepsIdsFailed(workcellStatus).length > 0
  }

  const setLiveLoading = () => {
    if (workcellStatus) {
      setWorkcellStatus({
        ...workcellStatus,
        _liveLoading: true,
        error_message: '',
      })
    }
  }

  const toggleAutoexecute = () => {
    const autoexecute = workcellStatus?.live || false
    if (autoexecute) {
      setLiveLoading()
      workcellAPI.stop()
    } else {
      setShowConfirmStartWorkcellDialog(true)
    }
  }

  const handleConfirmStart = () => {
    setLiveLoading()
    workcellAPI.start()
  }

  const clearMessage = async () => {
    if (workcellStatus) {
      setWorkcellStatus({ ...workcellStatus, error_message: '' })
    }

    await workcellAPI.clearMessage()

    Toaster.show({ message: 'Workcell message cleared.', intent: 'success' })
  }

  const performInstrumentRecovery = async () => {
    if (workcellStatus) {
      setWorkcellStatus({
        ...workcellStatus,
        _tempStatus: 'LOADING',
        error_message: '',
      })
    }
    await workcellAPI.performInstrumentRecovery()
  }

  const kickoffNextSingleStep = async () => {
    await workcellAPI.kickoffNextSingleStep()
  }

  const renderStatus = () => {
    if (workcellServicesFailedToInitialize) {
      return (
        <div className={cs.initializeError}>
          The workcell software failed to initialize. Please contact Monomer support.
        </div>
      )
    }
    if (workcellStatus === null || workcellStatus === undefined) {
      return (
        <div className={cs.status}>
          Status: <span className={cs.connecting}>Connecting...</span>
        </div>
      )
    }

    let statusLine = <span className={cs.faulted}>Unknown</span>

    statusLine = (
      <InstrumentStatusPopover
        workcellStatus={workcellStatus}
        performInstrumentRecovery={performInstrumentRecovery}
      />
    )

    return <div className={cs.status}>Instrument Status: {statusLine}</div>
  }

  const renderError = () => {
    if (workcellStatus?.error_message) {
      return (
        <div className={cs.error}>
          <WarningIcon className={cs.errorIcon} />
          <span className={cs.errorMessage}>{workcellStatus?.error_message}</span>
          <span className={cs.clearError} onClick={() => clearMessage()}>
            Clear Message
          </span>
        </div>
      )
    }
    return null
  }

  const stepUuidsFailed = getProcessStepsIdsFailed(workcellStatus)

  const getMenuOptions = () => {
    if (workcellServicesFailedToInitialize) {
      return [
        {
          label: 'Edit Raw Execution State (Advanced)',
          action: () => setShowWorkcellRawStateEditDialog(true),
        },
      ]
    }

    return [
      {
        label: 'Execute Next Single Step',
        action: () => kickoffNextSingleStep(),
        disabled:
          (!!workcellStatus &&
            (workcellStatus.live ||
              getProcessStepsIdsScheduled(workcellStatus).length === 0)) ||
          hasFailedStep(),
      },
      {
        label: 'Cancel Queued Routines',
        action: handleCancelAll,
        disabled: size(getRoutineIds(workcellStatus)) === 0,
      },
      {
        label: 'Configure Workcell...',
        action: () => setShowWorkcellConfigDialog(true),
      },
    ]
  }

  return (
    <MonomerErrorBoundary>
      <div className={cx(cs.workcellHeader, className)}>
        <div className={cs.left}>
          <div className={cs.titleContainer}>
            <span className={cs.title}>
              {workcellStatus?.config.workcell_name || 'Monomer Workcell'}
            </span>
            <PopupMenu options={getMenuOptions()} className={cs.menu} />
            <ToggleSwitch
              onLabel='ON'
              offLabel='OFF'
              isOn={workcellStatus?.live}
              loading={workcellStatus?._liveLoading}
              onClick={toggleAutoexecute}
              className={cs.toggleSwitch}
            />
          </div>
          {renderStatus()}
          {renderError()}
        </div>
        <div className={cs.fill} />
        {stepUuidsFailed.length ? (
          <FailedProcessStepNotification
            stepUuidsFailed={stepUuidsFailed}
            className={cs.errorNotification}
          />
        ) : (
          <ExecutionStatusBox />
        )}
        <WorkcellConfigDialog
          isOpen={showWorkcellConfigDialog}
          onClose={() => setShowWorkcellConfigDialog(false)}
          onShowWorkcellRawStateEditDialogClick={() =>
            setShowWorkcellRawStateEditDialog(true)
          }
          onShowGlobalConfigEditDialogClick={() => setShowGlobalConfigEditDialog(true)}
        />
        <WorkcellRawStateEditDialog
          isOpen={showWorkcellRawStateEditDialog}
          onClose={() => setShowWorkcellRawStateEditDialog(false)}
        />
        <ConfirmCancelAllRoutinesDialog
          isOpen={showConfirmCancelAllRoutinesDialog}
          onClose={() => setShowConfirmCancelAllRoutinesDialog(false)}
        />
        <ConfirmStartWorkcellDialog
          isOpen={showConfirmStartWorkcellDialog}
          onClose={() => setShowConfirmStartWorkcellDialog(false)}
          onConfirm={handleConfirmStart}
        />
        <GlobalConfigEditDialog
          isOpen={showGlobalConfigEditDialog}
          onClose={() => setShowGlobalConfigEditDialog(false)}
        />
      </div>
    </MonomerErrorBoundary>
  )
}

export default WorkcellHeader
