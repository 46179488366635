import cx from 'classnames'
import PropTypes from 'prop-types'

import PopupMenu, { PopupMenuOption } from '~/components/PopupMenu'

import cs from './app_header.scss'

interface AppHeader {
  appName: string
  iconSrc: string
  className?: string
  menuOptions: PopupMenuOption[]
  variant: 'default' | 'mini' | 'inline'
}

const AppHeader = ({ appName, iconSrc, className, menuOptions, variant }) => {
  if (variant === 'mini' || variant === 'inline') {
    return (
      <div className={cx(cs.appHeader, cs[variant], className)}>
        <img className={cs.appIcon} src={iconSrc} alt={`${appName}`} />
        <div className={cx(cs.appName, menuOptions && cs.hasMenu)}>
          {appName}
          {menuOptions && menuOptions.length > 0 && (
            <PopupMenu options={menuOptions} className={cs.menu} />
          )}
        </div>
      </div>
    )
  }

  return (
    <div className={cx(cs.appHeader, cs[variant], className)}>
      <div className={cx(cs.appName, menuOptions && cs.hasMenu)}>
        {appName}
        {menuOptions && menuOptions.length > 0 && (
          <PopupMenu options={menuOptions} className={cs.menu} />
        )}
      </div>
      <img className={cs.appIcon} src={iconSrc} alt={`${appName}`} />
    </div>
  )
}

AppHeader.propTypes = {
  appName: PropTypes.string,
  iconSrc: PropTypes.string,
  className: PropTypes.string,
  menuOptions: PropTypes.arrayOf(PropTypes.shape({})),
  variant: PropTypes.oneOf(['default', 'mini', 'inline']),
}

AppHeader.defaultProps = {
  variant: 'default',
}

export default AppHeader
