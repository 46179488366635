import { components } from '~/api/desktop/generated-schema'
import { client, unwrap } from '~/api/desktopAPIv2'

export type NestResponse = components['schemas']['NestResponse']
export type GetAllLocationsResponse = components['schemas']['GetAllLocationsResponse']
export type SuccessResponse = components['schemas']['DefaultSuccessResponse']
export type TeachToolStatus = components['schemas']['TeachToolStatus']
export type IsPlateGrippedResponse = components['schemas']['IsPlateGrippedResponse']
export type IsBodyFreeResponse = components['schemas']['IsBodyFreeResponse']
export type GetAllSeqFileNamesResponse =
  components['schemas']['GetAllSeqFileNamesResponse']
export type SequenceValidationRunParams =
  components['schemas']['SequenceValidationRunParams']
export type ValidateSequenceValidationRunParamsResponse =
  components['schemas']['ValidateSequenceValidationRunParamsResponse']
export type GetTeachToolSeqFileResponse =
  components['schemas']['GetTeachToolSeqFileResponse']
export type TeachToolSeqFile = components['schemas']['TeachToolSeqFile']
export type SequenceValidationRunStatus =
  components['schemas']['SequenceValidationRunStatus']

export default {
  getStatus: (driverName: string): Promise<TeachToolStatus> =>
    unwrap(
      client().GET('/api/v2/drivers/robotic-arm/{driver_name}/teach-tool/status', {
        params: {
          path: { driver_name: driverName },
        },
      }),
    ),
  reset: (driverName: string): Promise<SuccessResponse> =>
    unwrap(
      client().POST('/api/v2/drivers/robotic-arm/{driver_name}/teach-tool/reset', {
        params: {
          path: { driver_name: driverName },
        },
      }),
    ),
  f: (driverName: string): Promise<SuccessResponse> =>
    unwrap(
      client().POST('/api/v2/drivers/robotic-arm/{driver_name}/teach-tool/f', {
        params: {
          path: { driver_name: driverName },
        },
      }),
    ),
  sf: (driverName: string): Promise<SuccessResponse> =>
    unwrap(
      client().POST('/api/v2/drivers/robotic-arm/{driver_name}/teach-tool/sf', {
        params: {
          path: { driver_name: driverName },
        },
      }),
    ),
  uf: (driverName: string): Promise<SuccessResponse> =>
    unwrap(
      client().POST('/api/v2/drivers/robotic-arm/{driver_name}/teach-tool/uf', {
        params: {
          path: { driver_name: driverName },
        },
      }),
    ),
  jogc: (driverName: string, caxis: string, amount: number): Promise<string> =>
    unwrap(
      client().POST('/api/v2/drivers/robotic-arm/{driver_name}/teach-tool/jogc', {
        params: {
          path: { driver_name: driverName },
          query: { caxis: caxis, amount },
        },
      }),
    ),
  jogj: (driverName: string, jaxis: string, amount: number): Promise<string> =>
    unwrap(
      client().POST('/api/v2/drivers/robotic-arm/{driver_name}/teach-tool/jogj', {
        params: {
          path: { driver_name: driverName },
          query: { jaxis: jaxis, amount },
        },
      }),
    ),
  g: (
    driverName: string,
    plateWidth: number,
    gripForce?: number,
  ): Promise<SuccessResponse> => {
    const queryObj = { plate_width: plateWidth }
    if (gripForce) queryObj['grip_force'] = gripForce

    return unwrap(
      client().POST('/api/v2/drivers/robotic-arm/{driver_name}/teach-tool/g', {
        params: {
          path: { driver_name: driverName },
          query: queryObj,
        },
      }),
    )
  },
  ug: (driverName: string, plateWidth: number): Promise<SuccessResponse> =>
    unwrap(
      client().POST('/api/v2/drivers/robotic-arm/{driver_name}/teach-tool/ug', {
        params: {
          path: { driver_name: driverName },
          query: { plate_width: plateWidth },
        },
      }),
    ),
  wc: (driverName: string): Promise<string> =>
    unwrap(
      client().GET('/api/v2/drivers/robotic-arm/{driver_name}/teach-tool/wc', {
        params: {
          path: { driver_name: driverName },
        },
      }),
    ),
  wj: (driverName: string): Promise<string> =>
    unwrap(
      client().GET('/api/v2/drivers/robotic-arm/{driver_name}/teach-tool/wj', {
        params: {
          path: { driver_name: driverName },
        },
      }),
    ),
  anest: (
    driverName: string,
    nestName: string,
    firstOnly?: boolean,
    omitLast?: boolean,
  ): Promise<SuccessResponse> => {
    const queryObj = { nest_name: nestName }
    if (firstOnly) {
      queryObj['first_only'] = firstOnly
    }
    if (omitLast) {
      queryObj['omit_last'] = omitLast
    }

    return unwrap(
      client().POST('/api/v2/drivers/robotic-arm/{driver_name}/teach-tool/anest', {
        params: {
          path: { driver_name: driverName },
          query: queryObj,
        },
      }),
    )
  },
  lnest: (driverName: string, nestName: string): Promise<SuccessResponse> =>
    unwrap(
      client().POST('/api/v2/drivers/robotic-arm/{driver_name}/teach-tool/lnest', {
        params: {
          path: { driver_name: driverName },
          query: {
            nest_name: nestName,
          },
        },
      }),
    ),
  mloc: (driverName: string, locationName: string): Promise<SuccessResponse> =>
    unwrap(
      client().POST('/api/v2/drivers/robotic-arm/{driver_name}/teach-tool/mloc', {
        params: {
          path: { driver_name: driverName },
          query: {
            loc_name: locationName,
          },
        },
      }),
    ),
  wlocj: (driverName: string, locationName: string): Promise<SuccessResponse> =>
    unwrap(
      client().POST('/api/v2/drivers/robotic-arm/{driver_name}/teach-tool/wlocj', {
        params: {
          path: { driver_name: driverName },
          query: {
            loc_name: locationName,
          },
        },
      }),
    ),
  wnest_locc: (driverName: string, nestName: string): Promise<SuccessResponse> =>
    unwrap(
      client().POST('/api/v2/drivers/robotic-arm/{driver_name}/teach-tool/wnest_locc', {
        params: {
          path: { driver_name: driverName },
          query: {
            nest_name: nestName,
          },
        },
      }),
    ),
  wnest_locj: (driverName: string, nestName: string): Promise<SuccessResponse> =>
    unwrap(
      client().POST('/api/v2/drivers/robotic-arm/{driver_name}/teach-tool/wnest_locj', {
        params: {
          path: { driver_name: driverName },
          query: {
            nest_name: nestName,
          },
        },
      }),
    ),
  cnests: (driverName: string): Promise<NestResponse> =>
    unwrap(
      client().GET('/api/v2/drivers/robotic-arm/{driver_name}/teach-tool/cnests', {
        params: {
          path: {
            driver_name: driverName,
          },
        },
      }),
    ),
  canests: (driverName: string): Promise<NestResponse> =>
    unwrap(
      client().GET('/api/v2/drivers/robotic-arm/{driver_name}/teach-tool/canests', {
        params: {
          path: {
            driver_name: driverName,
          },
        },
      }),
    ),
  calocs: (driverName: string): Promise<GetAllLocationsResponse> =>
    unwrap(
      client().GET('/api/v2/drivers/robotic-arm/{driver_name}/teach-tool/calocs', {
        params: {
          path: {
            driver_name: driverName,
          },
        },
      }),
    ),
  caseqnames: (driverName: string): Promise<GetAllSeqFileNamesResponse> =>
    unwrap(
      client().GET('/api/v2/drivers/robotic-arm/{driver_name}/teach-tool/caseqnames', {
        params: {
          path: {
            driver_name: driverName,
          },
        },
      }),
    ),
  get_seq_file: (
    driverName: string,
    fileName: string,
  ): Promise<GetTeachToolSeqFileResponse> =>
    unwrap(
      client().GET(
        '/api/v2/drivers/robotic-arm/{driver_name}/teach-tool/get-seq-file',
        {
          params: {
            path: {
              driver_name: driverName,
            },
            query: {
              file_name: fileName,
            },
          },
        },
      ),
    ),
  is_plate_gripped: (driverName: string): Promise<IsPlateGrippedResponse> =>
    unwrap(
      client().GET(
        '/api/v2/drivers/robotic-arm/{driver_name}/teach-tool/is-plate-gripped',
        {
          params: {
            path: {
              driver_name: driverName,
            },
          },
        },
      ),
    ),
  is_body_free: (driverName: string): Promise<IsBodyFreeResponse> =>
    unwrap(
      client().GET(
        '/api/v2/drivers/robotic-arm/{driver_name}/teach-tool/is-body-free',
        {
          params: {
            path: {
              driver_name: driverName,
            },
          },
        },
      ),
    ),
  validateSequenceValidationRunParams: (
    driverName: string,
    params: SequenceValidationRunParams,
  ): Promise<ValidateSequenceValidationRunParamsResponse> =>
    unwrap(
      client().POST(
        '/api/v2/drivers/robotic-arm/{driver_name}/teach-tool/validate-sequence-validation-run-params',
        {
          params: {
            path: {
              driver_name: driverName,
            },
          },
          body: {
            params,
          },
        },
      ),
    ),
  runSequenceValidation: (
    driverName: string,
    params: SequenceValidationRunParams,
  ): Promise<SuccessResponse> =>
    unwrap(
      client().POST(
        '/api/v2/drivers/robotic-arm/{driver_name}/teach-tool/run-sequence-validation',
        {
          params: {
            path: {
              driver_name: driverName,
            },
          },
          body: {
            params,
          },
        },
      ),
    ),
}
