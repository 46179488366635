import PropTypes from 'prop-types'
import { IconProps } from './types/IconProps'
const ExecuteIcon = ({ className, onClick }: IconProps) => (
  <svg
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    onClick={onClick}
    className={className}
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M12 2C6.47998 2 2 6.48 2 12C2 17.52 6.47998 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM10 16.5L16 12L10 7.5V16.5ZM4 12C4 16.41 7.59003 20 12 20C16.41 20 20 16.41 20 12C20 7.59 16.41 4 12 4C7.59003 4 4 7.59 4 12Z'
    />
  </svg>
)

ExecuteIcon.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
}

export default ExecuteIcon
