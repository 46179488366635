import { Integration } from '~/types/Integration.interface'
import { RestAPI } from './core'

class IntegrationsAPI<ResourceType> extends RestAPI<ResourceType> {
  constructor(
    prefix: string | (() => string),
    resourceName: string,
    isDesktop = false,
    showToast = true,
  ) {
    super(prefix, resourceName, isDesktop, showToast)
  }

  sendTestSlackMessage = () => integrationsAPI.POST('slack/send-test-message/')
}

/** @deprecated Use `src/api/desktop/integrations.ts` instead */
const integrationsAPI = new IntegrationsAPI<Integration>(
  '/api/integrations',
  'Integration',
)

export default integrationsAPI
