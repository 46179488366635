// See /ui/buttons for Button playground

// A minimal button with no border or background color, and bold text.

import PropTypes from 'prop-types'
import React, { MouseEventHandler } from 'react'

import Button from './Button'

interface MinimalBoldButtonProps {
  className?: string
  label?: string
  to?: string
  IconComponent?: React.ElementType
  onClick?: MouseEventHandler<HTMLButtonElement> | ((...args: unknown[]) => unknown)
  type?: 'primary' | 'normal' | 'danger'
  disabled?: boolean
}

const MinimalBoldButton = ({
  label,
  onClick,
  type,
  className,
  IconComponent,
  disabled,
  to,
}: MinimalBoldButtonProps) => {
  return (
    <Button
      className={className}
      label={label}
      to={to}
      IconComponent={IconComponent}
      onClick={onClick}
      type={type}
      disabled={disabled}
      variant='minimalBold'
    />
  )
}

MinimalBoldButton.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  // If to is provided, wrap button in a Link.
  to: PropTypes.string,
  IconComponent: PropTypes.oneOfType([
    PropTypes.instanceOf(React.Component),
    PropTypes.func,
  ]),
  onClick: PropTypes.func,
  type: PropTypes.oneOf(['primary', 'normal', 'danger']),
  disabled: PropTypes.bool,
}

export default MinimalBoldButton
