import cx from 'classnames'

import { getProcessStepDisplay } from '~/pages/Workcell/utils/processStepDisplay'
import PropTypes from '~/propTypes'

import { ProcessStep } from '../../types/ProcessStep.interface'
import cs from './process_step_name.scss'

interface ProcessStepNameProps {
  className?: string
  processStep: ProcessStep
}

const ProcessStepName = ({ className, processStep }: ProcessStepNameProps) => {
  return (
    <div className={cx(cs.processStepName, className)}>
      <div className={cs.instrumentName}>{processStep.instrumentName}</div>
      <div className={cs.commandName}>{getProcessStepDisplay(processStep)}</div>
    </div>
  )
}

ProcessStepName.propTypes = {
  className: PropTypes.string,
  processStep: PropTypes.ProcessStep,
}

export default ProcessStepName
