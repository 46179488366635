import cx from 'classnames'
import { orderBy } from 'lodash/fp'

import {
  CultureViewModel,
  getDatasetCreatedAt,
} from '../../events/ViewModels/CultureViewModel'
import CultureImageGrid from './CultureImageGrid'
import cs from './culture_images.scss'

export interface CultureImagesProps {
  className?: string
  today: number
  culture: CultureViewModel
}

const CultureImages = ({ className, today, culture }: CultureImagesProps) => {
  const datasets = orderBy(
    dataset => getDatasetCreatedAt(today, dataset),
    'desc',
    culture.datasets,
  )

  return (
    <CultureImageGrid
      className={cx(cs.imageGrid, className)}
      today={today}
      culture={culture}
      datasets={datasets}
    />
  )
}

export default CultureImages
