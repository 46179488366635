import cx from 'classnames'
import PropTypes from 'prop-types'
import { ReactNode } from 'react'

import { ProcessItemLike } from '~/common.interface'
import { ProcessItemWithTransferStationId } from '../StorageViz/ProcessItemWithTransferStationId.interface'
import cs from './transfer_station_viz_view.scss'

export interface SelectedTransferStation {
  item: ProcessItemLike | null
  transferStationId: string
}

export const isSelectedTransferStationEqual = (
  locationOne: SelectedTransferStation,
  locationTwo: SelectedTransferStation,
) => {
  return (
    locationOne.transferStationId === locationTwo.transferStationId &&
    locationOne.item === locationTwo.item
  )
}

interface TransferStationVizViewProps {
  className?: string
  hideName?: boolean
  transferStations: ProcessItemWithTransferStationId[]
  renderTransferStation: (
    transferStation: ProcessItemWithTransferStationId | undefined,
  ) => ReactNode
}

const TransferStationVizView = ({
  className,
  hideName,
  transferStations,
  renderTransferStation,
}: TransferStationVizViewProps) => {
  const renderProcessItem = (transferStation: ProcessItemWithTransferStationId) => {
    return (
      <div
        key={transferStation.transferStationId}
        className={cx(cs.transferStation, hideName && cs.condensed)}
      >
        {!hideName && (
          <div className={cs.transferStationLabel}>
            {transferStation.transferStationId}
          </div>
        )}
        {renderTransferStation(transferStation)}
      </div>
    )
  }

  return (
    <div className={cx(className, cs.transferStationViz)}>
      {transferStations.map(renderProcessItem)}
    </div>
  )
}

TransferStationVizView.propTypes = {
  className: PropTypes.string,
  hideName: PropTypes.bool,
  transferStations: PropTypes.arrayOf(
    PropTypes.shape({
      transferStationId: PropTypes.string,
    }),
  ),
  renderTransferStation: PropTypes.func,
}

export default TransferStationVizView
