import { findIndex, get, includes, isNumber } from 'lodash/fp'

import { ProcessItem } from '~/common.interface'
import TinyMicroplate from '~/components/TinyMicroplate'
import { supportedSize } from '~/components/TinyMicroplate.interface'
import PropTypes from '~/propTypes'
import { convertWellCoordsToWellName } from '~/utils/microplate'
import { getPlateFormat } from '~/utils/processItems/common'
import {
  getExperimentPlateConsumedExperimentIndices,
  getExperimentPlateExperiments,
  getWellsForExperiment,
} from '~/utils/processItems/experimentPlate'

interface ProcessItemVizProps {
  className?: string
  processItem?: ProcessItem
  // Additional well to highlight
  numWellsToHighlight?: number
  size?: supportedSize
}

const ProcessItemViz = ({
  className,
  processItem,
  numWellsToHighlight,
  size,
}: ProcessItemVizProps) => {
  if (!processItem) return null
  if (processItem.type === 'reagent_plate' || processItem.type === 'tiprack') {
    const numRows = get(['metadata', 'num_rows'], processItem)
    const isHighlighted = (_row, col) => {
      const index = _row + numRows * col
      const currentIndex = get(['state', 'currentIndex'], processItem)
      const _numWellsToHighlight = numWellsToHighlight || 0
      if (!isNumber(currentIndex)) return false
      return index >= currentIndex && index < currentIndex + _numWellsToHighlight
    }
    const isFilled = (_row, col) => {
      const index = _row + numRows * col
      const currentIndex = get(['state', 'currentIndex'], processItem)
      const _numWellsToHighlight = numWellsToHighlight || 0
      if (!isNumber(currentIndex)) return false
      return index >= currentIndex + _numWellsToHighlight
    }
    return (
      <TinyMicroplate
        className={className}
        plateFormat={processItem ? getPlateFormat(processItem) : 'wells_96'}
        highlights={[
          {
            color: 'accent',
            fn: isFilled,
          },
          {
            color: 'warning',
            fn: isHighlighted,
          },
        ]}
      />
    )
  }
  if (processItem.type === 'experiment_plate') {
    const isFilled = (_row, col) => {
      const wellName = convertWellCoordsToWellName(_row, col)

      const experimentIndex = findIndex(
        experiment => includes(wellName, getWellsForExperiment(experiment)),
        getExperimentPlateExperiments(processItem),
      )

      if (experimentIndex === -1) {
        return false
      }

      const consumedIndices = getExperimentPlateConsumedExperimentIndices(processItem)
      if (includes(experimentIndex, consumedIndices)) {
        return false
      }

      return true
    }

    return (
      <TinyMicroplate
        className={className}
        plateFormat={processItem ? getPlateFormat(processItem) : 'wells_6'}
        highlights={[
          {
            fn: isFilled,
            color: 'accent',
          },
        ]}
      />
    )
  }
  if (processItem.type === 'culture_plate' || processItem.type === 'assay_plate') {
    const isFilled = (_row, col) => {
      return get(['state', 'contents', _row, col], processItem) != null
    }
    return (
      <TinyMicroplate
        className={className}
        plateFormat={processItem ? getPlateFormat(processItem) : 'wells_6'}
        highlights={[
          {
            color: 'accent',
            fn: isFilled,
          },
        ]}
        size={size}
      />
    )
  }
  return null
}

ProcessItemViz.propTypes = {
  className: PropTypes.string,
  processItem: PropTypes.ProcessItem,
  // Additional well to highlight
  numWellsToHighlight: PropTypes.number,
}

export default ProcessItemViz
