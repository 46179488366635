import PropTypes from 'prop-types'

import { renderDatetime, renderTime } from '~/utils/date'

import { ISODateString } from '~/types/ISODateString.interface'
import { RoutineOrProcessStepStatus } from '../../types/RoutineOrProcessStepStatus.interface'
import cs from './process_step_time.scss'

interface RoutineOrProcessStepStartTimeProps {
  routineOrProcessStep: {
    status: RoutineOrProcessStepStatus
    startTime?: ISODateString
    endTime?: ISODateString
    estimatedStartTime?: ISODateString
    estimatedEndTime?: ISODateString
  }
  displayType?: 'datetime' | 'time'
}

const RoutineOrProcessStepStartTime = ({
  routineOrProcessStep,
  displayType = 'datetime',
}: RoutineOrProcessStepStartTimeProps) => {
  if (!routineOrProcessStep) return null

  const renderFn = displayType === 'datetime' ? renderDatetime : renderTime

  if (
    routineOrProcessStep.status === 'initialized' ||
    routineOrProcessStep.status === 'canceled'
  ) {
    return <span className={cs.noTime}>--</span>
  }
  if (routineOrProcessStep.status === 'scheduled') {
    return (
      <span className={cs.estimatedTime}>
        {renderFn(routineOrProcessStep.estimatedStartTime)} (est.)
      </span>
    )
  }
  if (
    routineOrProcessStep.status === 'failed' ||
    routineOrProcessStep.status === 'in_progress' ||
    routineOrProcessStep.status === 'completed'
  ) {
    return <span className={cs.time}>{renderFn(routineOrProcessStep.startTime)}</span>
  }
  return null
}

RoutineOrProcessStepStartTime.propTypes = {
  processStep: PropTypes.any,
  displayType: PropTypes.oneOf(['time', 'datetime']),
}

export default RoutineOrProcessStepStartTime
