import PropTypes from 'prop-types'
import { IconProps } from './types/IconProps'
const ConfigIcon = ({ className, onClick }: IconProps) => (
  <svg
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    onClick={onClick}
    className={className}
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M19 3H5C3.89001 3 3 3.89999 3 5V19C3 20.1 3.89001 21 5 21H19C20.11 21 21 20.1 21 19V5C21 3.89999 20.11 3 19 3ZM7.41003 16.04L6.20996 13.97C6.13 13.84 6.16003 13.68 6.27002 13.58L7.54004 12.59L7.53735 12.5628C7.51819 12.3727 7.5 12.1909 7.5 12C7.5 11.8 7.52002 11.61 7.55005 11.41L6.28003 10.42C6.17004 10.32 6.13 10.16 6.20996 10.03L7.41003 7.95999C7.48999 7.82999 7.64001 7.78 7.78003 7.82999L9.27002 8.42999C9.56995 8.19 9.92004 8 10.28 7.85001L10.5 6.26001C10.53 6.10999 10.65 6 10.8 6H13.2C13.35 6 13.47 6.10999 13.5 6.25L13.72 7.84C14.09 7.98999 14.42 8.19 14.73 8.42999L16.22 7.82999C16.36 7.78 16.51 7.82999 16.59 7.95999L17.79 10.03C17.87 10.16 17.83 10.33 17.72 10.42L16.45 11.41L16.4526 11.4372C16.4718 11.6273 16.49 11.8091 16.49 12C16.49 12.19 16.48 12.39 16.45 12.58L17.72 13.57C17.83 13.67 17.87 13.83 17.79 13.96L16.59 16.03C16.51 16.16 16.36 16.21 16.22 16.16L14.73 15.56C14.42 15.8 14.08 16 13.72 16.15L13.5 17.74C13.47 17.89 13.35 18 13.2 18H10.8C10.65 18 10.53 17.89 10.5 17.75L10.28 16.16C9.91003 16.01 9.57996 15.81 9.27002 15.57L7.78003 16.17C7.64001 16.22 7.48999 16.17 7.41003 16.04ZM13.7101 12C13.7101 11.06 12.9401 10.29 12 10.29C11.0601 10.29 10.29 11.06 10.29 12C10.29 12.94 11.0601 13.71 12 13.71C12.9401 13.71 13.7101 12.94 13.7101 12ZM5 19H19V5H5V19Z'
    />
  </svg>
)

ConfigIcon.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
}

export default ConfigIcon
