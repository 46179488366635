/*
  LineGraph can graph multiple lines.

  Data is an object mapping keys to an array of objects.
  Each key is a unique line.

  Example:
  data: {
    datasetOne: [
      { x: 0, y: 10 },
      { x: 10, y: 20 },
    ]
  }
*/

import PropTypes from 'prop-types'

import D3ChartWrapper from '~/components/d3/D3ChartWrapper'

import { TooltipParams } from '../ChartTooltip'
import { D3ChartAxisOptions, D3ChartLayoutOptions } from '../d3/D3Chart'
import D3LineGraph, { D3LineGraphData, D3LineGraphOptions } from './D3LineGraph'

interface LineGraphProps {
  className?: string
  data: D3LineGraphData
  options?: Partial<D3LineGraphOptions>
  layoutOptions: Partial<D3ChartLayoutOptions>
  axisOptions: Partial<D3ChartAxisOptions<number, number>>
  renderTooltipContents?: (tooltipParams: TooltipParams) => void
}

const LineGraph = ({
  data,
  options,
  className,
  layoutOptions,
  axisOptions,
}: LineGraphProps) => (
  <D3ChartWrapper<D3LineGraphData, number, number, D3LineGraphOptions>
    D3ChartClass={D3LineGraph}
    className={className}
    data={data}
    options={options}
    layoutOptions={layoutOptions}
    axisOptions={axisOptions}
  />
)

LineGraph.propTypes = {
  className: PropTypes.string,
  data: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.any)),
  options: PropTypes.objectOf(PropTypes.any),
}

export default LineGraph
