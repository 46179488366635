import { get, sortBy } from 'lodash/fp'
import PropTypes from 'prop-types'
import { useState } from 'react'
import { Link } from 'react-router-dom'

import workcellAPI from '~/api/desktop/workcell'
import Table from '~/components/Table'
import Toaster from '~/components/Toaster'
import Button from '~/components/buttons/Button'
import MinimalButton from '~/components/buttons/MinimalButton'
import AddIcon from '~/components/icons/AddIcon'
import InstrumentTile from '~/pages/Workcell/components/instrument/InstrumentTile'

import AddInstrumentDialog from './AddInstrumentDialog'
import cs from './instruments.scss'

const Instruments = ({ instruments, instrumentStatus, onInstrumentsUpdate }) => {
  const [, setSelectedInstrument] = useState(null)
  const [addInstrumentDialogOpen, setAddInstrumentDialogOpen] = useState(false)

  const removeInstrument = async instrument => {
    try {
      await workcellAPI.removeInstrument(instrument.instrumentName)
      Toaster.show({
        message: `${instrument.instrumentName} successfully removed.`,
        intent: 'success',
      })
      onInstrumentsUpdate()
    } catch (e) {
      Toaster.show({
        message: `${instrument.instrumentName} could not be removed.`,
        intent: 'danger',
      })
    }
  }

  const handleRowClicked = row => {
    setSelectedInstrument(row)
  }

  const handleInstrumentAdd = async selectedInstrument => {
    setAddInstrumentDialogOpen(false)
    try {
      await workcellAPI.addInstrument(
        selectedInstrument.instrumentName,
        selectedInstrument.instrumentType,
      )
      Toaster.show({
        message: `${selectedInstrument.instrumentName} successfully added.`,
        intent: 'success',
      })
      onInstrumentsUpdate()
    } catch (e) {
      Toaster.show({
        message: `${selectedInstrument.instrumentName} could not be added.`,
        intent: 'danger',
      })
    }
  }

  const tableColumns = [
    {
      name: 'Name',
      width: 'flex',
      render: row => (
        <Link
          to={`/workcell/instruments/${row.instrumentName}`}
          className={cs.link}
          key={row.instrumentName}
        >
          <InstrumentTile
            instrument={row}
            instrumentStatus={get(row.instrumentName, instrumentStatus)}
            variant='leftAligned'
            mini
          />
        </Link>
      ),
    },
    {
      name: '',
      width: 150,
      render: row => (
        <MinimalButton
          label='Remove From Workcell'
          onClick={() => removeInstrument(row)}
        />
      ),
      showOnHover: true,
      omitCellPadding: true,
      stopPropagation: true,
    },
  ]

  const renderTable = () => {
    if (!instruments || instruments.length === 0) {
      return <div className={cs.bigMessage}>No instruments found.</div>
    }

    const sortedInstruments = sortBy('name', instruments)

    return (
      <Table
        columns={tableColumns}
        data={sortedInstruments}
        className={cs.table}
        rowPaddingVariant='rowPaddingLow'
        rowKey='instrumentName'
        onRowClick={handleRowClicked}
      />
    )
  }

  return (
    <div className={cs.instrumentDrivers}>
      <div className={cs.controls}>
        <div className={cs.fill} />
        <Button
          type='primary'
          label='Add an Instrument'
          IconComponent={AddIcon}
          onClick={() => setAddInstrumentDialogOpen(true)}
        />
      </div>
      {renderTable()}
      <AddInstrumentDialog
        isOpen={addInstrumentDialogOpen}
        onClose={() => setAddInstrumentDialogOpen(false)}
        onInstrumentAdd={handleInstrumentAdd}
      />
    </div>
  )
}

Instruments.propTypes = {
  instruments: PropTypes.arrayOf(
    PropTypes.shape({
      instrumentName: PropTypes.string,
      instrumentType: PropTypes.string,
    }),
  ),
  instrumentStatus: PropTypes.objectOf(
    PropTypes.shape({
      driverState: PropTypes.string,
      executionState: PropTypes.string,
      isSimulated: PropTypes.bool,
    }),
  ),
  onInstrumentsUpdate: PropTypes.func,
}

export default Instruments
