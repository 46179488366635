import cx from 'classnames'
import { size } from 'lodash/fp'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { useContext } from 'react'

import workcellAPI from '~/api/desktop/workcell'
import Dialog from '~/components/Dialog'
import MinimalBoldButton from '~/components/buttons/MinimalBoldButton'
import WorkcellStatusContext from '~/pages/Workcell/WorkcellStatusContext'
import {
  getRoutineIds,
  getRoutineIdsInProgress,
  getRoutineIdsScheduled,
  hasRoutinesInProgress,
} from '~/pages/Workcell/utils/workcellStatus'
import { displayCount } from '~/utils/string'

import Toaster from '~/components/Toaster'
import cs from './confirm_cancel_all_routines_dialog.scss'

interface ConfirmCancelAllRoutinesDialogProps {
  className?: string
  isOpen: boolean
  onClose(): void
}

const ConfirmCancelAllRoutinesDialog = ({
  className,
  isOpen,
  onClose,
}: ConfirmCancelAllRoutinesDialogProps) => {
  const [routineIdsWithStepsInProgress, setRoutineIdsWithStepsInProgress] = useState<
    number[] | null
  >(null)

  const [delayedIsOpen, setDelayedIsOpen] = useState(false)

  const workcellStatus = useContext(WorkcellStatusContext)

  const routinesIdsInProgress = getRoutineIdsInProgress(workcellStatus)
  const routinesIdsScheduled = getRoutineIdsScheduled(workcellStatus)
  const allRoutineIds = getRoutineIds(workcellStatus)

  const cancelAllScheduledSteps = async () => {
    try {
      const response = await workcellAPI.cancelAllScheduledSteps()

      Toaster.show({
        message: `Cancelled ${response.stepUuidsCancelled.length} steps.`,
        intent: 'success',
      })
    } catch (e) {
      Toaster.show({
        message: String(e),
        intent: 'danger',
      })
    }
  }
  const cancelAllExceptInProgress = async () => {
    const scheduledRoutineIds = getRoutineIdsScheduled(workcellStatus)
    const response = await workcellAPI.bulkCancelRoutines(scheduledRoutineIds)

    Toaster.show({
      message: `Cancelled ${response.stepUuidsCancelled.length} steps.`,
      intent: 'success',
    })
  }

  const onConfirmWithoutInProgressRoutines = () => {
    onClose()
    cancelAllExceptInProgress()
  }

  const onConfirm = () => {
    onClose()
    cancelAllScheduledSteps()
  }

  const fetchRoutineIdsWithStepsInProgress = async () => {
    const response = await workcellAPI.getRoutineIdsWithStepsInProgress()

    setRoutineIdsWithStepsInProgress(response.routine_ids_with_steps_in_progress)
  }

  useEffect(() => {
    // If there are no routines in progress, immediately cancel all.
    if (isOpen) {
      if (!hasRoutinesInProgress(workcellStatus)) {
        cancelAllScheduledSteps()
        onClose()
      } else {
        setDelayedIsOpen(true)
        fetchRoutineIdsWithStepsInProgress()
      }
    } else {
      setDelayedIsOpen(false)
      setRoutineIdsWithStepsInProgress(null)
    }
  }, [isOpen])

  const renderDialogBody = () => {
    if (routineIdsWithStepsInProgress === null) {
      return null
    }

    if (size(routineIdsWithStepsInProgress) > 0) {
      return (
        <>
          <div className={cs.text}>
            <b>
              {' '}
              {displayCount('routine', size(routinesIdsInProgress))} have steps that are
              currently in-progress.
            </b>
          </div>
          <div className={cs.text}>
            Please wait until the in-progress steps are complete before cancelling
            in-progress routines.
          </div>
          <div className={cs.options}>
            <MinimalBoldButton
              label={`Cancel All Except In-Progress Routines (${size(
                routinesIdsScheduled,
              )})`}
              onClick={onConfirmWithoutInProgressRoutines}
              type='danger'
              className={cs.button}
              disabled={size(routinesIdsScheduled) === 0}
            />
          </div>
        </>
      )
    }

    return (
      <>
        <div className={cs.text}>
          <b>
            {' '}
            {displayCount('routine', size(routinesIdsInProgress))} is currently
            in-progress.
          </b>
        </div>
        <div className={cs.text}>
          If you cancel this routine, you must manually return sample plates or
          consumables to their home positions before starting another routine.
        </div>
        <div className={cs.options}>
          <MinimalBoldButton
            label={`Cancel All Except In-Progress Routines (${size(
              routinesIdsScheduled,
            )})`}
            onClick={onConfirmWithoutInProgressRoutines}
            type='danger'
            className={cs.button}
            disabled={size(routinesIdsScheduled) === 0}
          />
          <MinimalBoldButton
            label={`Cancel All Routines (${size(allRoutineIds)})`}
            onClick={onConfirm}
            type='danger'
            className={cs.button}
          />
        </div>
      </>
    )
  }

  return (
    <Dialog
      isOpen={delayedIsOpen}
      onClose={onClose}
      className={cx(cs.confirmCancelAllDialog, className)}
    >
      {renderDialogBody()}
    </Dialog>
  )
}

ConfirmCancelAllRoutinesDialog.propTypes = {
  className: PropTypes.string,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
}

export default ConfirmCancelAllRoutinesDialog
