import { Instrument } from '~/common.interface'
import { SelectedStorageLocation } from '~/pages/Workcell/components/StorageViz/StorageVizView'
import { StorageParamsRequest } from '../../reloadItems/convertChangeToReloadOperation'
import { LoadParamsRequest } from '../LoadParamsRequestOrError.interface'
import { LoadUnloadItemsProcessItemType } from '../LoadUnloadItemsProcessItemType.interface'

export const getLoadStorageOperation = (
  instrument: Instrument,
  storageLocation: SelectedStorageLocation | null,
  processItemType: LoadUnloadItemsProcessItemType,
  loadParamRequest: LoadParamsRequest,
  useArmAssist: boolean,
  manuallyMove: boolean,
): {
  // TODO: LoadOperationRequest should work here but requires a deeper investigation
  instrument_name: string
  process_item_params: LoadParamsRequest
  type: string
  command: string
  command_params: StorageParamsRequest | null
  use_arm_assist: boolean
  manually_move: boolean
} => {
  return {
    instrument_name: instrument.instrumentName,
    command: 'load_process_item',
    command_params: storageLocation
      ? ({
          level_index: storageLocation.levelIndex,
          shelf_index: storageLocation.shelfIndex,
        } as StorageParamsRequest)
      : null,
    type: processItemType,
    process_item_params: loadParamRequest,
    use_arm_assist: useArmAssist,
    manually_move: manuallyMove,
  }
}
