import cx from 'classnames'
import { flatten, sortBy } from 'lodash/fp'
import { BulkExportCultureMetadataQuery } from '~/__generated__/graphql'
import Table from '~/components/Table'
import GroupedErrorTable, {
  ErrorGroup,
} from '~/pages/Workcell/OperatorActions/GroupedErrorTable'
import { convertWellNameToWellCoord } from '~/utils/microplate'
import { displayCount } from '~/utils/string'
import cs from './bulk_export_culture_metadata_preview.scss'
import { BulkExportDataTableRow } from './getDataRows'
import { getMissingPlates } from './getMissingPlates'

export interface BulkExportCultureMetadataPreviewProps {
  className?: string
  loading: boolean
  data?: BulkExportCultureMetadataQuery
  plateBarcodesToDisplay: string[]
}

const getErrorGroupForMissingPlates = (missingPlates: string[]): ErrorGroup => {
  return {
    errorType: 'nonexistent_monitor_plate',
    errorGroupHeader: `${displayCount('plate', missingPlates.length)} could not be found.`,
    errors: missingPlates.map(plateBarcode => ({
      displayElement: (
        <span>
          Plate <b>{plateBarcode}</b> does not exist.{' '}
        </span>
      ),
    })),
  }
}

const TABLE_COLUMNS = [
  {
    name: 'plate_barcode',
    width: 'flex',
    render: (row: BulkExportDataTableRow) => row.plateBarcode,
    oneLine: true,
  },
  {
    name: 'well',
    width: 60,
    render: (row: BulkExportDataTableRow) => row.well,
  },
  {
    name: 'cell_line_lot',
    width: 'flex',
    render: (row: BulkExportDataTableRow) => row.cellLineLot,
    oneLine: true,
  },
  {
    name: 'cell_line',
    width: 'flex',
    render: (row: BulkExportDataTableRow) => row.cellLine,
    oneLine: true,
  },
  {
    name: 'passage_number',
    width: 120,
    render: (row: BulkExportDataTableRow) => row.passageNumber,
    rightAlign: true,
  },
]

const BulkExportCultureMetadataPreview = ({
  className,
  loading,
  data,
  plateBarcodesToDisplay,
}: BulkExportCultureMetadataPreviewProps) => {
  const renderContents = () => {
    if (loading) {
      return <div className={cs.info}>Loading preview...</div>
    }
    if (!data) return null

    // If there are missing plates, show the error table.
    const missingPlates = getMissingPlates(data, plateBarcodesToDisplay)
    if (missingPlates.length > 0) {
      return (
        <>
          <div className={cs.errorCount}>
            {displayCount('error', missingPlates.length)} with pasted data
          </div>
          <GroupedErrorTable
            className={cs.parseDataErrorTable}
            errorGroups={[getErrorGroupForMissingPlates(missingPlates)]}
          />
        </>
      )
    }

    const rows: BulkExportDataTableRow[] = sortBy(
      [
        (row: BulkExportDataTableRow) =>
          plateBarcodesToDisplay.indexOf(row.plateBarcode),
        (row: BulkExportDataTableRow) => {
          const { rowIndex, colIndex } = convertWellNameToWellCoord(row.well)
          // We'll make do without the plateFormat.
          return rowIndex * 1000 + colIndex
        },
      ],
      flatten(
        data.filteredCulturePlatesWithWells.map(culturePlateWithWells => {
          return culturePlateWithWells.wellCultures.map(wellCulture => {
            return {
              plateBarcode: culturePlateWithWells.barcode,
              well: wellCulture.well,
              cellLineLot: wellCulture.cellLineLot,
              cellLine: wellCulture.cellLine,
              passageNumber: wellCulture.passageNumber,
            }
          })
        }),
      ),
    )

    return (
      <>
        <div className={cs.info}>
          Exporting {displayCount('well', rows.length)} from{' '}
          {displayCount('plate', plateBarcodesToDisplay.length)}
        </div>
        <Table<BulkExportDataTableRow>
          columns={TABLE_COLUMNS}
          data={rows}
          className={cx(cs.table)}
          rowKey='id'
          rowPaddingVariant='rowPaddingLow'
          heightSizing='flexAuto'
        />
      </>
    )
  }

  if (!data && !loading) return null
  if (plateBarcodesToDisplay.length === 0) return null

  return (
    <div className={cx(className, cs.bulkExportCultureMetadataPreview)}>
      <div className={cs.sectionTitle}>CSV Preview</div>
      <div className={cs.contents}>{renderContents()}</div>
    </div>
  )
}

export default BulkExportCultureMetadataPreview
