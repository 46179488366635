import { ProcessItem } from '~/common.interface'
import { getLocation } from '~/utils/processItems/common'
import { snakeCaseToLowerCase } from '~/utils/string'
import { ExistingPlateOrError } from './ExistingPlateOrError.interface'
import { ValidationOptions } from './ValidationOptions.interface'

export const validateExistingProcessItem = (
  processItem: ProcessItem | null,
  validationOptions: ValidationOptions,
): ExistingPlateOrError => {
  if (!processItem) return {}

  if (!validationOptions.allowExistingPlate) {
    return {
      error: `Barcode '${processItem.uuid}' has already been used.`,
    }
  }

  if (
    validationOptions.processItemType &&
    processItem.type !== validationOptions.processItemType
  ) {
    return {
      error: `Barcode '${
        processItem.uuid
      }' has already been used for ${snakeCaseToLowerCase(processItem.type)}.`,
    }
  }

  if (validationOptions.mustHaveIsEmptyFlag && !processItem.state?.isEmpty) {
    return {
      error: `Barcode '${
        processItem.uuid
      }' is already used by non-empty ${snakeCaseToLowerCase(processItem.type)}`,
    }
  }

  // If the existing plate is already checked in with a valid location,
  // do not allow.
  if (processItem.isCheckedIn && getLocation(processItem)) {
    return {
      error: `A ${snakeCaseToLowerCase(processItem.type)} with barcode '${
        processItem.uuid
      }' is already checked in`,
    }
  }

  return {
    existingPlate: processItem,
  }
}
