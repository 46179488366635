import { filter } from 'lodash'
import { RoutineDefinitionWithDSL } from '~/pages/Workcell/Routines/useRoutineDefinitionsWithDSLs'

const SAMPLE_PLATE_PARAMETER_TYPE = 'sample_plate'

export function getRoutineSamplePlateBarcodeParameter(
  routineDefinitionsWithDSL: RoutineDefinitionWithDSL,
): string | null {
  const matchingKeys = filter(
    Object.keys(routineDefinitionsWithDSL.dsl.inputs),
    key =>
      routineDefinitionsWithDSL.dsl.inputs[key].type === SAMPLE_PLATE_PARAMETER_TYPE &&
      !routineDefinitionsWithDSL.dsl.inputs[key].display_in_advanced_dialog,
  )

  return matchingKeys.length === 1 ? matchingKeys[0] : null
}

export function getAdvancedDialogParameters(
  routineDefinitionsWithDSL: RoutineDefinitionWithDSL,
): string[] {
  const matchingKeys = filter(
    Object.keys(routineDefinitionsWithDSL.dsl.inputs),
    key => routineDefinitionsWithDSL.dsl.inputs[key].display_in_advanced_dialog,
  ) as string[]

  return matchingKeys
}
