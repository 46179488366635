import cx from 'classnames'
import { filter, flow, get, map, toPairs } from 'lodash/fp'
import { useContext } from 'react'

import Table from '~/components/Table'
import LoadingIcon from '~/components/icons/LoadingIcon'
import WorkcellStatusContext from '~/pages/Workcell/WorkcellStatusContext'

import HelpPopover from '~/components/HelpPopover'
import { getInstrumentRealTimeState } from '../../utils/workcellStatus'
import { LiconicConfig } from './LiconicConfig.interface'
import cs from './latest_process_variable_values_tooltip.scss'

const getProcessVariableNameDisplay = (name: string) => {
  switch (name) {
    case 'temp':
      return 'Temperature'
    case 'humidity':
      return 'Humidity'
    case 'co2':
      return 'CO2'
    default:
      return name
  }
}

const getProcessVariableValueDisplay = (processVariableName: string, value: number) => {
  switch (processVariableName) {
    case 'temp':
      return `${value.toFixed(1)}°C`
    case 'humidity':
      return `${value.toFixed(1)}%`
    case 'co2':
      return `${value.toFixed(2)}%`
    default:
      return value
  }
}

const TABLE_COLUMNS = [
  {
    name: 'Process Variable',
    width: 200,
    render: row => getProcessVariableNameDisplay(row.metric),
  },
  {
    name: 'Value',
    width: 100,
    rightAlign: true,
    render: row => (
      <div className={cs.value}>
        {getProcessVariableValueDisplay(row.metric, row.value)}
      </div>
    ),
  },
]

interface InstrumentStatusPopoverProps {
  instrumentName: string
  config: LiconicConfig | null
}

interface LiconicRealTimeData {
  last_process_variable_values: {
    temp: number | null
    humidity: number | null
    co2: number | null
  }
}

const LatestProcessVariableValuesTooltip = ({
  instrumentName,
  config,
}: InstrumentStatusPopoverProps) => {
  const workcellStatus = useContext(WorkcellStatusContext)
  const realTimeStatus = getInstrumentRealTimeState(
    workcellStatus,
    instrumentName,
  ) as LiconicRealTimeData
  const metrics =
    realTimeStatus && realTimeStatus.last_process_variable_values && config
      ? flow(
          toPairs,
          filter(([processVariableName]) => {
            if (processVariableName === 'temp') return config.healthcheck_temp
            if (processVariableName === 'co2') return config.healthcheck_co2
            if (processVariableName === 'humidity') return config.healthcheck_humidity
            return false
          }),
          map(pairs => ({
            metric: pairs[0],
            value: pairs[1],
          })),
        )(realTimeStatus.last_process_variable_values)
      : []

  const renderPopover = () => {
    if (metrics.length === 0) {
      return <div className={cs.noDataMessage}>No data available</div>
    }
    return (
      <Table
        columns={TABLE_COLUMNS}
        data={metrics}
        rowKey='name'
        rowPaddingVariant='rowPaddingLow'
        className={cs.table}
        heightSizing='default'
      />
    )
  }

  const renderTarget = () => {
    return <span className={cx(cs.popoverTrigger)}>View Latest Environmental Data</span>
  }

  if (!config || !config.healthcheck_on || !config.use_communicator_version_two) {
    return null
  }

  if (get('_tempStatus', workcellStatus) === 'LOADING') {
    return <LoadingIcon className={cs.loadingIcon} />
  }

  return (
    <HelpPopover
      helpContent={renderPopover()}
      placement='bottom'
      text={renderTarget()}
      interactionKind='hover'
    ></HelpPopover>
  )
}

export default LatestProcessVariableValuesTooltip
